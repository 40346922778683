import { useParams } from 'react-router-dom'
import { ContractTypeLabels, ExtendedAccessContractsTypesList } from '../constants'
import { ContractStatusType, useGetContractStatusQuery } from '../graphql'
import { PATH_PARAMS } from '../routes/paths'

export const useIsLimitedContract = () => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: statusData } = useGetContractStatusQuery({
    variables: { id: applicationId },
    skip: !applicationId || Number.isNaN(Number(applicationId)),
    fetchPolicy: 'network-only',
  })

  const isLimitedContract = ExtendedAccessContractsTypesList.includes(
    statusData?.contract?.status as ContractStatusType,
  )

  return {
    isLimitedContract,
    contractStatusLabel: ContractTypeLabels[statusData?.contract?.status as ContractStatusType],
    contractStatus: statusData?.contract?.status,
    type: statusData?.contract?.type,
    contractCountry: statusData?.contract?.owner?.address?.country?.toUpperCase(),
  }
}
