import * as yup from 'yup'

import {
  EmailInputSchema,
  PasswordInputSchema,
  PrivacyAcceptanceInputSchema,
  RepeatPasswordInputSchema,
} from './common'

export const InviteSignUpFormInputSchema = PrivacyAcceptanceInputSchema.concat(EmailInputSchema)
  .concat(PasswordInputSchema)
  .concat(RepeatPasswordInputSchema)

export type InviteSignUpFormInput = yup.Asserts<typeof InviteSignUpFormInputSchema>
