import i18n from 'i18next'
import { EventsType } from 'react-idle-timer'
import {
  ActionsOrderBy,
  BusinessRegion,
  CardsOrderBy,
  ContractAuthPersonOrderBy,
  ContractPaymentRecipientOrderByType,
  ContractStatusType,
  DocumentOrderBy,
  OrderBy,
  OrderDirection,
  PaymentStatus,
  PaymentsOrderBy,
  SuccessFeeOrderBy,
  TransactionOrderBy,
} from '../graphql'
import {
  AccountsPageStateType,
  ActionsPageStateType,
  BeneficiaryOverviewPageStateType,
  BeneficiaryPageStateType,
  CardsPageStateType,
  DocumentPageStateType,
  GuaranteeEventPageStateType,
  OrderDirectionShort,
  PaymentsPageStateType,
  StandingOrdersPageStateType,
  StatusSelectorType,
  SuccessFeesPageStateType,
  TeamPageStateType,
  TrxPageStateType,
  VbansPageStateType,
  VbansTabsEnum,
} from '../types'

export const DIGITS_COUNT_OF_LONG_BALANCE = 10
export const DEFAULT_COUNTRY_CALLING_CODE = 'CH'
export const IDLE_SESSION_TIMEOUT = 1000 * 60 * 25
export const LOGOUT_AFTER_TIME = 1000 * 60 * 2
export const MAX_FILE_SIZE_BYTES = 15728640
export const limitAge = 18

export const ACTIVE_SESSION_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]
export const EMPTY_IMAGE = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
export const TOTP_STRING_PARAM = 'totp'

export enum delaysForRefetch {
  delay = 2000,
  interval = 10000,
}

export const initialActionsPageState: ActionsPageStateType = {
  searchValue: '',
  type: StatusSelectorType.AllTypes,
  status: StatusSelectorType.AllStatuses,
  sortBy: ActionsOrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initialAccountsPageState: AccountsPageStateType = {
  searchValue: '',
  currency: 'allCurrencies',
  status: StatusSelectorType.AllStatuses,
  page: 1,
}

export const initialTransactionPageState: TrxPageStateType = {
  searchValue: '',
  direction: 'allDirections',
  cardsFilter: '',
  status: 'allStatuses',
  notStatus: '',
  period: [null, null],
  cardId: 'allMethods',
  sortBy: TransactionOrderBy.CreatedAt,
  sortDirection: OrderDirection.Descending,
  page: 1,
}

export const initialStandingOrdersPageState: StandingOrdersPageStateType = {
  sortBy: OrderBy.CreatedAt,
  sortDirection: OrderDirectionShort.Ascending,
}

export const initClientsPageState: BeneficiaryPageStateType = {
  searchValue: '',
  sortBy: ContractPaymentRecipientOrderByType.RecipientName,
  direction: OrderDirection.Ascending,
  page: 1,
}

export const initClientsOverviewPageState: BeneficiaryOverviewPageStateType = {
  sortBy: TransactionOrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initCardsPageState: CardsPageStateType = {
  searchValue: '',
  sortBy: CardsOrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initialTeamPageState: TeamPageStateType = {
  searchValue: '',
  role: StatusSelectorType.AllRoles,
  status: StatusSelectorType.AllStatuses,
  direction: OrderDirection.Descending,
  sortBy: ContractAuthPersonOrderBy.CreatedAt,
  page: 1,
}
export const initialDocsPageState: DocumentPageStateType = {
  searchValue: '',
  documentType: 'allTypes',
  period: [null, null],
  direction: OrderDirection.Descending,
  sortBy: DocumentOrderBy.CreatedAt,
  page: 1,
}

export const initVbansPageState: VbansPageStateType = {
  searchValue: '',
  page: 1,
  currentTab: VbansTabsEnum.individual,
}

export const initGuaranteeEventsPageState: GuaranteeEventPageStateType = {
  sortBy: OrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const BUSINESS_APP_STATUS_KEY = (applicationId: string): string =>
  `businessAppStatus_${applicationId}`

export const initialSuccessFeePageState: SuccessFeesPageStateType = {
  sortBy: SuccessFeeOrderBy.ExecutedAt,
  sortDirection: OrderDirection.Descending,
  page: 1,
}

export const ExtendedAccessContractsTypesList = [
  ContractStatusType.Suspended,
  ContractStatusType.TerminationPending,
]

export const ContractTypeLabels: Partial<Record<ContractStatusType, string>> = {
  [ContractStatusType.Suspended]: 'suspended',
  [ContractStatusType.TerminationPending]: 'termination pending',
} as const

export const initialCardsVolumes = { domestic: 0, eea: 0, nonEea: 0 }

export const cardPaymentsRegions = [
  { key: BusinessRegion.Eea, label: 'EEA' },
  { key: BusinessRegion.Uk, label: 'UK' },
  { key: BusinessRegion.NorthAmerica, label: 'North America' },
  { key: BusinessRegion.SouthAmerica, label: 'South America' },
  { key: BusinessRegion.Asia, label: 'Asia' },
  { key: BusinessRegion.Africa, label: 'Africa' },
  { key: BusinessRegion.Australia, label: 'Australia' },
  { key: BusinessRegion.Cis, label: 'CIS' },
]

export const defaultDisplayAccountsLimit = 3

export const initialPaymentsPageState: PaymentsPageStateType = {
  searchValue: '',
  statuses: [PaymentStatus.Completed, PaymentStatus.Failed, PaymentStatus.Pending],
  methods: [],
  period: [null, null],
  sortBy: PaymentsOrderBy.ExecutedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const accountMenuOptions = Object.freeze({
  sendPayment: i18n.t('sendPayment', 'Send Payment'),
  internalTransfer: i18n.t('internalTransfer', 'Internal transfer'),
  transactions: i18n.t('transactions', 'Transactions'),
  allTransactions: i18n.t('allTransactions', 'All transactions'),
  viewVBANs: i18n.t('viewVBANs', 'View VBANS'),
  receivePayment: i18n.t('receivePayment', 'Receive payment'),
  standingOrders: i18n.t('standingOrders', 'Standing Orders'),
  changeAltAccount: i18n.t('changeAltAccount', 'Change Alternative Account'),
  pendingTransactions: i18n.t('pendingTransactions', 'Pending transactions'),
  depositGuaranteedInfo: i18n.t('depositGuaranteedInfo', 'Deposit guaranteed info'),
  rename: i18n.t('rename', 'Rename'),
})
