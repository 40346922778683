import * as yup from 'yup'

import {
  LATINICA_ONLY_MESSAGE,
  LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD,
} from '../../constants/validations'
import { isEmpty } from 'lodash'

export const AdditionalDetailsInputSchema = yup.object().shape({
  additionalDetails: yup
    .string()
    .test(
      'additionalDetails',
      LATINICA_ONLY_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD),
    ),
})

export type AdditionalDetailsInput = yup.Asserts<typeof AdditionalDetailsInputSchema>
