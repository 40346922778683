import React, { FC } from 'react'
import {
  Box,
  Hidden,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { toNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { LimitedAccessRight, Transaction, TransactionStatus } from '../../../graphql'
import { useDetermineUserRights } from '../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { currencyFormat, formatAccountIban, shortFormatDate } from '../../../utils'
import { notProcessedPayment } from '../../../utils/Data'
import { UploadDocument } from '../../AplicationActions'
import { SignatureProps } from '../../AplicationActions/contract'
import {
  SignaturesTable,
  useUpdateTransactionStatusHandler,
} from '../../AplicationActions/Tabs/helpers'

import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { TransferProps } from '../TrxTransferPage'
import { DelayedPaymentAlert } from './DelayedPaymentAlert'
import { useRepeatPayment } from '../../../components/Common/TransactionStatusCell/hooks'
import { OutboundButtonsBlock } from './OutboundButtonsBlock'
import { TransactionStatusCell } from './TransactionStatusCell'
import { generatePath } from 'react-router-dom'
import { paidList } from '../../../stubs'

export const TrxOutboundTab: FC<{
  transfer: TransferProps
  signedCount: number
  handleBack?: () => void
}> = ({ transfer, signedCount, handleBack }) => {
  const { txData } = transfer
  const { t } = useTranslation()
  const classes = useStyles()

  const statementAvailable = txData?.status === TransactionStatus.Completed
  const accId = (transfer.rawData as Transaction)?.account?.id as string
  const trxId = transfer.rawData?.id as string

  useUpdateTransactionStatusHandler(transfer.signatures)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly
  const isLimitedAccessUser = !!userRights?.limitedAccessRight

  const isFX = txData?.payoutCurrency && txData?.accountCurrency !== txData?.payoutCurrency

  const repeatPayment = useRepeatPayment(trxId)

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('transactionStatus', 'Transaction status')}
                </Box>
              </TableCell>
              <TableCell>
                <TransactionStatusCell
                  txData={transfer?.rawData as Transaction}
                  repeatPayment={repeatPayment}
                />
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('paymentNumber', 'Payment number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.referenceNumber}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('account', 'Account')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{`${txData?.accountName} ${
                  txData?.accountIban ? `(${formatAccountIban(txData?.accountIban as string)})` : ''
                }`}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('amount', 'Amount')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
                </Box>
              </TableCell>
            </TableRow>

            {isFX && (
              <>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('payoutAmount', 'Payout amount')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box component="span">
                      {txData?.payoutAmount}&nbsp;{txData?.payoutCurrency}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('exchangeRate', 'Exchange Rate')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box component="span">{txData?.xRate}</Box>
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('beneficiaryLabel', 'Beneficiary')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.to}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('feesToBePaidBy', 'Fees to be paid  by')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {paidList[txData?.chargeBearer as keyof typeof paidList]?.label}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('reference', 'Reference')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.comment}</Box>
              </TableCell>
            </TableRow>

            {txData?.additionalComment && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('additionalComment', 'Additional Comment')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">{txData?.additionalComment}</Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('clientReference', 'Client reference')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {txData?.clientReference ? txData?.clientReference : '-'}
                </Box>
              </TableCell>
            </TableRow>

            {!notProcessedPayment.includes(txData?.status as TransactionStatus) && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={clsx(classes.label)}>
                    {t('execution', 'Execution')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span" className={clsx(classes.name)}>
                    {txData?.standingOrderId ? (
                      <Link
                        href={generatePath(APP_PATHS.dashboard.standingOrderDetails, {
                          [PATH_PARAMS.applicationId]: applicationId,
                          [PATH_PARAMS.standingOrderId]: txData?.standingOrderId,
                        })}
                      >
                        {t('standingOrder', 'Standing order')}
                      </Link>
                    ) : (
                      `${txData?.executionType} (${shortFormatDate(
                        new Date((txData?.valueDate || txData?.createdAt) as string),
                        {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        },
                        'de-DE',
                      )})`
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('processing', 'Processing')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span" className={clsx(classes.name)}>
                  {txData?.priority}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('createdBy', 'Created by')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span" className={clsx(classes.name)}>
                  {(transfer.rawData as Transaction)?.executionAction?.initiatorName}
                </Box>
              </TableCell>
            </TableRow>

            {txData && txData.status && txData.status === TransactionStatus.Reverted && (
              <>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('reverted', 'Reverted')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box component="span">{}</Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('reverseTransaction')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box component="span">{}</Box>
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCell width="200" colSpan={2} className={classes.cell}>
                <Typography variant="h4" className={classes.label}>
                  {t('bankAccountDetails', 'Bank account details')}
                </Typography>
              </TableCell>
            </TableRow>

            {txData?.bank && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('bank', 'Bank')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">{txData?.bank}</Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              {txData?.iban ? (
                <>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('iban', 'IBAN')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box component="span">{txData?.iban}</Box>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('accNum', 'Account number')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box component="span">{txData?.accountNumber}</Box>
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('swiftBic', 'SWIFT / BIC')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.swift}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {!isViewOnly && (
          <UploadDocument
            accountId={accId}
            trxId={trxId}
            isTransaction={true}
            recipientType={txData?.recipientType}
          />
        )}
        {/*//TODO update Alert after delayed execution appears on back*/}
        <DelayedPaymentAlert />

        {!isLimitedAccessUser && (
          <SignaturesTable
            signatures={transfer.signatures as SignatureProps[]}
            signedCount={signedCount}
          />
        )}

        {statementAvailable && (
          <OutboundButtonsBlock
            accId={accId}
            handleBack={handleBack}
            trxId={trxId}
            confirmationAvailable={!!(transfer?.rawData as Transaction)?.isConfirmationAvailable}
          />
        )}
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
              <TransactionStatusCell
                txData={transfer?.rawData as Transaction}
                repeatPayment={repeatPayment}
              />
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('paymentNumber', 'Payment number')}</Box>
              <Box className={classes.mobileTableName}>{txData?.referenceNumber}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('account', 'Account')}</Box>
              <Box className={classes.mobileTableName}>{`${txData?.accountName} ${
                txData?.accountIban ? `(${formatAccountIban(txData?.accountIban as string)})` : ''
              }`}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
              <Box className={classes.mobileTableName}>
                {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('beneficiaryLabel', 'Beneficiary')}</Box>
              <Box>{txData?.to}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('feesToBePaidBy', 'Fees to be paid  by')}
              </Box>
              <Box>{paidList[txData?.chargeBearer as keyof typeof paidList]?.label}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('reference', 'Reference')}</Box>
              <Box>{txData?.comment}</Box>
            </Box>

            {txData?.additionalComment && (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>
                  {t('additionalComment', 'Additional Comment')}
                </Box>
                <Box>{txData?.additionalComment}</Box>
              </Box>
            )}

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('clientReference', 'Client reference')}
              </Box>
              <Box> {txData?.clientReference ? txData?.clientReference : '-'}</Box>
            </Box>

            {!notProcessedPayment.includes(txData?.status as TransactionStatus) && (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('execution', 'Execution')}</Box>
                <Box className={clsx(classes.name)}>{`${txData?.executionType} (${shortFormatDate(
                  new Date((txData?.valueDate || txData?.createdAt) as string),
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  },
                  'de-DE',
                )})`}</Box>
              </Box>
            )}
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('processing', 'Processing')}</Box>
              <Box className={clsx(classes.name)}>{txData?.priority}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('createdBy', 'Created by')}</Box>
              <Box className={clsx(classes.name)}>
                {(transfer.rawData as Transaction)?.executionAction?.initiatorName}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
                  {t('bankAccountDetails', 'Bank account details')}
                </Typography>
              </Box>
            </Box>
            {txData?.bank && (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('bank', 'Bank')}</Box>
                <Box>{txData?.bank}</Box>
              </Box>
            )}

            {txData?.iban ? (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('iban', 'IBAN')}</Box>
                <Box>{txData?.iban}</Box>
              </Box>
            ) : (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('accNum', 'Account number')}</Box>
                <Box>{txData?.accountNumber}</Box>
              </Box>
            )}

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('swiftBic', 'SWIFT / BIC')}</Box>
              <Box>{txData?.swift}</Box>
            </Box>

            {!isViewOnly && (
              <UploadDocument
                accountId={accId}
                trxId={trxId}
                isTransaction={true}
                recipientType={txData?.recipientType}
              />
            )}

            {/*//TODO update Alert after delayed execution appears on back*/}
            <DelayedPaymentAlert />

            {!isViewOnly && (
              <SignaturesTable
                signatures={transfer.signatures as SignatureProps[]}
                signedCount={signedCount}
              />
            )}
          </Box>
        </Box>
        {statementAvailable && (
          <OutboundButtonsBlock
            accId={accId}
            handleBack={handleBack}
            trxId={trxId}
            confirmationAvailable={!!(transfer.rawData as Transaction)?.isConfirmationAvailable}
          />
        )}
      </Hidden>
    </>
  )
}
