import React, { FC } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormTextField } from '../../Common'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormDatePickerField } from '../../Common'
import { CompanyTypeList } from '../../../utils/Data'
import { countriesArray } from '../../Common'
import { useFormContext } from 'react-hook-form'
import { CountryPhoneSelect, FormAutocompleteSelect } from '../../Common'

const useStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  datails: {
    // maxWidth: 552,
  },
}))

const GridRow: FC = ({ children }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={8} lg={6}>
        {children}
      </Grid>
    </Grid>
  )
}

export const PersonDetailsForm: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { getValues } = useFormContext()
  const defaultFirstname = getValues('firstName')
  const defaultLastName = getValues('lastName')
  const defaultEmail = getValues('email')

  return (
    <Box className={classes.datails}>
      <Box className={classes.titleWrap}>
        <Typography variant={'h2'} className={classes.title}>
          {t('personalDetails', 'Personal Details')}
        </Typography>
      </Box>

      <GridRow>
        <FormTextField
          label={t('firstNames', 'First Name(s)')}
          name="firstName"
          type="text"
          fullWidth={true}
          required={false}
          InputLabelProps={{
            shrink: !!defaultFirstname,
          }}
        />
      </GridRow>

      <GridRow>
        <FormTextField
          label={t('lastName', 'Last Name')}
          name="lastName"
          type="text"
          fullWidth={true}
          required={false}
          InputLabelProps={{
            shrink: !!defaultLastName,
          }}
        />
      </GridRow>

      <GridRow>
        <FormAutocompleteSelect
          name="companyType"
          label={t('companyType', 'Company Type')}
          data={CompanyTypeList}
        />
      </GridRow>

      <GridRow>
        <FormTextField
          label={t('emailAddress', 'Email Address')}
          name="email"
          type="text"
          fullWidth={true}
          required={false}
          InputLabelProps={{
            shrink: !!defaultEmail,
          }}
        />
      </GridRow>

      <GridRow>
        <FormDatePickerField name="birthday" label={t('dateOfBirth', 'Date of Birth')} />
      </GridRow>

      <GridRow>
        <FormTextField
          label={t('streetAddress', 'Street Address')}
          name="streetAddress"
          type="text"
          fullWidth={true}
          required={false}
        />
      </GridRow>

      <GridRow>
        <FormTextField
          label={t('city', 'City')}
          name="city"
          type="text"
          fullWidth={true}
          required={false}
        />
      </GridRow>

      <GridRow>
        <FormTextField
          label={t('postalCode', 'Postal code')}
          name="postalCode"
          type="text"
          fullWidth={true}
          required={false}
        />
      </GridRow>

      <GridRow>
        <FormAutocompleteSelect
          name="country"
          label={t('country', 'Country')}
          data={countriesArray}
        />
      </GridRow>

      <GridRow>
        <FormTextField
          label={t('nationality', 'Nationality')}
          name="nationality"
          type="text"
          fullWidth={true}
          required={false}
        />
      </GridRow>

      <GridRow>
        <CountryPhoneSelect name="phoneNumber" label={t('phoneNumber', 'Phone Number')} />
      </GridRow>
    </Box>
  )
}
