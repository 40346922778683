import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { get, isEqual } from 'lodash'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Box, Checkbox } from '@material-ui/core'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { AuthPersonsSelectType, ControlledTextFieldProps, CurrencyType } from '../../../types'
import { Maybe } from '../../../graphql'
import { handleLatinKeysDown } from '../../../utils'

const useStyles = (isValue: boolean, withCheckBox: boolean) =>
  makeStyles((theme: Theme) => ({
    formField: {
      maxWidth: '100%',
      '& .MuiInputLabel-root': {
        maxWidth: 'calc(100% - 40px)',
        minWidth: 'calc(100% - 40px)',
      },
      '& .MuiInputLabel-formControl': {
        transform: isValue ? 'translate(0, 12px) scale(0.857)' : '',
      },
    },
    positionRelative: {
      '& + .MuiAutocomplete-popperDisablePortal': {
        position: 'initial',
      },
    },
    input: {
      [theme.breakpoints.down('md')]: {
        '& .MuiAutocomplete-input:first-child': {
          paddingTop: '20px !important',
          paddingRight: '20px !important',
        },
      },
    },
    higherLabel: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiInputLabel-shrink': {
          transform: 'translate(0, 0px) scale(0.857)',
        },
      },
    },
    autocompleteListWrap: {
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        position: 'relative',
        paddingRight: 40,
        backgroundColor: 'transparent',
        '&::after': !withCheckBox
          ? {
              position: 'absolute',
              top: 12,
              right: 16,
              content: '""',
              width: 14,
              height: 8,
              borderLeft: '2px solid',
              borderBottom: '2px solid',
              transform: 'rotate(-45deg)',
            }
          : {},
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        color: '#434343',
        backgroundColor: theme.palette.action.hover,
      },
    },
    readOnly: {
      '& .MuiFormLabel-root ': {
        zIndex: 1,
      },
      '& .MuiInput-root': {
        backgroundColor: '#F5F5F5',
        pointerEvents: 'none',
      },
      '& .MuiIconButton-root': {
        color: '#999999',
      },
      '& .MuiInputBase-root': {
        color: '#999999',
      },
    },
    checkBox: {
      '& .PrivateSwitchBase-root-152': {
        padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
        margin: theme.spacing(0.7, 2, 0.7, 0),
      },
      '& .MuiIconButton-colorSecondary': {
        backgroundColor: 'transparent',
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      '& .makeStyles-autocompleteListWrap-322 .MuiAutocomplete-option[aria-selected="true"]::after': {
        display: 'none',
      },
      '&:hover': {
        '& svg > rect': {
          stroke: '#000000',
        },
      },
    },
  }))()

type DataType = Array<
  | (CurrencyType & { key: string })
  | AuthPersonsSelectType
  | { key: string; label: string; disabled?: boolean }
>

interface FormAutocompleteSelectProps {
  name: string
  data: DataType
  disableClearable?: boolean
  readOnly?: boolean
  defaultValue?: Maybe<string> | undefined
  filterOptionPosition?: 'start' | 'any'
  setDisableParentScroll?: React.Dispatch<React.SetStateAction<boolean>>
  onInputChange?: () => void
  disablePortal?: boolean
  isParentScroll?: boolean
  onlyLatinLetters?: boolean
}

const FormAutocompleteSelectNew: React.FC<
  FormAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  label,
  disableClearable,
  data,
  shouldValidateParam,
  readOnly = false,
  defaultValue,
  rules,
  filterOptionPosition = 'start',
  setDisableParentScroll,
  onInputChange,
  withCheckbox = false,
  isParentScroll,
  disablePortal,
  onlyLatinLetters = true,
  ...rest
}) => {
  const { errors, setValue, watch, clearErrors, control } = useFormContext()
  const error = errors ? get(errors, name || 'FormAutocompleteSelect') : null
  const value = watch(name)
  const classes = useStyles(!!value, withCheckbox)

  const [isOpen, setIsOpen] = useState(false)
  const isLongLabel = typeof label === 'string' && label.length > 30

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [name, defaultValue])

  useEffect(() => {
    if (isParentScroll) {
      handleClose()
    }
  }, [isParentScroll])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleTextFieldChanged = () => {
    setIsOpen(true)
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || null}
      render={({ onChange, value }) => (
        <Autocomplete
          open={isOpen}
          autoHighlight
          onBlur={handleClose}
          key={`${name}${value}`}
          className={`${classes.formField} ${disablePortal && classes.positionRelative} ${
            readOnly ? classes.readOnly : ''
          }`}
          classes={{
            paper: classes.autocompleteListWrap,
          }}
          disableClearable={disableClearable || true}
          options={data}
          onOpen={() => setDisableParentScroll && setDisableParentScroll(true)}
          onClose={() => setDisableParentScroll && setDisableParentScroll(false)}
          getOptionLabel={(option) => option.label}
          getOptionDisabled={(option) => !!('disabled' in option ? option.disabled : false)}
          getOptionSelected={(option, value) => {
            return option?.key === value?.key
          }}
          onChange={(_, option) => {
            setIsOpen(false)
            onChange(option ? option.key : null)
            onInputChange && onInputChange()
            return clearErrors(name)
          }}
          value={data.find((v) => v?.key === value)}
          disabled={readOnly}
          renderInput={(params) => (
            <TextField
              label={label}
              onClick={() => !readOnly && setIsOpen((prevState) => !prevState)}
              className={`${classes.input} ${isLongLabel && classes.higherLabel}`}
              name={name}
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              disabled={readOnly}
              onChange={handleTextFieldChanged}
              onKeyDown={(e) => handleLatinKeysDown(e, onlyLatinLetters)}
              {...rest}
              inputProps={{
                ...rest.inputProps,
                ...params.inputProps,
                readOnly,
                'data-test': `autotest-${name}`,
              }}
            />
          )}
          renderOption={(option, { selected }) =>
            withCheckbox ? (
              <Box className={classes.row}>
                <Box className={classes.checkBox}>
                  <Checkbox checked={selected} icon={<Unchecked />} checkedIcon={<Checked />} />
                </Box>
                {option.label}
              </Box>
            ) : (
              <Box>{option.label}</Box>
            )
          }
          disablePortal={disablePortal}
          openText={''}
          closeText={''}
          popupIcon={<ExpandMoreIcon />}
        />
      )}
    />
  )
}

export default React.memo(FormAutocompleteSelectNew, isEqual)
