import React, { FC, useCallback, useEffect } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import { FormControlledTextField, GridRow } from './Fields'
import { isEqual } from 'lodash'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  volume: {
    '& .MuiInputAdornment-positionEnd': {
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  addButton: {
    width: 150,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      marginLeft: '51%',
      transform: 'translate(0, -15px)',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  removeButton: {
    margin: '10px 0px 0px 10px',
    padding: 0,
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '100%',
    },
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
}))

const TextInputComponent: FC<{
  name: string
  label: string
  addBtnText: string
  isViewOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
}> = ({ name, label, addBtnText, isViewOnly, tooltipTitle }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const methods = useFormContext()
  const { watch, register, setValue, getValues, errors, formState } = methods

  const inputsFieldsNamesArr = ['input'].map((item, i) => `${name}Inp${i}`)
  const inputsValuesArr = watch(name)
  const inputsField = watch(inputsFieldsNamesArr)

  const error = errors ? errors[name] : null

  const onAdd = useCallback(() => {
    const valuesArr = getValues(name)
    register(inputsFieldsNamesArr[valuesArr?.length ?? 0])
    const newArrVal = [...(valuesArr || []), '']

    setValue(name, newArrVal, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [...Object.values(inputsField)])

  useEffect(() => {
    register(inputsFieldsNamesArr[0])
    register(name)
    if (getValues(name) === undefined) {
      setValue(name, [''])
    }
  }, [])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      if (indexToRemove > 0) {
        const newList = inputsValuesArr.filter(
          (_: string, index: number) => index !== indexToRemove,
        )
        setValue(name, newList, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    },
    [inputsValuesArr],
  )

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <GridRow>
            {inputsValuesArr &&
              inputsValuesArr.map((value: string, i: number) => {
                return (
                  <Box className={classes.twoRows} key={i}>
                    <FormControlledTextField
                      className={classes.volume}
                      label={label}
                      name={`${name}Inp${i}`}
                      fullWidth
                      required={false}
                      type="text"
                      defaultValue={value}
                      disabled={isViewOnly}
                    />

                    {i !== 0 && i === inputsValuesArr.length - 1 && (
                      <Box className={classes.buttonsBox}>
                        <Button
                          color="secondary"
                          aria-label="delete"
                          type="button"
                          onClick={() => onRemove(i)}
                          disabled={isViewOnly}
                        >
                          {t('delete', 'Delete')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )
              })}
            {!!error && (
              <Grid container className={classes.errorMessage}>
                <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
              </Grid>
            )}
            {tooltipTitle && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors[name]}
                title={tooltipTitle}
                arrowHorizontalPosition={'left'}
              />
            )}
            <Grid item className={classes.addButton}>
              <Button
                type="button"
                variant="contained"
                fullWidth
                disableElevation
                onClick={onAdd}
                disabled={isViewOnly}
              >
                {addBtnText}
              </Button>
            </Grid>
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}
export const TextInput = React.memo(TextInputComponent, isEqual)
