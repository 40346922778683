import React, { FC, useMemo } from 'react'
import { isEmpty, isUndefined } from 'lodash'
import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import {
  withStyles,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepIconProps,
  MobileStepper,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { stepperTypeVar, stepperHeaderTextVar, stepperCurrentStepVar } from '../../graphql/local'
import { Steppers } from '../../constants'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    // '& .MuiStepper-root': {
    //   marginBottom: 0,
    //   paddingBottom: 10,
    // },
    // '& .MuiStepLabel-label': {
    //   fontSize: 16,
    //   fontWeight: 'bold',
    //   fontFamily: 'Basier Square',
    // },
  },
  stepperWrap: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  stepper: {
    padding: theme.spacing(2, 1, 2, 1),
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0, 0, 0),
      marginTop: theme.spacing(0),
      position: 'static',
      '& .MuiStepConnector-root': {
        flex: 0,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-completed': {
      color: '#EF2828',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
      [theme.breakpoints.up('sm')]: {
        paddingRight: 8,
      },
    },
    '& .MuiStepLabel-label': {
      display: 'none',
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'left',
      },
    },
    '& .MuiStepConnector-active + .MuiStep-root': {
      borderBottomColor: '#EF2828',
    },
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    marginBottom: '1em',
  },
  step: {
    zIndex: 2,
    padding: theme.spacing(0, 0, 0, 0),
    borderBottom: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 1, 1, 1),
      borderBottom: '1px solid transparent',
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
    },
  },
  firstStep: {
    '&:not(.MuiStep-completed)': {
      borderBottomColor: '#EF2828',
    },
  },
}))

const StyledMobileStepper = withStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    maxWidth: '100%',
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'none',
    marginTop: -1,
    padding: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      // backgroundColor: '#EF2828',
      backgroundColor: '#D9D9D9',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#D9D9D9',
      height: '1px',
    },
  },
  progress: {
    width: '100%',
    height: 2,
  },
}))(MobileStepper)

const ColorlibConnector = withStyles((theme: Theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#EF2828',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'white',
      },
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#EF2828',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'white',
      },
    },
  },
  line: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: '#D9D9D9',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
    },
  },
}))(StepConnector)

const useColorlibStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    backgroundColor: '#F5F5F5',
    zIndex: 1,
    color: '#999999',
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: 'none',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      width: 20,
      height: 20,
    },
  },
  active: {
    backgroundColor: '#EF2828',
    boxShadow: '0 0 0 0',
    color: '#fff',
  },
  completed: {
    backgroundColor: '#EF2828',
    color: 'white',
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.up('sm')]: {
      width: 20,
      height: 20,
    },
  },
}))

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles()
  const { active, completed, icon } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CheckIcon className={classes.completed} /> : <div>{icon}</div>}
    </div>
  )
}

export const StepperLayout: FC = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matchesBreakpoints: boolean = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()

  const stepperType = useReactiveVar(stepperTypeVar)
  const currentStep = useReactiveVar(stepperCurrentStepVar)
  const headerText = useReactiveVar(stepperHeaderTextVar)

  const steps = useMemo(() => {
    const stepper = !isUndefined(stepperType) && Steppers[stepperType]
    return stepper
      ? Object.entries(stepper).reduce((acc, [key, defaultText], idx) => {
          acc[key] = [idx, t(key, defaultText)]
          return acc
        }, {} as Record<string, [idx: number, label: string]>)
      : {}
  }, [stepperType])

  const renderMobileStepLabel = useMemo(
    () =>
      steps &&
      currentStep &&
      !matchesBreakpoints && <div className={classes.label}>{steps[currentStep][1]}</div>,
    [matchesBreakpoints, steps, currentStep],
  )

  return (
    <>
      {isEmpty(steps) || !currentStep ? undefined : (
        <Box className={classes.stepperWrap}>
          <Grid item xs={12}>
            <Typography variant={'h1'}>{headerText}</Typography>
          </Grid>
          <Stepper
            connector={<ColorlibConnector />}
            activeStep={steps[currentStep][0]}
            className={classes.stepper}
          >
            {Object.values(steps).map(([idx, label]) => {
              return (
                <Step key={label} className={`${classes.step} ${idx === 0 && classes.firstStep}`}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {renderMobileStepLabel}
          <StyledMobileStepper
            variant="progress"
            steps={3}
            position="static"
            activeStep={steps[currentStep][0]}
            className={classes.root}
            nextButton={<Button style={{ display: 'none' }} />}
            backButton={<Button style={{ display: 'none' }} />}
          />
        </Box>
      )}
      {children}
    </>
  )
}

export default StepperLayout
