/* eslint-disable i18next/no-literal-string */
import React from 'react'
import { Box, Button, makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DEL_ICON from '../../assets/images/icons/icon-delete.svg'
import { useDeleteContractMutation } from '../../graphql'
import { refreshPage } from '../../utils'

const useStyles = makeStyles((theme) => ({
  wrapModalDel: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapModalDelClose: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBlock: {
    background: '#ffffff',
    width: '512px',
    height: '184px',
    position: 'relative',
    padding: '25px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '296px',
    },
  },
  modalHeader: {
    padding: '0 0 16px 0',
  },
  modalContent: {
    padding: 0,
  },
  modalContentText: {
    marginBottom: 24,
    fontSize: '15px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  modalControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& button': {
      minWidth: '92px',
      width: '92px',
      height: '48px',
      margin: '0 0 0 24px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      margin: '45px 0 0 0',

      '& button': {
        minWidth: '100%',
        width: '100%',
        height: '100%',
        margin: '0 0 20px 0',
      },
    },
  },
  modalClose: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    '& span': {
      position: 'absolute',
      width: '2px',
      height: '18px',
      background: '#595959',
    },
  },
  modalCloseBlockRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDel: {
    backgroundImage: `url(${DEL_ICON})`,
    width: '22px',
    height: '22px',
  },
  textHead: {
    color: '#EF2828',
    fontSize: '14px',
    textTransform: 'capitalize',
    margin: '0 0 0 12px',
    fontWeight: 700,
  },
}))

type FlagProps = {
  value: boolean
  numDeleteApp: string | number
  deleteApp: (value: string | number) => void
  updateFlag: (value: boolean) => void
}

export const ConfirmModalDel: React.FC<FlagProps> = ({
  value,
  updateFlag,
  numDeleteApp,
  deleteApp,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [deleteContractMutation] = useDeleteContractMutation({
    variables: { id: numDeleteApp.toString() },
  })

  const handleDeleteApp = () => {
    deleteContractMutation().then(() => {
      refreshPage()
    })
  }

  return (
    <Box className={value ? classes.wrapModalDel : classes.wrapModalDelClose}>
      <Box className={classes.modalBlock}>
        <Box className={classes.modalHeader}>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.wrapHeader}>
              <Box component="span" className={classes.iconDel} />
              <Box className={classes.textHead}>delete</Box>
            </Grid>
            <Grid item xs={6} className={classes.modalCloseBlockRight}>
              <Box
                className={classes.modalClose}
                onClick={() => {
                  updateFlag(false)
                  deleteApp('')
                }}
              >
                {/*<Box component="span" className={classes.modalCloseLeft}></Box>*/}
                {/*<Box component="span" className={classes.modalCloseRight}></Box>*/}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.modalContent}>
          <Box className={classes.modalContentText}>
            {t(
              'areYouSureWantToDeleteThisApplication',
              'Are you sure you want to delete this application?',
            )}
          </Box>
        </Box>
        <Box className={classes.modalControl}>
          <Button
            variant="contained"
            onClick={() => {
              updateFlag(false)
              deleteApp('')
            }}
          >
            {t('cancel', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDeleteApp()}
            data-test="deleteApp"
          >
            {t('delete', 'Delete')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfirmModalDel
