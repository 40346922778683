import { Box, ClickAwayListener, IconButton, InputBase, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { debounce, isEqual } from 'lodash'
import React, {
  CSSProperties,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ALL_EXCEPT_CYRILLIC_REGEXP } from '../../../constants/validations'

const useStyles = makeStyles((theme) => ({
  cardSelector: {
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    height: 48,
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid #c4c4c4',
    borderRadius: '0.05em',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
    '& .MuiInputBase-input': {
      height: 'inherit',
      textOverflow: 'ellipsis',
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiIconButton-root': {
      padding: 8,
    },
  },
}))

const SearchFieldComponent: FC<{
  setValue: (value: string) => void
  currentValue: string
  setPage?: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void)
  placeHolder?: string
  accountId?: string | number | null | undefined
  disabled?: boolean
  style?: CSSProperties
}> = ({ setValue, setPage, placeHolder, currentValue, accountId, disabled, style }) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const classes = useStyles()
  const { t } = useTranslation()

  const onSearch = useCallback(
    (value: string) => {
      debouncedRef.current.cancel()
      if (value !== currentValue) {
        setValue(value)
        setPage && setPage(1)
      }
    },
    [searchValue, setPage, currentValue, setValue],
  )

  const debouncedRef = useRef(debounce(onSearch, 3000))

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedRef.current.cancel()
    const currentValue = event.target.value
    setSearchValue((prev) => {
      // it's need to handle the onSearch with a delay after the input is cleared
      if (!!prev && !currentValue) {
        debouncedRef.current(currentValue)
      }
      return currentValue
    })
  }

  useEffect(() => {
    if (!!searchValue) {
      debouncedRef.current(searchValue)
    }
  }, [searchValue])

  useEffect(() => debouncedRef.current.cancel(), [])

  useEffect(() => {
    if (accountId && searchValue) {
      setSearchValue('')
      onSearch('')
    }
  }, [accountId])

  useEffect(() => {
    setSearchValue(currentValue)
  }, [currentValue, setSearchValue])

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!ALL_EXCEPT_CYRILLIC_REGEXP.test(event.key)) {
      event.preventDefault()
      return
    }
    if (event.key === 'Enter' && currentValue !== searchValue) {
      onSearch(searchValue)
    }
  }

  const handleClickAway = useCallback(() => {
    currentValue !== searchValue && onSearch(searchValue)
  }, [searchValue, currentValue, onSearch])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.cardSelector} style={style}>
        <IconButton type="button" onClick={() => onSearch(searchValue)}>
          <Search style={{ fill: '#c4c4c4' }} />
        </IconButton>
        <InputBase
          placeholder={
            placeHolder || `${t('searchTrxLabel', 'Search transaction by ID or From/To')}`
          }
          inputProps={{ 'aria-label': 'search google maps' }}
          value={searchValue}
          onChange={onChange}
          onKeyDown={handleKeyPress}
          disabled={disabled}
          autoFocus
        />
      </Box>
    </ClickAwayListener>
  )
}

export const SearchField = React.memo(SearchFieldComponent, isEqual)
