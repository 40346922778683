/* eslint-disable i18next/no-literal-string */
import React, { FC, MouseEvent, useCallback, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Box, Button, makeStyles, Theme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { ReactComponent as AlertIcon } from '../../assets/images/icons/alert_icon.svg'
import PENDING from '../../assets/images/icons/grey_dot.svg'
import ACTIVE from '../../assets/images/icons/green_dot.svg'
import FROZEN from '../../assets/images/icons/orange_dot.svg'
import ControlledIconTooltip from '../../components/Common/Tooltips/ControlledIconTooltip'
import { ActionSignature, ActionSignatureStatus, CardStatuses, CardType } from '../../graphql'
import { CurrentUserContext } from '../../hooks'
import { dateFormat } from '../../utils'
import { CardStatusResolver } from './CardStatusResolver'

const useStyles = makeStyles((theme: Theme) => ({
  backGround: {
    backgroundColor: '#F5F5F5',
    marginBottom: theme.spacing(1),
    '&:not(:first-child)': {
      borderTop: '1px solid rgba(0,0,0,.1)',
    },
    '&.select': {
      backgroundColor: '#FFFFFF',
    },
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0 20px 0 0',
  },
  mobileDoubleItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
  ownerWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  owner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  whichType: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    borderBottom: 'none',
    textTransform: 'capitalize',
    '& .Pending': {
      backgroundImage: `url(${PENDING})`,
      width: '4px',
      height: '4px',
      padding: '5px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .Active': {
      backgroundImage: `url(${ACTIVE})`,
      width: '4px',
      height: '4px',
      padding: '5px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .Blocked': {
      backgroundImage: `url(${FROZEN})`,
      width: '4px',
      height: '4px',
      padding: '5px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .Closed': {
      backgroundImage: `url(${PENDING})`,
      width: '4px',
      height: '4px',
      padding: '5px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  wrapStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  spendLimit: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  view: {
    fontWeight: 700,
    textDecoration: 'underline',
    fontSize: 14,
    paddingLeft: 0,
    '& .MuiButton-label': {
      justifyContent: 'start',
    },
    '&.MuiButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  btnCopy: {
    minWidth: 20,
    minHeight: 20,
    width: 20,
    height: 20,
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .MuiSvgIcon-colorPrimary': {
      color: '#bfc1c4',
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#999',
    },
  },
  tooltipBody: {
    maxWidth: '232px',
  },
}))

export const CardsListMobile: FC<{
  data: CardType
  setSelectedCardData: React.Dispatch<React.SetStateAction<CardType | undefined>>
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedCardId: number | string | undefined
  setOpenLimits: (value: boolean) => void
  setActionData: React.Dispatch<React.SetStateAction<ActionSignature | undefined>>
  setActionModal: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({
  data,
  setSelectedCardData,
  selectedCardId,
  setOpenModal,
  setOpenLimits,
  setActionData,
  setActionModal,
}) => {
  const { t } = useTranslation()
  const {
    id,
    alias,
    updatedAt,
    cardHolder,
    apiMaskedCardNumber,
    account,
    status,
    cardRequest,
  } = data
  const classes = useStyles()
  const cardClosed = !!status && status === CardStatuses.Closed
  const cardPending = !!status && status === CardStatuses.Pending

  const user = useContext(CurrentUserContext)
  const actionSignature = cardRequest?.executionAction?.signatures?.find((s) => s?.isMine)

  const handleClickItem = useCallback(() => {
    setSelectedCardData(data)
    setOpenModal(true)
  }, [data, setSelectedCardData])

  const handleClickItemView = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setOpenLimits(true)
      setSelectedCardData(data)
    },
    [data],
  )

  const areaRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (id === selectedCardId) {
      setSelectedCardData(data)
    }
  }, [status])

  return (
    <Box
      onClick={handleClickItem}
      className={clsx(classes.backGround, `${id !== selectedCardId ? 'select' : ''}`)}
    >
      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('cardLabel', 'Card label')}</Box>
            <Box className={classes.name}>{alias || t('none', 'None')}</Box>
          </Grid>

          <Grid item xs={6} className={classes.ownerWrap}>
            <Box className={classes.mobileTableLabel}>{t('owner', 'Owner')}</Box>
            <Box
              className={classes.owner}
            >{`${cardHolder?.person?.firstName} ${cardHolder?.person?.lastName}`}</Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('number', 'Number')}</Box>
            <Box>
              {'*'}
              {apiMaskedCardNumber && apiMaskedCardNumber.slice(-4)}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('currency', 'Currency')}</Box>
            {account?.currency}
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={6} className={classes.wrapStatus}>
            {actionSignature?.status !== ActionSignatureStatus.Pending && (
              <Box className={classes.mobileTableLabel}>{t('statusAction', 'Status/Actions')}</Box>
            )}
            <Box>
              <Grid container className={classes.whichType}>
                <Grid ref={areaRef} item sm={12}>
                  {status && user?.id && (
                    <CardStatusResolver
                      data={data}
                      userId={user.id}
                      setActionData={setActionData}
                      setActionModal={setActionModal}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container className={`${status}`} />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Grid container className={classes.spendLimit}>
              <Button
                className={clsx(classes.view, `${status}`)}
                disabled={cardClosed || cardPending}
                onClick={handleClickItemView}
              >
                {t('viewLimits', 'View limits')}
              </Button>

              {status === CardStatuses.Blocked && (
                <Grid item onClick={(e) => e.stopPropagation()}>
                  <ControlledIconTooltip
                    placement={'top'}
                    title={
                      status === CardStatuses.Blocked
                        ? `${t('frozenAt', 'Frozen at')} ${dateFormat(updatedAt, true)} ${t(
                            'by',
                            'by',
                          )} ${user?.firstName} ${user?.lastName}`
                        : ''
                    }
                  >
                    <AlertIcon />
                  </ControlledIconTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
