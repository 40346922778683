import { makeStyles, Theme } from '@material-ui/core'

const drawerWidth = 248

export const useMenuStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 0px 0px 0px',
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  appBarShift: {
    width: `100%`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    position: 'absolute',
  },
  batchButton: {
    marginRight: theme.spacing(2),
    width: 40,
    marginLeft: 16,
    '&:hover': {
      borderRadius: 'initial',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    minHeight: '100vh',
    border: 'none',
    '&.MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 'none',
    },
    '&.MuiDrawer-paper': {
      position: 'fixed',
    },
  },
  drawerPaperNonAuthenticated: {
    width: drawerWidth,
    minHeight: '100vh',
    border: 'none',
    '&.MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 'none',
    },
  },
  drawerInner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    marginTop: 64,
  },
  logoMenu: {
    padding: 35,
  },
  logo: {
    '& > img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: 136,
    },
    [theme.breakpoints.down('xs')]: {
      width: 120,
      transform: 'translate(17px, 0)',
    },
  },
  footerWrap: {},
  footerWrapNonAuthenticated: {
    padding: theme.spacing(3.5, 2, 2.5, 4),
  },
  backWrapp: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 1.5, 3, 4),
    '& .MuiFormControl-root': {
      width: '100%',
      minHeight: 'auto',
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      bottom: 5,
      margin: 0,
      paddingLeft: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  backWrappApp: {
    marginTop: 50,
  },
  backItem: {
    width: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    '& .MuiTypography-body1': {
      lineHeight: '40px',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#999',
      '& .black': {
        backgroundColor: '#000',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
    },
  },
  backIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    border: 0,
    borderRadius: '50%',
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    '& .MuiSvgIcon-root': {
      transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    },
  },
  paymentButton: {
    minWidth: 140,
    minHeight: 40,
    border: '1px solid black',
    fontSize: '1rem',
    lineHeight: '24px',
    '&.disabled': {
      border: '1px solid #D9D9D9',
      fontSize: '1rem',
      lineHeight: '24px',
      background: '#F5F5F5',
      color: '#CCCCCC',
      '&:hover': {
        cursor: 'initial',
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
}))
