import * as yup from 'yup'

import i18n from '../i18n'
import { isEmpty } from 'lodash'
import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  MAX_BUSINESS_LENGTH,
  MAX_REFERENCE_LENGTH,
  NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT,
  NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT_500,
  NUMBER_AND_LATINICA_REGEXP,
} from '../constants/validations'

export const ContactSupportSchema = yup.object().shape({
  issueCategory: yup
    .string()
    .required(i18n.t('thisFieldIsRequired', 'This field is required'))
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  topic: yup
    .string()
    .required(i18n.t('thisFieldIsMandatory', 'This field is mandatory'))
    .matches(NUMBER_AND_LATINICA_REGEXP, NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT)
    .test(
      'maxLength',
      NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT,
      (val) => !!val && val?.length <= MAX_REFERENCE_LENGTH,
    ),
  describeYourProblem: yup
    .string()
    .required(i18n.t('thisFieldIsMandatory', 'This field is mandatory'))
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT_500)
    .test(
      'maxLength',
      NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT_500,
      (val) => !!val && val?.length <= MAX_BUSINESS_LENGTH,
    ),
  //attachmentDoc: yup.string().notRequired(),
})

export type ContactSupportInput = yup.Asserts<typeof ContactSupportSchema>
