import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { IntegrationTypeOptions, PciComplianceOptions } from '../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../Common'
import { PciDssCompliance } from '../../../graphql'
import { getLabelByKey } from '../../../utils'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  dirtyControl: {
    position: 'relative',
  },
}))
const PciComplianceComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: PciDssCompliance | null
}> = ({ isViewOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { watch, setValue, formState, control } = useFormContext()

  const isNotIframeValueCheck =
    watch('integrationTypeSelect') !== IntegrationTypeOptions[0].key &&
    !!watch('integrationTypeSelect')
  const isFromPartyValueCheck = watch('pciCompliance') !== PciComplianceOptions[0].key

  useEffect(() => {
    if (!isNotIframeValueCheck) {
      setValue('pciCompliance', PciComplianceOptions[0].key)
      setValue('pciProvidersName', '')
    }
  }, [isNotIframeValueCheck])

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5">{t('pciCompliance', 'PCI DSS Compliance')}</Typography>
      </Box>
      <GridRow>
        <Controller
          as={
            <FormAutocompleteSelect
              name="integrationTypeSelect"
              label={t('integrationType', 'Integration type required')}
              className={classes.boxInput}
              data={IntegrationTypeOptions}
              readOnly={isViewOnly}
              disableClearable
            />
          }
          name="integrationTypeSelect"
          control={control}
        />
        {changedContractProfileData?.integrationTypeRequired && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.integrationTypeSelect}
            title={getLabelByKey(
              changedContractProfileData.integrationTypeRequired[0] as string,
              IntegrationTypeOptions,
            )}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>

      {isNotIframeValueCheck && (
        <GridRow>
          <FormAutocompleteSelect
            name="pciCompliance"
            label={t('pciCompliance', 'PCI DSS Compliance')}
            className={classes.boxInput}
            defaultValue={watch('pciCompliance') ?? (PciComplianceOptions[0].key as string)}
            data={PciComplianceOptions}
            readOnly={isViewOnly}
          />
          {changedContractProfileData?.dssCompliance && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.pciCompliance}
              title={getLabelByKey(
                changedContractProfileData.dssCompliance[0] as string,
                PciComplianceOptions,
              )}
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}

      {isFromPartyValueCheck && (
        <GridRow>
          <FormControlledTextField
            label={t('providerName', 'Your PCI provider`s name')}
            name="pciProvidersName"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            disabled={isViewOnly}
            data-test="pciProvidersName"
          />
          {changedContractProfileData?.pciProviderName && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.pciProvidersName}
              title={changedContractProfileData.pciProviderName}
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}
    </>
  )
}
export const PciCompliance = React.memo(PciComplianceComponent)
