import { generatePath, useHistory, useParams } from 'react-router-dom'
import { LimitedAccessRight } from '../graphql'
import { APP_PATHS, PATH_PARAMS } from '../routes/paths'
import { FacilitationTabsEnum } from '../types'
import { useDetermineUserRights } from './useDetermineUserRights'

export const useLimitedAccessGuard = (...accessDeniedUserRoles: LimitedAccessRight[]) => {
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const userRole = userRights?.limitedAccessRight

  if (userRole && !accessDeniedUserRoles.includes(userRole)) {
    return
  }

  switch (userRole) {
    case LimitedAccessRight.CardHolder:
      history.push(
        generatePath(APP_PATHS.dashboard.cards, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.cardsPageNum]: 1,
        }),
      )
      break
    case LimitedAccessRight.ViewOnly:
      history.push(
        generatePath(APP_PATHS.dashboard.home, {
          [PATH_PARAMS.applicationId]: applicationId,
        }),
      )
      break
    case LimitedAccessRight.PaymentFacilitationView:
      history.push(
        generatePath(APP_PATHS.dashboard.paymentFacilitator, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.facilitationTab]: FacilitationTabsEnum.overview,
        }),
      )
      break
    default:
      return
  }
}
