import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import clsx from 'clsx'
import { useParams } from 'react-router'

import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'

import { ReactComponent as IconDevice } from '../../assets/images/icons/2faIcon.svg'
import { ReactComponent as IconX } from '../../assets/images/icons/icon-x.svg'
import { ReactComponent as IconDeviceWarning } from '../../assets/images/icons/iconDeviceWarning.svg'
import {
  CreateApiUserMutation,
  GetApiUsersDocument,
  ResetApiUserSecretMutation,
  TwoFaCodeDispatchMode,
  TwoFaMethod,
  useCreateApiUserMutation,
  useDeactivateApiUserMutation,
  useGetActionSigningCodeMutation,
  useResetApiUserSecretMutation,
  useUpdateApiUserAllowedIpsMutation,
} from '../../graphql'
import { resendDelay } from '../../graphql/local'
import { ApiOperationTypes, SettingsTabsEnum } from '../../types'
import { InputCodeField } from './InputCode'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useCurrentUser } from '../../hooks'
import { TOTP_STRING_PARAM } from '../../constants'
import { TwoFAModalRenderButton } from './TwoFAModalRenderButton'
import { generatePath, useHistory } from 'react-router-dom'
import { personalProfileSettingsTab } from '../../graphql/local/dashboard'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 3.5, 6),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 14, 3),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
      position: 'absolute',
      left: '0',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  btnChange2FA: {
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    margin: theme.spacing(2, 0, 0, 0),
  },
  btnChange: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const DevApiSign2FAModal: FC<{
  open: boolean
  handleClose: () => void
  // eslint-disable-next-line
  vars: any
  type: ApiOperationTypes
  setSecret: (arg: string | number) => void
}> = ({ open, handleClose, vars, type, setSecret }) => {
  const initialValue = useMemo(() => ({ d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }), [])
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassege, setValidMassege] = useState<string>('')
  const [successClose, setSuccessClose] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const startInput = useRef<HTMLInputElement>(null)
  const currentUser = useCurrentUser()

  const [createApiUserMutation] = useCreateApiUserMutation()
  const [deactivateApiUserMutation] = useDeactivateApiUserMutation()
  const [updateApiUserAllowedIpsMutation] = useUpdateApiUserAllowedIpsMutation()
  const [resetApiUserSecretMutation] = useResetApiUserSecretMutation()

  const [
    getActionSigningCodeMutation,
    { data: actionServiceData },
  ] = useGetActionSigningCodeMutation()

  const enteredCode = Object.values(code).join('')

  const msgHeaderAction = useMemo(() => {
    switch (type) {
      case ApiOperationTypes.createApiUser:
        return t('apiUserCreate', 'Create new API User')
      case ApiOperationTypes.deactivateApiUser:
        return t('apiUserDisable', 'Disable API User')
      case ApiOperationTypes.resetSecretApiUser:
        return t('apiUserResetSecret', 'Reset API Secret')
      case ApiOperationTypes.updateApiUserIp:
        return t('apiUserIpUpdate')
    }
  }, [type])

  const actionMutation = useMemo(() => {
    switch (type) {
      case ApiOperationTypes.createApiUser:
        return createApiUserMutation
      case ApiOperationTypes.deactivateApiUser:
        return deactivateApiUserMutation
      case ApiOperationTypes.resetSecretApiUser:
        return resetApiUserSecretMutation
      case ApiOperationTypes.updateApiUserIp:
        return updateApiUserAllowedIpsMutation
    }
  }, [type])

  const handleActionServiceData = useCallback(
    async (mode?: TwoFaCodeDispatchMode) => {
      try {
        const { data } = await getActionSigningCodeMutation({ variables: { mode } })
        if (data?.getActionSigningCode) {
          const { resendTimeout, attemptsLeft } = data.getActionSigningCode
          resendDelay((resendTimeout as number) * 1000)
          setAttemptsLeft(attemptsLeft as number)
        }
      } catch (e) {
        toast.error((e as Error).message)
        handleClose()
        // refreshPage()
      }
    },
    [getActionSigningCodeMutation],
  )

  const onReSend = useCallback(
    (mode?: TwoFaCodeDispatchMode) => {
      if (type && vars) {
        setCode(initialValue)
        setIsError(false)
        setBtnDisable(true)
        setSecondsLeft(millisecondsToSeconds(resendDelay()))
        setValidMassege('')
        handleActionServiceData(mode)
      }
    },
    [type, vars, enteredCode.length, handleActionServiceData],
  )

  const sendCode = useCallback(async () => {
    if (enteredCode.length === 6) {
      setLoading(true)
      try {
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        const options = {
          variables: {
            ...vars,
            challengeId: `${challengeId}`,
            code: +enteredCode as number,
          },
          refetchQueries: [
            {
              query: GetApiUsersDocument,
              variables: { contractId: applicationId },
            },
          ],
          awaitRefetchQueries: true,
        }

        const { data } = await actionMutation(options)

        if (data) {
          if (type === ApiOperationTypes.createApiUser) {
            const secret = (data as CreateApiUserMutation)?.createApiUser?.apiSecret
            secret && setSecret(secret)
          }
          if (type === ApiOperationTypes.resetSecretApiUser) {
            const secret = (data as ResetApiUserSecretMutation).resetApiUserSecret
            setSecret(secret)
            toast.success(t('apiUserSuccessfulUpdate', 'API users updated'))
          }
        }

        setIsError(false)
        setSuccessClose(true)
        setValidMassege(t('codeCorrect', 'Code is correct'))
      } catch (e) {
        toast.error((e as Error).message)
        setIsError(true)
        if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
          setValidMassege(t('codeHasExpired', 'Code has expired'))
        } else {
          setValidMassege(t('codeIncorrect', 'Code is incorrect'))
        }
        setLoading(false)
        if (null !== startInput.current) {
          startInput.current.focus()
        }
      }
    }
  }, [actionServiceData, enteredCode, currentUser?.primaryTwoFAMethod])

  const onClose = useCallback(() => {
    setCode(initialValue)
    setIsError(false)
    setValidMassege('')
    handleClose()
  }, [handleClose, initialValue])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  useEffect(() => {
    code.d6 && sendCode()
  }, [code])

  useEffect(() => {
    if (open && type && vars && currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms) {
      handleActionServiceData()
    }
  }, [open, vars, type, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      if (attemptsLeft > 0) {
        setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
      }
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable, attemptsLeft])

  useEffect(() => {
    if (successClose) {
      const timerId = setTimeout(() => {
        setCode(initialValue)
        setIsError(false)
        handleClose()
      }, 1500)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [handleClose, successClose])

  const confirmSignatureMessage = useMemo(() => {
    return currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>
          {' '}
          {!isEmpty(actionServiceData)
            ? `${actionServiceData?.getActionSigningCode?.phoneLastFourDigits?.slice(2)}`
            : ''}
        </b>
        . {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [actionServiceData, currentUser?.primaryTwoFAMethod])

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={clsx(classes.paper, isError && 'error', validMassege && !isError && 'success')}
        >
          <IconButton
            className={classes.btnClose}
            color="primary"
            aria-label="close modal"
            component="span"
            onClick={onClose}
          >
            <IconX />
          </IconButton>

          <Grid className="iconContainer">{!isError ? <IconDevice /> : <IconDeviceWarning />}</Grid>

          <Box textAlign="center" mb={1}>
            <Typography variant={'h2'}>{msgHeaderAction}</Typography>
          </Box>

          <Box textAlign="center">{confirmSignatureMessage}</Box>

          <InputCodeField
            startInput={startInput}
            currencyValue={code}
            setCode={handleSetCode}
            loading={loading}
          />

          <Grid container justifyContent="center" className="infoText">
            {!!validMassege && <span>{validMassege}</span>}
          </Grid>

          <TwoFAModalRenderButton
            isNotShow={
              (validMassege && !isError) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp
            }
            secondsLeft={secondsLeft}
            btnDisable={btnDisable}
            onReSend={onReSend}
            isShowContactSupportLink={attemptsLeft < 1}
          />
          <Grid item xs={12} className={classes.btnChange}>
            <Link
              component="button"
              onClick={() => {
                personalProfileSettingsTab(2)
                history.push(
                  generatePath(APP_PATHS.dashboard.settings, {
                    [PATH_PARAMS.applicationId]: applicationId,
                  }) + `?tabId=${SettingsTabsEnum.twoFactor}`,
                )
              }}
              className={classes.btnChange2FA}
              underline="always"
            >
              {t('changeVerificationMethod', 'Change verification method')}
            </Link>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
