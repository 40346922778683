import React, { FC, useCallback, useEffect } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Box, Button, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'

import { FormControlledTextField, GridRow } from './Fields'
import { ExpectedVolumes } from '../../types'
import { isEqual } from 'lodash'
import { AmpInUse } from '../../graphql'
import { FormNumericFormat } from './Fields/FormNumericFormat'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  volume: {
    flexBasis: '47%',
    '& .MuiInputAdornment-positionEnd': {
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& label': {
      [theme.breakpoints.down(500)]: {
        transform: 'translate(0, 8px) scale(1)',
        '&[data-shrink="true"]': {
          transform: 'translate(0, 6px) scale(0.857)',
        },
      },
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      marginLeft: '51%',
      transform: 'translate(0, -15px)',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  leftMargin: {
    marginLeft: theme.spacing(2),
    '& label': {
      paddingLeft: '5px',
      paddingRight: 0,
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  removeButton: {
    margin: '10px 0px 0px 10px',
    padding: 0,
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '100%',
    },
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
}))

const DoubleTextInputComponent: FC<{
  name: string
  labels: [string, string]
  addBtnText: string
  isViewOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
}> = ({ name, labels, addBtnText, isViewOnly, tooltipTitle }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [label1, label2] = labels
  const methods = useFormContext()
  const { watch, register, setValue, getValues, errors, unregister, formState } = methods
  const inputsSelectNamesArr = ['input1'].map((_, i) => `${name + 'Inp1' + i}`)
  const inputsFieldsNamesArr = ['input2'].map((_, i) => `${name + 'Inp2' + i}`)
  const inputsValuesArr = watch(name)
  const inputsSelect = watch(inputsSelectNamesArr)
  const inputsField = watch(inputsFieldsNamesArr)

  const error = errors ? errors[name] : null

  const onAdd = useCallback(() => {
    const valuesArr = getValues(name)
    register(inputsSelectNamesArr[valuesArr?.length ?? 0])
    register(inputsFieldsNamesArr[valuesArr?.length ?? 0])
    const newArrVal = [...(valuesArr || []), { [label1]: '', [label2]: '' }]

    setValue(name, newArrVal, {
      shouldValidate: true,
    })
  }, [...Object.values(inputsSelect), ...Object.values(inputsField)])

  useEffect(() => {
    register(inputsSelectNamesArr[0])
    register(inputsFieldsNamesArr[0])
    register(name)
    if (getValues(name) === undefined) {
      setValue(name, [{ [label1]: '', [label2]: '' }])
    }
  }, [])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      if (indexToRemove > 0) {
        const tempArray: ExpectedVolumes[] = []
        inputsValuesArr?.forEach((itemValue: ExpectedVolumes, i: number) => {
          if (i !== indexToRemove) {
            tempArray.push(itemValue)
          } else {
            setValue(`${name + 'Inp1' + i}`, undefined, {
              shouldValidate: true,
              shouldDirty: true,
            })
            setValue(`${name + 'Inp2' + i}`, undefined, {
              shouldValidate: true,
              shouldDirty: true,
            })
            unregister([`${name + 'Inp1' + i}`, `${name + 'Inp2' + i}`])
          }
        })
        setValue(name, tempArray, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    },
    [inputsValuesArr],
  )

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <GridRow>
            {inputsValuesArr &&
              inputsValuesArr.map((value: AmpInUse, i: number) => {
                return (
                  <Box className={classes.twoRows} key={i}>
                    <FormControlledTextField
                      className={classes.volume}
                      label={label1}
                      name={`${name + 'Inp1' + i}`}
                      fullWidth={true}
                      required={false}
                      type="text"
                      inputProps={{
                        step: '1',
                        style: { textAlign: 'right' },
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(`${name}.${i}.ampInUseTypeCurrentlyInUse`, e.target.value)
                        },
                      }}
                      defaultValue={value?.ampInUseTypeCurrentlyInUse}
                      disabled={isViewOnly}
                    />

                    <FormNumericFormat
                      name={`${name + 'Inp2' + i}`}
                      label={label2}
                      className={clsx(
                        classes.volume,
                        i !== 0 && i === inputsValuesArr.length - 1 ? classes.leftMargin : '',
                      )}
                      disabled={isViewOnly}
                      fullWidth={true}
                      textAlignRight
                      defaultValue={value?.monthlyVolumeInEuro}
                    />

                    {i !== 0 && i === inputsValuesArr.length - 1 && (
                      <Box className={classes.buttonsBox}>
                        <Button
                          color="secondary"
                          aria-label="delete"
                          type="button"
                          onClick={() => onRemove(i)}
                          className={clsx(
                            i !== 0 && i === inputsValuesArr.length - 1 ? '' : classes.hidden,
                          )}
                          disabled={isViewOnly}
                        >
                          {t('delete', 'Delete')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )
              })}
            {!!error && (
              <Grid container className={classes.errorMessage}>
                <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
              </Grid>
            )}
            {tooltipTitle && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors[name]}
                title={tooltipTitle}
              />
            )}
            <Grid item className={classes.addButton}>
              <Button
                type="button"
                variant="contained"
                fullWidth={true}
                disableElevation
                onClick={onAdd}
                disabled={isViewOnly}
              >
                {addBtnText}
              </Button>
            </Grid>
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}
export const DoubleTextInput = React.memo(DoubleTextInputComponent, isEqual)
