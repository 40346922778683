import * as yup from 'yup'
import { PASSWORD_REQUIREMENT_REGEXP } from '../components/SignUp/Validations'
import i18n from '../i18n'

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(i18n.t('fieldIsMandatory', 'This field is mandatory')),
  password: yup
    .string()
    .required(i18n.t('fieldIsMandatory', 'This field is mandatory'))
    .notOneOf(
      [yup.ref('currentPassword'), null],
      i18n.t('currentAndNewPasswordsCanNotMatch', 'Current and new passwords can not match'),
    )
    .matches(
      PASSWORD_REQUIREMENT_REGEXP,
      i18n.t(
        'passwordSettings',
        'Passwords must contain 8 symbols, at least 1 capital and 1 digit',
      ),
    ),
  repeatPassword: yup
    .string()
    .required(i18n.t('fieldIsMandatory', 'This field is mandatory'))
    .oneOf(
      [yup.ref('password'), null],
      i18n.t('passwordsDontMatch', 'The passwords entered do not match'),
    ),
})

export type ChangePasswordInput = yup.Asserts<typeof changePasswordSchema>
