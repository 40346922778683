import { appointmentStatuses } from '@klarpay/enums'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HelpOutline from '@material-ui/icons/HelpOutline'
import clsx from 'clsx'
import { last, orderBy } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthPersonsVideoVerificationsQuery } from '../../../graphql'
import ControlledIconTooltip from '../../Common/Tooltips/ControlledIconTooltip'

type Props = {
  apVideoVerifications: AuthPersonsVideoVerificationsQuery | undefined
}

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
  summary: {
    paddingLeft: theme.spacing(1),
  },
  accordion: {
    boxShadow: 'none',
    '&::before': {
      height: 0,
    },
  },
  tableCell: {
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    cursor: 'pointer',
  },
  tooltip: {
    position: 'relative',
    bottom: 4,
    left: 5,
  },
}))

type R<T> = Required<NonNullable<T>>

type Contract = R<AuthPersonsVideoVerificationsQuery['contract']>

export const VideoVerificationsList: React.FC<Props> = ({ apVideoVerifications }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded((prevState) => {
      return !prevState
    })
  }

  const StatusChip = useCallback(
    (person?: R<R<Contract['authorizedPersons']>[number]>['person']) => {
      let statusLabel
      let klass: string
      const lastAppointment = last(orderBy(person?.appointments, 'startTime', 'asc'))
      if (person?.isVerified) {
        statusLabel = t('verified', 'Verified')
        klass = 'verified'
      } else if (!lastAppointment) {
        statusLabel = t('pending', 'Pending')
        klass = 'pending'
      } else if (lastAppointment.status === appointmentStatuses.missed.key) {
        statusLabel = t('missed', 'Missed')
        klass = 'missed'
      } else if (lastAppointment.status === appointmentStatuses.rescheduled.key) {
        statusLabel = t('rescheduled', 'Rescheduled')
        klass = 'rescheduled'
      } else if (lastAppointment.status === appointmentStatuses.scheduled.key) {
        statusLabel = t('invited', 'Invited')
        klass = 'invited'
      } else {
        return null
      }

      return (
        <Chip
          variant="outlined"
          size="small"
          label={statusLabel}
          className={clsx(`roundedPill ${klass}`)}
        />
      )
    },
    [t],
  )

  if (!apVideoVerifications) {
    return null
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      classes={{ root: classes.accordion }}
      square
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summary }}>
        <Typography variant="h4">{t('videoVerifications', 'Video Verifications')}</Typography>
        <Box className={classes.tooltip}>
          <ControlledIconTooltip
            placement="top"
            arrow
            Icon={HelpOutline}
            title={
              <Typography>
                {t(
                  'videoVerificationsExplanation',
                  "This list contains the video verifications for the company's signatories. Each person should have received an email with instructions how to schedule it individually.",
                )}
              </Typography>
            }
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.noPadding }}>
        <Table>
          <TableBody>
            {apVideoVerifications?.contract?.authorizedPersons?.map((ap, idx) => {
              return (
                <TableRow key={idx} className={classes.tableRow}>
                  <TableCell
                    padding="none"
                    width="100%"
                    classes={{ paddingNone: classes.tableCell }}
                  >
                    {ap?.person?.fullName}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }}>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    <StatusChip {...ap!.person!} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
