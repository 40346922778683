import * as yup from 'yup'

import {
  DocumentTypeInputSchema,
  NameInputSchema,
  AddressInputSchema,
  AdditionalDetailsInputSchema,
  StreetAddressInputSchema,
  NationalityInputSchema,
  BirthdayInputSchema,
  PostalCodeInputSchema,
} from './common'
import { PersonAdditionalDataSchema } from './PersonAdditionalDataSchema'

export const ManagingDirectorValidationSchema = yup
  .object()
  .shape({
    choosePerson: yup.string(),
    authorized_person: yup.string(),
  })
  .concat(NameInputSchema)
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(AdditionalDetailsInputSchema)
  .concat(DocumentTypeInputSchema)
  .concat(PersonAdditionalDataSchema)

export type ManagingDirectorInput = yup.Asserts<typeof ManagingDirectorValidationSchema>
