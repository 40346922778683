import { useHistory } from 'react-router'
import { isDirtyForm, isUnsaveFormData } from '../graphql/local'
import { APP_PATHS } from '../routes/paths'
import { useTableSort } from './useCurrentTableSort'
import { usePageFiltersSorting } from './usePageFiltersSorting'
import { useWebApi } from './useWebApi'

export const useLogOut = () => {
  const { webApi } = useWebApi()
  const history = useHistory()

  const { clearTablesSort } = useTableSort()
  const { resetAll } = usePageFiltersSorting()

  return () => {
    clearTablesSort()
    resetAll()
    isUnsaveFormData(false)
    isDirtyForm(false)
    localStorage.removeItem('guaranteedTab')
    webApi?.logout().then(() => {
      history.push(APP_PATHS.auth.login)
    })
  }
}
