import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'

const CssTextField = withStyles({
  // root: {
  //   '& .MuiInputBase-input': {
  //     padding: '12px 0 12px 12px',
  //   },
  // },
})(TextField)

export const CommonTextField: React.FC<TextFieldProps> = (props) => {
  return (
    <CssTextField
      variant="standard"
      margin="normal"
      size="small"
      required
      fullWidth={true}
      {...props}
    />
  )
}
