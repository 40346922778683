import * as yup from 'yup'

import { THIS_FIELD_IS_REQUIRED_MESSAGE } from '../../constants/validations'

export const DocumentTypeTransactionInputSchema = yup.object().shape({
  documentTypeTransaction: yup
    .string()
    .test('mandatoryTransaction', THIS_FIELD_IS_REQUIRED_MESSAGE, function () {
      return !this.parent.transactionDocumentRequired
    }),
})

export type DocumentTypeTransactionInput = yup.Asserts<typeof DocumentTypeTransactionInputSchema>
