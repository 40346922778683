import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { Box, FormControlLabel, Typography, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { FormRadioGroup, FormTextField, GridRow, BlackRadio } from '../../Common'
import { SignatoryRight } from '../../../graphql'

const useStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  genderWrapp: {
    margin: theme.spacing(5, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4, 0, 0, 0),
    },
  },
  radio: {
    '& .MuiRadio-root': {
      padding: theme.spacing(1, 1),
    },
  },
}))

interface HandleChange {
  target: { name?: string | undefined; value: number | unknown }
}

export const Authorised: FC<{
  hasControl?: boolean
  signatoryRight?: SignatoryRight | undefined | null
  groupSignature?: number | undefined | null
}> = ({ hasControl = true, signatoryRight, groupSignature }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { watch, setValue } = useFormContext()

  const signatory = watch('signatoryRight')

  useEffect(() => {
    if (signatoryRight) {
      setValue('signatoryRight', signatoryRight)
    }
    if (groupSignature) {
      setValue('groupSignature', groupSignature)
    }
  }, [signatoryRight, groupSignature, setValue])

  return (
    <Box className={classes.genderWrapp}>
      <Box className={classes.titleWrap}>
        <Typography variant={'h2'} className={classes.title}>
          {hasControl
            ? t('signatoryRights', 'Signatory Rights')
            : t('signatoryRightsWithFullAccess)', 'Signatory Rights (with Full Access)')}
        </Typography>
      </Box>
      <Box>
        <FormRadioGroup name="signatoryRight" value={signatory || SignatoryRight.Sole}>
          <Box mb={1.75} data-test="signatoryRightSole">
            <FormControlLabel
              value={SignatoryRight.Sole}
              control={<BlackRadio size="small" />}
              label={t('sole', 'Sole')}
              className={classes.radio}
            />
          </Box>
          <Box mb={1.75} data-test="signatoryRightDual">
            <FormControlLabel
              value={SignatoryRight.Joint}
              control={<BlackRadio size="small" />}
              label={t('jointDual', 'Joint (Dual)')}
              className={classes.radio}
            />
          </Box>
          <Box mb={3} data-test="signatoryRightGroup">
            <FormControlLabel
              value={SignatoryRight.Group}
              control={<BlackRadio size="small" />}
              label={t('jointGroup', 'Joint (Group)')}
              className={classes.radio}
            />
          </Box>
        </FormRadioGroup>
      </Box>

      <Box hidden={signatory !== 'group'}>
        <GridRow>
          <FormTextField
            label={t('groupSize', 'Group size')}
            name="groupSignature"
            type="number"
            fullWidth={true}
            required={false}
            onChange={useCallback(
              (e: HandleChange) => {
                const { value } = e.target
                setValue('groupSignature', +(value as string).replace(/\D+/g, ''))
              },
              [setValue],
            )}
            data-test="groupSignature"
          />
        </GridRow>
      </Box>
    </Box>
  )
}
