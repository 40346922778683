import React, { FC, useState } from 'react'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { isDirtyForm, isNavMenuRoute } from '../../graphql/local'
import { getModalStyle } from '../../utils'

export const usePromptStyles = makeStyles((theme: Theme) => ({
  // Modal
  modal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
  },
  modalHeader: {
    display: 'flex',
    padding: theme.spacing(3, 3, 2),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalContent: {
    padding: theme.spacing(0, 3),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
}))

export const CustomPrompt: FC<{
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}> = ({ open, onConfirm, onCancel }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = usePromptStyles()
  const { t } = useTranslation()

  const isNavigateAway = isNavMenuRoute()

  function handleConfirm() {
    isDirtyForm(false)
    isNavMenuRoute(false)
    onConfirm()
  }

  const handleCancel = () => {
    isNavMenuRoute(false)
    onCancel()
  }

  const confirmCustomPrompt = (
    <Box style={modalStyle} className={classes.modal} data-test="modalGoBack">
      <Box className={classes.modalHeader}>
        <Typography variant={'h4'}>
          {isNavigateAway
            ? t(
                'areYouSureYouWantToNavigateAwayFromThisPage',
                'Are you sure you want to navigate away from this page',
              )
            : t('areYouSureYouWantToGoBack', 'Are you sure you want to go back')}
          ?
        </Typography>
      </Box>

      <Box className={classes.modalContent}>
        <Typography>
          {t('allUnsubmittedDataWillBeLost', 'All unsubmitted data will be lost')}.
        </Typography>
      </Box>
      <Box className={classes.modalFooter}>
        <Button variant="contained" onClick={handleConfirm} data-test="modal-goBack-button">
          {isNavigateAway ? t('navigateAway', 'Navigate away') : t('goBack', 'Go back')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCancel}
          data-test="continueEditing"
        >
          {t('continueEditing', 'Continue editing')}
        </Button>
      </Box>
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {confirmCustomPrompt}
    </Modal>
  )
}
