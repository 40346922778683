import i18n from '../i18n'
export const DOCUMENTS_TYPE = [
  {
    value: '',
    label: '',
  },
  {
    value: 'proofOfResidence',
    label: i18n.t('proofOfResidence', 'Proof of address'),
  },
  {
    value: 'approvalRejectionForm',
    label: i18n.t('approvalRejectionForm', 'Approval Rejection Form'),
  },
  {
    value: 'bankCard',
    label: i18n.t('bankCard', 'Bank card'),
  },
  {
    value: 'bankPaymentDetails',
    label: i18n.t('bankPaymentDetails', 'Bank/Payment details'),
  },
  {
    value: 'bankStatement',
    label: i18n.t('bankStatement', 'Bank Statement'),
  },
  // {
  //   value: 'btcEthPop',
  //   label: 'BTC/ETH POP',
  // },
  {
    value: 'businessLicense',
    label: i18n.t('businessLicense', 'Business License'),
  },
  {
    value: 'cardCopies',
    label: i18n.t('creditDebitCardCopyFront', 'Credit/Debit Card Copy Front'),
  },
  {
    value: 'cardCopiesBack',
    label: i18n.t('creditDebitCardCopyBack', 'Credit/Debit Card Copy Back'),
  },
  {
    value: 'cardStatement',
    label: i18n.t('cardStatement', 'Card Statement'),
  },
  {
    value: 'certificateOfGoodStanding',
    label: i18n.t('certificateOfGoodStanding', 'Certificate of good standing'),
  },
  {
    value: 'certificateOfIncorporation',
    label: i18n.t('certificateOfIncorporation', 'Certificate of Incorporation'),
  },
  {
    value: 'certificateOfShareholders',
    label: i18n.t('certificateOfShareholders', 'Certificate of Shareholders'),
  },
  // {
  //   value: 'coinbasePop',
  //   label: 'Coinbase POP',
  // },
  {
    value: 'companiesRegistryExtract',
    label: i18n.t('companiesRegistryExtract', 'Companies registry extract'),
  },
  {
    value: 'drivingLicenseBack',
    label: i18n.t('drivingLicenceBack', 'Driving licence back'),
  },
  {
    value: 'drivingLicenseFront',
    label: i18n.t('drivingLicenceFront', 'Driving licence front'),
  },
  // {
  //   value: 'dueDiligence',
  //   label: 'Due Diligence',
  // },
  // {
  //   value: 'iBagreement',
  //   label: 'IB Agreement',
  // },
  {
    value: 'idBack',
    label: i18n.t('idBack', 'ID back'),
  },
  {
    value: 'idFront',
    label: i18n.t('idFront', 'ID front'),
  },
  {
    value: 'idTranslation',
    label: i18n.t('idTranslation', 'ID Translation'),
  },
  {
    value: 'jointAccountForm',
    label: i18n.t('jointAccountForm', 'Joint Account Form'),
  },
  {
    value: 'limitedPowerOfAttorney',
    label: i18n.t('limitedPowerOfAttorney', 'Limited power of attorney'),
  },
  {
    value: 'memorandumArticlesOfAssociation',
    label: i18n.t('memorandumArticlesOfAssociation', 'Memorandum & Articles of association'),
  },
  {
    value: 'passport',
    label: i18n.t('passport', 'Passport'),
  },
  {
    value: 'payslip',
    label: i18n.t('payslip', 'Payslip/Payroll'),
  },
  // {
  //   value: 'privateWallet',
  //   label: 'Private Wallet',
  // },
  // {
  //   value: 'professionalApplication',
  //   label: 'Professional Application',
  // },
  // {
  //   value: 'promotionalMaterial',
  //   label: 'Promotional Material',
  // },
  {
    value: 'proofOfAddressTranslation',
    label: i18n.t('proofOfAddressTranslation', 'POR Translation'),
  },
  {
    value: 'proofOfSignature',
    label: i18n.t('proofOfSignature', 'Proof of Signature'),
  },
  {
    value: 'residencePermitVisa',
    label: i18n.t('residencePermitVisa', 'Residence permit/visa'),
  },
  {
    value: 'revocationOfPoaSpoa',
    label: i18n.t(
      'revocationOfPoaSpoa',
      'Revocation of Power of Attorney / Special Power of Attorney',
    ),
  },
  {
    value: 'specialPowerOfAttorney',
    label: i18n.t('specialPowerOfAttorney', 'Special power of attorney'),
  },
  {
    value: 'swiftCopyPaymentAdvice',
    label: i18n.t('swiftCopyPaymentAdvice', 'Swift Copy/Payment Advice'),
  },
  {
    value: 'taxStatement',
    label: i18n.t('taxStatement', 'Tax Statement'),
  },
  {
    value: 'thirdPartyAuthorizationLetter',
    label: i18n.t('thirdPartyAuthorizationLetter', '3rd party authorization letter'),
  },
  {
    value: 'thirdPartyIdPassport',
    label: i18n.t('thirdPartyIdPassport', '3rd party ID/Passport'),
  },
  // {
  //   value: 'thirdPartyWc',
  //   label: '3rd Party WC',
  // },
  {
    value: 'utilityBill',
    label: i18n.t('utilityBill', 'Utility Bill'),
  },
  {
    value: 'virtualCard',
    label: i18n.t('virtualCard', 'Virtual card'),
  },
  {
    value: 'worldcheckResults',
    label: i18n.t('worldcheckResults', 'Worldcheck Results'),
  },
  {
    value: 'other',
    label: i18n.t('other', 'Other'),
  },
]
