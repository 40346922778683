import React, { FC, useMemo } from 'react'
import { businessCategoryQuestions } from '@klarpay/enums'
import { Box, FormControl, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { filter, isEmpty, isEqual, map, orderBy } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  anticipatedAccountActivitiesListValues,
  BusinessCategoriesList,
  economicProfileCompanySourceFundsTypesList,
  productsListValues,
  YesNoOptions,
} from '../../../utils/Data'
import {
  BaseEnumWithKey,
  FormAutocompleteMultiSelect,
  FormAutocompleteSelect,
  FormAutocompleteSelectMultiCustomValue,
  FormControlledTextField,
  GridRow,
} from '../../Common'
import { useCommonStyles } from './PreAssessmentQuestionnaire'
import {
  AnticipatedAccountActivities,
  ContractProfileChangeAnswers,
  Maybe,
  PreAssessment,
} from '../../../graphql'
import { getLabelByKey, getLabelsSeparatedByCommas } from '../../../utils'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'
import config from '../../../config'

const useStyles = makeStyles((theme) => ({
  longLabel: {
    [theme.breakpoints.down('md')]: {
      '& .MuiInputBase-input': {
        transform: `translate(0, 8px)`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& label': {
        transform: 'translate(0, 14px) scale(1)',
      },
      '& label.MuiFormLabel-filled': {
        transform: 'translate(0, 0px) scale(0.857)',
      },
      '& label.Mui-focused': {
        transform: 'translate(0, 0px) scale(0.857)',
      },
    },
  },
  minHeight: {
    '& .MuiInputBase-root': {
      minHeight: theme.spacing(7.5),
    },
  },
  dirtyControl: {
    position: 'relative',
  },
  dirtySpaceTop: {
    '& .MuiFormHelperText-root': {
      top: -3,
    },
  },
}))

const GeneralQuestionsComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ readOnly = false, changedContractProfileData }) => {
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { t } = useTranslation()

  const methods = useFormContext()
  const { watch, formState, control } = methods

  const businessSector = watch('businessSector')
  const businessRelationshipWithCompany = watch('businessRelationshipWithCompany')

  const businessSectorQuestions = useMemo(() => {
    return filter(businessCategoryQuestions, (question) => {
      return map(question.businessTypes, 'question.key').includes(businessSector)
    })
  }, [businessSector])

  const isBusinessSectorHidden = (value: keyof Maybe<PreAssessment>, key: string): boolean => {
    return (
      !changedContractProfileData?.preAssessment?.[value] ||
      watch(`businessSector`) !== changedContractProfileData?.businessSector ||
      formState.errors[`businessSector${key}`]
    )
  }

  return (
    <>
      <Box mt={4} className={classes.titleBox}>
        <Typography variant="h3" className={classes.title}>
          {t('generalQuestions', 'General Questions')}
        </Typography>
      </Box>
      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t(
              'describeWhatYouSellSellTo',
              'Please describe what you sell, whom you sell to, and when you charge your customers',
            )}
          </Typography>
        </Box>
      </GridRow>
      <GridRow>
        <FormControlledTextField
          label={t('businessDescription', 'Business Description')}
          name="businessActivitiesAndServices"
          type="text"
          fullWidth
          multiline
          required={false}
          data-test="businessActivitiesAndServices"
        />
        {changedContractProfileData?.businessDescription && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              !changedContractProfileData.businessDescription ||
              formState.errors.businessActivitiesAndServices
            }
            title={changedContractProfileData?.businessDescription ?? ''}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>
      <GridRow>
        <Controller
          name={'businessSector'}
          control={control}
          render={() => (
            <FormAutocompleteSelect
              name="businessSector"
              className={classes.boxInput}
              label={t('businessCategory', 'Business Category')}
              data={orderBy(
                BusinessCategoriesList.filter((c) => c.available),
                'label',
              )}
              readOnly={readOnly}
              data-test="businessCategoryParent"
            />
          )}
        />

        {changedContractProfileData?.businessSector && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={!changedContractProfileData.businessSector || formState.errors.businessSector}
            title={getLabelByKey(changedContractProfileData.businessSector, BusinessCategoriesList)}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>
      {businessSectorQuestions.map((question) => (
        <GridRow key={question.key}>
          {question.type === 'dropdown' ? (
            <>
              <Controller
                as={
                  <FormAutocompleteSelect
                    name={`businessSector${question.key}`}
                    className={`${classes.boxInput} ${
                      question.label.length > 60 && classes.longLabel
                    }`}
                    label={question.label}
                    data={YesNoOptions}
                    disabled={readOnly}
                  />
                }
                name={`businessSector${question.key}`}
                control={control}
              />
              {changedContractProfileData?.preAssessment?.[
                question.value as keyof Maybe<PreAssessment>
              ] && (
                <ControlledTooltipWrapped
                  wrapperClass={classes.dirtyControl}
                  hidden={
                    !changedContractProfileData?.preAssessment?.[
                      question.value as keyof Maybe<PreAssessment>
                    ]
                  }
                  title={getLabelByKey(
                    changedContractProfileData.preAssessment[
                      question.value as keyof Maybe<PreAssessment>
                    ],
                    YesNoOptions,
                  )}
                  arrowHorizontalPosition={'left'}
                />
              )}
            </>
          ) : (
            <>
              <FormControlledTextField
                label={question.label}
                name={`businessSector${question.key}`}
                type="text"
                fullWidth
                multiline
                className={`${classes.boxInput} ${question.label.length > 60 && classes.longLabel}`}
                required={false}
              />
              {changedContractProfileData?.preAssessment?.[
                question.value as keyof Maybe<PreAssessment>
              ] && (
                <ControlledTooltipWrapped
                  wrapperClass={classes.dirtyControl}
                  hidden={isBusinessSectorHidden(
                    question.value as keyof Maybe<PreAssessment>,
                    question.key,
                  )}
                  title={getLabelByKey(
                    changedContractProfileData.preAssessment[
                      question.value as keyof Maybe<PreAssessment>
                    ],
                    YesNoOptions,
                  )}
                  arrowHorizontalPosition={'left'}
                />
              )}
            </>
          )}
        </GridRow>
      ))}
      <GridRow>
        <Box>
          <FormAutocompleteMultiSelect
            className={clsx(classes.boxMultiSelect)}
            name="products"
            label={t('productsRequested', 'Products Requested')}
            data={productsListValues}
            readOnly={readOnly}
            data-test="products"
          />
          {changedContractProfileData?.economicProfile?.products && (
            <ControlledTooltipWrapped
              wrapperClass={`${classes.dirtyControl} ${classes.dirtySpaceTop}`}
              hidden={formState.errors.products}
              title={getLabelsSeparatedByCommas(
                changedContractProfileData.economicProfile.products,
                productsListValues,
              )}
              arrowHorizontalPosition={'left'}
            />
          )}
        </Box>
      </GridRow>
      <GridRow>
        <FormAutocompleteMultiSelect
          className={classes.boxMultiSelect}
          name="anticipatedAccountActivity"
          label={t('anticipatedAccountActivity', 'Anticipated Account Activities')}
          data={anticipatedAccountActivitiesListValues}
          readOnly={readOnly}
          data-test="anticipatedAccountActivity"
        />
      </GridRow>
      {!isEmpty(changedContractProfileData?.economicProfile?.anticipatedAccountActivity) && (
        <ControlledTooltipWrapped
          wrapperClass={`${classes.dirtyControl} ${classes.dirtySpaceTop}`}
          hidden={formState.errors.anticipatedAccountActivity}
          title={getLabelsSeparatedByCommas(
            changedContractProfileData?.economicProfile
              ?.anticipatedAccountActivity as AnticipatedAccountActivities[],
            anticipatedAccountActivitiesListValues,
          )}
          arrowHorizontalPosition={'left'}
        />
      )}
      <GridRow>
        <FormControl className={`${classes.boxInput} ${classes.minHeight}`}>
          <FormAutocompleteSelectMultiCustomValue
            name="sourceOfFunds"
            label={t('sourceOfFunds', 'Source of Funds')}
            data={economicProfileCompanySourceFundsTypesList}
            disabled={readOnly}
            data-test="sourceOfFunds"
          />
        </FormControl>
        {changedContractProfileData?.economicProfile?.sourceOfFunds && (
          <ControlledTooltipWrapped
            wrapperClass={`${classes.dirtyControl} ${classes.dirtySpaceTop}`}
            hidden={formState.errors.sourceOfFunds}
            title={`${getLabelsSeparatedByCommas(
              changedContractProfileData.economicProfile.sourceOfFunds,
              economicProfileCompanySourceFundsTypesList as BaseEnumWithKey[],
            )} ${
              changedContractProfileData.economicProfile?.sourceOfFundsDescription
                ? `, ${changedContractProfileData.economicProfile.sourceOfFundsDescription}`
                : ''
            }`}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>
      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t(
              'pleaseProvidePaymentProviders',
              "Please list the Banks, Electronic Money Institutions (EMI's) and payment providers you currently work with",
            )}
          </Typography>
        </Box>
      </GridRow>
      <GridRow>
        <FormControlledTextField
          className={clsx(classes.boxInput)}
          label={t(
            'paymentProviders',
            "Banks, Electronic Money Institutions (EMI's) and payment providers",
          )}
          name="paymentProviders"
          type="text"
          fullWidth
          multiline
          required={false}
          data-test="paymentProviders"
        />
        {changedContractProfileData?.preAssessment?.paymentProviders && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.paymentProviders}
            title={changedContractProfileData.preAssessment.paymentProviders}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>
      <GridRow>
        <FormAutocompleteSelect
          className={clsx(classes.boxInput)}
          name="businessRelationshipWithCompany"
          label={t(
            'businessRelationship',
            'Do you hold other business relationships with Klarpay?',
            {
              val1: config.name,
            },
          )}
          data={YesNoOptions}
          disabled={readOnly}
        />
        {changedContractProfileData?.preAssessment?.businessRelationshipWithCompany && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.businessRelationshipWithCompany}
            title={getLabelByKey(
              changedContractProfileData.preAssessment.businessRelationshipWithCompany,
              YesNoOptions,
            )}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>
      {businessRelationshipWithCompany === 'Yes' && (
        <GridRow>
          <FormControlledTextField
            className={clsx(classes.boxInput)}
            label={t('pleaseSpecify', 'Please specify')}
            name="businessRelationshipWithCompanyExplanation"
            type="text"
            fullWidth
            multiline
            required={false}
            data-test="businessRelationshipWithCompanyExplanation"
            disabled={readOnly}
          />
          {changedContractProfileData?.preAssessment
            ?.businessRelationshipWithCompanyExplanation && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.businessRelationshipWithCompanyExplanation}
              title={
                changedContractProfileData.preAssessment.businessRelationshipWithCompanyExplanation
              }
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}
    </>
  )
}
export const GeneralQuestions = React.memo(GeneralQuestionsComponent, isEqual)
