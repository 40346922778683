import * as yup from 'yup'
import {
  DocAddressInputSchema,
  DocCorrespondenceAddressInputSchema,
  DocLegalNameInputSchema,
} from './common'
import { DocTaxIdInputSchema } from './common/DocTaxId'

export const ContractManagementProfileDocsInputSchema = DocAddressInputSchema.concat(
  DocCorrespondenceAddressInputSchema,
)
  .concat(DocLegalNameInputSchema)
  .concat(DocTaxIdInputSchema)
export type ContractManagementProfileDocsInput = yup.Asserts<
  typeof ContractManagementProfileDocsInputSchema
>
