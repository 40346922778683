import { documentTypes, TypeOfEnum } from '@klarpay/enums'
import * as fileSelector from 'file-selector'
import React, { useCallback, useMemo } from 'react'
import Dropzone, { IDropzoneProps, IPreviewProps } from 'react-dropzone-uploader'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MAX_FILE_SIZE_BYTES } from '../../../../constants'
import {
  DocumentRelatedEntityType,
  DocumentType,
  useGetContractLegalEntityQuery,
  useGetContractRelatedIndividualsQuery,
  useGetSignedUrlMutation,
} from '../../../../graphql'
import '../../../PersonalProfile/Tabs/styles.css'
import { Input } from './Input'
import { Layout } from './Layout'
import { Preview } from './Preview'

const getFilesFromEvent = async (
  e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
) => {
  return (await fileSelector.fromEvent(e)).filter((file) =>
    'getAsFile' in file ? file.getAsFile() : file,
  ) as File[]
}

export type Document = {
  docType?: TypeOfEnum<typeof documentTypes>['key']
  relatesTo?: string
  expiryDate?: Date
}

export enum HandlerType {
  chooseDocTypeHandler = 'chooseDocTypeHandler',
  chooseRelatesToHandler = 'chooseRelatesToHandler',
  chooseFileDescriptionToHandler = 'chooseFileDescriptionToHandler',
  chooseDatePickerHandler = 'chooseDatePickerHandler',
}

export type DocumentUploadProps = {
  contractId: string | number
  docType?: DocumentType | null
  uploadComplete?: () => void
}

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
  docType,
  contractId,
  uploadComplete,
}) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  const { data: individuals } = useGetContractRelatedIndividualsQuery({
    variables: { contractId },
  })

  const { data: legalEntity } = useGetContractLegalEntityQuery({
    variables: { contractId },
  })

  const [getSignedUrl] = useGetSignedUrlMutation()

  const getUploadParams = useMemo<IDropzoneProps['getUploadParams']>(
    () => async ({ file }: { file: File }) => {
      const r = await getSignedUrl()
      const url = r.data?.getSignedUrl?.signedUrl
      if (!url) {
        throw new Error(
          t('failedToUploadFile', 'Failed to upload {{file}}. Please try again later', {
            file: file.name,
          }),
        )
      }

      return {
        meta: { fileUrl: r.data?.getSignedUrl?.plainUrl },
        url,
        body: file,
        method: 'PUT',
      }
    },
    [getSignedUrl],
  )

  const relatesToData = useMemo(
    () =>
      [
        ...(legalEntity?.contract && 'id' in legalEntity.contract?.owner
          ? [
              {
                key: String(legalEntity.contract?.owner.id),
                label: legalEntity.contract.owner.companyName,
                type: DocumentRelatedEntityType.LegalEntity,
              },
            ]
          : []),
        ...(individuals?.getContractRelatedIndividuals
          ?.map((i) => ({
            key: String(i?.id),
            label: `${i?.firstName} ${i?.lastName}`,
            type: DocumentRelatedEntityType.Individual,
          }))
          ?.sort((a, b) => -b.label.localeCompare(a.label)) ?? []),
      ] as { key: string; label: string; type: DocumentRelatedEntityType }[],
    [individuals, legalEntity],
  )

  const handleChangeStatus = useCallback<Required<IDropzoneProps>['onChangeStatus']>(
    (file, status, allFiles) => {
      if (status === 'ready') {
        setValue(`documents.${file.meta.id}.file`, file)
      } else if (status === 'done') {
        if (allFiles.filter((f) => f.meta.status !== 'done').length === 0) {
          uploadComplete?.()
        }
      }
    },
    [setValue],
  )

  const PreviewHoC = useMemo(
    () =>
      function PreviewComponent(props: IPreviewProps) {
        return <Preview {...props} relatesToData={relatesToData} defaultDocType={docType} />
      },
    [relatesToData],
  )

  return (
    <Dropzone
      maxFiles={100}
      maxSizeBytes={MAX_FILE_SIZE_BYTES}
      getUploadParams={getUploadParams}
      getFilesFromEvent={getFilesFromEvent}
      onChangeStatus={handleChangeStatus}
      PreviewComponent={PreviewHoC}
      LayoutComponent={Layout}
      accept=".jpg,.jpeg,.png,.pdf"
      InputComponent={Input}
      autoUpload={false}
    />
  )
}
