import { find } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PATH_PARAMS } from '../routes/paths'
import { useCurrentUser } from './useCurrentUser'

export const useGetBalanceCurrency = () => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const currentUser = useCurrentUser()

  const currentContractAuthPerson = useMemo(() => {
    if (!applicationId) return {}
    const contract = find(currentUser?.contracts, (c) => c?.id === Number(applicationId))
    return find(contract?.authorizedPersons, (p) => p?.person?.id === currentUser?.id)
  }, [currentUser, applicationId])

  return currentContractAuthPerson?.balanceCurrency
}
