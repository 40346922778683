import React, { FC, useMemo } from 'react'
import { Box, Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ActionStatus } from '../../../graphql'
import { useStyles } from './useStyles'
import { AccountProps } from '../contract'
import { SignaturesBox, SignaturesTable } from './helpers'

export const AccountTab: FC<{ data: AccountProps; signedCount: number }> = ({
  data,
  signedCount,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isShowReason = useMemo(
    () => data.reason && data.rawData?.status !== ActionStatus.Rejected,
    [data],
  )

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('iban', 'IBAN')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{data.iban}</Box>
              </TableCell>
            </TableRow>
            {isShowReason && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('reason', 'Reason')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">{t(data.reason as string)}</Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <SignaturesTable signatures={data.signatures} signedCount={signedCount} />
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={`${classes.mobileTableItem} ${classes.mobileTableBorderTop}`}>
              <Box className={classes.mobileTableLabel}>{t('iban', 'IBAN')}</Box>
              <Box className={classes.mobileTableName}>{data.iban}</Box>
            </Box>
            {isShowReason && (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('reason', 'Reason')}</Box>
                <Box className={classes.mobileTableName}>{t(data.reason as string)}</Box>
              </Box>
            )}
            <SignaturesBox signatures={data.signatures} signedCount={signedCount} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
