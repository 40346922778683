import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from '../Common'
import { TableColumnExplained } from './TableColumnExplained'

const InformBlockComponent: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <InfoBlock>
        {t(
          'batchCurrencyInfo',
          "Validation of the payment's bank details only occurs when uploaded to the User's dashboard. (i.e. data validation in this file does not confirm whether an IBAN is valid).",
        )}
      </InfoBlock>
      <Grid item xs={12}>
        <TableColumnExplained />
      </Grid>
    </>
  )
}

export const InformationBlock = React.memo(InformBlockComponent)
