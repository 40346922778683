import React, { ClipboardEventHandler, FC, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { CommonTextField } from '../Fields'
import { IInputCodeField } from './type'
import { isEmpty } from 'lodash'

export const InputCodeField: FC<IInputCodeField> = ({
  setCode,
  currencyValue,
  loading,
  startInput,
  className,
  modalId,
}) => {
  const handleClickBackspace = useCallback((event) => {
    if (event && event.key === 'Backspace') {
      const { id, value } = event.target
      const nextInputId = value === '' ? +id[1] - 1 : +id[1]
      const nextInput = document.getElementById(`d${nextInputId}`)
      nextInput && nextInput.focus()
    }
  }, [])

  const handleChange = useCallback(
    (event) => {
      const { id, value } = event.target

      if (['d1', 'd2', 'd3', 'd4', 'd5', 'd6'].includes(id)) {
        const nextInputId = +id[1] + 1
        const domElement = !!modalId ? document.getElementById(modalId) : document
        const nextInput = domElement?.querySelector(`#d${nextInputId}`) as HTMLElement
        setCode(id, value)
        if (!isEmpty(value)) {
          nextInput && nextInput.focus()
        }
      }
    },
    [setCode],
  )

  const handlePaste = useCallback<ClipboardEventHandler>(
    (event) => {
      event.preventDefault()
      const pastedText = event.clipboardData.getData('Text')
      if (pastedText.length === 6) {
        for (let i = 1; i <= pastedText.length; i++) {
          setCode('d' + i, pastedText[i - 1])
        }
        const nextInput = document.getElementById(`d6`)
        nextInput && nextInput.focus()
      }
    },
    [setCode],
  )

  return (
    <Box
      display="flex"
      gridGap={10}
      justifyContent="space-between"
      alignItems="center"
      onPaste={handlePaste}
    >
      <CommonTextField
        className={className || 'digits'}
        autoFocus
        id="d1"
        value={currencyValue.d1}
        type="text"
        disabled={loading}
        inputProps={{ inputMode: 'numeric', maxLength: 1 }}
        onChange={handleChange}
        inputRef={startInput}
        onKeyDown={handleClickBackspace}
        data-test="d1"
      />
      <CommonTextField
        className={className || 'digits'}
        id="d2"
        value={currencyValue.d2}
        type="text"
        disabled={loading}
        inputProps={{ inputMode: 'numeric', maxLength: 1 }}
        onChange={handleChange}
        onKeyDown={handleClickBackspace}
        data-test="d2"
      />
      <CommonTextField
        className={className || 'digits'}
        id="d3"
        value={currencyValue.d3}
        type="text"
        disabled={loading}
        inputProps={{ inputMode: 'numeric', maxLength: 1 }}
        onChange={handleChange}
        onKeyDown={handleClickBackspace}
        data-test="d3"
      />
      <CommonTextField
        className={className || 'digits'}
        id="d4"
        value={currencyValue.d4}
        type="text"
        disabled={loading}
        inputProps={{ inputMode: 'numeric', maxLength: 1 }}
        onChange={handleChange}
        onKeyDown={handleClickBackspace}
        data-test="d4"
      />
      <CommonTextField
        className={className || 'digits'}
        id="d5"
        value={currencyValue.d5}
        type="text"
        disabled={loading}
        inputProps={{ inputMode: 'numeric', maxLength: 1 }}
        onChange={handleChange}
        onKeyDown={handleClickBackspace}
        data-test="d5"
      />
      <CommonTextField
        className={className || 'digits'}
        id="d6"
        value={currencyValue.d6}
        type="text"
        disabled={loading}
        inputProps={{ inputMode: 'numeric', maxLength: 1 }}
        onChange={handleChange}
        onKeyDown={handleClickBackspace}
        data-test="d6"
      />
    </Box>
  )
}
