import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 21,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& .MuiPagination-ul': {
      flexWrap: 'nowrap',
    },
  },
  paginationText: {
    fontSize: '0.875rem',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

const PagePaginationComponent: FC<{
  page: number
  totalItems?: number | null
  handleChange: (event: ChangeEvent<unknown>, page: number) => void
  itemsPerPage?: number
  shape?: 'rounded' | 'round'
  variant?: 'text' | 'outlined'
  color?: 'secondary' | 'primary' | 'standard'
}> = ({
  page,
  totalItems,
  handleChange,
  itemsPerPage = 10,
  shape = 'rounded',
  variant = 'outlined',
  color = 'secondary',
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [itemsCount, setItemsCount] = useState<number>(0)
  const [pagesCount, setPagesCount] = useState<number>(1)
  const fromEntry = page === 1 ? 1 : itemsPerPage * page - (itemsPerPage - 1)
  const toEntry = page !== pagesCount ? itemsPerPage * page : itemsCount
  useEffect(() => {
    if (totalItems) {
      const pagesCount = Math.ceil(totalItems / itemsPerPage)
      setItemsCount(totalItems)
      setPagesCount(pagesCount)
    } else {
      setItemsCount(0)
      setPagesCount(0)
    }
  }, [totalItems])

  if (pagesCount > 1) {
    return (
      <Grid item className={classes.pagination}>
        <span
          className={classes.paginationText}
          data-test="showingEntries"
          data-from-entry={fromEntry}
          data-to-entry={toEntry}
          data-all-entries={itemsCount}
        >
          {t('showingEntries', {
            fromEntry,
            toEntry,
            itemsCount,
          })}
        </span>
        <Box>
          <Pagination
            count={pagesCount}
            color={color}
            variant={variant}
            shape={shape}
            page={page}
            onChange={handleChange}
            data-test="pagination"
          />
        </Box>
      </Grid>
    )
  }
  return null
}

const PagePagination = React.memo(PagePaginationComponent, isEqual)
export default PagePagination
