import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { useFormContext } from 'react-hook-form'
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  ILayoutProps,
  IUploadParams,
  StatusValue,
} from 'react-dropzone-uploader'
import { isEmpty } from 'lodash'

import { Box, makeStyles, Theme } from '@material-ui/core'

import {
  BatchInput,
  BatchPreview,
  BatchPreviewInvalid,
  BatchUploadProgress,
  InformationBlock,
} from '../components'
import {
  BatchPaymentFileValidation,
  useGetBatchFileUploadUrlMutation,
  useValidateBatchFileMutation,
} from '../graphql'
import { AlertDataType, BatchPaymentInfo, BatchStatus, IFileWithWidenedMeta } from '../types'
import '../components/NewPerson/Forms/styles.css'
import { validPaymentsCount } from '../graphql/local/dashboard'
import {
  batchErrAlert,
  batchErrCurrencyMismatch,
  batchErrFileType,
  batchOversizedAlert,
} from '../utils/BatchErrMsg'

const useStyles = makeStyles((theme: Theme) => ({
  dzuInputLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: 0,
    bottom: 0,
    left: 50,
    right: 0,
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    color: '#ccc',
    cursor: 'pointer',
  },
  DzuInput: {
    '& .dzu-dropzone': {
      height: 120,
      border: '1px solid #f0f0f0',
      overflow: 'hidden',
    },
  },
  modal: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

export const UploadBatchFile: FC<{
  isOpenAlert: boolean
  setIsOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
  setAlertData: React.Dispatch<React.SetStateAction<AlertDataType>>
  setPaymentReady: React.Dispatch<React.SetStateAction<boolean>>
  setIsBatchValid: React.Dispatch<React.SetStateAction<boolean>>
  setInfoPayment: React.Dispatch<React.SetStateAction<BatchPaymentInfo | undefined>>
}> = ({
  isOpenAlert,
  setIsOpenAlert,
  setAlertData,
  setPaymentReady,
  setInfoPayment,
  setIsBatchValid,
}) => {
  const classes = useStyles()
  const { setValue } = useFormContext()
  const [batchFile, setBatchFile] = useState<IFileWithWidenedMeta | null>(null)
  const [getBatchFileUploadUrlMutation, { data: urlData }] = useGetBatchFileUploadUrlMutation()
  const [validateBatchFileMutation, { data: validationResult }] = useValidateBatchFileMutation()

  const accountId = localStorage.getItem('selectedAccountId')

  const transformedValidationStatus = useMemo(() => {
    if ((isEmpty(validationResult?.validateBatchPaymentFile) || !batchFile) && !isOpenAlert) {
      return null
    } else if (
      validationResult?.validateBatchPaymentFile?.valid &&
      !isEmpty(validationResult?.validateBatchPaymentFile?.paymentRecords)
    ) {
      if (
        !validationResult?.validateBatchPaymentFile?.paymentRecords?.every(
          (record) =>
            record?.currency?.toUpperCase() ===
            validationResult?.validateBatchPaymentFile?.currency?.toUpperCase(),
        )
      ) {
        return BatchStatus.Unrecognized
      }
      const foundErrors = validationResult?.validateBatchPaymentFile?.paymentRecords?.some(
        (item) => !isEmpty(item?.errors),
      )
      return foundErrors ? BatchStatus.Invalid : BatchStatus.Valid
    } else if (
      !validationResult?.validateBatchPaymentFile?.valid &&
      !isEmpty(validationResult?.validateBatchPaymentFile?.paymentRecords)
    ) {
      return BatchStatus.Invalid
    } else {
      return BatchStatus.Unrecognized
    }
  }, [validationResult, isOpenAlert, batchFile])

  const getUploadParams: ({
    meta,
    file,
  }: IFileWithMeta) => IUploadParams | Promise<IUploadParams> = ({ file }: IFileWithMeta) => {
    return new Promise((resolve) => {
      getBatchFileUploadUrlMutation().then((r) => {
        const url = r.data?.getBatchPaymentFileSignedUrl?.signedUrl as string
        const urlObj = new URL(url)
        const fields = {
          'X-Amz-Algorithm': urlObj.searchParams.get('X-Amz-Algorithm') as string,
          'X-Amz-Credential': urlObj.searchParams.get('X-Amz-Credential') as string,
          'X-Amz-Date': urlObj.searchParams.get('X-Amz-Date') as string,
          'X-Amz-Expires': urlObj.searchParams.get('X-Amz-Expires') as string,
          'X-Amz-Signature': urlObj.searchParams.get('X-Amz-Signature') as string,
          'X-Amz-SignedHeaders': urlObj.searchParams.get('X-Amz-SignedHeaders') as string,
          'x-amz-acl': urlObj.searchParams.get('x-amz-acl') as string,
        }
        resolve({
          fields,
          meta: { fileUrl: r.data?.getBatchPaymentFileSignedUrl?.plainUrl },
          url,
          method: 'PUT',
          body: file,
        })
      })
    })
  }

  const handleBatchValidation = useCallback(
    (file) => {
      validateBatchFileMutation({
        variables: {
          fileUrl: urlData?.getBatchPaymentFileSignedUrl?.plainUrl as string,
          accountId: String(accountId),
        },
      })
        .then((r) => {
          if (!isEmpty(r.data?.validateBatchPaymentFile?.paymentRecords)) {
            if (
              !r.data?.validateBatchPaymentFile?.paymentRecords?.every(
                (record) =>
                  record?.currency?.toUpperCase() ===
                  r.data?.validateBatchPaymentFile?.currency?.toUpperCase(),
              )
            ) {
              setAlertData(batchErrCurrencyMismatch)
              setIsOpenAlert(true)
              file.remove()
              return
            }
            setValue('fileUrl', urlData?.getBatchPaymentFileSignedUrl?.plainUrl)
          } else {
            file.remove()
          }
        })
        .catch(() => {
          setIsOpenAlert(true)
          file.remove()
        })
    },
    [urlData, accountId],
  )

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (
    file: IFileWithWidenedMeta,
    status: StatusValue,
  ) => {
    if (file.meta.type !== 'text/csv') {
      setAlertData(batchErrFileType)
      setIsOpenAlert(true)
    }
    if (status === 'error_file_size') {
      file.remove()
      setBatchFile(null)
    }
    if (status === 'done') {
      setIsOpenAlert(false)
      setAlertData(batchErrAlert)
      setBatchFile(file)
    } else if (status === 'error_upload') {
      setIsOpenAlert(true)
      file.remove()
      setBatchFile(null)
    }
  }

  const getFilesFromEvent = (
    event: React.DragEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
  ): File[] | Promise<File[]> => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(event).then(
        (
          chosenFiles: {
            fileObject: File
            fullPath: string
            lastModified: string
            lastModifiedDate: string
            name: string
            size: number
            type: string
            webkitRelativePath: string
          }[],
        ) => {
          resolve(chosenFiles.slice(0, 1).map((f) => f.fileObject))
        },
      )
    })
  }

  const Layout = ({ input, dropzoneProps, files }: ILayoutProps) => {
    function removeFileHandler() {
      !!files && files.forEach((f) => f.remove())
      setPaymentReady(false)
      validPaymentsCount(0)
      setIsOpenAlert(false)
      setBatchFile(null)
    }

    const children = () => {
      switch (transformedValidationStatus) {
        case BatchStatus.Valid:
          return <BatchPreview {...validationResult?.validateBatchPaymentFile} />
        case BatchStatus.Invalid:
          return (
            <BatchPreviewInvalid
              data={validationResult?.validateBatchPaymentFile as BatchPaymentFileValidation}
              removeFileHandler={removeFileHandler}
            />
          )
        default:
          return (
            <>
              <InformationBlock />
              <div {...dropzoneProps}>
                {!files[0] ? input : <BatchUploadProgress {...files[0]?.meta} />}
              </div>
            </>
          )
      }
    }
    return (
      <Box className={classes.DzuInput} flexGrow={1}>
        {children()}
      </Box>
    )
  }

  useEffect(() => {
    if (batchFile && accountId) {
      setIsOpenAlert(false)
      handleBatchValidation(batchFile)
    }
  }, [accountId, batchFile])

  useEffect(() => {
    if (
      transformedValidationStatus === BatchStatus.Valid ||
      transformedValidationStatus === BatchStatus.Invalid
    ) {
      setIsBatchValid(transformedValidationStatus === BatchStatus.Valid)
      setPaymentReady(true)
    } else if (transformedValidationStatus === BatchStatus.Unrecognized) {
      if (validationResult?.validateBatchPaymentFile?.oversized) setAlertData(batchOversizedAlert)
      setIsOpenAlert(true)
    } else {
      setIsOpenAlert(false)
    }
    setInfoPayment({
      calculatedTotal: validationResult?.validateBatchPaymentFile?.calculatedTotal,
      count: validationResult?.validateBatchPaymentFile?.count as number,
      currency: validationResult?.validateBatchPaymentFile?.currency,
    })
  }, [transformedValidationStatus])

  return (
    <Box className={classes.modal}>
      <Box>
        <Dropzone
          getUploadParams={getUploadParams}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={1}
          // maxSizeBytes={15728640}
          classNames={{ inputLabel: classes.dzuInputLabel }}
          onChangeStatus={handleChangeStatus}
          LayoutComponent={Layout}
          disabled={(files) =>
            files.some((f) =>
              ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status),
            )
          }
          accept=".csv"
          InputComponent={BatchInput}
          autoUpload={true}
          multiple={false}
        />
      </Box>
    </Box>
  )
}
