import * as yup from 'yup'

import {
  LATINICA_ONLY_MESSAGE,
  LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD,
} from '../constants/validations'

export const TerminateContractInputSchema = yup.object().shape({
  terminationReasonOptional: yup
    .string()
    .notRequired()
    .nullable()
    .test('latinLettersOnly', LATINICA_ONLY_MESSAGE, (val) => {
      if (!!val) {
        return !!val.trim().match(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD)
      }
      return true
    }),
})

export type TerminateContractInputInput = yup.Asserts<typeof TerminateContractInputSchema>
