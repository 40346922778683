import React, { ReactNode } from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { ApolloProvider } from '@apollo/client'

import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import './i18n'

import * as serviceWorker from './serviceWorker'
import { getApolloClient } from './graphql'
import { WebApi, WebApiContext } from './services/web-api'
import { defaultTheme, useGlobalStyles } from './theme/default'
import { App } from './App'

export const apolloClient = getApolloClient()
const webApi = new WebApi()

interface ThemeProviderProps {
  children: ReactNode
  // any other props that come into the component
}

function AppThemeProvider({ children }: ThemeProviderProps) {
  useGlobalStyles()
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}

render(
  <React.StrictMode>
    <CssBaseline />
    <ToastContainer />
    <AppThemeProvider>
      <ApolloProvider client={apolloClient}>
        <WebApiContext.Provider value={{ webApi }}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </WebApiContext.Provider>
      </ApolloProvider>
    </AppThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
