type Config = {
  isWhitelabel: boolean
  apiUrl: string
  subscriptionsUri: string
  logo: string
  name: string
  brand: string
  requiresInvite: boolean
  signupCodeRegex: RegExp
  isIndividualSignupEnabled: boolean
  isGuaranteedDepositAccountEnabled: boolean
  isPaymentFacilitatorEnabled: boolean
  allowedCardCurrencies: string[]
  clientInformationDocumentLink: string
  images: {
    default: string
    error: string
    signIn: string
    resetPassword: string
    emailVerification: string
    restorePassword?: string
    twoFA?: string
    unusual?: string
    invitedSignUp?: string
    signUp?: string
    partnerSignUp?: string
    favicon?: string
  }
}

function getConfig() {
  const config: Config = {
    isWhitelabel: false,
    apiUrl: process.env.REACT_APP_API_URL || '',
    subscriptionsUri: process.env.REACT_APP_SUBSCRIPTIONS_API_URL || '',
    logo: '/img/logo 1.svg',
    name: 'Klarpay',
    brand: 'Klarpay AG',
    requiresInvite: false,
    signupCodeRegex: /KP-INVITE-\d{4}/,
    isIndividualSignupEnabled: process.env.REACT_APP_INDIVIDUAL_SIGNUP_ENABLED === 'true',
    isGuaranteedDepositAccountEnabled:
      process.env.REACT_APP_GUARANTEED_DEPOSIT_ACCOUNT_ENABLED === 'true',
    isPaymentFacilitatorEnabled: process.env.REACT_APP_PF_ENABLED === 'true',
    clientInformationDocumentLink: process.env.REACT_APP_CLIENT_INFORMATION_DOCUMENT_LINK || '',
    allowedCardCurrencies: process.env.REACT_APP_ALLOWED_CARD_CURRENCIES
      ? process.env.REACT_APP_ALLOWED_CARD_CURRENCIES.split(',')
      : ['EUR', 'USD'],
    images: {
      default: require('../assets/images/img/city.jpg'),
      error: require('../assets/images/img/error-page-foto.jpg'),
      signIn: require('../assets/images/img/signin-img-login.jpeg'),
      resetPassword: require('../assets/images/img/reset_psw_img.jpg'),
      emailVerification: require('../assets/images/img/signup-img3.jpg'),
    },
  }
  if (
    process.env.REACT_APP_CXPAY_URL_PATTERN &&
    new RegExp(process.env.REACT_APP_CXPAY_URL_PATTERN).test(window.location.host)
  ) {
    config.isWhitelabel = true
    config.apiUrl = process.env.REACT_APP_CXPAY_API_URL || ''
    config.subscriptionsUri = process.env.REACT_APP_SUBSCRIPTIONS_CXPAY_API_URL || ''
    config.logo = '/img/cxpay_logo.svg'
    config.name = 'Cellxpay'
    config.brand = 'Cellxpay'
    config.requiresInvite = true
    config.signupCodeRegex = /CX-INVITE-\d{4}/
    config.isIndividualSignupEnabled =
      process.env.REACT_APP_CXPAY_INDIVIDUAL_SIGNUP_ENABLED === 'true'
    config.isGuaranteedDepositAccountEnabled =
      process.env.REACT_APP_GUARANTEED_DEPOSIT_ACCOUNT_ENABLED === 'true'
    config.isPaymentFacilitatorEnabled = process.env.REACT_APP_PF_ENABLED === 'true'
    config.clientInformationDocumentLink =
      process.env.REACT_APP_CLIENT_INFORMATION_DOCUMENT_LINK || ''
    config.images.default = require('../assets/images/img/default_cxpay.jpg')
    config.images.error = require('../assets/images/img/error-page-foto-cxpay.jpg')
    config.images.signIn = require('../assets/images/img/signn-img-login-cxpay.jpg')
    config.images.restorePassword = require('../assets/images/img/default_cxpay.jpg')
    config.images.resetPassword = require('../assets/images/img/default_cxpay.jpg')
    config.images.emailVerification = require('../assets/images/img/default_cxpay.jpg')
    config.images.twoFA = require('../assets/images/img/default_cxpay.jpg')
    config.images.unusual = require('../assets/images/img/default_cxpay.jpg')
    config.images.invitedSignUp = require('../assets/images/img/signn-img-login-cxpay.jpg')
    config.images.signUp = require('../assets/images/img/error-page-foto-cxpay.jpg')
    config.images.partnerSignUp = require('../assets/images/img/error-page-foto-cxpay.jpg')
    config.images.favicon = 'cxpay_favicon.png'
  }

  return config
}

const config = getConfig()
export default config
