import React from 'react'
import i18n from '../../../i18n'

interface ErrorObj {
  response?: {
    status: number
    data: {
      error: string
    }
    statusText: string
  }
  message?: string
  request: never
}

interface ErrorState {
  code: string
  text: string
  description: string
}
export const errorHandler = (
  err: ErrorObj,
  errState: ErrorState,
  setErrState: React.Dispatch<React.SetStateAction<ErrorState>>,
): void => {
  if (err.response) {
    // client received an error response (5xx, 4xx)
    let descriptionUpdated = err.response.statusText

    if (err.response.status === 404 || err.response.status === 400) {
      descriptionUpdated = i18n.t(
        'weAreSorryThisPageDoesntExist',
        "We are sorry - this page doesn't exist",
      )
      setErrState(
        (): ErrorState => ({
          ...errState,
          code: `404`,
          text: 'Page not found',
          description: descriptionUpdated,
        }),
      )
      return
    }

    if (err.response.status === 500) {
      descriptionUpdated = i18n.t(
        'weAreWorkingToSolveTheProblemSorryForTheInconvenience',
        'We are working to solve the problem, sorry for the inconvenience',
      )
    }

    setErrState(
      (): ErrorState => ({
        ...errState,
        // code: `${err.response?.status}`,
        code: `404`,
        text: err.response?.data.error as string,
        description: descriptionUpdated,
      }),
    )
  } else if (err.request) {
    // client never received a response, or request never left
    setErrState(
      (): ErrorState => ({
        ...errState,
        description: err.message as string,
      }),
    )
  } else {
    setErrState(
      (): ErrorState => ({
        ...errState,
        description: err.message as string,
      }),
    )
  }
}
