import * as yup from 'yup'
import { OpenReasonList } from '../utils/Data'
import { THIS_FIELD_IS_REQUIRED_MESSAGE } from '../constants/validations'
import {
  EmailInputSchema,
  NameInputSchema,
  PasswordInputSchema,
  PrivacyAcceptanceInputSchema,
  RepeatPasswordInputSchema,
} from './common'

export const SignupFormInputSchema = yup
  .object()
  .shape({
    openReason: yup.string().oneOf(OpenReasonList, THIS_FIELD_IS_REQUIRED_MESSAGE),
    country: yup.string(),
  })
  .concat(PrivacyAcceptanceInputSchema)
  .concat(NameInputSchema)
  .concat(EmailInputSchema)
  .concat(PasswordInputSchema)
  .concat(RepeatPasswordInputSchema)

export type SignupFormInput = yup.Asserts<typeof SignupFormInputSchema>
