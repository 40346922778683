import { makeVar } from '@apollo/client'

import {
  initCardsPageState,
  initClientsOverviewPageState,
  initClientsPageState,
  initGuaranteeEventsPageState,
  initialAccountsPageState,
  initialActionsPageState,
  initialDocsPageState,
  initialPaymentsPageState,
  initialSuccessFeePageState,
  initialStandingOrdersPageState,
  initialTeamPageState,
  initialTransactionPageState,
  initVbansPageState,
  StepperTypes,
} from '../../constants'
import {
  ActionsPageStateType,
  BeneficiaryPageStateType,
  CardsPageStateType,
  DocumentPageStateType,
  TrxPageStateType,
  TeamPageStateType,
  VbansPageStateType,
  BeneficiaryOverviewPageStateType,
  AccountsPageStateType,
  SuccessFeesPageStateType,
  GuaranteeEventPageStateType,
  StandingOrdersPageStateType,
  PaymentsPageStateType,
  ControllingPersonsTypeEnum,
  CONTROLLING_PERSON_KEY,
} from '../../types'

// reactive variables for hide stepper after step Review
export const goNextPathVar = makeVar('')
export const goBackPathVar = makeVar('')
export const goBackVbanPathVar = makeVar('')

// reactive variables for allow step logic on forms edit in Review
export const stepperHeaderTextVar = makeVar('')
export const stepperTypeVar = makeVar<StepperTypes | undefined>(undefined)
export const stepperCurrentStepVar = makeVar<string>('')

// timeout delay for send actions
export const resendDelay = makeVar(180000)
export const resendDelaySms = makeVar(180000) //3 minutes

// forms unsave data control
export const isUnsaveFormData = makeVar(false)
export const isModalDialogOpen = makeVar(false)
export const isModalPromptOpen = makeVar(true)
export const isModalOpenAddAccount = makeVar(false)
export const isNavMenuRoute = makeVar(false)
export const continueRoute = makeVar('')
export const isDirtyForm = makeVar(false)
export const clickBatchPayment = makeVar(false)
export const isBatchPaymentFile = makeVar(false)
export const isCPChangedAfterReviewVar = makeVar(false)
export const isModalTaxIdOpen = makeVar(false)
export const isModalContractTransferOpen = makeVar(false)
export const modalMissingInfoId = makeVar('')

// UBO Legal Entity Logic transitions vars
export const activeForm = makeVar(
  localStorage.getItem(CONTROLLING_PERSON_KEY) || ControllingPersonsTypeEnum.personOwner,
)
export const totalShare = makeVar(0)
export const poiNeeded = makeVar(false)

// variable for Contract Authorized Person chose for edit
export type AccessRights = 'none' | 'limited' | 'full'
export const accessRights = makeVar<AccessRights>('none')

// variable for new application
export type ApplicationType = 'personal' | 'business'
export const applicationType = makeVar<ApplicationType>('business')

//logged off idle session by timeout
export const idleSessionLoggedOff = makeVar(false)

//filters and sorting state at pages
export const trxPageStateVar = makeVar<TrxPageStateType>(initialTransactionPageState)
export const standingOrdersPageStateVar = makeVar<StandingOrdersPageStateType>(
  initialStandingOrdersPageState,
)
export const actionsPageStateValue = makeVar<ActionsPageStateType>(initialActionsPageState)
export const accountsPageStateValue = makeVar<AccountsPageStateType>(initialAccountsPageState)
export const clientsPageStateVar = makeVar<BeneficiaryPageStateType>(initClientsPageState)
export const clientsOverviewPageStateVar = makeVar<BeneficiaryOverviewPageStateType>(
  initClientsOverviewPageState,
)
export const contractsTeamPageStateVar = makeVar<TeamPageStateType>(initialTeamPageState)
export const uploadDocsPageStateVar = makeVar<DocumentPageStateType>(initialDocsPageState)
export const receivedPageStateVar = makeVar<DocumentPageStateType>(initialDocsPageState)
export const cardsPageStateVar = makeVar<CardsPageStateType>(initCardsPageState)
export const vbansPageStateVar = makeVar<VbansPageStateType>(initVbansPageState)
export const successFeesPageStateVar = makeVar<SuccessFeesPageStateType>(initialSuccessFeePageState)
export const guaranteeEventsPageStateVar = makeVar<GuaranteeEventPageStateType>(
  initGuaranteeEventsPageState,
)
export const paymentsPageStateVar = makeVar<PaymentsPageStateType>(initialPaymentsPageState)
