import * as yup from 'yup'

import i18n from '../i18n'
import { ONE_NUMERIC_DIGIT_REGEXP } from '../components/SignUp/Validations'

export const UnusualSignInPageInputSchema = yup.object().shape({
  digit_1: yup
    .string()
    .required()
    .matches(ONE_NUMERIC_DIGIT_REGEXP, i18n.t('oneDigit', 'one digit')),
  digit_2: yup
    .string()
    .required()
    .matches(ONE_NUMERIC_DIGIT_REGEXP, i18n.t('oneDigit', 'one digit')),
  digit_3: yup
    .string()
    .required()
    .matches(ONE_NUMERIC_DIGIT_REGEXP, i18n.t('oneDigit', 'one digit')),
  digit_4: yup
    .string()
    .required()
    .matches(ONE_NUMERIC_DIGIT_REGEXP, i18n.t('oneDigit', 'one digit')),
  digit_5: yup
    .string()
    .required()
    .matches(ONE_NUMERIC_DIGIT_REGEXP, i18n.t('oneDigit', 'one digit')),
  digit_6: yup
    .string()
    .required()
    .matches(ONE_NUMERIC_DIGIT_REGEXP, i18n.t('oneDigit', 'one digit')),
})

export type UnusualSignInPageInput = yup.Asserts<typeof UnusualSignInPageInputSchema>
