import React, { FC, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { find, get, isEmpty, isEqual, take } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { GridRow } from '../../Common'
import { CountriesList } from '../../../utils/Data'
import { NewUboResidencyModal } from './NewUboResidencyModal'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  table: {
    borderTop: '0',
  },
  numberColumn: {
    minWidth: 92,
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  error: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  addButton: {
    width: 250,
    margin: theme.spacing(1, 0, 1.5, 0),
  },
}))

const UboInformationComponent: FC = () => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const [addingNewUbo, setAddingNewUbo] = useState(false)
  const [editingUbo, setEditingUbo] = useState<{ i: number; countries: string[] } | undefined>()

  const methods = useFormContext()
  const { control, errors, setValue, trigger } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'uboResidencies',
  })
  const ubos: { countries: string[] }[] | undefined = useWatch({
    control,
    name: 'uboResidencies',
  })
  const error = get(errors, 'uboResidencies')

  return (
    <>
      <Box mt={4}>
        <Typography variant="h3" className={clsx(classes.title, classes.moreBottomSpace)}>
          {t('uboInformation', 'UBO Information')}
        </Typography>
      </Box>
      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t('uboCountryOfResidence', 'Country of Residency of Ultimate Beneficial Owners')}
          </Typography>
        </Box>
      </GridRow>
      <FormProvider {...methods}>
        {!isEmpty(ubos) && !!ubos && (
          <GridRow>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.numberColumn}>#</TableCell>
                    <TableCell>{t('countries', 'Countries')}</TableCell>
                    <TableCell width={168}>{t('actions', 'Actions')}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {take(ubos, ubos?.length ?? 0).map((value, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {t('ubo', 'UBO')} #{i + 1}
                        </TableCell>
                        <TableCell>
                          {(value?.countries ?? [])
                            .map((c) => find(CountriesList, { key: c })?.label)
                            .join(', ')}
                        </TableCell>
                        <TableCell>
                          <Box className={classes.buttonsBox}>
                            <Button
                              type="button"
                              onClick={() => setEditingUbo({ i, countries: value.countries })}
                            >
                              {t('edit', 'Edit')}
                            </Button>
                            <Button
                              color="secondary"
                              aria-label="delete"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              {t('delete', 'Delete')}
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </GridRow>
        )}
        {!!error && (
          <GridRow data-test="uboResidenceErr">
            <FormHelperText className={classes.error}>{error.message}</FormHelperText>
          </GridRow>
        )}
        <GridRow>
          <Button
            name="uboResidencies"
            type="button"
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              append({ countries: [] })
              setAddingNewUbo(true)
            }}
            className={classes.addButton}
          >
            {t('addUboResidency', 'Add UBO Residency')}
          </Button>
        </GridRow>
      </FormProvider>
      <NewUboResidencyModal
        editData={editingUbo}
        isOpen={addingNewUbo || !!editingUbo}
        onClose={() => {
          addingNewUbo && remove(fields.length - 1)
          setAddingNewUbo(false)
          setEditingUbo(undefined)
          if (!!editingUbo) {
            setValue(`uboResidencies[${editingUbo.i}].countries`, editingUbo.countries)
          }
        }}
        onSubmit={(value) => {
          if (!!editingUbo) {
            setValue(`uboResidencies[${editingUbo.i}].countries`, value)
          } else {
            const index = Math.max(fields.length - 1, 0)
            setValue(`uboResidencies[${index}].countries`, value)
          }

          setAddingNewUbo(false)
          setEditingUbo(undefined)
          trigger('uboResidencies')
        }}
      />
    </>
  )
}
export const UboInformation = React.memo(UboInformationComponent, isEqual)
