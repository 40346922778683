import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
// import { createMuiTheme } from '@material-ui/core'
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { makeStyles, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'

const breakpoints = createBreakpoints({})

export const useGlobalStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      [theme.breakpoints.down('sm')]: {
        '& [aria-labelledby="simple-Currency-title"]': {
          zIndex: '1401!important',
        },
      },
      // Google Autocomplete +++
      '& .pac-container': {
        marginTop: 4,
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.05)',
        borderTop: 0,
      },
      '& .pac-item': {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        padding: '11px',
        borderTop: 0,
      },
      '& .pac-item-query ': {
        fontWeight: 'bold',
      },
      '& .pac-item:hover': {
        backgroundColor: '#F5F5F5',
      },
      '& .pac-logo:after': {
        display: 'none',
      },
      // Google Autocomplete ---

      // Datepicker +++
      '& .MuiPickersModal-dialogRoot': {
        '& .MuiPickersToolbar-toolbar': {
          height: 64,
          flexDirection: 'row-reverse',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '& .MuiPickersToolbarText-toolbarTxt': {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '1.5',
          },
          '& .MuiPickersToolbarButton-toolbarBtn': {
            marginRight: 10,
            '&:last-child': {
              cursor: 'default',
            },
          },
        },
        '& .MuiDialogActions-root': {
          padding: '0.5rem 0.75rem 0.75rem',
          '& .MuiButton-root': {
            minWidth: 'auto',
            minHeight: 32,
            padding: '0.5rem 1rem',
            marginLeft: 12,
            fontSize: '0.875rem',
            lineHeight: '16px',

            '&:last-child': {
              backgroundColor: '#000000',
              color: '#ffffff',
            },
          },
        },
        '& .MuiPickersBasePicker-pickerView': {
          minWidth: 324,
          '& .MuiPickersDay-day': {
            width: 32,
            height: 32,
            margin: '0 6px',
            borderRadius: 2,
          },
          '& .MuiPickersCalendarHeader-dayLabel': {
            width: 32,
            margin: '0 6px',
            color: '#999',
            fontWeight: 400,
            opacity: 1,
          },
        },
        '& .MuiPickersYear-root': {
          fontSize: '1rem',
        },
        '& .MuiPickersYear-yearSelected': {
          fontSize: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
      },
      // Negative balance
      '& .negative': {
        color: 'red',
      },
      // Pills +++
      '& .roundedPill': {
        width: 'fit-content',
        display: 'inline-block',
        padding: '3px 8px 2px 8px',
        fontSize: '0.75rem',
        fontWeight: 700,
        lineHeight: '14px',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        border: '1px solid rgba(0,0,0,.1)',
        borderRadius: '50rem',
        '&.completed, &.verified': {
          color: '#03703C',
          backgroundColor: '#F1FBF7',
        },
        '&.accept, &.executed, &.activeStatus': {
          color: '#03703C',
          backgroundColor: '#F1FBF7',
        },
        '&.pendingAction, &.pending,  &.pendingSignatures, &.scheduled': {
          color: 'rgba(188, 91, 2, 1)',
          backgroundColor: '#FFF3E8',
        },
        '&.reject, &.rejected,  &.deactivateStatus, &.failed, &.suspended, &.closed, &.declined, &.missed': {
          color: '#AB1300',
          backgroundColor: '#FFEFED',
        },
        '&.draft, &.revoked, &.closed, &.deactivatedStatus, &.open, &.canceled, &.cancelled': {
          color: '#434343',
          backgroundColor: '#F0F0F0',
        },
        '&.changesRequested, &.rescheduled': {
          color: '#AB1300',
          backgroundColor: '#FFEFED',
        },
        '&.signaturesPending, &.invitedStatus, &.processing, &.authorized': {
          color: '#1E54B7',
          backgroundColor: '#EFF3FE',
        },
        '&.pendingOpeningSignatures, &.pendingInvitation, &.pendingVerification, &.pendingSignature, &.verificationPendingStatus, &.pendingExecutionSignatures, &.pendingCancellationSignatures, &.pendingAml, &.pendingPayout, &.pendingApproval, &.invited': {
          color: '#BC5B02',
          backgroundColor: '#FFF3E8',
        },
        '&.pendingClosureSignatures': {
          color: '#BC5B02',
          backgroundColor: '#FFF3E8',
          whiteSpace: 'normal',
        },
        '&.pendingIncomingPayment': {
          color: '#BC5B02',
          backgroundColor: '#FFF3E8',
        },
        '&.approvalPending, &.underReview': {
          color: '#BC5B02',
          backgroundColor: '#FFF3E8',
        },
        '&.reverted': {
          color: 'rgba(188, 91, 2, 1)',
          backgroundColor: '#FFF3E8',
        },
        '&.signed': {
          color: '#1E54B7',
          backgroundColor: '#EFF3FE',
        },
        '&.released, &.received, &.sent, &.active': {
          color: '#03703C',
          backgroundColor: '#F1FBF7',
        },
        '&.held, &.suspended': {
          color: '#BC5B02',
          backgroundColor: '#FFF3E8',
        },
      },
      // Pills ---
      // icon status +++
      '& .iconCircle': {
        width: 8,
        height: 8,
        marginRight: 6,
        borderRadius: '50%',
        '& svg': {
          width: 8,
          height: 8,
        },
        '&.active svg circle': {
          fill: '#19B661',
        },
        '&.created svg circle': {
          fill: '#19B661',
        },
        '&.blocked svg circle': {
          fill: '#EF8019',
        },
        '&.pending svg circle': {
          fill: '#EF8019',
        },
        '&.closed svg circle': {
          fill: '#D9D9D9',
        },
      },
      // ---
      // UBO FORMS +++
      '& .form': {
        '& .MuiCheckbox-root': {
          padding: theme.spacing(0.5, 1, 0.5, 1.5),
        },
      },
      '& .group': {
        margin: theme.spacing(0, 0, 5, 0),
        [theme.breakpoints.down('xs')]: {
          margin: theme.spacing(0, 0, 4, 0),
        },
      },
      '& .titleWrap': {
        margin: theme.spacing(0, 0, 3, 0),
        [theme.breakpoints.down('xs')]: {
          margin: theme.spacing(0, 0, 2, 0),
        },
      },
      '& .title': {
        [theme.breakpoints.down('xs')]: {
          fontSize: '1.25rem',
          lineHeight: '1.75rem',
        },
      },
      '& .button': {
        ontFamily: theme.typography.fontFamily,
        fontSize: '1.125rem',
        maxWidth: 265,
        width: '100%',
        height: 48,
        position: 'relative',
        '& svg': {
          width: 24,
          height: 24,
        },
        '& span': {
          fontSize: '1.125rem',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
        },
      },
      '& .buttonsBox': {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 40,
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column-reverse',
        },
      },
      '& .secondaryButton': {
        marginRight: 40,
        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
          marginTop: 20,
          maxWidth: '100%',
        },
      },
      '& .inputWithSymbol': {
        position: 'relative',
        '&::after': {
          display: 'block',
          content: '"%"',
          fontSize: 14,
          position: 'absolute',
          right: 0,
          bottom: '30%',
          color: '#999999',
        },
      },
      // UBO FORMS ---
      '& .MuiAutocomplete-popper': {
        zIndex: 1410,
      },
      '& .MuiDialog-root': {
        zIndex: '1310!important',
      },
    },
  },
}))

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    h1: {
      fontSize: '2rem', //32px
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem', //24
      fontWeight: 'bold',
      lineHeight: '2rem',
    },
    h3: {
      fontSize: '1.25rem', //20px
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem', //16px
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      opacity: '0.64',
    },
    subtitle1: {
      fontSize: '1.75rem',
    },
    subtitle2: {
      fontSize: '0.75rem',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.6875rem',
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#EF2828',
      light: '#E5E5E5',
    },
    error: {
      main: '#EF2828',
    },
    warning: {
      main: '#EF8019',
    },
    success: {
      main: '#19B661',
    },
    text: {
      primary: '#000000',
    },
    action: {
      hover: '#f5f5f5',
    },
  },
  overrides: {
    MuiTable: {
      root: {
        borderTop: '1px solid rgba(0,0,0,.1)',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '0.875rem',
        fontWeight: 700,
      },
      root: {
        height: 48,
        padding: '6px 16px',
        fontSize: '0.875rem',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        '& .MuiIconButton-root': {
          width: 36,
          height: 36,
          padding: 10,
          borderRadius: '2px',
          '& .MuiTouchRipple-root *': {
            borderRadius: '2px',
          },
          '&:hover': {
            '& path': {
              fill: '#595959',
            },
          },
          '&.open': {
            backgroundColor: '#f0f0f0',
            border: '1px solid #d9d9d9',
            '& path': {
              fill: '#262626',
            },
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        '& .actionButtons': {
          opacity: 0,
        },
        '&:hover .actionButtons': {
          opacity: 1,
          transition: 'ease opacity 0.3s',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#000000',
      },
    },
    MuiButton: {
      root: {
        // minWidth: 208,
        minHeight: 48,
        padding: '8px 16px',
        fontSize: '1.125rem',
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: 0,
      },
      contained: {
        backgroundColor: '#f5f5f5',
      },
      containedPrimary: {
        color: '#fff',
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: '#ef2828',
        },
      },
      outlinedPrimary: {
        '&.MuiButton-sizeSmall': {
          fontWeight: 700,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          border: '1px solid #ef2828',
          color: '#ef2828',
        },
      },
      sizeSmall: {
        minWidth: 59,
        minHeight: 'auto',
        fontSize: '0.875rem',
        fontWeight: 400,
      },
    },
    MuiFormControl: {
      root: {
        minHeight: 48,
        // '& label + .MuiInput-formControl': {
        //   marginTop: 7,
        // },
        '& .MuiInputBase-multiline': {
          minHeight: 64,
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child, .MuiAutocomplete-endAdornment': {
          padding: '12px 40px 0 12px',
        },
        '& .MuiAutocomplete-endAdornment, MuiAutocomplete-endAdornment': {
          right: 12,
          top: 'calc(50% - 12px)',
          padding: 0,
        },
        '& .MuiSvgIcon-root': {
          color: '#999',
        },
        '& .MuiInputLabel-formControl': {
          zIndex: 1,
        },
        '& label + .MuiInput-formControl': {
          marginTop: 0,
          // minHeight: 64,
        },
        '& .MuiInput-underline:before': {
          borderColor: '#ccc',
        },
        '& .MuiInput-formControl.Mui-disabled': {
          backgroundColor: 'rgb(245 245 245)',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        },
      },
      // marginNormal: {
      //   marginTop: 24,
      //   marginBottom: 24,
      // },
      marginNormal: {
        marginTop: 20,
        marginBottom: 20,
      },
    },
    MuiInputBase: {
      root: {
        // minHeight: 64,
      },
      input: {
        height: 64,
        // paddingTop: 16,
        boxSizing: 'border-box',
        padding: '0 12px',
        fontSize: '0.875rem',
      },
      multiline: {
        padding: 12,
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiSelect: {
      root: {},
      selectMenu: {
        minHeight: 64,
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '48px',
        paddingTop: 16,
        boxSizing: 'border-box',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        top: 'calc(50% - 12px)',
        right: 12,
        color: '#999',
      },
    },
    MuiInputLabel: {
      root: {
        paddingLeft: 14,
        paddingRight: 14,
        color: '#999',
        zIndex: 1,
        '&.Mui-focused:not(.Mui-error)': {
          color: '#999',
        },
      },
      // shrink: {
      //   transform: 'translate(0, -3px) scale(0.857)',
      // },
      // marginDense: {
      //   transform: 'translate(0, 10px) scale(1)',
      // },
      // '& .Mui-focus': {
      //   '& + .MuiInput-root .MuiInput-input': {
      //     paddingTop: 16,
      //   },
      // },
      shrink: {
        transform: 'translate(0, 12px) scale(0.857)',
        '& + .MuiInput-root .MuiInput-input': {
          paddingTop: 16,
        },
      },
      marginDense: {
        transform: 'translate(0, 24px) scale(1)',
        '& + .MuiInput-root .MuiInput-input': {
          paddingTop: 16,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        top: '100%',
        paddingLeft: 12,
        fontSize: '0.75rem',
        fontWeight: 'normal',
        opacity: 1,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          color: '#434343',
        },
      },
    },
    MuiMenuItem: {
      root: {
        [breakpoints.up('xs')]: {
          minHeight: 48,
        },
        '&.Mui-selected': {
          position: 'relative',
          paddingRight: 40,
          backgroundColor: 'transparent',
          '&::after': {
            position: 'absolute',
            top: 12,
            right: 16,
            content: '""',
            width: 14,
            height: 8,
            borderLeft: '2px solid',
            borderBottom: '2px solid',
            transform: 'rotate(-45deg)',
          },
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 8,
        border: '1px solid #f0f0f0',
        borderRadius: 16,
        '&:after': {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          content: '""',
          border: '1px solid #fff',
          borderRadius: 16,
        },
      },
      colorPrimary: {
        backgroundColor: '#fff',
      },
      bar: {
        top: 1,
        bottom: 1,
        borderRadius: 16,
      },
      barColorPrimary: {
        backgroundColor: '#276ef1',
      },
    },
    MuiCheckbox: {
      root: {
        '& .MuiIconButton-label': {
          '& svg > rect': {
            stroke: '#ccc',
          },
        },
        '&:not(.Mui-checked)': {
          '& .MuiIconButton-label': {
            '& svg > path': {
              fill: '#ccc',
            },
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.875rem',
        color: 'white',
        backgroundColor: '#262626',
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 2,
        '& .MuiTooltip-arrow': {
          color: '#262626',
        },
      },
    },
  },
})
