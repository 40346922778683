import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import {
  stepperTypeVar,
  stepperHeaderTextVar,
  stepperCurrentStepVar,
} from '../graphql/local/businessAppVariables'
import { StepperTypes } from '../constants'

export interface UseStepperResult {
  currentStep: string
  setCurrentStep: (step: string) => void
  headerText: string
  setHeaderText: (step: string) => void
}

export function useStepper(stepperType: StepperTypes, headerText = ''): UseStepperResult {
  const headerTextVar = useReactiveVar(stepperHeaderTextVar)
  const currentStep = useReactiveVar(stepperCurrentStepVar)

  useEffect(() => {
    stepperTypeVar(stepperType)
    headerText && stepperHeaderTextVar(headerText)
  })

  return {
    currentStep,
    setCurrentStep: (step) => stepperCurrentStepVar(step),
    headerText: headerText || headerTextVar,
    setHeaderText: stepperHeaderTextVar,
  }
}
