import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography, Box, makeStyles, Button, Theme, Grid } from '@material-ui/core'
import { useReactiveVar } from '@apollo/client'
import { isEmpty } from 'lodash'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { statusActions, AppActions } from '../../constants'
import { uboLegalEntityTypesList } from '../../utils/Data'
import { Operating, Domiciliary } from '../NewPerson'
import {
  ContractUbo,
  UboOwner,
  LegalEntity,
  useGetOwnerSizeTypeQuery,
  useGetUbosListQuery,
  ContractStatusType,
  useGetUboOwnersLazyQuery,
  UboStakeType,
  ContractType,
} from '../../graphql'
import { activeForm, totalShare } from '../../graphql/local'
import { Loader, AlertTipItem } from '../Common'
import { ReactComponent as InformationIcon } from '../../assets/images/icons/info_icon.svg'
import { LegalOwnerSelect } from '../Common/Selectors/LegalOwnersSelect'
import { personsNestedOwners, transData } from '../../utils'
import { StepperTypes, RegistrationSteps } from '../../constants'
import { useStepper } from '../../hooks'
import { calcTotalShareAndIsDirectorAvail } from '../../utils'
import { LegalList, ShowAlertType } from '../../types'
import { Alerts } from './Tabs'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  subtitle: {
    lineHeight: '1.5rem',
    marginBottom: theme.spacing(4),
  },
  tipContainer: {
    maxWidth: 700,
    margin: theme.spacing(2, 0, 2),
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.125rem',
    width: 343,
    padding: 0,
    minWidth: 265,
    height: 48,
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  secondaryButton: {
    marginRight: 24,
    width: 104,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: 20,
      width: '100%',
    },
  },
  completeButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  wrapButton: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  buttonsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column-reverse',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  mb4: {
    marginBottom: theme.spacing(1),
  },
}))

export const BeneficialOwners: FC<{ handleNext?: () => void }> = () => {
  const [uboId, setUboId] = useState('')
  const [personsPrepared, setPersonsPrepared] = useState<Array<LegalList>>([])
  const [directorAvailable, setDirectorAvailable] = useState(true)
  const [isDomiciliary, setIsDomiciliary] = useState(false)
  const [canComplete, setCanComplete] = useState(false)
  const [proceedDisabled, setProceedDisabled] = useState(false)
  const [alertData, setAlertData] = useState<ShowAlertType[]>([])

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data } = useGetOwnerSizeTypeQuery({
    variables: { id: +applicationId },
  })
  const { data: owners } = useGetUbosListQuery({
    variables: {
      id: +applicationId,
    },
  })

  const [GetUboOwners, { data: uboOwnersData }] = useGetUboOwnersLazyQuery()

  const { data: contractData, loading: contractLoading } = useGetOwnerSizeTypeQuery({
    variables: { id: +applicationId },
  })

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const ownerValue = useReactiveVar(activeForm)
  //const goBackPath = useReactiveVar(goBackPathVar)
  const fromReviewCheck =
    history.location.state && (history.location.state as { from: string }).from === 'review'

  const handleOpen = useCallback(() => {
    !uboId || uboId === '0'
      ? history.push(generatePath(APP_PATHS.application.beneficialOwners.new, { applicationId }))
      : history.push(
          generatePath(APP_PATHS.application.beneficialOwners.newUboOwner, {
            applicationId,
            uboId,
          }),
        )
  }, [applicationId, history, uboId])

  const onProceed = useCallback(() => {
    if (canComplete) {
      history.push(generatePath(APP_PATHS.application.review, { applicationId }))
    } else {
      setProceedDisabled(true)
      const alerts: ShowAlertType[] = []
      alerts.push({
        key: 'noOneControllingPerson',
        value:
          'You have to add at least one Controlling person of {{val1}} before completing application.',
        messageParam1: (data?.contract?.owner as LegalEntity).companyName as string,
      })
      setAlertData(alerts)
    }
  }, [canComplete, history, applicationId, data?.contract?.owner])

  const onBack = useCallback(() => {
    const path =
      contractData?.contract?.type === ContractType.IntroducerReference
        ? APP_PATHS.application.edit
        : APP_PATHS.application.directors.list
    history.push(
      generatePath(path, {
        [PATH_PARAMS.applicationId]: +applicationId,
      }),
    )
  }, [applicationId, history, contractData])

  const { setHeaderText, setCurrentStep } = useStepper(
    contractData?.contract?.type === ContractType.IntroducerReference
      ? StepperTypes.partnerRegistration
      : StepperTypes.registration,
  )
  useEffect(() => {
    setCurrentStep(RegistrationSteps.controllingPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t(
            contractData?.contract?.type === ContractType.IntroducerReference
              ? 'companyPartnerApplication'
              : 'companyBusinessApplication',
            {
              companyName: contractOwner.companyName,
            },
          ),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [applicationId, contractData, contractLoading, history, setCurrentStep, setHeaderText, t])

  useEffect(() => {
    if (uboId && uboId !== '0')
      GetUboOwners({
        variables: {
          id: uboId,
        },
      })
  }, [GetUboOwners, uboId, uboOwnersData])

  useEffect(() => {
    const contractOwner = contractData?.contract?.owner as LegalEntity
    if (!contractLoading && contractOwner) {
      setIsDomiciliary(contractOwner.legalEntityType === uboLegalEntityTypesList[2].key)

      const shareSizeArr = contractData?.contract?.ubos?.reduce((result: number[], value) => {
        return [
          ...result,
          value?.ownershipType === UboStakeType.ManagingDirector
            ? 100
            : (value?.shareSize as number),
        ]
      }, [])

      const { shareSizeSummary, isDirectorAvailable } = calcTotalShareAndIsDirectorAvail(
        uboId,
        uboOwnersData?.getUboOwners as UboOwner[],
        shareSizeArr,
      )
      setDirectorAvailable(isDirectorAvailable)
      totalShare(shareSizeSummary)
    }
  }, [contractLoading, contractData, uboId, uboOwnersData])

  //TODO when query of all ubo & owners structure will be availiable refactor this component
  useEffect(() => {
    if (!isEmpty(owners?.contract?.ubos)) {
      history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
    }
    setCanComplete(!isEmpty(owners?.contract?.ubos))
    const persons = owners?.contract?.ubos?.filter(
      (person) => person?.ubo?.entity?.__typename !== 'Individual',
    )
    // const persons = owners?.contract?.ubos
    if (owners?.contract?.ubos && !isEmpty(persons)) {
      personsNestedOwners(persons as [ContractUbo]).then((result) => {
        const formatData = transData(result)

        setPersonsPrepared([
          {
            key: `0`,
            label: (data?.contract?.owner as LegalEntity).companyName,
          },
          ...formatData,
        ])
      })
      setUboId('0')
    } else setPersonsPrepared([])
  }, [data?.contract?.owner, owners?.contract?.ubos])

  if (contractLoading) return <Loader />

  return (
    <Grid container spacing={1} wrap={'wrap'} className={classes.root}>
      <Grid item md={8} className={classes.mb4}>
        <Alerts alertData={alertData} />
        {fromReviewCheck && !isEmpty(personsPrepared) && uboId ? (
          <>
            <Typography variant={'h2'} className={classes.title}>
              {t('addChooseControlling', 'Add a new controlling person of')}:
            </Typography>

            <LegalOwnerSelect setUboId={setUboId} uboId={uboId} dataPrepared={personsPrepared} />
          </>
        ) : (
          <Typography variant={'h2'} className={classes.title}>
            {t('addControlling', {
              name: (data?.contract?.owner as LegalEntity).companyName,
            })}
            :
          </Typography>
        )}
      </Grid>
      <Grid item md={8} className={classes.mb4}>
        <Typography variant={'body1'} className={classes.subtitle}>
          {t('selectOwnerType', 'Please add the controlling persons to {{company}}', {
            company: (data?.contract?.owner as LegalEntity).companyName,
          })}
          :
        </Typography>

        {isDomiciliary ? <Domiciliary /> : <Operating directorAvailable={directorAvailable} />}
        {ownerValue !== 'legalOwner' && (
          <Box className={classes.tipContainer}>
            <AlertTipItem
              value={t(
                'uploadControllingOwnerIdentity',
                'You will need to upload Controlling Persons identity documents: passport, ID or a driver’s license.',
              )}
              iconComponent={<InformationIcon />}
              type={'primary'}
            />
          </Box>
        )}
      </Grid>
      <Grid container className={classes.buttonsBox}>
        <Grid className={classes.wrapButton}>
          <Grid item className={classes.secondaryButton}>
            <Button type="button" variant="contained" fullWidth disableElevation onClick={onBack}>
              {t('goBack', 'Go back')}
            </Button>
          </Grid>

          <Grid item className={classes.completeButton}>
            <Button
              type="button"
              variant="contained"
              fullWidth
              disableElevation
              onClick={onProceed}
              disabled={proceedDisabled}
            >
              {t('completeApplication', 'Complete application')}
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <Button
            type="button"
            className={classes.button}
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleOpen}
            data-test="addControllingPerson-btn"
          >
            {t('addControllingPerson', 'Add Controlling person')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default BeneficialOwners
