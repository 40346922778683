import * as yup from 'yup'

import i18n from '../../i18n'
import { PASSWORD_REQUIREMENT_REGEXP } from '../../components/SignUp/Validations'

export const PasswordInputSchema = yup.object().shape({
  password: yup
    .string()
    .required(i18n.t('passwordIsMandatory', 'Password is mandatory'))
    .matches(
      PASSWORD_REQUIREMENT_REGEXP,
      i18n.t('passwordsMustContainSymbols', 'Must contain 8 symbols, 1 capital and 1 digit'),
    ),
})

export type PasswordInput = yup.Asserts<typeof PasswordInputSchema>
