/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useState } from 'react'
import {
  FxQuote,
  useGetFxMarkupQuery,
  useGetStaticFxRatesQuery,
  useNewFxQuotesSubscription,
  StaticFxRate,
  Currency,
} from '../graphql'
import { Dictionary, keyBy } from 'lodash'

type FXConverter = (fromCcy?: string | null, toCcy?: string | null) => number | null | undefined

export const useFXRate = (contractId: string | number): [FXConverter, boolean] => {
  const [fxRateMap, setFxRateMap] = useState<Record<string, Partial<FxQuote>>>({})

  const { data } = useNewFxQuotesSubscription({
    variables: { contractId },
    skip: !contractId,
  })

  const { data: fxMarkupData, loading } = useGetFxMarkupQuery({ variables: { contractId } })

  useEffect(() => {
    if (data?.newFXQuote) {
      const newSymbol = data.newFXQuote!.symbol.replace('/', '')
      setFxRateMap((curr) => ({ ...curr, [newSymbol]: data.newFXQuote! }))
    }
  }, [data])

  const applyMarkup = (rate: number): number => {
    if (fxMarkupData?.contract?.fxMarkup) {
      return +(rate - rate * fxMarkupData.contract.fxMarkup).toFixed(5)
    }
    return rate
  }

  const fxRateConverter: FXConverter = useCallback(
    (fromCcy?: string | null, toCcy?: string | null) => {
      if (!(fromCcy && toCcy)) return null
      if (fromCcy === toCcy) return 1
      const ccyPair = `${fromCcy}${toCcy}`
      const ccyInversePair = `${toCcy}${fromCcy}`
      let rate = null
      if (fxRateMap[ccyPair]) {
        rate = fxRateMap[ccyPair].bid // +markup
      }
      if (fxRateMap[ccyInversePair]) {
        rate = +(1 / fxRateMap[ccyInversePair].ask!).toFixed(5) //+markup
      }
      return rate ? applyMarkup(rate) : rate
    },
    [fxRateMap],
  )

  return [fxRateConverter, loading]
}

export const useStaticFXRate = (
  contractId: string | number,
  baseCurrency: string,
): [FXConverter, boolean] => {
  const [fxRateMap, setFxRateMap] = useState<Record<string, Dictionary<StaticFxRate>>>({})

  const { data } = useGetStaticFxRatesQuery({
    variables: { from: baseCurrency as Currency },
    skip: !baseCurrency,
  })

  const { data: fxMarkupData, loading } = useGetFxMarkupQuery({
    variables: { contractId },
    skip: !contractId,
  })

  useEffect(() => {
    if (data?.staticFXRates) {
      const rates = keyBy(
        data.staticFXRates,
        (r) => `${r!.from}${r!.to}`,
      ) as Dictionary<StaticFxRate>
      setFxRateMap((curr) => ({ ...curr, [baseCurrency]: rates }))
    }
  }, [data])

  const applyMarkup = (rate: number): number => {
    if (fxMarkupData?.contract?.fxMarkup) {
      return +(rate - rate * fxMarkupData.contract.fxMarkup).toFixed(5)
    }
    return rate
  }

  const staticFxRateConverter: FXConverter = useCallback(
    (fromCcy?: string | null, toCcy?: string | null) => {
      if (!(fromCcy && toCcy)) return null
      if (fromCcy === toCcy) return 1
      const ccyPair = `${fromCcy}${toCcy}`
      const ccyInversePair = `${toCcy}${fromCcy}`
      let rate = null
      const rateData = fxRateMap[baseCurrency] || {}
      if (rateData[ccyPair]) {
        rate = rateData[ccyPair].rate // +markup
      }
      if (rateData[ccyInversePair]) {
        rate = +(1 / rateData[ccyInversePair].rate!).toFixed(5) //+markup
      }
      return rate ? applyMarkup(rate) : rate
    },
    [fxRateMap],
  )

  return [staticFxRateConverter, loading]
}
