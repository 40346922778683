import { Collapse } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { YesNoOptions } from '../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../Common'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(2, 0),
  },
}))
const PersonAdditionalDataComponent: FC<{ isViewOnly?: boolean }> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { watch, setValue } = useFormContext()

  const isUSReportablePerson = watch('USReportablePerson')

  useEffect(() => {
    if (isUSReportablePerson === YesNoOptions[1].key) {
      setValue('tin', '')
    }
  }, [isUSReportablePerson])

  return (
    <>
      <GridRow>
        <FormAutocompleteSelect
          className={classes.formField}
          name="USReportablePerson"
          label={t('USReportablePerson', 'US reportable person')}
          data={YesNoOptions}
        />
      </GridRow>

      <Collapse in={isUSReportablePerson === YesNoOptions[0].key}>
        <GridRow>
          <FormControlledTextField
            label={t('tin', 'TIN')}
            name="tin"
            type="text"
            fullWidth
            multiline
            data-test="tin"
          />
        </GridRow>
      </Collapse>

      <GridRow>
        <FormAutocompleteSelect
          className={classes.formField}
          name="politicallyExposedPerson"
          label={t('politicallyExposedPerson', 'Politically exposed person')}
          data={YesNoOptions}
        />
      </GridRow>
    </>
  )
}
export const PersonAdditionalData = React.memo(PersonAdditionalDataComponent)
