import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useHistory, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Hidden,
  makeStyles,
} from '@material-ui/core'

import { activeForm, isCPChangedAfterReviewVar } from '../../../graphql/local'
import {
  ContractUbo,
  UboOwner,
  GetUbosListDocument,
  UboStakeType,
  useDeleteContractUboMutation,
  useDeleteUboOwnerMutation,
  LegalEntity,
  useGetUbosListQuery,
} from '../../../graphql'
import { Loader } from '../../Common'
import { BeneficialOwnerInfoRow } from './BeneficialOwnerInfoRow'
import { BeneficialOwnerInfoMob } from './BeneficialOwnerInfoMob'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { personsNestedOwners } from '../../../utils'
import { ControllingPersonsTypeEnum, PreparedContracUbo } from '../../../types'
import { BUSINESS_APP_STATUS_KEY } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 40,
      },
    },
    '& .MuiButton-outlinedSizeSmall': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.75, 2),
      },
    },
  },
  table: {
    borderTop: '0',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      marginBottom: theme.spacing(2),
    },
  },
  btnWrapp: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  // Mobile table
  mobileTable: {
    //borderBottom: '1px solid rgba(0,0,0,.1)',
    '&.bordered': {
      border: '1px solid rgba(0,0,0,.1)',
      borderBottom: 0,
    },
  },
}))

export const BeneficialOwnersTabs: FC<{
  onDelete?: () => void
}> = () => {
  const [personsPrepared, setPersonsPrepared] = useState<Array<PreparedContracUbo>>()
  const [openDelete, setOpenDelete] = useState<number | string>(0)

  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { t } = useTranslation()
  const classes = useStyles()
  const [deleteContractUboMutation] = useDeleteContractUboMutation()
  const [deleteUboOwnerMutation] = useDeleteUboOwnerMutation()
  const { data, loading, refetch: refetchUboList } = useGetUbosListQuery({
    variables: {
      id: +applicationId,
    },
  })

  const dataCheck = isEmpty(personsPrepared)
  const locationCheck = history.location.pathname.includes('beneficial-owners/list')

  const handleModalDelete = useCallback((id) => {
    setOpenDelete(id)
  }, [])

  const handleClose = useCallback(
    (modalName: string) => {
      if (modalName === 'delete') {
        setOpenDelete(0)
      }
    },
    [setOpenDelete],
  )

  const createNestedOwners = () => {
    if (data?.contract?.ubos) {
      const persons = data?.contract?.ubos
      personsNestedOwners(persons as [ContractUbo]).then((result) => setPersonsPrepared(result))
    }
  }

  const onConfirmDelete = useCallback(async (uboId, ownerLevel, person) => {
    if (ownerLevel) {
      deleteUboOwnerMutation({
        variables: {
          contractId: applicationId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uboId: (person as UboOwner).ownedUboId!,
          uboOwnerId: uboId,
        },
      })
      await refetchUboList()
      createNestedOwners()
    } else {
      deleteContractUboMutation({
        variables: {
          id: uboId,
        },
        refetchQueries: [
          {
            query: GetUbosListDocument,
            variables: { id: +applicationId },
          },
        ],
        awaitRefetchQueries: true,
      })
    }
    setOpenDelete(0)
    isCPChangedAfterReviewVar(true)
  }, [])

  const onEdit = useCallback((uboData, level = 0) => {
    if (uboData.ubo.entity.__typename === 'LegalEntity') {
      // Legal Entity Contract Owner
      activeForm(ControllingPersonsTypeEnum.legalOwner)
    } else {
      // Private Entity Contract Owner
      if (uboData.ownershipType === UboStakeType.Percent25OrMore) {
        activeForm(ControllingPersonsTypeEnum.personOwner)
      } else if (uboData.ownershipType === UboStakeType.Percent25OrMoreViaPoA) {
        activeForm(ControllingPersonsTypeEnum.attorneyPower)
      } else {
        activeForm(ControllingPersonsTypeEnum.managingDirector)
      }
    }

    if (level > 0) {
      //data from uboOwners ubodata.id === sso.ubo_Owner.id
      history.push(
        generatePath(APP_PATHS.application.beneficialOwners.editUboOwner, {
          applicationId,
          uboId: uboData.id,
          ownedUboId: uboData.ownedUboId,
        }),
      )
      return
    }

    isCPChangedAfterReviewVar(true)
    //data from uboOwners ubodata.id === sso.contract_Ubo.id
    history.push(
      generatePath(APP_PATHS.application.beneficialOwners.edit, {
        applicationId,
        uboId: uboData.id,
      }),
    )
  }, [])

  const onAdd = useCallback(() => {
    localStorage.removeItem(BUSINESS_APP_STATUS_KEY(applicationId))
    isCPChangedAfterReviewVar(true)
    history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }), {
      from: 'review',
    })
  }, [])

  useEffect(() => {
    if (locationCheck && personsPrepared && personsPrepared.length === 0) {
      history.push(
        generatePath(APP_PATHS.application.beneficialOwners.add, {
          applicationId,
        }),
      )
    }
  }, [locationCheck, personsPrepared])

  useEffect(() => {
    createNestedOwners()
  }, [data?.contract?.ubos])

  return (
    <>
      <Box className={classes.head}>
        <Typography variant={'h2'} className={classes.title}>
          {t('controllingPersonsOf', 'Controlling persons of', {
            companyName: (data?.contract?.owner as LegalEntity)?.companyName,
          })}
        </Typography>

        <Box className={classes.btnWrapp} hidden={locationCheck}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={onAdd}
            disableElevation
            size="small"
            data-test="addNew-btn"
          >
            {t('addNew', 'Add new')}
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Hidden xsDown>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('name', 'Name')}</TableCell>
                    <TableCell width={168}>{t('share', 'Share')}</TableCell>
                    <TableCell width={168}>{t('actions', 'Actions')}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody data-test="controllingPersonsOwners">
                  {!dataCheck &&
                    personsPrepared?.map((person, index) => {
                      return (
                        person && (
                          <React.Fragment key={`${person.id}_${index}`}>
                            <BeneficialOwnerInfoRow
                              person={person}
                              handleModalDelete={handleModalDelete}
                              openDelete={openDelete}
                              setOpenDelete={setOpenDelete}
                              handleClose={handleClose}
                              onEdit={onEdit}
                              onConfirmDelete={onConfirmDelete}
                            />
                            {person.ownersArr.length > 0 &&
                              person.ownersArr.map((owner: UboOwner, index: number) => (
                                <BeneficialOwnerInfoRow
                                  person={owner}
                                  key={`${person.id}${owner.id}${index}`}
                                  handleModalDelete={handleModalDelete}
                                  openDelete={openDelete}
                                  setOpenDelete={setOpenDelete}
                                  handleClose={handleClose}
                                  onEdit={onEdit}
                                  onConfirmDelete={onConfirmDelete}
                                />
                              ))}
                          </React.Fragment>
                        )
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>

          {/* mobile table */}
          <Hidden smUp>
            <Box className={classes.mobileTable}>
              {!dataCheck &&
                personsPrepared?.map((person, index) => {
                  return (
                    person && (
                      <React.Fragment key={`${person.id}_${index}`}>
                        <BeneficialOwnerInfoMob
                          person={person}
                          handleModalDelete={handleModalDelete}
                          openDelete={openDelete}
                          setOpenDelete={setOpenDelete}
                          handleClose={handleClose}
                          onEdit={onEdit}
                          onConfirmDelete={onConfirmDelete}
                        />
                        {person.ownersArr.length > 0 &&
                          person.ownersArr.map((owner: UboOwner, index: number) => (
                            <BeneficialOwnerInfoMob
                              person={owner}
                              key={`${person.id}${owner.id}${index}`}
                              handleModalDelete={handleModalDelete}
                              openDelete={openDelete}
                              setOpenDelete={setOpenDelete}
                              handleClose={handleClose}
                              onEdit={onEdit}
                              onConfirmDelete={onConfirmDelete}
                            />
                          ))}
                      </React.Fragment>
                    )
                  )
                })}
            </Box>
          </Hidden>
        </>
      )}
    </>
  )
}
