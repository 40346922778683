import * as yup from 'yup'

import {
  AdditionalDetailsInputSchema,
  AddressInputSchema,
  AuthStatSignatInputSchema,
  BirthdayInputSchema,
  DocumentTypeInputSchema,
  NameInputSchema,
  NationalityInputSchema,
  OwnerShipPercent1InputSchema,
  OwnerShipPercentInputSchema,
  PostalCodeInputSchema,
  StreetAddressInputSchema,
  TaxIdSchema,
} from './common'
import { PersonAdditionalDataSchema } from './PersonAdditionalDataSchema'

export const BeneficialOwnerValidationSchema = AuthStatSignatInputSchema.concat(
  AdditionalDetailsInputSchema,
)
  .concat(NameInputSchema)
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(OwnerShipPercentInputSchema)
  .concat(DocumentTypeInputSchema)
  .concat(PersonAdditionalDataSchema)

export const DomBeneficialOwnerValidationSchema = AuthStatSignatInputSchema.concat(
  AdditionalDetailsInputSchema,
)
  .concat(NameInputSchema)
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(OwnerShipPercent1InputSchema)
  .concat(DocumentTypeInputSchema)
  .concat(TaxIdSchema)
  .concat(PersonAdditionalDataSchema)

export type DomBeneficialOwnerInput = yup.Asserts<typeof DomBeneficialOwnerValidationSchema>
