import * as yup from 'yup'
import {
  MAX_SYMBOLS_LIMIT_MESSAGE,
  MAX_TAXID_LENGTH,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_DOTS_AND_DASHES_REGEXP,
  NUMBER_AND_LATINICA_AND_SYMBOLS,
  NUMBER_AND_LATINICA_ONLY,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'

export const RegistryNumberInputSchema = yup.object().shape({
  registryNumber: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .min(MIN_LENGTH, NUMBER_AND_LATINICA_AND_SYMBOLS)
    .max(MAX_TAXID_LENGTH, MAX_SYMBOLS_LIMIT_MESSAGE(MAX_TAXID_LENGTH))
    .matches(NUMBER_AND_LATINICA_AND_DOTS_AND_DASHES_REGEXP, NUMBER_AND_LATINICA_ONLY),
})

export type RegistryNumberInput = yup.Asserts<typeof RegistryNumberInputSchema>
