import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'
import ControlledIconTooltip from '../../components/Common/Tooltips/ControlledIconTooltip'

import { BatchPaymentRecord, Maybe } from '../../graphql'
import { batchPaymentCellValues } from '../../utils'
import { BeneficialCellType } from '../../types'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles(() => ({
  helpIcon: {
    height: 16,
    marginRight: 4,
    '& .MuiSvgIcon-root': {
      width: 14,
      height: 14,
    },
    cursor: 'pointer',
  },
}))

export const BeneficiariesListCell: FC<{
  cellType: BeneficialCellType
  data: Maybe<BatchPaymentRecord>
  rowCell?: boolean
}> = ({ cellType, data }) => {
  const { t } = useTranslation()
  const { valueError, valueCell } = batchPaymentCellValues(cellType, data)
  const classes = useStyles()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...(!!valueError && { color: '#EF2828' }),
      }}
    >
      {!!valueError && (
        <Box className={classes.helpIcon}>
          <ControlledIconTooltip
            Icon={ErrorIcon}
            placement="top"
            title={`${t(data?.errors[valueError])}`}
            iconColor="error"
          />
        </Box>
      )}
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {valueCell}
      </span>
    </div>
  )
}
