import { Box } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { AlertTipItem } from '../../Common'
import { ShowAlertType } from '../../../types'
import { ReactComponent as RejectedIcon } from '../../../assets/images/icons/icon-info2.svg'
const useStyles = makeStyles((theme) => ({
  alert: {
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

export const Alerts: FC<{
  alertData?: ShowAlertType[]
}> = ({ alertData }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (alertData) {
    return (
      <Box>
        {alertData.map((alertDataValue, index) => {
          const { key, value, messageParam1, messageParam2, messageParam3 } = alertDataValue
          return messageParam1 || messageParam2 || messageParam3 ? (
            <Box className={classes.alert}>
              <AlertTipItem
                key={`${key + index}`}
                value={
                  key && value
                    ? t(`${key}`, `${value}`, {
                        val1: messageParam1,
                        val2: messageParam2,
                        val3: messageParam3,
                      })
                    : ''
                }
                isOpenAlert
                iconComponent={<RejectedIcon />}
                type={'danger'}
              />
            </Box>
          ) : (
            <Box className={classes.alert}>
              <AlertTipItem
                key={`${key + index}`}
                value={t(key || '', value || '')}
                iconComponent={<RejectedIcon />}
                type={'danger'}
                isOpenAlert
              />
            </Box>
          )
        })}
      </Box>
    )
  } else {
    return null
  }
}
