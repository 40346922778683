import { useCallback } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ExtendedAccessContractsTypesList } from '../constants'
import {
  Action,
  ContractStatusType,
  TransactionAction,
  useGetContractStatusQuery,
} from '../graphql'
import { actionsPageStateValue, goBackPathVar } from '../graphql/local'
import { APP_PATHS, PATH_PARAMS } from '../routes/paths'
import { ActionsPageStateType } from '../types'

export function useHandleActionDetails(
  data: Action,
  filtersState: ActionsPageStateType,
): { handleDetails: () => void } {
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  actionsPageStateValue(filtersState)

  const { data: contractStatus } = useGetContractStatusQuery({
    variables: {
      id: applicationId,
    },
    skip: !applicationId || Number.isNaN(Number(applicationId)),
  })

  const fullAccessStatusesList = [ContractStatusType.Active, ...ExtendedAccessContractsTypesList]

  const handleDetails = useCallback(() => {
    if ('standingOrder' in data && data.standingOrder) {
      history.push(
        generatePath(APP_PATHS.dashboard.standingOrderDetails, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.standingOrderId]: data.standingOrder.id,
        }),
      )
      return
    }

    const { transaction } = data as TransactionAction

    if (transaction?.__typename === 'DebitTransaction') {
      goBackPathVar(
        generatePath(APP_PATHS.dashboard.actions, {
          [PATH_PARAMS.applicationId]: applicationId,
        }),
      )
      history.push(
        generatePath(APP_PATHS.dashboard.trxDetails, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.transactionId]: transaction?.id as string,
        }),
      )
    } else {
      const path = fullAccessStatusesList.includes(
        contractStatus?.contract?.status as ContractStatusType,
      )
        ? APP_PATHS.dashboard.actionDetails
        : APP_PATHS.application.actionDetails

      history.push(
        generatePath(path, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.signatureId]: data?.id,
        }),
      )
    }
  }, [applicationId, history, data, contractStatus])
  return { handleDetails }
}
