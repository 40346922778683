import React, { FC, useCallback, useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'

import { focusKeyPressNext } from '../../../utils'
import { CommonTipItem, FormPasswordField, Loader } from '../../../components'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { SignatoryRight, useGetCardInfoQuery } from '../../../graphql'
import { CardPasswordInputSchema, RepeatPasswordInputSchema } from '../../../schemes/common'
import { ReactComponent as IconConfirm } from '../../../assets/images/icons/confirmed.svg'
import { useCurrentUser, useDetermineUserRights } from '../../../hooks'
import { CardActions2FAModal } from '../../../components/Common/CardsActions2FAModal'
import { CardOperationTypes } from '../../../types'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
  },
  wrap: {
    width: '100%',
    marginBottom: '15px',
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
    },
    '& .MuiBox-root ': {
      marginTop: 0,
    },
    '& .MuiGrid-root ': {
      padding: 0,
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(11),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0),
    },
  },
  title: {
    fontSize: 32,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  footerBtn: {
    padding: '32px 0 0 0',
  },
  btnControl: {
    padding: theme.spacing(1.25, 2.5),
  },
  btnCancel: {
    border: '1px solid black',
    padding: theme.spacing(1.25, 2.5),
  },
  tipText: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    marginLeft: 32,
    fontSize: 14,
    lineHeight: '24px',
    '&>a': {
      color: 'black',
    },
  },
  tipContainerActions: {
    padding: '3px 8px',
    margin: theme.spacing(0, 0, 3),
    border: '1px solid #ADDEC9',
    '&>div': {
      padding: 0,
    },
    '& p': {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
    },
  },
}))

export const ActivateCardForm: FC = () => {
  const [open, setOpen] = useState(false)
  const [cardActivated, setCardActivated] = useState(false)

  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(CardPasswordInputSchema.concat(RepeatPasswordInputSchema)),
  })
  const { handleSubmit, formState, watch, trigger } = methods
  const password = watch('password')
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.cardId]: cardId,
    [PATH_PARAMS.cardsPageNum]: page,
  } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)
  const user = useCurrentUser()

  const { data: cardData, loading } = useGetCardInfoQuery({
    variables: {
      id: cardId,
      contractId: applicationId,
    },
  })

  const onCancel = useCallback(() => {
    setOpen(false)
    history.push(
      generatePath(APP_PATHS.dashboard.cards, {
        [PATH_PARAMS.applicationId]: applicationId,
        [PATH_PARAMS.cardsPageNum]: page,
      }),
    )
  }, [applicationId, history])

  const handleActivateCard = useCallback(async () => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (cardActivated) {
      history.push(
        generatePath(APP_PATHS.dashboard.cards, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.cardsPageNum]: page,
        }),
      )
    }
  }, [cardActivated])

  // useEffect(() => {
  //   if (cardData?.contractCard?.isCard3dSecureActivated) {
  //     onCancel()
  //   }
  // }, [cardData])

  if (loading) return <Loader />

  return (
    <>
      <Grid item container className={classes.head}>
        <Typography variant="h2" className={classes.title}>
          {t('activateVirtualCard', 'Activate virtual card')}
        </Typography>
      </Grid>

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(handleActivateCard)}
          id="forNextFocus"
          onKeyDown={focusKeyPressNext}
        >
          {userRights?.signatoryRight === SignatoryRight.Sole &&
            user?.id === cardData?.contractCard?.cardHolder?.person?.id && (
              <Grid item xs={12} sm={8} lg={4} className={classes.tipContainerActions}>
                <CommonTipItem
                  value={t('cardCreatedSuccess', 'Card created successfully!')}
                  iconComponent={<IconConfirm />}
                />
                <Box className={classes.tipText}>
                  {t(
                    'setPasswordInternetPurchases',
                    'Now please set up a password needed for Internet purchases.',
                  )}
                </Box>
              </Grid>
            )}
          <Grid item xs={12} sm={8} lg={4} className={classes.container}>
            <Box className={classes.wrap}>
              <Typography paragraph={true}>
                {t(
                  'setPswdText',
                  'Set up a password. Some merchants will require it to secure your Internet purchases.',
                )}
              </Typography>
            </Box>

            <FormPasswordField
              label={t('password', 'Password')}
              name="password"
              fullWidth
              helpText={t(
                'cardPasswordHelpText',
                'Password must be at least 8 characters and only the special characters "! # ; : ?  * ( ) + = / & , . [ ] { }"  are allowed',
              )}
            />
            <FormPasswordField
              label={t('repeatPassword', 'Repeat password')}
              name="repeat_password"
              fullWidth
              onChange={() => {
                trigger(`repeat_password`)
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.footerBtn}>
            <Grid container item spacing={3}>
              <Grid item>
                <Button onClick={onCancel} variant="outlined" className={classes.btnCancel}>
                  {t('cancel', 'Cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading || !formState?.isValid || !formState?.touched.password}
                  disableElevation
                  className={classes.btnControl}
                >
                  {t('activateCard', 'Activate card')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      {/* 2FA Modal */}
      <CardActions2FAModal
        open={open}
        handleClose={setOpen}
        cardId={cardId}
        cardName={'cardName'}
        type={CardOperationTypes.activate}
        password={password}
        setCardActivated={setCardActivated}
      />
    </>
  )
}

export default ActivateCardForm
