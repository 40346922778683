import React, { FC, useEffect } from 'react'
import { Grid, FormControl } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import {
  AddressAutocomplete,
  BaseEnumWithKey,
  CountryPhoneSelect,
  FormAutocompleteSelect,
  FormControlledTextField,
  FormDatePickerField,
  GridRow,
  nationalitiesArray,
} from '../../../components'
import { Address, Individual } from '../../../graphql'
import { useFormContext } from 'react-hook-form'
import { CountriesList } from '../../../utils/Data'
import { registeredAddressEmptyProps } from '../../../stubs'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}))

export const PersonProfileForm: FC<{
  personProfileFormData?: Individual | undefined | null
  setEditRequestModalFormData?: (formData: Individual | undefined | null) => void
  fillForm: () => void
  taxIdRequired?: boolean
}> = ({ personProfileFormData, setEditRequestModalFormData, fillForm, taxIdRequired = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const {
    email,
    phoneNumber,
    firstName,
    lastName,
    nationality,
    country,
    city,
    streetAddress,
    postalCode,
    additionalDetailsOptional,
    taxId,
  } = watch()

  useEffect(() => {
    fillForm()
  }, [personProfileFormData])

  useEffect(() => {
    if (personProfileFormData) {
      const currencyCountry = CountriesList?.find((v: BaseEnumWithKey) => v.key === country)?.label

      setEditRequestModalFormData &&
        setEditRequestModalFormData({
          id: personProfileFormData.id,
          email: personProfileFormData['email'] !== email ? email : '',
          phone: personProfileFormData['phone'] !== phoneNumber ? phoneNumber : '',
          firstName: personProfileFormData['firstName'] !== firstName ? firstName : '',
          lastName: personProfileFormData['lastName'] !== lastName ? lastName : '',
          nationality: personProfileFormData['nationality'] !== nationality ? nationality : '',
          taxId: personProfileFormData['taxId'] !== taxId ? taxId : '',
          address: {
            country: personProfileFormData?.address?.country !== country ? currencyCountry : '',
            city: personProfileFormData?.address?.city !== city ? city : '',
            line1: personProfileFormData?.address?.line1 !== streetAddress ? streetAddress : '',
            zip: personProfileFormData?.address?.zip !== postalCode ? postalCode : '',
            additionalDetails:
              personProfileFormData?.address?.additionalDetails !== additionalDetailsOptional
                ? additionalDetailsOptional
                : '',
          } as Address,
        } as Individual)
    }
  }, [
    personProfileFormData,
    setEditRequestModalFormData,
    email,
    phoneNumber,
    firstName,
    lastName,
    nationality,
    country,
    city,
    streetAddress,
    postalCode,
    additionalDetailsOptional,
    taxId,
  ])

  return (
    <Grid item xs={12} data-test="personalData">
      <GridRow>
        <FormControl className={classes.formControl}>
          <FormControlledTextField
            label={t('firstNames', 'First Name(s)')}
            name="firstName"
            type="text"
            fullWidth={true}
            required={false}
          />
        </FormControl>
      </GridRow>

      <GridRow>
        <FormControl className={classes.formControl}>
          <FormControlledTextField
            label={t('lastName', 'Last Name')}
            name="lastName"
            type="text"
            fullWidth={true}
            required={false}
          />
        </FormControl>
      </GridRow>

      <GridRow>
        <FormControl className={classes.formControl}>
          <FormDatePickerField
            name="birthday"
            label={t('dateOfBirth', 'Date of Birth')}
            readOnly={true}
          />
        </FormControl>
      </GridRow>

      {taxIdRequired && (
        <GridRow>
          <FormControlledTextField
            label={t('taxId', 'Tax ID')}
            name="taxId"
            type="text"
            fullWidth={true}
            required={false}
          />
        </GridRow>
      )}

      <AddressAutocomplete
        customCountryLabel={t('countryOfResidence', 'Country of Residence')}
        props={registeredAddressEmptyProps}
      />

      <GridRow>
        <FormControl className={classes.formControl}>
          <FormAutocompleteSelect
            name="nationality"
            label={t('nationality', 'Nationality')}
            data={nationalitiesArray}
          />
        </FormControl>
      </GridRow>

      <GridRow>
        <FormControl className={classes.formControl}>
          <FormControlledTextField
            label={t('emailAddress', 'Email Address')}
            name="email"
            type="text"
            fullWidth={true}
            required={false}
          />
        </FormControl>
      </GridRow>

      <GridRow>
        <CountryPhoneSelect name="phoneNumber" label={t('phoneNumber', 'Phone Number')} />
      </GridRow>
    </Grid>
  )
}
