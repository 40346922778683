import React, { FC, useCallback, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { uboLegalEntityTypesList } from '../../utils/Data'
import { LegalOwnerForm, ManagingDirectorForm, OwnerForm } from './Forms'
import { LegalEntity, UboStakeType, useGetOwnerSizeTypeLazyQuery } from '../../graphql'
import { activeForm } from '../../graphql/local'
import { Loader } from '../Common'
import { PATH_PARAMS } from '../../routes/paths'
import { ControllingPersonsTypeEnum } from '../../types'

export const AddNewOwner: FC = () => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const [GetOwnerSizeType, { data, loading }] = useGetOwnerSizeTypeLazyQuery({
    variables: { id: +applicationId },
  })
  const isDomiciliary =
    (data?.contract?.owner as LegalEntity)?.legalEntityType === uboLegalEntityTypesList[2].key

  useEffect(() => {
    GetOwnerSizeType()
  }, [GetOwnerSizeType])

  const renderForm = useReactiveVar(activeForm)
  const contractName = data?.contract?.owner
    ? (data?.contract?.owner as LegalEntity).companyName
    : 'Company'

  const formSwitch = useCallback(
    (condition: ControllingPersonsTypeEnum) => {
      switch (condition) {
        case ControllingPersonsTypeEnum.personOwner:
          return (
            <OwnerForm
              isDomiciliary={isDomiciliary}
              companyName={contractName}
              type={UboStakeType.Percent25OrMore}
            />
          )
        case ControllingPersonsTypeEnum.legalOwner:
          return (
            <LegalOwnerForm
              isDomiciliary={isDomiciliary}
              companyName={
                data?.contract?.owner
                  ? (data?.contract?.owner as LegalEntity).companyName
                  : 'Company'
              }
            />
          )
        case ControllingPersonsTypeEnum.attorneyPower:
          return (
            <OwnerForm
              isDomiciliary={isDomiciliary}
              companyName={
                data?.contract?.owner
                  ? (data?.contract?.owner as LegalEntity).companyName
                  : 'Company'
              }
              type={UboStakeType.Percent25OrMoreViaPoA}
            />
          )
        default:
          return (
            <ManagingDirectorForm
              companyName={
                data?.contract?.owner
                  ? (data?.contract?.owner as LegalEntity).companyName
                  : 'Company'
              }
            />
          )
      }
    },
    [isDomiciliary, contractName, data?.contract?.owner],
  )

  if (loading) return <Loader />

  return formSwitch(renderForm as ControllingPersonsTypeEnum)
}

export default AddNewOwner
