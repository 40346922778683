import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box, Icon, MenuItem, SvgIcon, Hidden } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { useIsLimitedContract, useWebApi } from '../../hooks'
import { APP_PATHS, ROOT_PATHS } from '../../routes/paths'
import { Change2FAMenuItem, ContactSupportLink } from '../Common'
import { ContractStatusType, useGetViewerQuery } from '../../graphql'
import { useReactiveVar } from '@apollo/client'
import { isDirtyForm, isModalDialogOpen } from '../../graphql/local'
import config from '../../config'
import { useTableSort } from '../../hooks/useCurrentTableSort'

const useStyles = makeStyles((theme) => ({
  menuFooter: {
    color: theme.palette.common.black,
    paddingLeft: 0,
    margin: 0,
    '& > .MuiGrid-container': {
      '& > .MuiGrid-item': {
        padding: theme.spacing(1, 0),
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  menuFooterMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.5,
    paddingLeft: theme.spacing(4),
    '&:hover': {
      color: '#434343',
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        opacity: '.4',
        transition: '0.3s',
      },
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& a': {
      width: '100%',
    },
  },
  menuItemContact: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    lineHeight: 1.5,
    transition: '0.3s',
    color: '#000000',
    '& > a:hover': {
      color: '#434343',
    },
    '&:hover': {
      color: '#434343',
      backgroundColor: 'transparent',
      transition: '0.3s',
      '& .MuiSvgIcon-root': {
        opacity: '.4',
        transition: '0.3s',
      },
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& a': {
      width: '100%',
      height: '48px',
      paddingLeft: theme.spacing(4),
    },
  },
  copyright: {
    padding: theme.spacing(1),
  },
  inMenu: {
    padding: theme.spacing(2, 2, 2.5, 4),
  },
  mb1: {
    marginBottom: theme.spacing(1.5),
  },
  mt1: {
    marginTop: theme.spacing(1.5),
  },
  dotContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  changeMargin: {
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      fontSize: '11px',
    },
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      '& span': {
        marginRight: '5px',
      },
    },
  },
  contactWrapper: {
    marginBottom: 15,
    '& a': {
      paddingLeft: 0,
    },
  },
}))

export const Footer: FC<{
  isMenu: boolean
  isMenuOpen?: boolean | undefined
}> = ({ isMenu = false, isMenuOpen }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { webApi } = useWebApi()
  const { data } = useGetViewerQuery()
  const isDirtyField = useReactiveVar(isDirtyForm)

  const history = useHistory()
  const { clearTablesSort } = useTableSort()
  const { contractStatus } = useIsLimitedContract()

  const checkableContractStatuses = [
    ContractStatusType.Active,
    ContractStatusType.Suspended,
    ContractStatusType.TerminationPending,
    ContractStatusType.Monitoring,
  ]

  const contractStatusCheck = contractStatus && !checkableContractStatuses.includes(contractStatus)

  const onLogout = useCallback(() => {
    clearTablesSort()

    if (isDirtyField) {
      isModalDialogOpen(true)
      return
    } else {
      webApi?.logout().then(() => history.push(APP_PATHS.auth.login))
    }
  }, [isDirtyField, webApi])

  const renderMenu = useMemo(() => {
    return (
      <ul className={`${classes.menuFooter} ${isMenuOpen ? classes.menuFooterMargin : ''}`}>
        <Change2FAMenuItem />
        {contractStatusCheck && (
          <MenuItem onClick={onLogout} className={classes.menuItem}>
            <SvgIcon>
              <path
                fill="#000"
                d="M14 5l-1.41 1.41L14.17 8H6v2h8.17l-1.58 1.58L14 13l4-4-4-4zM2 2h7V0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h7v-2H2V2z"
              />
            </SvgIcon>
            {t('logOut', 'Log Out')}
          </MenuItem>
        )}
      </ul>
    )
  }, [isDirtyField, contractStatus])

  return (
    <Box>
      {!isMenu && <Hidden smDown>{renderMenu}</Hidden>}
      {data?.viewer && isMenu && renderMenu}

      {/*for desktop left menu application*/}
      {(isMenuOpen || isMenu) && (
        <Grid
          item
          xs={12}
          container
          direction="column"
          alignContent="flex-start"
          alignItems="flex-start"
          className={`${classes.copyright} ${isMenu && classes.inMenu}`}
        >
          <Grid className={classes.contactWrapper} item container>
            <MenuItem className={classes.menuItemContact}>
              <ContactSupportLink menuLink={true} newTab />
            </MenuItem>
            {history.location.pathname === ROOT_PATHS.appRoot && (
              <MenuItem onClick={onLogout} className={classes.menuItemContact}>
                <SvgIcon>
                  <path
                    fill="#000"
                    d="M14 5l-1.41 1.41L14.17 8H6v2h8.17l-1.58 1.58L14 13l4-4-4-4zM2 2h7V0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h7v-2H2V2z"
                  />
                </SvgIcon>
                {t('logOut', 'Log Out')}
              </MenuItem>
            )}
          </Grid>
          <Grid item container className={classes.mb1}>
            <Grid item>
              <Typography variant="body2">
                <b>{t(config.isWhitelabel ? 'poweredByKlarpayAG' : 'klarpayAG')}</b>
              </Typography>
            </Grid>
            <Grid item className={classes.dotContainer}>
              <Box component="span" display="flex">
                <Icon style={{ fontSize: '5px' }}>fiber_manual_record</Icon>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <b>CHE-402.707.584</b>
              </Typography>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item className={classes.changeMargin}>
              <Typography variant="body2" align="left" className={classes.address}>
                <span>{t('industriestrasse246300', 'Industriestrasse 24, 6300')}</span>
                <span>
                  {t('zugSwitzerlandAllRightsReserved', ' Zug, Switzerland. All rights reserved.')}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {t(
                  'regulatedByTheSwissFederalBankingAct',
                  'Regulated by the Swiss Federal Banking Act.',
                )}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item container spacing={1} className={classes.mt1}>
            <Grid item>
              <img
                src={process.env.PUBLIC_URL + '/img/Flag_of_Switzerland 1.png'}
                alt={'Flag_of_Switzerland'}
              />
            </Grid>
            <Grid item>{t('madeInSwitzerland', 'Made in Switzerland')}</Grid>
          </Grid>
          {process.env.REACT_APP_VERSION && (
            <Grid item container spacing={1} className={classes.mt1}>
              <Grid item>{`Version: ${process.env.REACT_APP_VERSION}`}</Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}
