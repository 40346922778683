import React, { FC, useCallback, useMemo, useState } from 'react'
import { ConfirmSignatureModal } from '../../components/Common/ActionsSign2FAModal'
import { Button, Grid } from '@material-ui/core'
import { LoadingButton } from '../../components'
import { ActionSignatureProps, canSignAction, canSignOrReject } from './contract'
import { useTranslation } from 'react-i18next'
import { useDetailsStyles } from './useDetailsStyles'
import { useCurrentUser } from '../../hooks'
import { ActionSignature, ActionType, DebitTransaction } from '../../graphql'

export const DetailsFooter: FC<{
  actionSignatureProps: ActionSignatureProps
  handleBack: () => void
  type?: ActionType
}> = ({ actionSignatureProps, handleBack, type }) => {
  const { t } = useTranslation()
  const classes = useDetailsStyles()
  const currentUser = useCurrentUser()
  const [actionModal, setActionModal] = useState<string | undefined>('')
  const signature =
    actionSignatureProps.rawData?.__typename === 'DebitTransaction'
      ? (actionSignatureProps.rawData as DebitTransaction)?.executionAction?.signatures?.find(
          (s) => s?.isMine,
        )
      : (actionSignatureProps.rawData as ActionSignature)

  const canSign = useMemo(
    () =>
      actionSignatureProps.rawData?.__typename === 'ActionSignature'
        ? canSignAction((actionSignatureProps.rawData as ActionSignature).action)
        : canSignOrReject(currentUser?.id, actionSignatureProps.signatures),
    [currentUser, actionSignatureProps],
  )

  const isShowCloseBtn = useMemo(() => {
    const validTypes = [
      'ActionSignature',
      'DebitTransaction',
      'AccountAction',
      'AddContractAuthorizedPersonRequestAction',
      'ContractAuthorizedPersonDeactivationAction',
      'ContractChangeAction',
      'DocumentAction',
    ]

    return !canSign && validTypes.includes(actionSignatureProps.rawData?.__typename as string)
  }, [canSign, actionSignatureProps.rawData?.__typename])

  const handleCloseModal = useCallback(
    (success?: boolean) => {
      setActionModal(undefined)
      if (success) {
        // handleBack()
      }
    },
    [handleBack],
  )

  return (
    <>
      {/* 2FA Modal */}
      {!!actionModal && (
        <ConfirmSignatureModal
          openAction={actionModal}
          handleClose={handleCloseModal}
          actionData={signature as ActionSignature}
          multipleActionsData={[]}
          type={type}
        />
      )}

      {canSign && (
        <Grid item container spacing={3} className={classes.control} data-test="groupBtn">
          <Grid item className={classes.innerControl}>
            <LoadingButton
              onClick={() => setActionModal('sign')}
              className={classes.btn}
              loading={false}
              variant="contained"
              color="primary"
              disableElevation
              data-test="sign"
            >
              {t('Sign', 'Sign')}
            </LoadingButton>
          </Grid>
          <Grid item className={classes.innerControl}>
            <Button
              onClick={() => setActionModal('reject')}
              variant="contained"
              disableElevation
              color="default"
              className={classes.btn}
              data-test="reject"
            >
              {t('Reject', 'Reject')}
            </Button>
          </Grid>
        </Grid>
      )}
      {isShowCloseBtn && (
        <Grid item container spacing={3} className={classes.control}>
          <Grid item className={classes.innerControl}>
            <Button
              onClick={handleBack}
              variant="contained"
              disableElevation
              color="default"
              className={classes.closeBtn}
            >
              {t('close', 'Close')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}
