import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Button, FormControl, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { InputAdornment } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { every, isEmpty } from 'lodash'

import { FormControlledTextField, GridRow } from '../../components'
import { currencies } from '../../stubs'
import { currenciesForSelectFunc } from '../../utils'
import { ContractProfileChangeAnswers } from '../../graphql'
import { makeTooltipTitleTextExpectedVolumes } from '../../components/NewBusinessApp/PreAssessmentQuestionnaire/helpers/functions'
import ControlledTooltipWrapped from '../../components/Common/Tooltips/ControlledTooltipWrapped'
import FormAutocompleteSelectNew from '../../components/Common/Selectors/FormAutocompleteSelectNew'
import { ExpectedVolumesErrorTypes } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  volume: {
    flexBasis: '47%',
    marginLeft: theme.spacing(2.5),
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      paddingRight: 15,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        transform: 'translate(0, 6px)',
      },
    },
    '& .MuiInputBase-input': {
      paddingRight: 5,
      '-moz-appearance': 'textfield',
      [theme.breakpoints.down('xs')]: {
        transform: 'translate(0, 6px)',
      },
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  removeButton: {
    margin: '10px 0px 0px 10px',
    padding: 0,
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '100%',
    },
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
  lightDivider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255,.3)',
    height: 3,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorItem: {
    paddingLeft: 15,
  },
}))

const EMPTY_CURRENCYVOLUME = { currency: '', expectedVolume: '' }

type ExpectedVolumesArray = Record<string, string>[]

export const PreAssessmentExpectedVolumes: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ readOnly = false, changedContractProfileData }) => {
  const fieldName = 'expectedVolumes'

  const { t } = useTranslation()
  const classes = useStyles()

  const methods = useFormContext()

  const { setValue, errors, control, formState, trigger } = methods

  const { fields, append, remove } = useFieldArray({ control, name: fieldName })

  const currencyVolumes = useWatch({
    name: fieldName,
    control,
  }) as ExpectedVolumesArray

  const [selectedCurrencies, setSelectedCurrencies] = useState<{ key: number; value: string }[]>([])

  const expectedVolumeSumPercent = useMemo(
    () =>
      currencyVolumes
        ? (currencyVolumes as ExpectedVolumesArray)?.reduce(
            (acc, field) => acc + Number(field.expectedVolume),
            0,
          )
        : 0,
    [currencyVolumes],
  )

  const isNoOptions = useMemo(() => Object.keys(currencies).length - fields?.length <= 0, [
    fields,
    currencies,
  ])

  const isNoSelectedCurrency = useMemo(
    () => currencyVolumes?.some((item: Record<string, string>) => !item.currency),
    [currencyVolumes],
  )

  const error = errors ? errors[`expectedVolumes`] : null

  const onAdd = useCallback(() => {
    append(EMPTY_CURRENCYVOLUME)
  }, [currencyVolumes, setValue])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      if (indexToRemove > 0) {
        remove(indexToRemove)
      }
    },
    [currencyVolumes, setValue, setSelectedCurrencies],
  )

  useEffect(() => {
    if (fields.length === 0) onAdd()
  }, [fields])

  useEffect(() => {
    if (
      !!currencyVolumes?.length &&
      every(currencyVolumes, ({ currency, expectedVolume }) => expectedVolume && currency) &&
      currencyVolumes[0].currency &&
      currencyVolumes[0].expectedVolume
    ) {
      trigger(fieldName)
    }
  }, [currencyVolumes])

  useEffect(() => {
    const selectedCurr: { key: number; value: string }[] = []
    fields.forEach((field, index) => {
      !!field.currency && selectedCurr.push({ key: index, value: field.currency })
    })
    setSelectedCurrencies(selectedCurr)
  }, [fields.length])

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <GridRow>
            {!isEmpty(fields) &&
              fields.map((field, index) => {
                return (
                  <Box className={classes.twoRows} key={field.id}>
                    <FormControl className={classes.formControl}>
                      <FormAutocompleteSelectNew
                        label={t('currency', 'Currency')}
                        name={`expectedVolumes[${index}].currency`}
                        data={currenciesForSelectFunc(selectedCurrencies, index)}
                        defaultValue={field.currency}
                        readOnly={readOnly}
                        onInputChange={() => trigger(fieldName)}
                        data-test="expectedVolumesCurrency"
                      />
                    </FormControl>
                    <FormControlledTextField
                      className={classes.volume}
                      label={t('expectedVolume', 'Expected Volume')}
                      name={`expectedVolumes[${index}].expectedVolume`}
                      defaultValue={field.expectedVolume}
                      fullWidth
                      required={false}
                      type="number"
                      inputProps={{
                        step: '1',
                        style: { textAlign: 'right' },
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                      }}
                      disabled={readOnly}
                      onInputChange={() => trigger(fieldName)}
                      data-test="expectedVolumesVolume"
                    />
                    {!readOnly && index !== 0 && index === fields?.length - 1 && (
                      <Box className={classes.buttonsBox}>
                        <Button
                          color="secondary"
                          aria-label="delete"
                          type="button"
                          onClick={() => onRemove(index)}
                          className={clsx(
                            index !== 0 && index === fields?.length - 1 ? '' : classes.hidden,
                          )}
                        >
                          {t('delete', 'Delete')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )
              })}
            {!!error && (
              <Grid className={classes.errorMessage} data-test="expectedVolume">
                <Box xs={6}>
                  {error.type === ExpectedVolumesErrorTypes.volumeIsNotEmptyCurrency && (
                    <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                  )}
                </Box>
                <Box xs={6} className={classes.errorItem}>
                  {error.type !== ExpectedVolumesErrorTypes.volumeIsNotEmptyCurrency && (
                    <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                  )}
                </Box>
              </Grid>
            )}
            {changedContractProfileData?.preAssessment?.currencyAndVolume && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.expectedVolumes}
                title={makeTooltipTitleTextExpectedVolumes(
                  changedContractProfileData?.preAssessment?.currencyAndVolume as string[],
                  { divider: classes.lightDivider, listItem: classes.listItem },
                )}
              />
            )}
            {!readOnly && (
              <Grid item className={classes.addButton}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={onAdd}
                  disabled={
                    expectedVolumeSumPercent >= 100 ||
                    !!error ||
                    isNoOptions ||
                    isNoSelectedCurrency
                  }
                >
                  {t('add', 'Add')}
                </Button>
              </Grid>
            )}
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}

export default PreAssessmentExpectedVolumes
