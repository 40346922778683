import { Box, ClickAwayListener, Tooltip } from '@material-ui/core'
import React, { FC } from 'react'
import { useControlledTooltip } from '../../../hooks/useContolledTooltip'
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  popperWrap: {
    pointerEvents: 'auto',
    maxHeight: '40vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
    '& +.MuiTooltip-arrow.left': {
      left: '10% !important',
    },
    '& +.MuiTooltip-arrow.center': {
      left: 'calc(50% - 7px) !important',
    },
    '& +.MuiTooltip-arrow.right': {
      left: '90% !important',
    },
  },
}))

type AdditionalProps = {
  arrowHorizontalPosition?: 'left' | 'center' | 'right'
}

const ControlledTooltip: FC<TooltipProps & AdditionalProps> = ({
  title,
  arrowHorizontalPosition,
  arrow = true,
  children,
  ...rest
}) => {
  const { tooltipListeners, tooltipOpen, handleTooltipClose } = useControlledTooltip()
  const classes = useStyles()
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        open={tooltipOpen}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        {...tooltipListeners}
        arrow
        classes={{ arrow: arrowHorizontalPosition ?? '' }}
        title={
          <Box {...tooltipListeners} className={classes.popperWrap}>
            {title}
          </Box>
        }
        {...rest}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  )
}
export default ControlledTooltip
