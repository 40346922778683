import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { setTabProps } from '../../utils'

import { PersonalDetails, SecureDocumentUpload, Settings } from './Tabs'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: '100%',
    paddingTop: 30,
  },
  root: {
    flexGrow: 1,
    '& .MuiPaper-elevation4': {
      boxShadow: 'none',
    },
    '& .MuiTab-wrapper': {
      textTransform: 'none',
      fontFamily: 'basier_square',
      size: '16px',
      lineHeight: '24px',
    },
    '& .MuiButtonBase-root': {
      marginRight: '1rem',
    },
    '& .Mui-selected': {
      fontWeight: '700 !important',
    },
  },
  border: {
    border: 'none !important',
  },
}))

export const PersonalProfilePage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (_: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Grid className={classes.mainContainer}>
      <Grid item>
        <div className={classes.root}>
          <AppBar position="static" color="inherit">
            <Tabs
              className={classes.border}
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              textColor="primary"
            >
              <Tab label={t('digitalSignatures', 'Digital Signatures')} {...setTabProps(0)} />
              <Tab
                label={t('secureDocumentUploadSmall', 'Secure document upload')}
                {...setTabProps(1)}
              />
              <Tab label={t('personalDetails', 'Personal Details')} {...setTabProps(2)} />
              <Tab label={t('ettings', 'Settings')} {...setTabProps(3)} />
            </Tabs>
          </AppBar>
        </div>
        <TabPanel value={value} index={0} />
        <TabPanel value={value} index={1}>
          <SecureDocumentUpload />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PersonalDetails />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Settings />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default PersonalProfilePage
