import React, { FC, useEffect } from 'react'
import { NumericFormat } from 'react-number-format'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { FormInputFieldEntity } from '../../../types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { fullWidth: boolean }>((theme: Theme) => ({
  numeric: {
    '& .MuiInputBase-input': {
      '-moz-appearance': 'textfield',
      padding: '16px 12px 0 12px',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  label: {
    zIndex: 1,
    ...theme.typography.body1,
    position: 'absolute',
    top: 16,
    left: 0,
    lineHeight: '14px',
    color: '#999',
    display: 'block',
    paddingLeft: 14,
    paddingRight: 14,
    transformOrigin: 'top left',
    transform: 'translate(0, 24px) scale(1)',
    transition:
      'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  shrink: {
    '&.setShrink': {
      transform: 'translate(0, 12px) scale(0.857)',
    },
    transformOrigin: 'top left',
  },
  labelError: {
    color: theme.palette.error.main,
  },
  formControl: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
    position: 'relative',
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
}))

interface NumericFormatType {
  helperText?: React.ReactNode
  error?: boolean
  decimalScale?: number
  fixedDecimalScale?: boolean
  allowNegative?: boolean
  textAlignRight?: boolean
  thousandSeparator?: string | boolean
  allowLeadingZeros?: boolean
  fullWidth?: boolean
  className?: string
  type?: 'text' | 'tel' | 'password'
}

export const FormNumericFormat: FC<FormInputFieldEntity & NumericFormatType> = ({
  name,
  label,
  placeholder,
  defaultValue = '',
  decimalScale = 0,
  fixedDecimalScale = true,
  allowNegative = false,
  thousandSeparator = ',',
  textAlignRight = false,
  allowLeadingZeros = false,
  fullWidth = false,
  className,
  type = 'text',
  ...rest
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ fullWidth })
  const { control, watch, setValue, errors } = useFormContext()

  const error = errors ? errors[name] : null

  const currentValue = watch(name)

  const setFormValue = (
    val: unknown,
    config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }> | undefined,
  ) => {
    if (typeof val === 'string' || typeof val === 'number') {
      setValue(name, parseFloat(val?.toString()?.replaceAll(',', '')), config)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setFormValue(defaultValue)
    }
  }, [])

  return (
    <FormControl
      className={clsx(classes.formControl, className)}
      onBlur={() => {
        if (currentValue !== '') {
          setFormValue(currentValue, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      }}
    >
      <Controller
        as={
          <NumericFormat
            label={label}
            type={type}
            customInput={TextField}
            error={error}
            helperText={error && t(error.message)}
            inputProps={{
              style: { textAlign: textAlignRight ? 'right' : 'left' },
            }}
          />
        }
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue}
        className={classes.numeric}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={fixedDecimalScale}
        decimalScale={decimalScale}
        allowNegative={allowNegative}
        placeholder={placeholder}
        allowLeadingZeros={allowLeadingZeros}
        {...rest}
      />
    </FormControl>
  )
}
