import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { isEmpty, toNumber } from 'lodash'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import clsx from 'clsx'

import { Box, Collapse, Grid, Link, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import { ReactComponent as IconDeviceWarning } from '../../assets/images/icons/iconDeviceWarning.svg'
import { docDescriptionOrActionType } from '../../pages/AplicationActions/contract'
import { ReactComponent as IconDevice } from '../../assets/images/icons/2faIcon.svg'
import { ReactComponent as IconX } from '../../assets/images/icons/icon-x.svg'
import { SettingsTabsEnum } from '../../types'
import { checkFullCode, currencyFormat, getCurrencySign } from '../../utils'
import { resendDelay } from '../../graphql/local'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { InputCodeField } from './InputCode'
import {
  ActionSignature,
  ActionSignatureStatus,
  ActionSigningCodeResponse,
  ActionType,
  DebitTransaction,
  TransactionAction,
  TwoFaCodeDispatchMode,
  TwoFaMethod,
  useGetActionSigningCodeMutation,
  useSignActionWithChallengeCodeMutation,
  useSignMultipleActionsWithChallengeCodeMutation,
} from '../../graphql'
import { useCurrentUser } from '../../hooks'
import { MODAL_WRAPPER_ZINDEX, TOTP_STRING_PARAM } from '../../constants'
import { TwoFAModalRenderButton } from './TwoFAModalRenderButton'
import { generatePath, useHistory } from 'react-router-dom'
import { personalProfileSettingsTab } from '../../graphql/local/dashboard'
import { apolloClient } from '../../index'

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
    padding: theme.spacing(8, 6, 3.5),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 4.5),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
        position: 'absolute',
        left: '0',
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: 'rgb(239, 40, 40)',
        position: 'absolute',
        left: '0',
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  actionName: {
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(0, 2),
    textDecoration: 'underline',
    fontSize: 14,
    lineHeight: '24px',
  },
  btnChange2FA: {
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    margin: theme.spacing(2, 0, 0, 0),
  },
  btnChange: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }

export const ConfirmSignatureModal: FC<{
  openAction: string | undefined
  handleClose: (success?: boolean) => void
  actionData: ActionSignature | undefined
  children?: React.ReactNode
  multipleActionsData: ActionSignature[]
  setAddAccountSuccess?: (value: boolean) => void
  makeAlertMessage?: () => void
  type?: ActionType
}> = ({
  openAction,
  setAddAccountSuccess,
  handleClose,
  actionData,
  multipleActionsData,
  makeAlertMessage,
  children,
  type,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassege, setValidMassege] = useState<string>('')
  const [successClose, setSuccessClose] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const startInput = useRef<HTMLInputElement>(null)
  const resultCheckFullCode = checkFullCode(code)
  const currentUser = useCurrentUser()

  const [
    getActionSigningCodeMutation,
    { data: actionServiceData },
  ] = useGetActionSigningCodeMutation()

  const [
    signActionWithChallengeCodeMutation,
    { loading },
  ] = useSignActionWithChallengeCodeMutation()

  const [signMultipleActionsWithChallengeCode] = useSignMultipleActionsWithChallengeCodeMutation()

  const refetchQueriesArr = useMemo(() => {
    const standartQueriesNamesArr = ['getActionDetails', 'actionSignatureRequestsCount']
    const batchRefetchArr = ['getTransactions', 'getContractTransactionsCount']
    const cardsRefetchArr = ['cardsList', 'cardsCount']
    const authPersonsRefetchArr = ['contractAuthorizedPersons']
    switch (type) {
      case ActionType.TransactionSigning:
        return [...standartQueriesNamesArr, 'getTransactionDetails']
      case ActionType.BatchPayment:
        return [...standartQueriesNamesArr, ...batchRefetchArr]
      case ActionType.CardIssueRequest:
        return [...standartQueriesNamesArr, ...cardsRefetchArr]
      case ActionType.ContractAuthorizedPersonRequest:
      case ActionType.ContractAuthorizedPersonDeactivation:
        return [...standartQueriesNamesArr, ...authPersonsRefetchArr]
      default:
        return standartQueriesNamesArr
    }
  }, [type])

  const confirmSignatureMessage = useMemo(() => {
    return currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>
          {!isEmpty(actionServiceData)
            ? `${actionServiceData?.getActionSigningCode?.phoneLastFourDigits?.slice(2)}`
            : ''}
        </b>
        . {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [actionServiceData, currentUser?.primaryTwoFAMethod])

  const delayedQueriesRefetch = useCallback(() => {
    setTimeout(async () => {
      await apolloClient.refetchQueries({
        include: refetchQueriesArr,
      })
    }, 5000)
  }, [refetchQueriesArr])
  const handleActionServiceData = useCallback(
    async (dispatchMode?: TwoFaCodeDispatchMode) => {
      try {
        const { data } = await getActionSigningCodeMutation({ variables: { mode: dispatchMode } })
        if (data) {
          const {
            resendTimeout,
            attemptsLeft,
          } = data?.getActionSigningCode as ActionSigningCodeResponse
          resendDelay((resendTimeout as number) * 1000)
          setAttemptsLeft(attemptsLeft as number)
        }
      } catch (e) {
        toast.error((e as Error).message)
        handleClose(false)
      }
    },
    [getActionSigningCodeMutation, handleClose],
  )

  const onReSend = useCallback(
    async (dispatchMode?: TwoFaCodeDispatchMode) => {
      if (actionData) {
        setCode(initialValue)
        setIsError(false)
        setBtnDisable(true)
        setSecondsLeft(millisecondsToSeconds(resendDelay()))
        setValidMassege('')
        handleActionServiceData(dispatchMode).then()
      }
    },
    [actionData, handleActionServiceData],
  )

  const tryToReturnToReferer = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const referer = urlParams.get('referer')
    if (referer) {
      setTimeout(() => {
        history.push(referer)
      }, 4000)
    }
  }, [window.location.search])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  const onSendCode = useCallback(
    async (id, code) => {
      try {
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        await signActionWithChallengeCodeMutation({
          variables: {
            actionSignatureId: id,
            code: code,
            challengeId: `${challengeId}`,
            status:
              openAction === 'sign' ? ActionSignatureStatus.Accept : ActionSignatureStatus.Reject,
          },
        })

        delayedQueriesRefetch()

        setIsError(false)
        setValidMassege(t('codeCorrect', 'Code is correct'))
        setSuccessClose(true)
        setAddAccountSuccess && setAddAccountSuccess(true)
        tryToReturnToReferer()
      } catch (e) {
        setIsError(true)
        if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
          setValidMassege(t('codeHasExpired', 'Code has expired'))
        } else {
          setValidMassege(t('codeIncorrect', 'Code is incorrect'))
        }
      }
    },
    [
      actionServiceData,
      openAction,
      refetchQueriesArr,
      signActionWithChallengeCodeMutation,
      t,
      currentUser?.primaryTwoFAMethod,
    ],
  )

  const onSendCodeSignMultiple = useCallback(
    async (code) => {
      try {
        const signActionIds = multipleActionsData.map((actionSignature) => actionSignature.id)

        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        await signMultipleActionsWithChallengeCode({
          variables: {
            actionSignatureIds: signActionIds,
            status:
              openAction === 'sign' ? ActionSignatureStatus.Accept : ActionSignatureStatus.Reject,
            challengeId: `${challengeId}`,
            code: code,
          },
        })

        delayedQueriesRefetch()

        setValidMassege(t('codeCorrect', 'Code is correct'))
        setSuccessClose(true)
        tryToReturnToReferer()
      } catch (e) {
        setIsError(true)
        setValidMassege(t('codeIncorrect', 'Code is incorrect'))
      }
    },
    [
      actionServiceData,
      multipleActionsData,
      refetchQueriesArr,
      signMultipleActionsWithChallengeCode,
      t,
      currentUser?.primaryTwoFAMethod,
    ],
  )

  const isMultipleActions = useMemo(() => {
    return !isEmpty(multipleActionsData) && multipleActionsData.length >= 2
  }, [actionData, multipleActionsData])

  const [actionAmount, actionName, actionCurrency] = useMemo(() => {
    if (isMultipleActions || !actionData?.action) return ['-', '-', '']

    const { transaction } = actionData?.action as TransactionAction
    switch (actionData?.action?.__typename) {
      case 'AccountAction':
        return [
          actionData.action.account?.balance?.available,
          actionData.action.account?.alias,
          actionData.action.account?.currency,
        ]
      case 'TransactionAction':
        return [
          actionData.action.transaction?.value,
          (transaction as DebitTransaction)?.to?.name,
          actionData.action.transaction?.currency,
        ]
      default:
        return ['-', '-', '']
    }
  }, [actionData])

  const handleChange2FA = useCallback(() => {
    personalProfileSettingsTab(2)
    history.push(
      generatePath(APP_PATHS.dashboard.settings, {
        [PATH_PARAMS.applicationId]: applicationId,
      }) + `?tabId=${SettingsTabsEnum.twoFactor}`,
    )
  }, [personalProfileSettingsTab, applicationId])

  useEffect(() => {
    if (
      openAction &&
      (actionData || !isEmpty(multipleActionsData)) &&
      currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
    ) {
      handleActionServiceData().then()
    }
  }, [openAction, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    if (successClose) {
      const timeoutClose = setTimeout(() => {
        handleClose(true)
        setAddAccountSuccess && setAddAccountSuccess(false)
        makeAlertMessage && makeAlertMessage()
      }, 3500)

      return () => {
        clearTimeout(timeoutClose)
      }
    }
  }, [handleClose, successClose, makeAlertMessage])

  useEffect(() => {
    const enteredCode = Object.values(code).join('')
    if (
      enteredCode.length === 6 &&
      (!isEmpty(actionServiceData) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp)
    ) {
      if (actionData) {
        onSendCode(actionData.id, enteredCode).then()
      }

      if (!isEmpty(multipleActionsData)) {
        onSendCodeSignMultiple(enteredCode).then()
      }
    }
  }, [code, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMassege('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  return (
    <Box>
      <Modal
        style={{ zIndex: MODAL_WRAPPER_ZINDEX }}
        open={!!openAction}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test="actionsSignModal"
      >
        <Box
          className={clsx(
            classes.paper,
            resultCheckFullCode && isError && 'error',
            validMassege && !isError && 'success',
          )}
        >
          <Box className={classes.wrap}>
            <IconButton
              className={classes.btnClose}
              color="primary"
              aria-label="close modal"
              component="span"
              onClick={() => handleClose(false)}
            >
              <IconX />
            </IconButton>

            <Grid className="iconContainer">
              {!isError ? <IconDevice /> : <IconDeviceWarning />}
            </Grid>

            {openAction === 'sign' ? (
              <Box textAlign="center" mb={1}>
                <Typography variant={'h2'}>{t('confirmSignature', 'Confirm signature')}</Typography>
              </Box>
            ) : (
              <Box textAlign="center" mb={1}>
                <Typography variant={'h2'}> {t('rejectSignature', 'Reject signature')}</Typography>
              </Box>
            )}

            {!!children && <Box textAlign="left">{children}</Box>}

            {actionData?.action &&
              actionData?.action.type === ActionType.TransactionSigning &&
              !isMultipleActions && (
                <Box textAlign="left">
                  <Typography>
                    {t('paymentSingle2FALabel', {
                      name: actionName,
                      amount: `${getCurrencySign(actionCurrency)} ${currencyFormat(
                        Math.abs(toNumber(actionAmount)),
                      )}`,
                    })}
                  </Typography>
                </Box>
              )}

            {actionData?.action &&
              (actionData?.action.type !== ActionType.TransactionSigning || isMultipleActions) && (
                <Box textAlign="left">
                  <Typography>
                    {t(
                      'signDocs2FA',
                      'Please approve the selected action. You won’t be able to revoke this action.',
                    )}
                  </Typography>
                </Box>
              )}
            <ul className={classes.actionName}>
              {isMultipleActions &&
                multipleActionsData.map((action) => {
                  return (
                    <li key={action?.action?.id}>{docDescriptionOrActionType(action?.action)}</li>
                  )
                })}
            </ul>

            <Box height={48}>
              <Collapse
                in={
                  !isEmpty(actionServiceData) ||
                  currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp
                }
              >
                <Box textAlign="left">{confirmSignatureMessage}</Box>
              </Collapse>
            </Box>

            <InputCodeField
              startInput={startInput}
              currencyValue={code}
              setCode={handleSetCode}
              loading={loading}
            />

            <Grid container justifyContent="center" className="infoText" data-test="validMassege">
              {!!validMassege && resultCheckFullCode && <span>{validMassege}</span>}
            </Grid>

            <TwoFAModalRenderButton
              isNotShow={currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp}
              secondsLeft={secondsLeft}
              btnDisable={btnDisable}
              onReSend={onReSend}
              isShowContactSupportLink={attemptsLeft < 1}
            />
            <Grid item xs={12} className={classes.btnChange}>
              <Link
                component="button"
                onClick={handleChange2FA}
                className={classes.btnChange2FA}
                underline="always"
              >
                {t('changeVerificationMethod', 'Change verification method')}
              </Link>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
