import React, { FC, ReactNode } from 'react'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    arrowDropDown: {
      margin: '0 0 0 15px',
    },
  }),
)

const ClosedInfoBlockComponent: FC<{
  handleToggle: () => void
  tableOpen: boolean
  children: ReactNode
}> = ({ handleToggle, tableOpen, children }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid className={clsx(`header ${tableOpen ? 'open' : ''}`)} onClick={handleToggle}>
      <Grid>{children}</Grid>
      <Grid>
        <Typography paragraph={true}>
          {tableOpen ? t('minimize') : t('show')}
          {tableOpen ? (
            <ArrowDropUp className={classes.arrowDropDown} />
          ) : (
            <ArrowDropDown className={classes.arrowDropDown} />
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const ClosedInfoBlock = React.memo(ClosedInfoBlockComponent, isEqual)
