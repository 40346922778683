import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ALL_EXCEPT_CYRILLIC_REGEXP } from '../../../constants/validations'
import { FormInputFieldEntity } from '../../../types'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import { get } from 'lodash'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  general: {
    [theme.breakpoints.only('md')]: {
      '& label': {
        top: '-7px',
      },
    },
  },
}))

export const FormControlledTextField: React.FC<FormInputFieldEntity> = ({
  type,
  name,
  label,
  placeholder,
  defaultValue = '',
  onInputChange,
  ...rest
}) => {
  const { t } = useTranslation()
  const { control, errors } = useFormContext()
  const error = errors ? get(errors, name) : null
  const classes = useStyles()

  return (
    <Controller
      render={({ onChange, ...other }) => (
        <TextField
          {...other}
          className={clsx(classes.general)}
          label={label}
          type={!!type ? type : 'text'}
          placeholder={placeholder}
          error={!!error}
          onKeyDown={(event) => {
            if (!ALL_EXCEPT_CYRILLIC_REGEXP.test(event.key)) {
              event.preventDefault()
            }
          }}
          onChange={(e) => {
            onChange(e)
            if (onInputChange) {
              onInputChange(e)
            }
          }}
          helperText={error && t(error.message)}
          variant="standard"
          size="small"
          margin="normal"
          InputProps={{
            margin: 'none',
          }}
          fullWidth={true}
          {...rest}
        />
      )}
      id={name}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  )
}
