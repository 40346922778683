import { Box, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { ContractChanges } from '../../types'
import { getLabelByKey } from '../../utils'
import { BusinessCategoriesList, CountriesUnknownList } from '../../utils/Data'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'
import { omit, isArray } from 'lodash'
import i18next from 'i18next'
import {
  anticipatedAccountActivities,
  economicProfileSourceFundsTypes,
  economicProfileProducts,
} from '@klarpay/enums'

export const ChangesValue: FC<{ diffData: ContractChanges; type: string }> = ({
  diffData,
  type,
}) => {
  const value = diffData?.[type as keyof ContractChanges]

  let markUp = <></>

  if (typeof value === 'string' || typeof value === 'number') {
    markUp = <>{value}</>
  }

  if (Array.isArray(value) && value.length) {
    if (typeof value[0] !== 'object') {
      markUp =
        (value as string[]).length !== 1 ? (
          <ControlledTooltipWrapped title={parseStringsArr(value)} arrowHorizontalPosition={'left'}>
            <Box>{`${parseStringsArr(value).split(',')[0]}...`}</Box>
          </ControlledTooltipWrapped>
        ) : (
          <>{`${parseStringsArr(value)}`}</>
        )
    } else {
      const logoText = !!(value[0] as Record<string, never>)?.name
        ? `${(value[0] as Record<string, never>)?.name}`
        : `${Object.values(omit(value[0], '__typename')).join(' ')}`
      markUp = (
        <ControlledTooltipWrapped title={parseObjArr(value)} arrowHorizontalPosition={'left'}>
          <Box>{logoText + '...'}</Box>
        </ControlledTooltipWrapped>
      )
    }
  }

  if (
    typeof value === 'object' &&
    (value as Record<string, unknown>)?.__typename === 'TotalPredictedMonthlyVolumes'
  ) {
    const logoText = !!(value as Record<string, never>)?.name
      ? `${(value as Record<string, never>)?.name}`
      : `${Object.values(omit(value, '__typename')).join(' ')}`
    markUp = (
      <ControlledTooltipWrapped
        title={parseObj(value as Record<string, unknown>)}
        arrowHorizontalPosition={'left'}
      >
        <Box>{logoText + '...'}</Box>
      </ControlledTooltipWrapped>
    )
  }

  return markUp
}

function parseStringsArr(arr: unknown[]): string {
  const enums = {
    ...anticipatedAccountActivities,
    ...economicProfileSourceFundsTypes,
    ...economicProfileProducts,
  }
  return arr.map((value) => enums[value as string]?.label || i18next.t(value as string)).join(', ')
}
function parseObj(object: Record<string, unknown>) {
  return (
    <List disablePadding>
      {Object.keys(object).map((key, index) => {
        if (key === '__typename') return null

        return (
          <React.Fragment key={index}>
            <ListItem key={`${key + index}`}>
              <ListItemText
                primary={
                  <>
                    <Typography
                      component={'span'}
                      style={{
                        display: 'inline-block',
                        fontWeight: 'bold',
                        marginRight: '4px',
                      }}
                    >
                      {i18next.t(key)}:
                    </Typography>
                    {object[key]}
                  </>
                }
              />
            </ListItem>
            <Divider
              style={{
                margin: '4px',
                backgroundColor: 'rgba(255, 255, 255,.3)',
                height: 3,
              }}
            />
          </React.Fragment>
        )
      })}
    </List>
  )
}

function parseObjArr(arr: Record<string, unknown>[]): NonNullable<React.ReactNode> {
  const parser = (objItem: Record<string, unknown>, key: string) => {
    switch (key) {
      case 'country':
      case 'bankCountry':
        return getLabelByKey(objItem[key] as string, CountriesUnknownList)
      case 'businessSector':
        return getLabelByKey(objItem[key] as string, BusinessCategoriesList)
      default:
        return objItem[key]
    }
  }

  return (
    <List disablePadding>
      {arr.map((objItem, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && (
              <Divider
                style={{
                  margin: '4px',
                  backgroundColor: 'rgba(255, 255, 255,.3)',
                  height: 3,
                }}
              />
            )}
            {Object.keys(objItem).map((key, index) => {
              if (key === '__typename') return null

              const translatedValue = isArray(objItem[key])
                ? parseStringsArr(objItem[key] as (string | unknown)[])
                : parser(objItem, key)

              return (
                <ListItem key={`${key + index}`}>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          component={'span'}
                          style={{
                            display: 'inline-block',
                            fontWeight: 'bold',
                            marginRight: '4px',
                          }}
                        >
                          {i18next.t(key)}:
                        </Typography>
                        {translatedValue}
                      </>
                    }
                  />
                </ListItem>
              )
            })}
          </React.Fragment>
        )
      })}
    </List>
  )
}
