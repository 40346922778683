import React, { FC, useEffect, useState } from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import SlotCounter from 'react-slot-counter'
import { useGetContractAccountsQuery } from '../../../graphql'
import { useGetBalanceCurrency } from '../../../hooks'
import { useStaticFXRate } from '../../../hooks/useFXRate'
import { PATH_PARAMS } from '../../../routes/paths'
import { ControlledTextFieldProps } from '../../../types'
import { currencyFormat, negativeCheck } from '../../../utils'
import { defaultCurrency } from '../../../utils/Data'
import { CurrencySelector } from '../CurrencySelector'
import { Loader } from '../Loader'

const useStyles = makeStyles((theme: Theme) => ({
  inputWrap: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'right',
  },
  inputLabel: {
    position: 'static',
    width: '100%',
    height: '16px',
    textAlign: 'right',
    left: '0px',
    top: '0px',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#595959',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  totalBalance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0px',
    position: 'static',
    // width: '291px',
    height: '60px',
    left: '837px',
    top: '0px',
    background: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end',
      borderTop: '1px solid #F0F0F0',
      position: 'relative',
      left: '0',
      height: '88px',
      padding: '20px 0 0 0',
    },
  },
  amount: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'static',
    //width: '161px',
    height: '40px',
    left: '0px',
    top: '0px',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '40px',
    color: '#000000',
  },
  totalBalanceValue: {
    fontSize: '20px',
    lineHeight: '1.7',
    transform: 'translate(0, -4px)',
  },
}))

export const TotalBalance: FC<Partial<ControlledTextFieldProps>> = () => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const balanceCurrency = useGetBalanceCurrency()

  const [currencyKey, setCurrencyKey] = useState<string>(balanceCurrency ?? defaultCurrency)
  const [totalBalance, setTotalBalance] = useState<Record<string, string | number | undefined>>()

  const { t } = useTranslation()
  const classes = useStyles()

  const { data: accountsData, loading: loadingContractAccount } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
    },
  })

  useEffect(() => {
    if (balanceCurrency && balanceCurrency !== currencyKey) {
      setCurrencyKey(balanceCurrency)
    }
  }, [])

  const [fxConverter, loading] = useStaticFXRate(applicationId, currencyKey)

  useEffect(() => {
    if (accountsData) {
      const selectedAccountBalance = accountsData.accounts?.reduce((accumulator, current) => {
        if (current?.balance?.available) {
          const xRate = fxConverter(current?.currency, currencyKey) || 1

          return (
            accumulator +
            (current?.currency?.toUpperCase() === currencyKey?.toUpperCase()
              ? current?.balance?.available
              : current?.balance?.available * xRate)
          )
        }

        return accumulator
      }, 0)

      setTotalBalance({
        key: currencyKey,
        amount: selectedAccountBalance,
      })
    }
  }, [accountsData, currencyKey, fxConverter])

  if (loadingContractAccount)
    return (
      <Box>
        <Loader />
      </Box>
    )

  const totalBalanceAmount = +(totalBalance?.amount || 0)
  const totalBalanceAmountFormatted = currencyFormat(totalBalanceAmount)

  return (
    <Box className={classes.totalBalance}>
      <Box className={classes.amount}>
        <Box>
          <Box className={classes.inputLabel}>{t('totalBalance', 'Total balance')}</Box>
          <Box className={classes.inputWrap}>
            <Typography
              className={clsx(classes.totalBalanceValue, negativeCheck(totalBalance?.amount))}
              variant={'h3'}
            >
              {loading ? (
                <Loader size={20} />
              ) : (
                <SlotCounter
                  value={totalBalanceAmountFormatted}
                  debounceDelay={500}
                  animateUnchanged
                />
              )}
            </Typography>
          </Box>
        </Box>
        <CurrencySelector currencyKey={currencyKey} setCurrencyKey={setCurrencyKey} />
      </Box>
    </Box>
  )
}
