import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { RadioGroup } from '@material-ui/core'

interface FormRadioEntity {
  name: string
  value?: string
  onChange?: (event?: React.ChangeEvent) => void
}

export const FormRadioGroup: React.FC<FormRadioEntity> = (props) => {
  const { control } = useFormContext()
  return (
    <Controller
      as={
        <RadioGroup name={props.name} value={props.value} onChange={props.onChange}>
          {props.children}
        </RadioGroup>
      }
      name={props.name}
      control={control}
      defaultValue={props.value}
    />
  )
}
