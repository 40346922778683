import { Box, makeStyles, Typography } from '@material-ui/core'
import { isPast, isToday, isValid } from 'date-fns'
import { get } from 'lodash'
import React from 'react'
import { IFileWithMeta } from 'react-dropzone-uploader'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Attention } from '../../../../assets/images/icons/attention.svg'
import { dateRequiredFieldsList } from '../../../../utils/Data'
import '../../../PersonalProfile/Tabs/styles.css'

const useStyles = makeStyles((theme) => ({
  modalErrorMessage: {
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
    border: '1px solid #fed7d2',
    marginTop: 12,
  },
  iconErrorMessage: {
    padding: theme.spacing(0, 1.5, 0, 0),
  },
}))

const isDateValid = (datePicker: Date | undefined) =>
  datePicker && isValid(datePicker) && !isToday(datePicker) && !isPast(datePicker)

export const ErrorPanel: React.FC<{ files: IFileWithMeta[] }> = ({ files }) => {
  const { watch } = useFormContext()
  const { t } = useTranslation()
  const values = watch()

  const isExpiredDate = files
    .map(({ meta: { id } }) => get(values, `documents.${id}.expiryDate`))
    .some(
      (expiryDate) =>
        expiryDate && isValid(expiryDate) && (isPast(expiryDate) || isToday(expiryDate)),
    )

  const missingDocType = files
    .map(({ meta: { id } }) => get(values, `documents.${id}.docType`))
    .some((docType) => !docType)

  const missingDate = files
    .map(({ meta: { id } }) => ({
      docType: get(values, `documents.${id}.docType`),
      expiryDate: get(values, `documents.${id}.expiryDate`),
    }))
    .some(
      ({ docType, expiryDate }) =>
        docType && dateRequiredFieldsList.includes(docType) && !isDateValid(expiryDate),
    )

  const errorMessage =
    missingDocType || missingDate
      ? `${t('PleaseSelect')} ${[
          missingDocType && t('documentType', 'Document Type'),
          missingDate && t('expiryDate', 'Expiry date'),
        ]
          .filter(Boolean)
          .join(' ' + t('and') + ' ')}`
      : undefined

  const classes = useStyles()

  return (
    <>
      {files.some((file) => file.meta.status === 'error_file_size') && (
        <Box className={classes.modalErrorMessage}>
          <Box component="span" className={classes.iconErrorMessage}>
            <Attention />
          </Box>
          <Typography>
            {t('fileExceedsMaximumSize', 'File exceeds maximum size of 15 MB')}
          </Typography>
        </Box>
      )}
      {files.some((file) => file.meta.status === 'rejected_file_type') && (
        <Box className={classes.modalErrorMessage}>
          <Box component="span" className={classes.iconErrorMessage}>
            <Attention />
          </Box>
          <Typography>
            {t('wrongDocumentFormat', 'Wrong document format. PDF, JPG and PNG are supported')}
          </Typography>
        </Box>
      )}
      {(isExpiredDate || !!errorMessage) && (
        <Box className={classes.modalErrorMessage}>
          <Box component="span" className={classes.iconErrorMessage}>
            <Attention />
          </Box>
          <Box>
            {isExpiredDate && (
              <Typography>{t('dateExpiryPast', "Expiry date can't be in the past")}</Typography>
            )}
            {errorMessage && <Typography>{errorMessage}</Typography>}
          </Box>
        </Box>
      )}
    </>
  )
}
