import { subYears } from 'date-fns'
import React, { FC, useEffect } from 'react'
import { Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { limitAge } from '../../../constants'

import { FormControlledTextField, GridRow } from '../../Common'
import { CountryPhoneSelect, FormAutocompleteSelect, nationalitiesArray } from '../../Common'
import { FormDatePickerField, AddressAutocomplete } from '../../Common'
import { Individual } from '../../../graphql'
import { registeredAddressEmptyProps } from '../../../stubs'

const useStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
}))

export const NaturalPersonForm: FC<{
  personData?: Individual | undefined | null
}> = ({ personData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { watch, trigger, reset } = useFormContext()

  const phoneNum = watch('phoneNumber')
  useEffect(() => {
    if (personData) {
      reset({
        firstName: personData.firstName,
        lastName: personData.lastName,
        email: personData.email,
        phoneNumber: personData.phone,
        country: personData.address?.country,
        birthday: personData.birthday,
        streetAddress: personData.address?.line1 ?? '',
        additionalDetailsOptional: personData.address?.additionalDetails,
        postalCode: personData.address?.zip,
        city: personData.address?.city,
        nationality: personData.nationality,
      })
    }
  }, [personData, reset])

  return (
    <>
      <Box className={classes.titleWrap}>
        <Typography variant={'h2'} className={classes.title}>
          {t('personalDetails', 'Personal Details')}
        </Typography>
      </Box>
      <GridRow>
        <FormControlledTextField
          label={t('firstNames', 'First Name(s)')}
          name="firstName"
          type="text"
          fullWidth
          required={false}
          data-test="firstName"
        />
      </GridRow>

      <GridRow>
        <FormControlledTextField
          label={t('lastName', 'Last Name')}
          name="lastName"
          type="text"
          fullWidth
          required={false}
          data-test="lastName"
        />
      </GridRow>

      <GridRow>
        <FormControlledTextField
          label={t('emailAddress', 'Email Address')}
          name="email"
          type="text"
          fullWidth
          required={false}
          data-test="emailAddress"
        />
      </GridRow>

      <GridRow>
        <Box onBlur={() => trigger('birthday')}>
          <FormDatePickerField
            name="birthday"
            label={t('dateOfBirth', 'Date of Birth')}
            isShiftTimezone={false}
            maxDate={subYears(new Date(), limitAge)}
          />
        </Box>
      </GridRow>

      <Box onBlur={() => trigger('country')}>
        <AddressAutocomplete
          customCountryLabel={t('countryOfResidence', 'Country of Residence')}
          props={registeredAddressEmptyProps}
        />
      </Box>

      <GridRow>
        <Box mt={2.5} onBlur={() => trigger('nationality')}>
          <FormAutocompleteSelect
            name="nationality"
            label={t('nationality', 'Nationality')}
            data={nationalitiesArray}
            data-test="nationality"
          />
        </Box>
      </GridRow>

      <GridRow>
        <Box mt={5} onBlur={() => trigger('phoneNumber')}>
          <CountryPhoneSelect
            name="phoneNumber"
            label={t('phoneNumber', 'Phone Number')}
            defaultValue={phoneNum}
            data-test="phoneNumber"
          />
        </Box>
      </GridRow>
    </>
  )
}
