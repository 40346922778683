import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { IFileWithMeta, IMeta } from 'react-dropzone-uploader'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { last } from 'lodash'
import clsx from 'clsx'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { documentTypes } from '@klarpay/enums'
import { Alert, Skeleton } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import { Maybe } from 'graphql/jsutils/Maybe'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { ContractInfRequestSchema } from '../../../schemes/ContractInfRequestSchema'
import { addCategoriesToShortLabel } from '../../../utils/Data'
import { FormControlledTextField } from '../../Common'
import { DocumentUpload } from './DocumentUpload'
import {
  ContractInformationRequestStatus,
  ContractInformationRequestType,
  DocumentRelatedEntityType,
  DocumentType,
  useCreateOwnDocumentMutation,
  useGetContractInformationRequestQuery,
  useUpdateContractInformationRequestMutation,
} from '../../../graphql'
import { FileIcon } from '../../Common/FileIcon'

const useStyles = makeStyles(() => ({
  wrap: {
    '& .MuiBox-root': {
      maxHeight: 'unset',
    },
  },
  content: {
    overflow: 'hidden',
  },
}))

const useLinkStyles = makeStyles(() => ({
  link: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    gap: '4px',
    alignItems: 'center',
  },
}))

const DocumentLink: React.FC<{
  url?: Maybe<string>
  mimeType?: Maybe<string>
  fileName?: Maybe<string>
}> = ({ url, fileName, mimeType }) => {
  const classes = useLinkStyles()

  return (
    <Link href={url ?? '#'} className={classes.link} target="_blank">
      <Box width={48} height={48}>
        <FileIcon mimeType={mimeType ?? last(fileName?.split('.'))} />
      </Box>
      {fileName}
    </Link>
  )
}

type Props = {
  contractId: number
  requestId: string | number
  onClose: () => void
  statusLabel: (
    status: ContractInformationRequestStatus | null | undefined,
    type: ContractInformationRequestType | null | undefined,
  ) => string
}

type Form = {
  root: {
    documentType: void
  }
  clientNote: string
  needText: boolean
  documents: Record<
    string,
    {
      file: IFileWithMeta
      docType: { key: DocumentType }
      expirtyDate: Date
      relatesTo: { key: string; type: DocumentRelatedEntityType }
    }
  >
}

export const ContractInformationRequest: React.FC<Props> = ({
  contractId,
  requestId,
  onClose,
  statusLabel,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { data, loading } = useGetContractInformationRequestQuery({
    variables: { id: requestId },
  })
  const form = useForm<Form>({
    reValidateMode: 'onChange',
    resolver: yupResolver(ContractInfRequestSchema),
    shouldUnregister: true,
    defaultValues: {
      needText: false,
      clientNote: '',
    },
  })

  const [createOwnDocument] = useCreateOwnDocumentMutation()
  const [updateContractInformationRequest] = useUpdateContractInformationRequestMutation()
  const canReply =
    data?.contractInformationRequest?.status === ContractInformationRequestStatus.Open
  const responded =
    data?.contractInformationRequest &&
    data?.contractInformationRequest?.status !== ContractInformationRequestStatus.Open

  const dialogTitleText =
    data?.contractInformationRequest?.title ||
    (data?.contractInformationRequest?.documentType
      ? documentTypes[data?.contractInformationRequest?.documentType].label
      : 'No title')

  const uploadComplete = useCallback(async () => {
    const { documents, clientNote } = form.getValues()
    if (Object.values(documents ?? []).some((doc) => !doc.docType || !doc.relatesTo)) {
      return
    }
    const documentIds = await Promise.all(
      Object.values(documents ?? []).map(async (d) => {
        const result = await createOwnDocument({
          variables: {
            contractId,
            type: d?.docType?.key,
            fileName: d?.file?.meta?.name,
            plainUrl: (d?.file?.meta as IMeta & { fileUrl: string })?.fileUrl,
            size: d.file?.meta?.size,
            expiration: d.expirtyDate,
            ...(d.relatesTo
              ? { relatedEntityId: d.relatesTo.key, relatedEntityType: d.relatesTo.type }
              : undefined),
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return result.data!.createOwnDocument!.id
      }),
    )

    await updateContractInformationRequest({
      variables: {
        id: requestId,
        clientNote: clientNote,
        documentIds,
      },
    })

    onClose()
  }, [form, requestId])

  const documents = form.watch('documents')
  const requestDocumentUploaded = useMemo(
    () =>
      !data?.contractInformationRequest?.documentType ||
      Object.values(documents ?? {})
        .map((d) => d.docType.key)
        .includes(data.contractInformationRequest.documentType),
    [
      data?.contractInformationRequest?.documentType,
      Object.values(form.getValues().documents ?? {})
        .filter((d) => d.docType)
        .map((d) => d.docType.key)
        .join(),
    ],
  )

  useEffect(() => {
    if (requestDocumentUploaded) {
      form.clearErrors('root.documentType')
    }
    return () => form.clearErrors('root')
  }, [requestDocumentUploaded])

  useEffect(() => {
    form.register('needText')
    form.setValue(
      'needText',
      data?.contractInformationRequest?.type === ContractInformationRequestType.FreeText,
    )
  }, [data?.contractInformationRequest?.type])

  const onSubmit: SubmitHandler<Form> = useCallback(
    async ({ documents }) => {
      if (data?.contractInformationRequest?.documentType && !requestDocumentUploaded) {
        form.setError('root.documentType', {
          message: t('pleaseUploadDocumentType', 'Please upload {{documentType}}', {
            documentType: addCategoriesToShortLabel(
              documentTypes[data.contractInformationRequest.documentType].value,
              documentTypes[data.contractInformationRequest.documentType].label,
            ),
          }),
        })
        return
      }
      if (documents) {
        Object.values(documents).forEach((d) => {
          if (d.file?.meta?.status === 'ready') {
            d.file.restart()
          }
        })
        uploadComplete()
      } else {
        uploadComplete()
      }
    },
    [uploadComplete],
  )

  return (
    <Dialog open={!!requestId} maxWidth="md" fullWidth className={classes.wrap}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
              <Grid item>
                <Typography variant={'h3'}>
                  {loading ? (
                    t('informationRequest', 'Information Request')
                  ) : (
                    <>
                      {dialogTitleText}
                      <Chip
                        size="small"
                        style={{ marginLeft: 20 }}
                        data-test={'status'}
                        className={clsx(`roundedPill ${data?.contractInformationRequest?.status}`)}
                        label={statusLabel(
                          data?.contractInformationRequest?.status,
                          data?.contractInformationRequest?.type,
                        )}
                      />
                    </>
                  )}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton aria-label="close section" edge="end" onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.content}>
            {loading && <Skeleton />}
            {data?.contractInformationRequest?.rejectReason && (
              <Alert severity="error" style={{ marginBottom: 20 }}>
                {data?.contractInformationRequest.rejectReason}
              </Alert>
            )}
            {!loading && data && (
              <Grid container spacing={5}>
                <Grid item xs={12} md={6} style={{ paddingTop: 0, marginBottom: 20 }}>
                  <Typography variant="h5" style={{ marginTop: 10 }}>
                    {t('reaceivedOn', 'Received on {{date}}', {
                      date:
                        data?.contractInformationRequest?.createdAt &&
                        new Intl.DateTimeFormat('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        }).format(new Date(data.contractInformationRequest.createdAt)),
                    })}
                  </Typography>
                  <Typography variant="body1" data-test={'description'}>
                    <ReactMarkdown>{data.contractInformationRequest?.note ?? ''}</ReactMarkdown>
                  </Typography>
                  {!!data.contractInformationRequest?.attachments?.length && (
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Grid container>
                        {data.contractInformationRequest.attachments.map((document, idx) => (
                          <DocumentLink {...document} key={idx} />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                  {canReply && (
                    <FormControlledTextField
                      label={t('reply', 'Reply')}
                      name="clientNote"
                      type="text"
                      maxRows={6}
                      multiline
                      fullWidth
                      data-test={'inputField'}
                      disabled={
                        data.contractInformationRequest?.status !==
                        ContractInformationRequestStatus.Open
                      }
                    />
                  )}
                  {responded && (
                    <>
                      <Typography variant="h5" style={{ marginTop: 10 }}>
                        {t('repliedOn', 'Responded {{date}}', {
                          date:
                            data?.contractInformationRequest?.filledAt &&
                            new Intl.DateTimeFormat('en-US', {
                              month: '2-digit',
                              day: '2-digit',
                              year: 'numeric',
                            }).format(new Date(data.contractInformationRequest.filledAt)),
                        })}
                      </Typography>
                      <Typography variant="body1">
                        <ReactMarkdown>
                          {data.contractInformationRequest?.clientNote ?? ''}
                        </ReactMarkdown>
                      </Typography>
                      {!!data.contractInformationRequest?.documents?.length && (
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Grid container>
                            {data.contractInformationRequest.documents.map((document, idx) => (
                              <React.Fragment key={idx}>
                                {document?.firstSide && <DocumentLink {...document?.firstSide} />}
                                {document?.secondSide && <DocumentLink {...document?.secondSide} />}
                              </React.Fragment>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  <Box mt={2}>
                    {form.errors.root?.documentType && (
                      <Alert severity="error" style={{ marginBottom: 10 }}>
                        {form.errors.root?.documentType.message}
                      </Alert>
                    )}
                    {canReply && (
                      <DocumentUpload
                        contractId={contractId}
                        uploadComplete={uploadComplete}
                        docType={data.contractInformationRequest?.documentType}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          {canReply && (
            <DialogActions>
              <Box mr={2} mb={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disableElevation
                  data-test="sendReply"
                >
                  {t('reply', 'Reply')}
                </Button>
              </Box>
            </DialogActions>
          )}
        </form>
      </FormProvider>
    </Dialog>
  )
}
