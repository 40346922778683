import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { find, forEach } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { Box, makeStyles, Theme, Typography } from '@material-ui/core'

import './styles.css'
import { sizeInKB } from '../../../utils'
import { FilesForSavingAndShowType } from '../../../types'
import { FilesListUpload } from './FilesListUpload'
import { DOCUMENTS_TYPE } from '../../../constants'
import { FormDatePickerExpirationField } from '../../Common/Fields/FormDatePickerExpirationField'

const useStyles = () =>
  makeStyles((theme: Theme) => ({
    modal: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      margin: '32px 0 0 0',
    },
    docLabel: {
      margin: '16px 0 0 0',
      fontSize: '14px',
      lineHeight: '24px',
    },
    wrapFileStuff: {
      '& > div': {
        marginTop: '0px',
      },
    },
  }))()

export const DocAlreadyUploadedControllingPerson: FC<{
  typeDoc?: string
  optionalIdentityList?: { key: string; label: string }[]
  isFirstSide?: boolean
}> = ({ typeDoc, optionalIdentityList, isFirstSide = true }) => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const classes = useStyles()
  const filesForUpload = watch(`filesForUpload`)
  const expiryDate: Date = watch(`expiryDate`)

  const docToPreview = useMemo(() => {
    return (filesForUpload as FilesForSavingAndShowType[]).find(
      (docFile) =>
        docFile.isAlreadyUploaded && docFile.isFirstSide === isFirstSide && !docFile.isRemoved,
    )
  }, [filesForUpload, isFirstSide])
  const name = docToPreview?.fileName as string
  const size = docToPreview?.size as number
  const percent = 100
  const status = 'done'
  const type = 'image/jpeg' as string
  const docType = docToPreview?.docType
  const removeFileHandler = useCallback(() => {
    const tempFilesForUpload: FilesForSavingAndShowType[] = []
    forEach(filesForUpload, (itemFile) => {
      const tempDocument: FilesForSavingAndShowType = itemFile
      if (itemFile.isFirstSide === isFirstSide) {
        tempDocument.isRemoved = true
      }
      tempFilesForUpload.push(tempDocument)
    })
    setValue(`filesForUpload`, tempFilesForUpload, {
      shouldValidate: true,
      shouldDirty: true,
    })
    isFirstSide && setValue('expiryDate', undefined)
  }, [setValue])

  useEffect(() => {
    const tempFilesForUpload: FilesForSavingAndShowType[] = []
    forEach(filesForUpload, (itemFile) => {
      const tempDocument: FilesForSavingAndShowType = itemFile
      tempDocument.expiration = expiryDate
      tempFilesForUpload.push(tempDocument)
    })
    setValue(`filesForUpload`, tempFilesForUpload)
  }, [expiryDate, setValue])

  return (
    <Box className={classes.modal}>
      <Typography className={classes.docLabel}>
        {find(DOCUMENTS_TYPE, (o) => o.value === typeDoc)?.label}
      </Typography>
      <Box>
        <FilesListUpload
          name={name}
          size={sizeInKB(size)}
          percent={percent}
          status={status}
          removeFileHandler={() => removeFileHandler()}
          type={type}
          docType={docType ?? ''}
          optionalIdentityList={optionalIdentityList}
          forCp={true}
        />
        {isFirstSide && (
          <Box className={classes.wrapFileStuff}>
            <FormDatePickerExpirationField
              name="expiryDate"
              label={t('expiryDate', 'Expiry date')}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
