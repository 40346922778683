import React, { FC, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useParams, Redirect, generatePath, useHistory } from 'react-router-dom'
import {
  useGetContractDetailsQuery,
  BankableEntityContractType,
  ContractStatusType,
  ContractType,
  UboType,
} from '../../graphql'
import { OnboardingProcess } from './Onboarding'
import { Loader } from '../Common'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { statusActions, AppActions } from '../../constants'

export const ApplicationStatus: FC = () => {
  const history = useHistory()
  const [contractStatus, setContractStatus] = useState<ContractStatusType | undefined>()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  useEffect(() => {
    if (!contractLoading && contractData?.contract?.status) {
      let redirectPath
      if (contractData.contract.ownerType === UboType.NaturalPerson) {
        redirectPath = APP_PATHS.application.individual.identityVerification
      } else if (
        isEmpty(contractData?.contract.authorizedPersons) &&
        contractData?.contract.type !== ContractType.IntroducerReference
      ) {
        redirectPath = APP_PATHS.application.authorizedPersons.add
      } else if (isEmpty(contractData?.contract.ubos)) {
        redirectPath = APP_PATHS.application.beneficialOwners.list
      }
      if (
        redirectPath &&
        statusActions[contractData?.contract?.status] &&
        statusActions[contractData?.contract?.status].includes(AppActions.Edit)
      ) {
        history.push(
          generatePath(redirectPath, { [PATH_PARAMS.applicationId]: contractData?.contract.id }),
        )
      } else if (
        contractData.contract.ownerType === UboType.NaturalPerson &&
        contractData?.contract?.status === ContractStatusType.Active
      ) {
        history.push(
          generatePath(APP_PATHS.dashboard.home, {
            [PATH_PARAMS.applicationId]: contractData?.contract.id,
          }),
        )
      }

      setContractStatus(
        (contractData?.contract as BankableEntityContractType)?.status as ContractStatusType,
      )
    }
  }, [contractData, contractLoading, history])

  if (contractLoading || !contractStatus) return <Loader />

  return statusActions[contractStatus] &&
    statusActions[contractStatus].includes(AppActions.Edit) ? (
    <Redirect
      to={generatePath(APP_PATHS.application.review, {
        [PATH_PARAMS.applicationId]: applicationId,
      })}
    />
  ) : (
    <OnboardingProcess
      contractStatus={contractStatus}
      isIntroducerOnly={contractData?.contract?.type === ContractType.IntroducerReference}
      showReferralLink={
        !!contractData?.contract?.isIntroducingPartner &&
        contractStatus !== ContractStatusType.PreAssessment
      }
      referralLink={contractData?.contract?.referralLink}
    />
  )
}
export default ApplicationStatus
