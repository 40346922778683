import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { head, isEmpty, isEqual } from 'lodash'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  useTheme,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { ReactComponent as AccountCircle } from '../../assets/images/icons/account_circle.svg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useMenuStyles } from './menuStyles'
import { DashboardDrawer } from './DasboardDrawer'
import { DashboardNavMenu } from './DashboardNavMenu'
import {
  AccountStatus,
  ContractStatusType,
  FundsType,
  Individual,
  LimitedAccessRight,
  useGetContractAccountsQuery,
} from '../../graphql'
import { ButtonWithTooltip, Loader } from '../Common'
import { useCurrentUser, useDetermineUserRights, useIsLimitedContract } from '../../hooks'
import { refreshPage, showError } from '../../utils'
import { PaymentButtons } from './PaymentButtons'
import config from '../../config'
import { MENU_WRAPPER_ZINDEX } from '../../constants'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      zIndex: 1200,
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  },
  toolBar: {
    minHeight: '69px',
    marginLeft: 'auto',
    paddingRight: 32,
  },
  buttonMenu: {
    fontWeight: 400,
    fontSize: 14,
    '& svg': {
      marginLeft: theme.spacing(4),
    },
  },
  backWrappAppBar: {
    position: 'relative',
    padding: theme.spacing(0, 1, 0, 1.5),
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      borderBottom: 0,
    },
  },
  mobileIconButton: {
    '& svg': {
      width: 20,
    },
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))

const DashboardMenuComponent: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isContractLimitedMode, setIsContractLimitedMode] = React.useState<boolean>(false)
  const [user, setUser] = React.useState<Individual>({} as Individual)
  const [isActiveAccounts, setIsActiveAccounts] = useState<boolean>(false)
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()
  const classes = { ...useMenuStyles(), ...useStyles() }
  const currentUser = useCurrentUser()

  const {
    data: accountsData,
    loading: loadingGetContractAccounts,
    error: errorGetContractAccounts,
  } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
  })

  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const { isLimitedContract, contractStatusLabel } = useIsLimitedContract()

  const isOnlyOneDepositGuaranteed =
    accountsData?.accounts?.length === 1 &&
    head(accountsData?.accounts)?.fundsType === FundsType.DepositGuaranteed

  const tooltipLabel = isLimitedContract
    ? t(
        'isNotAvailable',
        `New payment is not available, because the contract is ${contractStatusLabel}`,
        {
          option: 'New payment',
          contractStatusLabel,
        },
      )
    : t(
        'newPaymentIsNotAvailable',
        "New payment is not available because you don't have active accounts",
      )

  const handleDrawerToggle = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setMobileOpen(!mobileOpen)
    },
    [mobileOpen],
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setMobileOpen(false)
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl, setMobileOpen],
  )

  const handleNewPayment = useCallback(() => {
    setMobileOpen(false)
    applicationId && history.push(generatePath(APP_PATHS.dashboard.newPayment, { applicationId }))
    location.pathname.endsWith('new-payment') && refreshPage()
  }, [setMobileOpen, applicationId, history, location])

  useEffect(() => {
    const statuses = [
      ContractStatusType.AmloPending,
      ContractStatusType.SignaturesPending,
      ContractStatusType.ManagementPending,
    ]

    if (applicationId && currentUser) {
      // eslint-disable-next-line array-callback-return
      const currentContract = (currentUser as Individual).contracts?.find((contract) => {
        if (contract?.id) {
          return contract.id === +applicationId
        }
      })
      const appStatusLimited = statuses.includes(currentContract?.status as ContractStatusType)

      setIsContractLimitedMode(appStatusLimited)
    }
    if (!isEmpty(currentUser)) {
      setUser(currentUser as Individual)
    }
  }, [currentUser, applicationId])

  useEffect(() => {
    setIsActiveAccounts(!isEmpty(accountsData?.accounts))
  }, [accountsData, setIsActiveAccounts])

  useEffect(() => {
    if (errorGetContractAccounts) {
      showError(errorGetContractAccounts)
    }
  }, [errorGetContractAccounts])

  const isLimitedAccess =
    userRights?.limitedAccessRight === LimitedAccessRight?.CardHolder ||
    userRights?.limitedAccessRight === LimitedAccessRight?.ViewOnly ||
    userRights?.limitedAccessRight === LimitedAccessRight?.PaymentFacilitationView

  const isLoading = useMemo(
    () => loadingGetContractAccounts || !applicationId || isEmpty(currentUser),
    [loadingGetContractAccounts, applicationId, currentUser],
  )

  return (
    <Box className={classes.container}>
      {isLoading && (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      )}
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBarShift, classes.appBar)}>
          <Hidden mdUp>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                {mobileOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <Box display="flex" flexGrow={1} justifyContent="center">
                <Box className={classes.logo}>
                  <img src={process.env.PUBLIC_URL + config.logo} alt={'company_logo'} />
                </Box>
              </Box>

              <IconButton
                color="inherit"
                className={classes.mobileIconButton}
                onClick={handleClick}
              >
                <AccountCircle />
              </IconButton>
              <Hidden xsDown>
                {!isLimitedAccess && (
                  <ButtonWithTooltip
                    title={tooltipLabel}
                    disabled={
                      isContractLimitedMode ||
                      !isActiveAccounts ||
                      isLimitedContract ||
                      isOnlyOneDepositGuaranteed
                    }
                    placement="bottom-end"
                    variant="outlined"
                    hiddeTooltip={isOnlyOneDepositGuaranteed}
                    className={clsx(
                      classes.paymentButton,
                      (isContractLimitedMode || !isActiveAccounts) && 'disabled',
                    )}
                    onClick={handleNewPayment}
                  >
                    {t('newPayment', 'New payment')}
                  </ButtonWithTooltip>
                )}
              </Hidden>
            </Toolbar>
          </Hidden>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              style={{
                zIndex: MENU_WRAPPER_ZINDEX,
              }}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DashboardNavMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                user={user}
                contractId={applicationId}
                limitMode={isContractLimitedMode}
              />
              <DashboardDrawer
                contractId={applicationId}
                setMobileOpen={setMobileOpen}
                user={user}
                limitMode={isContractLimitedMode}
                limitedAccessRight={userRights?.limitedAccessRight}
                isIntroducerContractOwner={!!userRights?.isIntroducerContractOwner}
                hasCardPayment={!!userRights?.hasCardPayment}
              />
            </Drawer>
          </Hidden>

          {/*Full screen view*/}
          <Hidden smDown>
            <AppBar position="fixed" className={clsx(classes.appBarShift, classes.appBar)}>
              <Toolbar className={classes.toolBar}>
                <Box className={classes.backWrappAppBar}>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.buttonMenu}
                    data-test="userPersonalMenu"
                  >
                    <span>
                      {user.firstName} {user.lastName}
                    </span>{' '}
                    {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                </Box>
                {!isLimitedAccess && (
                  <PaymentButtons
                    isActiveAccounts={isActiveAccounts}
                    limitedMode={isContractLimitedMode}
                  />
                )}
              </Toolbar>
            </AppBar>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <DashboardNavMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                user={user}
                contractId={applicationId}
                limitMode={isContractLimitedMode}
              />
              <DashboardDrawer
                contractId={applicationId}
                setMobileOpen={setMobileOpen}
                user={user}
                limitMode={isContractLimitedMode}
                limitedAccessRight={userRights?.limitedAccessRight}
                isIntroducerContractOwner={!!userRights?.isIntroducerContractOwner}
                hasCardPayment={!!userRights?.hasCardPayment}
              />
            </Drawer>
          </Hidden>
        </nav>
      </div>
    </Box>
  )
}

export const DashboardMenu = memo(DashboardMenuComponent, isEqual)
