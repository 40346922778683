import * as yup from 'yup'

import {
  EmailInputSchema,
  StreetAddressInputSchema,
  AdditionalDetailsOptionalInputSchema,
  AddressInputSchema,
  NationalityInputSchema,
  BirthdayInputSchema,
  PostalCodeInputSchema,
  PhoneNumberInputSchema,
  NameInputSchema,
} from './common'

export const PersonProfileInputSchema = AdditionalDetailsOptionalInputSchema.concat(NameInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(EmailInputSchema)
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(PhoneNumberInputSchema)

export type PersonProfileInput = yup.Asserts<typeof PersonProfileInputSchema>
