import { useCallback, useEffect, useState } from 'react'
import { Action, ActionSignatureStatus } from '../graphql'
import { transformActionStatus } from '../utils'

type ReturnTypes = [checked: boolean, disableCheck: boolean, handleCheck: () => void]

export function useHandleCheckBoxes(
  data: Action,
  checkedActions: Action[],
  handleActionsForSign: (actionData: Action, check: boolean) => void,
): ReturnTypes {
  const [disableCheck, setDisableCheck] = useState<boolean>(false)

  const signatureStatus = data?.signatures?.[0]?.status

  const checked = checkedActions.some(({ id }) => id === data.id)

  const handleCheck = useCallback(() => {
    handleActionsForSign(data, !checked)
  }, [checked, data, handleActionsForSign])

  useEffect(() => {
    if (transformActionStatus(data) !== ActionSignatureStatus.Pending) {
      setDisableCheck(true)
    }
  }, [data, signatureStatus])

  return [checked, disableCheck, handleCheck]
}
