import React, { FC, useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { actionsPageStateValue, FiltersResetBtn, SearchField } from '../../../components'
import { FilterSelector } from '../../../components/Common/Selectors/FilterSelector'
import { initialActionsPageState } from '../../../constants'
import { ActionStatus } from '../../../graphql'
import { usePageFiltersSorting } from '../../../hooks'
import { ActionsFilterTypesEnum, StatusSelectorType } from '../../../types'
import { GetActionsFilterLabel, normalizeActionStatus } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  filterBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  filterSelector: {
    height: '48px',
    flexBasis: theme.spacing(23),
    [theme.breakpoints.down('md')]: {
      // height: 'unset',
      paddingRight: 0,
      flexBasis: theme.spacing(22),
      '&.selector': {
        margin: 0,
        [theme.breakpoints.between('xs', theme.spacing(129))]: {
          minWidth: '45%',
          flexGrow: 1,
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

const ActionsFiltersComponent: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { pageFilters, setPageFilters } = usePageFiltersSorting(actionsPageStateValue)

  const isFiltered = useMemo(() => !isEqual(pageFilters, initialActionsPageState), [pageFilters])

  const typeOptions = useMemo(() => {
    const types = Object.values(ActionsFilterTypesEnum).map((key) => {
      return {
        key,
        label: GetActionsFilterLabel(key),
      }
    })
    return [{ key: StatusSelectorType.AllTypes, label: t('allTypes', 'All types') }, ...types]
  }, [GetActionsFilterLabel])

  const statusOptions = useMemo(() => {
    const statuses = Object.keys(ActionStatus).map((type) => {
      const key = ActionStatus[type as keyof typeof ActionStatus]

      return {
        key,
        label: t(normalizeActionStatus(key)),
      }
    })
    return [
      { key: StatusSelectorType.AllStatuses, label: t('allStatuses', 'All statuses') },
      ...statuses,
    ]
  }, [ActionStatus, normalizeActionStatus])

  return (
    <Box className={classes.filterBox}>
      <Box className={classes.searchField}>
        <SearchField
          key={pageFilters.searchValue}
          setValue={(value) => setPageFilters({ searchValue: value, page: 1 })}
          currentValue={pageFilters.searchValue || ''}
          placeHolder={t('searchDocuments', 'Search documents')}
        />
      </Box>
      <Box className={clsx(classes.filterSelector, `selector`)}>
        <FilterSelector
          optionsList={typeOptions}
          setValue={(value) => setPageFilters({ type: value as ActionsFilterTypesEnum, page: 1 })}
          currentValue={pageFilters.type}
        />
      </Box>
      <Box className={clsx(classes.filterSelector, `selector`)}>
        <FilterSelector
          optionsList={statusOptions}
          setValue={(value) => setPageFilters({ status: value as ActionStatus, page: 1 })}
          currentValue={pageFilters.status}
        />
      </Box>
      <FiltersResetBtn
        isDisabled={!isFiltered}
        resetPageState={() => setPageFilters(initialActionsPageState)}
      />
    </Box>
  )
}

export const ActionsFilters = React.memo(ActionsFiltersComponent, isEqual)
