import React, { FC, useState } from 'react'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLogOut } from '../../hooks'
import { getModalStyle } from '../../utils'
import { usePromptStyles } from './CustomPrompt'

export const LogOutPrompt: FC<{
  logoutPromptOpen: boolean
  setLogoutPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ logoutPromptOpen, setLogoutPromptOpen }) => {
  const classes = usePromptStyles()
  const { t } = useTranslation()

  const [modalStyle] = useState(getModalStyle)

  const handleLogOut = useLogOut()

  return (
    <Modal
      open={logoutPromptOpen}
      onClose={() => null}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box style={modalStyle} className={classes.modal} data-test="modalGoBack">
        <Box className={classes.modalHeader}>
          <Typography variant={'h4'}>
            {t('areYouSureYouWantToLogOut', 'Are you sure you want to log out')}?
          </Typography>
        </Box>

        <Box className={classes.modalContent}>
          <Typography>
            {t('allUnsubmittedDataWillBeLost', 'All unsubmitted data will be lost')}.
          </Typography>
        </Box>
        <Box className={classes.modalFooter}>
          <Button variant="contained" onClick={handleLogOut} data-test="modal-goBack-button">
            {t('logOut', 'Log Out')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setLogoutPromptOpen(false)}
            data-test="continueEditing"
          >
            {t('continueEditing', 'Continue editing')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
