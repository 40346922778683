import * as yup from 'yup'
import { isEmpty } from 'lodash'
import i18n from '../../i18n'

export const DocAddressInputSchema = yup.object().shape({
  requiredAddress: yup.mixed().notRequired(),
  filesForUploadAddress: yup.mixed().notRequired(),
  documentTypeAddress: yup
    .string()
    .test(
      'documentTypeAddressCheck',
      i18n.t('pleaseSelectTheDocumentType', 'Please select the document type'),
      function () {
        return !this.parent.requiredAddress || !isEmpty(this.parent.documentTypeAddress)
      },
    )
    .test(
      'mandatoryAddress',
      i18n.t('proofAdrMandatory', 'Proof of address is mandatory'),
      function () {
        return !this.parent.requiredAddress || !isEmpty(this?.parent?.filesForUploadAddress)
      },
    ),
})

export type DocAddressInput = yup.Asserts<typeof DocAddressInputSchema>
