import * as yup from 'yup'
import { isEmpty } from 'lodash'
import i18n from '../../i18n'

export const DocCorrespondenceAddressInputSchema = yup.object().shape({
  requiredCorrespondenceAddress: yup.mixed().notRequired(),
  filesForUploadCorrespondenceAddress: yup.mixed().notRequired(),
  documentTypeCorrespondenceAddress: yup
    .string()
    .test(
      'documentTypeCorrespondenceAddressCheck',
      i18n.t('pleaseSelectTheDocumentType', 'Please select the document type'),
      function () {
        return (
          !this.parent.requiredCorrespondenceAddress ||
          !isEmpty(this.parent.documentTypeCorrespondenceAddress)
        )
      },
    )
    .test(
      'mandatoryCorrespondenceAddress',
      i18n.t('proofCorAddrsMandatory', 'Proof of Correspondence Address is mandatory'),
      function () {
        return (
          !this.parent.requiredCorrespondenceAddress ||
          !isEmpty(this?.parent?.filesForUploadCorrespondenceAddress)
        )
      },
    ),
})

export type DocCorrespondenceAddressInput = yup.Asserts<typeof DocCorrespondenceAddressInputSchema>
