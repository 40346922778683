import React, { createContext, FC, useCallback, useState } from 'react'
import { ActionSignature } from '../../graphql'
import { BatchPaymentInfo } from '../../types'

type PaymentContextType = {
  paymentSuccessful: boolean
  paymentReady: boolean
  actionData: ActionSignature | undefined
  batchPaymentResultText: BatchPaymentInfo | undefined
  signNow: boolean
  batchListPage: number
  setPaymentSuccessful: React.Dispatch<React.SetStateAction<boolean>>
  setPaymentReady: React.Dispatch<React.SetStateAction<boolean>>
  setActionData: React.Dispatch<React.SetStateAction<ActionSignature | undefined>>
  setBatchPaymentResultText: React.Dispatch<React.SetStateAction<BatchPaymentInfo | undefined>>
  setSignNow: React.Dispatch<React.SetStateAction<boolean>>
  setBatchListPage: React.Dispatch<React.SetStateAction<number>>
  resetBatchComponent: () => void
  tableStatus: boolean
  setTableStatus: React.Dispatch<React.SetStateAction<boolean>>
  isNeedToReset: boolean
  setIsNeedToReset: React.Dispatch<React.SetStateAction<boolean>>
}

export const PaymentContext = createContext<PaymentContextType>({} as PaymentContextType)

export const PaymentContextComponent: FC = ({ children }) => {
  const [paymentSuccessful, setPaymentSuccessful] = useState(false)
  const [paymentReady, setPaymentReady] = useState(false)
  const [actionData, setActionData] = useState<ActionSignature | undefined>()
  const [batchPaymentResultText, setBatchPaymentResultText] = useState<
    BatchPaymentInfo | undefined
  >()
  const [signNow, setSignNow] = useState(true)
  const [batchListPage, setBatchListPage] = useState(1)
  const [tableStatus, setTableStatus] = useState<boolean>(false)
  const [isNeedToReset, setIsNeedToReset] = useState<boolean>(false)

  const resetBatchComponent = useCallback(() => {
    setPaymentSuccessful(false)
    setPaymentReady(false)
    setActionData(undefined)
    setSignNow(true)
  }, [setPaymentSuccessful, setPaymentReady, setActionData, setSignNow])

  const context = {
    paymentSuccessful,
    paymentReady,
    actionData,
    batchPaymentResultText,
    signNow,
    batchListPage,
    setBatchListPage,
    setPaymentSuccessful,
    setPaymentReady,
    setActionData,
    setBatchPaymentResultText,
    setSignNow,
    resetBatchComponent,
    tableStatus,
    setTableStatus,
    isNeedToReset,
    setIsNeedToReset,
  }

  return <PaymentContext.Provider value={context}>{children}</PaymentContext.Provider>
}
