import React, { FC, useCallback, useState } from 'react'
import { Box, FormControlLabel, Radio, RadioProps, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { useTranslation } from 'react-i18next'

import { FormRadioGroup } from '../../Common'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  controlWrap: {
    margin: theme.spacing(3, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  },
  titleWrap: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  radio: {
    '& .MuiRadio-root': {
      padding: theme.spacing(0.25, 0.5, 0.5, 1),
    },
  },
}))

const BlackRadio = withStyles({
  root: {
    color: grey[900],
    '&$checked': {
      color: grey[900],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />)

export const ControlPerson: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [controlRights, setControlRights] = useState('votingRights')

  const handleRadioChange = useCallback(
    (event) => {
      const value = event.target.value
      setControlRights(value)
    },
    [setControlRights],
  )

  return (
    <Box className={classes.controlWrap}>
      <Box>
        <Box className={classes.titleWrap}>
          <Typography variant={'h2'} className={classes.title}>
            {t('controllingStake', 'Controlling Stake')}
          </Typography>
        </Box>
        <Box>
          <FormRadioGroup
            name="controllingStake"
            value={controlRights}
            onChange={handleRadioChange}
          >
            <Box mb={1.75}>
              <FormControlLabel
                value="votingRights"
                control={<BlackRadio size="small" />}
                label={
                  <div>
                    {t('thePersonIs', 'the person is')}{' '}
                    <b>
                      {t(
                        'thePersonIsHolding25OrMoreOfTheContractingPartnersShares',
                        'the person is holding 25% or more of the contracting partner’s shares (capital shares or voting rights);',
                      )}
                      ;
                    </b>{' '}
                    {t('or', 'or')}
                  </div>
                }
                className={classes.radio}
              />
            </Box>

            <Box mb={1.75}>
              <FormControlLabel
                value="alternativeControl"
                control={<BlackRadio size="small" />}
                label={
                  <div>
                    {t(
                      'capitalSharesOrVotingRightsOf25OrMore',
                      'if there are no capital shares or voting rights of 25% or more, that the following person',
                    )}
                    <b>
                      {t(
                        'isControllingTheContractingPartnerInOtherWays',
                        'is controlling the contracting partner in other ways',
                      )}
                      ;
                    </b>
                    {t('or', 'or')}{' '}
                  </div>
                }
                className={classes.radio}
              />
            </Box>

            <Box>
              <FormControlLabel
                value="managingDirectors"
                control={<BlackRadio size="small" />}
                label={
                  <div>
                    {t(
                      'inCaseNoPersonExistWhoExercise',
                      'in case no person(s) exist(s) who exercise(s) control over the contracting partner in a different capacity, person is the',
                    )}{' '}
                    <b>{t('managingDirectors', 'managing directors')}.</b>
                  </div>
                }
                className={classes.radio}
              />
            </Box>
          </FormRadioGroup>
        </Box>
      </Box>
    </Box>
  )
}
