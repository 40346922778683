import React, { FC, useCallback, useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { Alert } from '@material-ui/lab'
import { CreateCardTermsActionType, useCreateCardTermsActionMutation } from '../../../graphql'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'

import { focusKeyPressNext, parseDefaultLimits, parseDefaultLimitsArr } from '../../../utils'
import {
  CardOwnerAutocompleteSelect,
  DebitAccountAutocompleteSelect,
  FormTextField,
} from '../../../components'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { Loader } from '../../../components/Common'
import {
  AccountType,
  ActionSignature,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  ApiCardTypes,
  CardIssueRequestAction,
  CardLimitInputType,
  CardsCountDocument,
  CardsListDocument,
  CardType,
  Currency,
  Maybe,
  OrderBy,
  OrderDirection,
  SignatoryRight,
  UboType,
  useGetAuthorizedPersonsQuery,
  useGetContractAccountsQuery,
  useGetDefaultCardLimitsQuery,
  useIssueCardRequestMutation,
} from '../../../graphql'
import { CreateNewCardSchema } from '../../../schemes'
import { CardholderType, LimitsType } from '../../../types'
import { SpendingLimits } from '../Tabs'
import NewCardholderInfoModal from '../../../components/Common/NewCardholderInfoModal'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { ConfirmSignatureModal } from '../../../components/Common/ActionsSign2FAModal'
import { useCurrentUser, useDetermineUserRights } from '../../../hooks'
import { cardAlert, cardAlertHeader } from '../../../graphql/local/dashboard'
import config from '../../../config'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
  },
  wrap: {
    width: '100%',
    marginBottom: '15px',
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
    },
    '& .MuiBox-root ': {
      marginTop: 0,
    },
    '& .MuiGrid-root ': {
      padding: 0,
    },
  },
  arrowIcon: {
    '& .MuiSvgIcon-root': {
      color: '#999',
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0),
    },
  },
  title: {
    fontSize: 32,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  footerBtnWrap: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  footerBtn: {
    padding: '32px 0 0 0',
  },
  btnControl: {
    padding: theme.spacing(1.25, 2.5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  btnCancel: {
    border: '1px solid black',
    padding: theme.spacing(1.25, 2.5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formControl: {
    width: '100%',
    position: 'relative',
    '& .MuiInputAdornment-positionEnd': {
      color: '#000000',
      padding: '0 0 0 0',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#000000',
      padding: '18px 0 0 0',
    },
  },
  signTerms: {
    fontSize: '0.875rem',
    marginBottom: '20px',
  },
}))

export const CreateNewCardForm: FC = () => {
  const [currency, setCurrency] = useState<string | undefined>(undefined)
  const [limits, setLimits] = useState<LimitsType | undefined>()
  const [limitsArr, setLimitsArr] = useState<CardLimitInputType[]>()
  const [signNow, setSignNow] = useState<boolean>(true)
  const [signatoryCard, setSignatoryCard] = useState<CardType | null>()
  const [actionData, setActionData] = useState<ActionSignature | undefined>()
  const [cardHolders, setCardholders] = useState<CardholderType[]>()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [createCardTermsAction, setCreateCardTermsAction] = useState<CreateCardTermsActionType>()

  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(CreateNewCardSchema),
  })
  const { getValues, setValue, handleSubmit, formState } = methods
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.cardsPageNum]: page,
  } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)

  const user = useCurrentUser()

  const currentContract = user?.contracts?.find(
    (contract) => contract && contract.id === +applicationId,
  )
  const currentContractIsIndividualOwned = currentContract?.ownerType === UboType.NaturalPerson
  const isUserSignatoryRightSole = userRights?.signatoryRight === SignatoryRight.Sole
  const isUserCardholder = signatoryCard?.cardHolder?.person?.id === user?.id

  const { data: accountsData, loading: loadingAccountData } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      currencies: config.allowedCardCurrencies as Currency[],
      isVirtual: false,
      types: [AccountType.IntroducerReference, AccountType.StandardCurrent],
    },
  })
  const { data: APData } = useGetAuthorizedPersonsQuery({
    variables: {
      id: applicationId,
    },
  })

  const { data: defaultLimitsData } = useGetDefaultCardLimitsQuery({
    variables: {
      contractId: applicationId,
    },
  })

  const [createCardTermsActionMutation] = useCreateCardTermsActionMutation()
  useEffect(() => {
    createCardTermsActionMutation({
      variables: { contractId: applicationId },
    }).then(({ data }) => {
      if (data?.createCardTermsAction) {
        setCreateCardTermsAction(data.createCardTermsAction)
      }
    })
  }, [createCardTermsActionMutation, setCreateCardTermsAction])

  const onCreateCardTermsAction = useCallback(async () => {
    const createCardPath = generatePath(APP_PATHS.dashboard.createCard, {
      [PATH_PARAMS.applicationId]: applicationId,
      [PATH_PARAMS.cardsPageNum]: page,
    })
    const createCardTermsActionPath = generatePath(APP_PATHS.dashboard.actionDetails, {
      [PATH_PARAMS.applicationId]: applicationId,
      [PATH_PARAMS.signatureId]: createCardTermsAction?.actionId as string,
    })
    history.push(`${createCardTermsActionPath}?referer=${createCardPath}`)
  }, [applicationId, history, page, createCardTermsAction])

  const [issueCardRequestMutation] = useIssueCardRequestMutation()

  //special case for sole signatory cardholder navigation to card activation
  // const soleCardholderCheck = useMemo(() => {
  //   return (
  //     signNow &&
  //     signatoryCard &&
  //     isUserSignatoryRightSole &&
  //     user?.id === signatoryCard?.cardHolder?.person?.id
  //   )
  // }, [signatoryCard, user, userRights, signNow])

  const updateCurrency = useCallback((val: string) => {
    setCurrency(val)
  }, [])

  const onCancel = useCallback(
    (success?: boolean) => {
      if (isUserSignatoryRightSole && isUserCardholder && success) {
        history.push(
          generatePath(APP_PATHS.dashboard.activateCard, {
            [PATH_PARAMS.applicationId]: applicationId,
            [PATH_PARAMS.cardId]: signatoryCard?.id,
            [PATH_PARAMS.cardsPageNum]: 1,
          }),
        )
      } else {
        history.push(
          generatePath(APP_PATHS.dashboard.cards, {
            [PATH_PARAMS.applicationId]: applicationId,
            [PATH_PARAMS.cardsPageNum]: 1,
          }),
        )
      }
    },
    [applicationId, history, userRights?.signatoryRight, signatoryCard, user?.id],
  )

  const handleClose = useCallback(
    (success?: boolean) => {
      setActionData(undefined)

      if (success && isUserSignatoryRightSole && !isUserCardholder) {
        cardAlertHeader(t('cardCreatedSuccess', 'Card created successfully!'))
        cardAlert(' ')
      }

      if (!success) {
        cardAlert(t('pleaseConfirmIt', 'Please confirm it with your signature.'))
      }
      onCancel(success)
    },
    [onCancel],
  )

  const handleSignNow = useCallback(() => {
    setSignNow(!signNow)
  }, [signNow])

  const handleCreateCard = useCallback(
    async (formData) => {
      try {
        setBtnDisabled(true)
        const { data } = await issueCardRequestMutation({
          variables: {
            accountId: formData.debitAccount,
            contractAuthorizedPersonId: formData.cardOwner,
            type: ApiCardTypes.Virtual,
            limits: limitsArr,
            ...(!formData.nameOptional ? {} : { alias: formData.nameOptional }),
          },
          refetchQueries: [
            {
              query: CardsCountDocument,
              variables: {
                contractId: applicationId,
              },
            },
            {
              query: CardsListDocument,
              variables: {
                contractId: applicationId,
                limit: 10,
                offset: 0,
                orderBy: OrderBy.CreatedAt,
                orderDirection: OrderDirection.Descending,
              },
            },
            {
              query: ActionSignatureRequestsCountDocument,
              variables: { contractId: applicationId, statuses: [ActionSignatureStatus.Pending] },
            },
          ],
        })

        if (!isEmpty(data?.issueCardRequest?.executionAction)) {
          const signature = (data?.issueCardRequest
            ?.executionAction as CardIssueRequestAction).signatures?.find(
            (actionSignature) => actionSignature?.isMine === true,
          )

          if (!isEmpty(data?.issueCardRequest?.card)) {
            setSignatoryCard(data?.issueCardRequest?.card)
          }

          signature && setActionData(signature)
        }

        !signNow && onCancel()

        const soleSignatoryTest = (element: Maybe<ActionSignature>) =>
          element && element.signatory.signatoryRight === SignatoryRight.Sole
        const soleSignatoryPresent = (data?.issueCardRequest
          ?.executionAction as CardIssueRequestAction)?.signatures?.some(soleSignatoryTest)

        if (userRights?.limitedAccessRight && soleSignatoryPresent) {
          cardAlert(
            t(
              'afterSignatoryConfirms',
              'After the signatory confirms it, you can proceed to activation.',
            ),
          )
        }

        if (!signNow && isUserSignatoryRightSole) {
          cardAlert(t('pleaseConfirmIt', 'Please confirm it with your signature.'))
        }

        if (userRights?.limitedAccessRight || userRights?.signatoryRight !== SignatoryRight.Sole) {
          cardAlert(
            t(
              'afterSignatoriesConfirm',
              'After the signatories confirm it, you can proceed to activation.',
            ),
          )
        }
      } catch (e) {
        toast.error((e as Error).message)
      } finally {
        setBtnDisabled(false)
      }
    },
    [applicationId, issueCardRequestMutation, limitsArr, onCancel, signNow],
  )

  useEffect(() => {
    const defaultLimits =
      defaultLimitsData?.cardsAllowedLimitsAndMaxes?.defaults &&
      parseDefaultLimits(defaultLimitsData?.cardsAllowedLimitsAndMaxes?.defaults)

    const limitsDefaultArr =
      defaultLimitsData?.cardsAllowedLimitsAndMaxes?.defaults &&
      parseDefaultLimitsArr(defaultLimitsData?.cardsAllowedLimitsAndMaxes?.defaults)

    defaultLimits && setLimits(defaultLimits)
    limitsDefaultArr && setLimitsArr(limitsDefaultArr)
  }, [defaultLimitsData])

  useEffect(() => {
    if (!isEmpty(APData?.contract?.authorizedPersons)) {
      const filteredCardholders = APData?.contract?.authorizedPersons
        //?.filter((item) => item?.limitedAccessRight === LimitedAccessRight.CardHolder)
        ?.map((item) => {
          if (item && item.person) {
            return {
              key: item.id,
              label: `${item.person.firstName} ${item.person.lastName}`,
              isPhoneVerified: item.person.isPhoneVerified,
              videoVerified: item.person.videoVerified,
              limitedAccessRight: item.limitedAccessRight,
              status: item.status,
            }
          }
          return null
        })

      !isEmpty(filteredCardholders) && setCardholders(filteredCardholders as CardholderType[])
    }
  }, [APData])

  useEffect(() => {
    if (userRights?.limitedAccessRight) {
      setSignNow(false)
    }
  }, [userRights])

  useEffect(() => {
    const cardHoldersLas =
      cardHolders && ((cardHolders[cardHolders?.length - 1] as unknown) as CardholderType)
    if (getValues('cardOwner') !== undefined) {
      cardHoldersLas && setValue('cardOwner', cardHoldersLas.key)
    }
  }, [cardHolders, getValues, setValue])

  if (loadingAccountData) return <Loader />

  return (
    <>
      <Grid item container className={classes.head}>
        <Typography variant="h2" className={classes.title}>
          {t('createVirtualCard', 'Create virtual card')}
        </Typography>
      </Grid>
      {createCardTermsAction && !createCardTermsAction.signed && (
        <Alert
          variant="outlined"
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={onCreateCardTermsAction}>
              {t('signNow', 'Sign now')}
            </Button>
          }
          className={classes.signTerms}
        >
          {t('signCardTerms', 'You need to sign the terms & conditions to create a card.')}
        </Alert>
      )}
      {createCardTermsAction?.signed && (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(handleCreateCard)}
            id="forNextFocus"
            onKeyDown={focusKeyPressNext}
          >
            <Grid item xs={12} sm={8} lg={4} className={classes.container}>
              <Box className={classes.wrap}>
                <CardOwnerAutocompleteSelect
                  name="cardOwner"
                  label={t('cardOwner', 'Card owner')}
                  data={cardHolders}
                  classPut={classes.arrowIcon}
                  setIsOpenModal={setIsOpenModal}
                  withAddMore={!currentContractIsIndividualOwned}
                  data-test="cardOwner"
                />
              </Box>
              <Box className={classes.wrap}>
                <DebitAccountAutocompleteSelect
                  updateCurrency={updateCurrency}
                  name="debitAccount"
                  data={accountsData}
                  label={t('debitAccount', 'Debit account')}
                  classPut={classes.arrowIcon}
                  data-test="debitAccount"
                />
              </Box>
              <Box className={classes.wrap}>
                <FormControl className={classes.formControl}>
                  <FormTextField
                    label={t('cardLabelOptional', 'Card label (optional)')}
                    name="nameOptional"
                    type="text"
                    required={false}
                    data-test="cardLabelOptional"
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={10} lg={6}>
              {limits && (
                <SpendingLimits
                  limits={limits}
                  setLimits={setLimits}
                  currency={currency}
                  setLimitsArr={setLimitsArr}
                  setBtnDisabled={setBtnDisabled}
                  editable={true}
                />
              )}
            </Grid>

            <Grid item xs={12} className={classes.footerBtn}>
              {userRights?.signatoryRight && (
                <Box mb={'32px'} ml={'4px'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="signPayment"
                        icon={<Unchecked />}
                        checkedIcon={<Checked />}
                        defaultChecked
                        data-test="signCheckBox"
                      />
                    }
                    onChange={handleSignNow}
                    label={t('signCardCreationNow', 'Sign the card creation action now')}
                  />
                </Box>
              )}

              <Grid container item spacing={3} className={classes.footerBtnWrap}>
                <Grid item>
                  <Button
                    onClick={() => onCancel()}
                    variant="outlined"
                    className={classes.btnCancel}
                    data-test="cancelButton"
                  >
                    {t('cancel', 'Cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!formState.isDirty || btnDisabled}
                    disableElevation
                    className={classes.btnControl}
                    data-test="createCardButton"
                  >
                    {t('createCard')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
      {/*<CardHolderFormModal isOpen={isOpenModal} setIsOpenModal={setIsOpenModal} />*/}
      <NewCardholderInfoModal isOpen={isOpenModal} setIsOpenModal={setIsOpenModal} />

      {/* 2FA Modal */}
      {!isEmpty(actionData) && signNow && (
        <ConfirmSignatureModal
          openAction={!isEmpty(actionData) ? 'sign' : undefined}
          handleClose={handleClose}
          actionData={actionData}
          multipleActionsData={[]}
        />
      )}
    </>
  )
}

export default CreateNewCardForm
