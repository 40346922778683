import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
//import clsx from 'clsx'

import { Box, Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { TransactionData } from '../../AplicationActions/contract'
import { trxStatusNormalize } from '../../../utils'
import clsx from 'clsx'

export const TrxFeeTab: FC<{
  feeTransfer: TransactionData
}> = ({ feeTransfer }) => {
  const { amount, currency, status, transactionFeeDetails, referenceNumber } = feeTransfer

  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('transactionStatus', 'Transaction status')}
                </Box>
              </TableCell>
              <TableCell>
                {status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(status)}`)}
                  >
                    {t(`${trxStatusNormalize(status)}`)}
                  </Box>
                )}
              </TableCell>
            </TableRow>

            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('referenceNumber', 'Reference number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{referenceNumber}</Box>
              </TableCell>
            </TableRow>

            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('amount', 'Amount')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{amount}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('currency', 'Currency')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{currency}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('details', 'Details')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{transactionFeeDetails}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
              <Box className={classes.mobileTableName}>
                {status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(status)}`)}
                  >
                    {t(`${trxStatusNormalize(status)}`)}
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('referenceNumber', 'Reference number')}
              </Box>
              <Box className={classes.mobileTableName}>{referenceNumber}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
              <Box className={classes.mobileTableName}>{amount}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('currency', 'Currency')}</Box>
              <Box className={classes.mobileTableName}>{currency}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('details', 'Details')}</Box>
              <Box className={classes.mobileTableName}>{transactionFeeDetails}</Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
