import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import {
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  Typography,
  Hidden,
  Grid,
  TableBody,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { find } from 'lodash'

import { LegalEntity, Scalars } from '../../../graphql'
import { dateConverter } from '../../../utils'
import { useHistory } from 'react-router-dom'
import { APP_PATHS } from '../../../routes/paths'
import { BusinessCategoriesList, uboLegalEntityTypesList } from '../../../utils/Data'
import { REGEXP_HTTP } from '../../../constants/regexp'
import { BaseEnumWithKey } from '../../Common'

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '& .MuiButton-outlinedSizeSmall': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.75, 2),
      },
    },
  },
  table: {
    borderTop: '0',
    tableLayout: 'fixed',
    '& td': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      marginBottom: theme.spacing(2),
    },
  },
  label: {
    fontWeight: 'bold',
  },
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  // Mobile table
  mobileTable: {
    borderBottom: '1px solid rgba(0,0,0,.1)',
    '&.bordered': {
      border: '1px solid rgba(0,0,0,.1)',
      borderBottom: 0,
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
}))

export const CompanyDetailsTab: FC<{
  applicationId: Scalars['ID']['input']
  data: LegalEntity
}> = ({ applicationId, data }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const onEditCompany = useCallback(() => {
    history.push(generatePath(APP_PATHS.application.edit, { applicationId }))
  }, [])

  const renderSiteLink = useMemo(() => {
    if (data?.website) {
      const isValidLink = REGEXP_HTTP.test(data?.website)
      return isValidLink ? data.website : `https://${data.website}`
    }
  }, [data?.website])

  return (
    <>
      <Box className={classes.head}>
        <Typography variant={'h2'} className={classes.title}>
          {t('companyDetails', 'Company Details')}
        </Typography>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={onEditCompany}
          disableElevation
          size="small"
          data-test="companyDetailsEdit-btn"
        >
          {t('edit', 'Edit')}
        </Button>
      </Box>

      <Hidden xsDown>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('legalNameUpperCase', 'Legal Name')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      className={classes.text}
                      title={data && (data.companyName as string | undefined)}
                    >
                      {data.companyName}
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('tradingNameDetails', 'Trading Name')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      className={classes.text}
                      title={data && (data.tradingName as string | undefined)}
                    >
                      {data.tradingName}
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('country', 'Country')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>{data.address?.countryDescription}</Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('address', 'Address')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      className={classes.text}
                      title={
                        data &&
                        (`${data.address?.line1}, ${data.address?.zip}` as string | undefined)
                      }
                    >{`${data.address?.line1}, ${data.address?.zip}`}</Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('city', 'City')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>{data.address?.city}</Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('phoneNumberSmall', 'Phone number')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>{data.phone}</Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} md={6}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('entityType', 'Entity Type')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>
                      {t(
                        find<BaseEnumWithKey>(uboLegalEntityTypesList, {
                          key: data.legalEntityType as string,
                        })?.label as string,
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('registrationNumber', 'Registration Number')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>{data.companyRegistrationId}</Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('dateRegistration', 'Date of Registration')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>
                      {dateConverter(data.companyRegistrationDate)}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('siteLink', 'Site/link')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {/*<Box className={classes.text}>https://{data.website}</Box>*/}
                    <Box className={classes.text} data-test="siteLink">
                      {renderSiteLink}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('businessCategory', 'Business Category')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.text}>
                      {t(
                        find<BaseEnumWithKey>(BusinessCategoriesList, {
                          key: data.businessSector as string,
                        })?.label as string,
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell width="200">
                    <Box component="span" className={classes.label}>
                      {t('businessDescription', 'Business Description')}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      className={classes.text}
                      title={data && (data.businessDescription as string | undefined)}
                    >
                      {data.businessDescription}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Hidden>

      {/*Company Details mobile */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('legalNameUpperCase', 'Legal Name')}</Box>
            <Box>{data.companyName}</Box>
          </Box>
          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>
              {t('tradingNameDetails', 'Trading Name')}
            </Box>
            <Box>{data.tradingName}</Box>
          </Box>
          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('country', 'Country')}</Box>
            <Box>{data.address?.countryDescription}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('address', 'Address')}</Box>
            <Box>{`${data.address?.line1}, ${data.address?.zip}`}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('city', 'City')}</Box>
            <Box>{data.address?.city}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('phoneNumberSmall', 'Phone number')}</Box>
            <Box>{data.phone}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('entityType', 'Entity Type')}</Box>
            <Box>
              {t(
                find<BaseEnumWithKey>(uboLegalEntityTypesList, {
                  key: data.legalEntityType as string,
                })?.label as string,
              )}
            </Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>
              {t('registrationNumber', 'Registration Number')}
            </Box>
            <Box>{data.companyRegistrationId}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>
              {t('dateRegistration', 'Date of Registration')}
            </Box>
            <Box>{dateConverter(data.companyRegistrationDate)}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>{t('siteLink', 'Site/link')}</Box>
            <Box>{renderSiteLink}</Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>
              {t('businessCategory', 'Business Category')}
            </Box>
            <Box>
              {t(
                find<BaseEnumWithKey>(BusinessCategoriesList, {
                  key: data.businessSector as string,
                })?.label as string,
              )}
            </Box>
          </Box>

          <Box className={classes.mobileTableItem}>
            <Box className={classes.mobileTableLabel}>
              {t('businessDescription', 'Business Description')}
            </Box>
            <Box>{data.businessDescription}</Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
