import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { ContractManagementTablesEnum, TableSortPagesEnum } from '../types'
import { OrderDirection } from '../graphql'
import { getSortStateVarName } from '../utils'
import { usePageFiltersSorting } from './usePageFiltersSorting'

export const useColumnSorting = (
  name: string,
  refetchFunc: (sortedBy: string) => void,
  contractManagementType?: ContractManagementTablesEnum,
): { sortData: () => void } => {
  const location = useLocation()
  const activePage = location.pathname
  const pageVariable = getSortStateVarName(activePage, contractManagementType)

  const { pageFilters, setPageFilters } = usePageFiltersSorting(pageVariable)

  const isSorted = pageFilters.direction === OrderDirection.Descending
  const sortDirection = isSorted ? OrderDirection.Ascending : OrderDirection.Descending

  const sortData = useCallback(() => {
    const directionSorting = activePage.includes(TableSortPagesEnum.transactions)
      ? { sortDirection }
      : { direction: sortDirection }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setPageFilters({ sortBy: name, ...directionSorting })
    // varName({ ...sortState, sortBy: name, ...directionSorting })
    refetchFunc(name)
  }, [name, setPageFilters, refetchFunc, activePage, sortDirection])

  return {
    sortData,
  }
}
