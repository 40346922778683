import { Box, Button, Fab, Grid, Hidden, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { find, sum } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import config from '../../../config'
import {
  ActionSignatureStatus,
  ContractInformationRequestStatus,
  ContractInformationRequestType,
  ContractStatusType,
  GetContractInformationRequestsQuery,
  UboType,
  useActionSignatureRequestsCountQuery,
  useAuthPersonsVideoVerificationsQuery,
  useGetContractInformationRequestsQuery,
} from '../../../graphql'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { AlertTipItem, ContactSupportLink } from '../../Common'
import { ContractInformationRequestsList } from './ContractInformationRequestsList'
import { AccountOpenAlert } from './AccountOpenAlert'
import { ContractInformationRequest } from './ContractInformationRequest'
import { Progress, ProgressStep } from './Progress'
import { VideoVerificationsList } from './VideoVerificationsList'
import { copyToClipboard } from '../../../utils'
import { AlertDataType } from '../../../types'
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/info_icon.svg'
import { useCurrentUser } from '../../../hooks'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
    },
  },
  groupBtn: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  startBusinessButton: {
    marginBottom: theme.spacing(3),
    marginRight: 24,
    minWidth: 219,
    [theme.breakpoints.down('md')]: {
      width: 248,
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  imgContainer: {
    position: 'relative',
  },
  imgBox: {
    // height: '100%',
    backgroundImage: `url(${config.images.signIn})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      bottom: -48,
      left: 0,
      right: -8,
    },
  },
  modalWrap: {
    '& [tabindex]': {
      outline: 'none',
    },
  },
  tipContainer: {
    margin: theme.spacing(0, 0, 4),
  },
  root: {
    border: 'none',
    marginBottom: 48,
    '& .MuiTableCell-root': {
      paddingLeft: 2,
    },
  },
  tableBody: {
    '& .MuiTableRow-root': {
      height: '48px',
    },
    '& .MuiTableRow-root:last-child': {
      '& .MuiTableCell-root': { border: 'none' },
    },
  },
  subtitle: {
    borderTop: '1px solid #E5E5E5',
    borderBottom: '1px solid #E5E5E5',
    padding: '12px 10px',
    marginBottom: 20,
  },
  flexWrap: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'revert',
      alignItems: 'center',
    },
  },
  btnCopy: {
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    marginRight: 12,
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
      transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .MuiSvgIcon-colorPrimary': {
      color: '#276EF1',
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#999',
    },
  },
  inner: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '496px',
    position: 'fixed',
    top: 8,
    zIndex: 9999,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    '&>div': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  referralLink: {
    fontSize: 17,
  },
  box: {
    width: '100%',
  },
}))

const statusLabel = (t: TFunction<'translation', undefined>) => (
  status: ContractInformationRequestStatus | null | undefined,
  type: ContractInformationRequestType | null | undefined,
) => {
  switch (status) {
    case ContractInformationRequestStatus.Completed:
      return t('completed', 'Completed')
    case ContractInformationRequestStatus.UnderReview:
      return t('underReview', 'Under Review')
    case ContractInformationRequestStatus.Rejected:
      return t('rejected', 'Rejected')
    case ContractInformationRequestStatus.Open:
      return type === ContractInformationRequestType.FreeText
        ? t('notResponded', 'Not responded')
        : t('notUploaded', 'Not uploaded')
    default:
      return t('underReview', 'Under Review')
  }
}

export const OnboardingProcess: React.FC<{
  contractStatus: ContractStatusType
  isIntroducerOnly: boolean
  showReferralLink: boolean
  referralLink: string | null | undefined
}> = ({ contractStatus, isIntroducerOnly, showReferralLink, referralLink }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const user = useCurrentUser()
  const [requestId, setRequestId] = useState<string | number | undefined>()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const [alertData, setAlertData] = useState<AlertDataType | null>(null)

  const rejectedCheck =
    contractStatus === ContractStatusType.OnboardingClosed ||
    contractStatus === ContractStatusType.Rejected

  const { data: signaturesCount } = useActionSignatureRequestsCountQuery({
    variables: { contractId: applicationId, statuses: [ActionSignatureStatus.Pending] },
    skip: !applicationId,
  })

  const { data: contractInformationRequestsData } = useGetContractInformationRequestsQuery({
    variables: { contractId: applicationId },
    skip: !applicationId,
  })

  const { data: apVideoVerifications } = useAuthPersonsVideoVerificationsQuery({
    variables: { contractId: applicationId },
    skip: contractStatus === ContractStatusType.PreAssessment,
  })

  const currentContract = useMemo(
    () => find(user?.contracts, (contract) => contract?.id === Number(applicationId)),
    [user, applicationId],
  )
  const isCreatedByCurrentUser = currentContract?.createdBy?.id === user?.id
  const isAuthorizedPerson = useMemo(
    () => currentContract?.authorizedPersons?.map((item) => item?.person?.id).includes(user?.id),
    [currentContract, user],
  )
  const isOnlyCreator = isCreatedByCurrentUser && !isAuthorizedPerson
  const isIndividualOnboarding = currentContract?.ownerType === UboType.NaturalPerson

  const createNewApp = useCallback(() => {
    history.push(APP_PATHS.application.new)
  }, [history])

  const handleCopy = useCallback((value: string) => {
    copyToClipboard(value)

    setAlertData({
      text: t('copiedToClipboard', { name: t('referralLink') }),
      type: 'warning',
      icon: <InfoIcon />,
    })

    setTimeout(() => {
      setAlertData(null)
    }, 3000)
  }, [])

  const informationRequests = useMemo(
    () => groupRequests(contractInformationRequestsData, ContractInformationRequestType.FreeText),
    [contractInformationRequestsData],
  )

  const documentRequests = useMemo(
    () =>
      groupRequests(
        contractInformationRequestsData,
        ContractInformationRequestType.DocumentRequest,
      ),
    [contractInformationRequestsData],
  )

  const documentsRequestsStage = !contractInformationRequestsData?.contractInformationRequests?.filter(
    ({ type }) => type === ContractInformationRequestType.DocumentRequest,
  )?.length
    ? 'future'
    : !documentRequests[ContractInformationRequestStatus.UnderReview].length &&
      !documentRequests[ContractInformationRequestStatus.Open].length
    ? 'done'
    : documentRequests[ContractInformationRequestStatus.UnderReview].length /
      sum(Object.values(documentRequests).map((l) => l.length))

  const informationRequestsStage = !contractInformationRequestsData?.contractInformationRequests?.filter(
    ({ type }) => type === ContractInformationRequestType.FreeText,
  )?.length
    ? 'future'
    : !informationRequests[ContractInformationRequestStatus.UnderReview].length &&
      !informationRequests[ContractInformationRequestStatus.Open].length
    ? 'done'
    : informationRequests[ContractInformationRequestStatus.UnderReview]?.length ??
      0 / sum(Object.values(informationRequests).map((l) => l.length))

  const _statusLabel = useMemo(() => statusLabel(t), [t])

  const completedVVs = useMemo(
    () =>
      apVideoVerifications?.contract?.authorizedPersons?.filter((ap) => ap?.person?.isVerified)
        ?.length ?? 0,
    [apVideoVerifications],
  )

  const anyRequests =
    contractInformationRequestsData?.contractInformationRequests &&
    contractInformationRequestsData?.contractInformationRequests.length > 0

  useEffect(() => {
    let timeOut: NodeJS.Timeout
    if (rejectedCheck) {
      const alertText =
        contractStatus === ContractStatusType.Rejected
          ? t('appRejectedTxt', 'Your application was rejected')
          : t('appClosedTxt', 'Your application was closed')
      setAlertData({
        text: alertText,
        type: 'warning',
        icon: <InfoIcon />,
      })

      timeOut = setTimeout(() => {
        setAlertData(null)
      }, 10000)
    } else {
      setAlertData(null)
    }
    return () => {
      clearTimeout(timeOut)
    }
  }, [rejectedCheck])

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={6}
        className={classes.mainContainer}
        data-test="submittedKlarpayReview"
      >
        <Grid item lg={6} xs={12}>
          <Grid container direction="column" style={{ gap: 20 }}>
            <Box className={classes.box}>
              <Typography variant="h3" className={classes.title}>
                {t('applicationStatus')}
              </Typography>

              <Box>
                <Typography
                  variant="body1"
                  style={
                    anyRequests
                      ? {}
                      : { margin: '5vh 0 1vh 0', lineHeight: 1.5, fontWeight: 'normal' }
                  }
                >
                  {t(
                    anyRequests
                      ? 'klarpayIsReviewingTheApplicationInformation3'
                      : 'klarpayIsReviewingTheApplicationInformation1',
                    {
                      val1: config.name,
                    },
                  )}
                </Typography>
              </Box>
            </Box>
            {anyRequests && (
              <Box className={classes.box}>
                <ContractInformationRequestsList
                  statusLabel={_statusLabel}
                  onSelect={setRequestId}
                  requests={[
                    ...informationRequests[ContractInformationRequestStatus.Open],
                    ...documentRequests[ContractInformationRequestStatus.Open],
                    ...informationRequests[ContractInformationRequestStatus.UnderReview],
                    ...documentRequests[ContractInformationRequestStatus.UnderReview],
                  ]}
                  expanded
                  title={t('pendingRequests', 'Pending Requests - {{count}}', {
                    count:
                      informationRequests[ContractInformationRequestStatus.UnderReview].length +
                      informationRequests[ContractInformationRequestStatus.Open].length +
                      documentRequests[ContractInformationRequestStatus.Open].length +
                      documentRequests[ContractInformationRequestStatus.UnderReview].length,
                  })}
                />
                <ContractInformationRequestsList
                  onSelect={setRequestId}
                  statusLabel={_statusLabel}
                  requests={[
                    ...informationRequests[ContractInformationRequestStatus.Completed],
                    ...documentRequests[ContractInformationRequestStatus.Completed],
                    ...informationRequests[ContractInformationRequestStatus.Rejected],
                    ...documentRequests[ContractInformationRequestStatus.Rejected],
                  ]}
                  title={t('completedRequests', 'Completed Requests - {{count}}', {
                    count:
                      informationRequests[ContractInformationRequestStatus.Completed].length +
                      informationRequests[ContractInformationRequestStatus.Rejected].length +
                      documentRequests[ContractInformationRequestStatus.Completed].length +
                      documentRequests[ContractInformationRequestStatus.Rejected].length,
                  })}
                />
              </Box>
            )}
            {anyRequests && !isIntroducerOnly && (
              <Box className={classes.box}>
                <VideoVerificationsList apVideoVerifications={apVideoVerifications} />
              </Box>
            )}

            <Box mt={4} className={classes.box}>
              {signaturesCount?.actionSignatureRequestsCount === 0 &&
                contractStatus !== ContractStatusType.PreAssessment &&
                anyRequests &&
                !isIntroducerOnly &&
                !isOnlyCreator && (
                  <AccountOpenAlert className={classes.tipContainer} contractId={+applicationId} />
                )}
              <Box className={classes.groupBtn}>
                {!isIntroducerOnly && !isIndividualOnboarding && (
                  <Button
                    className={classes.startBusinessButton}
                    variant="contained"
                    disableElevation
                    onClick={createNewApp}
                    data-test="CreateAnotherApp"
                  >
                    {t('createAnother', 'Create another')}
                  </Button>
                )}
                {!rejectedCheck && (
                  <ContactSupportLink>
                    <Button
                      className={classes.startBusinessButton}
                      variant="outlined"
                      disableElevation
                      color="primary"
                      data-test="contactUs"
                    >
                      {t('contactUs', 'Contact Us')}
                    </Button>
                  </ContactSupportLink>
                )}
              </Box>
            </Box>
          </Grid>
          {requestId && (
            <ContractInformationRequest
              statusLabel={_statusLabel}
              contractId={+applicationId}
              requestId={requestId}
              onClose={() => setRequestId(undefined)}
            />
          )}
          {showReferralLink && referralLink && (
            <Box>
              <Typography variant="h3" className={classes.title}>
                {t('uniqueReferralLink')}
              </Typography>
              <Box className={classes.flexItem}>
                <Box className={classes.inner}>
                  <Typography variant="body1" component="p" className={classes.referralLink}>
                    {referralLink}
                  </Typography>
                </Box>
                <Fab className={classes.btnCopy} onClick={() => handleCopy(referralLink)}>
                  <Tooltip title={t('copyReferralLink')} placement="top" arrow>
                    <FileCopyOutlinedIcon color={'primary'} />
                  </Tooltip>
                </Fab>
              </Box>
              <Typography variant="body1" component="p">
                {t('referrralLinkExplanation')}
              </Typography>
            </Box>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item md={6} className={classes.imgContainer}>
            {contractStatus === ContractStatusType.PreAssessment && !isIndividualOnboarding && (
              <Box className={classes.imgBox}>
                <div />
              </Box>
            )}
            {(contractStatus !== ContractStatusType.PreAssessment || isIndividualOnboarding) &&
              !rejectedCheck && (
                <>
                  <Typography variant={'h4'} className={classes.subtitle}>
                    {t('completion', 'Completion')}
                  </Typography>
                  <Box ml={1}>
                    <Progress>
                      <ProgressStep
                        stage="done"
                        label={t('applicationSubmitted', { val1: config.name })}
                      />
                      <ProgressStep
                        stage={documentsRequestsStage}
                        label={t(
                          'documentsProgress',
                          'Documents: $t(acceptedCount, {"count": {{accepted}} }), $t(pendingCount, {"count": {{pending}} }), $t(notUploadedCount, {"count": {{notUploaded}} })',
                          {
                            accepted:
                              documentRequests[ContractInformationRequestStatus.Completed]
                                ?.length ?? 0,
                            pending:
                              documentRequests[ContractInformationRequestStatus.UnderReview]
                                ?.length ?? 0,
                            notUploaded:
                              documentRequests[ContractInformationRequestStatus.Open]?.length ?? 0,
                          },
                        )}
                      />
                      <ProgressStep
                        stage={informationRequestsStage}
                        label={t(
                          'informationProgress',
                          'Information progress: $t(acceptedCount, {"count": {{accepted}} }), $t(pendingCount, {"count": {{pending}} }), $t(notRespondedCount, {"count": {{notResponded}} })',

                          {
                            accepted:
                              informationRequests[ContractInformationRequestStatus.Completed]
                                ?.length ?? 0,
                            pending:
                              informationRequests[ContractInformationRequestStatus.UnderReview]
                                ?.length ?? 0,
                            notResponded:
                              informationRequests[ContractInformationRequestStatus.Open]?.length ??
                              0,
                          },
                        )}
                      />
                      {!isIntroducerOnly &&
                        (!isIndividualOnboarding ||
                          (isIndividualOnboarding &&
                            contractStatus !== ContractStatusType.PreAssessment)) && (
                          <ProgressStep
                            stage={
                              !anyRequests
                                ? 'future'
                                : completedVVs ===
                                  apVideoVerifications?.contract?.authorizedPersons?.length
                                ? 'done'
                                : completedVVs /
                                  (apVideoVerifications?.contract?.authorizedPersons?.length ?? 1)
                            }
                            label={t(
                              'videoVerificationsCount',
                              'Video verifications: $t(completedVVsCount, {"count": {{completed}} })',

                              {
                                completed: completedVVs,
                              },
                            )}
                          />
                        )}
                      {isOnlyCreator ? (
                        <ProgressStep
                          stage={
                            currentContract?.status === ContractStatusType.Active
                              ? 'done'
                              : 'future'
                          }
                          label={t('applicationIsCompleted', 'Application is completed')}
                        />
                      ) : (
                        <ProgressStep
                          stage="future"
                          label={t(
                            isIndividualOnboarding &&
                              contractStatus === ContractStatusType.PreAssessment &&
                              !user?.isVerified
                              ? 'vvAvailableAfterAllSteps'
                              : 'applicationCompltedAfterAllSteps',
                          )}
                        />
                      )}
                    </Progress>
                  </Box>
                </>
              )}
          </Grid>
        </Hidden>
      </Grid>
      {alertData && (
        <Box mt={3} className={classes.alertContainer}>
          <AlertTipItem
            value={alertData.text}
            iconComponent={alertData.icon}
            type={alertData?.type}
            isCloseButton={false}
            isOpenAlert={!!alertData}
          />
        </Box>
      )}
    </>
  )
}

function groupRequests(
  contractInformationRequestsData: GetContractInformationRequestsQuery | undefined,
  type: ContractInformationRequestType,
) {
  const initial = Object.fromEntries(
    Object.values(ContractInformationRequestStatus).map((status) => [
      status,
      [] as GetContractInformationRequestsQuery['contractInformationRequests'],
    ]),
  ) as Record<
    ContractInformationRequestStatus,
    GetContractInformationRequestsQuery['contractInformationRequests']
  >
  return (
    contractInformationRequestsData?.contractInformationRequests
      .filter((request) => request.type === type)
      .reduce(
        (acc, request) => ({
          ...acc,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          [request.status!]: [...(acc[request.status!] || []), request],
        }),
        initial,
      ) ?? initial
  )
}
