import * as yup from 'yup'
import i18n from '../../i18n'
import {
  MAX_LENGTH,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_ONLY_MESSAGE,
} from '../../constants/validations'
import { isEmpty } from 'lodash'

export const StreetAddressInputSchema = yup.object().shape({
  streetAddress: yup
    .string()
    .nullable()
    .test(
      'length',
      NUMBER_AND_LATINICA_ONLY_MESSAGE,
      (val) => isEmpty(val) || (!!val && val?.length >= MIN_LENGTH && val?.length <= MAX_LENGTH),
    )
    // .matches(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD, NUMBER_AND_LATINICA_ONLY_MESSAGE)
    .required(i18n.t('thisFieldIsRequired', 'This field is required')),
})

export type StreetAddressInput = yup.Asserts<typeof StreetAddressInputSchema>
