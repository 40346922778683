import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import config from '../config'
import { APP_PATHS } from '../routes/paths'
import fragmentTypes from './generated/fragmentTypes.json'

const uri = `${config.apiUrl}/graphql`
const subscriptionsUri = config.subscriptionsUri

const httpLink = new HttpLink({
  uri,
  credentials: 'include',
})

const wsUri = subscriptionsUri || ''
const wsLink = new WebSocketLink(
  new SubscriptionClient(wsUri, {
    reconnect: true,
    lazy: true,
  }),
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { statusCode } = networkError as any
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${statusCode}`)
    if (statusCode === 401) {
      const { hostname, host, pathname, search, protocol, port } = window.location
      if (
        pathname !== APP_PATHS.auth.login &&
        pathname !== APP_PATHS.auth.root &&
        pathname !== APP_PATHS.root &&
        !pathname.startsWith('/invitation/')
      ) {
        window.location.href = `${protocol}//${hostname || host}${port ? `:${port}` : ''}${
          APP_PATHS.auth.login
        }?return=${encodeURIComponent(`${pathname}${search}`)}`
      }
    }
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getApolloClient = () => {
  const cache: InMemoryCache = new InMemoryCache({ possibleTypes: fragmentTypes.possibleTypes })
  return new ApolloClient({
    link: ApolloLink.from([errorLink, splitLink]),
    cache,
  })
}
