import React, { FC, PropsWithChildren } from 'react'
import { Grid } from '@material-ui/core'

export const GridRow: FC<
  PropsWithChildren<{
    tabletFullScreen?: boolean
    fullWidth?: boolean
  }>
> = ({ children, tabletFullScreen = false, fullWidth }) => {
  return (
    <Grid container spacing={0}>
      {tabletFullScreen ? (
        <Grid item xs={12} sm={12} md={fullWidth ? 12 : 8} lg={fullWidth ? 12 : 6}>
          {children}
        </Grid>
      ) : (
        <Grid item xs={12} sm={fullWidth ? 12 : 8} lg={fullWidth ? 12 : 6}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}
