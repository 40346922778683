import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { get } from 'lodash'
import { Maybe } from '../../../graphql'
import { ControlledTextFieldProps } from '../../../types'
import { CountriesList, CountriesUnknownList } from '../../../utils/Data'
import { filterOptionsStart, handleLatinKeysDown } from '../../../utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { BaseEnumWithKey } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(3, 0, 2.5),
    maxWidth: '100%',
    '& + .MuiAutocomplete-popperDisablePortal': {
      left: 0,
      top: 85,
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputBase-input': {
        transform: `translate(0, 9px)`,
      },
    },
  },
  input: {},
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
}))

type CountryAutocompleteSelectProps = {
  name: string
  defaultValue?: Maybe<string> | undefined
  clearFields?: () => void
  readOnly?: boolean | undefined
  setDisableParentScroll?: React.Dispatch<React.SetStateAction<boolean>>
  withUnknown?: boolean
  onlyLatinLetters?: boolean
}
const defaultRules = {} //{ required: true }

export const CountryAutocompleteSelect: React.FC<
  CountryAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  defaultValue = '',
  readOnly = false,
  clearFields,
  rules,
  setDisableParentScroll,
  withUnknown = false,
  onlyLatinLetters = true,
  ...rest
}) => {
  const classes = useStyles()
  const [defaultVal, setDefaultVal] = useState()
  const { errors, register, setValue, watch, getValues } = useFormContext()
  const error = errors ? get(errors, name || 'FormAutocompleteSelect') : null
  const formPreSettedVal = watch(name)
  useEffect(() => {
    if (!getValues(name)) {
      register(name, { ...defaultRules, ...(rules ?? {}) })
    }
  }, [register, getValues, name, rules])

  useEffect(() => {
    setDefaultVal(formPreSettedVal)
  }, [formPreSettedVal])

  const options = withUnknown ? CountriesUnknownList : CountriesList

  return (
    <Autocomplete
      key={`${defaultVal}+${name}`}
      className={`${classes.formField} ${readOnly ? classes.readOnly : ''}`}
      options={options}
      onOpen={() => setDisableParentScroll && setDisableParentScroll(true)}
      onClose={() => setDisableParentScroll && setDisableParentScroll(false)}
      getOptionLabel={(option) => (option as BaseEnumWithKey).label}
      value={options?.find((v: BaseEnumWithKey) => v.key === watch(name))}
      onChange={(_, option) => {
        if (clearFields) {
          clearFields()
        }
        setValue(name, option ? (option as BaseEnumWithKey).key : defaultValue, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }}
      defaultValue={options?.find((v: BaseEnumWithKey) => v.key === defaultVal)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          className={classes.input}
          name={name}
          {...params}
          error={!!error}
          helperText={error ? error.message : null}
          {...rest}
          disabled={readOnly}
          onKeyDown={(e) => handleLatinKeysDown(e, onlyLatinLetters)}
        />
      )}
      filterOptions={filterOptionsStart}
      openText={''}
      closeText={''}
      popupIcon={<ExpandMoreIcon />}
      disabled={readOnly}
      disableClearable
      autoHighlight
      clearOnEscape
      openOnFocus={!readOnly}
    />
  )
}
