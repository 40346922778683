import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { intersection, isEmpty, isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { ReactComponent as AddCircleIcon } from '../../assets/images/icons/add_circle_outline_white.svg'
import {
  AlertTipItem,
  ButtonWithTooltip,
  cardsPageStateVar,
  FiltersResetBtn,
  Loader,
} from '../../components'
import { ConfirmSignatureModal } from '../../components/Common/ActionsSign2FAModal'

import {
  AccountStatus,
  ActionSignature,
  CardsOrderBy,
  CardType,
  ContractType,
  Currency,
  LimitedAccessRight,
  OrderDirection,
  useCardsCountQuery,
  useCardsListQuery,
  useGetContractAccountsQuery,
  useGetUserDataQuery,
} from '../../graphql'
import { useDetermineUserRights, useIsLimitedContract, usePageFiltersSorting } from '../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { CardDetailsPage } from './CardDetailsPage'
import { CardFilters } from './CardFilters'
import CardsEmptyStatePage from './CardsEmptyStatePage'
import { CardsListTab } from './CardsListTab'
import { EmptyCard } from './EmptyCard'
import { initCardsPageState } from '../../constants'
import { CARDS_ITEMS_PER_PAGE } from './helpers'
import { AlertDataType } from '../../types'
import { CARD_ALERT_TIMEOUT_SHORT } from '../../constants/alerts'
import config from '../../config'

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    flexWrap: 'nowrap',
    '& .tableWrap': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
    },
  },
  emptyPageHeader: {
    padding: '80px 0 40px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
  },
  emptyPageTitle: {
    fontSize: 32,
    alignSelf: 'start',
  },
  header: {
    padding: '80px 0 40px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 20px 0',
    },
  },
  margin: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .tableWrap': {
      marginBottom: 'auto',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  title: {
    alignSelf: 'center',
    fontSize: '32px',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 21,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 50,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  paginationText: {
    fontSize: '0.875rem',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cardsView: {
    width: '100%',
    flex: '1 1 auto',
    '&.MuiGrid-item': {
      paddingRight: 24,
    },
    [theme.breakpoints.down('xs')]: {
      '&.MuiGrid-item': {
        padding: 0,
      },
    },
  },
  cardView: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '0 0 400px',
    minHeight: '100vh',
    '&.MuiGrid-item': {
      paddingTop: 122,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      left: 0,
    },
  },
  fullHeight: {
    height: '100%',
  },
  addButton: {
    padding: theme.spacing(1, 2.3),
    '& svg': {
      marginLeft: theme.spacing(1.4),
    },
    fontSize: 18,
    lineHeight: '24px',
    border: '1px solid #000000',
    backgroundColor: '#000000',
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    '&.MuiButton-contained': {
      color: '#fff',
    },
    '& svg path': {
      transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
    '&:hover svg path': {
      fill: '#000',
    },
  },
  loader: {
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.up(1280)]: {
    cardDetailsWrap: {
      flex: '0 0 400px',
      display: 'flex',
    },
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  resetBtn: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '496px',
    position: 'fixed',
    top: 8,
    zIndex: 9999,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&>div': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    },
  },
}))

const Cards: FC = () => {
  const [actionModal, setActionModal] = useState<string | undefined>('')
  const [actionData, setActionData] = useState<ActionSignature | undefined>()
  const classes = useStyles()
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { pageFilters, setPageFilters } = usePageFiltersSorting(cardsPageStateVar)

  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [alertData, setAlertData] = useState<AlertDataType>()

  const isFiltered = useMemo(() => !isEqual(pageFilters, initCardsPageState), [pageFilters])

  const { page, searchValue } = pageFilters
  const { t } = useTranslation()
  const [selectedCardData, setSelectedCardData] = useState<CardType>()
  const [openLimits, setOpenLimits] = useState<boolean>(false)
  const [isActiveAccounts, setIsActiveAccounts] = useState<boolean>(false)

  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  const { isLimitedContract, contractStatusLabel, type, contractCountry } = useIsLimitedContract()

  const { data: accountsData } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
    fetchPolicy: 'cache-first',
  })
  const { data: individualData } = useGetUserDataQuery()
  const { data: cardsData, loading, refetch: cardsListRefetch } = useCardsListQuery({
    variables: {
      contractId: applicationId,
      search: searchValue,
      limit: CARDS_ITEMS_PER_PAGE,
      orderBy: pageFilters.sortBy,
      orderDirection: pageFilters.direction,
      offset: (Number(page) - 1) * CARDS_ITEMS_PER_PAGE,
    },
    fetchPolicy: 'network-only',
  })

  const cardsList = cardsData?.contractCards

  const {
    data: count,
    refetch: countRefetch,
    loading: cardCountLoading,
    previousData,
  } = useCardsCountQuery({
    variables: {
      contractId: applicationId,
      search: searchValue,
    },
  })

  const isNoCreateCardLimitations = useMemo(() => {
    const accountsAvailiableCurrencies = accountsData?.accounts?.map((account) => account?.currency)
    const allowedCardCurrencies = intersection(
      accountsAvailiableCurrencies,
      config.allowedCardCurrencies,
    )

    if (type === ContractType.Individual && contractCountry) {
      return (
        process.env.REACT_APP_INDIVIDUAL_COUNTRY_EUR?.includes(contractCountry) &&
        allowedCardCurrencies.includes(Currency.Eur)
      )
    }

    if (type === ContractType.Corporate && contractCountry) {
      return (
        (process.env.REACT_APP_CORPORATE_COUNTRY_EUR?.includes(contractCountry) &&
          allowedCardCurrencies.includes(Currency.Eur)) ||
        (process.env.REACT_APP_CORPORATE_COUNTRY_USD?.includes(contractCountry) &&
          allowedCardCurrencies.includes(Currency.Usd))
      )
    }
    return !isEmpty(allowedCardCurrencies)
  }, [type, contractCountry, accountsData?.accounts])

  const tooltipMessage = useMemo(() => {
    let msg = t('NoActiveAccounts', "You don't have active accounts")
    if (isLimitedContract) {
      msg = t(
        'isNotAvailable',
        `Adding cards is not available, because the contract is ${contractStatusLabel}`,
        {
          option: 'Adding cards',
          contractStatusLabel,
        },
      )
    }
    if (!isNoCreateCardLimitations) {
      msg = t(
        'cardUnAvailableMsg',
        'New card is not available at this time. Please contact support for more details.',
      )
    }
    return msg
  }, [isLimitedContract, isNoCreateCardLimitations])

  const handleClose = useCallback(() => {
    setActionModal('')
    setActionData(undefined)
  }, [])

  const onAddNew = useCallback(() => {
    history.push(
      generatePath(APP_PATHS.dashboard.createCard, {
        [PATH_PARAMS.applicationId]: applicationId,
        [PATH_PARAMS.cardsPageNum]: page,
      }),
    )
  }, [applicationId, history, page])

  const handleChange = useCallback(
    (_, value) => {
      setPageFilters({ page: value })
    },
    [setPageFilters],
  )

  const handleSort = useCallback(
    (sortedBy: string) => {
      handleChange(null, 1)
      if (pageFilters.sortBy !== sortedBy) {
        setPageFilters({ sortBy: sortedBy as CardsOrderBy, direction: OrderDirection.Ascending })
      } else {
        const direction =
          pageFilters.direction === OrderDirection.Descending
            ? OrderDirection.Ascending
            : OrderDirection.Descending
        setPageFilters({ direction })
      }
    },
    [handleChange, setPageFilters, pageFilters.direction, pageFilters.sortBy],
  )

  useEffect(() => {
    let alertTimeout: ReturnType<typeof setTimeout>
    if (isOpenAlert) {
      alertTimeout = setTimeout(() => {
        setIsOpenAlert(false)
        clearTimeout(alertTimeout)
      }, CARD_ALERT_TIMEOUT_SHORT)
    }
    return () => clearTimeout(alertTimeout)
  }, [isOpenAlert])

  useEffect(() => {
    setIsActiveAccounts(!isEmpty(accountsData?.accounts))
  }, [accountsData, setIsActiveAccounts])

  useEffect(() => {
    setTimeout(cardsListRefetch, 300)
    setTimeout(countRefetch, 300)
  }, [actionModal, searchValue])

  useEffect(() => {
    return () => {
      setPageFilters({ page: 1 })
    }
  }, [individualData?.viewer, applicationId])

  const isLimitedAccess =
    userRights?.limitedAccessRight === LimitedAccessRight?.CardHolder || isViewOnly
  if (cardCountLoading && !previousData) {
    return (
      <Box display="flex" alignItems="center" height="100%">
        <Loader />
      </Box>
    )
  }

  if (!cardCountLoading && !count?.contractCardsCount && !searchValue) {
    return (
      <Box className={clsx(classes.mainContainer)}>
        <Box className={classes.emptyPageHeader}>
          <div className={classes.emptyPageTitle}>
            <Typography variant="h1">{t('cads', 'Cards')}</Typography>
          </div>
          <CardsEmptyStatePage
            checkIsCardholder={userRights?.limitedAccessRight === LimitedAccessRight?.CardHolder}
            isNoCreateCardLimitations={isNoCreateCardLimitations}
          />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={8}
        className={classes.mainContainer}
        data-test="cadsPage"
      >
        <Grid item className={classes.cardsView}>
          <Box className={clsx(classes.mainContainer, isEmpty(cardsList) && classes.margin)}>
            <Box className={classes.header}>
              <div className={classes.title}>
                <Typography variant="h1">{t('cads', 'Cards')}</Typography>
              </div>
              <Box className={classes.buttonSection}>
                {/*<TotalSpent />*/}
                {!isLimitedAccess && (
                  <ButtonWithTooltip
                    title={tooltipMessage}
                    disabled={!isActiveAccounts || isLimitedContract || !isNoCreateCardLimitations}
                    placement="bottom-end"
                    className={classes.addButton}
                    variant="contained"
                    onClick={onAddNew}
                    data-test="addNew"
                  >
                    {t('addNew', 'Add new')}
                    {<AddCircleIcon />}
                  </ButtonWithTooltip>
                )}
                <Hidden xsDown>
                  <Box>
                    <FiltersResetBtn
                      isDisabled={!isFiltered}
                      resetPageState={() => setPageFilters(initCardsPageState)}
                    />
                  </Box>
                </Hidden>
              </Box>
            </Box>
            <CardFilters
              setPage={(page) => handleChange(null, page)}
              disabled={!loading && isEmpty(cardsList) && !searchValue}
            />
            <Hidden smUp>
              <Box className={classes.resetBtn}>
                <FiltersResetBtn
                  isDisabled={!isFiltered}
                  resetPageState={() => setPageFilters(initCardsPageState)}
                />
              </Box>
            </Hidden>

            <Box className="tableWrap">
              {!loading && isEmpty(cardsList) ? (
                <CardsEmptyStatePage
                  checkIsCardholder={
                    userRights?.limitedAccessRight === LimitedAccessRight?.CardHolder
                  }
                  isNoCreateCardLimitations={isNoCreateCardLimitations}
                />
              ) : (
                <>
                  <CardsListTab
                    setIsOpenAlert={setIsOpenAlert}
                    setAlertData={setAlertData}
                    count={count}
                    isLoading={loading}
                    data={cardsList as [CardType]}
                    selectedCardData={selectedCardData}
                    setSelectedCardData={setSelectedCardData}
                    setActionData={setActionData}
                    setActionModal={setActionModal}
                    isViewOnly={isViewOnly}
                    setOpenLimits={setOpenLimits}
                    openLimits={openLimits}
                    handleSort={handleSort}
                  />
                </>
              )}
            </Box>
          </Box>

          {/* 2FA Modal */}
          {!!actionModal && actionData && (
            <ConfirmSignatureModal
              openAction={actionModal}
              handleClose={handleClose}
              actionData={actionData}
              multipleActionsData={[]}
            />
          )}
        </Grid>

        {!selectedCardData && (
          <Hidden mdDown>
            <Grid item className={classes.cardView}>
              <EmptyCard />
            </Grid>
          </Hidden>
        )}
        {selectedCardData && (
          <Box className={classes.cardDetailsWrap}>
            <Hidden mdDown implementation={'css'}>
              <Grid item className={`${classes.cardView} ${classes.fullHeight}`}>
                <CardDetailsPage
                  setIsOpenAlert={setIsOpenAlert}
                  setAlertData={setAlertData}
                  selectedCardData={selectedCardData}
                  setOpenLimits={setOpenLimits}
                  openLimits={openLimits}
                />
              </Grid>
            </Hidden>
          </Box>
        )}
      </Grid>
      <Box className={classes.alertContainer}>
        {alertData && (
          <AlertTipItem
            value={alertData.text}
            title={alertData.title}
            iconComponent={alertData.icon}
            type={alertData?.type}
            isCloseButton={true}
            setIsOpenAlert={setIsOpenAlert}
            isOpenAlert={isOpenAlert}
          />
        )}
      </Box>
    </>
  )
}

export default Cards
