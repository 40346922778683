import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { map, isEqual, find } from 'lodash'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Checkbox } from '@material-ui/core'

import { ControlledTextFieldProps, KeyAndLabelSelectType } from '../../../types'
import { handleLatinKeysDown } from '../../../utils'

const uncheckedIcon = <CheckBoxOutlineBlankIcon color={'primary'} fontSize="small" />
const checkedIcon = <CheckBoxIcon color={'primary'} fontSize="small" />

const useStyles = makeStyles(() => ({
  formField: {
    maxHeight: '50vh',
    height: '100%',
    maxWidth: '100%',
    '& .MuiInputLabel-root': {
      maxWidth: 'calc(100% - 40px)',
      minWidth: 'calc(100% - 40px)',
    },
  },
  autocompleteListWrap: {
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      position: 'relative',
      paddingRight: 40,
      backgroundColor: 'transparent',
      '&::after': {
        display: 'none',
      },
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      color: '#434343',
    },
  },
  readOnly: {
    '& div': {
      backgroundColor: '#F5F5F5',
    },
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
  multiSelect: {
    '& .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable': {
      borderRadius: '4px',
      backgroundColor: '#F0F0F0',
      '& svg > path': {
        d:
          'path("M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z")',
        fill: 'black',
        fillOpacity: '0.4',
      },
      '& svg': {
        width: 24,
        height: 24,
        margin: '12px 0 0 0',
      },
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
    },
  },
}))

interface FormAutocompleteSelectProps {
  name: string
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  data: KeyAndLabelSelectType[]
  disableClearable?: boolean
  readOnly?: boolean
}

const defaultRules = {} //{ required: true }

export const FormAutocompleteMultiSelectComponent: React.FC<
  FormAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  disableClearable,
  data,
  shouldValidateParam,
  readOnly = false,
  rules,
  defaultValue,
  ...rest
}) => {
  const classes = useStyles()
  const { errors, register, setValue, watch } = useFormContext()
  const error = errors ? errors[name || 'FormAutocompleteSelect'] : null
  const [defaultData, setDefaultData] = useState<KeyAndLabelSelectType[]>([])
  const currentValue = useMemo(() => watch(name), [watch, name])

  useEffect(() => {
    setDefaultData(
      data?.filter((itemData) =>
        find(currentValue, (itemCurrentValue) => itemCurrentValue === itemData.key),
      ),
    )
  }, [currentValue])

  useEffect(() => {
    if (defaultValue) {
      setDefaultData(
        data?.filter((itemData) =>
          find(defaultValue, (itemCurrentValue) => itemCurrentValue === itemData.key),
        ),
      )
    }
  }, [])

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })
  }, [register, name, rules])

  return (
    <Autocomplete
      value={defaultData}
      multiple
      className={`${classes.formField} ${classes.multiSelect} ${readOnly ? classes.readOnly : ''}`}
      classes={{
        paper: classes.autocompleteListWrap,
      }}
      options={data}
      getOptionLabel={(option) => option.label}
      onChange={(_, value) => {
        const newVal = map(value, (itemList) => itemList.key)
        setValue(name, newVal, { shouldValidate: true, shouldDirty: true })
      }}
      disableCloseOnSelect
      disabled={readOnly}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          error={!!error}
          helperText={error ? error.message : null}
          {...rest}
          inputProps={{ ...rest.inputProps, ...params.inputProps, readOnly }}
          onKeyDown={handleLatinKeysDown}
          multiline
        />
      )}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox checked={selected} icon={uncheckedIcon} checkedIcon={checkedIcon} />
          {option.label}
        </>
      )}
      openText={''}
      closeText={''}
      popupIcon={<ExpandMoreIcon />}
      openOnFocus={!readOnly}
    />
  )
}
export const FormAutocompleteMultiSelect = React.memo(FormAutocompleteMultiSelectComponent, isEqual)
