import * as yup from 'yup'

import {
  FIRSTNAME_IS_REQUIRED_MESSAGE,
  LASTNAME_IS_REQUIRED_MESSAGE,
  LATINICA_ONLY_MESSAGE,
  LATINICA_ONLY_REGEXP,
  MIN_LENGTH,
} from '../../constants/validations'

export const NameInputSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(FIRSTNAME_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  lastName: yup
    .string()
    .required(LASTNAME_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE)
    .test('minLength', LATINICA_ONLY_MESSAGE, (val) => !!val && val?.length >= MIN_LENGTH),
})

export type NameInput = yup.Asserts<typeof NameInputSchema>
