export function joinPaths(...paths: string[]): string {
  return paths.join('/')
}

export const CONTACT_SUPPORT_PATH = 'contact-support'

export const ROOT_PATHS = {
  appRoot: '/',
  auth: '/auth',
  signup: '/signup',
  preAssessment: '/pre-assessment',
  confirmEmail: '/confirm-email',
  invitation: '/invitation',
  error: '/error',
  personalInterest: '/personal-interest',
  application: '/application',
  // onboarding: '/onboarding',
  dashboard: '/dashboard',
  contactSupport: `/${CONTACT_SUPPORT_PATH}`,
  person: '/person',
}

export const PATH_PARAMS = {
  token: 'token',
  invitationId: 'invitationId',
  applicationId: 'applicationId',
  signatureId: 'signatureId',
  transactionId: 'transactionId',
  standingOrderId: 'standingOrderId',
  authPersonId: 'authPersonId',
  uboId: 'uboId',
  ownedUboId: 'ownedUboId',
  phoneLastDigits: 'phoneLastDigits',
  cardId: 'cardId',
  accountId: 'accountId',
  vbanId: 'vbanId',
  twoFaType: 'twoFaType',
  cardsPageNum: 'cardsPageNum',
  recipientID: 'recipientID',
  payoutId: 'payoutId',
  contractManagementTab: 'contractManagementTab',
  facilitationTab: 'facilitationTab',
  paymentId: 'paymentId',
}

export const APP_PATHS = {
  root: ROOT_PATHS.appRoot,
  contactSupport: ROOT_PATHS.contactSupport,
  signup: ROOT_PATHS.signup,
  preAssessment: ROOT_PATHS.preAssessment,
  personalInterest: ROOT_PATHS.personalInterest,
  confirmEmail: joinPaths(ROOT_PATHS.confirmEmail, `:${PATH_PARAMS.token}`),
  invitation: joinPaths(ROOT_PATHS.invitation, `:${PATH_PARAMS.invitationId}`),
  partnerSignup: joinPaths(ROOT_PATHS.signup, 'partner'),
  phoneVerification: '/phone-verification',
  videoVerification: joinPaths('/video-verification', `:${PATH_PARAMS.token}`),
  auth: {
    root: ROOT_PATHS.auth,
    login: joinPaths(ROOT_PATHS.auth, 'login'),
    twofa: joinPaths(
      ROOT_PATHS.auth,
      `twoFaType/:${PATH_PARAMS.twoFaType}/twofa/:${PATH_PARAMS.phoneLastDigits}`,
    ),
    logout: joinPaths(ROOT_PATHS.auth, 'logout'),
    resetPassword: joinPaths(ROOT_PATHS.auth, `reset-password/:${PATH_PARAMS.token}`),
    forgotPassword: joinPaths(ROOT_PATHS.auth, 'forgot-password'),
    changeEmail: joinPaths(ROOT_PATHS.auth, 'change-email'),
  },
  application: {
    root: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}`),
    new: joinPaths(ROOT_PATHS.application, 'new'),
    contactSupport: joinPaths(
      ROOT_PATHS.application,
      `:${PATH_PARAMS.applicationId}/${CONTACT_SUPPORT_PATH}`,
    ),
    transferContractOwnership: joinPaths(
      ROOT_PATHS.application,
      `:${PATH_PARAMS.applicationId}/transfer-contract-ownership`,
    ),
    authorizedPersons: {
      add: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/authorized-persons/add`,
      ),
      new: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/authorized-persons/new`,
      ),
      list: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/authorized-persons/list`,
      ),
      edit: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/authorized-persons/:${PATH_PARAMS.authPersonId}`,
      ),
    },
    directors: {
      new: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/directors/new`),
      list: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/directors/list`),
    },
    beneficialOwners: {
      editUboOwner: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/beneficial-owners/edit/:${PATH_PARAMS.uboId}/owner/:${PATH_PARAMS.ownedUboId}`,
      ),
      newUboOwner: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/beneficial-owners/:${PATH_PARAMS.uboId}/new`,
      ),
      edit: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/beneficial-owners/edit/:${PATH_PARAMS.uboId}`,
      ),
      uboList: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/beneficial-owners/list/:${PATH_PARAMS.uboId}`,
      ),
      add: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/beneficial-owners/add`),
      new: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/beneficial-owners/new`),
      list: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/beneficial-owners/list`,
      ),
    },
    onboarding: {
      // root: ROOT_PATHS.onboarding,
      // contactSupport: joinPaths(ROOT_PATHS.application, CONTACT_SUPPORT_PATH),
      start: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/onboarding/start`),
      personalDetails: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/onboarding/personal-details`,
      ),
      phoneVerification: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/onboarding/phone-verification`,
      ),
      videoVerification: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/onboarding/video-verification`,
      ),
      complete: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/onboarding/complete`,
      ),
    },
    edit: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/business-details`),
    review: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/review`),
    status: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/status`),
    actions: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/actions`),
    actionDetails: joinPaths(
      ROOT_PATHS.application,
      `:${PATH_PARAMS.applicationId}/actions/:${PATH_PARAMS.signatureId}`,
    ),
    settings: joinPaths(ROOT_PATHS.application, `:${PATH_PARAMS.applicationId}/settings`),
    individual: {
      new: joinPaths(ROOT_PATHS.application, 'individual/new'),
      phoneVerification: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/individual/phone-verification`,
      ),
      identityVerification: joinPaths(
        ROOT_PATHS.application,
        `:${PATH_PARAMS.applicationId}/individual/identity-verification`,
      ),
    },
  },
  // onboarding: {
  //   root: ROOT_PATHS.onboarding,
  //   contactSupport: joinPaths(ROOT_PATHS.onboarding, CONTACT_SUPPORT_PATH),
  //   start: joinPaths(ROOT_PATHS.onboarding, `application/:${PATH_PARAMS.applicationId}`),
  //   personalDetails: joinPaths(
  //     ROOT_PATHS.onboarding,
  //     `application/:${PATH_PARAMS.applicationId}/personal-details`,
  //   ),
  //   phoneVerification: joinPaths(
  //     ROOT_PATHS.onboarding,
  //     `application/:${PATH_PARAMS.applicationId}/phone-verification`,
  //   ),
  //   videoVerification: joinPaths(
  //     ROOT_PATHS.onboarding,
  //     `application/:${PATH_PARAMS.applicationId}/video-verification`,
  //   ),
  //   complete: joinPaths(
  //     ROOT_PATHS.onboarding,
  //     `application/:${PATH_PARAMS.applicationId}/complete`,
  //   ),
  // },
  dashboard: {
    root: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}`),
    actions: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/actions`),
    transactions: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/transactions`),
    standingOrders: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/standing-orders/:${PATH_PARAMS.accountId}`,
    ),
    standingOrderDetails: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/standing-order-details/:${PATH_PARAMS.standingOrderId}`,
    ),
    mismatches: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/transactions/mismatches`,
    ),
    vbans: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/account-home/vbans/:${PATH_PARAMS.accountId}`,
    ),
    vbanstransactions: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/transactions/:${PATH_PARAMS.vbanId}`,
    ),
    changeAdress: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/change-address`),
    accountClosure: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/account-closure`,
    ),
    accountOpening: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/account-opening`,
    ),
    createAccount: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/create-account`),
    home: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/account-home`),
    accounts: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/accounts`),
    personProfile: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/person-profile`),
    settings: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/settings`),
    contactSupport: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/${CONTACT_SUPPORT_PATH}`,
    ),
    newPayment: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/new-payment`),
    batchPayment: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/batch-payment`),
    actionDetails: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/actions/:${PATH_PARAMS.signatureId}`,
    ),
    trxDetails: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/trx-details/:${PATH_PARAMS.transactionId}`,
    ),
    cardTrxs: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/card-trxs/:${PATH_PARAMS.cardId}`,
    ),
    contractManagement: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/contract-management/:${PATH_PARAMS.contractManagementTab}`,
    ),
    cards: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/cards/:${PATH_PARAMS.cardsPageNum}`,
    ),
    createCard: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/cards/createCard/:${PATH_PARAMS.cardsPageNum}`,
    ),
    activateCard: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/cards/:${PATH_PARAMS.cardId}/activateCard/:${PATH_PARAMS.cardsPageNum}`,
    ),
    developers: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/developers`),
    paymentFacilitator: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/payment-facilitator/:${PATH_PARAMS.facilitationTab}`,
    ),
    paymentDetails: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/payment-details/:${PATH_PARAMS.paymentId}`,
    ),
    clients: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/clients`),
    clientOverview: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/clientOverview/:${PATH_PARAMS.recipientID}`,
    ),
    partnerManagementOverview: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/partner-management`,
    ),
    partnerManagementPayout: joinPaths(
      ROOT_PATHS.dashboard,
      `:${PATH_PARAMS.applicationId}/payout/:${PATH_PARAMS.payoutId}`,
    ),
  },

  errors: {
    _404: joinPaths(ROOT_PATHS.error, '404'),
    _50x: joinPaths(ROOT_PATHS.error, '50x'),
  },

  mapping: {
    cardsWithoutPage: joinPaths(ROOT_PATHS.dashboard, `:${PATH_PARAMS.applicationId}/cards`),
  },
}

export const DashboardAppParamPaths = Object.values(APP_PATHS.dashboard).filter((path) =>
  path.includes(':applicationId'),
)
