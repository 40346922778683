import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { countries } from '@klarpay/enums'
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { find, isEmpty } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useBeforeUnload } from 'react-use'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { AppActions, RegistrationSteps, statusActions, StepperTypes } from '../../../constants'
import {
  ContractStatusType,
  GetContractDetailsDocument,
  GetUserContractsDocument,
  LegalEntity,
  NewAccountCurrency,
  useCreateContractMutation,
  useGetContractDetailsQuery,
  useGetContractsCountQuery,
  useGetPendingPreassessmentsQuery,
  useGetViewerLazyQuery,
  useUpdateContractDetailsMutation,
} from '../../../graphql'
import {
  continueRoute as continueRouteVar,
  goBackPathVar,
  isModalDialogOpen as isModalDialogOpenVar,
  isNavMenuRoute as isNavMenuRouteVar,
  isUnsaveFormData as isUnsaveFormDataVar,
} from '../../../graphql/local'
import { useStepper } from '../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { PreAssessmentOnboardingInput, PreAssessmentOnboardingInputSchema } from '../../../schemes'
import { registeredAddressEmptyProps } from '../../../stubs'
import {
  createInitValues,
  focusKeyPressNext,
  parsePreAssessementFormData,
  parsePreassessementValues,
  scrollToInputElement,
} from '../../../utils'

import {
  AddressAutocomplete,
  AddressCorrespondencePropsType,
  CountryPhoneSelect,
  CustomPrompt,
  FormControlledTextField,
  GridRow,
  Loader,
  LoadingButton,
} from '../../Common'
import { CompanyInformation } from './CompanyInformation'
import { ContactPerson } from './ContactPerson'
import { GeneralQuestions } from './GeneralQuestions'
import { RegulatorInformation } from './RegulatorInformation'
import { TransactionalQuestions } from './TransactionalQuestions'
import { UboInformation } from './UboInformation'
import { CardPaymentsQuestions } from './CardPaymentsQuestions'
import NavigationPrompt from 'react-router-navigation-prompt'
import { ExpectedVolumes } from '../../../types'

export const useCommonStyles = makeStyles((theme: Theme) => ({
  wrapperButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  button: {
    height: 48,
    width: '100%',
    margin: theme.spacing(4, 0, 3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  titleBox: {
    marginBottom: theme.spacing(4),
  },
  titleSubBox: {
    marginTop: theme.spacing(3),
    fontWeight: 400,
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  boxInput: {
    margin: '20px 0 20px 0',
    display: 'flex',
    '& .MuiInputLabel-root': {
      minWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-input': {
        transform: `translate(0, 8px)`,
      },
    },
  },
  iconTooltip: {
    '& .MuiSvgIcon-root': {
      color: '#999999',
      position: 'absolute',
      top: 22,
      right: 47,
      [theme.breakpoints.down('xs')]: {
        right: 37,
      },
      '&:hover': {
        color: '#000000',
      },
    },
  },
  tooltipChargeBearer: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '495px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
  titleTooltip: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '24px',
  },
  textTooltip: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  inputHelperText: {
    margin: theme.spacing(1.5, 0, 0),
    paddingLeft: 12,
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textDecoration: 'revert',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      margin: '0 0 0 5px',
      '& svg': {
        display: 'none',
      },
    },
  },
  boxMultiSelect: {
    margin: '24px 0 8px 0',
    display: 'flex',
    '& .MuiAutocomplete-inputRoot': {
      minHeight: 48,
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 20px)',
      },
    },
  },
  formControlMultiSelect: {
    marginTop: 20,
  },
}))

const correspondenceAddressProps: AddressCorrespondencePropsType = {
  countryCorrespondence: {
    name: 'countryCorrespondence',
    initialValue: undefined,
  },
  streetAddress: {
    name: 'streetAddressCorrespondence',
    initialValue: undefined,
  },
  additionalDetailsOptional: {
    name: 'additionalDetailsOptionalCorrespondence',
    initialValue: undefined,
  },
  postalCode: {
    name: 'postalCodeCorrespondence',
    initialValue: undefined,
  },
  city: {
    name: 'cityCorrespondence',
    initialValue: undefined,
  },
}

export const PreAssessmentQuestionnaire: FC<{
  isIntroducer: boolean
  initialTempValues: { [x: string]: unknown }
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      [x: string]: unknown
    }>
  >
}> = ({ isIntroducer, initialTempValues, setFormValues }) => {
  const { t } = useTranslation()
  const classes = useCommonStyles()
  const history = useHistory()

  const [allowNavigation, setAllowNavigation] = useState(false)
  const [isAddressesEqual, setIsAddressesEqual] = useState<boolean>(true)
  const [correspondenceNeeded, setCorrespondenceNeeded] = useState<boolean>(true)
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const goBackPath = useReactiveVar(goBackPathVar)
  const isDirtyForm = useReactiveVar(isUnsaveFormDataVar)
  const { setHeaderText, setCurrentStep } = useStepper(StepperTypes.registration)

  const [GetViewerQuery] = useGetViewerLazyQuery()
  const { data: contractData, loading: contractLoading, refetch } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
    fetchPolicy: 'network-only',
  })
  const {
    data: preAssessmentsData,
    loading: preAssessmentsLoading,
  } = useGetPendingPreassessmentsQuery({
    skip: !!applicationId,
  })
  const { data: contractsCount, loading: contractsCountLoading } = useGetContractsCountQuery({
    skip: !!applicationId,
  })

  const [createContractMutation, { loading: mutationCreateLoading }] = useCreateContractMutation()
  const [
    updateContractDetailsMutation,
    { loading: mutationUpdateLoading },
  ] = useUpdateContractDetailsMutation()

  const mutationLoading = mutationCreateLoading || mutationUpdateLoading

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(PreAssessmentOnboardingInputSchema),
    shouldUnregister: false,
  })
  const { formState, watch, setValue, reset, getValues, errors } = methods
  const companyName = watch('companyName')

  useEffect(
    () => () =>
      setFormValues((prevValues) => ({
        ...prevValues,
        ...getValues(),
      })),
    [getValues],
  )

  const preassessment = useMemo(() => {
    if (
      !preAssessmentsLoading &&
      !isEmpty(preAssessmentsData?.preAssessments) &&
      !contractsCountLoading &&
      contractsCount?.contractsCount === 0
    ) {
      return find(
        preAssessmentsData?.preAssessments,
        (p) => p?.companyInfoRegisteredName?.toLowerCase() === companyName?.toLowerCase(),
      )
    }
  }, [
    preAssessmentsData,
    preAssessmentsLoading,
    companyName,
    contractsCountLoading,
    contractsCount,
  ])

  useEffect(() => {
    if (contractsCount?.contractsCount === 0) {
      if (preassessment) {
        setValue('regulatorNameAndWebsite', preassessment.regulatoryInfoName)
        setValue('licenseNumber', preassessment.regulatoryInfoLicenseNumber)
        setValue(
          'country',
          find(countries, { label: preassessment.companyInfoCountryOfRegistration as string })?.key,
        )
        setValue('fullName', preassessment.contactPersonFullName)
        setValue('email', preassessment.contactPersonEmail)
        setValue('contactPersonPhoneNumber', preassessment.contactPersonPhoneNumber)
        setValue('companyAnnualIncome', preassessment.companyAnnualIncome)
        setValue('companyAnnualIncomeCurrency', preassessment.companyAnnualIncomeCurrency)
        setValue('paymentProviders', preassessment.generalQuestionsPaymentProviders)
        setValue(
          'estimatedIncomingTransactionsAmount',
          preassessment.incomingEstimatedMonthlyAmount,
        )
        setValue(
          'estimatedOutgoingTransactionsAmount',
          preassessment.outgoingEstimatedMonthlyAmount,
        )
        setValue('businessActivitiesAndServices', preassessment.generalQuestionsBusinessActivities)

        const parsedCurrencyVolumes: ExpectedVolumes[] = []
        ;(preassessment.currencyAndVolume ?? []).forEach((item: string | null) => {
          const expectedVolume = item?.split(' ')[0].split('%')[0]
          const currency = item?.split(' ')[1] as NewAccountCurrency
          currency && expectedVolume && parsedCurrencyVolumes.push({ currency, expectedVolume })
        })
        setValue('expectedVolumes', parsedCurrencyVolumes)
      } else {
        setValue('regulatorNameAndWebsite', '')
        setValue('licenseNumber', '')
        setValue('country', '')
        setValue('fullName', '')
        setValue('email', '')
        setValue('contactPersonPhoneNumber', '')
        setValue('companyAnnualIncome', '')
        setValue('companyAnnualIncomeCurrency', '')
        setValue('paymentProviders', '')
        setValue('businessActivitiesAndServices', '')
        setValue('expectedVolumes', [{ currency: '', expectedVolume: '' }])
      }
    }
  }, [preassessment, setValue, getValues, contractsCount])

  useEffect(() => {
    setValue(`correspondenceRequired`, correspondenceNeeded, {
      shouldValidate: false,
      shouldDirty: true,
    })
  }, [correspondenceNeeded, setValue])

  useEffect(() => {
    if (isAddressesEqual) {
      setCorrespondenceNeeded(false)
    } else {
      setCorrespondenceNeeded(true)
    }
  }, [isAddressesEqual, setCorrespondenceNeeded])

  const correspondenceAddressChkBox = useCallback(() => {
    setCorrespondenceNeeded((previous) => !previous)
  }, [correspondenceNeeded])

  useBeforeUnload(
    formState.isDirty,
    t('youHaveUnsavedChangesAreYouSure', 'You have unsaved changes, are you sure?'),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      GetViewerQuery({ fetchPolicy: 'network-only' }).then()
    }, 60 * 1000) // every minute

    const timer = setTimeout(() => {
      clearInterval(interval)
    }, 1000 * 60 * 30) // clear after 30 minutes

    return () => {
      clearInterval(interval)
      clearTimeout(timer)
    }
  }, [])

  const handleNext = useCallback(
    async (formData: PreAssessmentOnboardingInput) => {
      setAllowNavigation(true)
      try {
        const parsedFormValues = parsePreassessementValues(
          formData,
          correspondenceNeeded,
          watch,
          isIntroducer,
        )

        if (applicationId) {
          await updateContractDetailsMutation({
            variables: { id: applicationId, updates: parsedFormValues },
            refetchQueries: [
              {
                query: GetContractDetailsDocument,
                variables: { id: +applicationId },
              },
              {
                query: GetUserContractsDocument,
              },
            ],
            awaitRefetchQueries: true,
          })
          isUnsaveFormDataVar(false)
          if (goBackPath && goBackPath !== APP_PATHS.root) {
            history.push(goBackPath)
            goBackPathVar('')
          } else {
            history.push(
              generatePath(APP_PATHS.application.authorizedPersons.list, {
                [PATH_PARAMS.applicationId]: applicationId,
              }),
            )
          }
        } else {
          const { data } = await createContractMutation({
            variables: { input: parsedFormValues },
            refetchQueries: [
              {
                query: GetUserContractsDocument,
              },
            ],
            awaitRefetchQueries: true,
          })
          const id = data?.createContract?.id
          await refetch({ id })
          isUnsaveFormDataVar(false)
          goBackPathVar(
            generatePath(APP_PATHS.application.edit, {
              [PATH_PARAMS.applicationId]: id,
            }),
          )
          history.push(
            generatePath(APP_PATHS.application.status, {
              [PATH_PARAMS.applicationId]: id,
            }),
          )
        }
      } catch (e) {
        setAllowNavigation(false)
        toast.error(e.message)
      }
    },
    [
      history,
      correspondenceNeeded,
      refetch,
      applicationId,
      updateContractDetailsMutation,
      isIntroducer,
    ],
  )

  useEffect(() => {
    if (applicationId && contractData?.contract?.id) {
      const { address, correspondenceAddress } = contractData?.contract?.owner as LegalEntity

      if (
        address?.line1 !== correspondenceAddress?.line1 ||
        address?.zip !== correspondenceAddress?.zip ||
        address?.city !== correspondenceAddress?.city ||
        address?.additionalDetails !== correspondenceAddress?.additionalDetails
      ) {
        setIsAddressesEqual(false)
      }

      const [parsedFormData] = parsePreAssessementFormData(contractData)
      reset(parsedFormData)
    }

    if (!applicationId && !isEmpty(initialTempValues)) {
      const initValues = createInitValues(initialTempValues)
      reset(initValues)
    }

    return () => {
      isUnsaveFormDataVar(false)
      isModalDialogOpenVar(false)
      isNavMenuRouteVar(false)
      continueRouteVar('')
    }
  }, [contractData, applicationId, isAddressesEqual, initialTempValues, reset])

  useEffect(() => {
    if (formState.isDirty) {
      isUnsaveFormDataVar(true)
    }
  }, [formState.isDirty])

  useEffect(() => {
    if (errors) {
      return scrollToInputElement(errors, 150)
    }
  }, [errors])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    setCurrentStep(RegistrationSteps.preAssessment)
    if (applicationId && !contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit)) {
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      }
      if (contractOwner) {
        setHeaderText(
          t('companyPartnerApplication', {
            companyName: (contractData?.contract?.owner as LegalEntity).companyName,
          }),
        )
      }
      !goBackPath && goBackPathVar(generatePath(APP_PATHS.root, { from: 'dashboard' }))
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [
    applicationId,
    contractData,
    contractLoading,
    goBackPath,
    history,
    setCurrentStep,
    setHeaderText,
    t,
  ])
  return (
    <>
      <NavigationPrompt when={isDirtyForm && !allowNavigation}>
        {({ onCancel, onConfirm }) => (
          <CustomPrompt open={true} onCancel={onCancel} onConfirm={onConfirm} />
        )}
      </NavigationPrompt>
      <FormProvider {...methods}>
        {applicationId && !contractData ? (
          <Loader height={300} />
        ) : (
          <Box m={1}>
            <form
              onSubmit={methods.handleSubmit(handleNext)}
              id="forNextFocus"
              onKeyDown={focusKeyPressNext}
              data-test="preAssessmentForm"
            >
              <GridRow>
                <Box>
                  <Typography variant="h6" className={classes.titleSubBox}>
                    {t(
                      'pleaseFillInThisForm',
                      'Please fill in this form electronically and to the best of your ability. The form must be completed in its entirety.',
                    )}
                  </Typography>
                </Box>
              </GridRow>

              <CompanyInformation />

              <AddressAutocomplete
                showWarn={true}
                addressLabel={t('registeredAddress', 'Registered address')}
                props={registeredAddressEmptyProps}
                hideCountry
              />
              <Box data-test="companyDetails-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!correspondenceNeeded}
                      name="checkCorrespondenceAddress"
                      icon={<Unchecked />}
                      checkedIcon={<Checked />}
                    />
                  }
                  onChange={correspondenceAddressChkBox}
                  label={t(
                    'correspondenceAddressCheckBoxLabel',
                    'Correspondence address is the same as registered',
                  )}
                />
              </Box>
              {correspondenceNeeded && (
                <AddressAutocomplete
                  linkSupportNewTab={true}
                  showWarn={true}
                  addressLabel={t('correspondenceAddressTitle', 'Correspondence address')}
                  correspondence
                  props={correspondenceAddressProps}
                />
              )}

              <UboInformation />

              <Box mt={4}>
                <Typography variant={'h3'} className={classes.title}>
                  {t('contactInformation', 'Contact information')}
                </Typography>
              </Box>
              <GridRow>
                <CountryPhoneSelect
                  name="phoneNumber"
                  label={t('businessPhone', 'Business phone')}
                  data-test="preAssessmentPhone"
                />
              </GridRow>
              <GridRow>
                <FormControlledTextField
                  className={classes.boxInput}
                  label={t(
                    'siteLinkNew',
                    'Website, app store link or social media profile of your business',
                  )}
                  name="urls"
                  type="text"
                  fullWidth
                  multiline
                  required={false}
                  data-test="preAssessmentSite"
                />
              </GridRow>

              <RegulatorInformation />

              <GeneralQuestions />

              <TransactionalQuestions />

              <CardPaymentsQuestions />

              <ContactPerson />

              <Box display="flex">
                <Box className={classes.wrapperButton}>
                  <LoadingButton
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={mutationLoading}
                    data-test="companyDetails-submitAndProceed"
                  >
                    {t('submit', 'Submit')}
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          </Box>
        )}
      </FormProvider>
    </>
  )
}
