import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as Checked } from '../../assets/images/icons/cheked_icon.svg'
import { ReactComponent as BatchIcon } from '../../assets/images/icons/download.svg'

import { ReactComponent as Unchecked } from '../../assets/images/icons/unchecked_icon.svg'
import {
  Account,
  AccountStatus,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  BatchPaymentActionType,
  FundsType,
  GetContractAccountsQuery,
  Maybe,
  SignatoryRight,
  useGetContractAccountsQuery,
  useStartBatchPaymentMutation,
} from '../../graphql'
import { isBatchPaymentFile } from '../../graphql/local'
import { isBatchFileUploaded } from '../../graphql/local/dashboard'
import { useDetermineUserRights } from '../../hooks'
import i18n from '../../i18n'
import { UploadBatchFile } from '../../pages/UploadBatchFile'
import { PATH_PARAMS } from '../../routes/paths'
import { AlertDataType, BatchPaymentInfo } from '../../types'
import { currencyFormat } from '../../utils'
import { batchErrAlert } from '../../utils/BatchErrMsg'
import { AccountsAutocompleteSelect, AlertTipItem, FormTextField } from '../Common'
import { ConfirmSignatureModal } from '../Common/ActionsSign2FAModal'
import BatchPaymentResult from './BatchPaymentResult'
import { PaymentContext } from './PaymentContextComponent'

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    padding: theme.spacing(10, 1, 1, 1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(4),
    },
    '&.MuiGrid-container': {
      display: 'block',
    },
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  uploadBatchFile: {
    width: '100%',
  },
  alert: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  hidden: {
    border: 0,
    clipPath: 'rect(0 0 0 0)',
    height: 1,
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1,
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: '24px 0 32px 0',
    width: 'auto',
    textDecoration: 'none',
    color: 'black',
    '& svg': {
      marginRight: 13,
    },
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  signCheck: {
    margin: '16px 0 32px 3px',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& .MuiButton-root': {
      // minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
          minWidth: 248,
          height: 48,
          fontSize: '18px',
          fontWeight: 'bold',
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(2),
        },
      },
      '&:nth-child(2)': {
        minWidth: 191,
      },
    },
  },
  mobileMargin: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
}))

export const BatchPayment: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const [alertData, setAlertData] = useState<AlertDataType>(batchErrAlert)
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [infoPayment, setInfoPayment] = useState<BatchPaymentInfo | undefined>()
  const [accountHasSufficientFunds, setAccountHasSufficientFunds] = useState<boolean>(false)
  const [isBatchValid, setIsBatchValid] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const methods = useForm()

  const { watch } = methods

  const { data: accountsData } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: [AccountStatus.Active],
    },
  })

  const {
    signNow,
    paymentReady,
    paymentSuccessful,
    actionData,
    batchPaymentResultText,
    setPaymentSuccessful,
    setPaymentReady,
    setSignNow,
    setActionData,
    setBatchPaymentResultText,
    setBatchListPage,
    setTableStatus,
  } = useContext(PaymentContext)

  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isLimitedAccessRight = !!userRights?.limitedAccessRight
  const selectedAccount = watch('account')

  const [startBatchPaymentMutation, { loading }] = useStartBatchPaymentMutation()

  const filteredAccounts = useMemo(() => {
    const accountsWithBalance = accountsData?.accounts?.filter(
      (account) =>
        [AccountStatus.Active, AccountStatus.Monitoring].includes(
          account?.status as AccountStatus,
        ) &&
        (account?.balance?.available || 0) > 0 &&
        account?.fundsType !== FundsType.DepositGuaranteed,
    )
    if (!infoPayment?.calculatedTotal) {
      return { accounts: accountsWithBalance }
    }
    return {
      accounts: accountsWithBalance?.sort((a) => {
        if (a?.currency?.toUpperCase() !== infoPayment?.currency?.toUpperCase()) {
          return 1
        } else if (a?.currency?.toUpperCase() === infoPayment?.currency?.toUpperCase()) {
          return -1
        } else {
          return 0
        }
      }),
    }
  }, [accountsData, infoPayment])

  const accountCurrencyCheck =
    filteredAccounts?.accounts
      ?.find((acc) => acc?.id === watch('account'))
      ?.currency?.toUpperCase() === infoPayment?.currency?.toUpperCase()

  const onClose = useCallback(() => {
    isBatchPaymentFile(false)
    setIsOpenAlert(false)
    setPaymentReady(false)
    setBatchListPage(1)
    history.goBack()
  }, [isBatchPaymentFile, setIsOpenAlert, setPaymentReady, setBatchListPage, history])

  const handleSignNow = useCallback(() => {
    setSignNow((prevState) => !prevState)
  }, [setSignNow])

  const handleClose = useCallback(
    (success?: boolean) => {
      if (success && signNow && userRights?.signatoryRight === SignatoryRight.Sole) {
        setBatchPaymentResultText({
          title: i18n.t('BatchPaymentSubmittedSignatorySigned', 'Batch payment submitted!'),
          text: i18n.t(
            'BatchSignatorySignedText',
            'Your batch file consisting of {{count}} payments (total of {{calculatedTotal}} {{currency}}) has been successfully submitted for execution.',
            {
              count: infoPayment?.count,
              calculatedTotal: currencyFormat(infoPayment?.calculatedTotal ?? 0),
              currency: infoPayment?.currency?.toUpperCase(),
            },
          ),
        })
      }
      if (
        (success && signNow && userRights?.signatoryRight === SignatoryRight.Joint) ||
        (success && signNow && userRights?.signatoryRight === SignatoryRight.Group)
      ) {
        setBatchPaymentResultText({
          title: i18n.t('BatchPaymentSubmittedDualSigned', 'Batch payment submitted!'),
          text: i18n.t(
            'BatchDualSignedText',
            'Your batch file consisting of {{count}} payments (total of {{calculatedTotal}} {{currency}}) has been successfully submitted and will be executed after all Signatories have signed the order.',
            {
              count: infoPayment?.count,
              calculatedTotal: currencyFormat(infoPayment?.calculatedTotal ?? 0),
              currency: infoPayment?.currency?.toUpperCase(),
            },
          ),
        })
      }
      if (signNow && !success && userRights?.signatoryRight === SignatoryRight.Sole) {
        setBatchPaymentResultText({
          title: i18n.t(
            'BatchPaymentSubmittedSignatoryNotSigned',
            'Batch payment submitted for signing!',
          ),
          text: i18n.t(
            'BatchSignatoryNotSignedText',
            'Your batch file consisting of {{count}} payments (total of {{calculatedTotal}} {{currency}}) has been successfully submitted and will be executed after you sign the order.',
            {
              count: infoPayment?.count,
              calculatedTotal: currencyFormat(infoPayment?.calculatedTotal ?? 0),
              currency: infoPayment?.currency?.toUpperCase(),
            },
          ),
        })
      }
      if (
        (signNow && !success && userRights?.signatoryRight === SignatoryRight.Joint) ||
        (signNow && !success && userRights?.signatoryRight === SignatoryRight.Group) ||
        !!userRights?.limitedAccessRight
      ) {
        setBatchPaymentResultText({
          title: i18n.t('BatchPaymentSubmittedLa', 'Batch payment submitted for signing!'),
          text: i18n.t(
            'BatchSubmittedLaText',
            '  Your batch file consisting of {{count}} payments (total of {{calculatedTotal}} {{currency}}) has been successfully submitted and will be executed after all Signatories have signed the order.\n',
            {
              count: infoPayment?.count,
              calculatedTotal: currencyFormat(infoPayment?.calculatedTotal ?? 0),
              currency: infoPayment?.currency?.toUpperCase(),
            },
          ),
        })
      }
      setPaymentSuccessful(true)
      setOpenModal(false)
      !success && setSignNow(false)
    },
    [signNow, userRights, batchPaymentResultText, infoPayment],
  )

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const { data } = await startBatchPaymentMutation({
          variables: {
            accountId: formData.account,
            fileUrl: formData.fileUrl,
          },
          refetchQueries: [
            {
              query: ActionSignatureRequestsCountDocument,
              variables: {
                contractId: applicationId,
                statuses: [ActionSignatureStatus.Pending],
              },
            },
          ],
        })

        if (!isEmpty(data?.startBatchPayment?.executionAction)) {
          const signature =
            (data?.startBatchPayment?.executionAction as BatchPaymentActionType).signatures?.find(
              (actionSignature) => actionSignature?.isMine === true,
            ) ??
            (data?.startBatchPayment?.executionAction as BatchPaymentActionType).signatures?.[0]
          signature && setActionData(signature)
        }

        if (userRights?.signatoryRight === SignatoryRight.Sole && !signNow) {
          setBatchPaymentResultText({
            title: i18n.t(
              'BatchPaymentSubmittedSignatoryNotSigned',
              'Batch payment submitted for signing!',
            ),
            text: i18n.t(
              'BatchSignatoryNotSignedText',
              'Your batch file consisting of {{count}} payments (total of {{calculatedTotal}} {{currency}}) has been successfully submitted and will be executed after you sign the order.',
              {
                count: infoPayment?.count,
                calculatedTotal: currencyFormat(infoPayment?.calculatedTotal ?? 0),
                currency: infoPayment?.currency?.toUpperCase(),
              },
            ),
          })
        }
        if (
          (userRights?.signatoryRight === SignatoryRight.Joint && !signNow) ||
          (userRights?.signatoryRight === SignatoryRight.Group && !signNow) ||
          !!userRights?.limitedAccessRight
        ) {
          setBatchPaymentResultText({
            title: i18n.t('BatchPaymentSubmittedLa', 'Batch payment submitted for signing!'),
            text: i18n.t(
              'BatchSubmittedLaText',
              '  Your batch file consisting of {{count}} payments (total of {{calculatedTotal}} {{currency}}) has been successfully submitted and will be executed after all Signatories have signed the order.\n',
              {
                count: infoPayment?.count,
                calculatedTotal: currencyFormat(infoPayment?.calculatedTotal ?? 0),
                currency: infoPayment?.currency?.toUpperCase(),
              },
            ),
          })
        }

        !signNow && setPaymentSuccessful(true)
        signNow && setOpenModal(true)
      } catch (e) {
        toast.error((e as Error).message)
      } finally {
        isBatchFileUploaded(false)
      }
    },
    [userRights, signNow, batchPaymentResultText, infoPayment],
  )

  const getAccountsWithoutDepositType = (
    accountsData: GetContractAccountsQuery | undefined,
  ): [] | Maybe<Account>[] => {
    const accountsWithoutDeposit = accountsData?.accounts?.filter(
      (account) => account?.fundsType !== FundsType.DepositGuaranteed && !account?.qrrSupported,
    )
    return !accountsWithoutDeposit || isEmpty(accountsWithoutDeposit) ? [] : accountsWithoutDeposit
  }

  useEffect(() => {
    isBatchFileUploaded(paymentReady)
  }, [paymentReady])

  useEffect(() => {
    isLimitedAccessRight && setSignNow(false)
  }, [isLimitedAccessRight, setSignNow])

  useEffect(() => {
    if (paymentReady && infoPayment && selectedAccount) {
      const account = filteredAccounts?.accounts?.find((a) => a?.id === selectedAccount)
      const hasSufficientFunds =
        (account?.balance?.available || 0) >= (infoPayment.calculatedTotal || 0)
      setAccountHasSufficientFunds(hasSufficientFunds)
    }
  }, [infoPayment, selectedAccount, paymentReady])

  useEffect(() => {
    return () => {
      isBatchFileUploaded(false)
      setPaymentReady(false)
      setTableStatus(false)
    }
  }, [])

  return (
    <>
      {paymentSuccessful ? (
        <Grid container className={classes.wrap}>
          <BatchPaymentResult
            batchPaymentResultText={batchPaymentResultText}
            signNow={signNow}
            actionData={actionData}
          />
        </Grid>
      ) : (
        <Grid container className={classes.wrap}>
          <Grid item className={classes.headerModal}>
            <Box className={classes.modalHeader}>
              <Typography variant={'h1'}>{t('newBatchPay', 'New batch payment')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={11} xl={7}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormTextField name="fileUrl" type="text" className={classes.hidden} />
                <Box
                  mt={3}
                  className={
                    paymentReady && !accountHasSufficientFunds ? classes.mobileMargin : undefined
                  }
                >
                  <FormControl className={classes.formControl}>
                    <AccountsAutocompleteSelect
                      data={filteredAccounts?.accounts || []}
                      filterAccs={getAccountsWithoutDepositType}
                      label={t('fromAccount', 'From account')}
                      name="account"
                      isFromPay={true}
                    />
                    {paymentReady && !accountHasSufficientFunds && (
                      <FormHelperText error>
                        {t(
                          'batchPaymentsInsufficientFunds',
                          'Selected account does not have sufficient funds for executing the batch payments',
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box className={classes.uploadBatchFile} hidden={!selectedAccount}>
                  {selectedAccount && (
                    <UploadBatchFile
                      isOpenAlert={isOpenAlert}
                      setIsOpenAlert={setIsOpenAlert}
                      setAlertData={setAlertData}
                      setPaymentReady={setPaymentReady}
                      setInfoPayment={setInfoPayment}
                      setIsBatchValid={setIsBatchValid}
                    />
                  )}
                </Box>
                <Box hidden={paymentReady}>
                  <a
                    href={process.env.REACT_APP_BATCH_EXAMPLE}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <BatchIcon />
                    <Typography variant={'h6'}>
                      {t('downloadTemplate', 'Download {{val1}} template', { val1: 'Klarpay' })}
                    </Typography>
                  </a>
                </Box>

                <Box mt={3} className={`${classes.alert} ${classes.uploadBatchFile}`}>
                  <AlertTipItem
                    value={alertData.text}
                    iconComponent={alertData.icon}
                    type={alertData?.type}
                    isCloseButton={false}
                    isOpenAlert={isOpenAlert}
                    setIsOpenAlert={setIsOpenAlert}
                  />
                </Box>

                <Box hidden={!paymentReady || isLimitedAccessRight} className={classes.signCheck}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="signPayment"
                        checked={signNow}
                        icon={<Unchecked />}
                        checkedIcon={isBatchValid ? <Checked /> : <Unchecked />}
                        disabled={!isBatchValid}
                      />
                    }
                    onChange={handleSignNow}
                    label={t('signPaymentNow', 'Sign this payment now')}
                  />
                </Box>

                <Box className={classes.modalFooter}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={
                      loading ||
                      (filteredAccounts && !accountCurrencyCheck) ||
                      !paymentReady ||
                      !isBatchValid ||
                      !accountHasSufficientFunds
                    }
                  >
                    {t('submitPayments', 'Submit payments')}
                  </Button>
                  <Button variant="contained" onClick={onClose} disableElevation>
                    {t('cancel', 'Cancel')}
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      )}

      {/* 2FA Modal */}
      {!isEmpty(actionData) && signNow && openModal && (
        <ConfirmSignatureModal
          openAction={!isEmpty(actionData) ? 'sign' : undefined}
          handleClose={handleClose}
          actionData={actionData}
          multipleActionsData={[]}
        />
      )}
    </>
  )
}

export default BatchPayment
