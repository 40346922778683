import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/confirmed.svg'
import { InputCodes } from '../../../../components/Common/InputCode/type'
import {
  GetEmailAndTwoFaCategoryPreferencesDocument,
  GetUserDataDocument,
  TwoFaMethod,
  // useChangeIndividualPrimary2FaMethodMutation,
  useFinishSetupTwoFaForIndividualMutation,
  // useGetActionSigningCodeMutation,
} from '../../../../graphql'
// import { useCurrentUser } from '../../../../hooks'
import { AlertDataType, StepsAuth } from '../../../../types'

export const useHandlePrimary2FA = (
  setIsErrorFinish: React.Dispatch<React.SetStateAction<boolean>>,
  setValidMessageFinish: React.Dispatch<React.SetStateAction<string>>,
  setAlertDataFinish: React.Dispatch<React.SetStateAction<AlertDataType | undefined>>,
  setCodeFinish: React.Dispatch<React.SetStateAction<InputCodes>>,
  setOptionMode: React.Dispatch<React.SetStateAction<TwoFaMethod>>,
  setAuthStep: React.Dispatch<React.SetStateAction<StepsAuth>>,
  handleClose: (success: boolean) => void,
) => {
  const [successClose, setSuccessClose] = useState(false)

  const { t } = useTranslation()
  const [finishSetupTwoFaForIndividualMutation] = useFinishSetupTwoFaForIndividualMutation()
  const initialValue = useMemo(() => ({ d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }), [])

  const handlePrimary2FA = async (code: string) => {
    try {
      const { data } = await finishSetupTwoFaForIndividualMutation({
        variables: {
          token: code,
        },
        refetchQueries: [
          {
            query: GetEmailAndTwoFaCategoryPreferencesDocument,
          },
          {
            query: GetUserDataDocument,
          },
        ],
        awaitRefetchQueries: true,
      })

      if (data?.finishSetupTwoFaForIndividual) {
        setIsErrorFinish(false)
        setValidMessageFinish('')
        setAlertDataFinish({
          text: `${t(
            'pleaseUseYourAuthenticatorForLogInAndSign',
            'Please use your Authenticator app for log in and signable actions.',
          )}`,
          textBold: `${t(
            'twoFactorSuccessfullyAlert',
            'Two-factor authentication set up successfully',
          )}`,
          type: 'primary',
          icon: <SuccessIcon />,
        })
        setCodeFinish(initialValue)
        setOptionMode(TwoFaMethod.Totp)
        setAuthStep(StepsAuth.AuthenticatorApp)
      } else {
        setIsErrorFinish(true)
        setValidMessageFinish(t('codeIncorrect', 'Code is incorrect'))
      }
      setSuccessClose(true)
    } catch (e) {
      toast.error((e as Error).message)
    }
  }

  useEffect(() => {
    if (successClose) {
      const timeoutClose = setTimeout(() => {
        setCodeFinish(initialValue)
        handleClose(true)
        setSuccessClose(false)
      }, 2500)
      return () => {
        clearTimeout(timeoutClose)
      }
    }
  }, [handleClose, successClose])

  return handlePrimary2FA
}
