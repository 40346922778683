import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { toNumber } from 'lodash'

import { Box, Hidden, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { useParams } from 'react-router'
import { useDetermineUserRights } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'

import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { TransferProps } from '../TrxTransferPage'
import { UploadDocument } from '../../AplicationActions'
import { SignaturesTable } from '../../AplicationActions/Tabs/helpers'
import { SignatureProps } from '../../AplicationActions/contract'
import { DelayedPaymentAlert } from './DelayedPaymentAlert'
import { currencyFormat, trxStatusNormalize } from '../../../utils'
import { LimitedAccessRight, Transaction } from '../../../graphql'

export const TrxRevertedTab: FC<{
  transfer: TransferProps
  signedCount: number
}> = ({ transfer, signedCount }) => {
  const { txData } = transfer

  const { t } = useTranslation()
  const classes = useStyles()

  const accId = (transfer.rawData as Transaction)?.account?.id as string
  const trxId = transfer.rawData?.id as string

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('transactionStatus', 'Transaction status')}
                </Box>
              </TableCell>
              <TableCell>
                {txData?.status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(txData?.status)}`)}
                  >
                    {t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('referenceNumber', 'Reference number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.referenceNumber}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('amount', 'Amount')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('account', 'Account')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.accountName}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('sender', 'Sender')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.sender}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('received', 'Received')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.received}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('outboundTransaction', 'Outbound transaction')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.outboundTransaction}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200" colSpan={2} className={classes.cell}>
                <Typography variant="h4" className={classes.label}>
                  {t('bankAccountDetails', 'Bank account details')}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('bank', 'Bank')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.bank}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('iban', 'IBAN')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.iban}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('swift', 'SWIFT')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.swift}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {!isViewOnly && <UploadDocument accountId={accId} trxId={trxId} isTransaction={true} />}
        {/*//TODO update Alert after delayed execution appears on back*/}
        <DelayedPaymentAlert />

        <SignaturesTable
          signatures={transfer.signatures as SignatureProps[]}
          signedCount={signedCount}
        />
      </Hidden>
      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
              <Box className={classes.mobileTableName}>
                {txData?.status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(txData?.status)}`)}
                  >
                    {t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('referenceNumber', 'Reference number')}
              </Box>
              <Box className={classes.mobileTableName}>{txData?.referenceNumber}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
              <Box className={classes.mobileTableName}>
                {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('account', 'Account')}</Box>
              <Box className={classes.mobileTableName}>{txData?.accountName}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('sender', 'Sender')}</Box>
              <Box className={classes.mobileTableName}>{txData?.sender}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('received', 'Received')}</Box>
              <Box>{txData?.received}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('outboundTransaction', 'Outbound transaction')}
              </Box>
              <Box>{txData?.outboundTransaction}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
                  {t('bankAccountDetails', 'Bank account details')}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('bank', 'Bank')}</Box>
              <Box>{txData?.bank}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('iban', 'IBAN')}</Box>
              <Box>{txData?.iban}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('swift', 'SWIFT')}</Box>
              <Box>{txData?.swift}</Box>
            </Box>

            {!isViewOnly && <UploadDocument accountId={accId} trxId={trxId} isTransaction={true} />}
            {/*//TODO update Alert after delayed execution appears on back*/}
            <DelayedPaymentAlert />

            <SignaturesTable
              signatures={transfer.signatures as SignatureProps[]}
              signedCount={signedCount}
            />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
