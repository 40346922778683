import React, { FC } from 'react'
import { Box, Button, Hidden, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import {
  LimitedAccessRight,
  Maybe,
  Transaction,
  TransactionStatus,
  TransactionType,
} from '../../../graphql'
import { useDetermineUserRights, useIsLimitedContract } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { trxStatusNormalize } from '../../../utils'
import { useStyles as globalStyles } from '../../AplicationActions/Tabs/useStyles'
import { ReactComponent as IconDot } from '../../../assets/images/icons/Dot.svg'

const useStyles = makeStyles(() => ({
  repeatBtn: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    textDecoration: 'underline',
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      background: '#ffffff',
    },
  },
}))

const TransactionStatusCellComponent: FC<{
  txData: Maybe<Transaction> | undefined
  repeatPayment: (event: React.SyntheticEvent) => void
}> = ({ txData, repeatPayment }) => {
  const { t } = useTranslation()
  const classes = { ...globalStyles(), ...useStyles() }

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  const isLimitedContract = useIsLimitedContract()

  const txStatus = txData?.status
  const isCompleted = txStatus === TransactionStatus.Completed
  const isRepeatable =
    isCompleted && txData?.type === TransactionType.TransferSwift && !isLimitedContract

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gridGap={10}>
      {txStatus && (
        <Box component="span" className={clsx(`roundedPill ${trxStatusNormalize(txStatus)}`)}>
          {t(`${trxStatusNormalize(txStatus)}`)}
        </Box>
      )}
      {!isViewOnly && isRepeatable && (
        <>
          <Hidden xsDown>
            <IconDot />
          </Hidden>
          <Button
            aria-label="repeat"
            type="button"
            onClick={repeatPayment}
            className={classes.repeatBtn}
          >
            {t('repeatPayment', 'Repeat payment')}
          </Button>
        </>
      )}
    </Box>
  )
}

export const TransactionStatusCell = React.memo(TransactionStatusCellComponent, isEqual)
