import React, { FC, useCallback, useState, useContext, useEffect, useRef, MouseEvent } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import {
  FormHelperText,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  FormControl,
  ListItemIcon,
} from '@material-ui/core'
import { ReactComponent as ActivateAccount } from '../../../assets/images/icons/icon-activate-account.svg'
import { ReactComponent as BusinessIcon } from '../../../assets/images/icons/business.svg'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import {
  ActionSignatureStatus,
  BankableEntityContractType,
  ContractStatusType,
  ContractType,
  Individual,
  LegalEntity,
  UboLegalEntityType,
  UboType,
  useActionSignatureRequestsCountLazyQuery,
  useGetUserContractsQuery,
} from '../../../graphql'
import { useMenuStyles } from '../../MainMenu/menuStyles'
import { CurrentUserContext, usePageFiltersSorting } from '../../../hooks'
import { isActiveContract, isLimitedActiveContract, isNotActiveContract } from '../../../utils'
import { AppActions, displayStatusKeys, statusActions } from '../../../constants'
import { dashboardClosedStatuses } from '../../../utils/Data'
import { goBackPathVar, isUnsaveFormData } from '../../../graphql/local'

const useStyles = makeStyles((theme) => ({
  wrapRoot: {
    display: 'flex',
    position: 'relative',
    width: `100%`,
    flexDirection: 'column',
  },
  inLine: {
    display: 'flex',
    alignItems: 'center',
    '&.open': {
      opacity: 0.7,
      '& .MuiFormHelperText-root': {
        color: '#595959',
      },
    },
  },
  popper: {
    zIndex: 2,
    width: '100%',
  },
  paper: {
    marginRight: 0,
    maxHeight: 400,
    maxWidth: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    borderRadius: 0,
    boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
  },
  nameOwner: {
    textAlign: 'left',
    paddingLeft: '5px',
    width: '90%',
    '& .MuiFormHelperText-root': {
      bottom: 0,
      margin: 0,
      position: 'relative',
      paddingLeft: 0,
      color: '#999999',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1.5, 1, 1.5),
    '& .flag-icon-lg': {
      fontSize: '1.5em',
    },
  },
  itemNewApp: {
    borderTop: '1px solid #F5F5F5',
    textDecoration: 'underline',
  },
  btnCompanySelect: {
    fontSize: '1.3em',
    lineHeight: '1.5rem',
    fontWeight: 'normal',
    width: '100%',
    minHeight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000000',
    '&.MuiButton-root.Mui-disabled': {
      color: '#000000',
      pointerEvents: 'all',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: 15,
      right: 0,
    },
    '&:hover': {
      background: 'none',
    },
  },
  expandLessIcon: {
    opacity: '1',
  },
  expandMoreIcon: {
    opacity: '.3',
  },
  iconActivateAccount: {
    opacity: '.2',
    minWidth: theme.spacing(1),
  },
  companyName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    lineHeight: '2rem',
  },
  wordBrakeText: {
    margin: 0,
    wordBreak: 'break-word',
  },
}))

const FinishedContracts: FC<{ isActive: boolean }> | null = ({ isActive }) => {
  const classes = useStyles()
  return isActive ? (
    <ListItemIcon className={classes.iconActivateAccount}>
      <ActivateAccount />
    </ListItemIcon>
  ) : null
}

export const CompanySelect: FC<{
  contractId: number | string | undefined
  name: string
  disabled?: boolean | undefined
}> = ({ contractId, name, disabled }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [owner, setOwner] = useState<string>('')
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [contractsList, setContractsList] = React.useState<BankableEntityContractType[]>([])
  const { resetAll } = usePageFiltersSorting()
  const history = useHistory()
  const classes = { ...useMenuStyles(), ...useStyles() }
  const user = useContext(CurrentUserContext)

  const { resetSearchAndPageValues } = usePageFiltersSorting()

  const { data: contractsData } = useGetUserContractsQuery()
  const [getActionSignatureRequestsCount] = useActionSignatureRequestsCountLazyQuery()

  const onView = useCallback(
    (contract: BankableEntityContractType) => {
      history.push(
        generatePath(APP_PATHS.application.status, {
          [PATH_PARAMS.applicationId]: contract.id,
        }),
      )
    },
    [history],
  )

  const onEdit = useCallback(
    (contract: BankableEntityContractType) => {
      let redirectPath = APP_PATHS.application.review
      if (contract.status === ContractStatusType.Draft) {
        if (
          (contract.owner as LegalEntity).legalEntityType === UboLegalEntityType.Unknown &&
          contract.type !== ContractType.IntroducerReference
        ) {
          redirectPath = APP_PATHS.application.edit
        } else if (
          isEmpty(contract.authorizedPersons) &&
          contract.type !== ContractType.IntroducerReference
        ) {
          redirectPath = APP_PATHS.application.authorizedPersons.add
        } else if (isEmpty((contract.owner as LegalEntity).legalEntityDirectors)) {
          redirectPath = APP_PATHS.application.directors.list
        } else if (isEmpty(contract.ubos)) {
          redirectPath = APP_PATHS.application.beneficialOwners.list
        }
      }
      history.push(generatePath(redirectPath, { [PATH_PARAMS.applicationId]: contract.id }))
    },
    [history],
  )

  const actions: { [key in AppActions]: (contract: BankableEntityContractType) => void } = {
    [AppActions.View]: onView,
    [AppActions.Edit]: onEdit,
    [AppActions.Delete]: () => true,
  }

  const handleChange = useCallback(
    async (contract: BankableEntityContractType) => {
      goBackPathVar('')
      isUnsaveFormData(false)
      const specialContractStatuses = [
        ContractStatusType.FirstLinePending,
        ContractStatusType.AmloPending,
        ContractStatusType.SignaturesPending,
      ]

      if (
        (contract.owner as LegalEntity).legalEntityType === UboLegalEntityType.Unknown &&
        contract.type !== ContractType.IntroducerReference
      ) {
        history.push(
          generatePath(APP_PATHS.application.edit, { [PATH_PARAMS.applicationId]: contract?.id }),
        )
      } else {
        const { data } = await getActionSignatureRequestsCount({
          variables: { contractId: contract.id, statuses: [ActionSignatureStatus.Pending] },
          fetchPolicy: 'network-only',
        })

        const pathToRedirect = !!data?.actionSignatureRequestsCount
          ? APP_PATHS.dashboard.actions
          : contract.type === ContractType.IntroducerReference
          ? APP_PATHS.dashboard.partnerManagementOverview
          : APP_PATHS.dashboard.home

        if (
          (isActiveContract(contract, user as Individual) ||
            isLimitedActiveContract(contract, user as Individual)) &&
          contract.status &&
          !specialContractStatuses.includes(contract.status)
        ) {
          resetSearchAndPageValues()
          history.push(generatePath(pathToRedirect, { [PATH_PARAMS.applicationId]: contract?.id }))
        } else {
          actions[statusActions[contract.status as ContractStatusType][0]](
            contract as BankableEntityContractType,
          )
        }
      }
      localStorage.clear()
      resetAll()
      setOpen(false)
    },
    [history, resetSearchAndPageValues, getActionSignatureRequestsCount],
  )

  const handleClose = useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const handleToggle = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setOpen((prevOpen) => !prevOpen)
    },
    [setOpen],
  )

  useEffect(() => {
    if (!isEmpty(contractsData?.contracts)) {
      const nameOwner = (contractsData?.contracts as BankableEntityContractType[]).find(
        (contract) => {
          // eslint-disable-next-line eqeqeq
          return contractId == contract.id
        },
      )
      setOwner(
        nameOwner?.ownerType === UboType.NaturalPerson
          ? t('personal')
          : (nameOwner?.owner as LegalEntity)?.companyName ?? '',
      )
    }
    return () => {
      setOwner('')
    }
  }, [contractId, contractsData])

  useEffect(() => {
    if (!isEmpty(contractsData?.contracts)) {
      const personalContract = (contractsData?.contracts as BankableEntityContractType[]).filter(
        (contract) =>
          contract?.ownerType === UboType.NaturalPerson
            ? isActiveContract(contract, user as Individual)
            : false,
      )
      const activeContracts = (contractsData?.contracts as BankableEntityContractType[]).filter(
        // eslint-disable-next-line array-callback-return
        (contract) => {
          if (contract?.status && contract?.ownerType !== UboType.NaturalPerson)
            return isActiveContract(contract, user as Individual)
        },
      )
      const notActiveContracts = (contractsData?.contracts as BankableEntityContractType[]).filter(
        // eslint-disable-next-line array-callback-return
        (contract) => {
          if (contract?.status) return isNotActiveContract(contract, user as Individual)
        },
      )
      setContractsList(personalContract.concat(activeContracts.concat(notActiveContracts)))
    }

    return () => {
      setContractsList([])
    }
  }, [contractsData, user?.id])

  const addNewApp = useCallback(() => {
    history.push(APP_PATHS.root, { from: 'dashboard' })
  }, [history])

  return (
    <FormControl>
      <Box className={classes.wrapRoot}>
        <Box className={`${classes.inLine} ${open ? 'open' : ''}`}>
          <BusinessIcon />
          <Button
            disableRipple
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.btnCompanySelect}
            disabled={disabled}
            data-test="contractSelector"
          >
            <Typography component="div" className={classes.nameOwner} data-test="owner">
              {<p className={classes.wordBrakeText}>{owner}</p>}
              <FormHelperText>{name}</FormHelperText>
            </Typography>
            {disabled ? null : open ? (
              <ExpandLessIcon className={classes.expandLessIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandMoreIcon} />
            )}
          </Button>
        </Box>
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          className={classes.popper}
          placement="bottom-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <List id="menu-list-grow">
                    {!isEmpty(contractsList) &&
                      contractsList.map((contract) => {
                        const { id, owner, status, ownerType } = contract
                        const name =
                          ownerType === UboType.NaturalPerson
                            ? t('personal')
                            : (owner as LegalEntity).companyName
                        return (
                          <ListItem
                            key={id}
                            onClick={() => handleChange(contract)}
                            button
                            className={classes.item}
                            disabled={dashboardClosedStatuses.includes(
                              contract.status as ContractStatusType,
                            )}
                          >
                            <Typography className={classes.companyName}>{name}</Typography>
                            {!isActiveContract(contract, user as Individual) ? (
                              <Box
                                component="span"
                                className={clsx(
                                  `roundedPill ${displayStatusKeys[status as string]}`,
                                )}
                              >
                                {t(displayStatusKeys[status as string])}
                              </Box>
                            ) : (
                              <FinishedContracts isActive={Number(contractId) === id} />
                            )}
                          </ListItem>
                        )
                      })}
                    {!(user?.isIntroducer && (contractsData?.contracts?.length ?? 0) > 0) && (
                      <ListItem
                        onClick={addNewApp}
                        button
                        className={clsx(classes.item, classes.itemNewApp)}
                      >
                        <ListItemText data-test="addNewApp">
                          {t('addNewApplication', 'Add new application')}
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </FormControl>
  )
}
