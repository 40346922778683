import React, { FC, PropsWithChildren } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Box, SvgIcon } from '@material-ui/core'

import { joinPaths } from '../../utils'
import { CONTACT_SUPPORT_PATH, ROOT_PATHS } from '../../routes/paths'
import { useTableSort } from '../../hooks/useCurrentTableSort'

const useStyles = makeStyles((theme) => ({
  menuLink: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.5,
    paddingLeft: 0,
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'contents',
    },
  },
  smallScreen: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  plainLink: {
    color: '#000',
    cursor: 'pointer',
  },
  pointInline: {
    display: 'inline',
    position: 'relative',
    '&::after': {
      content: '"."',
    },
  },
}))

export const ContactSupportLink: FC<
  PropsWithChildren<{
    menuLink?: boolean
    homeToLink?: boolean
    pointInline?: boolean
    footerDisplay?: boolean
    newTab?: boolean
  }>
> = ({
  menuLink = true,
  homeToLink,
  children,
  pointInline = false,
  footerDisplay = false,
  newTab = false,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { clearTablesSort } = useTableSort()

  const { pathname } = useLocation()

  const isContractSupportPage = pathname === ROOT_PATHS.contactSupport

  return (
    <Box hidden={isContractSupportPage}>
      <Link
        to={
          homeToLink || newTab
            ? joinPaths(ROOT_PATHS.contactSupport)
            : joinPaths(
                `${history.location.pathname.split('/').slice(0, 3).join('/')}`,
                CONTACT_SUPPORT_PATH,
              )
        }
        onClick={clearTablesSort}
        className={`${menuLink ? classes.menuLink : classes.plainLink} ${
          pointInline ? classes.pointInline : ''
        } ${footerDisplay ? classes.smallScreen : ''}`}
        data-test="supLink"
        target={newTab ? '_blank' : ''}
      >
        {children ? (
          children
        ) : (
          <>
            {menuLink && (
              <SvgIcon>
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM17.46 7.12L14.68 8.27C14.17 6.91 13.1 5.83 11.73 5.33L12.88 2.55C14.98 3.35 16.65 5.02 17.46 7.12ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13ZM7.13 2.54L8.3 5.32C6.92 5.82 5.83 6.91 5.32 8.29L2.54 7.13C3.35 5.02 5.02 3.35 7.13 2.54ZM2.54 12.87L5.32 11.72C5.83 13.1 6.91 14.18 8.29 14.68L7.12 17.46C5.02 16.65 3.35 14.98 2.54 12.87ZM12.88 17.46L11.73 14.68C13.1 14.17 14.18 13.09 14.68 11.71L17.46 12.88C16.65 14.98 14.98 16.65 12.88 17.46Z"
                  fill="black"
                />
              </SvgIcon>
            )}
            <Trans i18nKey="contactSupportSmall" defaults="Contact support" />
          </>
        )}
      </Link>
    </Box>
  )
}
