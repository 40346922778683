import { Rxios } from 'rxios'
import config from '../config'

export const rxiosAuth = new Rxios({
  baseURL: `${config.apiUrl}/api` || 'http://localhost:8000/api',
  headers: {
    'Access-Control-Allow-Credentials': true,
    // 'access-control-request-headers': 'Access-Control-Allow-Credentials',
  },
  withCredentials: true,
})
export const rxios = new Rxios({
  baseURL: `${config.apiUrl}/api` || 'http://localhost:8000/api',
  // withCredentials: true,
})

export const rxiosLogin = new Rxios({
  baseURL: `${config.apiUrl}/auth` || 'http://localhost:8000/auth',
  withCredentials: true,
})
