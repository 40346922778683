import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { AccountAction, Action } from '../../graphql'
import { AccountTab } from './Tabs/AccountTab'
import { GoBackButton } from '../../components'
import { AccountProps, getSignatures } from './contract'
import { SignatureAlert } from './Tabs/helpers'
import { useDetailsStyles } from './useDetailsStyles'
import { DetailsFooter } from './DetailsFooter'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { VbanTab } from './Tabs/VbanTab'

export const AccountDetailsPage: FC<{
  title: string
  signedCount: number
  actionDetails: Action
  handleBack: () => void
}> = ({ title, actionDetails, signedCount, handleBack }) => {
  const { t } = useTranslation()
  const classes = useDetailsStyles()

  const accountProps: AccountProps = {
    rawData: actionDetails,
    iban: (actionDetails as AccountAction).account?.iban || 'N/A',
    currency: (actionDetails as AccountAction).account?.currency || undefined,
    reason: actionDetails?.rejectionReason?.toString(),
    signatures: getSignatures(actionDetails?.signatures),
    name: (actionDetails as AccountAction).account?.name,
    dateOfBirth: (actionDetails as AccountAction).account?.isVirtual
      ? format(
          new Date((actionDetails as AccountAction).account?.virtualDetails?.dateOfBirth),
          'MM.dd.yyyy',
        )
      : undefined,
    registeredAddress: (actionDetails as AccountAction).account?.virtualDetails?.registeredAddress,
    nationality: (actionDetails as AccountAction).account?.virtualDetails?.nationality,
    isVirtual: (actionDetails as AccountAction).account?.isVirtual,
    contractSignatoriesCount: actionDetails.contract?.signatoriesCount as number,
  }

  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12} md={10}>
        <Grid item container className={classes.head}>
          <GoBackButton handleBack={handleBack} />
          {accountProps.isVirtual ? (
            <Typography variant="h2" className={classes.title}>
              {t('newVbanСreation', 'New VBAN creation')}
            </Typography>
          ) : (
            <Typography variant="h2" className={classes.title}>
              {accountProps?.currency ? accountProps?.currency : ''} {title.toLowerCase()}
            </Typography>
          )}
        </Grid>

        <SignatureAlert signatures={accountProps.signatures} />
        {accountProps.isVirtual ? (
          <VbanTab data={accountProps} signedCount={signedCount} />
        ) : (
          <AccountTab data={accountProps} signedCount={signedCount} />
        )}
        <DetailsFooter actionSignatureProps={accountProps} handleBack={handleBack} />
      </Grid>
    </Grid>
  )
}

export default AccountDetailsPage
