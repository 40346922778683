import * as yup from 'yup'

import i18n from '../../i18n'

export const RepeatPasswordInputSchema = yup.object().shape({
  repeat_password: yup
    .string()
    .oneOf(
      [yup.ref('password')],
      i18n.t('passwordsDontMatch', 'The passwords entered do not match'),
    )
    .required(i18n.t('repeatPasswordIsMandatory', 'Repeat password is mandatory')),
})

export type RepeatPasswordInput = yup.Asserts<typeof RepeatPasswordInputSchema>
