import { useReactiveVar } from '@apollo/client'
import { useCallback, useContext } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { clickBatchPayment, isDirtyForm, isNavMenuRoute } from '../../../graphql/local'
import { isBatchFileUploaded, validPaymentsCount } from '../../../graphql/local/dashboard'
import { useTableSort } from '../../../hooks/useCurrentTableSort'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { PaymentContext } from '../../BatchPayment'

export const useHandleNewPayments = (isActiveAccounts: boolean) => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const history = useHistory()
  const { clearTablesSort } = useTableSort()

  const isFileUploaded = useReactiveVar(isBatchFileUploaded)
  const isDirtyField = useReactiveVar(isDirtyForm)

  const { resetBatchComponent, setIsNeedToReset } = useContext(PaymentContext)

  const handleNewPayments = useCallback(() => {
    isNavMenuRoute(true)
    clearTablesSort()
    setIsNeedToReset(true)
    applicationId && history.push(generatePath(APP_PATHS.dashboard.newPayment, { applicationId }))
  }, [clearTablesSort, history, applicationId, setIsNeedToReset])

  const handleBatchPayment = useCallback(() => {
    if (isFileUploaded || !isActiveAccounts) return

    if (isDirtyField) {
      clickBatchPayment(true)
    }

    validPaymentsCount(0)
    if (!history.location.pathname.includes('batch-payment')) {
      clearTablesSort()
      isNavMenuRoute(true)
      history.push(generatePath(APP_PATHS.dashboard.batchPayment, { applicationId }))
    } else {
      resetBatchComponent()
    }
  }, [isDirtyField, history, isFileUploaded, isActiveAccounts, applicationId])

  return [handleNewPayments, handleBatchPayment]
}
