import React, { FC, useCallback, useState } from 'react'
import { Button, makeStyles, Grid, Modal, Typography, Fab } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
    marginBottom: '15px',
    padding: '0 10px 0 10px',

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0, 3, 0),
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
    },
    '& .MuiBox-root ': {
      marginTop: 0,
    },
    '& .MuiGrid-root ': {
      padding: 0,
    },
  },
  modalStyle: {
    '& [tabindex]': {
      outline: 'none',
    },
  },
  modal: {
    maxWidth: 512,
    padding: 24,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      // maxWidth: 'calc(328px - 32px)',
      maxWidth: '95%',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: theme.spacing(3, 3, 2, 3),
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  btnControl: {
    padding: theme.spacing(1.25, 2.5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  container: {
    display: 'block',
    padding: '16px 0 24px 0',
  },
}))

export const NewCardholderInfoModal: FC<{
  isOpen: boolean
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isOpen, setIsOpenModal }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const handleClose = useCallback(() => {
    setIsOpenModal(false)
  }, [setIsOpenModal])

  const onProceed = useCallback(() => {
    history.push(
      generatePath(APP_PATHS.dashboard.contractManagement, {
        [PATH_PARAMS.applicationId]: applicationId,
        [PATH_PARAMS.contractManagementTab]: 2,
      }),
    )
  }, [applicationId, history])

  return (
    <Grid item xs={12} className={classes.wrap}>
      <Grid item>
        <Modal
          open={isOpen}
          onClose={handleClose}
          className={classes.modalStyle}
          aria-labelledby="account-modal-title"
          aria-describedby="account-modal-description"
        >
          <Grid container item style={modalStyle} className={classes.modal}>
            <Grid item className={classes.headerModal}>
              <Typography variant={'h5'}>{t('addNewCardholder', 'Add new cardholder')}</Typography>
              <Fab
                color="default"
                aria-label="close"
                className={classes.btnCloseModal}
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </Grid>

            <Grid item xs={12} className={classes.container}>
              <Typography>
                {t(
                  'redirectToTeamMsg',
                  'You will be redirected to the Team section where you should invite a new Authorised person. Please keep in mind that a card can be issued for a person with a verified phone number only.',
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container item spacing={3} justifyContent="flex-end">
                <Grid item xs={12} sm="auto">
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    disableElevation
                    className={classes.btnControl}
                  >
                    {t('cancel', 'Cancel')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.btnControl}
                    disableElevation
                    onClick={onProceed}
                  >
                    {t('proceedTeam', 'Proceed to Team')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  )
}

export default NewCardholderInfoModal
