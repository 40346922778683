import React, { FC, useCallback, useState } from 'react'
import { Button, Grid, List, ListItem, Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { AdressTab } from './Tabs/AdressTab'
import { GoBackButton } from '../../components'
import { AuthoriseModal } from '../../components'
import ListItemIcon from '@material-ui/core/ListItemIcon'

const useStyles = makeStyles((theme) => ({
  wrapp: {
    overflow: 'hidden',
    padding: theme.spacing(10, 1, 1, 1),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  btn: {
    minWidth: 160,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  control: {
    marginTop: theme.spacing(2.5),
  },
  innerControl: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flex: '0 0 100%',
    },
  },
  lists: {
    margin: theme.spacing(1.5, 0),
    '& .MuiListItem-root': {
      paddingLeft: 0,
      paddingBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      marginRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      width: 5,
      height: 5,
      fill: '#000',
    },
  },
  list: {
    textDecoration: 'underline',
  },
}))

const data = {
  streetAddress_old: 'Agias Paraskevis 44',
  streetAddress_new: 'Agios Konstantinos 38',
  postal_code_old: 4404,
  postal_code_new: 4607,
  city_old: 'Germasogeia',
  city_new: 'Pissouri',
  signatures: {
    lindaMason: {
      status: 'signed',
      actionTaken: '23.02.2021 at 10:33 AM',
    },
    maxMustermann: {
      status: 'signed',
      actionTaken: '23.02.2021 at 11:33 AM',
    },
    ricardoDeLaCruz: {
      status: 'rejected',
      actionTaken: '23.02.2021 at 09:17 AM',
    },
  },
}

export const AdressPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [challengeId] = useState('')

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <Grid container className={classes.wrapp}>
      <Grid item xs={12} md={10}>
        <Grid item container className={classes.head}>
          <GoBackButton />
          <Typography variant="h2" className={classes.title}>
            {t('addressChangeTitle', 'Address change')}
          </Typography>
        </Grid>

        <AdressTab data={data} />

        <Grid item container spacing={3} className={classes.control}>
          <Grid item className={classes.innerControl}>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              disableElevation
              color="default"
              className={classes.btn}
            >
              {t('close', 'Close')}
            </Button>
          </Grid>
        </Grid>

        <AuthoriseModal
          open={open}
          handleClose={handleClose}
          id={challengeId}
          title={t('confirmSignature', 'Confirm signature')}
        >
          <Box mt={1.5}>
            <Typography>{t('aboutFollowingDocuments')}</Typography>
            <Typography>
              <List dense={true} className={classes.lists}>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography className={classes.list}>
                    {t('newBusinessApplication', 'New business application')}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography className={classes.list}>{t('someOtherDocument')}</Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography className={classes.list}>{t('someOtherDocument')} 2</Typography>
                </ListItem>
              </List>
            </Typography>
          </Box>
        </AuthoriseModal>
      </Grid>
    </Grid>
  )
}

export default AdressPage
