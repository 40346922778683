import React, { FC, useCallback, useEffect, useState } from 'react'
import { find, isEmpty } from 'lodash'
import { Grid, Box, Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation, useHistory, generatePath } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useReactiveVar } from '@apollo/client'

import { goBackPathVar, goNextPathVar, isCPChangedAfterReviewVar } from '../../graphql/local'
import {
  LegalEntity,
  useGetContractDetailsQuery,
  useSubmitContractForApprovalMutation,
  GetUserContractsDocument,
  ContractStatusType,
  useGetAuthorizedPersonsDataQuery,
  useGetUbosListQuery,
  ContractUbo,
  UboLegalEntityType,
  ContractType,
} from '../../graphql'
import {
  Alerts,
  BeneficialOwnersTabs,
  CompanyDetailsTab,
  DirectorsTab,
  PersonsAccessTab,
} from './Tabs'
import { CommonTipItem, Loader } from '../Common'
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info2.svg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import {
  StepperTypes,
  RegistrationSteps,
  AppActions,
  statusActions,
  BUSINESS_APP_STATUS_KEY,
} from '../../constants'
import { useStepper } from '../../hooks'
import { ActionButtonType, CPAlerts, LegalList, ShowAlertType } from '../../types'
import { processLegalsList } from './ReviewOwners'
import { validateCP } from '../../utils'
import { ControllingPersonsConfirmModalReview } from './ControllingPersonsConfirmModalReview'
import { uboLegalEntityTypesList } from '../../utils/Data'

const useStyles = makeStyles((theme: Theme) => ({
  stepButton: {
    width: 264,
    height: 48,
    margin: theme.spacing(3, 0, 3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(4, 0, 4),
    },
  },
  info: {
    marginBottom: 40,
    border: '1px solid #FFD8B5',
    fontSize: 14,
    '&>div': {
      padding: '5px 10px',
    },
  },
}))

export const Review: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const isCPChangedAfterReview = useReactiveVar(isCPChangedAfterReviewVar)
  const [canComplete, setCanComplete] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [alertData, setAlertData] = useState<ShowAlertType[]>([])
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })
  const { data: personsData, loading: personsLoading } = useGetAuthorizedPersonsDataQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const { data: ubosData, loading: ubosLoading } = useGetUbosListQuery({
    variables: {
      id: +applicationId,
    },
    skip: !applicationId,
  })

  const { setHeaderText, setCurrentStep } = useStepper(
    contractData?.contract?.type === ContractType.IntroducerReference
      ? StepperTypes.partnerRegistration
      : StepperTypes.registration,
  )
  const [legalsList, setLegalsList] = useState<Array<LegalList>>([])

  useEffect(() => {
    goBackPathVar(location.pathname)
    canComplete && goNextPathVar(location.pathname)
    setCurrentStep(RegistrationSteps.review)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit)) {
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      } else if (
        contractOwner.legalEntityType === UboLegalEntityType.Unknown &&
        contractData?.contract?.type !== ContractType.IntroducerReference
      ) {
        history.push(generatePath(APP_PATHS.application.edit, { applicationId }))
      }
      if (contractOwner) {
        setHeaderText(
          t(
            contractData?.contract?.type === ContractType.IntroducerReference
              ? 'companyPartnerApplication'
              : 'companyBusinessApplication',
            {
              companyName: contractOwner.companyName,
            },
          ),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [
    applicationId,
    contractData,
    contractLoading,
    history,
    location.pathname,
    setCurrentStep,
    setHeaderText,
    canComplete,
    t,
  ])

  useEffect(() => {
    if (!personsLoading && !ubosLoading) {
      const entityWithout25Percents = find(legalsList, (o) => (o?.ownersTotalShareSize ?? 0) < 25)
      let domiciliaryCanComplete = true

      const isDomiciliary =
        contractData?.contract?.owner.__typename === 'Individual'
          ? false
          : (contractData?.contract?.owner as LegalEntity)?.legalEntityType ===
            uboLegalEntityTypesList[2].key

      if (isDomiciliary) {
        const domiciliaryShareSize = contractData?.contract?.ubos?.reduce(
          (result: number, value) => {
            return (result + (value?.shareSize ?? 0)) as number
          },
          0,
        )
        domiciliaryCanComplete = domiciliaryShareSize === 100
      }

      const apsRequired = contractData?.contract?.type !== ContractType.IntroducerReference

      setCanComplete(
        ((apsRequired && !isEmpty(personsData?.contract?.authorizedPersons)) ||
          isEmpty(personsData?.contract?.authorizedPersons)) &&
          !isEmpty(ubosData?.contract?.ubos) &&
          isEmpty(entityWithout25Percents) &&
          domiciliaryCanComplete,
      )
    }
  }, [personsLoading, ubosLoading, personsData, ubosData, legalsList, contractData])

  useEffect(() => {
    if (!ubosLoading) {
      processLegalsList(
        ubosData?.contract?.owner as LegalEntity,
        ubosData?.contract?.ubos as ContractUbo[],
        setLegalsList,
      )
    }
  }, [ubosLoading, ubosData])

  const [
    submitContractForApproval,
    // { loading: submitContractForApprovalLoading },
  ] = useSubmitContractForApprovalMutation()

  const onComplete = useCallback(async () => {
    const [alerts, canProcess] = validateCP(legalsList, ActionButtonType.onProceed)
    if (
      isCPChangedAfterReview &&
      !canProcess &&
      alerts.find((alert) => alert.key === CPAlerts.OperatingCompanyPercents)
    ) {
      setOpenModal(true)
    } else if (!canProcess && !isEmpty(alerts)) {
      setAlertData(alerts)
    } else {
      await submitContractForApproval({
        variables: { id: applicationId },
        refetchQueries: [
          {
            query: GetUserContractsDocument,
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(() => {
          localStorage.removeItem(BUSINESS_APP_STATUS_KEY(applicationId))
          history.push(generatePath(APP_PATHS.application.status, { applicationId }))
          isCPChangedAfterReviewVar(false)
        })
        .catch((e) => {
          toast.error((e as Error).message)
        })
    }
  }, [
    applicationId,
    history,
    submitContractForApproval,
    legalsList,
    setOpenModal,
    isCPChangedAfterReview,
    setAlertData,
  ])

  if (contractLoading || !contractData?.contract?.owner) return <Loader height={100} />

  return (
    <Box m={1} data-test="reviewTab">
      <Grid container>
        <Grid item xs={12} lg={11}>
          <Box className={classes.info}>
            <CommonTipItem
              value={t(
                'reviewApplicationDetails',
                'Please review your application details, ensure everything is correct, and then complete the application. After submission, you will not be able to edit your application.',
              )}
              iconComponent={<IconInfo />}
            />
          </Box>

          {contractLoading ? (
            <Loader height={100} />
          ) : (
            <>
              <Box>
                {contractData && contractData.contract && (
                  <CompanyDetailsTab
                    applicationId={applicationId}
                    data={contractData.contract.owner as LegalEntity}
                  />
                )}
              </Box>

              {contractData?.contract?.type !== ContractType.IntroducerReference && (
                <Box mt={6}>
                  <PersonsAccessTab />
                </Box>
              )}

              <Box mt={6}>
                <DirectorsTab
                  contractId={+applicationId}
                  directors={[]}
                  companyName={
                    (contractData?.contract?.owner as LegalEntity)?.companyName as string
                  }
                />
              </Box>

              <Box mt={6}>
                <BeneficialOwnersTabs />
              </Box>

              <Box mt={6}>
                <Alerts alertData={alertData} />
              </Box>

              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onComplete}
                  className={classes.stepButton}
                  disableElevation
                >
                  {t('completeApplication', 'Complete application')}
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <ControllingPersonsConfirmModalReview isOpen={openModal} setOpen={setOpenModal} />
    </Box>
  )
}

export default Review
