import * as yup from 'yup'
import {
  LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD,
  OPTIONAL_FIELD_MESSAGE,
} from '../../constants/validations'
import { isEmpty } from 'lodash'

export const AdditionalDetailsOptionalInputSchema = yup.object().shape({
  additionalDetailsOptional: yup
    .string()
    .nullable()
    .test(
      'additionalDetails',
      OPTIONAL_FIELD_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD),
    ),
})

export type AdditionalDetailsOptionalInput = yup.Asserts<
  typeof AdditionalDetailsOptionalInputSchema
>
