import { yupResolver } from '@hookform/resolvers/yup'

import {
  Box,
  Button,
  Collapse,
  Fab,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { forEach, includes, isEmpty, orderBy } from 'lodash'
import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as AttentionIcon } from '../../assets/images/icons/icon-info2.svg'
import {
  AlertTipItem,
  ConfirmationModal,
  FormAutocompleteSelectDocs,
  FormControlledTextField,
  isModalDialogOpen as isModalDialogOpenVar,
  isModalPromptOpen,
} from '../../components'
import { ProofOfIdentity } from '../../components/NewPerson/Forms/ProofOfIdentity'
import {
  Account,
  AccountStatus,
  AuthorizedPersonStatuses,
  ContractAuthorizedPersonType,
  CreateOwnDocumentRelatedMutationVariables,
  CreditTransaction,
  DebitTransaction,
  Document,
  DocumentRelatedEntityType,
  DocumentType,
  DocumentUserFilter,
  GetGetUploadedDocsCountDocument,
  GetTransactionDocumentsDocument,
  GetTransactionsQueryVariables,
  GetUploadedDocsDocument,
  LegalEntity,
  Maybe,
  OrderBy,
  OrderDirection,
  SwiftTransferRecipientType,
  Transaction,
  TransactionStatus,
  useCreateOwnDocumentRelatedMutation,
  useGetAuthorizedPersonsQuery,
  useGetContractAccountsQuery,
  useGetContractDetailsQuery,
  useGetTransactionDocumentsLazyQuery,
  useGetTransactionsLazyQuery,
} from '../../graphql'
import { useDetermineUserRights } from '../../hooks'
import { PATH_PARAMS } from '../../routes/paths'
import { UploadTransactionDocumentSchema } from '../../schemes/UploadTransactionDocumentSchema'

import { DocumentUploadRelated, FilesForSavingAndShowType } from '../../types'
import { currencyFormat, getCurrencySign, sortTransactions } from '../../utils'
import {
  DebitTransactionDocumentOptions,
  emptyKeyInListOfValues,
  TransactionDocTypesMandatory,
  TransactionDocumentListBusiness,
  TransactionDocumentListPerson,
} from '../../utils/Data'
import { DocsUploadModalTabs } from './DocsUploadModalTabs'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      margin: 0,
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: theme.spacing(3, 3, 2, 3),
    borderBottom: '1px solid #ccc',
  },
  bodyModal: {
    // padding: theme.spacing(0, 3),
    // margin: theme.spacing(3, 0),
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'auto',
  },
  footerModal: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  footerBtn: {
    width: '100%',
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  formGroup: {
    width: '100%',
    position: 'relative',
    marginBottom: theme.spacing(5),
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  alertItem: {
    marginTop: theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
  hidden: {
    display: 'none',
  },
}))

type DocsUploadModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSupportingDocuments: React.Dispatch<React.SetStateAction<Document[] | undefined>>
  setPaymentReviewDocs?: React.Dispatch<
    React.SetStateAction<CreateOwnDocumentRelatedMutationVariables[] | undefined>
  >
  paymentReviewDocs?: CreateOwnDocumentRelatedMutationVariables[] | undefined
  setFilesForSaving?: React.Dispatch<SetStateAction<FilesForSavingAndShowType[] | undefined>>
  filesForSaving?: FilesForSavingAndShowType[] | undefined
  relatedIndividualIncluded?: boolean
  accountId?: string | number | undefined
  trxId?: string | number | undefined
  recipientType?: Maybe<SwiftTransferRecipientType> | undefined
  paymentReviewTrx?: string
  isPaymentAboveCertainAmount?: boolean
  refetchDataCallback?: () => void
}

export const DocsUploadModal: FC<DocsUploadModalProps> = ({
  open,
  setOpen,
  setSupportingDocuments,
  setPaymentReviewDocs,
  paymentReviewDocs,
  setFilesForSaving,
  filesForSaving,
  relatedIndividualIncluded = false,
  accountId,
  trxId,
  recipientType,
  paymentReviewTrx,
  isPaymentAboveCertainAmount,
  refetchDataCallback,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isPersonalContract = userRights?.isIndividualContractOwner

  const location = useLocation()
  const isContractManagePage = includes(location.pathname, 'contract-management')

  const defaultVal = {
    accountValue: undefined,
    documentTypeTransaction: undefined,
    individualValue: undefined,
    transactionValue: undefined,
    documentName: '',
    transactionDocumentRequired: true,
    individualRequired: relatedIndividualIncluded,
    filesForUpload: [],
  }

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UploadTransactionDocumentSchema),
    defaultValues: defaultVal,
  })

  const {
    watch,
    clearErrors,
    register,
    getValues,
    setValue,
    reset,
    formState,
    handleSubmit,
    trigger,
    errors,
  } = methods

  const isErrorInDocName = 'documentName' in errors

  const accountValue = watch(`accountValue`)
  const transactionValue = watch(`transactionValue`)
  const individualValue = watch(`individualValue`)
  const filesForUpload = watch(`filesForUpload`)
  const documentTypeTransaction = watch(`documentTypeTransaction`)
  const documentName = watch('documentName')

  const [isDisablePaymentSelect, setIsDisablePaymentSelect] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<DocumentUploadRelated>(
    DocumentUploadRelated.companyRelated,
  )

  const isFormCompleted: boolean = useMemo(
    () =>
      currentTab === DocumentUploadRelated.companyRelated
        ? Boolean(accountValue && transactionValue && documentName && !isEmpty(filesForUpload))
        : Boolean(
            accountValue &&
              transactionValue &&
              documentName &&
              !isEmpty(filesForUpload) &&
              individualValue,
          ),
    [accountValue, filesForUpload, individualValue, documentName, currentTab, transactionValue],
  )

  const [transactionsSelectData, setTransactionsSelectData] = useState<
    { key: string; label: Maybe<string> | undefined; verified: boolean }[]
  >([])

  const [createOwnDocumentRelated] = useCreateOwnDocumentRelatedMutation({})
  const { data: authorizedPersonsData } = useGetAuthorizedPersonsQuery({
    variables: {
      id: applicationId,
    },
  })
  const { data: accountsData } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: [AccountStatus.Active],
    },
  })
  const { data: contractData } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
  })

  const [GetTransactionsQuery, { data: transactionsData }] = useGetTransactionsLazyQuery()
  const [
    GetTransactionDocumentsQuery,
    { data: documentsData },
  ] = useGetTransactionDocumentsLazyQuery()

  const accountsSelectData: {
    key: string
    label: Maybe<string> | undefined
    verified: boolean
  }[] = [
    {
      key: `#0`,
      label: `${t('noRelatedAccount', 'No related account')}`,
      verified: true,
    },
  ]

  if (!isEmpty(accountsData?.accounts)) {
    const orderByAccounts = orderBy(
      accountsData?.accounts,
      ['balance.available'],
      ['desc'],
    ) as Account[]

    orderByAccounts.forEach((account) => {
      accountsSelectData.push({
        key: `${account?.id}`,
        label: `${account?.alias} ${getCurrencySign(account?.currency as string)}${currencyFormat(
          Math.abs(account?.balance?.available as number),
        )}`,
        verified: true,
      })
    })
  }

  const handleHelpReset = () => {
    setOpen(false)
    reset(defaultVal)
  }

  const handleClose = useCallback(() => {
    if (formState.isDirty) {
      isModalPromptOpen(false)
      isModalDialogOpenVar(true)
      return
    }
    handleHelpReset()
  }, [formState.isDirty])

  useEffect(() => {
    if (getValues(`accountValue`) !== undefined && !accountId) {
      setValue(`accountValue`, undefined)
    }
    if (getValues(`transactionValue`) !== undefined && !trxId) {
      setValue(`transactionValue`, ``)
    }
    if (getValues(`individualValue`) !== undefined) {
      setValue(`individualValue`, ``)
    }

    if (getValues(`documentTypeTransaction`) !== undefined) {
      setValue(`documentTypeTransaction`, ``)
    }
    if (getValues(`documentName`) !== undefined) {
      setValue(`documentName`, ``)
    }
    if (getValues(`transactionDocumentRequired`) !== undefined) {
      setValue(`transactionDocumentRequired`, true)
    }
  }, [accountId, getValues, open, setValue, trxId])

  useEffect(() => {
    if ((accountId || accountValue) && accountValue !== emptyKeyInListOfValues) {
      if (!setPaymentReviewDocs && accountValue) {
        const queryParams: GetTransactionsQueryVariables = {
          contractId: applicationId,
          accountId: accountId ? accountId : (accountValue as Maybe<string>),
        }
        if (!!trxId) {
          queryParams.ids = [trxId]
        }

        ;(async () => {
          await GetTransactionsQuery({
            variables: queryParams,
            fetchPolicy: 'network-only',
          })
        })()
        setIsDisablePaymentSelect(false)
      } else if (!!paymentReviewTrx) {
        const transactionsTemp: {
          key: string
          label: Maybe<string> | undefined
          verified: boolean
        }[] = []
        transactionsTemp.push({
          key: `#0`,
          label: `${paymentReviewTrx}`,
          verified: true,
        })
        setTransactionsSelectData(transactionsTemp)
      }
      !isPaymentAboveCertainAmount && setValue('transactionValue', undefined)
    } else {
      setIsDisablePaymentSelect(true)
      setTransactionsSelectData([])
      setValue(`transactionValue`, emptyKeyInListOfValues)
    }
  }, [
    GetTransactionsQuery,
    accountId,
    accountValue,
    applicationId,
    paymentReviewTrx,
    setPaymentReviewDocs,
    setValue,
    trxId,
    setTransactionsSelectData,
    setIsDisablePaymentSelect,
  ])

  useEffect(() => {
    if (transactionsData && accountValue !== '#0') {
      const dataTransactions = sortTransactions(
        transactionsData?.contractTransactions as Transaction[],
      )
      const transactionsTemp: {
        key: string
        label: Maybe<string> | undefined
        verified: boolean
      }[] = []
      transactionsTemp.push({
        key: `#0`,
        label: `${t('noRelatedPayment', 'No related payment')}`,
        verified: true,
      })

      if (!isEmpty(dataTransactions)) {
        dataTransactions?.forEach((transaction) => {
          transactionsTemp.push({
            key: `${transaction?.id}`,
            label: `#${transaction?.id} ${currencyFormat(Math.abs(Number(transaction?.value)))} ${
              transaction?.currency
            } ${
              (transaction as DebitTransaction)?.to
                ? 'to ' + (transaction as DebitTransaction)?.to?.name
                : 'from ' + (transaction as CreditTransaction)?.from?.name
            }`,
            verified: transaction?.status === TransactionStatus.Completed,
          })
        })
      }
      setTransactionsSelectData(transactionsTemp)
    }
  }, [transactionsData, accountValue, setTransactionsSelectData])

  useEffect(() => {
    if (documentsData) {
      setSupportingDocuments(documentsData.documents as Document[])
    }
  }, [documentsData, setSupportingDocuments])

  useEffect(() => {
    if (trxId && !setPaymentReviewDocs) {
      ;(async () => {
        await GetTransactionDocumentsQuery({
          variables: {
            transactionId: trxId as string,
          },
        })
      })()
    }
  }, [trxId, setPaymentReviewDocs, GetTransactionDocumentsQuery])

  useEffect(() => {
    register(`filesForUpload`)
    if (getValues(`filesForUpload`) === undefined || getValues(`filesForUpload`)?.length) {
      setValue(`filesForUpload`, [])
    }
    register(`transactionDocumentRequired`)
    if (getValues(`transactionDocumentRequired`) === undefined) {
      setValue(`transactionDocumentRequired`, true)
    }
    register(`individualRequired`)
    if (getValues(`individualRequired`) === undefined) {
      relatedIndividualIncluded
        ? setValue(`individualRequired`, true)
        : setValue(`individualRequired`, false)
    }
  }, [register, setValue, relatedIndividualIncluded, getValues, open])

  useEffect(() => {
    if (filesForUpload) {
      const filesCheck = filesForUpload.some((element: FilesForSavingAndShowType) =>
        includes(TransactionDocTypesMandatory, element?.docType),
      )
      if (filesCheck) {
        setValue(`transactionDocumentRequired`, false)
        clearErrors('documentTypeTransaction')
      } else {
        setValue(`transactionDocumentRequired`, true)
      }
    }
  }, [filesForUpload, clearErrors, setValue])

  const sendChangeRequest = useCallback(async () => {
    try {
      async function processUploadedDocs(filesForUpload: FilesForSavingAndShowType[]) {
        if (filesForUpload && filesForUpload.length > 0) {
          for (const fileForSaving of filesForUpload) {
            const mutationParams: CreateOwnDocumentRelatedMutationVariables = {
              contractId: +applicationId,
              type: fileForSaving.docType as DocumentType,
              fileName: fileForSaving.fileName,
              size: fileForSaving.size,
              plainUrl: fileForSaving.plainUrl ?? '',
              fileDescription: fileForSaving.documentName,
            }
            if (accountValue !== emptyKeyInListOfValues) {
              mutationParams.relatedAccountId = accountId ? accountId : accountValue
            }
            if (transactionValue !== emptyKeyInListOfValues) {
              mutationParams.relatedTransactionId = trxId ? trxId : transactionValue
            }
            if (individualValue !== emptyKeyInListOfValues) {
              mutationParams.relatedIndividualId = individualValue
            }
            if (isContractManagePage) {
              mutationParams.relatesTo = {
                relatedEntityId: (contractData?.contract?.owner as LegalEntity)?.id,
                relatedEntityType:
                  currentTab === DocumentUploadRelated.companyRelated
                    ? DocumentRelatedEntityType.LegalEntity
                    : DocumentRelatedEntityType.Individual,
              }
            }
            const refetchQueriesArr = trxId
              ? [
                  {
                    query: GetTransactionDocumentsDocument,
                    variables: { transactionId: trxId as string },
                  },
                ]
              : [
                  {
                    query: GetUploadedDocsDocument,
                    variables: {
                      contractId: +applicationId,
                      userFilter: DocumentUserFilter.UploadedByIndividual,
                      limit: 10,
                      offset: 0,
                      orderBy: OrderBy.CreatedAt,
                      orderDirection: OrderDirection.Descending,
                    },
                  },
                  {
                    query: GetGetUploadedDocsCountDocument,
                    variables: {
                      contractId: +applicationId,
                      userFilter: DocumentUserFilter.UploadedByIndividual,
                    },
                  },
                  ...(transactionValue && transactionValue !== '#0' && !trxId
                    ? [
                        {
                          query: GetTransactionDocumentsDocument,
                          variables: { transactionId: transactionValue },
                        },
                      ]
                    : []),
                ]
            if (!setPaymentReviewDocs) {
              await createOwnDocumentRelated({
                variables: mutationParams,
                refetchQueries: refetchQueriesArr,
                awaitRefetchQueries: true,
              })
            } else {
              const filesForUploadParamsA: CreateOwnDocumentRelatedMutationVariables[] = []
              const tempArrayParams: CreateOwnDocumentRelatedMutationVariables[] = []
              forEach(paymentReviewDocs, (paramItem) => tempArrayParams.push(paramItem))
              forEach(filesForUpload, (paramItem) =>
                filesForUploadParamsA.push({
                  contractId: +applicationId,
                  type: paramItem?.docType as DocumentType,
                  fileName: paramItem?.fileName,
                  size: paramItem?.size,
                  plainUrl: paramItem.plainUrl ?? '',
                  relatedIndividualId: individualValue,
                  fileDescription: paramItem.documentName ?? '',
                }),
              )
              setPaymentReviewDocs &&
                setPaymentReviewDocs(tempArrayParams.concat(filesForUploadParamsA))

              setFilesForSaving &&
                setFilesForSaving((prevState) => {
                  return prevState ? [...prevState, fileForSaving] : [fileForSaving]
                })
            }
          }
        }
      }

      await processUploadedDocs(filesForUpload)

      // this callback should force the parent component's data to be updated and use its filters if available
      refetchDataCallback && refetchDataCallback()
    } catch (e) {
      toast.error((e as Error).message)
      toast.error(t('savingError', 'Error saving data'))
    } finally {
      setValue(`filesForUpload`, [])
      setOpen(false)
    }
  }, [
    accountId,
    accountValue,
    applicationId,
    createOwnDocumentRelated,
    filesForUpload,
    individualValue,
    setPaymentReviewDocs,
    paymentReviewDocs,
    setValue,
    t,
    transactionValue,
    trxId,
    filesForSaving,
    setFilesForSaving,
  ])

  const individualsSelectData: {
    key: string
    label: Maybe<string> | undefined
    verified: boolean
  }[] = []

  if (!isEmpty(authorizedPersonsData?.contract?.authorizedPersons)) {
    ;(authorizedPersonsData?.contract?.authorizedPersons as ContractAuthorizedPersonType[]).forEach(
      (individual) => {
        const isActive = individual?.status === AuthorizedPersonStatuses.Activated
        individualsSelectData.push({
          key: `${individual?.person?.id}`,
          label: `${individual?.person?.firstName} ${individual?.person?.lastName} ${
            isActive ? '' : '- ' + t('unverified')
          }`,
          verified: isActive,
        })
      },
    )
  }

  const transactionDocumentList = useMemo(() => {
    if (isEmpty(recipientType)) {
      return TransactionDocumentListBusiness
    } else if (isPaymentAboveCertainAmount) {
      return DebitTransactionDocumentOptions
    } else {
      return recipientType === SwiftTransferRecipientType.Company
        ? TransactionDocumentListBusiness
        : TransactionDocumentListPerson
    }
  }, [recipientType])

  useEffect(() => {
    if (documentTypeTransaction) {
      let newDocName = ''
      if (documentTypeTransaction !== DocumentType.Other) {
        const foundTransactionDocument = transactionDocumentList.find(
          (doc) => doc.key === documentTypeTransaction,
        )
        newDocName = foundTransactionDocument ? foundTransactionDocument.label : ''
      }
      setValue(`documentName`, newDocName)
      trigger('documentName').then()
    }
  }, [documentTypeTransaction, setValue, recipientType])

  useEffect(() => {
    isPersonalContract && setCurrentTab(DocumentUploadRelated.personRelated)
  }, [isPersonalContract])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid
          container
          spacing={5}
          style={modalStyle}
          className={classes.modal}
          data-test="uploadADocument"
        >
          <Grid item xs={12} className={classes.headerModal}>
            <Typography variant={'h5'}>{t('uploadADocument', 'Upload a document')}</Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={handleClose}
              data-test="btnCloseModal"
            >
              <CloseIcon />
            </Fab>
          </Grid>
          <Grid item xs={12} className={classes.bodyModal}>
            <FormProvider {...methods}>
              <ConfirmationModal
                handleHelpReset={handleHelpReset}
                setOpen={setOpen}
                isGoBack={false}
              />
              {isContractManagePage && !isPersonalContract && (
                <DocsUploadModalTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
              )}
              <form onSubmit={handleSubmit(sendChangeRequest)}>
                {isPaymentAboveCertainAmount && (
                  <Box className={classes.alertItem}>
                    <AlertTipItem
                      type={'warning'}
                      iconComponent={<AttentionIcon />}
                      value={t(
                        'PaymentAboveCertainAmountAlert',
                        'We suggest that you upload a document before proceeding. You can proceed without uploading it but keep in mind that we might ask you to upload it while processing.',
                      )}
                    />
                  </Box>
                )}
                <Box className={classes.formGroup} data-test="relatedAccount">
                  <FormAutocompleteSelectDocs
                    name="accountValue"
                    label={t('relatedAccount', 'Related account')}
                    data={accountsSelectData}
                    defaultValue={``}
                    disabled={!!accountId}
                    isDisabled={!!accountId}
                    defaultVal={accountId ? accountId : ``}
                  />
                </Box>
                <Box className={classes.formGroup} data-test="relatedPayment">
                  <FormAutocompleteSelectDocs
                    name="transactionValue"
                    label={t('relatedPayment', 'Related payment')}
                    data={transactionsSelectData}
                    defaultValue={``}
                    disabled={isDisablePaymentSelect || !!trxId}
                    isDisabled={isDisablePaymentSelect || !!trxId}
                    defaultVal={
                      trxId && !!transactionsSelectData.find((item) => item.key === trxId)
                        ? trxId
                        : ``
                    }
                    searchLabel={t('searchBySenderBeneficiary')}
                    onlyLatinLetters={false}
                  />
                </Box>
                <Collapse
                  in={
                    relatedIndividualIncluded && currentTab !== DocumentUploadRelated.companyRelated
                  }
                >
                  <Box className={classes.formGroup} data-test="relatedPerson">
                    <FormAutocompleteSelectDocs
                      name="individualValue"
                      label={t('relatedPerson', 'Related person')}
                      data={individualsSelectData}
                      defaultValue={``}
                    />
                  </Box>
                </Collapse>
                <FormControl
                  className={`${classes.formGroup} ${classes.mb3}`}
                  data-test="documentTypeTransaction"
                >
                  <ProofOfIdentity
                    poiDocTypesArr={[]}
                    isProfile={true}
                    nameLabel={''}
                    infoLabel={''}
                    documentTypeRegName={'documentTypeTransaction'}
                    optionalIdentityList={
                      isPaymentAboveCertainAmount
                        ? DebitTransactionDocumentOptions
                        : transactionDocumentList
                    }
                    isTransaction={false}
                    disabled={isErrorInDocName}
                  />
                </FormControl>
                <FormControl
                  className={`${classes.formGroup} ${
                    documentTypeTransaction !== DocumentType.Other && classes.hidden
                  }`}
                  data-test="documentName"
                >
                  <FormControlledTextField
                    label={t('documentName')}
                    name="documentName"
                    type="text"
                    fullWidth
                    required={false}
                    data-test="documentName"
                  />
                </FormControl>
                <Grid item xs={12} className={classes.footerModal}>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        className={classes.footerBtn}
                        type="submit"
                        variant="contained"
                        disabled={!isFormCompleted}
                        color="primary"
                        data-test="upload"
                      >
                        {isPaymentAboveCertainAmount
                          ? t('uploadAndProceed', 'Upload and proceed')
                          : t('upload', 'Upload')}
                      </Button>
                    </Grid>
                    <Grid item data-test="cancel" xs={12} sm={6}>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        className={classes.footerBtn}
                      >
                        {isPaymentAboveCertainAmount
                          ? t('ProceedWithoutDoc', 'Proceed without doc')
                          : t('cancel', 'Cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}
