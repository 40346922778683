import i18n from '../i18n'

export const MIN_REGISTARTION_NUMBER = 6
export const MIN_AMOUNT_NUMBER = 1
export const MIN_REFERENCE_LENGTH = 1
export const MIN_ZIPCODE_LENGTH = 2
export const MAX_ZIPCODE_LENGTH = 10
export const MIN_LENGTH = 2
export const MAX_LEGAL_NAME_LENGTH = 250
export const MIN_TAXID_DIGITS_LENGTH = 2
export const MAX_LENGTH = 50
export const MAX_LENGTH_PERCENTS_FIELD = 5
export const MAX_DOCUMENT_NAME_LENGTH = 200
export const MAX_BUSINESS_LENGTH = 500
export const MAX_REFERENCE_LENGTH = 50
export const MAX_TAXID_LENGTH = 50
export const MAX_PAYMENT_REFERENCE_LENGTH = 100
export const LIMITED_AGE = 18
export const MAX_TRANSACTIONAL_PARTNERS = 10
export const MIN_GROUP_SIZE = 3
export const MAX_GROUP_SIZE = 30
export const PRE_ASSESSMENT_COMMON_FIELD_MAX = 255
export const CLIENT_REFERENCE_FIELD_MAX = 255
export const PRE_ASSESSMENT_LONG_FIELD_MAX = 1000
export const COMMENTS_SYMBOLS_MAX = 255
export const MAX_SOURCE_OF_FUNDS_NAME_LENGTH = 150
export const LATIN_LETTERS_ONLY_REGEXP = /^[a-zA-ZÀ-ž](?:[ '.,\-a-zA-ZÀ-ž]){1,}$/
export const LATINICA_ONLY_REGEXP = /^[ "'`&.,\-a-zA-ZA-Za-zÀ-ž](?:[ "'`&$€£.,\-a-zA-ZA-Za-zÀ-ž]){1,}$/
export const LATINICA_ONLY_REGEXP_FROM_5_TO_500_SYMBOLS = /\s*(?:\S\s*){5,}$/
export const LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD = /^[',. \-a-zA-Z0-9À-ž]{2,100}$/
export const MIN_TWO_DIGITS_REGEXP = /[0-9].*[0-9]/
export const NUMBER_AND_LATINICA_ONLY_REGEXP = /^[a-zA-ZÀ-ÿ0-9\s-]+$/
export const NUMBER_AND_LATINICA_AND_DOTS_AND_DASHES_REGEXP = /^[a-zA-ZÀ-ÿ0-9\s-.]+$/
export const NUMBER_AND_LATINICA_REGEXP = /^[a-zA-Z0-9À-ž]*[a-zA-Z0-9À-ž_',.\s–\-]*[a-zA-Z0-9À-ž]*$/
export const NUMBER_AND_LATINICA_AND_SLASH_REGEXP = /^[a-zA-Z0-9À-ž/_',.\s–\-]*$/
export const NUMBER_AND_LATINICA_CARD_NAME_REGEXP = /^[^-\s]([a-zA-ZÀ-ÿ0-9]+\s)*[a-zA-ZÀ-ÿ0-9]+$/
export const URL_WEB_SITE_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9À-ž]+([-.]{1}[a-zA-Z0-9À-ž]+)*\.[a-zA-ZÀ-ž]{2,24}(:[0-9]{1,5})?(\/.*)?$/
export const NUMBER_AND_LATINICA_AND_SYMBOLS_REGEXP = /^[ "'`&.,\-a-zA-Z0-9A-Za-zÀ-ž](?:[ "'`&$€£.,\-a-zA-Z0-9A-Za-zÀ-ž]){1,}$/
export const NUMBER_AND_LATINICA_AND_SYMBOLS_REGEXP_NOT_REQUIRED_FIELD = /^[ "'`&.,\-a-zA-Z0-9A-Za-zÀ-ž]*$/
export const NUMBER_ONLY_REGEXP = /^[+-]?\d+(\.\d+)?$/
export const NUMBERS_ONLY_REGEXP = /^[0-9]*$/
export const COMPANY_NAME_REGEX = /^[ $-/:-?{-~!"@^_№`\[\]\-a-zA-Z0-9A-Za-zÀ-ž](?:[ $-/:-?{-~!"@^_№`\[\]\-a-zA-Z0-9A-Za-zÀ-ž]){1,}$/
export const TIN_REGEX = /^(?!000|666|9\d\d)\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^\d{9}$|^\d{3}-\d{2}-\d{4}$/
export const ACCOUNT_NUMBER_REGEXP = /^[0-9]{1,255}$/
export const NUMBER_2DIGIT_AFTER_DOT_ONLY_REGEXP = /^[0-9]{0,11}(\.[0-9]{1,2})?$/
export const ACCOUNT_REGEXP = /^\d*(\.\d{0,2})?$/
export const SWIFT_REGEXP = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
export const ALL_EXCEPT_CYRILLIC_REGEXP = /^[^а-яё]+$/iu
export const NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS = /^[',. \-a-zA-Z0-9À-ž]{2,50}$/
export const NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_1_TO_50_SYMBOLS = /^[',. \-a-zA-Z0-9À-ž]{1,50}$/
export const NUMBER_AND_LATINICA_ONLY_PAYMENT_FROM_2_TO_50_SYMBOLS = /^[\/?:().,'+\\R \-a-zA-Z0-9]{2,50}$/
export const NUMBER_AND_LATINICA_ONLY_PAYMENT_FROM_1_TO_100_SYMBOLS = /^[\/?:().,'+\\R \-a-zA-Z0-9]{1,100}$/
export const PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS = /^[ .,\-0-9]*$/
export const PRE_ASSESSMENT_PERCENTS_REGEXP = /^[0-9]{0,3}?$/
export const EMAIL_REGEXP = /^([a-zA-Z0-9_?!+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,50})$/
export const CYRILIC_REGEXP = '/[wа-я]+/s+/ig'
export const ALL_ARABIC_REGEXP = /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/
export const SPECIAL_SYMBOLS_REGEXP = /[._!"#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/
// MESSAGES
export const THIS_FIELD_IS_REQUIRED_MESSAGE = i18n.t(
  'thisFieldIsRequired',
  'This field is required',
)
export const THIS_FIELD_IS_MANDATORY_MESSAGE = i18n.t(
  'textReplyMandatory',
  'Text reply is mandatory',
)
export const TIN_IS_INVALID = i18n.t('tinIsInvalid', 'TIN is invalid')
export const FIRSTNAME_IS_REQUIRED_MESSAGE = i18n.t('firstNameIsRequired', 'First Name is required')
export const LASTNAME_IS_REQUIRED_MESSAGE = i18n.t('lastNameIsRequired', 'Last Name is required')
export const DATE_OF_REGISTRATION_REQUIRED_MESSAGE = i18n.t(
  'registrationDateRequired',
  'Date of registration is required',
)
export const EMAIL_IS_REQUIRED_MESSAGE = i18n.t('emailIsRequired', 'Email is required')
export const EMAIL_IS_MANDATORY_MESSAGE = i18n.t('emailIsMandatory', 'Email is mandatory')
export const INCORRECT_EMAIL_FORMAT_MESSAGE = i18n.t(
  'incorrectEmailFormat',
  'Incorrect email format',
)
export const BIRTHDAY_IS_REQUIRED_MESSAGE = i18n.t(
  'dateOfBirthIsRequired',
  'Date of Birth is required',
)
export const INVALID_DATE_FORMAT_MESSAGE = i18n.t(
  'invalidDateFormat',
  'Invalid date format (DD/MM/YYYY)',
)
export const INVALID_PHONE_FORMAT_MESSAGE = i18n.t(
  'incorrectPhoneNumberFormat',
  'Incorrect phone number format',
)
export const MAXIMUM_DATE_MESSAGE = i18n.t(
  'dateCannotBeInTheFuture',
  'Date cannot be in the future',
)
export const NUMBER_AND_LATINICA_ONLY_MESSAGE = i18n.t(
  'minimumSymbolsAndLatin',
  'From {{min}} to {{max}} characters, Latin letters and/or numbers only',
  { min: MIN_LENGTH, max: MAX_LENGTH },
)
export const NUMBER_AND_LATINICA_ONLY_MESSAGE_REFERENCE = i18n.t('paymentReference')
export const LATIN_LETTERS_NUMBERS = i18n.t(
  'minimumSymbolsAndLatin',
  'From {{min}} to {{max}} characters, Latin letters and/or numbers only',
  { min: MIN_LENGTH, max: MAX_LENGTH },
)
export const NUMBER_AND_LATINICA_ONLY_MESSAGE_DOCUMENT = i18n.t(
  'minimumSymbolsAndLatinDocument',
  'From {{min}} to {{max}} symbols, Latin letters and/or numbers only',
  { min: MIN_LENGTH, max: MAX_DOCUMENT_NAME_LENGTH },
)
export const NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT = i18n.t(
  'mustHaveNumLatinUpTo',
  'Must have numbers and Latin letters, up to {{max}} symbols',
  { max: MAX_REFERENCE_LENGTH },
)
export const MAX_SYMBOLS_LIMIT_MESSAGE = (limit = 1): string =>
  i18n.t('mustHaveUpToSymbols', 'Must have up to {{max}} symbols', { max: limit })
export const MUST_HAVE_AT_LEAST_NUMBERS_MESSAGE = (min = 1): string =>
  i18n.t('mustHaveAtLeastNumbers', 'Must have at least {{min}} numbers', { min })
export const MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE = (min = 1): string =>
  i18n.t('theFieldMustHaveLatin', 'Must have at least {{min}} characters', { min })
export const MUST_HAVE_NOT_MORE_MESSAGE = (max = 255): string =>
  i18n.t('mustHaveNotMoreMaxCharacters', 'Must have not more {{max}} characters', { max })
export const PLEASE_UPLOAD_DOCUMENT_TYPE = (documentType: string): string =>
  i18n.t('pleaseUploadDocumentType', 'Please upload {{documentType}}', { documentType })
export const ACCOUNT_NUM_VALIDATION_MSG = i18n.t('accNumErrMsg', 'Up to 255 digits, numbers only', {
  max: MAX_REFERENCE_LENGTH,
})
export const NUMBER_AND_LATINICA_ONLY_MESSAGE_SUPPORT_500 = i18n.t(
  'DescribeSupportCodeIsRequired',
  'Must have numbers, latin letters and special symbols, up to {{max}} symbols',
  { max: MAX_BUSINESS_LENGTH },
)
export const NUMBER_AND_LATINICA_ONLY = i18n.t(
  'mustHaveNumbersLatinLetters',
  'Must have numbers and Latin letters',
)
export const NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS = i18n.t(
  'mustHaveNumbersLatinLettersSpecialSymbols',
  'Must have numbers, latin letters and special symbols',
)
export const POSTAL_CODE_REQUIRED = i18n.t(
  'topicSupportCodeIsRequired',
  'Must have from {{min}} to {{max}} characters',
  { min: MIN_ZIPCODE_LENGTH, max: MAX_ZIPCODE_LENGTH },
)
export const POSTAL_CODE_MATCH = i18n.t(
  'postalCodeIsMatch',
  'Only digits, letters, spaces and hyphens',
)

export const GROUP_SIZE_REQUIRED = i18n.t(
  'groupSizeRequired',
  'Group size can be from {{min}} to {{max}} persons',
  { min: MIN_GROUP_SIZE, max: MAX_GROUP_SIZE },
)

export const NUMBER_ONLY = i18n.t('numberOnly', 'Numbers only')
export const URL_IS_REQUIRED_MESSAGE = i18n.t('incorrectUrlFormat', 'Incorrect URL format')
export const MIN_REGISTARTION_NUMBER_MESSAGE = i18n.t(
  'haveAtLeastSymbols',
  `Registration number must have at least ${MIN_REGISTARTION_NUMBER} symbols`,
  {
    min: MIN_REGISTARTION_NUMBER,
  },
)
export const MIN_AMOUNT_NUMBER_MESSAGE = i18n.t(
  'haveAtLeastSymbols',
  `Amount must have at least ${MIN_AMOUNT_NUMBER} symbols`,
  {
    min: MIN_AMOUNT_NUMBER,
  },
)
export const LIMITED_AGE_MESSAGE = i18n.t(
  'limitedAge',
  `Select Date of birth (limited to {{age}} yrs from current date)`,
  { age: LIMITED_AGE },
)
export const LATINICA_ONLY_MESSAGE = i18n.t(
  'theFieldMustHaveLatinLettersOnly',
  'Must have at least {{min}} characters, Latin letters only',
  { min: MIN_LENGTH },
)
export const OPTIONAL_FIELD_MESSAGE = i18n.t(
  'theFieldMustHaveLatinLettersOnly',
  'Must have from {{min}} to 100 characters, Latin letters, numbers, periods or dashes',
  { min: MIN_LENGTH },
)
export const NO_CYRILLIC_MESSAGE = i18n.t(
  'mustHaveLatinLettersOnly',
  'Must have latin letters only',
)
export const NUMBER_AND_LATINICA_AND_SYMBOLS = i18n.t(
  'theFieldMustHaveLatin',
  'Must have at least {{min}} characters',
  { min: MIN_LENGTH },
)
export const NUMBER_AND_LATINICA_AND_SYMBOLS_ALPHANUMERIC = i18n.t(
  'theFieldMustHaveLatinAlphanumeric',
  'Must have at least {{min}} characters. Alphanumeric characters only',
  { min: MIN_LENGTH },
)
export const BUSINESS_DESCRIPTION_FIELD_LENGTH = i18n.t(
  'fieldMustHaveFrom5To500Symbols',
  `This field must have from 5 to 500 characters`,
)

export const NUMBER_ONLY_MESSAGE = i18n.t(
  'numbersAfterDot',
  `Maximum 2 digits after the decimal point`,
)
export const MAX_LENGTH_MESSAGE = i18n.t(
  'mustHaveNotMoreMaxCharacters',
  'Must have not more {{max}} characters',
  { max: MAX_SOURCE_OF_FUNDS_NAME_LENGTH },
)

export const EMAIL_EXSIST_AUTHORIZED_PERSON = i18n.t(
  'emailExistInContract',
  `This email was used for another authorised person of this company`,
)
export const EMAIL_EXSIST_AUTHORIZED_PERSON_RESPONSE = i18n.t(
  'emailExistInContractResponse',
  `Person Already Authorized On Contract`,
)

export const NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS = i18n.t(
  'symbolsAndLatinFrom2To50',
  'Other field must have {{min}}-{{max}} symbols.',
  { min: MIN_LENGTH, max: MAX_LENGTH },
)

export const NUMBERS_DASHES_COMMAS_FULLSTOPS = i18n.t(
  'numbersDashesCommasFullstops',
  `This field  may have numbers, dashes, commas and fullstops`,
)

export const PERCENTS_ONLY = i18n.t('percentsOnly', 'Percents only')

export const NOT_NEGATIVE = i18n.t('valueCantBeNegative', "Value can't be negative")

export const PRE_ASSESSMENT_SUM_OF_PERCENTS100 = i18n.t(
  'sumOfPercents',
  'Sum of percents should not be more than 100',
)

export const OVERALL_VALUE_SHOULDNT_BE_GREATER_THAN_100 = i18n.t(
  'overallValueGreater100',
  "Overall value shouldn't be greater than 100%",
)
export const OVERALL_PREDICTED_AMOUNT_CANT_BE_0 = i18n.t(
  'overallPredictedAmountCantBe0',
  "Overall predicted amount can't be 0",
)

export const PRE_ASSESSMENT_SUM_OF_PERCENTS0 = i18n.t(
  'sumOfPercentsMore',
  'Sum of percents should be more than 0',
)

export const PERCENT_FIELD_MAX_LENGTH = i18n.t(
  'percentFieldMaxLength',
  'Maximum 5 characters allowed, fractional numbers (or integers)',
)

export const PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON = i18n.t(
  'mustHaveNotMoreMaxCharacters',
  'Must have not more {{max}} characters',
  { max: PRE_ASSESSMENT_COMMON_FIELD_MAX },
)

export const PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG = i18n.t(
  'mustHaveNotMoreMaxCharacters',
  'Must have not more {{max}} characters',
  { max: PRE_ASSESSMENT_LONG_FIELD_MAX },
)

export const NUMBERS_ONLY_MESSAGE = i18n.t(
  'numbersOnlyMessage',
  `Only a positive number is allowed`,
)
