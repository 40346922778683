import React, { FC, SetStateAction, useCallback, useEffect } from 'react'
import { isEqual } from 'lodash'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTabsStyle } from '../../commonStyles'
import { DocumentUploadRelated } from '../../types'

type DocsUploadModalTabsPropsType = {
  currentTab: DocumentUploadRelated
  setCurrentTab: React.Dispatch<SetStateAction<DocumentUploadRelated>>
}

const DocsUploadModalTabsComponent: FC<DocsUploadModalTabsPropsType> = ({
  currentTab,
  setCurrentTab,
}) => {
  const classes = useTabsStyle()
  const { t } = useTranslation()

  const methods = useFormContext()
  const { setValue } = methods

  useEffect(() => {
    setValue(`individualRequired`, false)
  }, [])

  const handleChange = useCallback(
    (_, newValue) => {
      setCurrentTab(newValue)
      if (DocumentUploadRelated.companyRelated) {
        setValue(`individualRequired`, false)
        setValue('individualValue', undefined)
      } else {
        setValue(`individualRequired`, true)
      }
    },
    [setCurrentTab],
  )

  return (
    <AppBar position="static" className={classes.tabBar}>
      <Tabs value={currentTab} onChange={handleChange} indicatorColor="secondary" name="tabs">
        <Tab
          label={t(DocumentUploadRelated.companyRelated, 'Company related')}
          className={classes.tab}
          value={DocumentUploadRelated.companyRelated}
        />
        <Tab
          label={t(DocumentUploadRelated.personRelated, 'Person related')}
          className={classes.tab}
          value={DocumentUploadRelated.personRelated}
          data-test={'personRelatedTab'}
        />
      </Tabs>
    </AppBar>
  )
}

export const DocsUploadModalTabs = React.memo(DocsUploadModalTabsComponent, isEqual)
