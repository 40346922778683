import React from 'react'

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FlagIcon } from '../../assets/images/icons/logosForCard.svg'
import { ReactComponent as Visa } from '../../assets/images/icons/visaForCards.svg'

const useStyles = makeStyles((theme: Theme) => ({
  details: {
    // marginTop: '80px',
    width: '100%',
    height: '200px',
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#999999',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    filter: 'grayscale(1)',
    position: 'static',
    height: '100%',
    padding: '12px 42px 12px 28px',
    left: '24px',
    top: '29.28px',
    background: '#FFFFFF',
    boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '16px',
    flex: 'none',
    margin: '0 0',
  },
  logo: {
    marginTop: '13px',
    marginLeft: '-4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginRight: 'auto',
  },
  cardNumber: {
    marginTop: '33px',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    width: '100%',
    color: '#999999',
    '& .MuiTypography-h3 ': {
      fontSize: '24px',
      color: '#CCCCCC',
    },
  },
  expiryDateCvv: {
    marginTop: '9px',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'center',
    color: '#000000',
    width: '100%',
  },
  visa: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0-18px 0 0',
  },
  expiryDate: {
    color: '#CCCCCC',
    fontSize: '16px',
  },
  cvv: {
    marginLeft: '32px',
    color: '#CCCCCC',
    fontSize: '16px',
  },
  ownerVisa: {
    marginTop: '10px',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: '#999999',
    width: '100%',
    marginBottom: '20px',
  },
  owner: {
    color: '#CCCCCC',
    fontSize: '16px',
  },
}))

export const EmptyCard: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box className={classes.details}>
        {/*<CardNotSelected />*/}
        <Grid container alignItems="center" wrap="nowrap" className={classes.card}>
          <Grid item className={classes.logo}>
            <FlagIcon />
          </Grid>

          <Grid item className={classes.cardNumber}>
            <Typography variant={'h3'}>{'0000'}</Typography>
            <Typography variant={'h3'}>{'0000'}</Typography>
            <Typography variant={'h3'}>{'0000'}</Typography>
            <Typography variant={'h3'}>{'0000'}</Typography>
          </Grid>
          <Grid item className={classes.expiryDateCvv}>
            <Typography className={classes.expiryDate} variant={'h4'}>
              {'00/00'}
            </Typography>
            <Typography className={classes.cvv} variant={'h4'}>
              {'000'}
            </Typography>
          </Grid>
          <Grid item className={classes.ownerVisa}>
            <Typography className={classes.owner} variant={'h4'}>
              {t('сardholderName')}
            </Typography>
            <Box className={classes.visa}>
              <Visa />
            </Box>
          </Grid>
        </Grid>
        <Typography variant={'h4'} className={classes.title}>
          {t('cardNotSelected', 'Card not selected')}
        </Typography>
      </Box>
    </>
  )
}
