import React, { FC, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Box, FormControl, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { FormControlledTextField, GridRow } from './Fields'
import { AddressAutocompleteSelect, PlaceResult } from './Selectors'
import { CountryAutocompleteSelect } from './Selectors/CountryAutocompleteSelect'
import { ContactSupportLink } from '../Common'
import { getLabelByKey } from '../../utils'
import { ContractProfileChangeAnswers } from '../../graphql'
import { CountriesUnknownList } from '../../utils/Data'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  inputHelperText: {
    margin: theme.spacing(1.5, 0, 0),
    paddingLeft: 12,
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textDecoration: 'revert',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      margin: '0 0 0 5px',
      '& svg': {
        display: 'none',
      },
    },
  },
  street: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  formControl: {
    width: '100%',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  dirtyControl: {
    position: 'relative',
  },
}))

export type AddressFieldsType =
  | 'city'
  | 'streetAddress'
  | 'postalCode'
  | 'additionalDetailsOptional'

export type CorrespondenceFieldsType = 'countryCorrespondence' | AddressFieldsType

export type AddressPropsType = {
  [key in AddressFieldsType]: {
    name: string
    initialValue: Maybe<string | undefined>
  }
}

export type AddressCorrespondencePropsType = {
  [key in CorrespondenceFieldsType]: {
    name: string
    initialValue: Maybe<string | undefined>
  }
}
export const AddressAutocomplete: FC<{
  linkSupportNewTab?: boolean
  showWarn?: boolean
  hideCountry?: boolean
  customCountryLabel?: string
  correspondence?: boolean
  addressLabel?: string
  readOnlyCountry?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
  props: AddressPropsType | AddressCorrespondencePropsType
  numberOnPage?: number
}> = ({
  linkSupportNewTab = false,
  showWarn,
  hideCountry = false,
  customCountryLabel,
  correspondence = false,
  readOnlyCountry,
  addressLabel,
  changedContractProfileData,
  props,
  numberOnPage = 1,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { setValue, watch, formState, errors } = useFormContext()
  const country = watch('country')
  const countryCorrespondence = watch('countryCorrespondence')
  const autocompleteFields = useCallback((place: PlaceResult): void => {
    const { country, postalCode, city } = place
    if (!correspondence) {
      setValue('country', country?.toLowerCase())
    }
    setValue(correspondence ? 'postalCodeCorrespondence' : 'postalCode', postalCode || '', {
      shouldValidate: true,
      shouldDirty: true,
    })
    city &&
      setValue(correspondence ? 'cityCorrespondence' : 'city', city, {
        shouldValidate: true,
        shouldDirty: true,
      })
  }, [])

  const clearFields = useCallback(() => {
    const names = correspondence
      ? ['postalCodeCorrespondence', 'cityCorrespondence', 'streetAddressCorrespondence']
      : ['postalCode', 'city', 'streetAddress']
    names.forEach((field) => {
      setValue(field, '')
    })
  }, [])

  const fieldsRenderOrder: AddressFieldsType[] = ['additionalDetailsOptional', 'postalCode', 'city']

  const isAdditionalFieldChanged = (field: AddressFieldsType): boolean => {
    switch (field) {
      case 'additionalDetailsOptional':
        return correspondence
          ? !!changedContractProfileData?.correspondenceAddress?.additionalDetails
          : !!changedContractProfileData?.address?.additionalDetails
      case 'postalCode':
        return correspondence
          ? !!changedContractProfileData?.correspondenceAddress?.zip
          : !!changedContractProfileData?.address?.zip
      case 'city':
        return correspondence
          ? !!changedContractProfileData?.correspondenceAddress?.city
          : !!changedContractProfileData?.address?.city
      default:
        return false
    }
  }

  const isHidden = (field: AddressFieldsType): boolean => {
    if (formState.errors[props[field].name]) return true

    switch (field) {
      case 'additionalDetailsOptional':
        return correspondence
          ? !changedContractProfileData?.correspondenceAddress?.additionalDetails
          : !changedContractProfileData?.address?.additionalDetails
      case 'postalCode':
        return correspondence
          ? !changedContractProfileData?.correspondenceAddress?.zip
          : !changedContractProfileData?.address?.zip
      case 'city':
        return correspondence
          ? !changedContractProfileData?.correspondenceAddress?.city
          : !changedContractProfileData?.address?.city
      default:
        return true
    }
  }

  const getTitle = (field: AddressFieldsType): string => {
    switch (field) {
      case 'additionalDetailsOptional':
        return correspondence
          ? changedContractProfileData?.correspondenceAddress?.additionalDetails || ''
          : changedContractProfileData?.address?.additionalDetails || ''
      case 'postalCode':
        return correspondence
          ? changedContractProfileData?.correspondenceAddress?.zip || ''
          : changedContractProfileData?.address?.zip || ''
      case 'city':
        return correspondence
          ? changedContractProfileData?.correspondenceAddress?.city || ''
          : changedContractProfileData?.address?.city || ''
      default:
        return ''
    }
  }

  const isCountryChanged = correspondence
    ? !!changedContractProfileData?.correspondenceAddress?.country
    : !!changedContractProfileData?.address?.country

  const isStreetAddressChanged = correspondence
    ? !!changedContractProfileData?.correspondenceAddress?.line1
    : !!changedContractProfileData?.address?.line1

  return (
    <>
      {!!addressLabel && (
        <Box mt={4}>
          <Typography variant={'h3'} className={classes.title}>
            {addressLabel}
          </Typography>
        </Box>
      )}
      {!hideCountry && (
        <GridRow>
          <FormControl className={`${classes.formControl}`}>
            <CountryAutocompleteSelect
              key={correspondence ? countryCorrespondence : country}
              name={correspondence ? 'countryCorrespondence' : 'country'}
              label={customCountryLabel ? customCountryLabel : t('country', 'Country')}
              clearFields={clearFields}
              readOnly={readOnlyCountry}
              data-test="companyDetails-country"
            />
            {showWarn && (
              <Typography className={classes.inputHelperText}>
                {t('ifYouDontSeeYourCountryHere, ', 'If you don’t see your country here, ')}
                <ContactSupportLink newTab={linkSupportNewTab} />
              </Typography>
            )}
          </FormControl>
          {isCountryChanged && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={
                correspondence
                  ? !changedContractProfileData?.correspondenceAddress?.country
                  : !changedContractProfileData?.address?.country
              }
              title={
                correspondence
                  ? getLabelByKey(
                      changedContractProfileData?.correspondenceAddress?.country,
                      CountriesUnknownList,
                    )
                  : getLabelByKey(
                      changedContractProfileData?.address?.country,
                      CountriesUnknownList,
                    )
              }
            />
          )}
        </GridRow>
      )}
      <GridRow>
        <FormControl
          className={`${classes.formControl} ${classes.street}`}
          data-test={`companyDetails-${props.streetAddress.name}`}
        >
          <AddressAutocompleteSelect
            name={props.streetAddress.name}
            label={t('streetAddress', 'Street Address')}
            handleChange={autocompleteFields}
            countryCode={correspondence ? countryCorrespondence : country}
            multiline
            numberOnPage={numberOnPage}
          />
        </FormControl>
        {isStreetAddressChanged && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              props.streetAddress.name in errors ||
              (correspondence
                ? !changedContractProfileData?.correspondenceAddress?.line1
                : !changedContractProfileData?.address?.line1)
            }
            title={
              correspondence
                ? changedContractProfileData?.correspondenceAddress?.line1 || ''
                : changedContractProfileData?.address?.line1 || ''
            }
          />
        )}
      </GridRow>

      {fieldsRenderOrder.map((field: AddressFieldsType, i) => {
        return (
          <GridRow key={field + i}>
            <FormControl
              className={classes.formControl}
              data-test={`companyDetails-${props[field].name}`}
            >
              <FormControlledTextField
                label={t(field)}
                name={props[field].name}
                type="text"
                fullWidth={true}
                required={false}
                data-test={`autotest-${props[field].name}`}
                multiline
              />
            </FormControl>

            {isAdditionalFieldChanged(field) && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={isHidden(field)}
                title={getTitle(field)}
              />
            )}
          </GridRow>
        )
      })}
    </>
  )
}
