import * as yup from 'yup'

import i18n from '../../i18n'

export const CountryDomicileInputSchema = yup.object().shape({
  countryDomicile: yup
    .string()
    .required(i18n.t('countryOfDomicileIsRequired', 'Country of Domicile is required 1')),
})

export type CountryDomicileInput = yup.Asserts<typeof CountryDomicileInputSchema>
