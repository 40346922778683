import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Button, FormControl, Grid, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'

import { FormAutocompleteMultiSelect, useCommonStyles } from '../..'
import { CountriesList } from '../../../utils/Data'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalBody: {
    padding: theme.spacing(0, 3, 0, 3),
    overflow: 'auto',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
  btnClose: {
    minWidth: 'auto',
    minHeight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
    margin: '0 0 20px 0',
    '& .MuiAutocomplete-root': {
      margin: '0 0 20px 0',
    },
  },
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export const NewUboResidencyModal: React.FC<{
  isOpen: boolean
  editData: { i: number; countries: string[] } | undefined
  onClose: () => void
  onSubmit: (value: string[]) => void
}> = ({ isOpen, editData, onClose, onSubmit }) => {
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const methods = useForm({ mode: 'onChange' })

  const { register, watch, reset } = methods

  const uboResidencyName = 'residencies'
  register(uboResidencyName)
  const value = watch(uboResidencyName)

  const handleResetModal = () => {
    reset({
      [uboResidencyName]: [],
    })
  }

  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <Box style={modalStyle} className={classes.modal}>
        <FormProvider {...methods}>
          <Box className={classes.modalHeader}>
            <Typography variant={'h4'} className={classes.title}>
              {t('uboResidency', 'UBO Residency')}
            </Typography>
            <Button onClick={() => onClose()} className={classes.btnClose}>
              <CloseIcon fontSize="small" />
            </Button>
          </Box>

          <Box className={classes.modalBody}>
            <Grid container spacing={0}>
              <FormControl className={classes.formControl}>
                <FormAutocompleteMultiSelect
                  defaultValue={!!editData && editData.countries}
                  className={classes.boxMultiSelect}
                  name={uboResidencyName}
                  label={t('countries', 'Countries')}
                  data={CountriesList}
                  data-test="uboResidency"
                />
              </FormControl>
            </Grid>
          </Box>

          <Box className={classes.modalFooter}>
            <Button
              variant="contained"
              disableElevation
              color="default"
              onClick={() => {
                onClose()
                handleResetModal()
              }}
            >
              {t('cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              type="button"
              color="primary"
              disabled={isEmpty(value)}
              disableElevation
              data-test="newUboResidencyModalSubmit"
              onClick={() => {
                onSubmit(value)
                handleResetModal()
              }}
            >
              {t('submit', 'Submit')}
            </Button>
          </Box>
        </FormProvider>
      </Box>
    </Modal>
  )
}
