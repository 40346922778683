import { Box, Button, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconDot } from '../../../assets/images/icons/Dot.svg'
import { ConfirmationAnyModal } from '../../Common'
import { ReactComponent as Attention } from '../../../assets/images/icons/attention.svg'
import { ContractUbo, UboOwner, Individual, LegalEntity } from '../../../graphql'

const useStyles = makeStyles((theme) => ({
  mobileTableRow: {
    marginBottom: theme.spacing(0),
    '&:not(:first-child)': {
      // borderTop: '1px solid rgba(0,0,0,.1)',
      borderTop: 0,
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
    '&:nth-child(n+5)': {
      [theme.breakpoints.down('xs')]: {
        borderBottom: '0',
      },
    },
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      // borderRadius: '25px',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
}))

export const BeneficialOwnerInfoMob: FC<{
  person: ContractUbo | UboOwner
  handleModalDelete: (id: number | string) => void
  openDelete: number | string
  setOpenDelete: (val: number | string) => void
  handleClose: (val: string) => void
  onEdit: (person: ContractUbo | UboOwner, ownerLevel: number) => void
  onConfirmDelete: (
    id: string | number,
    ownerLevel: string | number,
    person: ContractUbo | UboOwner,
  ) => void
}> = ({ person, handleModalDelete, openDelete, handleClose, onEdit, onConfirmDelete }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { firstName, lastName } = person.ubo?.entity as Individual
  const { companyName } = person?.ubo?.entity as LegalEntity
  const { shareSize, id } = person
  const ownerLevel = (((person as UboOwner).level as number) + 1) * 3
  const shareInfo = shareSize === 0 && !companyName ? 'Managing director' : shareSize + '%'

  return (
    <Box key={id} className={classes.mobileTableRow}>
      <Box className={classes.mobileTableItem}>
        <Box ml={ownerLevel} className={classes.mobileTableLabel}>
          {t('name', 'Name')}
        </Box>
        <Box ml={ownerLevel}>{companyName ? `${companyName}` : `${firstName} ${lastName}`}</Box>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Box ml={ownerLevel} className={classes.mobileTableLabel}>
          {t('share', 'Share')}
        </Box>
        <Box ml={ownerLevel}>
          {ownerLevel ? <Box style={{ color: 'grey' }}>{shareInfo}</Box> : <b>{shareInfo}</b>}
        </Box>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Box ml={ownerLevel}>
          <Box className={classes.buttonsBox}>
            <Button aria-label="Edit" type="button" onClick={() => onEdit(person, ownerLevel)}>
              {t('edit', 'Edit')}
            </Button>
            <IconDot />
            <Button
              color="secondary"
              aria-label="delete"
              type="button"
              onClick={() => handleModalDelete(id as number | string)}
            >
              {t('delete', 'Delete')}
            </Button>

            <ConfirmationAnyModal
              title={t('delete', 'Delete')}
              color={'error'}
              icon={<Attention />}
              name="delete"
              handleClose={() => handleClose('delete')}
              handleConfirm={() => onConfirmDelete(id as string | number, ownerLevel, person)}
              isOpen={+openDelete === +(id as number)}
              labelCancel={t('cancel', 'Cancel')}
              labelConfirm={t('delete', 'Delete')}
            >
              <Typography>
                {t(
                  'deleteUboConfirmControlling',
                  'Are you sure you want to delete this Controlling person',
                )}
                ?
              </Typography>
            </ConfirmationAnyModal>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
