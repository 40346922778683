import { Box, Button } from '@material-ui/core'
import { isEqual } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ButtonWithTooltip } from '../../../components'
import { LimitedAccessRight } from '../../../graphql'
import { useDetermineUserRights, useIsLimitedContract } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { useDownloadTrxStatement } from './hooks/useDownloadTrxStatement'
import { useRepeatPayment } from '../../../components/Common/TransactionStatusCell/hooks'

const OutboundButtonsBlockComponent: FC<{
  handleBack?: () => void
  trxId: string
  accId: string
  confirmationAvailable: boolean
}> = ({ handleBack, trxId, accId, confirmationAvailable }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  const repeatPayment = useRepeatPayment(trxId)
  const { isLimitedContract } = useIsLimitedContract()

  const handleDownloadStatement = useDownloadTrxStatement(accId, trxId, confirmationAvailable)
  const downLoadText = confirmationAvailable
    ? t('downloadConfirmation', 'Download Confirmation')
    : t('downloadStatement', 'Download Statement')
  return (
    <Box className={classes.modalFooter}>
      <Button
        variant="contained"
        className={classes.selectBut}
        onClick={handleDownloadStatement}
        disableElevation
        color={isViewOnly ? 'primary' : undefined}
      >
        {downLoadText}
      </Button>
      {isViewOnly ? (
        <Button
          className={classes.cancelBut}
          variant="contained"
          onClick={handleBack}
          disableElevation
        >
          {t('close', 'Close')}
        </Button>
      ) : (
        <ButtonWithTooltip
          className={classes.selectBut}
          disabledOperationName="Repeat payment"
          disabled={isLimitedContract}
          variant="contained"
          color="primary"
          onClick={repeatPayment}
        >
          {t('repeatPayment', 'Repeat payment')}
        </ButtonWithTooltip>
      )}
    </Box>
  )
}

export const OutboundButtonsBlock = React.memo(OutboundButtonsBlockComponent, isEqual)
