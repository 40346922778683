import * as yup from 'yup'
import {
  MAX_ZIPCODE_LENGTH,
  MIN_ZIPCODE_LENGTH,
  NUMBER_AND_LATINICA_ONLY_REGEXP,
  POSTAL_CODE_MATCH,
  POSTAL_CODE_REQUIRED,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'

export const PostalCodeInputSchema = yup.object({
  postalCode: yup
    .string()
    .nullable()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(NUMBER_AND_LATINICA_ONLY_REGEXP, POSTAL_CODE_MATCH)
    .test('minLength', POSTAL_CODE_REQUIRED, (val) => !!val && val?.length >= MIN_ZIPCODE_LENGTH)
    .test('maxLength', POSTAL_CODE_REQUIRED, (val) => {
      return !!val && val?.length <= MAX_ZIPCODE_LENGTH
    }),
})

// OLD VARIANT

// export const PostalCodeInputSchema = yup.object().shape({
//   postalCode: yup
//     .string()
//     .notRequired()
//     .test(
//       'minLength',
//       i18n.t(POSTAL_CODE_REQUIRED),
//       (val) => !!val && val?.length >= MIN_ZIPCODE_LENGTH,
//     )
//     .test('maxLength', i18n.t(POSTAL_CODE_REQUIRED), (val) => {
//       return !!val && val?.length <= MAX_ZIPCODE_LENGTH
//     })
//     // .required(i18n.t('postalCodeIsRequired', 'Postal Code is required '))
//     .matches(NUMBER_AND_LATINICA_ONLY_REGEXP, POSTAL_CODE_REQUIRED),
// })

export type PostalCodeInput = yup.Asserts<typeof PostalCodeInputSchema>
