import { useReactiveVar } from '@apollo/client'
import { ClickAwayListener } from '@material-ui/core'
import React, { FC } from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import { isModalPromptOpen, isNavMenuRoute } from '../../graphql/local'
import { CustomPrompt } from './CustomPrompt'

export const HandleAwayFromPage: FC<{ when: boolean }> = ({ when, children }) => {
  const modalPromptOpen = useReactiveVar(isModalPromptOpen)

  const handleClickAway = () => isNavMenuRoute(true)

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseUp">
      <div>
        {modalPromptOpen && (
          <NavigationPrompt when={when}>
            {({ onCancel, onConfirm }) => (
              <CustomPrompt open onCancel={onCancel} onConfirm={onConfirm} />
            )}
          </NavigationPrompt>
        )}
        {children}
      </div>
    </ClickAwayListener>
  )
}
