import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import { ReactComponent as EmptyCardsList } from '../../assets/images/icons/empty_cards_list.svg'
import { cardsPageStateVar } from '../../graphql/local'
import { usePageFiltersSorting } from '../../hooks'
import { EmptyCardsListMessage } from './EmptyCardsListMessage'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconEmptyCards: {
    marginLeft: '45px',
    marginTop: '50px',
  },
  round: {
    display: 'block',
    height: '160px',
    width: '160px',
    lineHeight: '160px',

    borderRadius: '80px',

    backgroundColor: '#FFEFED',
    marginBottom: '20px',
  },
  info: {
    textAlign: 'center',
    marginBottom: 12,
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
}))

export const CardsEmptyState: React.FC<{
  checkIsCardholder: boolean
  isNoCreateCardLimitations?: boolean
}> = ({ checkIsCardholder, isNoCreateCardLimitations = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { pageFilters } = usePageFiltersSorting(cardsPageStateVar)

  return (
    <Grid item xs={12} className={classes.container}>
      <Box className={classes.round}>
        <EmptyCardsList className={classes.iconEmptyCards} />
      </Box>

      {!!pageFilters.searchValue ? (
        <Typography variant={'h2'} className={classes.info}>
          {t('noCardsFound', 'No cards found')}
        </Typography>
      ) : (
        <EmptyCardsListMessage
          checkIsCardholder={checkIsCardholder}
          isNoCreateCardLimitations={isNoCreateCardLimitations}
        />
      )}
    </Grid>
  )
}

export default CardsEmptyState
