import { Rxios } from 'rxios'
import config from '../../config'
// import { Observable } from 'rxjs'

import { InvitedUserData } from '../../types/web-api.types'

export class WebApi {
  private rxios: Rxios

  constructor(private readonly apiUrl = process.env.REACT_APP_API_URL) {
    this.rxios = new Rxios({
      baseURL: `${config.apiUrl}` || 'http://localhost:8000',
      headers: {
        'Access-Control-Allow-Credentials': true,
        // 'access-control-request-headers': 'Access-Control-Allow-Credentials',
      },
      withCredentials: true,
    })
  }

  async logout(): Promise<void> {
    return this.rxios.get<void>('/auth/logout?redirect=false').toPromise()
  }

  async getInvitedUserData(invitationId: string): Promise<InvitedUserData> {
    return await this.rxios.get<InvitedUserData>(`/api/onboarding/info/${invitationId}`).toPromise()
  }
}
