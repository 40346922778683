import { Box, FormHelperText } from '@material-ui/core'
import React, { FC, ReactElement, ReactNode } from 'react'
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'
import ControlledTooltip from './ControlledTooltip'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  dirtyText: {
    flex: '0 0 100%',
    width: '100%',
    color: theme.palette.warning.main,
    textDecoration: 'underline',
    top: -20,
  },
}))

type AdditionalProps = {
  arrowHorizontalPosition?: 'left' | 'center' | 'right'
  hidden?: boolean
  wrapperClass?: string
  'data-test'?: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: ReactElement<any, any> & ReactNode
}

const ControlledTooltipWrapped: FC<Omit<TooltipProps, 'children'> & AdditionalProps> = ({
  title,
  arrowHorizontalPosition,
  arrow = true,
  hidden = false,
  placement = 'bottom-start',
  wrapperClass,
  'data-test': dataTest = 'changeApproved',
  children,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={wrapperClass ?? ''} hidden={hidden} data-test={dataTest}>
      <ControlledTooltip
        title={title}
        arrowHorizontalPosition={arrowHorizontalPosition}
        arrow={arrow}
        placement={placement}
        hidden={hidden}
        {...rest}
      >
        {children === undefined || children === null ? (
          <FormHelperText className={classes.dirtyText}>
            {t('changeApproved', 'Change to be approved')}
          </FormHelperText>
        ) : (
          children
        )}
      </ControlledTooltip>
    </Box>
  )
}
export default ControlledTooltipWrapped
