import * as yup from 'yup'

import i18n from '../../i18n'
import { poiNeeded } from '../../graphql/local'

export const PoiInputSchema = yup.object().shape({
  documentType: yup.string().test({
    name: 'mandatoryPoi',
    message: i18n.t(
      'mandatoryPoi',
      'One file of "ID Front", "Driving License Front" or "Passport" is mandatory',
    ),
    test: () => !poiNeeded(),
  }),
})

export type PoiInput = yup.Asserts<typeof PoiInputSchema>
