import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { isArray, isEmpty } from 'lodash'
import { Grid, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'

import { ChangesType, ContractProfileChangesType } from '../../types'
import {
  DocsListCorrespondenceAddress,
  DocsListLegalNameAndTradingName,
  DocsListTaxId,
  EconomicProfileCurrencies,
  ProofOfIdentityListPOA,
} from '../../utils/Data'
import { ProofOfIdentityControllingPerson } from '../NewPerson/Forms/ProofOfIdentityControllingPerson'
import { economicProfileLabels } from '../../pages/AplicationActions/contract'
import { contractChangeTypes, getEnumLabel } from '../../utils'

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    flexWrap: 'wrap',
    padding: 0,
    '& .MuiListItemText-primary': {
      ...theme.typography.subtitle2,
      color: '#595959',
    },
    '& .MuiListItemText-secondary': {
      ...theme.typography.body1,
      color: '#000',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  listLabel: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  bodyModal: {
    padding: '24px 0 0 24px',
    maxHeight: 'calc(100vh - 180px)',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '24px',
    },
  },
  uploadDoc: {
    padding: theme.spacing(0, 3),
    margin: '16px 0 24px 0',
    maxHeight: 'calc(100vh - 180px)',
    '& > div > div > div > div': {
      marginTop: '0px',
    },
  },
}))

type ChangesConfirmationSubListProps = {
  changes: ChangesType[] | undefined
  contractProfileChangesType: ContractProfileChangesType
}

export const ChangesConfirmationSubList: FC<ChangesConfirmationSubListProps> = ({
  changes,
  contractProfileChangesType,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    setValue,
    register,
    getValues: modalFormGetValues,
    watch: watchModal,
    clearErrors,
  } = useFormContext()

  const { filterForChanges, isUpload, docsOptions, isOtherInDocType } = useMemo(() => {
    switch (contractProfileChangesType) {
      case ContractProfileChangesType.LegalName:
        return {
          filterForChanges: ['companyName'],
          isUpload: true,
          docsOptions: DocsListLegalNameAndTradingName,
          isOtherInDocType: true,
        }
      case ContractProfileChangesType.GeneralInfoNoConfirmation:
        return {
          filterForChanges: ['tradingName', 'businessSector'],
          isUpload: false,
          docsOptions: [],
          isOtherInDocType: false,
        }
      case ContractProfileChangesType.TaxId:
        return {
          filterForChanges: ['taxId'],
          isUpload: true,
          docsOptions: DocsListTaxId,
          isOtherInDocType: true,
        }
      case ContractProfileChangesType.Address:
        return {
          filterForChanges: ['city', 'streetAddress', 'postalCode', 'additionalDetailsOptional'],
          isUpload: true,
          docsOptions: ProofOfIdentityListPOA,
          isOtherInDocType: false,
        }
      case ContractProfileChangesType.CorrespondenceAddress:
        return {
          filterForChanges: [
            'cityCorrespondence',
            'streetAddressCorrespondence',
            'postalCodeCorrespondence',
            'additionalDetailsOptionalCorrespondence',
          ],
          isUpload: true,
          docsOptions: DocsListCorrespondenceAddress,
          isOtherInDocType: false,
        }
      case ContractProfileChangesType.ContactInformation:
        return {
          filterForChanges: ['phoneNumber', 'urls', 'businessWebsiteUrl'],
          isUpload: false,
          docsOptions: [],
          isOtherInDocType: false,
        }
      case ContractProfileChangesType.GeneralQuestions:
        return {
          filterForChanges: [
            'businessSectorConductingClientKYC',
            'products',
            'anticipatedAccountActivity',
            'sourceOfFunds',
            'conductingKYC',
            'businessSectorConductingKYC',
            'businessSectorConductPublisherContentMonitoring',
          ],
          isUpload: false,
          docsOptions: [],
          isOtherInDocType: false,
        }
      case ContractProfileChangesType.EconomicProfile:
        return {
          filterForChanges: [
            'currenciesProfile',
            'estimatedMonthlyTurnover',
            'inboundTransfersDescription',
            'outboundTransfersDescription',
            'businessActivitiesAndServices',
            'estimatedIncomingTransactionsAmount',
            'estimatedOutgoingTransactionsAmount',
            'regulatorNameAndWebsite',
            'licenseNumber',
          ],
          isUpload: false,
          docsOptions: [],
          isOtherInDocType: false,
        }
      default:
        return { filterForChanges: [], isUpload: false, docsOptions: [], isOtherInDocType: false }
    }
  }, [contractProfileChangesType])

  const isChanges = useMemo(() => {
    return changes?.some((change) => filterForChanges.includes(change.fieldName))
  }, [changes, filterForChanges])

  useEffect(() => {
    register(`filesForUpload` + contractProfileChangesType)
    if (modalFormGetValues(`filesForUpload` + contractProfileChangesType) === undefined) {
      setValue(`filesForUpload` + contractProfileChangesType, [])
    }
    register(`required` + contractProfileChangesType)
    if (modalFormGetValues(`required` + contractProfileChangesType) === undefined) {
      setValue(`required` + contractProfileChangesType, isChanges)
    }
  }, [modalFormGetValues, register, setValue, contractProfileChangesType])

  useEffect(() => {
    if (watchModal(`filesForUpload` + contractProfileChangesType)) {
      const filesCheck = !isEmpty(watchModal(`filesForUpload` + contractProfileChangesType))
      if (filesCheck) {
        setValue(`required` + contractProfileChangesType, false)
        clearErrors('documentType' + contractProfileChangesType)
      } else if (isChanges) {
        setValue(`required` + contractProfileChangesType, true)
      }
    }
  }, [watchModal(`filesForUpload` + contractProfileChangesType), setValue, clearErrors, changes])

  const adaptValueToView = (value: unknown, key?: string): string => {
    if (isArray(value)) {
      const economicProfileCurrencies = EconomicProfileCurrencies.map((v) => v.key)
      const result = value.map((value) => {
        if (typeof value === 'string' && economicProfileCurrencies.includes(value)) {
          return value.toUpperCase()
        }
        return economicProfileLabels(value)
      })
      return result.join(', ')
    }

    if (typeof value === 'string') {
      const getEnumLabelValue = !!key ? getEnumLabel(value, key) : value
      return economicProfileLabels(getEnumLabelValue)
    }
    return `${value || ''}`
  }
  return (
    <>
      {isChanges ? (
        <>
          <Grid item xs={12} container>
            {changes
              ?.filter((change) => filterForChanges.includes(change.fieldName))
              ?.filter((change) => {
                return !(!change.oldValue && !change.newValue)
              })
              ?.map((change, i) => (
                <Grid key={`${i}+${change.fieldName}`} item xs={12} className={classes.bodyModal}>
                  <List dense={false} className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <Typography variant="h3" className={classes.listLabel}>
                        {contractChangeTypes(change.fieldName)}
                      </Typography>
                      <ListItemText
                        primary={`${t('currentValue')}:`}
                        secondary={adaptValueToView(change.oldValue, change.fieldName)}
                      />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${classes.mb2}`}>
                      <ListItemText
                        primary={`${t('newValue', 'New value')}:`}
                        secondary={adaptValueToView(change.newValue, change.fieldName)}
                      />
                    </ListItem>
                  </List>
                </Grid>
              ))}
          </Grid>

          {isUpload && (
            <Grid item xs={12} className={classes.uploadDoc}>
              <ProofOfIdentityControllingPerson
                nameLabel={t('supportingDocument', 'Supporting document')}
                infoLabel={t('spaceSymbol', ' ')}
                filesForUploadRegName={'filesForUpload' + contractProfileChangesType}
                documentTypeRegName={'documentType' + contractProfileChangesType}
                expiryDateRegName={'expiryDate' + contractProfileChangesType}
                optionalIdentityList={docsOptions}
                isModal={true}
                isOtherInDocType={isOtherInDocType}
              />
            </Grid>
          )}
        </>
      ) : null}
    </>
  )
}
