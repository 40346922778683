import React, { FC, useCallback, useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import {
  Typography,
  Box,
  makeStyles,
  Button,
  FormControlLabel,
  RadioGroup,
  Grid,
  Link,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { accessRights as accessRightsVar, goBackPathVar } from '../../graphql/local'
import {
  LegalEntity,
  useGetContractDetailsQuery,
  ContractStatusType,
  ContractAuthorizedPersonType,
  useGetAuthorizedPersonsLazyQuery,
} from '../../graphql'
import { BlackRadio } from '../Common'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { StepperTypes, RegistrationSteps, AppActions, statusActions } from '../../constants'
import { useStepper } from '../../hooks'
import { ReactComponent as InformationIcon } from '../../assets/images/icons/info_icon.svg'
import { isEmpty } from 'lodash'
import { isEnoughSignatories } from '../../utils'
import { useReactiveVar } from '@apollo/client'
import { PersonAccessRightsEnum } from '../../types'
import config from '../../config'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.125rem',
    minWidth: 265,
    width: '430px',
    height: 48,
    margin: '0 0 20px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  twoButtonsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    '& button': {
      width: 'auto',
      minWidth: 'auto',
    },
  },
  threeButtonsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column-reverse',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  secondaryButton: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  proceedControll: {
    width: '302px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  goBack: {
    width: '104px',
    marginRight: '24px',
    height: 48,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: '24px',
    },
  },
  mb4: {
    marginBottom: theme.spacing(3.5),
  },
  alertWin: {
    border: '1px solid',
    borderColor: 'rgba(39, 110, 241, 0.2)',
    padding: '10px 10px',
    margin: '0 0 24px 0',
    display: 'inline-flex',
    alignItems: 'center',

    '& p': {
      margin: '0 0 0 15px',
    },
    '& button': {
      fontSize: '0.875rem',
      verticalAlign: 'revert',
    },
  },
}))

export const PersonsDetailsList: FC = () => {
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const accessRights = useReactiveVar(accessRightsVar)
  const [persons, setPersons] = useState<ContractAuthorizedPersonType[]>([])
  const [canProceed, setCanProceed] = useState(false)
  const [GetAuthorizedPersonsQuery, { data, loading }] = useGetAuthorizedPersonsLazyQuery({
    variables: { id: +applicationId },
  })
  const successfullyOpenCheck =
    history.location.state &&
    (history.location.state as { successfully: string }).successfully === 'open'

  const goBackPath = useReactiveVar(goBackPathVar)
  const isFromReview =
    history.location.state && (history.location.state as { from: string }).from === 'review'

  const { t } = useTranslation()
  const classes = useStyles()
  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  useEffect(() => {
    const authorizedPersons = data?.contract?.authorizedPersons as ContractAuthorizedPersonType[]
    if (!loading && authorizedPersons) {
      setPersons(authorizedPersons)
    }
  }, [data, loading])

  useEffect(() => {
    GetAuthorizedPersonsQuery()
  }, [])

  const { setHeaderText, setCurrentStep } = useStepper(StepperTypes.registration)
  useEffect(() => {
    setCurrentStep(RegistrationSteps.authorizedPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t('companyBusinessApplication', 'Business application', {
            companyName: (contractData?.contract?.owner as LegalEntity).companyName,
          }),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [contractData, contractLoading])

  const handleOpen = useCallback(() => {
    goBackPathVar('')
    history.push(generatePath(APP_PATHS.application.authorizedPersons.new, { applicationId }), {
      accessRights,
    })
  }, [applicationId, accessRights])

  const onProceed = useCallback(() => {
    history.push(generatePath(APP_PATHS.application.directors.list, { applicationId }))
  }, [])

  const handleBack = useCallback(() => {
    if (goBackPath) {
      history.push(goBackPath)
      goBackPathVar('')
    } else {
      goBackPathVar(
        generatePath(
          APP_PATHS.application.authorizedPersons[
            !contractData?.contract?.signatoriesCount ? 'add' : 'list'
          ],
          {
            [PATH_PARAMS.applicationId]: applicationId,
          },
        ),
      )
      history.push(generatePath(APP_PATHS.application.edit, { applicationId }))
    }
  }, [goBackPath, history, contractData])

  const handleAccessRights = useCallback((event) => {
    accessRightsVar(event.target.value)
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      const authorizedPersons = data?.contract?.authorizedPersons as ContractAuthorizedPersonType[]
      const [canProcess] = isEnoughSignatories(authorizedPersons)
      setCanProceed(canProcess)
    }
  }, [data])

  useEffect(() => {
    accessRightsVar(PersonAccessRightsEnum.full)
  }, [])

  return (
    <Grid
      container
      spacing={1}
      wrap={'wrap'}
      className={classes.root}
      data-test="authorizedPersons"
    >
      <Grid xs={12} item className={classes.mb4}>
        {successfullyOpenCheck ? (
          <Box className={classes.alertWin}>
            <InformationIcon />
            <Typography paragraph={true}>
              <Trans i18nKey="successfullyPerson" t={t}>
                <Link
                  component="button"
                  onClick={() => {
                    history.push(
                      generatePath(APP_PATHS.application.authorizedPersons.list, {
                        [PATH_PARAMS.applicationId]: +applicationId,
                      }),
                    )
                  }}
                  underline="always"
                >
                  {t('hereLink', 'here')}
                </Link>
              </Trans>
            </Typography>
          </Box>
        ) : null}
        <Typography variant={'h2'} className={classes.title}>
          {t(
            'personsWhoWillHaveAccessToYourAccounts',
            'Persons who will have access to your accounts',
          )}
        </Typography>
      </Grid>
      <Grid xs={12} item className={classes.mb4}>
        <Typography variant="body1">
          {t(
            'selectAuthorisedPersonToAddOnTheNextStep',
            'Please add the persons that should have access to your account at {{company}}.',
            { company: config.name },
          )}
        </Typography>
      </Grid>
      <Grid item md={8} className={classes.mb4}>
        <Box>
          <RadioGroup name="access_accounts" value={accessRights} onChange={handleAccessRights}>
            <Box mb={3} data-test="authorisedSignatory">
              <FormControlLabel
                value={PersonAccessRightsEnum.full}
                control={<BlackRadio size="small" />}
                label={
                  <Box ml={2}>
                    <Typography gutterBottom variant={'h4'}>
                      {t('authorisedSignatory', 'Authorised Signatory')}
                    </Typography>
                    <Typography variant={'body1'}>
                      {t(
                        'willHaveFullAccessToTheCompanysAccounts',
                        'A person who is duly authorised by the company to sign applications, documents and payment orders. Will have full access to the company’s accounts.',
                      )}
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box mb={3} data-test="personWithLimitedAccess">
              <FormControlLabel
                value={PersonAccessRightsEnum.limited}
                control={<BlackRadio size="small" />}
                label={
                  <Box ml={2}>
                    <Typography gutterBottom variant={'h4'}>
                      {t(
                        'authorisedPersonWithLimitedAccess',
                        'Authorised Person with limited access',
                      )}{' '}
                    </Typography>
                    <Typography variant={'body1'}>
                      {t(
                        'willHaveOneOfTheFurtherSelectedLimited',
                        'A person who doesn’t have signature rights. Will have one of the further selected limited levels of access to the company’s accounts.',
                      )}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </RadioGroup>
        </Box>
      </Grid>
      <Grid
        container
        spacing={1}
        className={isFromReview ? classes.twoButtonsWrap : classes.threeButtonsWrap}
      >
        <Grid item className={classes.secondaryButton}>
          <Button
            type="button"
            variant="contained"
            className={classes.goBack}
            fullWidth
            disableElevation
            onClick={handleBack}
            data-test="goBack-btn"
          >
            {t('goBack', 'Go back')}
          </Button>
          {!isFromReview && (
            <Button
              type="button"
              variant="contained"
              className={classes.proceedControll}
              fullWidth
              disableElevation
              disabled={!canProceed || !persons?.length}
              onClick={onProceed}
            >
              {t('proceedToDirectors', 'Proceed to Directors')}
            </Button>
          )}
        </Grid>

        <Grid item>
          <Button
            type="button"
            className={classes.button}
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleOpen}
            data-test="proceedToDetails-btn"
          >
            {t('proceedToDetails', 'Proceed to details')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PersonsDetailsList
