import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  useDownloadAccountStatementLazyQuery,
  useDownloadTransactionConfirmationLazyQuery,
} from '../../../../graphql'

export const useDownloadTrxStatement = (
  accountId: string,
  transactionId: string,
  realConfirmationAvailable: boolean,
) => {
  const { t } = useTranslation()
  const [DownloadAccountStatementTransactionQuery] = useDownloadAccountStatementLazyQuery()
  const [DownloadTransactionConfirmationQuery] = useDownloadTransactionConfirmationLazyQuery()

  const openDownloadedDoc = (link: string) => {
    const downloadLink = window.document.createElement('a')
    downloadLink.setAttribute('target', '_blank')
    downloadLink.href = link
    downloadLink.click()
  }

  return useCallback(async () => {
    try {
      let result = null
      if (realConfirmationAvailable) {
        const { data, error } = await DownloadTransactionConfirmationQuery({
          variables: {
            id: transactionId,
          },
        })
        if (error) {
          toast.error(error.message)
        }
        result = data?.report?.result
      } else {
        const { data, error } = await DownloadAccountStatementTransactionQuery({
          variables: {
            id: accountId,
            transactionId: transactionId,
          },
        })
        if (error) {
          toast.error(error.message)
        }
        result = data?.report?.result
      }
      if (result) {
        openDownloadedDoc(result)
      }
    } catch (error) {
      toast.error(error.message)
      toast.error(t('errorDownloadConfirmation', 'Error Downloading Confirmation Statement'))
    }
  }, [DownloadAccountStatementTransactionQuery, accountId, transactionId])
}
