import React, { FC, useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid, makeStyles, Typography, Box, Button } from '@material-ui/core'
import { PASSWORD_REQUIREMENT_REGEXP } from '../SignUp/Validations'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { AuthoriseModal, ContainerWithImage, FormPasswordField, Logo } from '../Common'
import { useHistory, useParams } from 'react-router'
import { CommonTipItem } from '../Common'
import { ReactComponent as IconConfirmed } from '../../assets/images/icons/confirmed.svg'
import { SignupFormInput } from '../../schemes'
import { rxiosLogin } from '../../resources'
import { ErrorPage } from '../Error'
import { errorHandler } from '../Error'
import { resendDelay } from '../../graphql/local'
import { focusKeyPressNext } from '../../utils'
import { TwoFaMethod } from '../../graphql'
import config from '../../config'

const useStyles = makeStyles(() => ({
  formContainer: {
    visibility: 'hidden',
    width: 1,
    overflow: 'hidden',
  },
  error: {
    color: 'red',
    padding: '0 0 0 12px',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '12px',
  },
  info: {
    margin: '20px 0 20px 0',
    border: '1px solid #ADDEC9',
    '&>div': {
      padding: '5px 10px',
    },
  },
  button: {
    marginTop: 32,
  },
  notAsterisk: {
    marginBottom: '10px',
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
}))

export const ResetPasswordPage: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<SignupFormInput>({
    mode: 'onBlur',
  })
  const { handleSubmit, getValues, formState, errors, trigger } = methods
  const classes = useStyles()
  const history = useHistory()
  const { [PATH_PARAMS.token]: token } = useParams() as Record<string, string>

  const [pswdChanged, setPswdChanged] = useState(false)
  const [open, setOpen] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)
  const [challengeId, setChallengeId] = useState('')
  const [twoFaType, setTwoFaType] = useState('')
  const [phoneDigits, setPhoneDigits] = useState('')
  const [errorInfo, setErrorInfo] = useState({
    code: '',
    text: '',
    description: '',
  })

  const resetHandler = useCallback(async (formData: SignupFormInput) => {
    type Response = {
      challengeId: string
      requires2FA: boolean
      resendTimeout: number
      phoneLastFourDigits: string
      twoFaType: TwoFaMethod
    }

    rxiosLogin
      .post('password-reset/finish', {
        token: token,
        newPassword: formData.password,
      })
      .subscribe(
        (next: Response | unknown) => {
          if ((next as Response).requires2FA) {
            const { challengeId, resendTimeout, phoneLastFourDigits, twoFaType } = next as Response

            setChallengeId(challengeId)
            setTwoFaType(twoFaType ?? '')
            setPhoneDigits(phoneLastFourDigits.slice(2))
            setOpen(true)
            resendDelay(resendTimeout * 1000)
          } else {
            setPswdChanged(true)
          }
        },
        () => {
          setOpen(false)
        },
      )
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setBtnDisable(true)
  }, [])

  const onLogin = useCallback(() => {
    history.push(APP_PATHS.auth.login)
  }, [])

  const handlerEnter = useCallback(
    (ev, fState) => {
      return focusKeyPressNext(ev, fState)
    },
    [formState.isDirty],
  )

  useEffect(() => {
    rxiosLogin
      .post('password-reset/validate-token', {
        token: token,
      })
      .subscribe(
        () => {
          // eslint-disable-next-line no-console
          console.log('token valid')
        },
        (err) => {
          errorHandler(err, errorInfo, setErrorInfo)
        },
      )
  }, [token])

  return errorInfo.description ? (
    <ErrorPage code={errorInfo.code} text={errorInfo.text} description={errorInfo.description} />
  ) : (
    <ContainerWithImage
      image={config.images.resetPassword}
      reverse={true}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid container direction="row" data-test="resetPasswordPage">
        <Grid item xs container direction="row" justifyContent="center">
          <Grid item xs={12}>
            <Grid item xs={12} className="logo hiddenMdUp">
              <a href={APP_PATHS.root}>
                <Logo />
              </a>
            </Grid>
            <Box display="flex" justifyContent="center">
              <Typography variant={'h2'}>
                {!pswdChanged
                  ? t('resetPassword', 'Reset password')
                  : t('passwordChanged', 'Password changed')}
              </Typography>
            </Box>

            <AuthoriseModal
              open={open}
              handleClose={handleClose}
              id={challengeId}
              twoFaType={twoFaType}
              phoneDigits={phoneDigits}
              setPswdChanged={setPswdChanged}
              title={t('confirmResetPassword', 'Confirm reset password')}
            />

            {pswdChanged && (
              <Grid item xs={12} className={classes.info} data-test="passwordSuccessfullyTitle">
                <CommonTipItem
                  value={t(
                    'yourPasswordHasBeenChangedSuccessfully.',
                    'Your password has been changed successfully.',
                  )}
                  iconComponent={<IconConfirmed />}
                />
              </Grid>
            )}

            {pswdChanged && (
              <Button
                type="submit"
                className={classes.button}
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                onClick={onLogin}
                data-test="logIn"
              >
                {t('logIn', 'Log In')}
              </Button>
            )}

            <Grid className={pswdChanged ? classes.formContainer : ''}>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(resetHandler)}
                  id="forNextFocus"
                  onKeyDown={(event) => {
                    handlerEnter(event, formState)
                  }}
                >
                  <FormPasswordField
                    className={classes.notAsterisk}
                    label={t('passwordNew', 'Enter new password')}
                    name="password"
                    fullWidth
                    validations={{
                      required: true,
                      pattern: {
                        value: PASSWORD_REQUIREMENT_REGEXP,
                        message: '',
                        // message: t('passwordsMustContainSymbols', 'Must contain 8 symbols, 1 capital and 1 digit'),
                      },
                    }}
                    onChange={() => {
                      ;(!isEmpty(errors) || formState.touched.repeat_password) &&
                        trigger(['password', 'repeat_password'])
                    }}
                    data-test="passwordNew"
                  />
                  {errors.password && errors.password.type === 'pattern' && (
                    <div className={classes.error} data-test="errorPasswordNew">
                      {t(
                        'passwordsMustContainSymbols',
                        'Must contain 8 symbols, 1 capital and 1 digit',
                      )}
                    </div>
                  )}
                  {methods.errors.password && methods.errors.password.type === 'required' && (
                    <div className={classes.error}>
                      {t('fieldIsMandatory', 'This field is mandatory')}
                    </div>
                  )}

                  <FormPasswordField
                    className={classes.notAsterisk}
                    label={t('repeatNewPassword', 'Repeat new password')}
                    name="repeat_password"
                    fullWidth
                    validations={{
                      required: true,
                      validate: (value) => value === getValues('password'),
                    }}
                    onChange={() => {
                      trigger(`repeat_password`)
                    }}
                    data-test="repeatNewPassword"
                  />
                  {errors.repeat_password && errors.repeat_password.type === 'validate' && (
                    <div className={classes.error} data-test="errorRepeatNewPassword">
                      {t('passwordsDontMatch', 'The passwords entered do not match')}
                    </div>
                  )}
                  {methods.errors.repeat_password &&
                    methods.errors.repeat_password.type === 'required' && (
                      <div className={classes.error}>
                        {t('fieldIsMandatory', 'This field is mandatory')}
                      </div>
                    )}
                  <Button
                    type="submit"
                    className={classes.button}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={btnDisable || !formState.isValid}
                    data-test="resetPassword"
                  >
                    {t('resetPassword', 'Reset password')}
                  </Button>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerWithImage>
  )
}

export default ResetPasswordPage
