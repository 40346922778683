import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { isEmpty, isEqual } from 'lodash'
import Box from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormControlledTextField, GridRow } from './Fields'
import FormAutocompleteSelectNew from './Selectors/FormAutocompleteSelectNew'
import { Button, FormControl, Grid, InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import FormHelperText from '@material-ui/core/FormHelperText'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'
import { regionsForSelect } from '../../utils'
import { BusinessRegionsEnumErrorTypes } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      '& label': {
        top: '-5px',
      },
    },
  },
  volume: {
    flexBasis: '47%',
    marginLeft: theme.spacing(2.5),
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      paddingRight: 15,
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      paddingRight: 5,
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputLabel-root': {
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
      },
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  removeButton: {
    margin: '10px 0px 0px 10px',
    padding: 0,
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '100%',
    },
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
  errorItem: {
    paddingLeft: 15,
  },
}))

type Region = { key: string; label: string }
type TransactionDataRegionsProps = {
  regionsData: Region[]
  readOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
}
type TransactionDataRegionsArray = Record<string, string>[]

const EMPTY_TRANSACTION_ITEM = { businessCategoryRegion: '', businessCategoryPercentage: '' }

const BusinessRegionsComponent: FC<TransactionDataRegionsProps> = ({
  readOnly,
  regionsData,
  tooltipTitle,
}) => {
  const fieldName = 'businessRegions'

  const { t } = useTranslation()
  const classes = useStyles()
  const methods = useFormContext()
  const { setValue, errors, control, formState, trigger } = methods

  const regions = useWatch({
    name: fieldName,
    control,
  }) as TransactionDataRegionsArray

  useEffect(() => {
    if (regions && isEmpty(regions)) {
      onAdd()
    }
  }, [regions])

  const { fields, append, remove } = useFieldArray({ control, name: fieldName })

  const [selectedRegions, setSelectedRegions] = useState<{ key: number; value: string }[]>([])

  const transactionDataPercentSum = useMemo(() => {
    return regions
      ? regions.reduce((acc, field) => acc + Number(field.businessCategoryPercentage), 0)
      : 0
  }, [regions])

  const error = errors ? errors[fieldName] : null

  const onAdd = useCallback(() => {
    append(EMPTY_TRANSACTION_ITEM)
  }, [setValue, regions])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      if (indexToRemove > 0) {
        remove(indexToRemove)
      }
    },
    [regions, setValue, setSelectedRegions],
  )

  const isNoOptions = useMemo(() => Object.keys(regionsData).length - fields?.length <= 0, [
    fields,
    regionsData,
  ])
  const isEmptyRegions = useMemo(() => regions?.some((region) => !region.businessCategoryRegion), [
    regions,
  ])

  useEffect(() => {
    const selectedRegions: { key: number; value: string }[] = []
    if (fields) {
      fields.forEach((field, index) => {
        !!field.businessCategoryRegion &&
          selectedRegions.push({ key: index, value: field.businessCategoryRegion })
      })
    }

    setSelectedRegions(selectedRegions)
  }, [fields.length])

  return (
    <FormProvider {...methods}>
      <Box>
        <GridRow>
          {!isEmpty(fields) &&
            fields.map((field, index) => {
              return (
                <Box className={classes.twoRows} key={field.id}>
                  <FormControl className={classes.formControl}>
                    <FormAutocompleteSelectNew
                      label={t('businessRegion', 'Business region')}
                      name={`businessRegions[${index}].businessCategoryRegion`}
                      data={regionsForSelect(regionsData, selectedRegions, index)}
                      defaultValue={field?.businessCategoryRegion}
                      readOnly={readOnly}
                      onInputChange={() => trigger(fieldName)}
                      data-test="businessRegion"
                    />
                  </FormControl>
                  <FormControlledTextField
                    className={clsx(classes.volume)}
                    label={t('percentageOfVolume', 'Percentage of volume')}
                    name={`businessRegions[${index}].businessCategoryPercentage`}
                    fullWidth={true}
                    required={false}
                    type="number"
                    inputProps={{
                      step: '1',
                      style: { textAlign: 'right' },
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                    }}
                    defaultValue={field?.businessCategoryPercentage || ''}
                    disabled={readOnly}
                    onInputChange={() => trigger(fieldName)}
                    data-test="percentageOfVolume"
                  />
                  {!readOnly && index !== 0 && index === fields?.length - 1 && (
                    <Box className={classes.buttonsBox}>
                      <Button
                        color="secondary"
                        aria-label="delete"
                        type="button"
                        onClick={() => onRemove(index)}
                        className={clsx(
                          index !== 0 && index === fields?.length - 1 ? '' : classes.hidden,
                        )}
                        data-test="regionDelete"
                      >
                        {t('delete', 'Delete')}
                      </Button>
                    </Box>
                  )}
                </Box>
              )
            })}
          {!!error && (
            <Grid className={classes.errorMessage}>
              <Box xs={6}>
                {error.type === BusinessRegionsEnumErrorTypes.valueIsNotEmptyRegion && (
                  <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                )}
              </Box>

              <Box xs={6} className={classes.errorItem}>
                {error.type !== BusinessRegionsEnumErrorTypes.valueIsNotEmptyRegion && (
                  <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                )}
              </Box>
            </Grid>
          )}
          {tooltipTitle && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.businessRegions}
              title={tooltipTitle}
            />
          )}
          <Grid item className={classes.addButton}>
            <Button
              type="button"
              variant="contained"
              fullWidth={true}
              disableElevation
              onClick={onAdd}
              disabled={
                transactionDataPercentSum >= 100 || !!error || isNoOptions || isEmptyRegions
              }
              data-test="addButtonBusinessRegion"
            >
              {t('add', 'Add')}
            </Button>
          </Grid>
        </GridRow>
      </Box>
    </FormProvider>
  )
}

export const BusinessRegions = React.memo(BusinessRegionsComponent, isEqual)
