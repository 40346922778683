import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  Box,
  Fab,
  Grid,
  Hidden,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconConfirm } from '../../assets/images/icons/confirmed.svg'
import { cardsPageStateVar, ColumnNameWithSort, CommonTipItem, Loader } from '../../components'
import { CARD_ALERT_TIMEOUT } from '../../constants/alerts'
import { ActionSignature, CardsCountQuery, CardsOrderBy, CardType } from '../../graphql'
import { cardAlert, cardAlertHeader } from '../../graphql/local/dashboard'
import { CardDetailsPage } from './CardDetailsPage'
import { CardsListMobile } from './CardsListMobile'

import { CardsListRow } from './CardsListRow'
import { CardsListTablet } from './CardsListTablet'
import { MODAL_WRAPPER_ZINDEX } from '../../constants'
import PagePagination from '../../components/Common/PagePagination'
import { CARDS_ITEMS_PER_PAGE } from './helpers'
import { usePageFiltersSorting } from '../../hooks'
import { AlertDataType } from '../../types'

const useStyles = makeStyles((theme) => ({
  table: {
    borderTop: '1px solid rgba(0,0,0,.1)',
  },
  sortTransfer: {
    ...theme.typography.body1,
    fontWeight: 700,
    minHeight: 32,
    minWidth: 70,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: theme.spacing(1),
    width: 'fit-content',
    position: 'fixed',
    top: 8,
    zIndex: 9999,
    left: 0,
    right: 0,
    margin: 'auto',
    border: '1px solid #ADDEC9',
    boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    '& p': {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  alertTextContainer: {
    display: 'flex',
    padding: '0 18px',
    gap: theme.spacing(1),
    '&>div': {
      padding: 0,
    },
    '& .tipItem': {
      padding: 0,
    },
    '& .alertHeader': {
      marginTop: 0,
    },
  },
  tipText: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 14,
    lineHeight: '24px',
    '&>a': {
      color: 'black',
    },
  },
  btnClose: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: 20,
    width: 20,
    minHeight: 20,
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    '& .MuiSvgIcon-root': {
      height: 16,
      width: 16,
    },
  },
}))

export const CardsListTab: React.FC<{
  data: [CardType]
  selectedCardData: CardType | undefined
  setSelectedCardData: React.Dispatch<React.SetStateAction<CardType | undefined>>
  setActionData: React.Dispatch<React.SetStateAction<ActionSignature | undefined>>
  setActionModal: React.Dispatch<React.SetStateAction<string | undefined>>
  isViewOnly: boolean
  setOpenLimits: (value: boolean) => void
  openLimits: boolean
  handleSort: (sortedBy: string) => void
  isLoading: boolean
  count: CardsCountQuery | undefined
  setAlertData: React.Dispatch<React.SetStateAction<AlertDataType | undefined>>
  setIsOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  data,
  selectedCardData,
  setSelectedCardData,
  setActionData,
  setActionModal,
  setOpenLimits,
  openLimits,
  handleSort,
  isLoading,
  count,
  setAlertData,
  setIsOpenAlert,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const classes = useStyles()

  const alert = useReactiveVar(cardAlert)
  const alertHeader = useReactiveVar(cardAlertHeader)

  const { pageFilters, setPageFilters } = usePageFiltersSorting(cardsPageStateVar)

  useEffect(() => {
    if (alert) {
      const alertTimeout = setTimeout(() => {
        cardAlert(undefined)
        cardAlertHeader(undefined)
        clearTimeout(alertTimeout)
      }, CARD_ALERT_TIMEOUT)
    }
  }, [alert])

  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" height="100%" mt={3}>
        <Loader />
      </Box>
    )
  }

  return (
    <>
      <Hidden mdDown>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <ColumnNameWithSort
                name={CardsOrderBy.Alias}
                handleRefetch={handleSort}
                data-test="cardLabel"
              >
                {t('cardLabel', 'Card label')}
              </ColumnNameWithSort>

              <ColumnNameWithSort
                data-test="owner"
                name={CardsOrderBy.Owner}
                handleRefetch={handleSort}
              >
                {t('owner', 'Owner')}
              </ColumnNameWithSort>

              <ColumnNameWithSort
                data-test="number"
                name={CardsOrderBy.CardNumber}
                handleRefetch={handleSort}
              >
                {t('number', 'Number')}
              </ColumnNameWithSort>

              <ColumnNameWithSort
                data-test="currency"
                name={CardsOrderBy.Currency}
                handleRefetch={handleSort}
              >
                {t('currency', 'Currency')}
              </ColumnNameWithSort>

              <ColumnNameWithSort
                data-test="status"
                name={CardsOrderBy.Status}
                handleRefetch={handleSort}
              >
                {t('status', 'Status')}
              </ColumnNameWithSort>
              <TableCell data-test="limits">
                <Box className={classes.sortTransfer}>{t('spentPerMonth', 'Spent per month')}</Box>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.map((value) => {
                return (
                  <CardsListRow
                    data={value}
                    key={value.id}
                    selectedCardId={selectedCardData?.id}
                    setSelectedCardData={setSelectedCardData}
                    setActionData={setActionData}
                    setActionModal={setActionModal}
                    setOpenLimits={setOpenLimits}
                  />
                )
              })}
          </TableBody>
        </Table>
      </Hidden>

      {alert && (
        <Box className={classes.alertContainer}>
          <IconConfirm />
          <Box className={classes.alertTextContainer}>
            <Box display="flex" flexDirection="column">
              <CommonTipItem
                value={
                  alertHeader
                    ? alertHeader
                    : t('cardRequestCreatedSuccess', 'Card request created successfully!')
                }
                iconComponent={null}
              />
              <Box className={classes.tipText}>{alert}</Box>
            </Box>
          </Box>
          <Fab
            className={classes.btnClose}
            onClick={() => {
              cardAlert(undefined)
            }}
          >
            <CloseIcon />
          </Fab>
        </Box>
      )}

      {/* mobile tablet view */}
      <Hidden xsDown lgUp>
        <Grid container>
          <Grid item xs={12}>
            {!isLoading &&
              data &&
              data.map((value) => {
                return (
                  <CardsListTablet
                    data={value}
                    key={value.id}
                    selectedCardId={selectedCardData?.id}
                    setSelectedCardData={setSelectedCardData}
                    setOpenModal={setOpenModal}
                    setOpenLimits={setOpenLimits}
                    setActionData={setActionData}
                    setActionModal={setActionModal}
                  />
                )
              })}
          </Grid>
        </Grid>
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Grid container>
          <Grid item xs={12}>
            {!isLoading &&
              data &&
              data.map((value) => {
                return (
                  <CardsListMobile
                    data={value}
                    key={value.id}
                    selectedCardId={selectedCardData?.id}
                    setSelectedCardData={setSelectedCardData}
                    setOpenModal={setOpenModal}
                    setOpenLimits={setOpenLimits}
                    setActionData={setActionData}
                    setActionModal={setActionModal}
                  />
                )
              })}
          </Grid>
        </Grid>
      </Hidden>

      {selectedCardData && (
        <Hidden lgUp>
          <Modal
            style={{ zIndex: MODAL_WRAPPER_ZINDEX }}
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="account-modal-title"
            aria-describedby="account-modal-description"
          >
            <CardDetailsPage
              setIsOpenAlert={setIsOpenAlert}
              setAlertData={setAlertData}
              closeModal={() => setOpenModal(false)}
              selectedCardData={selectedCardData}
              setOpenLimits={setOpenLimits}
              openLimits={openLimits}
            />
          </Modal>
        </Hidden>
      )}
      <PagePagination
        page={pageFilters.page}
        itemsPerPage={CARDS_ITEMS_PER_PAGE}
        totalItems={count?.contractCardsCount}
        handleChange={(event, value) => {
          setPageFilters({ page: value })
        }}
      />
    </>
  )
}
