import React, { FC, useCallback, useState } from 'react'
import { AppBar, Button, Tab, Tabs } from '@material-ui/core'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useTabsStyle } from '../../../commonStyles'
import { ApplicationType } from '../../../graphql/local'
import { ApplicationTypeTab } from '../../../types'
import { InfoBlock } from '../../Common'

const ApplicationTypeTabsSectionComponent: FC<{
  addNewApp: (type: ApplicationType) => void
}> = ({ addNewApp }) => {
  const classes = useTabsStyle()
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<ApplicationTypeTab>(ApplicationTypeTab.business)

  const handleChange = useCallback(
    (_, newValue) => {
      setCurrentTab(newValue)
    },
    [setCurrentTab],
  )

  return (
    <>
      <AppBar position="static" className={classes.tabBar}>
        <Tabs value={currentTab} onChange={handleChange} indicatorColor="secondary" name="tabs">
          <Tab
            label={t('business', 'Business')}
            className={classes.tab}
            value={ApplicationTypeTab.business}
          />
          <Tab
            label={t('personal', 'Personal')}
            className={classes.tab}
            value={ApplicationTypeTab.personal}
          />
        </Tabs>
      </AppBar>
      <InfoBlock fontSize={14}>
        {currentTab === ApplicationTypeTab.business
          ? t(
              'personalApplicationIsCreatedForLegalEntity',
              'Business application is created for a legal entity',
            )
          : t(
              'personalApplicationIsCreatedForNaturalPerson',
              'Personal application is created for a natural person',
            )}
        .
      </InfoBlock>

      <Button
        variant="contained"
        disableElevation
        color="primary"
        onClick={() => addNewApp(currentTab)}
        fullWidth
      >
        {t('createNewApplication', 'Create new application')}
      </Button>
    </>
  )
}

export const ApplicationTypeTabsSection = React.memo(ApplicationTypeTabsSectionComponent, isEqual)
