import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as SortTransferIcon } from '../../../assets/images/icons/transactionsSort.svg'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    minHeight: 'auto',
    minWidth: 'auto',
    width: 24,
    height: 24,
    cursor: 'pointer',
    padding: 0,
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
  },
  rotate: {
    transform: 'scale(1, -1)',
  },
}))

export const SortButton: FC<{
  isSorted?: boolean
  handleClick?: () => void
}> = ({ handleClick, isSorted = false }) => {
  const classes = useStyles()

  return (
    <Button
      className={`${classes.sortIcon} ${!isSorted && classes.rotate}`}
      onClick={handleClick}
      data-test="sortActions"
    >
      <SortTransferIcon />
    </Button>
  )
}
