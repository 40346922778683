import { toNumber } from 'lodash'
import React, { useCallback } from 'react'
import { useParams } from 'react-router'
import { generatePath, useHistory } from 'react-router-dom'
import {
  ContractPaymentRecipient,
  DebitTransaction,
  Maybe,
  useGetTransactionDetailsLazyQuery,
  Currency,
} from '../../../../graphql'
import { currentStepVarPay } from '../../../../graphql/local/dashboard'
import { APP_PATHS, PATH_PARAMS } from '../../../../routes/paths'
import { historyPaymentType } from '../../../../types'

export const useRepeatPayment = (trxId: Maybe<string | number> | undefined) => {
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const [GetTransactionDetailsQuery] = useGetTransactionDetailsLazyQuery()

  return useCallback(
    async (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (!trxId) {
        return
      }
      const { data } = await GetTransactionDetailsQuery({
        variables: {
          id: trxId,
        },
      })

      const recipientIban = (data?.transaction as DebitTransaction)?.to?.iban

      const recipient: ContractPaymentRecipient = {
        __typename: 'ContractPaymentRecipient',
        id: 'repeatPayment',
        type: (data?.transaction as DebitTransaction).to?.type,
        firstName: (data?.transaction as DebitTransaction).to?.name,
        lastName: '',
        companyName: (data?.transaction as DebitTransaction).to?.name,
        iban: !recipientIban ? null : recipientIban,
        accountNo: recipientIban ? null : (data?.transaction as DebitTransaction).to?.accountNo,
        currency: data?.transaction?.payoutCurrency as Maybe<Currency> | undefined,
        bankBic: (data?.transaction as DebitTransaction).to?.bankBic,
        address: {
          __typename: 'Address',
          country: (data?.transaction as DebitTransaction).to?.address?.country,
          city: (data?.transaction as DebitTransaction).to?.address?.city,
          zip: (data?.transaction as DebitTransaction).to?.address?.zip,
          line1: (data?.transaction as DebitTransaction).to?.address?.line1,
        },
      }
      currentStepVarPay(0)

      const amountFromData = Math.abs(toNumber(data?.transaction?.value)).toFixed(2)?.toString()
      const payoutAmountFromData = data?.transaction?.payoutValue
        ? Math.abs(toNumber(data?.transaction?.payoutValue)).toFixed(2)?.toString()
        : data?.transaction?.payoutValue || undefined

      const locationState: historyPaymentType = {
        account: data?.transaction?.account?.id,
        amount: amountFromData,
        payoutAmount: payoutAmountFromData,
        clientReference: data?.transaction?.clientReference,
        reference: data?.transaction?.comment,
        recipient,
        chargeBearer: data?.transaction?.chargeBearer,
      }

      localStorage.setItem('selectedAccountId', String(data?.transaction?.account?.id))

      applicationId &&
        data &&
        history.push(generatePath(APP_PATHS.dashboard.newPayment, { applicationId }), locationState)
    },
    [history, applicationId, GetTransactionDetailsQuery],
  )
}
