import * as yup from 'yup'
import i18n from '../i18n'
import { isEmpty } from 'lodash'

import {
  MAX_DOCUMENT_NAME_LENGTH,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_SLASH_REGEXP,
  NUMBER_AND_LATINICA_ONLY_MESSAGE_DOCUMENT,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../constants/validations'
import { DocumentTypeTransactionInputSchema } from './common/DocumentTypeTransaction'

export const UploadTransactionDocumentSchema = yup
  .object()
  .shape({
    transactionDocumentRequired: yup.mixed().notRequired(),
    individualRequired: yup.mixed().notRequired(),
    accountValue: yup
      .string()
      .test(
        'accountValueRequired',
        i18n.t(
          'accountValueRequiredMessage',
          'This field is required. If there’s no related account, choose this option in the list',
        ),
        function () {
          return !isEmpty(this.parent.accountValue)
        },
      ),
    transactionValue: yup
      .string()
      .test(
        'transactionValueRequired',
        i18n.t(
          'transactionValueRequiredValue',
          'This field is required. If there’s no related payment, choose this option in the list',
        ),
        function () {
          return !!this.parent.transactionValue
        },
      ),
    individualValue: yup
      .string()
      .test(
        'individualValueRequired',
        i18n.t(
          'relatedPersonValueRequired',
          'This field is required. If there’s no related person, choose this option in the list',
        ),
        function () {
          return !isEmpty(this.parent.individualValue) || !this.parent.individualRequired
        },
      ),
    documentName: yup
      .string()
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
      .test(
        'minLength',
        NUMBER_AND_LATINICA_ONLY_MESSAGE_DOCUMENT,
        (val) => !!val && val?.length >= MIN_LENGTH && val?.length <= MAX_DOCUMENT_NAME_LENGTH,
      )
      .matches(NUMBER_AND_LATINICA_AND_SLASH_REGEXP, NUMBER_AND_LATINICA_ONLY_MESSAGE_DOCUMENT),
  })
  .concat(DocumentTypeTransactionInputSchema)

export type UploadNewDocumentInput = yup.Asserts<typeof UploadTransactionDocumentSchema>
