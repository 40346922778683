import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ReactComponent as InformationIcon } from '../../../assets/images/icons/info_icon.svg'
import { useGetContractLegalEntityQuery } from '../../../graphql'
import { AlertTipItem, Loader } from '../../Common'

export const AccountOpenAlert: React.FC<{ className: string; contractId: number }> = ({
  className,
  contractId,
}) => {
  const { t } = useTranslation()
  const { data, error: errorGetContractLegalEntity, loading } = useGetContractLegalEntityQuery({
    variables: { contractId },
  })

  if (loading) return <Loader />

  if (errorGetContractLegalEntity) {
    toast.error(t('errorFetchingData', 'Error fetching data'))
    // eslint-disable-next-line no-console
    console.log(errorGetContractLegalEntity.message)
    return null
  }

  const owner = data?.contract?.owner
  const name = owner && 'companyName' in owner ? owner.companyName : undefined

  if (!name) return null

  return (
    <Box className={className}>
      <AlertTipItem
        value={t(
          'weWillOpenAccounts',
          'By default, we will provide you with one corporate expense account in Swiss Francs (CHF) and in Euros (EUR) for {{name}}. If additional accounts are required, you may request them via your Dashboard after activation. You may also contact your Account Manager or accounts@klarpay.com. Please note: As a Swiss financial institution, CHF is the base currency for all our clients.',
          { name },
        )}
        iconComponent={<InformationIcon />}
        type={'primary'}
      />
    </Box>
  )
}
