import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty, map } from 'lodash'

import { Box, FormControlLabel, RadioGroup, makeStyles } from '@material-ui/core'

import { AuthPersonsSelectType } from '../../../types'
import { YesNoOptions } from '../../../utils/Data'
import { BlackRadio, GridRow } from '../Fields'
import { FormAutocompleteSelect } from './FormAutocompleteSelect'
import {
  ContractUbo,
  Individual,
  LegalEntity,
  UboOwner,
  useContractIndividualOwnersQuery,
  useGetAuthorizedPersonsDataQuery,
  useGetDirectorsQuery,
  useGetUboOwnersDetailsLazyQuery,
} from '../../../graphql'
import { Loader } from '../Loader'
import { PATH_PARAMS } from '../../../routes/paths'

const useStyles = makeStyles(() => ({
  selectMargin: {
    marginBottom: '20px',
  },
}))

export const UboRadioComboSelect: FC<{
  ownersList: AuthPersonsSelectType[]
  setOwnersList: React.Dispatch<React.SetStateAction<AuthPersonsSelectType[]>>
  formMode: string
  setFormMode: React.Dispatch<React.SetStateAction<string>>
  user: Individual
}> = ({ ownersList, setOwnersList, formMode, setFormMode, user }) => {
  const [userIsUBO, setUserIsUBO] = useState(false)

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.uboId]: uboId,
  } = useParams() as Record<string, string>

  const classes = useStyles()
  const { t } = useTranslation()
  const { setValue, watch, reset } = useFormContext()

  const authorizedPerson = watch('authorizedPerson')

  const { data: personsData } = useGetAuthorizedPersonsDataQuery({
    variables: { id: +applicationId },
  })
  const { data: directorsData } = useGetDirectorsQuery({
    variables: { id: +applicationId },
  })
  const { data: ownersData } = useContractIndividualOwnersQuery({
    variables: {
      id: +applicationId,
    },
  })

  const [GetUboOwners, { data: uboOwnersData }] = useGetUboOwnersDetailsLazyQuery({
    variables: {
      id: uboId,
    },
  })

  const handleFormMode = useCallback(
    (event) => {
      reset()
      const value = event.target.value
      setFormMode(value)
    },
    [setFormMode, reset],
  )

  useEffect(() => {
    const apList = personsData?.contract?.authorizedPersons
    const directorsList = (directorsData?.contract?.owner as LegalEntity)?.legalEntityDirectors

    const ownersList: AuthPersonsSelectType[] = []
    if (ownersData?.contract && !isEmpty(apList)) {
      ownersList.push(
        ...(apList || []).map((authorizedPerson) => {
          const { id, firstName, lastName } = authorizedPerson?.person as Individual
          return {
            key: id,
            label: `${firstName} ${lastName}`,
            isAuthorizedSignatory: `${authorizedPerson?.isAuthorizedSignatory}`,
          }
        }),
      )
    }
    if (ownersData?.contract && !isEmpty(directorsList)) {
      ownersList.push(
        ...(directorsList || [])
          .map((director) => {
            const { id, firstName, lastName } = director?.individual as Individual
            return {
              key: id,
              label: `${firstName} ${lastName}`,
              isAuthorizedSignatory: 'false',
            }
          })
          .filter((director) => !map(ownersList, 'key').includes(director.key)),
      )
    }
    setOwnersList(ownersList)
    if (ownersData?.contract && !isEmpty(ownersData?.contract?.ubos)) {
      let uboPersonsList: UboOwner[] | ContractUbo[] = (ownersData.contract
        .ubos as ContractUbo[]).filter((contractUbo) => {
        const uboEntity = contractUbo.ubo?.entity
        return uboEntity?.__typename === 'Individual'
      })
      if (uboId) {
        GetUboOwners()
        if (uboOwnersData?.getUboOwners) {
          const filteredList = (uboOwnersData.getUboOwners as UboOwner[]).filter((uboOwner) => {
            const uboEntity = uboOwner.ubo?.entity
            return uboEntity?.__typename === 'Individual'
          })
          uboPersonsList = [...filteredList]
        }
      }

      if (user && !isEmpty(ownersList)) {
        const uboIndividualsIds = uboPersonsList.map((ubo) => {
          return (ubo as ContractUbo | UboOwner).ubo?.entity?.id
        })
        setUserIsUBO(uboIndividualsIds.includes(user.id))
        const excludeIdsList = new Set([user.id, ...uboIndividualsIds])

        setOwnersList(ownersList.filter((owner) => !excludeIdsList.has(Number(owner.key))))
      }
    }
  }, [ownersData?.contract?.ubos, personsData, user, uboOwnersData, directorsData])

  useEffect(() => {
    if (formMode === 'existing' && authorizedPerson) {
      let personData = personsData?.contract?.authorizedPersons?.find(
        (val) => authorizedPerson === val?.person?.id,
      )?.person
      if (!personData) {
        personData = (directorsData?.contract?.owner as LegalEntity)?.legalEntityDirectors?.find(
          (val) => authorizedPerson === val?.individual?.id,
        )?.individual
      }

      setValue('firstName', personData?.firstName)
      setValue('lastName', personData?.lastName)
      setValue('country', personData?.address?.country)
      setValue('birthday', personData?.birthday)
      setValue('streetAddress', personData?.address?.line1)
      setValue('additionalDetailsOptional', personData?.address?.additionalDetails)
      setValue('postalCode', personData?.address?.zip)
      setValue('city', personData?.address?.city)
      setValue('nationality', personData?.nationality)
      setValue('tin', personData?.taxId)
      setValue(
        'politicallyExposedPerson',
        personData?.isPep ? YesNoOptions[0].key : YesNoOptions[1].key,
      )
      setValue('USReportablePerson', personData?.taxId ? YesNoOptions[0].key : YesNoOptions[1].key)
    }
  }, [authorizedPerson, personsData, directorsData])

  if (!ownersData || !personsData) return <Loader />

  return (
    <>
      {!(userIsUBO && isEmpty(ownersList)) && (
        <>
          <RadioGroup name="formMode" value={formMode} onChange={handleFormMode}>
            <Box mb={1.75} hidden={isEmpty(ownersList)}>
              <FormControlLabel
                value="existing"
                control={<BlackRadio size="small" />}
                label={t('addExistingAuthorizedPerson', 'Add existing authorized person')}
              />
            </Box>
            <Box mb={1.75} data-test="addNewPerson">
              <FormControlLabel
                value="new"
                control={<BlackRadio size="small" />}
                label={t('addNewPerson', 'Add new person')}
              />
            </Box>
            <Box mb={1.75} hidden={userIsUBO} data-test="iAmAddingMyself">
              <FormControlLabel
                value="myself"
                control={<BlackRadio size="small" />}
                label={t('iAmAddingMyself', 'I am adding myself')}
              />
            </Box>
          </RadioGroup>

          <GridRow>
            <Box hidden={formMode !== 'existing'} className={classes.selectMargin}>
              <FormAutocompleteSelect
                name="authorizedPerson"
                label={t('authorizedPersonDirector', 'Authorized person or director')}
                data={ownersList}
              />
            </Box>
          </GridRow>
        </>
      )}
    </>
  )
}
