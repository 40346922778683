import React, { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'

import {
  Action,
  ActionType,
  CreditTransaction,
  DebitTransaction,
  Maybe,
  SwiftTransferRecipientType,
  TransactionAction,
  TransactionEntryType,
  TransactionExecutionType,
  TransactionParty,
  TransactionPriorityType,
} from '../../graphql'
import { getSignatures, TransferProps } from './contract'
import { GoBackButton } from '../../components'
import { TransferTab } from './Tabs/TransferTab'
import { SignatureAlert } from './Tabs/helpers'
import { useDetailsStyles } from './useDetailsStyles'
import { DetailsFooter } from './DetailsFooter'
import { addressToString } from '../../utils'

export const TransferPage: FC<{
  actionDetails: Action
  signedCount: number
  handleBack: () => void
}> = ({ actionDetails, signedCount, handleBack }) => {
  const classes = useDetailsStyles()

  const { transaction } = actionDetails as TransactionAction
  let transactionParty: TransactionParty | undefined | null
  let title: string | undefined = undefined
  let recipientType: Maybe<SwiftTransferRecipientType> | undefined
  switch (transaction?.entryType) {
    case TransactionEntryType.Debit:
      transactionParty = (transaction as DebitTransaction).to
      recipientType = (transaction as DebitTransaction).to?.type
      title = 'Transfer to '
      break
    case TransactionEntryType.Credit:
      transactionParty = (transaction as CreditTransaction).from
      recipientType = (transaction as CreditTransaction).from?.type
      title = 'Transfer from '
      break
  }
  const transferProps: TransferProps = {
    rawData: actionDetails,
    signatures: getSignatures(actionDetails?.signatures),
    txData: {
      title: title + (transactionParty?.name || ''),
      referenceNumber: transaction?.id || '',
      amount: transaction?.value?.toFixed(2) || undefined,
      currency: transaction?.currency || undefined,
      beneficiary: transactionParty?.name || undefined,
      address: addressToString(transactionParty?.address), // TODO: where to get address from?
      bank: transactionParty?.bankName || undefined,
      iban: transactionParty?.iban || transactionParty?.accountNo || undefined,
      swift: transactionParty?.bankBic,
      accId: transaction?.account?.id as string,
      trxId: transaction?.id as string,
      recipientType,
      status: transaction?.status,
      accountName: transaction?.account?.alias,
      comment: transaction?.comment as string,
      additionalComment: transaction?.additionalComment as string,
      isLocal: transaction?.isLocal as boolean,
      createdAt: transaction?.createdAt,
      executionType: transaction?.executionType as TransactionExecutionType,
      priority: transaction?.priority as TransactionPriorityType,
    },
  }

  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12} md={10}>
        <Grid item container className={classes.head}>
          <GoBackButton handleBack={handleBack} />
          <Typography variant="h2" className={classes.title}>
            {transferProps.txData?.title || 'Transfer'}
          </Typography>
        </Grid>

        <SignatureAlert signatures={transferProps.signatures} />
        <TransferTab transfer={transferProps} signedCount={signedCount} />
        <DetailsFooter
          actionSignatureProps={transferProps}
          handleBack={handleBack}
          type={ActionType.TransactionSigning}
        />
      </Grid>
    </Grid>
  )
}

export default TransferPage
