import React, { FC, MouseEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import clsx from 'clsx'

import { Box, Checkbox, Grid, makeStyles, Theme } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { Action } from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'
import { ActionsPageStateType, OperationNames } from '../../../types'
import { dateFormat } from '../../../utils'
import { SignatureStatus } from '../Tabs/helpers'
import { actionAmount, convertActionType, docDescriptionOrActionType } from '../contract'
import { useDetermineUserRights, useHandleActionDetails, useHandleCheckBoxes } from '../../../hooks'

const useStyles = makeStyles((theme: Theme) => ({
  mobileTableRow: {
    fontFamily: 'Arial',
    fontSize: 14,
    marginBottom: theme.spacing(1),
    '&:not(:first-child)': {
      borderTop: '1px solid rgba(0,0,0,.1)',
    },
    '&.rejected': {
      backgroundColor: '#FFEFED',
    },
    '&.signed': {
      backgroundColor: '#F1FBF7',
    },
    '&.checked': {
      backgroundColor: '#EFF3FE',
    },
  },
  mobileDoubleItem: {
    display: 'flex',
    flexDirection: 'row',
    '&>div:first-child': {
      paddingRight: theme.spacing(1),
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
  checkbox: {
    alignSelf: 'center',
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
  actionName: {
    textDecoration: 'underline',
  },
}))

const ActionsMobileComponent: FC<{
  data: Action
  handleSign: (data: Action, operation: OperationNames | null) => void
  handleActionsForSign: (actionData: Action, check: boolean) => void
  filtersState: ActionsPageStateType
  checkedActions: Action[]
}> = ({ data, handleSign, handleActionsForSign, filtersState, checkedActions }) => {
  const [checked, disableCheck, handleCheck] = useHandleCheckBoxes(
    data,
    checkedActions,
    handleActionsForSign,
  )
  const { handleDetails } = useHandleActionDetails(data, filtersState)
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isLimitedAccessUser = !!userRights?.limitedAccessRight

  const disableClickPropagation = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }, [])

  const { executedAt, type, signatures, createdAt } = data

  return (
    <Box
      className={clsx(
        classes.mobileTableRow,
        `${signatures?.[0]?.status}`,
        `${checked && !disableCheck && 'checked'}`,
      )}
      onClick={handleDetails}
    >
      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          {!isLimitedAccessUser && (
            <Grid item xs={2} className={classes.checkbox}>
              <Checkbox
                checked={disableCheck ? false : checked}
                icon={<Unchecked />}
                checkedIcon={<Checked />}
                onChange={handleCheck}
                onClick={disableClickPropagation}
                disabled={disableCheck}
                classes={{ disabled: classes.disabledCheckbox }}
              />
            </Grid>
          )}

          <Grid item xs={isLimitedAccessUser ? 12 : 10}>
            <Box className={classes.mobileTableLabel}>{t('document', 'Document')}</Box>
            <Box className={clsx(classes.actionName)}>{docDescriptionOrActionType(data)}</Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('type', 'Type')}</Box>
            <Box>{type && convertActionType(type)}</Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
            <Box>{actionAmount(data.__typename, data)}</Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('added', 'Added')}</Box>
            <Box>{dateFormat(createdAt)}</Box>
          </Grid>

          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('actionTaken', 'Action taken')}</Box>
            <Box>{executedAt ? dateFormat(executedAt) : '-'}</Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.mobileTableItem}>
        <SignatureStatus data={data} handleSign={handleSign} />
      </Box>
    </Box>
  )
}

export const ActionsMobile = React.memo(ActionsMobileComponent, isEqual)
