import React, { useCallback, useState, useEffect } from 'react'
import { filter, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  LegalEntity,
  ProcessTransferContractOwnershipMutationVariables,
  useProcessTransferContractOwnershipMutation,
} from '../../../graphql'
import { useCurrentUser } from '../../../hooks'
import { isModalContractTransferOpen, isModalDialogOpen } from '../../../components'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { getModalStyle } from '../../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalBody: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
  btnClose: {
    minWidth: 'auto',
    minHeight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
  },
  textField: {
    width: '100%',
  },
}))

export const TransferContractModal: React.FC = () => {
  const classes = useStyles()
  const user = useCurrentUser()
  const { t } = useTranslation()
  const [modalStyle] = useState(getModalStyle)
  const isOpen = useReactiveVar(isModalContractTransferOpen)

  const [selectedTransfers, setSelectedTransfers] = useState<string[]>([])

  const [
    processTansferContractOwnershipMutation,
    { loading: mutationUpdateLoading },
  ] = useProcessTransferContractOwnershipMutation()

  const handleClose = useCallback(() => {
    isModalContractTransferOpen(false)
  }, [isModalContractTransferOpen])

  const onAccept = useCallback(async () => {
    isModalContractTransferOpen(false)
    for (const transfer of selectedTransfers) {
      await processTansferContractOwnershipMutation({
        variables: {
          contractOwnershipTransferId: transfer,
        } as ProcessTransferContractOwnershipMutationVariables,
      })
    }
    window.location.reload()
  }, [selectedTransfers])

  const onSelectTransfer = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      setSelectedTransfers(
        event.target.checked
          ? [...selectedTransfers, id]
          : filter(selectedTransfers, (t) => t !== id),
      )
    },
    [selectedTransfers, setSelectedTransfers],
  )

  useEffect(() => {
    return () => {
      isModalDialogOpen(false)
      isModalContractTransferOpen(false)
    }
  }, [])

  return (
    <Modal open={!!isOpen} onClose={() => handleClose()}>
      <Box style={modalStyle} className={classes.modal}>
        <Box className={classes.modalHeader}>
          <Typography variant="h4" className={classes.title}>
            {t('pendingApplicationInvitation')}
          </Typography>
          <Button onClick={() => handleClose()} className={classes.btnClose}>
            <CloseIcon fontSize="small" />
          </Button>
        </Box>

        <Box className={classes.modalBody}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('companyName', 'Company Name')}</TableCell>
                  <TableCell>{t('from', 'From')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(user?.pendingContractOwnershipTransfers ?? [])?.map((value, index) => {
                  return (
                    !isEmpty(value) && (
                      <TableRow key={index}>
                        <TableCell>
                          <Box>
                            <Checkbox
                              icon={<Unchecked />}
                              checkedIcon={<Checked />}
                              onChange={(event) => onSelectTransfer(event, value.id as string)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>{(value.contract?.owner as LegalEntity).companyName}</TableCell>
                        <TableCell>{value.fromIndividual?.fullName}</TableCell>
                      </TableRow>
                    )
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box className={classes.modalFooter}>
          <Button
            variant="contained"
            disableElevation
            color="default"
            onClick={() => handleClose()}
          >
            {t('cancel', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => onAccept()}
            disabled={mutationUpdateLoading || isEmpty(selectedTransfers)}
          >
            {t('accept')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
