import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { IIdleTimer, useIdleTimer } from 'react-idle-timer'
import { useLocation } from 'react-router-dom'
import { ACTIVE_SESSION_EVENTS, IDLE_SESSION_TIMEOUT } from '../../constants'
import { NonAuthenticatedRoutes } from '../../routes/AppRoutes'
import { APP_PATHS } from '../../routes/paths'
import { LogOutModal } from './LogOutModal'
import { useLogOut } from '../../hooks'
import { useGetViewerLazyQuery } from '../../graphql'

const TIMER_MESSAGES = {
  TOKEN_REFRESHED: 'tokenRefreshed',
}

export const LogOutByTime: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation()

  const logOutUser = useLogOut()

  const [getViewerQuery] = useGetViewerLazyQuery()

  const [showModal, setShowModal] = useState<boolean>(false)

  const currentPath = location.pathname

  const isOnAuthenticatedPage = useMemo(
    () =>
      !NonAuthenticatedRoutes.some(({ path }) => path === currentPath) &&
      !currentPath.includes(APP_PATHS.auth.root),
    [NonAuthenticatedRoutes, currentPath],
  )

  const onIdle = useCallback(
    (idleTimer: IIdleTimer) => {
      if (isOnAuthenticatedPage) {
        setShowModal(true)
      } else {
        logOutUser()
        idleTimer.pause()
      }
    },
    [isOnAuthenticatedPage],
  )

  const onMessage = useCallback((data: string, idleTimer: IIdleTimer) => {
    if (data === TIMER_MESSAGES.TOKEN_REFRESHED) {
      setShowModal(false)
      idleTimer.reset()
    }
  }, [])

  const idleTimer = useIdleTimer({
    onIdle: () => onIdle(idleTimer),
    onMessage: (data) => {
      onMessage(data, idleTimer)
    },
    timeout: IDLE_SESSION_TIMEOUT,
    events: ACTIVE_SESSION_EVENTS,
    leaderElection: true,
    name: 'kp-dash-idle-timer',
    crossTab: true,
    syncTimers: 1000,
  })

  const handleLogout = useCallback(() => {
    logOutUser()
    setShowModal(false)
    idleTimer.pause()
  }, [logOutUser, idleTimer])

  const handleContinueSession = useCallback(() => {
    getViewerQuery({ fetchPolicy: 'network-only' })
      .catch((error) => {
        if (error.message.includes('401')) {
          // eslint-disable-next-line no-console
          console.log('Already Logged Out')
        }
      })
      .then(() => {
        idleTimer.message(TIMER_MESSAGES.TOKEN_REFRESHED)
        idleTimer.reset()
      })
    setShowModal(false)
  }, [idleTimer])

  return (
    <>
      {children}
      <LogOutModal
        isIdle={showModal}
        onContinueSession={handleContinueSession}
        onLogOut={handleLogout}
      />
    </>
  )
}
