import { FC, default as React } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { LinkComponent } from './LinkComponent'

const useStyles = makeStyles(() => ({
  ico: {
    marginTop: '3px',
  },
  rowItem: {
    paddingTop: '10px',
  },
}))

export const HomeListItem: FC<{ text: string; path?: string }> = ({ text, path }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2} className={classes.rowItem}>
      <Grid item>
        <Box fontSize={14} className={classes.ico}>
          <ArrowForwardIos fontSize={'inherit'} />
        </Box>
      </Grid>
      <Grid item>
        <LinkComponent text={text} path={path} />
      </Grid>
    </Grid>
  )
}
