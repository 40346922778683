import { isEqual } from 'lodash'
import React, { FC, memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Hidden, List, Toolbar } from '@material-ui/core'

import {
  ActionSignatureStatus,
  LimitedAccessRight,
  useActionSignatureRequestsCountQuery,
  useBackOfficeNewDocsCountQuery,
  useCardsCountQuery,
  Individual,
} from '../../graphql'
import { actionsPageStateValue } from '../../graphql/local'
import { usePageFiltersSorting } from '../../hooks'
import { useTableSort } from '../../hooks/useCurrentTableSort'
import { FacilitationTabsEnum } from '../../types'
import { MainMenuItem } from './MainMenuItem'
import { ReactComponent as IconHome } from '../../assets/images/icons/home_2.svg'
import { ReactComponent as AccountsIcon } from '../../assets/images/icons/accounts-icon.svg'
import { ReactComponent as IconDevelopers } from '../../assets/images/icons/developers.svg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { ReactComponent as IconPencil } from '../../assets/images/icons/icon_pencil.svg'
import { ReactComponent as CompareArrows } from '../../assets/images/icons/compare_arrows.svg'
import { ReactComponent as BusinessCenter } from '../../assets/images/icons/business_center.svg'
import { ReactComponent as Payment } from '../../assets/images/icons/payment.svg'
import { ReactComponent as IconClients } from '../../assets/images/icons/people_alt.svg'
import { Footer } from '../Footer'
import { useMenuStyles } from './menuStyles'
import { CompanySelect } from '../Common'
import config from '../../config'
import { PaymentSection } from './PaymentSection'

const useStyles = makeStyles(() => ({
  logoMenu: {
    top: 0,
    padding: '22px 32px',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 999,
    width: 248,
  },
  list: {
    position: 'relative',
    '& > span': {
      width: '100%',
    },
  },
}))

const DashboardDrawerComponent: FC<{
  contractId: number | string | undefined
  setMobileOpen: (arg: boolean) => void
  user: Individual
  limitMode: boolean
  limitedAccessRight: LimitedAccessRight | null | undefined
  isIntroducerContractOwner: boolean
  hasCardPayment: boolean
}> = ({
  contractId,
  setMobileOpen,
  user,
  limitMode,
  limitedAccessRight,
  isIntroducerContractOwner,
  hasCardPayment,
}) => {
  const { t } = useTranslation()
  const classes = { ...useMenuStyles(), ...useStyles() }
  const location = useLocation()

  const { data: signaturesCount, refetch } = useActionSignatureRequestsCountQuery({
    variables: { contractId: contractId, statuses: [ActionSignatureStatus.Pending] },
  })

  const { data: docsCount } = useBackOfficeNewDocsCountQuery({
    ...(contractId ? { variables: { contractId: +contractId } } : {}),
  })

  const { data: cardsCount } = useCardsCountQuery({
    ...(contractId ? { variables: { contractId: +contractId } } : {}),
  })

  const { clearTablesSort } = useTableSort()
  const { resetSearchAndPageValues } = usePageFiltersSorting()

  const resetPages = useCallback(() => {
    resetSearchAndPageValues()
    clearTablesSort()
    localStorage.removeItem('guaranteedTab')
  }, [actionsPageStateValue, clearTablesSort])

  useEffect(() => {
    refetch().then()
  }, [location?.pathname, refetch])

  const contract = contractId ? user.contracts?.find((c) => c?.id === +contractId) : undefined
  const isIntroducingPartner = !!contract?.isIntroducingPartner

  const paymentFacilitatorEnabled =
    config.isPaymentFacilitatorEnabled ||
    (process.env.REACT_APP_PF_TEST_CONTRACTS || '').split(',').includes(String(contractId))

  return (
    <Box
      key={contractId}
      className={classes.drawerInner}
      onClick={() => {
        setMobileOpen(false)
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box onClick={resetPages}>
        <Hidden smDown>
          <Toolbar className={classes.logoMenu}>
            <img src={process.env.PUBLIC_URL + config.logo} alt={'company_logo'} />
          </Toolbar>
        </Hidden>

        <Box className={classes.backWrapp}>
          <CompanySelect contractId={contractId} name={`${user.firstName} ${user.lastName}`} />
        </Box>

        <List className={classes.list}>
          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) &&
            !isIntroducerContractOwner && (
              <>
                <MainMenuItem
                  icon={<IconHome />}
                  primary={t('home', 'Home')}
                  path={generatePath(APP_PATHS.dashboard.home, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  disabled={limitMode}
                  data-test="homePageNavItem"
                />

                <MainMenuItem
                  icon={<AccountsIcon />}
                  primary={t('accounts', 'Accounts')}
                  path={generatePath(APP_PATHS.dashboard.accounts, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  disabled={limitMode}
                />
              </>
            )}

          {isIntroducerContractOwner && (
            <MainMenuItem
              icon={<IconClients />}
              primary={t('home')}
              path={generatePath(APP_PATHS.dashboard.partnerManagementOverview, {
                [PATH_PARAMS.applicationId]: contractId,
              })}
              disabled={limitMode}
            />
          )}

          {![
            LimitedAccessRight.CardHolder,
            LimitedAccessRight.ViewOnly,
            LimitedAccessRight.PaymentFacilitationView,
          ].includes(limitedAccessRight as LimitedAccessRight) && (
            <MainMenuItem
              icon={<IconPencil />}
              countNum={signaturesCount?.actionSignatureRequestsCount || 0}
              primary={t('actions', 'Actions')}
              path={generatePath(APP_PATHS.dashboard.actions, {
                [PATH_PARAMS.applicationId]: contractId,
              })}
              detailsPath={APP_PATHS.dashboard.actionDetails}
            />
          )}

          {!isIntroducerContractOwner &&
            limitedAccessRight !== LimitedAccessRight.PaymentFacilitationView && (
              <MainMenuItem
                icon={<CompareArrows />}
                primary={t('transactions', 'Transactions')}
                path={generatePath(APP_PATHS.dashboard.transactions, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                disabled={limitMode}
                detailsPath={[APP_PATHS.dashboard.trxDetails, APP_PATHS.dashboard.cardTrxs]}
                data-test="drawer-transactions"
              />
            )}

          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) &&
            !isIntroducerContractOwner && (
              <MainMenuItem
                icon={<IconClients />}
                primary={t('beneficiaries', 'Beneficiaries')}
                path={generatePath(APP_PATHS.dashboard.clients, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                disabled={limitMode}
                detailsPath={APP_PATHS.dashboard.clientOverview}
              />
            )}

          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) && (
            <MainMenuItem
              icon={<BusinessCenter />}
              countNum={docsCount?.unreadDocumentsCount as number}
              primary={t('contractManagement', 'Contract management')}
              path={generatePath(APP_PATHS.dashboard.contractManagement, {
                [PATH_PARAMS.applicationId]: contractId,
                [PATH_PARAMS.contractManagementTab]: 0,
              })}
              detailsPath={APP_PATHS.dashboard.contractManagement}
              disabled={limitMode}
            />
          )}

          {(![LimitedAccessRight.ViewOnly, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) ||
            (!!cardsCount?.contractCardsCount &&
              limitedAccessRight === LimitedAccessRight?.ViewOnly)) &&
            !isIntroducerContractOwner && (
              <MainMenuItem
                icon={<Payment />}
                primary={t('cards', 'Cards')}
                path={generatePath(APP_PATHS.dashboard.cards, {
                  [PATH_PARAMS.applicationId]: contractId,
                  [PATH_PARAMS.cardsPageNum]: 1,
                })}
                disabled={limitMode}
                detailsPath={[APP_PATHS.dashboard.activateCard, APP_PATHS.dashboard.createCard]}
                data-test="cardPageNavItem"
              />
            )}

          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) &&
            !isIntroducerContractOwner && (
              <>
                <MainMenuItem
                  icon={<IconDevelopers />}
                  primary={t('developers', 'Developers')}
                  path={generatePath(APP_PATHS.dashboard.developers, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  disabled={limitMode}
                />
              </>
            )}
          {isIntroducingPartner &&
            !isIntroducerContractOwner &&
            limitedAccessRight !== LimitedAccessRight.PaymentFacilitationView && (
              <MainMenuItem
                icon={<IconClients />}
                primary={t('partnerManagement')}
                path={generatePath(APP_PATHS.dashboard.partnerManagementOverview, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                disabled={limitMode}
              />
            )}
          {paymentFacilitatorEnabled && hasCardPayment && (
            <MainMenuItem
              icon={<DashboardIcon />}
              primary={t('paymentFacilitator', 'Payment facilitation')}
              path={generatePath(APP_PATHS.dashboard.paymentFacilitator, {
                [PATH_PARAMS.applicationId]: contractId,
                [PATH_PARAMS.facilitationTab]: FacilitationTabsEnum.overview,
              })}
              detailsPath={[
                APP_PATHS.dashboard.paymentFacilitator,
                APP_PATHS.dashboard.paymentDetails,
              ]}
            />
          )}
        </List>
        {![
          LimitedAccessRight.ViewOnly,
          LimitedAccessRight.CardHolder,
          LimitedAccessRight.PaymentFacilitationView,
        ].includes(limitedAccessRight as LimitedAccessRight) &&
          !isIntroducerContractOwner && (
            <Hidden mdUp>
              <PaymentSection limitMode={limitMode} />
            </Hidden>
          )}
      </Box>
      <Box mt={2}>
        <Footer isMenu={true} />
      </Box>
    </Box>
  )
}

export const DashboardDrawer = memo(DashboardDrawerComponent, isEqual)
