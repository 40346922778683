import React, { FC } from 'react'
import Hidden from '@material-ui/core/Hidden'
import { PaymentContextComponent } from '../BatchPayment'

import { DashboardMenu } from '../MainMenu'
import { Footer } from '../Footer'
import { useLayoutStyles } from './layoutStyles'

export const DashboardLayout: FC = ({ children }) => {
  const classes = useLayoutStyles()

  return (
    <PaymentContextComponent>
      <div className={classes.wrap}>
        <DashboardMenu />
        <main className={classes.content} data-test="mainContainer">
          <Hidden mdUp>
            <div className={classes.drawerHeader} />
          </Hidden>
          {children}
          <Hidden mdUp>{<Footer isMenu={false} />}</Hidden>
        </main>
      </div>
    </PaymentContextComponent>
  )
}

export default DashboardLayout
