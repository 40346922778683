import React, { FC, useCallback, useState, useEffect } from 'react'
import { Grid, Link, Box, makeStyles, Typography, Button } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { Logo, FormTextField, FormPasswordField, ContainerWithImage } from '../Common'
import { rxiosLogin } from '../../resources'
import { CommonTipItem } from '../Common'
import { ReactComponent as IconConfirmed } from '../../assets/images/icons/confirmed.svg'
import { ReactComponent as IconAttention } from '../../assets/images/icons/attention.svg'
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info2.svg'
import { SignInPageInput, SignInPageInputSchema } from '../../schemes'
import { useGetViewerQuery } from '../../graphql'
import { idleSessionLoggedOff, resendDelay } from '../../graphql/local'
import { Response2FA_type } from '../../types'
import { focusKeyPressNext } from '../../utils'
import { useQuery } from '../../hooks'
import { useReactiveVar } from '@apollo/client'
import { TOTP_STRING_PARAM } from '../../constants'
import config from '../../config'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({
  formWrap: {
    // [theme.breakpoints.up('sm')]: {
    //   padding: '0 50px',
    // },
  },
  notAsterisk: {
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
  forgot: {
    marginTop: '1rem',
    marginBottom: '3rem',
    justifyContent: 'flex-start',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  linkWrap: {
    marginTop: 24,
    fontSize: '14px',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',

    '& a': {
      textDecoration: 'none',
      borderBottom: '1px solid',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
  info: {
    border: '1px solid #ADDEC9',
    '&>div': {
      padding: '5px 10px',
    },
  },
  infoAttention: {
    border: '1px solid #FED7D2',
    marginTop: 20,
    '&>div': {
      padding: '5px 10px',
    },
  },
  infoAlert: {
    border: '1px solid #FFD8B5',
    marginTop: 20,
    '&>div': {
      padding: '5px 10px',
    },
  },
}))

export const SignInPage: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<SignInPageInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(SignInPageInputSchema),
  })
  const { watch, formState } = methods
  const fieldEmail = watch('email')
  const fieldPassword = watch('password')
  const classes = useStyles()
  const [loginFail, setLoginFail] = useState(false)
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false)
  const [loginFailMessage, setLoginFailMessage] = useState('')
  const verifyEmail = sessionStorage.getItem('verifyToken') ?? ''
  const history = useHistory()
  const { data: userData } = useGetViewerQuery()
  const inviteContract = (history.location?.state as { inviteContract: string })?.inviteContract
  const returnPath = useQuery().get('return')
  const isIdleSession = useReactiveVar(idleSessionLoggedOff)

  const signInHandler = useCallback(async (formData: SignInPageInput) => {
    setIsLoginBtnDisabled(true)

    !isEmpty(verifyEmail) && sessionStorage.removeItem('verifyToken')

    rxiosLogin.post('login', formData).subscribe(
      (next) => {
        if ((next as Response2FA_type).requires2FA) {
          const {
            challengeId,
            resendTimeout,
            phoneLastFourDigits,
            twoFaType,
          } = next as Response2FA_type
          sessionStorage.setItem('challengeId', `${challengeId}`)
          resendDelay(resendTimeout * 1000)
          const phoneDigits = phoneLastFourDigits ? phoneLastFourDigits.slice(2) : TOTP_STRING_PARAM
          history.push(
            generatePath(APP_PATHS.auth.twofa, {
              [PATH_PARAMS.twoFaType]: twoFaType,
              [PATH_PARAMS.phoneLastDigits]: phoneDigits,
            }),
            { inviteContract },
          )
        } else {
          !!inviteContract
            ? history.push(
                generatePath(APP_PATHS.application.onboarding.start, {
                  [PATH_PARAMS.applicationId]: inviteContract as string,
                }),
              )
            : history.push(APP_PATHS.root)
        }
      },
      (error) => {
        setLoginFail(true)
        setIsLoginBtnDisabled(false)
        if (error.response?.data?.code === 'ACCOUNT_TEMPORARILY_LOCKED') {
          setLoginFailMessage(
            t(
              'accountTemporarilyLocked',
              'Account temporarily locked due to too many failed 2FA attempts, please retry in {{minutes}} minutes',
              { minutes: Math.ceil(error.response?.data.retryIn / 60000) },
            ),
          )
        } else {
          setLoginFailMessage(t('logInFailed', 'Log in failed'))
        }
      },
    )
    idleSessionLoggedOff(false)
  }, [])
  const handlerEnter = useCallback(
    (ev, fState) => {
      return focusKeyPressNext(ev, fState)
    },
    [formState.isDirty],
  )

  const isAuthPage = history.location.pathname === APP_PATHS.auth.login

  useEffect(() => {
    if (!isAuthPage && !inviteContract && userData?.viewer?.__typename === 'Individual') {
      idleSessionLoggedOff(false)
      returnPath ? history.push(returnPath) : history.push(APP_PATHS.root)
    }
  }, [userData, returnPath, idleSessionLoggedOff, isAuthPage])

  return (
    <ContainerWithImage image={config.images.signIn} isRightMenu={false} classWrapper="content">
      <Grid item xs={12} className={classes.formWrap}>
        <Grid item md={12} className="logo">
          <Logo />
        </Grid>
        <Box display="flex" justifyContent="center" mb={7}>
          <Typography variant={'h2'}>{t('welcome', 'Welcome')}!</Typography>
        </Box>

        {!isEmpty(verifyEmail) && (
          <Grid item xs={12} className={classes.info}>
            <CommonTipItem
              value={t(
                'yourEmailWasConfirmedSuccessfullyPleaseLogIn.',
                'Your email was confirmed successfully. Please log in.',
              )}
              iconComponent={<IconConfirmed />}
            />
          </Grid>
        )}

        {isIdleSession && (
          <Grid item xs={12} className={classes.infoAlert}>
            <CommonTipItem
              value={t('youHaveBeenLoggedOut.', 'You have been logged out for security reasons.')}
              iconComponent={<IconInfo />}
              data-test="signinForm-loggedOutForSecurityReasons"
            />
          </Grid>
        )}

        {loginFail && (
          <Grid item xs={12} className={classes.infoAttention}>
            <CommonTipItem value={loginFailMessage} iconComponent={<IconAttention />} />
          </Grid>
        )}
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(signInHandler)}
            id="forNextFocus"
            onKeyDown={(event) => {
              handlerEnter(event, formState)
            }}
            data-test="signinForm"
          >
            <FormTextField
              label={fieldEmail?.length > 0 ? t('email', 'Email') : t('enterEmail', 'Enter email')}
              name="email"
              type="email"
              className={fieldEmail?.length > 0 ? classes.notAsterisk : ''}
              fullWidth={true}
              data-test="signinForm-email"
            />

            <FormPasswordField
              label={
                fieldPassword?.length > 0
                  ? t('password', 'Password')
                  : t('enterYourPassword', 'Enter your password')
              }
              name="password"
              className={fieldPassword?.length > 0 ? classes.notAsterisk : ''}
              fullWidth
              onChange={() => methods.trigger(`password`)}
              data-test="signinForm-pwd"
            />

            <Box className={classes.forgot}>
              <Link
                href={APP_PATHS.auth.forgotPassword}
                underline="always"
                data-test="signinForm-forgotPwdLink"
              >
                <span>{t('forgotPassword', 'Forgot password')}?</span>
              </Link>
            </Box>

            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              <Button
                id={'logInButton'}
                type="submit"
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                disabled={isLoginBtnDisabled || fieldPassword?.length < 1}
                data-test="signinForm-loginButton"
              >
                {t('logIn', 'Log In')}
              </Button>
              <Box className={classes.linkWrap}>
                {t('newToKlarpay', 'New to {{val1}}?', {
                  val1: config.name,
                })}{' '}
                <Link href={APP_PATHS.signup} data-test="signupLink">
                  <span>
                    <b>{t('signUp', 'Sign Up')}</b>
                  </span>
                </Link>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </ContainerWithImage>
  )
}

export default SignInPage
