import { withStyles } from '@material-ui/styles'
import { Radio, RadioProps } from '@material-ui/core'
import React from 'react'

export const BlackRadio = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />)
