import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { find, orderBy, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { makeStyles, Theme } from '@material-ui/core/styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import Box from '@material-ui/core/Grid'
import { Check } from '@material-ui/icons'
import {
  AppBar,
  Backdrop,
  Box as MBox,
  Fade,
  FormControl,
  FormControlLabel,
  Hidden,
  MenuItem,
  Modal,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'

import {
  BlackRadio,
  ConfirmationAnyModal,
  DateRangePicker,
  GridRow,
  LoadingButton,
} from '../../../components'
import { dateRangeType } from '../../../types'
import { accountStatusesForTransactionsShown } from '../../../utils/Data'
import { currencyFormat, getCurrencySign, sortAccountStatuses } from '../../../utils'
import { useDetailsStyles } from '../../AplicationActions/useDetailsStyles'
import { PeriodButtonsSection } from './PeriodButtonsSection'
import { PATH_PARAMS } from '../../../routes/paths'
import {
  Account,
  AccountStatementType,
  useDownloadAccountCancellationReturnsReportLazyQuery,
  useDownloadAccountStatementLazyQuery,
  useGetContractAccountsQuery,
} from '../../../graphql'

const useStyles = (currentAccount: Account | null) =>
  makeStyles((theme: Theme) => ({
    tabBar: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: '#F0F0F0',
      borderRadius: '4px',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0.5),
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
      '& .MuiTabs-root': {
        minHeight: 32,
      },
    },
    tab: {
      ...theme.typography.h6,
      flex: '0 0 50%',
      color: '#000',
      maxWidth: '100%',
      textTransform: 'none',
      borderRadius: '2px',
      minHeight: 32,
      padding: theme.spacing(0.5),
      '&.Mui-selected': {
        backgroundColor: 'white',
      },
    },
    accountSelect: {
      marginBottom: theme.spacing(5),
      '& .MuiAutocomplete-inputFocused': {
        visibility: currentAccount ? 'hidden' : 'visible',
      },
      '& .MuiTypography-root': {
        width: '92%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
          width: '85%',
        },
      },
    },
    selectedAcc: {
      transform: `translate(10px, 53px)`,
    },
    inputStyle: {
      '& #accountSelect': {
        visibility: 'visible',
      },
    },
    button: {
      height: 36,
      margin: theme.spacing(4, 0, 2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    radioGroup: {
      marginLeft: 15,
    },
    optionRow: {
      width: '100%',
      display: 'flex',
      gap: 8,
    },
    listItemCheck: {
      color: '#000000',
    },
    checkRow: {
      marginLeft: 'auto',
    },
    optionName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }))()

enum ExtractExportType {
  Statement = 'Statement',
  CancellationReturns = 'Cancellation/Returns',
}

enum ExportFormat {
  PDF = 'PDF',
  CSV = 'CSV',
}

export const DownloadStatementModal: FC<{
  isOpen: boolean
  handleClose: (success?: boolean) => void
  accountId: string | number | undefined
  children?: React.ReactNode
}> = ({ isOpen, handleClose, accountId }) => {
  const { t } = useTranslation()
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormat.PDF)
  const [exportType, setExportType] = useState<ExtractExportType>(ExtractExportType.Statement)
  const [searchValue, setSearchValue] = useState('')
  const [
    DownloadAccountStatementQuery,
    { error: statementDownloadError },
  ] = useDownloadAccountStatementLazyQuery()
  const [
    DownloadAccountCancellationsReportQuery,
    { error: cancellationReportsError },
  ] = useDownloadAccountCancellationReturnsReportLazyQuery()
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null)
  const [secretModal, setSecretModal] = useState(false)
  const [dateRange, setDateRange] = useState<dateRangeType>([null, null])

  const { [PATH_PARAMS.vbanId]: vbanId } = useParams() as Record<string, string>
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const classes = useStyles(currentAccount)
  const className = useDetailsStyles()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 410,
    bgcolor: 'background.paper',
    border: '2px solid grey',
    boxShadow: 24,
    p: 4,
  }
  const { data: accountsData, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: accountStatusesForTransactionsShown,
      search: searchValue,
      isVirtual: !!vbanId,
    },
  })

  const accountsList = useMemo(() => {
    const orderByAccounts = orderBy(
      accountsData?.accounts,
      ['balance.available'],
      ['desc'],
    ) as Account[]
    return sortBy(orderByAccounts, sortAccountStatuses) as Account[]
  }, [accountsData])

  const handleExportFormatChange = (
    _: React.SyntheticEvent<EventTarget>,
    newValue: ExportFormat,
  ) => {
    setExportFormat(newValue)
  }

  const handleExportTypeChange = (
    _: React.SyntheticEvent<EventTarget>,
    newValue: ExtractExportType,
  ) => {
    setExportType(newValue)
  }

  const handleExportTypeChangeMobile = useCallback((event) => {
    setExportType(event.target.value)
  }, [])

  const handleConfirm = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const [startDate, endDate] = dateRange
      if (startDate && endDate) {
        let report
        if (exportType === ExtractExportType.Statement) {
          const { data } = await DownloadAccountStatementQuery({
            variables: {
              id: currentAccount?.id as string,
              from: format(startDate, 'yyyy-MM-dd'),
              to: format(endDate, 'yyyy-MM-dd'),
              type:
                exportFormat === ExportFormat.PDF
                  ? AccountStatementType.Pdf
                  : AccountStatementType.Csv,
            },
          })
          report = data?.report
        } else {
          const { data } = await DownloadAccountCancellationsReportQuery({
            variables: {
              id: currentAccount?.id as string,
              from: format(startDate, 'yyyy-MM-dd'),
              to: format(endDate, 'yyyy-MM-dd'),
            },
          })
          report = data?.report
        }

        if (report) {
          if (report?.async) {
            handleOpenSecretModal()
          } else {
            const downloadLink = window.document.createElement('a')
            downloadLink.setAttribute('target', '_blank')
            downloadLink.href = report?.result as string
            downloadLink.click()
            handleClose()
          }
        }
      } else {
        toast.error(t('notSelectedDateRange', 'Date range is not selected'))
      }
    } catch (e) {
      toast.error((e as Error).message)
    }
  }, [
    dateRange,
    DownloadAccountStatementQuery,
    accountId,
    exportFormat,
    t,
    handleClose,
    currentAccount,
  ])

  const renderOption = ({ currency, balance, alias, id }: Account) => {
    const availableBalance = getCurrencySign(currency) + currencyFormat(balance?.available || 0)
    return (
      <>
        <MenuItem className={classes.optionRow}>
          <Typography
            variant={'body1'}
            className={classes.optionName}
          >{`${currency} ${alias}`}</Typography>
          <Typography variant={'body1'}>
            <b>{availableBalance}</b>
          </Typography>
          <Box className={classes.checkRow}>
            {currentAccount?.id === id && (
              <Check className={classes.listItemCheck} fontSize={'small'} />
            )}
          </Box>
        </MenuItem>
      </>
    )
  }

  const getOptionLabel = ({ currency, balance, alias }: Account) => {
    const availableBalance = currencyFormat(balance?.available || 0, currency)

    return `${currency} ${alias} ${availableBalance}`
  }

  const handleOpenSecretModal = useCallback(() => {
    setSecretModal(true)
  }, [])

  const handleCloseSecretModal = useCallback(() => {
    setSecretModal(false)
    handleClose()
  }, [])

  useEffect(() => {
    if (statementDownloadError) {
      toast.error(t('errorDownloadStatement', 'Error Download Statement'))
      toast.error(statementDownloadError.message)
    }
  }, [statementDownloadError, t])

  useEffect(() => {
    if (cancellationReportsError) {
      toast.error(t('errorDownloadReport', 'Error Download Report'))
      toast.error(cancellationReportsError.message)
    }
  }, [cancellationReportsError, t])

  useEffect(() => {
    const accountForSet = find(accountsList, ({ id }) => id === accountId)
    if (accountForSet) {
      setCurrentAccount(accountForSet)
    } else {
      setCurrentAccount(null)
    }
  }, [accountId, accountsList])

  useEffect(() => {
    if (!currentAccount) {
      setSearchValue('')
    }
  }, [currentAccount])

  useEffect(() => {
    if (exportType === ExtractExportType.CancellationReturns) {
      setExportFormat(ExportFormat.CSV)
    }
  }, [exportType])

  return (
    <ConfirmationAnyModal
      title={t('exportReport', 'Export Report')}
      name={'downloadStatement'}
      isOpen={isOpen}
      handleClose={handleClose}
      labelConfirm={t('export', 'Export')}
      handleConfirm={handleConfirm}
      disableConfirm={!currentAccount}
    >
      <Hidden xsDown>
        <AppBar position="static" className={classes.tabBar}>
          <Tabs value={exportType} onChange={handleExportTypeChange} indicatorColor="secondary">
            <Tab
              value={ExtractExportType.Statement}
              label={t('statement', 'Statement')}
              className={classes.tab}
            />
            <Tab
              value={ExtractExportType.CancellationReturns}
              label={t('cancellationReturns', 'Cancellation/Returns')}
              className={classes.tab}
            />
          </Tabs>
        </AppBar>
      </Hidden>

      <AppBar position="static" className={classes.tabBar}>
        <Tabs value={exportFormat} onChange={handleExportFormatChange} indicatorColor="secondary">
          <Tab
            value={ExportFormat.PDF}
            label="PDF"
            className={classes.tab}
            disabled={exportType === ExtractExportType.CancellationReturns}
          />
          <Tab value={ExportFormat.CSV} label="CSV" className={classes.tab} />
        </Tabs>
      </AppBar>

      <Hidden smUp>
        <FormControl component="td" className={classes.radioGroup}>
          <RadioGroup
            aria-label="ExportTypeSelect"
            name="processing"
            value={exportType}
            onChange={handleExportTypeChangeMobile}
          >
            <Box>
              <FormControlLabel
                value={ExtractExportType.Statement}
                control={<BlackRadio size="small" />}
                label={t('statement', 'Statement')}
                className={className.radio}
              />
            </Box>
            <Box>
              <FormControlLabel
                value={ExtractExportType.CancellationReturns}
                control={<BlackRadio size="small" />}
                label={t('cancellationReturns', 'Cancellation/Returns')}
                className={className.radio}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Hidden>

      <Box className={classes.accountSelect}>
        {currentAccount && (
          <Typography variant={'body1'} className={classes.selectedAcc}>
            {`${currentAccount.currency} ${currentAccount.alias} `}
            <b>
              {currencyFormat(currentAccount.balance?.available || 0, currentAccount?.currency)}
            </b>
          </Typography>
        )}

        <Autocomplete
          id="accountSelect"
          options={accountsList || []}
          loading={loading}
          getOptionLabel={getOptionLabel}
          value={currentAccount}
          className={vbanId ? classes.inputStyle : ''}
          onChange={(_, newValue: Account | null) => setCurrentAccount(newValue)}
          disabled={!!vbanId}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              label={'Select Account'}
            />
          )}
          popupIcon={<ExpandMoreIcon />}
        />
      </Box>

      <PeriodButtonsSection dateRange={dateRange} setDateRange={setDateRange} />

      <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={secretModal}
        onClose={handleCloseSecretModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={secretModal}>
          <MBox sx={style}>
            <Typography id="spring-modal-title" variant="h3" align={'center'} paragraph={true}>
              {t('statementByEmailTitle', 'Info')}
            </Typography>
            <Typography id="spring-modal-title" align={'justify'} paragraph={false}>
              {t(
                'statementByEmailText',
                'Due to the file size of the requested statement, the document will be provided via a link sent to your email shortly.',
              )}
            </Typography>
            <GridRow>
              <LoadingButton
                className={classes.button}
                loading={false}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleCloseSecretModal}
                disableElevation
              >
                {t('statementByEmailClose', 'Close')}
              </LoadingButton>
            </GridRow>
          </MBox>
        </Fade>
      </Modal>
    </ConfirmationAnyModal>
  )
}
