import { Box, Grid, Link, makeStyles } from '@material-ui/core'
import { AppActions, displayStatusKeys, statusActions } from '../../../constants'
import clsx from 'clsx'
import {
  BankableEntityContractType,
  ContractStatusType,
  ContractType,
  Individual,
  LegalEntity,
  UboLegalEntityType,
  UboType,
} from '../../../graphql'
import { ActionsMenu } from '../ActionsMenu'
import { isEmpty } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  applicationsList: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('md')]: {
        padding: '6px 4px',
      },
    },
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  listItem: {
    borderTop: '1px solid rgba(0,0,0,0.1)',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    marginBottom: 10,
  },
  itemName: {
    paddingLeft: 16,
    height: 48,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  linkName: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemAction: {
    justifyContent: 'end',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    paddingRight: 12,
  },
  itemStatusWrap: {
    paddingLeft: 16,
    height: 48,
  },
  statusWrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}))
const BusinessAppMobile: FC<{
  contracts: BankableEntityContractType[]
  actions: { [key in AppActions]: (contract: BankableEntityContractType) => void }
  updateFlag: (value: boolean) => void
  deleteApp: (value: string | number) => void
}> = ({ contracts, actions, deleteApp, updateFlag }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.applicationsList} data-test="applicationsList">
      {contracts &&
        contracts.map(
          (contract) =>
            contract && (
              <Grid
                container
                className={classes.listItem}
                key={contract.id}
                data-test="businessApplications"
              >
                <Grid
                  className={classes.itemName}
                  item
                  xs={10}
                  data-test={`${displayStatusKeys[contract.status as string]}`}
                >
                  <Link
                    component={'span'}
                    className={classes.linkName}
                    onClick={() =>
                      actions[statusActions[contract.status as ContractStatusType][0]](
                        contract as BankableEntityContractType,
                      )
                    }
                    underline="always"
                    title={t('name', 'Name')}
                  >
                    {contract?.ownerType === UboType.NaturalPerson
                      ? ((contract?.owner as Individual).fullName as string)
                      : ((contract?.owner as LegalEntity).companyName as string)}
                  </Link>
                </Grid>
                <Grid className={classes.itemAction} item xs={2}>
                  <ActionsMenu
                    updateFlag={updateFlag}
                    deleteApp={deleteApp}
                    status={contract.status as ContractStatusType}
                    contractId={contract.id}
                    hasAPs={!isEmpty(contract.authorizedPersons)}
                    hasUbo={!isEmpty(contract.ubos)}
                    hasPendingBusinessDetails={
                      (contract.owner as LegalEntity).legalEntityType ===
                        UboLegalEntityType.Unknown &&
                      contract.type !== ContractType.IntroducerReference
                    }
                    ownerType={contract.ownerType as UboType}
                    owner={contract.owner}
                  />
                </Grid>
                <Grid className={classes.itemStatusWrap} item xs={12}>
                  <Box className={classes.statusWrap}>
                    <Box
                      component="span"
                      className={clsx(
                        `roundedPill ${displayStatusKeys[contract.status as string]}`,
                      )}
                    >
                      {t(displayStatusKeys[contract.status as string])}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ),
        )}
    </Box>
  )
}

export default BusinessAppMobile
