import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Fab, Hidden } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = (whiteButton: boolean) =>
  makeStyles((theme) => ({
    goBack: {
      ...theme.typography.body1,
      color: '#000',
      lineHeight: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      minWidth: 40,
      minHeight: 40,
      padding: 0,
      borderRadius: '50%',
      backgroundColor: whiteButton ? '#FFFFFF' : '#F5F5F5',
      flex: '0 0 40px',
      boxShadow: 'none',
      alignSelf: 'flex-start',
      '&:active': {
        boxShadow: 'none',
      },
      '& .MuiTouchRipple-root': {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        height: 32,
        minHeight: 32,
        flex: '0 0 auto',
        borderRadius: 48,
        padding: theme.spacing(1, 2, 1, 1),
        minWidth: 82,
        marginBottom: theme.spacing(1),
      },
    },
    icon: {
      [theme.breakpoints.down('xs')]: {
        width: 11,
        height: 11,
        fill: '#595959',
      },
    },
    mobileText: {
      textTransform: 'none',
      marginLeft: 10,
      fontWeight: 'bold',
    },
  }))()

export const GoBackButton: FC<{ handleBack?: () => void; whiteButton?: boolean }> = ({
  handleBack,
  whiteButton = false,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles(whiteButton)

  return (
    <Fab
      className={classes.goBack}
      onClick={() => (handleBack ? handleBack() : history.goBack())}
      data-test="goBackButton"
    >
      <ArrowBackIcon className={classes.icon} fontSize="small" />
      <Hidden smUp>
        <Box className={classes.mobileText} component={'span'}>
          {t('back', 'Back')}
        </Box>
      </Hidden>
    </Fab>
  )
}
