import React, { FC, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: '24px',
    '& .MuiTypography-gutterBottom': {
      marginTop: '1em',
      marginBottom: 'unset',
    },
  },
  topMrg: {
    paddingTop: 30,
  },
}))

//TODO check usage of this component

export const VideoVerified: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const goToDigitalSignatures = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('go to digital signatures')
  }, [])

  return (
    <Box className={classes.root}>
      <Grid container spacing={3} className={classes.topMrg}>
        <Grid item xs={12}>
          <Typography variant={'h1'}>
            {t(
              'congratulationsYourWereSsuccessfullyVideoVerified',
              'Congratulations! Your were successfully video verified.',
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <p>
            {t(
              'continueToTheDigitalSignaturesPage',
              'Please continue to the digital signatures page.',
            )}
          </p>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            component="span"
            fullWidth
            onClick={goToDigitalSignatures}
          >
            {t('goToDigitalSignatures', 'Go to Digital Signatures')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
