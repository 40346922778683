import { Maybe } from 'graphql/jsutils/Maybe'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { AlertTipItem } from '../../../components'
import { AlertDataType, LimitsType, LimNameType, LimPeriod, LimPeriodType } from '../../../types'
import { ReactComponent as AttentionIcon } from '../../../assets/images/icons/attention.svg'
import { CardLimitInputType, CardStatuses, useGetDefaultCardLimitsQuery } from '../../../graphql'
import { limitsValueFormat, parseLimitsArr, parseMaxLimits } from '../../../utils'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { useDetermineUserRights } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { omit } from 'lodash'

const useStyles = makeStyles((theme) => ({
  modalView: {
    display: 'flex',
    //flexDirection: 'column-reverse',
    flexDirection: 'column',
    paddingBottom: 24,
  },
  table: {
    width: '100%',
    marginTop: 10,
    '&.modalTable.MuiTable-root': {
      borderTop: 'none',
    },
    '& .MuiTableHead-root': {
      background: '#F5F5F5',
    },
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '&.nonEdit .MuiTableCell-root ': {
      paddingLeft: 16,
    },
  },
  header: {
    paddingTop: 24,
    marginBottom: 12,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 20px 0',
    },
    '&.modalHeader': {
      marginBottom: 20,
    },
  },
  alertBox: {
    paddingBottom: 12,
  },
  modalAlert: {
    paddingTop: 12,
  },
  title: {
    paddingLeft: 8,
    alignSelf: 'center',
  },
  tabName: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  editButton: {
    minHeight: 32,
    minWidth: 59,
    fontSize: 14,
    lineHeight: '16px',
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    '&.modalBtnEdit': {
      minHeight: 32,
      minWidth: 83,
      fontSize: 14,
    },
  },
  btnGroup: {
    display: 'flex',
    '&.modalBtnGroup': {
      flexDirection: 'row-reverse',
    },
  },
  btnControl: {
    minHeight: 32,
    fontSize: 14,
    lineHeight: '16px',
    padding: theme.spacing(1, 2),
    '&.modalBtnControl': {
      marginRight: 12,
      minHeight: 32,
      fontSize: 14,
    },
  },
  btnCancel: {
    minHeight: 32,
    padding: theme.spacing(1, 2),
    marginRight: 16,
    fontSize: 14,
    lineHeight: '16px',
    border: 'none',
    backgroundColor: 'rgba(245, 245, 245, 1)',
  },
  limitInput: {
    minHeight: 0,
    '& .MuiInputBase-input': {
      height: 24,
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&.inputPadding .MuiInputBase-input': {
      paddingLeft: 8,
      border: '1px solid #F0F0F0',
    },
    '& .MuiInputBase-input:disabled': {
      color: 'black',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #276EF1',
    },
    '&.error .Mui-focused .MuiOutlinedInput-notchedOutline, &.error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EF2828',
    },
  },
  controlEditConf: {
    margin: theme.spacing(0, 0, 2, 1.2),
  },
}))

export const SpendingLimits: React.FC<{
  limits: LimitsType
  setLimits?: React.Dispatch<React.SetStateAction<LimitsType | undefined>>
  currency?: string
  setLimitsArr?: React.Dispatch<React.SetStateAction<CardLimitInputType[] | undefined>>
  modalView?: boolean
  saveLimits?: (newLimits: CardLimitInputType[]) => void
  setBtnDisabled?: React.Dispatch<React.SetStateAction<boolean>>
  editable?: boolean
  setSignNow?: React.Dispatch<React.SetStateAction<boolean>>
  signNow?: boolean
  status?: Maybe<CardStatuses> | undefined
}> = ({
  limits,
  setLimits,
  currency,
  setLimitsArr,
  modalView = false,
  saveLimits,
  setBtnDisabled,
  editable,
  setSignNow,
  signNow,
  status,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const [maxLimits, setMaxLimits] = useState<LimitsType | undefined>()
  const [alertData, setAlertData] = useState<AlertDataType>()
  const [errLimitName, setErrLimitName] = useState<string>()
  const [tempSavedLimits, setTempSavedLimits] = useState<LimitsType>()
  const [filteredLimits, setFilteredLimits] = useState<Partial<LimitsType>>({})
  const classes = useStyles()
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: contractId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(contractId)
  const currencyFormatOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  const { data: maxLimitsData } = useGetDefaultCardLimitsQuery({
    variables: {
      contractId,
    },
  })
  useEffect(() => {
    const defaultLimits =
      maxLimitsData?.cardsAllowedLimitsAndMaxes?.maxes &&
      parseMaxLimits(maxLimitsData?.cardsAllowedLimitsAndMaxes?.maxes)

    defaultLimits && setMaxLimits(defaultLimits)
  }, [maxLimitsData])

  useEffect(() => {
    setTempSavedLimits(limits)
  }, [])

  const handleEdit = useCallback(() => {
    setIsEdit(true)
    setBtnDisabled && setBtnDisabled(true)
    setErrLimitName(undefined)
    setAlertData(undefined)
    setLimits && setLimits(limits)
  }, [isEdit, limits, setLimits])

  const onCancel = useCallback(() => {
    setIsEdit(false)
    setBtnDisabled && setBtnDisabled(false)
    setErrLimitName(undefined)
    setAlertData(undefined)
    setLimits && setLimits(tempSavedLimits)
  }, [tempSavedLimits])

  const onSave = useCallback(() => {
    if (!!alertData) return
    const InternetLimitsArr = parseLimitsArr(limits, 'Internet')
    const ContactlessLimitsArr = parseLimitsArr(limits, 'Contactless')
    const WithdrawalsLimitsArr = parseLimitsArr(limits, 'Withdrawals')

    const newLimits = [...InternetLimitsArr, ...ContactlessLimitsArr, ...WithdrawalsLimitsArr]
    setIsEdit(false)
    setBtnDisabled && setBtnDisabled(false)
    setTempSavedLimits(limits)

    return modalView && saveLimits ? saveLimits(newLimits) : setLimitsArr && setLimitsArr(newLimits)
  }, [alertData, limits, modalView, saveLimits, setLimitsArr])

  const handleChangeLimits = useCallback(
    (event, limName: LimNameType, period: LimPeriodType) => {
      const value = parseInt(event.target.value.replaceAll(/[\D]/g, '')) || 0

      setAlertData(undefined)
      setErrLimitName(undefined)
      if (maxLimits && value > maxLimits[limName][period]) {
        setAlertData({
          text: t('limitOverMax', {
            limit: `${currency} ${maxLimits[limName][period].toLocaleString('ja-JP')}`,
          }),
          type: 'warning',
          icon: <AttentionIcon />,
        })
        setErrLimitName(`${limName}${period}`)
      } else if (
        (period === LimPeriod.Daily && value > limits[limName].monthly) ||
        (period === LimPeriod.Monthly && value < limits[limName].daily)
      ) {
        setAlertData({
          text: t('limitOverMonthly', '"Monthly limit can’t be less than daily limit'),
          type: 'warning',
          icon: <AttentionIcon />,
        })
        setErrLimitName(`${limName}${period}`)
        // } else if (
        //   (period === LimPeriod.Weekly && value < limits[limName].daily) ||
        //   (period === LimPeriod.Daily && value > limits[limName].weekly)
        // ) {
        //   setAlertData({
        //     text: t('limitOverWeekly'),
        //     type: 'warning',
        //     icon: <AttentionIcon />,
        //   })
        //   setErrLimitName(`${limName}${period}`)
        // } else if (
        //   (period === LimPeriod.Daily && value < limits[limName].transaction) ||
        //   (period === LimPeriod.Transaction && value > limits[limName].daily)
        // ) {
        //   setAlertData({
        //     text: t('limitOverDaily'),
        //     type: 'warning',
        //     icon: <AttentionIcon />,
        //   })
        //   setErrLimitName(`${limName}${period}`)
      }

      setLimits &&
        setLimits({
          ...limits,
          [limName]: {
            ...limits[limName],
            [period]: value,
          },
        })
    },
    [currency, limits, setLimits, t],
  )

  const handleSignNow = useCallback(() => {
    !!setSignNow && setSignNow(!signNow)
  }, [signNow, setSignNow])

  useEffect(() => {
    if (limits) {
      setFilteredLimits(omit(limits, 'Withdrawals'))
    } else {
      setFilteredLimits(limits)
    }
  }, [limits])

  return (
    <Box className={modalView ? classes.modalView : ''}>
      <Box className={clsx(classes.header, modalView && 'modalHeader')}>
        {/*{!modalView && (*/}
        {/*  <div className={classes.title}>*/}
        {/*    <Typography className={classes.tabName}>*/}
        {/*      {t('spendingLimits', 'Spending Limits')}*/}
        {/*    </Typography>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className={classes.title}>
          <Typography className={classes.tabName}>
            {editable
              ? t('editSpendingLimits', 'Edit spending limits')
              : t('spendingLimits', 'Spending limits')}
          </Typography>
        </div>
        <Box hidden={!editable || !currency}>
          {!isEdit ? (
            <Button
              className={clsx(classes.editButton, modalView && 'modalBtnEdit')}
              variant="contained"
              disableElevation
              color={modalView ? 'primary' : 'default'}
              onClick={handleEdit}
              disabled={status !== CardStatuses.Active}
            >
              {t('edit', 'Edit')}
            </Button>
          ) : (
            <Box className={clsx(classes.btnGroup, modalView && 'modalBtnGroup')}>
              <Button variant="outlined" className={classes.btnCancel} onClick={onCancel}>
                {t('cancel', 'Cancel')}
              </Button>

              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.btnControl, modalView && 'modalBtnControl')}
                onClick={onSave}
              >
                {t('save', 'Save')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {!!setSignNow && !userRights?.limitedAccessRight && (
        <Grid item xs={12} className={classes.controlEditConf}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox name="signEditRequest" icon={<Unchecked />} checkedIcon={<Checked />} />
              }
              onChange={handleSignNow}
              label={t('signChangeSpendingLimitsNow', 'Sign change spending limits now')}
            />
          </Box>
        </Grid>
      )}

      {alertData && (
        <Box className={modalView ? classes.modalAlert : classes.alertBox}>
          <AlertTipItem
            value={alertData.text}
            iconComponent={alertData.icon}
            type={alertData?.type}
            isCloseButton={false}
            isOpenAlert={!!alertData}
          />
        </Box>
      )}

      <TableContainer>
        <Table className={clsx(classes.table, modalView && 'modalTable', !editable && 'nonEdit')}>
          <TableHead>
            <TableRow>
              <TableCell width={'25%'}>{t('category', 'Category')}</TableCell>
              {/*<TableCell>{t('transactionPurchase', 'Transaction')}</TableCell>*/}
              <TableCell>{t('daily', 'Daily')}</TableCell>
              {/*<TableCell>{t('weekly', 'Weekly')}</TableCell>*/}
              <TableCell>{t('monthly', 'Monthly')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(filteredLimits).map((limitName, i) => {
              return (
                <TableRow key={`${limitName}+${i}`}>
                  <TableCell> {limitName} </TableCell>
                  <TableCell>
                    <TextField
                      disabled={!isEdit || (!!errLimitName && `${limitName}daily` !== errLimitName)}
                      className={clsx(
                        classes.limitInput,
                        `${limitName}daily` === errLimitName && 'error',
                        isEdit && 'inputPadding',
                      )}
                      variant="outlined"
                      type={'text'}
                      value={limitsValueFormat(
                        currency,
                        limits[limitName as LimNameType].daily,
                        currencyFormatOptions,
                      )}
                      onChange={(e) =>
                        handleChangeLimits(e, limitName as LimNameType, LimPeriod.Daily)
                      }
                    />
                  </TableCell>
                  {/*<TableCell>*/}
                  {/*  <TextField*/}
                  {/*    disabled={*/}
                  {/*      !isEdit || (!!errLimitName && `${limitName}weekly` !== errLimitName)*/}
                  {/*    }*/}
                  {/*    className={clsx(*/}
                  {/*      classes.limitInput,*/}
                  {/*      `${limitName}weekly` === errLimitName && 'error',*/}
                  {/*      isEdit && 'inputPadding',*/}
                  {/*    )}*/}
                  {/*    variant="outlined"*/}
                  {/*    type={'text'}*/}
                  {/*    value={limitsValueFormat(*/}
                  {/*      currency,*/}
                  {/*      limits[limitName as LimNameType].weekly,*/}
                  {/*      currencyFormatOptions,*/}
                  {/*    )}*/}
                  {/*    onChange={(e) =>*/}
                  {/*      handleChangeLimits(e, limitName as LimNameType, LimPeriod.Weekly)*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  <TableCell>
                    <TextField
                      disabled={
                        !isEdit || (!!errLimitName && `${limitName}monthly` !== errLimitName)
                      }
                      className={clsx(
                        classes.limitInput,
                        `${limitName}monthly` === errLimitName && 'error',
                        isEdit && 'inputPadding',
                      )}
                      variant="outlined"
                      type={'text'}
                      value={limitsValueFormat(
                        currency,
                        limits[limitName as LimNameType].monthly,
                        currencyFormatOptions,
                      )}
                      onChange={(e) =>
                        handleChangeLimits(e, limitName as LimNameType, LimPeriod.Monthly)
                      }
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
