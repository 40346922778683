import React, { FC } from 'react'
import { isEmpty } from 'lodash'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDetermineUserRights } from '../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { generatePath, NavLink } from 'react-router-dom'
import { FacilitationTabsEnum } from '../../types'
import { dashboardPassStatuses } from '../../utils/Data'
import {
  ActionSignatureStatus,
  BankableEntityContractType,
  ContractStatusType,
  LegalEntity,
  LimitedAccessRight,
  UboType,
  useActionSignatureRequestsCountQuery,
} from '../../graphql'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    '& .MuiPaper-rounded': {
      borderRadius: 8,
      borderColor: '#D9D9D9',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  navLink: {
    width: '100%',
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    textDecoration: 'none',
    padding: theme.spacing(1.5, 2),
    '&:hover': {
      '& .MuiSvgIcon-root': {
        fill: '#EF2828',
      },
      '& .MuiTypography-body1': {
        color: '#000',
      },
    },
  },
  item: {
    flex: '0 0 100%',
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
  },
  icon: {
    minWidth: 20,
    width: 20,
    height: 20,
    fill: '#ccc',
  },
  label: {
    fontSize: 16,
    color: '#595959',
    '&.MuiTypography-body1': {
      marginBottom: 0,
    },
  },
}))

const ContractLink: FC<{
  contract: BankableEntityContractType
}> = ({ contract }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: signaturesCount } = useActionSignatureRequestsCountQuery({
    variables: { contractId: contract.id, statuses: [ActionSignatureStatus.Pending] },
  })

  const { userRights } = useDetermineUserRights(contract.id as string | number)

  const specialContractStatuses = [
    ContractStatusType.FirstLinePending,
    ContractStatusType.AmloPending,
    ContractStatusType.SignaturesPending,
  ]

  if (contract?.status && specialContractStatuses.includes(contract?.status)) {
    return (
      <NavLink
        to={generatePath(APP_PATHS.application.status, {
          [PATH_PARAMS.applicationId]: contract.id,
        })}
        className={classes.navLink}
        data-test="firstLinePendingApp"
      >
        <Typography variant={'body1'} className={classes.label}>
          {contract?.ownerType === UboType.NaturalPerson
            ? t('personal')
            : ((contract?.owner as LegalEntity).companyName as string)}
        </Typography>
        <ArrowForwardIosIcon className={classes.icon} />
      </NavLink>
    )
  } else if (userRights?.limitedAccessRight === LimitedAccessRight.CardHolder) {
    return (
      <NavLink
        to={generatePath(APP_PATHS.dashboard.cards, {
          [PATH_PARAMS.applicationId]: contract.id,
          [PATH_PARAMS.cardsPageNum]: 1,
        })}
        className={classes.navLink}
      >
        <Typography variant={'body1'} className={classes.label}>
          {(contract?.owner as LegalEntity).companyName}
        </Typography>
        <ArrowForwardIosIcon className={classes.icon} />
      </NavLink>
    )
  } else if (userRights?.limitedAccessRight === LimitedAccessRight.PaymentFacilitationView) {
    return (
      <NavLink
        to={generatePath(APP_PATHS.dashboard.paymentFacilitator, {
          [PATH_PARAMS.applicationId]: contract.id,
          [PATH_PARAMS.facilitationTab]: FacilitationTabsEnum.overview,
        })}
        className={classes.navLink}
      >
        <Typography variant={'body1'} className={classes.label}>
          {(contract?.owner as LegalEntity).companyName}
        </Typography>
        <ArrowForwardIosIcon className={classes.icon} />
      </NavLink>
    )
  } else {
    return (
      <>
        {signaturesCount?.actionSignatureRequestsCount === 0 ? (
          <NavLink
            to={generatePath(
              userRights?.isIntroducerContractOwner
                ? APP_PATHS.dashboard.partnerManagementOverview
                : APP_PATHS.dashboard.home,
              {
                [PATH_PARAMS.applicationId]: contract.id,
              },
            )}
            className={classes.navLink}
          >
            <Typography variant={'body1'} className={classes.label}>
              {contract?.ownerType === UboType.NaturalPerson
                ? t('personal')
                : ((contract?.owner as LegalEntity).companyName as string)}
            </Typography>
            <ArrowForwardIosIcon className={classes.icon} />
          </NavLink>
        ) : (
          <NavLink
            to={generatePath(APP_PATHS.dashboard.actions, {
              [PATH_PARAMS.applicationId]: contract.id,
            })}
            className={classes.navLink}
          >
            <Typography variant={'body1'} className={classes.label}>
              {contract?.ownerType === UboType.NaturalPerson
                ? t('personal')
                : ((contract?.owner as LegalEntity).companyName as string)}
            </Typography>
            <ArrowForwardIosIcon className={classes.icon} />
          </NavLink>
        )}
      </>
    )
  }
}

export const ContractsAuthorizedPerson: FC<{ contracts: BankableEntityContractType[] }> = ({
  contracts,
}) => {
  const classes = useStyles()
  const contractsToLogin = contracts.filter((contract) =>
    dashboardPassStatuses.includes(contract.status as ContractStatusType),
  )

  return (
    <>
      {!isEmpty(contractsToLogin) && (
        <Grid item container className={classes.wrapper}>
          <Grid item xs={12} container data-test="contractsContainer">
            {contractsToLogin.map((contract) => (
              <Paper variant="outlined" key={contract.id} className={classes.item}>
                <ContractLink contract={contract} />
              </Paper>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}
