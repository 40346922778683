import { authorizedPersonStatuses } from '@klarpay/enums'
import { AccountStatus, ContractStatusType } from '../graphql'
import i18n from '../i18n'

export enum AppActions {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
}

export const statusActions: {
  [key in ContractStatusType]: AppActions[]
} = {
  [ContractStatusType.PreAssessment]: [AppActions.View],
  [ContractStatusType.Draft]: [AppActions.Edit, AppActions.Delete],
  [ContractStatusType.ChangeRequest]: [AppActions.Edit, AppActions.Delete],
  [ContractStatusType.FirstLinePending]: [AppActions.View],
  [ContractStatusType.ManagementConsideration]: [AppActions.View],
  [ContractStatusType.AmloPending]: [AppActions.View],
  [ContractStatusType.ManagementPending]: [AppActions.View],
  [ContractStatusType.Active]: [AppActions.View],
  [ContractStatusType.SignaturesPending]: [AppActions.View],
  [ContractStatusType.Rejected]: [AppActions.View],
  [ContractStatusType.Terminated]: [AppActions.View],
  [ContractStatusType.Suspended]: [AppActions.View],
  [ContractStatusType.Monitoring]: [AppActions.View],
  [ContractStatusType.TerminationPending]: [AppActions.View],
  [ContractStatusType.OnboardingClosed]: [AppActions.Delete],
  [ContractStatusType.ManagementConsideration]: [AppActions.View],
}

//TODO 3 statuses added in Enums, how they have being displayed?
export const displayStatusKeys: { [key: string]: string } = {
  [ContractStatusType.PreAssessment]: 'pending',
  [ContractStatusType.Draft]: 'draft',
  [ContractStatusType.ChangeRequest]: 'changesRequested',
  [ContractStatusType.FirstLinePending]: 'approvalPending',
  [ContractStatusType.AmloPending]: 'approvalPending',
  [ContractStatusType.ManagementPending]: 'approvalPending',
  [ContractStatusType.Active]: 'completed',
  [ContractStatusType.SignaturesPending]: 'signaturesPending',
  [ContractStatusType.Rejected]: 'rejected',
  [ContractStatusType.Terminated]: 'terminated',
  [ContractStatusType.Suspended]: 'suspended',
  [ContractStatusType.Monitoring]: 'monitoring',
  [ContractStatusType.TerminationPending]: 'pending',
  [ContractStatusType.OnboardingClosed]: 'approvalPending',
}

export const authorizedPersonStatus: { [key: string]: string } = {
  [authorizedPersonStatuses.activated.key]: i18n.t('activeStatus'),
  [authorizedPersonStatuses.deactivated.key]: i18n.t('deactivatedStatus'),
  [authorizedPersonStatuses.invited.key]: i18n.t('invitedStatus'),
  [authorizedPersonStatuses.pendingInvitation.key]: i18n.t('pendingInvitation'),
  [authorizedPersonStatuses.pendingSignature.key]: i18n.t('pendingSignature'),
  [authorizedPersonStatuses.pendingVerification.key]: i18n.t('pendingVerification'),
  [authorizedPersonStatuses.rejected.key]: i18n.t('rejectedStatus'),
}

export const displayAccountStatusKeys: { [key: string]: string } = {
  [AccountStatus.Active]: i18n.t('active', 'Active'),
  [AccountStatus.Suspended]: i18n.t('suspended', 'Suspended'),
  [AccountStatus.Closed]: i18n.t('closed'),
  [AccountStatus.PendingClosureSignatures]: i18n.t('pendingClosureSignatures'),
}

export const displayVbanStatusKeys: { [key: string]: string } = {
  [AccountStatus.Active]: i18n.t('active', 'Active'),
  [AccountStatus.Suspended]: i18n.t('suspended', 'Suspended'),
  [AccountStatus.Closed]: i18n.t('deactivated'),
  [AccountStatus.PendingApproval]: i18n.t('pendingApprovalStatus'),
  [AccountStatus.PendingClosureSignatures]: i18n.t('pendingActions'),
  [AccountStatus.PendingOpeningSignatures]: i18n.t('pendingActions'),
  [AccountStatus.PendingIncomingPayment]: i18n.t('pendingActions'),
  [AccountStatus.Rejected]: i18n.t('rejected', 'Rejected'),
}
