import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { generatePath, useHistory } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../routes/paths'
import { useTranslation } from 'react-i18next'
import config from '../config'
import { useParams } from 'react-router'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
  },
  errorPanel: {
    padding: '120px 112px 48px 112px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      padding: '60px 53px 40px 60px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 40px 28px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 15px 20px 15px',
    },
  },
  darkPanel: {
    width: '100%',
    backgroundColor: '#F0F3F7',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  imgBox: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${config.images.error})`,
    backgroundPosition: 'center',
  },
  footer: {
    marginTop: theme.spacing(3),
    fontSize: '0.75rem',
  },
  footer_company: {
    justifyContent: 'center',
  },
  footer_flag: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  blockWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  title: {
    color: '#000',
    fontFamily: theme.typography.fontFamily,
    fontSize: '2.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.5rem',
    },
  },
  titleWrap: {
    backgroundColor: '#F5F5F5',
    borderRadius: '4px',
    padding: '22px 32px',
  },
  subtitle1: {
    color: '#000',
    fontSize: '1.5rem',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  text: {
    marginTop: 12,
    color: '#000',
    fontSize: '0.875rem',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
    lineHeight: '1.5',
    textAlign: 'center',
  },
  btn: {
    borderColor: '#000',
    color: '#000',
    fontSize: '0.875rem',
    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.2',
    padding: '0.25rem 0.5rem',
    minWidth: 'auto',
  },
}))

export const Error500Page: React.FC<{
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}> = ({ clearError }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const classes = useStyles()

  const goHome = useCallback(() => {
    clearError()

    applicationId
      ? history.push(
          generatePath(APP_PATHS.dashboard.home, {
            [PATH_PARAMS.applicationId]: applicationId,
          }),
        )
      : history.push(APP_PATHS.root)
  }, [])

  return (
    <Grid container direction="row" className={classes.mainContainer}>
      <Grid item md={6} className={classes.darkPanel}>
        <Box className={classes.imgBox} />
      </Grid>
      <Grid
        item
        xs
        container
        className={classes.errorPanel}
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={12} className={classes.blockWrap}>
          <Box display="flex" justifyContent="center" mb={5} className={classes.titleWrap}>
            <Typography variant={'h1'} className={classes.title}>
              {t('500Error', '500 Error')}
            </Typography>
          </Box>
          <Typography variant={'subtitle1'} className={classes.subtitle1}>
            {t('internalServerError', 'Internal server error')}
          </Typography>
          <Typography variant={'subtitle1'} className={classes.text}>
            {t(
              'weAreWorkingToSolveTheProblemSorryForTheInconvenience',
              'We are working to solve the problem, sorry for the inconvenience',
            )}
          </Typography>
          <Box display="flex" mt={3}>
            <Button
              variant="outlined"
              size="large"
              disabled={false}
              className={classes.btn}
              onClick={goHome}
            >
              {t('homepage', 'Homepage')}
            </Button>
          </Box>
        </Grid>
        <Grid
          className={classes.footer}
          item
          xs={12}
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item className={classes.footer_company}>
            <b>{t(config.isWhitelabel ? 'poweredByKlarpayAG' : 'klarpayAG')}</b>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className={classes.footer_flag}
          >
            <Grid item>
              <img
                src={process.env.PUBLIC_URL + '/img/Flag_of_Switzerland 1.png'}
                alt={'Flag_of_Switzerland'}
              />
            </Grid>
            <Grid item>{t('madeInSwitzerland', 'Made in Switzerland')}</Grid>
          </Grid>
          {process.env.REACT_APP_VERSION && (
            <Grid item container spacing={1} className={classes.footer_company}>
              <Grid item>{`Version: ${process.env.REACT_APP_VERSION}`}</Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Error500Page
