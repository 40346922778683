import React, { FC, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, generatePath } from 'react-router-dom'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { ContainerWithImage, FormPasswordField, FormTextField, Logo } from '../Common'
import { rxios } from '../../resources'
import { InviteSignUpFormInput, InviteSignUpFormInputSchema } from '../../schemes'
import SIGN_UP_IMAGE from '../../assets/images/img/signup-img2.svg'
import { useWebApi, useWebApiRequest } from '../../hooks'
import { useGetViewerQuery, Individual } from '../../graphql'
import { Loader } from '../Common'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  readOnly: {
    backgroundColor: '#F5F5F5',
    '& .MuiInput-root': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
      pointerEvents: 'none',
    },
    '&.MuiTextField-root': {
      paddingTop: theme.spacing(1.75),
      marginTop: theme.spacing(3.75),
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 12px) scale(0.857)',
    },
  },
}))

export const InviteSignUpPage: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<InviteSignUpFormInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(InviteSignUpFormInputSchema),
  })
  const classes = useStyles()
  const history = useHistory()
  const { webApi } = useWebApi()
  const { [PATH_PARAMS.invitationId]: invitationId } = useParams() as Record<string, string>
  const { handleSubmit, setValue, register, unregister, trigger, formState } = methods
  const { data: userData, loading } = useGetViewerQuery()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const {
    data: invitedUserData,
    loading: apiLoading,
    error: invitationDataError,
    clearError: clearInvitationDataError,
  } = useWebApiRequest<'getInvitedUserData'>('getInvitedUserData', [invitationId])

  const signUpHandler = useCallback(
    async (formData: InviteSignUpFormInput) => {
      try {
        if (invitationId) {
          setSubmitInProgress(true)
          rxios
            .post('onboarding/complete-registration', {
              invitationId: invitationId,
              password: formData.password,
              privacyAcceptance: formData.privacyAcceptance,
              eulaAcceptance: true,
              ageAcceptance: true,
            })
            .subscribe(
              () =>
                history.push(APP_PATHS.auth.login, { inviteContract: invitedUserData?.contractId }),
              () => {
                setSubmitInProgress(false)
              },
            )
          return
        }
      } catch (e) {
        toast.error((e as Error).message)
      }
    },
    [invitationId, invitedUserData, history],
  )

  useEffect(() => {
    return () => {
      unregister(['password', 'repeat_password'])
    }
  }, [unregister])

  useEffect(() => {
    if (invitationDataError) {
      if (invitationDataError === 'Internal Server Error: Already Onboard') {
        toast.error(t('alreadyOnboard', 'Already onboard'), { autoClose: false })
        history.push(APP_PATHS.root)
      } else {
        toast.error(invitationDataError, { autoClose: false })
        clearInvitationDataError()
      }
    }
    if (!apiLoading && invitedUserData) {
      if (!loading) {
        if (userData?.viewer?.__typename === 'Individual') {
          const user = userData?.viewer as Individual
          if (userData?.viewer.isPhoneVerified && userData?.viewer.videoVerified) {
            history.push(
              generatePath(APP_PATHS.dashboard.home, {
                [PATH_PARAMS.applicationId]: invitedUserData.contractId,
              }),
            )
          } else if (invitedUserData.email === user.email) {
            history.push(
              generatePath(APP_PATHS.application.onboarding.start, {
                [PATH_PARAMS.applicationId]: invitedUserData?.contractId,
              }),
            )
          } else {
            webApi?.logout().finally(() => {
              if (invitedUserData.isRegistered) {
                window.location.reload()
              }
            })
          }
        } else {
          if (invitedUserData.isRegistered) {
            history.push(APP_PATHS.auth.login, { inviteContract: invitedUserData?.contractId })
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue('email', invitedUserData.email)
      }
    }
  }, [
    loading,
    userData,
    apiLoading,
    invitedUserData,
    webApi,
    invitationDataError,
    clearInvitationDataError,
    history,
    invitationId,
    setValue,
    t,
  ])

  if (apiLoading || loading || !invitedUserData) return <Loader />

  return (
    <ContainerWithImage
      image={config.images.invitedSignUp || SIGN_UP_IMAGE}
      reverse={true}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid item xs={12}>
        <Grid item xs={12} className="logo">
          <a href={APP_PATHS.root}>
            <Logo />
          </a>
        </Grid>
        <Box display="flex" justifyContent="center">
          <Typography variant={'h2'}>{t('createYourAccount', 'Create your account')}</Typography>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(signUpHandler)} noValidate={true}>
            <FormTextField
              label={t('email', 'Email')}
              name="email"
              type="email"
              fullWidth={true}
              // disabled
              inputProps={{
                readOnly: true,
              }}
              className={classes.readOnly}
            />
            <FormPasswordField label={t('password', 'Password')} name="password" fullWidth />
            <FormPasswordField
              label={t('repeatPassword', 'Repeat password')}
              name="repeat_password"
              fullWidth
            />

            <Box mt={0.5} mb={3}>
              <FormControlLabel
                name={'privacyAcceptance'}
                inputRef={register({ required: true, min: 1 })}
                control={<Checkbox color="primary" />}
                onChange={() => trigger()}
                label={
                  <div>
                    <span>{t('iHaveReadAndAgreeToThe', 'I have read and agree to the')} </span>
                    <Link
                      href={process.env.PUBLIC_URL + '/privacy-policy.pdf'}
                      target="_blank"
                      underline="always"
                    >
                      <b>{t('privacyPolicy', 'Privacy policy')}</b>
                    </Link>
                    <span> {t('andToThe', 'and to the')} </span>
                    <Link
                      href={process.env.PUBLIC_URL + '/art7a-product-disclosure.pdf'}
                      target="_blank"
                      underline="always"
                    >
                      <b>{t('art7ProductDisclosure', 'Article 7a Product Disclosure')}</b>
                    </Link>
                  </div>
                }
              />
            </Box>
            <Button
              type="submit"
              className="signupButton"
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                submitInProgress ||
                !formState?.isDirty ||
                (formState?.isDirty && !formState?.isValid)
              }
              disableElevation
            >
              {t('createAccount', 'Create account')}
            </Button>
          </form>
        </FormProvider>
      </Grid>
    </ContainerWithImage>
  )
}

export default InviteSignUpPage
