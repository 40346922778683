import * as yup from 'yup'

import {
  AdditionalDetailsInputSchema,
  AddressInputSchema,
  BirthdayInputSchema,
  NameInputSchema,
  NationalityInputSchema,
  PostalCodeInputSchema,
  StreetAddressInputSchema,
} from './common'
//todo move into another folder

export const ProfileDetailsInputSchema = AdditionalDetailsInputSchema.concat(AddressInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(NameInputSchema)

export type ProfileDetailsInput = yup.Asserts<typeof ProfileDetailsInputSchema>
