/* eslint-disable no-underscore-dangle */
import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { IAppRouteSetting } from '../types'
import { AuthGuard } from './guards'
import {
  StepperLayout,
  DashboardLayout,
  ApplicationLayout,
  PersonLayout,
  PreAssessmentLayout,
} from '../components/Layouts'
import { APP_PATHS } from './paths'
import ApRouteGuard from './guards/ApRouteGuard'

export const NonAuthenticatedRoutes: Array<IAppRouteSetting> = [
  //temp case from Personal Onboarding start screen (FE)
  // {
  //   exact: true,
  //   path: '/onboarding',
  //   component: lazy(() => import('../pages/EmbeddedKYC/PersonalOnboardingStartScreenFE')),
  // },
  //
  {
    exact: true,
    path: APP_PATHS.preAssessment,
    layout: PreAssessmentLayout,
    component: lazy(() => import('../pages/LegacyPreAssessmentPage/PreAssessmentQuestionnaire')),
  },
  {
    exact: true,
    path: APP_PATHS.signup,
    component: lazy(() => import('../components/SignUp/SignUpPage')),
  },
  {
    exact: true,
    path: APP_PATHS.partnerSignup,
    component: lazy(() => import('../components/SignUp/PartnerSignUpPage')),
  },
  {
    exact: true,
    path: APP_PATHS.phoneVerification,
    component: lazy(() => import('../components/SignUp/PartnerPhoneVerification')),
  },
  {
    exact: true,
    path: APP_PATHS.confirmEmail,
    component: lazy(() => import('../components/SignUp/VerificationEmailPage')),
  },
  {
    exact: true,
    path: APP_PATHS.contactSupport,
    layout: PersonLayout,
    component: lazy(() => import('../pages/ContactSupport')),
  },
  {
    exact: true,
    path: APP_PATHS.invitation,
    component: lazy(() => import('../components/SignUp/InviteSignUpPage')),
  },
  {
    exact: true,
    path: APP_PATHS.auth.root,
    component: lazy(() => import('../components/SignIn/SignInPage')),
  },
  {
    exact: true,
    path: APP_PATHS.auth.login,
    component: lazy(() => import('../components/SignIn/SignInPage')),
  },
  {
    exact: true,
    path: APP_PATHS.auth.twofa,
    component: lazy(() => import('../components/SignIn/2FactorAuthPage')),
  },
  {
    exact: true,
    path: APP_PATHS.auth.resetPassword,
    component: lazy(() => import('../components/ResetPassword/ResetPassword')),
  },
  {
    exact: true,
    path: APP_PATHS.auth.forgotPassword,
    component: lazy(() => import('../components/RestorePassword/RestorePassword')),
  },
  {
    exact: true,
    path: APP_PATHS.auth.changeEmail,
    component: lazy(() => import('../components/ChangeMail/ChangeMailPage')),
  },
  {
    exact: true,
    path: APP_PATHS.personalInterest,
    component: lazy(() => import('../components/Individuals/Individuals')),
  },
  {
    exact: true,
    path: APP_PATHS.videoVerification,
    component: lazy(() => import('../components/StartPersonalOnboarding/VideoVerificationPage')),
  },
]

// Error pages
const ErrorPages: Array<IAppRouteSetting> = [
  {
    exact: true,
    path: APP_PATHS.errors._404,
    component: lazy(() => import('../pages/Error404Page')),
  },
  {
    exact: true,
    path: APP_PATHS.errors._50x,
    component: lazy(() => import('../pages/Error500Page')),
  },
]

// Redirect to 404
const redirect404Route: IAppRouteSetting = {
  // eslint-disable-next-line react/display-name, @typescript-eslint/explicit-module-boundary-types
  component: () => <Redirect to={APP_PATHS.errors._404} />,
}

const GetStartedRoutes: Array<IAppRouteSetting> = [
  {
    exact: true,
    path: APP_PATHS.root,
    component: lazy(
      () => import('../components/StartBusinessApp/StartBusinessAppPage/StartBusinessAppPage'),
    ),
  },
  {
    exact: true,
    path: APP_PATHS.application.onboarding.start,
    component: lazy(() => import('../components/StartPersonalOnboarding/StartPersonalPage')),
  },
]

export const BusinessApplicationRoutes: IAppRouteSetting = {
  path: APP_PATHS.application.root,
  guard: AuthGuard,
  layout: ApplicationLayout,
  routes: [
    {
      exact: true,
      path: APP_PATHS.application.status,
      component: lazy(() => import('../components/NewBusinessApp/ApplicationStatus')),
    },
    {
      exact: true,
      path: APP_PATHS.application.actions,
      component: lazy(() => import('../pages/AplicationActions/ActionsPage/ActionsPage')),
    },
    {
      exact: true,
      path: APP_PATHS.application.actionDetails,
      component: lazy(() => import('../pages/AplicationActions/DetailedView')),
    },
    {
      exact: true,
      path: APP_PATHS.application.settings,
      component: lazy(() => import('../pages/PersonPage/Settings')),
    },
    {
      exact: true,
      path: APP_PATHS.application.contactSupport,
      component: lazy(() => import('../pages/ContactSupport')),
    },
    {
      exact: true,
      path: APP_PATHS.application.transferContractOwnership,
      component: lazy(
        () => import('../components/NewBusinessApp/Onboarding/TransferContractOwnership'),
      ),
    },
    {
      path: APP_PATHS.application.root,
      layout: StepperLayout,
      routes: [
        {
          exact: true,
          path: APP_PATHS.application.new,
          component: lazy(() => import('../components/NewBusinessApp/BusinessDetails')),
        },
        {
          exact: true,
          path: APP_PATHS.application.edit,
          component: lazy(() => import('../components/NewBusinessApp/BusinessDetails')),
        },
        // {
        //   exact: true,
        //   path: APP_PATHS.application.onboarding.contactSupport,
        //   component: lazy(() => import('../pages/ContactSupport')),
        // },
        {
          exact: true,
          path: APP_PATHS.application.onboarding.personalDetails,
          component: lazy(() => import('../components/StartPersonalOnboarding/ProfileDetails')),
        },
        {
          exact: true,
          path: APP_PATHS.application.onboarding.phoneVerification,
          component: lazy(
            () => import('../components/StartPersonalOnboarding/PersonalPhoneVerification'),
          ),
        },
        {
          exact: true,
          path: APP_PATHS.application.onboarding.videoVerification,
          component: lazy(
            () => import('../components/StartPersonalOnboarding/PersonalVideoVerification'),
          ),
        },
        {
          exact: true,
          path: APP_PATHS.application.onboarding.complete,
          component: lazy(() => import('../pages/ReviewPersonalOnboardingPage')),
        },
        {
          exact: true,
          path: APP_PATHS.application.authorizedPersons.add,
          component: lazy(() => import('../components/NewBusinessApp/PersonsDetailsList')),
        },
        {
          exact: true,
          path: APP_PATHS.application.authorizedPersons.new,
          component: lazy(() => import('../components/NewPerson/AddNewPerson')),
        },
        {
          exact: true,
          path: APP_PATHS.application.authorizedPersons.list,
          component: lazy(() => import('../components/NewBusinessApp/ReviewPerson')),
        },
        {
          exact: true,
          path: APP_PATHS.application.authorizedPersons.edit,
          component: lazy(() => import('../components/NewPerson/AddNewPerson')),
        },
        {
          exact: true,
          path: APP_PATHS.application.directors.new,
          component: lazy(() => import('../components/NewPerson/AddNewDirector')),
        },
        {
          exact: true,
          path: APP_PATHS.application.directors.list,
          component: lazy(() => import('../components/NewBusinessApp/ReviewDirectors')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.newUboOwner,
          component: lazy(() => import('../components/NewPerson/AddNewOwner')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.uboList,
          component: lazy(() => import('../components/NewBusinessApp/ReviewOwners')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.add,
          component: lazy(() => import('../components/NewBusinessApp/BeneficialOwners')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.new,
          component: lazy(() => import('../components/NewPerson/AddNewOwner')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.list,
          component: lazy(() => import('../components/NewBusinessApp/ReviewOwners')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.edit,
          component: lazy(() => import('../components/NewPerson/AddNewOwner')),
        },
        {
          exact: true,
          path: APP_PATHS.application.beneficialOwners.editUboOwner,
          component: lazy(() => import('../components/NewPerson/AddNewOwner')),
        },
        {
          exact: true,
          path: APP_PATHS.application.review,
          component: lazy(() => import('../components/NewBusinessApp/Review')),
        },
        {
          exact: true,
          path: APP_PATHS.application.individual.new,
          component: lazy(() => import('../components/IndividualOnboarding/ProfileDetails')),
        },
        {
          exact: true,
          path: APP_PATHS.application.individual.phoneVerification,
          component: lazy(
            () => import('../components/StartPersonalOnboarding/PersonalPhoneVerification'),
          ),
        },
        {
          exact: true,
          path: APP_PATHS.application.individual.identityVerification,
          component: lazy(
            () => import('../components/StartPersonalOnboarding/PersonalVideoVerification'),
          ),
        },
        redirect404Route,
      ],
    },
    redirect404Route,
  ],
}

// export const PersonalOnboardingRoutes: IAppRouteSetting = {
//   path: APP_PATHS.application.onboarding.root,
//   guard: AuthGuard,
//   layout: PersonLayout,
//   routes: [
//     {
//       exact: true,
//       path: APP_PATHS.application.onboarding.contactSupport,
//       component: lazy(() => import('../pages/ContactSupport')),
//     },
//     {
//       path: APP_PATHS.application.onboarding.start,
//       layout: StepperLayout,
//       routes: [
//         {
//           exact: true,
//           path: APP_PATHS.application.onboarding.personalDetails,
//           component: lazy(() => import('../components/StartPersonalOnboarding/ProfileDetails')),
//         },
//         {
//           exact: true,
//           path: APP_PATHS.application.onboarding.phoneVerification,
//           component: lazy(
//             () => import('../components/StartPersonalOnboarding/PersonalPhoneVerification'),
//           ),
//         },
//         {
//           exact: true,
//           path: APP_PATHS.application.onboarding.videoVerification,
//           component: lazy(
//             () => import('../components/StartPersonalOnboarding/PersonalVideoVerification'),
//           ),
//         },
//         {
//           exact: true,
//           path: APP_PATHS.application.onboarding.complete,
//           component: lazy(() => import('../pages/ReviewPersonalOnboardingPage')),
//         },
//         redirect404Route,
//       ],
//     },
//     redirect404Route,
//   ],
// }

export const DashboardRoutes: IAppRouteSetting = {
  path: APP_PATHS.dashboard.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: APP_PATHS.dashboard.contactSupport,
      component: lazy(() => import('../pages/ContactSupport')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.home,
      component: lazy(() => import('../pages/HomePage/HomePage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.accounts,
      component: lazy(() => import('../pages/AccountsPage/AccountsPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.createAccount,
      component: lazy(() => import('../pages/CreateAccount/CreateAccountPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.actions,
      component: lazy(() => import('../pages/AplicationActions/ActionsPage/ActionsPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.transactions,
      //component: lazy(() => import('../pages/AplicationActions/TransferPage')),
      component: lazy(() => import('../pages/TransactionsPage/TransactionsPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.clients,
      component: lazy(() => import('../pages/ClientsPage/ClientsPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.clientOverview,
      component: lazy(() => import('../pages/ClientsPage/OverviewPage/OverviewPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.mismatches,
      component: lazy(() => import('../pages/MismatchesPage/MismatchesPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.cardTrxs,
      component: lazy(() => import('../pages/TransactionsPage/TransactionsPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.vbans,
      component: lazy(() => import('../pages/VbansPage/VbansPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.vbanstransactions,
      component: lazy(() => import('../pages/TransactionsPage/TransactionsPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.changeAdress,
      component: lazy(() => import('../pages/AplicationActions/AdressPage')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.newPayment,
      component: lazy(() => import('../pages/NewPaymentPage/NewPayment')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.batchPayment,
      component: lazy(() => import('../components/BatchPayment/BatchPayment')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.personProfile,
      component: lazy(() => import('../pages/PersonPage/PersonProfile')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.actionDetails,
      component: lazy(() => import('../pages/AplicationActions/DetailedView')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.trxDetails,
      component: lazy(() => import('../pages/TransactionsPage/TrxDetailedView')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.contractManagement,
      component: lazy(() => import('../pages/ContractManagementPage/ContractManagement')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.cards,
      component: lazy(() => import('../pages/CardsPage/Cards')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.mapping.cardsWithoutPage,
      component: () => {
        const path = `${window.location}/1`
        const url = new URL(path)
        return <Redirect to={url.pathname} />
      },
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.createCard,
      component: lazy(() => import('../pages/CardsPage/Forms/CreateNewCardForm')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.activateCard,
      component: lazy(() => import('../pages/CardsPage/Forms/ActivateCardForm')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.settings,
      component: lazy(() => import('../pages/PersonPage/Settings')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.developers,
      component: lazy(() => import('../pages/DevelopersPage/Developers')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.standingOrders,
      component: lazy(() => import('../pages/StandingOrdersPage/StandingOrders')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.standingOrderDetails,
      component: lazy(() => import('../pages/StandingOrdersPage/StandingOrderDetails')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.partnerManagementOverview,
      component: lazy(() => import('../pages/PartnerManagementPage/PartnerManagement')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.partnerManagementPayout,
      component: lazy(() => import('../pages/PartnerManagementPage/Payout')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.paymentFacilitator,
      component: lazy(() => import('../pages/PaymentFacilitatorPage/PaymentFacilitator')),
    },
    {
      exact: true,
      guard: ApRouteGuard,
      path: APP_PATHS.dashboard.paymentDetails,
      component: lazy(() => import('../pages/PaymentFacilitatorPage/PaymentDetailedView')),
    },
    redirect404Route,
  ],
}

export const AuthenticatedRoutes: IAppRouteSetting = {
  path: APP_PATHS.root,
  guard: AuthGuard,
  routes: [
    ...GetStartedRoutes,
    BusinessApplicationRoutes,
    // PersonalOnboardingRoutes,
    DashboardRoutes,
    // Person,
    ...ErrorPages,
    redirect404Route,
  ],
}
