import * as yup from 'yup'
import i18n from '../../i18n'

export const NationalityInputSchema = yup.object().shape({
  nationality: yup
    .mixed()
    .nullable()
    .required(i18n.t('nationalityIsRequired', 'Nationality is required')),
})

export type NationalityInput = yup.Asserts<typeof NationalityInputSchema>
