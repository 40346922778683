import React, { FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { PciCompliance } from './PciCompilance'
import { RiskManagement } from './RiskManagement'
import { TransactionData } from './TransactionData'
import { ProcessingHistory } from './ProcessingHistory'
import { CardPaymentsSelectOptions } from '../../../types'
import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { FormAutocompleteSelect, GridRow } from '../../Common'
import { selectCardPaymentOptions } from '../../../utils/Data'
import { MerchantWebsites } from './MerchantWebsites'
import { CardPaymentsAndAmp } from '../../../graphql'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
}))
const CardPaymentsComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: CardPaymentsAndAmp | null
}> = ({ readOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { watch, control } = useFormContext()

  const cardPaymentsApmRequired = watch('cardPaymentsApm') === CardPaymentsSelectOptions.required

  return (
    <>
      <Box mt={4}>
        <Typography variant="h3" className={clsx(classes.title, classes.moreBottomSpace)}>
          {t('cardPaymentsApm', 'Payment Processing')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'cardPaymentApmSubText',
            'Do you require cards and/or alternative payment processing solutions from Klarpay?',
          )}
        </Typography>
      </Box>
      <GridRow>
        <Controller
          name="cardPaymentsApm"
          control={control}
          render={() => (
            <FormAutocompleteSelect
              className={classes.boxInput}
              defaultValue={
                cardPaymentsApmRequired
                  ? (selectCardPaymentOptions[1].key as string)
                  : (selectCardPaymentOptions[0].key as string)
              }
              name="cardPaymentsApm"
              data={selectCardPaymentOptions}
              readOnly={readOnly}
            />
          )}
        />
      </GridRow>
      {cardPaymentsApmRequired && (
        <>
          <TransactionData
            changedContractProfileData={changedContractProfileData}
            isViewOnly={readOnly}
          />
          <RiskManagement
            changedContractProfileData={changedContractProfileData?.riskManagement}
            isViewOnly={readOnly}
          />
          <MerchantWebsites
            changedContractProfileData={changedContractProfileData}
            isViewOnly={readOnly}
          />
          <PciCompliance
            changedContractProfileData={changedContractProfileData?.pciDssCompliance}
            isViewOnly={readOnly}
          />
          <ProcessingHistory
            changedContractProfileData={changedContractProfileData?.processingHistory}
            isViewOnly={readOnly}
          />
        </>
      )}
    </>
  )
}

export const CardPaymentsQuestions = React.memo(CardPaymentsComponent)
