import React, { FC, useCallback, useEffect, useState } from 'react'
import { omit } from 'lodash'
import {
  Grid,
  makeStyles,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Link,
  Button,
  Collapse,
} from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Logo,
  FormTextField,
  FormPasswordField,
  ContainerWithImage,
  sharedMailboxes,
} from '../Common'
import { rxiosAuth } from '../../resources'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router'
import { CommonTipItem } from '../Common'
import { domains } from '../Common'
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info2.svg'
import { SignupFormInput, SignupFormInputSchema } from '../../schemes'
import SIGN_UP_IMAGE from '../../assets/images/img/signup-img6.jpeg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { generatePath } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { signUpForm } from '../../graphql/local'
import { isUndefined } from 'lodash'
import { checkPassword, focusKeyPressNext, scrollToInputElement } from '../../utils'
import { AxiosError } from 'axios'
import { useQuery } from '../../hooks'
import config from '../../config'
import { InviteOnlyModal } from './InviteOnlyModal'
import CheckIcon from '@material-ui/icons//Check'

const useStyles = makeStyles((theme) => ({
  info: {
    margin: theme.spacing(2, 0, 0, 0),
    border: '1px solid #FFD8B5',
    '&>div': {
      padding: '0 10px',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  wrap_email: {
    position: 'relative',
  },
  linkWrap: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },

    '& a': {
      textDecoration: 'none',
      borderBottom: '1px solid',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
  selectReason: {
    [theme.breakpoints.between('xs', 'md')]: {
      '& select': {
        whiteSpace: 'break-spaces',
        // height: '2.1876em',
      },
    },
  },
  mobView: {
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
  },
  successText: {
    color: 'green',
  },
  icon: {
    transform: 'scale(0.85)',
  },
}))

const checkMailDomains = (email: string) => {
  if (email && email.includes('@')) {
    for (let i = 0; i < domains.length; i++) {
      if (email.includes(domains[i])) {
        return true
      }
    }
  }
}

const checkForSharedInbox = (email: string) => {
  if (email && email.includes('@')) {
    const inbox = email.split('@')[0].toLowerCase()
    for (let i = 0; i < sharedMailboxes.length; i++) {
      if (inbox.includes(sharedMailboxes[i])) {
        return true
      }
    }
  }
  return false
}

export const SignUpPage: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<SignupFormInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(SignupFormInputSchema),
  })
  const { setValue, watch, formState, trigger, errors, setError, clearErrors } = methods
  const signUpFormVar = useReactiveVar(signUpForm)
  const classes = useStyles()
  const history = useHistory()
  const { search: urlSearch } = useLocation()
  const urlQueryParams = new URLSearchParams(urlSearch)

  const [loading, setLoading] = useState<boolean>(false)
  const [isSharedInbox, setIsSharedInbox] = useState<boolean>(false)

  const email: string = watch('email')
  const password: string = watch('password')
  const repeatPassword: string = watch('repeat_password')
  const signupCode = urlQueryParams.get('code') || ''
  const signupCodeValid =
    !config.requiresInvite || (config.requiresInvite && config.signupCodeRegex.test(signupCode))

  useEffect(() => {
    return () => {
      methods.unregister(['password', 'repeat_password'])
    }
  }, [methods.unregister])

  const referralCode = useQuery().get('kp_ref')

  const signUpHandler = useCallback(
    async (formData: SignupFormInput) => {
      let cleanupFunction = false
      setLoading(true)

      const signUpData = {
        firstName: `${formData.firstName}`,
        lastName: `${formData.lastName}`,
        email: `${formData.email}`,
        privacyAcceptance: true,
        eulaAcceptance: true,
        password: `${formData.password}`,
        ageAcceptance: true,
        signupCode,
        referralCode,
      }

      rxiosAuth.post('signup', signUpData).subscribe(
        () => {
          // toast.success('Account created')
          if (!cleanupFunction) setLoading(false)
        },
        (error: AxiosError) => {
          const responseData = error.response?.data as { message: string }
          toast.error(responseData?.message || error.message)
          setLoading(false)
        },
        () => {
          setLoading(false)
          history.push(
            generatePath(APP_PATHS.confirmEmail, {
              [PATH_PARAMS.token]: formData.email,
            }),
          )
          signUpForm({ ...omit(formData, ['password', 'repeat_password']) })
        },
      )
      cleanupFunction = true
    },
    [setLoading, history],
  )

  const handlerEnter = useCallback(
    (ev, fState) => {
      return focusKeyPressNext(ev, fState)
    },
    [formState.isDirty],
  )

  useEffect(() => {
    return () => {
      methods.unregister(['password', 'repeat_password'])
    }
  }, [methods.unregister])

  useEffect(() => {
    if (repeatPassword?.length) {
      trigger('repeat_password')
    }
  }, [password])

  useEffect(() => {
    if (!isUndefined(signUpFormVar)) {
      setValue('firstName', signUpFormVar.firstName)
      setValue('lastName', signUpFormVar.lastName)
      setValue('email', signUpFormVar.email)
      setValue('privacyAcceptance', !!signUpFormVar.privacyAcceptance)
    }
  }, [setValue, signUpFormVar])

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  return (
    <ContainerWithImage
      image={config.images.signUp || SIGN_UP_IMAGE}
      reverse={true}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid item xs={12}>
        <Grid item xs={12} className="logo noMarginTop">
          <a href={APP_PATHS.root}>
            <Logo />
          </a>
        </Grid>
        <Box display="flex" justifyContent="center" mb={2}>
          <Typography variant={'h2'}>{t('createYourAccount', 'Create your account')}</Typography>
        </Box>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(signUpHandler)}
            id="forNextFocus"
            onKeyDown={(event) => {
              handlerEnter(event, formState)
            }}
            data-test="signUpForm"
          >
            <FormTextField
              label={t('firstNames', 'First Name(s)')}
              name="firstName"
              type="text"
              defaultValue={signUpFormVar?.firstName || ''}
              fullWidth={true}
              inputProps={{
                'data-test': 'signupName',
              }}
            />
            <FormTextField
              label={t('lastName', 'Last Name')}
              name="lastName"
              type="text"
              defaultValue={signUpFormVar?.lastName || ''}
              fullWidth={true}
              inputProps={{
                'data-test': 'signupSurName',
              }}
            />

            <Grid className={classes.wrap_email}>
              <FormTextField
                label={t('email', 'Email')}
                name="email"
                type="email"
                defaultValue={signUpFormVar?.email || ''}
                fullWidth={true}
                inputProps={{
                  'data-test': 'signupEmail',
                }}
                onBlur={() => setIsSharedInbox(checkForSharedInbox(email))}
              />
              {checkMailDomains(email) ? (
                <Grid item xs={12} className={classes.info}>
                  <CommonTipItem
                    value={t(
                      'weSuggestUsingACorporateEmailRatherThanAPrivateOne',
                      'We suggest using a corporate email rather than a private one',
                    )}
                    iconComponent={<IconInfo />}
                  />
                </Grid>
              ) : null}
              {isSharedInbox && !checkMailDomains(email) && (
                <Grid item xs={12} className={classes.info}>
                  <CommonTipItem
                    value={t(
                      'sharedInboxSuggestion',
                      'For security reasons we recommend only using personal corporate email addresses',
                    )}
                    iconComponent={<IconInfo />}
                  />
                </Grid>
              )}
            </Grid>

            <FormPasswordField
              label={t('password', 'Password')}
              name="password"
              fullWidth
              data-test="signupPsw"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                checkPassword(e, setError, clearErrors)
              }
            />
            <Collapse in={Boolean(password && !errors.password)}>
              <Box display="flex">
                <Typography className={classes.successText}>
                  {t('passwordAccepted', 'Password Accepted')}
                </Typography>
                <CheckIcon className={classes.icon} />
              </Box>
            </Collapse>
            <FormPasswordField
              label={t('repeatPassword', 'Repeat password')}
              name="repeat_password"
              fullWidth
              data-test="signupPswRepeat"
            />

            <Box mb={2}>
              <FormControlLabel
                name={'privacyAcceptance'}
                inputRef={methods.register({ required: true, min: 1 })}
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={!!signUpFormVar?.privacyAcceptance || false}
                    data-test="signupCheckBoxRead"
                  />
                }
                //data-test="signUpForm-checkPrivacy"
                onChange={() => methods.trigger(`privacyAcceptance`)}
                label={
                  <div>
                    <span>{t('iHaveReadAndAgreeToThe', 'I have read and agree to the')} </span>
                    <Link
                      href={process.env.PUBLIC_URL + '/privacy-policy.pdf'}
                      target="_blank"
                      underline="always"
                    >
                      <b>{t('privacyPolicy', 'Privacy policy')}</b>
                    </Link>
                    <span> {t('andToThe', 'and to the')} </span>
                    <Link
                      href={process.env.PUBLIC_URL + '/art7a-product-disclosure.pdf'}
                      target="_blank"
                      underline="always"
                    >
                      <b>{t('art7ProductDisclosure', 'Article 7a Product Disclosure')}</b>
                    </Link>
                  </div>
                }
              />
            </Box>
            <Button
              type="submit"
              className="signupButton"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading || !methods.formState?.isValid || !signupCodeValid}
              disableElevation
              data-test="signupCreateAccButton"
            >
              {t('createAccount', 'Create account')}
            </Button>
          </form>
          <Box className={classes.linkWrap} data-test="signupLink">
            {t('alreadyHaveAnAccoun', 'Already have an account?')}{' '}
            <Link href={APP_PATHS.auth.login}>
              <span>
                <b>{t('logIn', 'Log In')}</b>
              </span>
            </Link>
          </Box>
        </FormProvider>
      </Grid>
      <InviteOnlyModal isOpen={!signupCodeValid} />
    </ContainerWithImage>
  )
}

export default SignUpPage
