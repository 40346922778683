import * as yup from 'yup'

import {
  MAX_LENGTH,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_CARD_NAME_REGEXP,
  NUMBER_AND_LATINICA_ONLY_MESSAGE,
} from '../constants/validations'

export const CardNameInputSchema = yup.object().shape({
  cardName: yup
    .string()
    .notRequired()
    .test(
      'minLength',
      NUMBER_AND_LATINICA_ONLY_MESSAGE,
      (val) => val === '' || (!!val && val?.length >= MIN_LENGTH && val?.length <= MAX_LENGTH),
    )
    .test('latinAndNumberOnly', NUMBER_AND_LATINICA_ONLY_MESSAGE, (val) => {
      if (!!val) {
        return !!val.trim().match(NUMBER_AND_LATINICA_CARD_NAME_REGEXP)
      }
      return true
    }),
})

export type CardNameInput = yup.Asserts<typeof CardNameInputSchema>
