import React, { useCallback, useState } from 'react'
import { Box, Chip, ClickAwayListener } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import LIST_CLOSED_ICON from '../../../assets/images/icons/list_closed.svg'
import LIST_CLOSED_ICON_ERROR from '../../../assets/images/icons/list_closed_error.svg'
import LIST_OPENED_ICON from '../../../assets/images/icons/list_opened.svg'

const useStyles = (docTypeError: boolean, openSearch: boolean) =>
  makeStyles((theme: Theme) => ({
    input: {
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        transform: 'translate(0, -30px)',
        padding: theme.spacing(3.5, 0, 0, 1.5),
        lineHeight: '1.1876em',
        display: 'block',
        fontSize: '0.875rem',
      },
      '& .MuiButtonBase-root': {
        transform: 'translate(9px, -5px) scale(0.857)',
      },
    },
    docTypeLabel: {
      display: 'flex',
      justifyContent: 'flex-start',
      color: '#000000',
      backgroundColor: docTypeError ? '#FFEFED' : '#f0f0f0',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
      marginRight: '10px',
      fontSize: '12px',
      width: '100%',
      '& :before': {
        position: 'absolute',
        display: 'block',
        border: 0,
        content: '""',
        backgroundImage: openSearch
          ? `url(${LIST_OPENED_ICON})`
          : docTypeError
          ? `url(${LIST_CLOSED_ICON_ERROR})`
          : `url(${LIST_CLOSED_ICON})`,
        backgroundPosition: '0 50%',
        backgroundRepeat: 'no-repeat',
        width: 18,
        height: 18,
        top: '0px',
        bottom: 0,
        margin: 'auto',
        left: 'unset',
        right: '0px',
      },
    },
    hidden: {
      visibility: 'hidden',
      transform: 'translate(0, -33px)',
      margin: theme.spacing(0, 0, -15),
      zIndex: -100,
    },
    element: {
      height: '32px',
      '& .MuiInputBase-root': {
        height: '30px',
      },
    },
  }))()

type Entry = { key: string; label: string }

type FormAutocompleteSearchProps = {
  data: Entry[]
  onChange?: (value: Entry | null) => void
  value?: Entry
  hasError?: boolean
  label: string
} & TextFieldProps

const numberOfOptionsToDisplaySearch = 5

export const FormAutocompleteSearch: React.FC<FormAutocompleteSearchProps> = ({
  onChange,
  data,
  value,
  hasError,
  label,
  ...rest
}) => {
  const { t } = useTranslation()
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const classes = useStyles(hasError ?? false, openSearch)

  const handleClickAway = useCallback(() => {
    setOpenSearch(false)
  }, [setOpenSearch])

  const toggleSearch = useCallback(() => {
    setOpenSearch((prevState) => !prevState)
  }, [setOpenSearch])

  const _onChange = useCallback(
    (_, option: typeof data[number] | null) => {
      onChange?.(option)
      toggleSearch()
    },
    [toggleSearch, onChange],
  )

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        {!(openSearch && data.length > numberOfOptionsToDisplaySearch) && (
          <Chip
            className={classes.docTypeLabel}
            label={value?.label ?? label}
            onClick={toggleSearch}
          />
        )}
        {openSearch && (
          <Autocomplete
            className={classes.element}
            open={true}
            closeIcon={null}
            options={data}
            getOptionLabel={(option) => option.label}
            onChange={_onChange}
            renderInput={(params) => (
              <Box>
                <TextField
                  className={
                    data.length > numberOfOptionsToDisplaySearch ? classes.input : classes.hidden
                  }
                  autoFocus
                  {...params}
                  {...rest}
                  placeholder={t('search', 'Search')}
                />
              </Box>
            )}
            filterOptions={createFilterOptions({ matchFrom: 'start' })}
          />
        )}
      </Box>
    </ClickAwayListener>
  )
}
