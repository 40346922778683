import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'

import {
  BusinessRegions,
  DoubleTextInput,
  FormAutocompleteMultiSelect,
  GridRow,
} from '../../Common'
import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { getLabelsSeparatedByCommas, newCurrenciesLabelsArray } from '../../../utils'
import { CardsVolumes } from './Tabs/CardsVolumes'
import { AmpInUse, CardPaymentsAndAmp, Maybe } from '../../../graphql'
import FormHelperText from '@material-ui/core/FormHelperText'
import { cardPaymentsRegions } from '../../../constants'
import {
  makeTooltipTitleTextDoubleInputBusinessRegions,
  makeTooltipTitleTextDoubleTextInputApm,
} from './helpers/functions'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
  dirtyTextNoUnderline: {
    width: '100%',
    color: theme.palette.warning.main,
    position: 'relative',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  lightDivider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255,.3)',
    height: 3,
  },
}))

const TransactionDataComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: CardPaymentsAndAmp | null
}> = ({ isViewOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { watch, errors, formState } = useFormContext()

  const totalPredictedMonthlyVolumesErrors = errors['totalPredictedMonthlyVolumes']

  const tooltipDataMap: Record<string, Array<Maybe<AmpInUse>> | null | undefined> = {
    apmCurrently: changedContractProfileData?.ampCurrentlyInUse,
    apmRequired: changedContractProfileData?.ampRequiredCurrentlyInUse,
  }

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5">{t('transactionData', 'Transaction data')}</Typography>
      </Box>

      <BusinessRegions
        readOnly={isViewOnly}
        regionsData={cardPaymentsRegions}
        tooltipTitle={makeTooltipTitleTextDoubleInputBusinessRegions(
          changedContractProfileData?.cardPaymentsTransactionalDataRegion,
          { divider: classes.lightDivider, listItem: classes.listItem },
        )}
      />

      <GridRow>
        <Box>
          <FormAutocompleteMultiSelect
            className={clsx(classes.boxMultiSelect)}
            name={`trxCurrencies`}
            label={t(`transactionCurrencies`, 'Transaction currencies')}
            data={newCurrenciesLabelsArray()}
            value={watch(`trxCurrencies`)}
            readOnly={isViewOnly}
            data-test="transactionCurrencies"
          />
          {changedContractProfileData?.transactionalCurrencies && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.trxCurrencies}
              title={getLabelsSeparatedByCommas(
                changedContractProfileData?.transactionalCurrencies,
                newCurrenciesLabelsArray(),
              )}
            />
          )}
        </Box>
      </GridRow>

      <GridRow>
        <FormAutocompleteMultiSelect
          className={clsx(classes.boxMultiSelect)}
          name={`settlementCurrencies`}
          label={t(`requiredSettlementCurrencies`, 'Required settlement currencies')}
          data={newCurrenciesLabelsArray()}
          value={watch(`settlementCurrencies`)}
          readOnly={isViewOnly}
          data-test="settlementCurrencies"
        />
        {changedContractProfileData?.requiredSettlementCurrencies && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.settlementCurrencies}
            title={getLabelsSeparatedByCommas(
              changedContractProfileData?.requiredSettlementCurrencies,
              newCurrenciesLabelsArray(),
            )}
          />
        )}
      </GridRow>

      <GridRow>
        <Box mb={2} mt={6}>
          <Typography
            variant="h6"
            className={`${classes.titleSubBox} ${
              !!totalPredictedMonthlyVolumesErrors && classes.redError
            }`}
          >
            {t('totalVolumesProcessed', 'Total predicted monthly volumes to be processed:')}
          </Typography>
        </Box>
      </GridRow>
      <CardsVolumes isViewOnly={isViewOnly} />
      {changedContractProfileData?.totalPredictedMonthlyVolumesVisa &&
        changedContractProfileData?.totalPredictedMonthlyVolumesMasterCard && (
          <Box hidden={formState.errors.totalPredictedMonthlyVolumes}>
            <FormHelperText className={classes.dirtyTextNoUnderline}>
              {t('changeApproved', 'Change to be approved')}
            </FormHelperText>
          </Box>
        )}
      {!!totalPredictedMonthlyVolumesErrors && (
        <Grid container>
          <FormHelperText className={classes.redError}>
            {totalPredictedMonthlyVolumesErrors.message}
          </FormHelperText>
        </Grid>
      )}
      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t('ampCurrentlyInUse', 'APM(s) currently in use')}
          </Typography>
        </Box>
      </GridRow>
      <DoubleTextInput
        name={'apmCurrently'}
        labels={[
          t('ampCurrently', 'APM currently in use'),
          t('monthlyVolumeEur', 'Monthly volume in EUR'),
        ]}
        addBtnText={t('addApm', 'Add APM')}
        isViewOnly={isViewOnly}
        tooltipTitle={makeTooltipTitleTextDoubleTextInputApm('apmCurrently', tooltipDataMap, {
          divider: classes.lightDivider,
          listItem: classes.listItem,
        })}
      />

      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t('ampRequiredCurrentlyInUse', 'Required APM(s)')}
          </Typography>
        </Box>
      </GridRow>
      <DoubleTextInput
        name={'apmRequired'}
        labels={[t('requiredApm', 'Required APM'), t('monthlyVolumeEur', 'Monthly volume in EUR')]}
        addBtnText={t('addApm', 'Add APM')}
        isViewOnly={isViewOnly}
        tooltipTitle={makeTooltipTitleTextDoubleTextInputApm('apmRequired', tooltipDataMap, {
          divider: classes.lightDivider,
          listItem: classes.listItem,
        })}
      />
    </>
  )
}
export const TransactionData = React.memo(TransactionDataComponent)
