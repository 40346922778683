import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import { Box, Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'
import { CardholderProps } from '../contract'
import { SignaturesBox, SignaturesTable } from './helpers'
import { rightsTeamRole } from '../../../utils'
import { ContractAuthorizedPersonType } from '../../../graphql'

const useCurrentStyles = makeStyles((theme) => ({
  cellsWidth: {
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(8),
    },
  },
}))

export const CardholderTab: FC<{
  data: CardholderProps & ContractAuthorizedPersonType
  signedCount: number
}> = ({ data, signedCount }) => {
  const { t } = useTranslation()
  const classes = { ...useStyles(), ...useCurrentStyles() }

  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.borderTop}>
            <TableCell className={classes.cellsWidth}>
              <Box component="span" className={classes.label}>
                {t('name', 'Name')}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="span" className={classes.name}>
                {data?.name}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow className={classes.borderTop}>
            <TableCell className={classes.cellsWidth}>
              <Box component="span" className={classes.label}>
                {t('role', 'Role')}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="span">
                {data?.isAuthorizedSignatory
                  ? rightsTeamRole(data?.signatoryRight as string, data?.groupSize ?? 0)
                  : rightsTeamRole(data?.limitedAccessRight as string)}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Hidden xsDown>
        <SignaturesTable signatures={data.signatures} signedCount={signedCount} />
      </Hidden>
      <Hidden smUp>
        <SignaturesBox signatures={data.signatures} />
      </Hidden>

      {/* mobile table */}
      {/*<Hidden smUp>*/}
      {/*  <Box className={classes.mobileTable}>*/}
      {/*    <Box className={classes.mobileTableRow}>*/}
      {/*      <Box className={`${classes.mobileTableItem} ${classes.mobileTableBorderTop}`}>*/}
      {/*        <Box className={classes.mobileTableLabel}>{t('document', 'Document')}</Box>*/}
      {/*        <Box className={classes.mobileTableName}>*/}
      {/*          <a href={data.url} target="_blank" rel={'noopener'}>*/}
      {/*            View*/}
      {/*          </a>*/}
      {/*        </Box>*/}
      {/*      </Box>*/}
      {/*      <Box className={`${classes.mobileTableItem} ${classes.mobileTableBorderTop}`}>*/}
      {/*        <Box className={classes.mobileTableLabel}>{t('name', 'Name')}</Box>*/}
      {/*        <Box className={classes.mobileTableName}>{data.description}</Box>*/}
      {/*      </Box>*/}
      {/*      <Box className={`${classes.mobileTableItem} ${classes.mobileTableBorderTop}`}>*/}
      {/*        <Box className={classes.mobileTableLabel}>{t('type', 'Type')}</Box>*/}
      {/*        <Box className={classes.mobileTableName}>{data.type}</Box>*/}
      {/*      </Box>*/}
      {/*      <SignaturesBox signatures={data.signatures} />*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*</Hidden>*/}
    </>
  )
}
