import * as yup from 'yup'

import {
  COMPANY_NAME_REGEX,
  MAX_LEGAL_NAME_LENGTH,
  MAX_SYMBOLS_LIMIT_MESSAGE,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
  NUMBER_AND_LATINICA_AND_SYMBOLS,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'

export const CompanyNameInputSchema = yup.object().shape({
  companyName: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    // TODO: convert NUMBER_AND_LATINICA_AND_SYMBOLS to MIN_CHARACTERS_LIMIT_MESSAGE() function and fix dependencies
    .min(MIN_LENGTH, NUMBER_AND_LATINICA_AND_SYMBOLS)
    .max(MAX_LEGAL_NAME_LENGTH, MAX_SYMBOLS_LIMIT_MESSAGE(MAX_LEGAL_NAME_LENGTH))
    .matches(COMPANY_NAME_REGEX, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS),
})

export type CompanyNameInput = yup.Asserts<typeof CompanyNameInputSchema>
