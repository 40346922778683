import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import { Action, ContractChangeAction } from '../../graphql'
import { getSignatures } from './contract'
import { GoBackButton } from '../../components'
import { SignatureAlert } from './Tabs/helpers'
import { useDetailsStyles } from './useDetailsStyles'
import { DetailsFooter } from './DetailsFooter'
import { ContractChangeTab } from './Tabs/ContractChangeTab'
import { ContractChangeProps } from '../../types'

export const ContractChangePage: FC<{
  actionDetails: Action
  signedCount: number
  handleBack: () => void
}> = ({ actionDetails, signedCount, handleBack }) => {
  const classes = useDetailsStyles()
  const { t } = useTranslation()

  const actionSignatureProps: ContractChangeProps = {
    rawData: actionDetails,
    signatures: getSignatures(actionDetails?.signatures),
    contractChangeData: actionDetails as ContractChangeAction,
  }

  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12} md={10}>
        <Grid item container className={classes.head}>
          <GoBackButton handleBack={handleBack} />
          <Typography variant="h2" className={classes.title}>
            {t('businessDetailsChange', 'Business details change')}
          </Typography>
        </Grid>

        <SignatureAlert signatures={actionSignatureProps.signatures} />
        <ContractChangeTab action={actionSignatureProps} signedCount={signedCount} />
        <DetailsFooter actionSignatureProps={actionSignatureProps} handleBack={handleBack} />
      </Grid>
    </Grid>
  )
}

export default ContractChangePage
