import { useContext, useState, useEffect, useCallback } from 'react'
import { isEqual, isEmpty } from 'lodash'

import { WebApi, WebApiContext } from '../services/web-api'
import {
  WebApiRequestType,
  WebApiRequestReturnType,
  WebApiRequestArgsType,
  WebApiResponse,
} from '../types/web-api.types'

export function useWebApiRequest<
  M extends WebApiRequestType,
  T = WebApiRequestReturnType<WebApi[M]>
>(request: WebApiRequestType, args: WebApiRequestArgsType<WebApi[M]>): WebApiResponse<T> {
  const { webApi } = useContext(WebApiContext)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<string | undefined>()
  const [requestArgs, setRequestArgs] = useState<WebApiRequestArgsType<WebApi[M]> | undefined>()

  useEffect(() => {
    !isEqual(args, requestArgs) && setRequestArgs(args)
  }, [args, requestArgs])

  useEffect(() => {
    if (!isEmpty(requestArgs)) {
      setLoading(true)
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      webApi?.[request](...requestArgs)
        .then((res) => {
          setData((res as unknown) as T)
        })
        .catch((err) => {
          setError(err.response?.data?.error)
        })
        .finally(() => setLoading(false))
    }
  }, [request, requestArgs, webApi])

  const clearError = useCallback(() => setError(undefined), [setError])
  return { data, loading, error, clearError }
}
