import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FormControlLabel, makeStyles, RadioGroup, Theme, Typography } from '@material-ui/core'
import { useReactiveVar } from '@apollo/client'

import { BlackRadio } from '../../Common'
import { activeForm } from '../../../graphql/local'
import { CONTROLLING_PERSON_KEY, ControllingPersonsTypeEnum } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
  description: {
    paddingLeft: theme.spacing(2),
  },
  radioGroup: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(4.5),
    '&:last-child': {
      marginBottom: theme.spacing(1.5),
    },
  },
}))

export const Domiciliary: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const renderForm = useReactiveVar(activeForm)

  const handleRadioChange = useCallback((event): void => {
    const name = (event?.target as HTMLInputElement).value as ControllingPersonsTypeEnum
    localStorage.setItem(CONTROLLING_PERSON_KEY, name)
    activeForm(name)
  }, [])

  return (
    <RadioGroup
      aria-label="gender"
      name="owner"
      value={renderForm ?? 'domiciliaryPersonOwner'}
      onChange={handleRadioChange}
    >
      <Box className={classes.radioGroup}>
        <FormControlLabel
          value={ControllingPersonsTypeEnum.personOwner}
          control={<BlackRadio size="small" />}
          label={
            <Box>
              <Typography variant={'h4'} className={classes.title}>
                {t('domiciliaryPerson', 'Person who owns shares')}
              </Typography>
              <Typography variant={'body1'} className={classes.description}>
                {t(
                  'requirePersonalDescription',
                  'We’ll require personal details and the percentage of ownership (capital shares or voting rights)',
                )}
              </Typography>
            </Box>
          }
        />
      </Box>
      <Box className={classes.radioGroup}>
        <FormControlLabel
          value={ControllingPersonsTypeEnum.legalOwner}
          control={<BlackRadio size="small" />}
          label={
            <Box>
              <Typography variant={'h4'} className={classes.title}>
                {t('domiciliaryLegal', 'Legal entity which owns shares')}
              </Typography>
              <Typography variant={'body1'} className={classes.description}>
                {t(
                  'requireEntityDescription',
                  'We’ll require the entity details and the percentage of ownership',
                )}
              </Typography>
            </Box>
          }
        />
      </Box>
      <Box className={classes.radioGroup}>
        <FormControlLabel
          value={ControllingPersonsTypeEnum.attorneyPower}
          control={<BlackRadio size="small" />}
          label={
            <Box>
              <Typography variant={'h4'} className={classes.title}>
                {t(
                  'personAttorneyText',
                  'Person who holds shares through trust or power of attorney',
                )}
              </Typography>
              <Typography variant={'body1'} className={classes.description}>
                {t(
                  'requirePercentageDescription',
                  'We’ll require details and the percentage of ownership',
                )}
              </Typography>
            </Box>
          }
        />
      </Box>
    </RadioGroup>
  )
}
