import * as yup from 'yup'
import { EMAIL_REGEXP, INCORRECT_EMAIL_FORMAT_MESSAGE } from '../../constants/validations'

import i18n from '../../i18n'

export const EmailInputSchema = yup.object().shape({
  email: yup
    .string()
    .required(i18n.t('emailIsRequired', 'Email is required'))
    .nullable()
    .matches(EMAIL_REGEXP, INCORRECT_EMAIL_FORMAT_MESSAGE),
})

export type EmailInput = yup.Asserts<typeof EmailInputSchema>
