import React, { FC } from 'react'
import { isEqual } from 'lodash'
import clsx from 'clsx'
import { Button, Grid, makeStyles, Theme } from '@material-ui/core'

import { ReactComponent as AddToAppleWallet } from '../../assets/images/icons/addAppleWallet.svg'
import { ReactComponent as AddToGoogleWallet } from '../../assets/images/img/addGoogleWallet.svg'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    marginTop: theme.spacing(3),
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-around',
    textAlign: 'center',
    color: '#000000',
  },
  addToWallet: {
    '&.MuiButton-root': {
      padding: '0px 0px 0px 0px',
      backgroundColor: '#F5F5F5',
    },
    '&.MuiButton-contained:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&.terminated': {
      '& span svg rect': {
        opacity: 0.5,
      },
    },
  },
  walletButton: {
    opacity: 0.5,
  },
  image: {
    width: '168px',
    height: '47px',
    transform: 'translate(0, 0) scale(1.15, 1.05)',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      transform: 'translate(0, 0) scale(1.05, 1)',
    },
  },
  googleIcon: {
    height: '47px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      transform: 'translate(0, 0) scale(1, 1.1)',
    },
  },
}))

type WalletButtonsProps = {
  cardPending: boolean
  cardClosed: boolean
  setOpenWallet: React.Dispatch<React.SetStateAction<string>>
}

const WalletButtonsComponent: FC<WalletButtonsProps> = ({
  cardPending,
  cardClosed,
  setOpenWallet,
}) => {
  const classes = useStyles()
  const disableWallet: boolean = cardPending || cardClosed
  return (
    <Grid item className={classes.container}>
      <Button
        disabled={disableWallet}
        className={clsx(classes.addToWallet, disableWallet && 'terminated')}
        variant="contained"
        disableElevation
        color="default"
        onClick={() => {
          setOpenWallet('apple')
        }}
      >
        <AddToAppleWallet className={clsx(classes.image, disableWallet && classes.walletButton)} />
      </Button>

      <Button
        disabled={disableWallet}
        className={clsx(classes.addToWallet, disableWallet && 'terminated')}
        variant="contained"
        disableElevation
        color="default"
        onClick={() => {
          setOpenWallet('android')
        }}
      >
        <AddToGoogleWallet
          className={clsx(classes.googleIcon, disableWallet && classes.walletButton)}
        />
      </Button>
    </Grid>
  )
}

export const WalletButtons = React.memo(WalletButtonsComponent, isEqual)
