import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'

import {
  Action,
  ActionType,
  ContractAuthorizedPersonDeactivationAction,
  ContractAuthorizedPersonType,
} from '../../graphql'
import { CardholderProps, getSignatures } from './contract'
import { AlertTipItem, GoBackButton } from '../../components'
import { useDetailsStyles } from './useDetailsStyles'
import { DetailsFooter } from './DetailsFooter'
import { CardholderTab } from './Tabs/CardholderTab'
import { ReactComponent as InfoIcon } from '../../assets/images/icons/info_icon.svg'

export const AuthPersonActionDetails: FC<{
  actionDetails: Action
  signedCount?: number
  handleBack: () => void
  isSigned: boolean
}> = ({ actionDetails, signedCount, handleBack, isSigned }) => {
  const classes = useDetailsStyles()
  const { t } = useTranslation()

  const infoPerson = (actionDetails as ContractAuthorizedPersonDeactivationAction)
    .deactivatedAuthorizedPerson
  const signatures = getSignatures(actionDetails?.signatures)

  const ApProps: CardholderProps & ContractAuthorizedPersonType = {
    rawData: actionDetails,
    name: infoPerson?.person?.fullName || 'N/A',
    isAuthorizedSignatory: infoPerson?.isAuthorizedSignatory,
    signatoryRight: infoPerson?.signatoryRight,
    limitedAccessRight: infoPerson?.limitedAccessRight,
    groupSize: infoPerson?.groupSize,
    signatures,
  }
  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12} md={10}>
        <Grid item container className={classes.head}>
          <GoBackButton handleBack={handleBack} />
          <Typography variant="h2" className={classes.title}>
            <Box component="span" className={classes.fullName}>{`${ApProps.name} `}</Box>
            {t('deactivation', 'deactivation')}
          </Typography>
        </Grid>

        {!isSigned && (
          <Box className={classes.alert}>
            <AlertTipItem
              value={t(
                'approveDeactivationAlert',
                'After all the signatories approve the deactivation, this individual will lose their access to the Klarpay Dashboard',
              )}
              iconComponent={<InfoIcon />}
              type={'primary'}
            />
          </Box>
        )}
        <CardholderTab data={ApProps} signedCount={signedCount as number} />
        <DetailsFooter
          actionSignatureProps={ApProps}
          handleBack={handleBack}
          type={ActionType.ContractAuthorizedPersonRequest}
        />
      </Grid>
    </Grid>
  )
}

export default AuthPersonActionDetails
