import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
import { FormProvider, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { find, get, isEmpty, isEqual, orderBy } from 'lodash'
import { Box, Button, FormControl, Grid, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  FormAutocompleteSelect,
  FormAutocompleteSelectMultiCustomValue,
  FormControlledTextField,
  useCommonStyles,
} from '../..'
import { CountryAutocompleteSelect } from '../../Common/Selectors/CountryAutocompleteSelect'
import { BusinessCategoriesList } from '../../../utils/Data'
import { KeyAndLabelSelectType, Partner, PartnersInformationEnum } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    width: '100%',
    maxHeight: 'calc(100% - 132px)',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalBody: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
  btnClose: {
    minWidth: 'auto',
    minHeight: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
    margin: '0 0 20px 0',
    '& .MuiAutocomplete-root': {
      margin: '0 0 20px 0',
    },
  },
  paymentPurpose: {
    minHeight: 68,
  },
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export const NewPartnerModal: React.FC<{
  name: string
  onClose: () => void
  setEditingPartner: React.Dispatch<
    React.SetStateAction<{ i: number; partner: Partner } | undefined>
  >
  setAddingNewPartner: React.Dispatch<React.SetStateAction<boolean>>
  paymentPurposeData: KeyAndLabelSelectType[]
  defaultPartner?: Partner
  onlyOnePartnerRequired: boolean
  type: PartnersInformationEnum
}> = ({
  name,
  onClose,
  paymentPurposeData,
  setEditingPartner,
  setAddingNewPartner,
  defaultPartner,
  onlyOnePartnerRequired,
  type,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const methods = useFormContext()
  const { errors, control, formState, trigger } = methods
  const partner: Partner | undefined = useWatch({
    control,
    name,
  })

  const prevPartnerVal = useMemo(() => partner, [])

  const isFormValid = useMemo(
    () =>
      !!partner?.name &&
      !get(errors, name) &&
      !!partner?.country &&
      !!partner.bankCountry &&
      !!partner.businessSector &&
      !isEmpty(partner.paymentPurpose) &&
      !!partner.website,
    [errors, partner],
  )

  const handleSubmit = async () => {
    if (
      prevPartnerVal?.country !== partner?.country ||
      prevPartnerVal?.bankCountry !== partner?.bankCountry ||
      prevPartnerVal?.businessSector !== partner?.businessSector ||
      !isEqual(prevPartnerVal?.paymentPurpose, partner?.paymentPurpose)
    ) {
      formState.isDirty = true
      await trigger(partner?.paymentPurpose)
    }

    setAddingNewPartner(false)
    setEditingPartner(undefined)
    await trigger(`partners${type}`)
  }

  return (
    <Modal open={true} onClose={onClose}>
      <Box style={modalStyle} className={classes.modal}>
        <FormProvider {...methods}>
          <Box className={classes.modalHeader}>
            <Typography variant={'h4'} className={classes.title}>
              {t('partnerDetails')}
            </Typography>
            <Button onClick={onClose} className={classes.btnClose}>
              <CloseIcon fontSize="small" />
            </Button>
          </Box>

          <Box className={classes.modalBody}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <FormControlledTextField
                  label={t('name', 'Name')}
                  name={`${name}.name`}
                  className={classes.formControl}
                  type="text"
                  fullWidth
                  required={false}
                  defaultValue={defaultPartner?.name || ''}
                  disabled={onlyOnePartnerRequired}
                  data-test="newPartnerModalName"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <CountryAutocompleteSelect
                    name={`${name}.country`}
                    label={t('country', 'Country')}
                    defaultValue={defaultPartner?.country}
                    readOnly={onlyOnePartnerRequired}
                    data-test="newPartnerModalCountry"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <CountryAutocompleteSelect
                    name={`${name}.bankCountry`}
                    label={t(
                      onlyOnePartnerRequired
                        ? 'countryOfYourCompanyCounterpartyBank'
                        : 'countryOfPartnerBank',
                    )}
                    withUnknown
                    defaultValue={defaultPartner?.bankCountry}
                    data-test="newPartnerModalBankCountry"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <FormAutocompleteSelect
                    name={`${name}.businessSector`}
                    label={t(
                      'businessCategoryCounterParty',
                      'Business Category (of counter party)',
                    )}
                    data={orderBy(
                      [
                        ...BusinessCategoriesList.filter((c) => c.available),
                        find(BusinessCategoriesList, { value: 'other' }),
                      ],
                      'label',
                    )}
                    readOnly={onlyOnePartnerRequired}
                    data-test="newPartnerModalBusinessCategory"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={clsx(classes.formControl, classes.paymentPurpose)}>
                  <FormAutocompleteSelectMultiCustomValue
                    name={`${name}.paymentPurpose`}
                    label={t('paymentPurpose', 'Payment purpose')}
                    data={paymentPurposeData}
                    data-test="newPartnerModalPaymentPurpose"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlledTextField
                  label={t(
                    'siteLinkNewPartner',
                    'Website, app store link or social media profile of the counterparty',
                  )}
                  name={`${name}.website`}
                  className={classes.boxInput}
                  type="text"
                  fullWidth
                  required={false}
                  defaultValue={defaultPartner?.website || ''}
                  disabled={onlyOnePartnerRequired}
                  data-test="newPartnerModalWebsite"
                />
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.modalFooter}>
            <Button variant="contained" disableElevation color="default" onClick={onClose}>
              {t('cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              type="button"
              color="primary"
              disableElevation
              disabled={!isFormValid}
              onClick={handleSubmit}
              data-test="newPartnerModalSubmit"
            >
              {t('submit', 'Submit')}
            </Button>
          </Box>
        </FormProvider>
      </Box>
    </Modal>
  )
}
