import React, { FC, useCallback, CSSProperties } from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles<Theme, { isFullWidth: boolean }>((theme: Theme) => ({
  button: {
    width: ({ isFullWidth }) => (isFullWidth ? '100%' : 'auto'),
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: () => '100%',
    },
  },
}))

export const FiltersResetBtn: FC<{
  resetPageState: () => void
  isDisabled?: boolean
  fullWidth?: boolean
  style?: CSSProperties
}> = ({ resetPageState, isDisabled = false, fullWidth = false, style }) => {
  const { t } = useTranslation()
  const classes = useStyles({ isFullWidth: fullWidth })

  const resetHandler = useCallback(() => {
    resetPageState()
  }, [resetPageState])

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={isDisabled}
      disableElevation
      onClick={resetHandler}
      style={style}
      className={classes.button}
    >
      {t('reset', 'Reset')}
    </Button>
  )
}
