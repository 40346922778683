import React, { FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ControlledTextFieldProps } from '../../../types'
import { isEmpty, isEqual, orderBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Check } from '@material-ui/icons'
import {
  Box,
  Button,
  ClickAwayListener,
  FormHelperText,
  Grow,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Tooltip,
} from '@material-ui/core'

import { currencyFormat, getCurrencySign, negativeCheck } from '../../../utils'
import { PATH_PARAMS } from '../../../routes/paths'
import {
  Account,
  AccountStatus,
  GetContractAccountsQuery,
  Maybe,
  useGetContractAccountsQuery,
} from '../../../graphql'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  popper: {
    zIndex: 1503,
    width: '100%',
  },
  paper: {
    marginRight: 0,
    maxHeight: 400,
    maxWidth: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    borderRadius: 0,
    boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
  },
  item: {
    fontSize: 14,
    padding: theme.spacing(1, 1, 1, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    cursor: 'pointer',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    '& .MuiSvgIcon-root': {
      color: '#999999',
      position: 'absolute',
      right: 15,
    },
    '&.Mui-disabled': {
      pointerEvents: 'inherit',
      // cursor: 'pointer',
    },
  },
  itemText: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    flexWrap: 'wrap',
  },
  popperTool: {
    zIndex: 1555,
    whiteSpace: 'nowrap',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  arrow: {
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 12,
    right: 12,
    margin: 'auto',
    zIndex: 2,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(153, 153, 153, 0.1)',
      borderRadius: '20px',
    },
    '& .MuiSvgIcon-root': {
      color: '#999',
    },
  },
  selectButton: {
    ...theme.typography.body1,
    justifyContent: 'flex-start',
    textAlign: 'start',
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(1.5),
    minHeight: 42,
    width: '100%',
    position: 'relative',
    zIndex: 5,
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.20)',
    transition: 'border-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& .MuiButton-label': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(4),
    },
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '0 2px 0 rgba(0, 0, 0, 1)',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  error: {
    boxShadow: `0 2px 0 ${theme.palette.error.main}`,
  },
  helperText: {
    color: theme.palette.error.main,
  },
  label: {
    ...theme.typography.body1,
    lineHeight: '14px',
    color: '#999',
    display: 'block',
    paddingLeft: 14,
    paddingRight: 14,
    transformOrigin: 'top left',
    transform: 'translate(0, 24px) scale(1)',
    transition:
      'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  shrink: {
    transform: 'translate(0, 10px) scale(0.857)',
    transformOrigin: 'top left',
  },
  labelError: {
    color: theme.palette.error.main,
  },
  preselected: {
    backgroundColor: '#ebebeb',
  },
}))

interface AccountsAutocompleteSelectProps {
  name: string
  label: string
  filterAccs?: (accountsData: GetContractAccountsQuery | undefined) => [] | Maybe<Account>[]
  readOnly?: boolean
  defaultValue?: Maybe<string | number | undefined>
  classPut?: string
  setAccount?: React.Dispatch<React.SetStateAction<Account | null | undefined>>
  batchCurrency?: Maybe<string> | undefined
  isFromPay: boolean
  isPayoutAccount?: boolean
  setIsNewPayoutAccount?: React.Dispatch<React.SetStateAction<boolean>>
}

const AccountsAutocompleteSelectComponent: FC<
  AccountsAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  label,
  shouldValidateParam,
  defaultValue,
  rules,
  filterAccs,
  setAccount,
  batchCurrency,
  isFromPay,
  readOnly,
  isPayoutAccount = false,
  setIsNewPayoutAccount,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState('')
  const [preselectedOption, setPreselectedOption] = useState<Account | null>(null)

  const [accountId, setAccountId] = useState<string | number>('')
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { errors, register, setValue } = useFormContext()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { data: accountsData } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: [AccountStatus.Active],
      search: searchValue,
    },
  })

  const filteredData = (filterAccs && filterAccs(accountsData)) ?? accountsData?.accounts
  const sortedData = useMemo(() => orderBy(filteredData, ['balance.available'], ['desc']), [
    filteredData,
  ])
  const error = errors ? errors[name || 'AccountsAutocompleteSelect'] : null
  const selectAccount = useMemo(() => {
    const selectAcc = accountsData?.accounts?.find((item) => item?.id === accountId)
    if (selectAcc) {
      const normalizedBalance = `${getCurrencySign(selectAcc?.currency as string)} ${currencyFormat(
        selectAcc?.balance?.available as number,
      )}`

      return (
        <>
          <span data-test="accForPayName">{selectAcc?.alias}&nbsp;</span>
          <b>{t(selectAcc?.fundsType as string)}&nbsp;</b>
          <span>{selectAcc?.iban}&nbsp;</span>
          <b className={negativeCheck(selectAcc?.balance?.available)}>{normalizedBalance}</b>
        </>
      )
    }
    return <></>
  }, [accountId, accountsData?.accounts])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
    setSearchValue('')
    setPreselectedOption(null)
  }

  const changeAccount = useCallback(
    (account) => {
      if (!!batchCurrency && account?.currency?.toUpperCase() !== batchCurrency?.toUpperCase()) {
        return
      }
      setAccountId(account?.id)
      isFromPay && localStorage.setItem('selectedAccountId', String(account.id))
      setValue(name, account ? account.id : null, {
        shouldValidate: shouldValidateParam ?? true,
        shouldDirty: true,
      })
      accountsData?.accounts?.forEach((acc) => {
        if (acc?.id === account.id) {
          setAccount && setAccount(acc)
        }
      })
      setOpen(false)
      setSearchValue('')
    },
    [accountsData?.accounts],
  )

  const handlePressKey = (event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        const account =
          sortedData?.length === 1 && !preselectedOption ? sortedData[0] : preselectedOption

        account && changeAccount(account)
        setSearchValue('')
        setPreselectedOption(null)
        break

      case 'ArrowDown':
        const nextPreselectedIndex = preselectedOption
          ? (sortedData as Account[]).findIndex(({ id }) => id === preselectedOption.id) + 1
          : 0

        setPreselectedOption(sortedData[nextPreselectedIndex])
        break

      case 'ArrowUp':
        const prevPreselectedIndex = preselectedOption
          ? (sortedData as Account[]).findIndex(({ id }) => id === preselectedOption.id) - 1
          : 0

        setPreselectedOption(sortedData[prevPreselectedIndex])
        break

      default:
        return
    }
  }

  useEffect(() => {
    register(name, { ...(rules ?? {}) })
  }, [register, name, rules])

  useEffect(() => {
    setAccountId(defaultValue || '')
    setValue(`${name}`, defaultValue)
  }, [defaultValue])

  return (
    <Box className={classes.root} data-test={name}>
      <label
        htmlFor={`${name}-composition-button`}
        className={`${classes.label} ${selectAccount ? classes.shrink : ''} ${
          !!error && classes.labelError
        }`}
      >
        {label}
      </label>
      <Button
        ref={anchorRef}
        id={`${name}-composition-button`}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`${classes.selectButton} ${!!error && classes.error}`}
        disabled={readOnly}
      >
        {selectAccount}
        <Box component="span" className={classes.arrow}>
          {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
        </Box>
      </Button>
      {!!error && (
        <FormHelperText id="component-helper-text" className={classes.helperText}>
          {error?.message}
        </FormHelperText>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <List id={`menu-list-grow-${name}`}>
                  <ListItem>
                    <TextField
                      onChange={(e) => {
                        setSearchValue(e.target.value)
                      }}
                      onKeyDown={handlePressKey}
                      label={t('selectAccount', 'Select Account')}
                      variant={'standard'}
                      fullWidth
                      autoFocus
                    />
                  </ListItem>
                  {!isEmpty(accountsData?.accounts) &&
                    sortedData?.map((account) => {
                      const { id, alias, iban, currency, balance, fundsType } = account as Account
                      const normalizedBalance = `${getCurrencySign(
                        currency as string,
                      )} ${currencyFormat(balance?.available as number)}`

                      const disableListener = !!batchCurrency
                        ? batchCurrency?.toUpperCase() === currency?.toUpperCase()
                        : true

                      return (
                        <Tooltip
                          key={id}
                          title={`${t('accCurrMismatchTxt')}`}
                          arrow
                          placement={'top'}
                          classes={{ popper: classes.popperTool, tooltip: classes.noMaxWidth }}
                          disableHoverListener={disableListener}
                          disableFocusListener={disableListener}
                          disableTouchListener={disableListener}
                        >
                          <ListItem
                            onClick={() => {
                              changeAccount(account)
                            }}
                            button
                            disabled={
                              !!batchCurrency &&
                              batchCurrency.toUpperCase() !== currency?.toUpperCase()
                            }
                            className={`${classes.item} ${
                              (account as Account).id === preselectedOption?.id
                                ? classes.preselected
                                : ''
                            }`}
                          >
                            <Box className={classes.itemText}>
                              <span>{alias}&nbsp;</span>
                              <b>{t(fundsType as string)}&nbsp;</b>
                              <span>{iban}&nbsp;</span>
                              <b className={negativeCheck(balance?.available)}>
                                {normalizedBalance}
                              </b>
                            </Box>
                            {accountId === id && <Check fontSize={'small'} />}
                          </ListItem>
                        </Tooltip>
                      )
                    })}
                  {isPayoutAccount && (
                    <ListItem
                      onClick={() => {
                        setAccount && setAccount(undefined)
                        setIsNewPayoutAccount && setIsNewPayoutAccount(true)
                      }}
                      button
                      className={classes.item}
                    >
                      <span>+&nbsp;{t('addExternalAccount')}</span>
                    </ListItem>
                  )}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export const AccountsAutocompleteSelect = React.memo(AccountsAutocompleteSelectComponent, isEqual)
