import React, {
  ChangeEventHandler,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { filter, isEmpty, startsWith, toLower } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, List, ListItem, ListItemText, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Search from '@material-ui/icons/Search'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import { Check } from '@material-ui/icons'
import Grow from '@material-ui/core/Grow'
import { AuthorizedPersonStatuses } from '../../../graphql'

import { CardholderType, ControlledTextFieldProps } from '../../../types'
import { ReactComponent as IconCardholder } from '../../../assets/images/icons/iconNewCardholder.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  rootPaper: {
    width: '100%',
    zIndex: 9,
    backgroundColor: 'white',
  },
  formControl: {
    width: '100%',
    position: 'relative',
  },
  input: {
    width: '100%',
    position: 'relative',
    zIndex: 2,
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(4),
    },
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(0, 10px) scale(1)',
    },
  },
  iconInput: {
    cursor: 'pointer',
    width: 48,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 24px)',
    bottom: 12,
    right: 0,
    margin: 'auto',
    zIndex: 2,
    '& .MuiSvgIcon-root': {
      '&:hover': {
        background: 'rgba(153, 153, 153, 0.1)',
        borderRadius: '20px',
      },
    },
  },
  searchField: {
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(0.5, 1, 1, 1),
      padding: theme.spacing(0),
      minHeight: 'auto',
    },
    '& .MuiInput-root': {
      paddingLeft: theme.spacing(1.5),
    },
    '& .MuiInputBase-input': {
      height: 48,
      boxSizing: 'border-box',
    },
    '& .MuiInput-underline:before': {
      height: '100%',
      border: '1px solid #D9D9D9',
    },
    '& .MuiSvgIcon-root': {
      fill: '#999',
    },
  },
  item: {
    padding: theme.spacing(1, 1.5),
    '&.block': {
      opacity: '0.5',
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
  itemBold: {
    '& span': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '150px',
    },
  },
  listWrapper: {
    overflowY: 'auto',
    maxHeight: '300px',
  },
  preselected: {
    backgroundColor: '#ebebeb',
  },
}))

const defaultRules = {} //{ required: true }

interface CardOwnerAutocompleteSelectProps {
  name: string
  data: CardholderType[]
  classPut?: string
  // handleOpen: () => void
  isOpen: boolean
  withAddMore?: boolean
  setIsOpenModal: (val: boolean) => void
}

export const CardOwnerAutocompleteSelect: FC<
  Partial<CardOwnerAutocompleteSelectProps & ControlledTextFieldProps>
> = ({
  name = '',
  data = [],
  isOpen,
  setIsOpenModal,
  rules,
  classPut,
  withAddMore = true,
  ...rest
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [selectedLabel, setSelectedLabel] = useState<string>('')
  const [preselectedOption, setPreselectedOption] = useState<CardholderType | null>(null)

  const { t } = useTranslation()
  const classes = useStyles()

  const { errors, register, setValue, watch } = useFormContext()
  const value = watch(name)
  const error = errors ? errors[name || 'cardOwner'] : null

  const optionsList = filter([...data], (c) => startsWith(toLower(c.label), toLower(search)))

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
    setPreselectedOption(null)
    setSearch('')
  }

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    return setSearch(event.target.value)
  }, [])

  const handleOpenModal = () => {
    if (setIsOpenModal) {
      setIsOpenModal(true)
    }
  }
  const changeOwner = useCallback(
    (id) => {
      setValue(name, id, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setOpen(false)
    },
    [name, setValue],
  )

  const checkIsCardHolderAvailable = (person: CardholderType) =>
    person.status === AuthorizedPersonStatuses.Activated &&
    person.isPhoneVerified &&
    person.isPhoneVerified &&
    (!!person.limitedAccessRight || person.videoVerified)

  const handlePressKey = (event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        const cardOwner =
          optionsList.filter(checkIsCardHolderAvailable).length === 1 && !preselectedOption
            ? optionsList[0]
            : preselectedOption

        cardOwner && checkIsCardHolderAvailable(cardOwner) && changeOwner(cardOwner.key)
        setSearch('')
        setPreselectedOption(null)
        break

      case 'ArrowDown':
        const nextPreselectedIndex = preselectedOption
          ? optionsList.findIndex(({ key }) => key === preselectedOption.key) + 1
          : 0

        checkIsCardHolderAvailable(optionsList[nextPreselectedIndex]) &&
          setPreselectedOption(optionsList[nextPreselectedIndex])
        break

      case 'ArrowUp':
        const prevPreselectedIndex = preselectedOption
          ? optionsList.findIndex(({ key }) => key === preselectedOption.key) - 1
          : 0

        checkIsCardHolderAvailable(optionsList[prevPreselectedIndex]) &&
          setPreselectedOption(optionsList[prevPreselectedIndex])
        break

      default:
        return
    }
  }

  useEffect(() => {
    if (isOpen) {
      setOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    const labelOwner = data && data.find((v) => v.key === value)?.label
    setSelectedLabel(labelOwner as string)
  }, [data, value])

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })
  }, [register, name, rules])

  return (
    <>
      <div className={`${classes.root} ${classPut ? classPut : ''}`}>
        <FormControl className={`${classes.formControl}`}>
          <TextField
            name={name}
            value={selectedLabel}
            onClick={handleToggle}
            disabled={open}
            className={classes.input}
            error={!!error}
            helperText={error ? error.message : null}
            inputRef={anchorRef}
            {...rest}
          />
          <Box component="span" className={classes.iconInput} onClick={handleToggle}>
            {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </Box>
        </FormControl>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.rootPaper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <List id={`menu-list-grow-${name}`} className={classes.listWrapper}>
                    <FormControl className={classes.searchField}>
                      <TextField
                        InputProps={{ startAdornment: <Search fontSize={'small'} /> }}
                        onChange={handleSearch}
                        onKeyDown={handlePressKey}
                        value={search}
                        variant={'standard'}
                        autoFocus
                        placeholder={t('search', 'Search')}
                        data-test="cardOwnerSearch"
                      />
                    </FormControl>
                    {!isEmpty(optionsList) &&
                      optionsList.map(
                        ({
                          key,
                          label,
                          isPhoneVerified,
                          videoVerified,
                          limitedAccessRight,
                          status,
                        }) => {
                          const accessRightCheck =
                            isPhoneVerified && (!!limitedAccessRight || videoVerified)

                          return (
                            <ListItem
                              key={key}
                              onClick={() => {
                                changeOwner(key)
                              }}
                              button
                              disabled={status !== AuthorizedPersonStatuses.Activated}
                              className={`${classes.item} ${accessRightCheck ? '' : 'block'} ${
                                key === preselectedOption?.key ? classes.preselected : ''
                              }`}
                            >
                              <ListItemText>{`${label} ${
                                accessRightCheck ? '' : '- ' + t('unverified')
                              }`}</ListItemText>
                              {value === key && <Check fontSize={'small'} />}
                            </ListItem>
                          )
                        },
                      )}
                    {withAddMore && (
                      <ListItem button className={classes.item} onClick={handleOpenModal}>
                        <ListItemText className={classes.itemBold}>
                          <IconCardholder /> {t('newCardholder', 'New cardholder')}
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  )
}
