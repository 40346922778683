import { documentTypes } from '@klarpay/enums'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContractInformationRequestStatus,
  ContractInformationRequestType,
  GetContractInformationRequestsQuery,
} from '../../../graphql'

type Props = {
  requests: GetContractInformationRequestsQuery['contractInformationRequests'] | undefined
  title: string
  expanded?: boolean
  onSelect?: (id: string | number) => void
  statusLabel: (
    status: ContractInformationRequestStatus,
    type: ContractInformationRequestType,
  ) => string
}

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
  summary: {
    paddingLeft: theme.spacing(1),
  },
  accordion: {
    boxShadow: 'none',
    '&::before': {
      height: 0,
    },
  },
  tableCell: {
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    cursor: 'pointer',
  },
}))

export const ContractInformationRequestsList: React.FC<Props> = ({
  requests,
  title,
  expanded,
  onSelect,
  statusLabel,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (!requests?.length) {
    return null
  }

  return (
    <Accordion defaultExpanded={expanded} classes={{ root: classes.accordion }} square>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summary }}>
        <Typography variant="h4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.noPadding }}>
        <Table>
          <TableBody>
            {requests.map(({ id, title, status, type, documentType }, idx) => {
              const titleLabel =
                title || (documentType ? documentTypes[documentType].label : undefined)
              return (
                <TableRow key={idx} data-test={'pendingRequests'}>
                  <TableCell
                    padding="none"
                    width="100%"
                    classes={{ paddingNone: classes.tableCell }}
                  >
                    {titleLabel}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="right">
                    <Chip
                      variant="outlined"
                      size="small"
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      label={statusLabel(status!, type!)}
                      className={clsx(`roundedPill ${status}`)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      onClick={() => onSelect?.(id)}
                      className={classes.tableRow}
                      data-test={
                        status === ContractInformationRequestStatus.Open ? 'replyBtn' : 'viewBtn'
                      }
                    >
                      <Typography variant="body1" color="primary">
                        {status === ContractInformationRequestStatus.Open
                          ? t('reply', 'Reply')
                          : t('view', 'View')}
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
