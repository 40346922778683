import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { useCurrentUser } from '../../../hooks'
import { CountryPhoneSelect, FormControlledTextField, GridRow } from '../../Common'
import { useCommonStyles } from './PreAssessmentQuestionnaire'

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1.5, 1.5),
    },
  },
}))

const ContactPersonComponent: FC = () => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const [currentUserIsContactPerson, setCurrentUserIsContactPerson] = useState<boolean>(false)

  const currentUser = useCurrentUser()

  const methods = useFormContext()
  const { setValue, clearErrors } = methods

  useEffect(() => {
    const { fullName, email, phone } = currentUser || {}
    if (currentUserIsContactPerson) {
      setValue('fullName', fullName)
      setValue('email', email)
      setValue('contactPersonPhoneNumber', phone)
    } else {
      setValue('fullName', '')
      setValue('email', '')
      setValue('contactPersonPhoneNumber', '')
    }
  }, [currentUserIsContactPerson, setValue, currentUser])

  const handleAddMyself = useCallback(
    (_, checked) => {
      clearErrors(['fullName', 'email', 'contactPersonPhoneNumber'])
      setCurrentUserIsContactPerson(checked)
    },
    [setCurrentUserIsContactPerson],
  )

  return (
    <>
      <Box mt={4}>
        <Typography variant="h3" className={classes.title}>
          {t('contactPerson', 'Contact Person')}
        </Typography>
      </Box>

      <Box mb={1.75} data-test="iAmAddingMyself">
        <FormControlLabel
          control={
            <Checkbox
              name="myself"
              icon={<Unchecked />}
              checkedIcon={<Checked />}
              checked={currentUserIsContactPerson}
            />
          }
          onChange={handleAddMyself}
          label={t('iAmAddingMyself', 'I am adding myself')}
          data-test="iAmAddingMyselfCheckBox"
          className={classes.checkbox}
        />
      </Box>

      <GridRow>
        <FormControlledTextField
          label={t('fullName', 'Full Name')}
          name="fullName"
          type="text"
          fullWidth
          required={false}
          disabled={currentUserIsContactPerson}
          multiline
          data-test="fullName"
        />
      </GridRow>

      <GridRow>
        <FormControlledTextField
          label={t('email', 'Email')}
          name="email"
          type="text"
          fullWidth
          required={false}
          disabled={currentUserIsContactPerson}
          data-test="email"
        />
      </GridRow>

      <GridRow>
        <CountryPhoneSelect
          name="contactPersonPhoneNumber"
          label={t('phoneNumber', 'Phone Number')}
          data-test="contactPersonPhoneNumber"
        />
      </GridRow>
    </>
  )
}

export const ContactPerson = React.memo(ContactPersonComponent)
