import * as yup from 'yup'
import {
  OVERALL_PREDICTED_AMOUNT_CANT_BE_0,
  OVERALL_VALUE_SHOULDNT_BE_GREATER_THAN_100,
  PRE_ASSESSMENT_SUM_OF_PERCENTS0,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import { isEmpty, isUndefined } from 'lodash'
import { findNumberedObjectKeys } from '../../utils'
import { BusinessRegionsEnumErrorTypes, CardPaymentsSelectOptions } from '../../types'

interface VirtualFormFields {
  [key: string]: unknown
}

export const CardPaymentAndApmSchema = (virtualFormFields: VirtualFormFields) => {
  const dynamicApmCurrentlyFields: string[] = findNumberedObjectKeys(
    virtualFormFields,
    'apmCurrentlyInp',
  )
  const dynamicApmRequiredFields: string[] = findNumberedObjectKeys(
    virtualFormFields,
    'apmRequiredInp',
  )
  const dynamicMerchantWebsitesFields: string[] = findNumberedObjectKeys(
    virtualFormFields,
    'merchantWebsitesInp',
  )
  const dynamicBusinessCategoryRegionFields =
    'businessRegions' in virtualFormFields
      ? (virtualFormFields['businessRegions'] as {
          businessCategoryRegion: string
        }[]).map(({ businessCategoryRegion }) => businessCategoryRegion)
      : []

  return yup.object().shape({
    cardPaymentsApm: yup.string(),
    businessRegions: yup
      .mixed()
      .notRequired()
      .when('cardPaymentsApm', {
        is: CardPaymentsSelectOptions.required,
        then: yup
          .mixed()
          .test(
            BusinessRegionsEnumErrorTypes.businessRegions100,
            OVERALL_VALUE_SHOULDNT_BE_GREATER_THAN_100,
            function (value) {
              const percentSum = (value as Record<string, string>[])?.reduce(
                (acc, field) => acc + Number(field.businessCategoryPercentage),
                0,
              )
              return percentSum <= 100
            },
          )
          .test(
            BusinessRegionsEnumErrorTypes.businessRegions0,
            PRE_ASSESSMENT_SUM_OF_PERCENTS0,
            function (value) {
              const percentSum = (value as Record<string, string>[])?.reduce(
                (acc, field) => acc + Number(field.businessCategoryPercentage),
                0,
              )
              return percentSum > 0
            },
          )
          .test(
            BusinessRegionsEnumErrorTypes.valueIsNotEmptyPercentage,
            THIS_FIELD_IS_REQUIRED_MESSAGE,
            function (value) {
              let valid = true
              ;(value as Record<string, string>[]).forEach((itemValue) => {
                if (!itemValue.businessCategoryPercentage) {
                  valid = false
                }
              })
              return valid
            },
          ),
      }),
    trxCurrencies: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    settlementCurrencies: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    totalPredictedMonthlyVolumes: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup
        .mixed()
        .test(
          'totalPredictedMonthlyVolumes0',
          OVERALL_PREDICTED_AMOUNT_CANT_BE_0,
          (val) => val > 0,
        ),
    }),
    ...dynamicBusinessCategoryRegionFields.reduce((schema, value, currentIndex) => {
      return {
        ...schema,
        [`businessRegions[${currentIndex}].businessCategoryRegion`]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup
              .string()
              .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, () => !isEmpty(value)),
          }),
      }
    }, {}),
    apmCurrentlyInp10: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    apmCurrentlyInp20: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    apmRequiredInp10: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    apmRequiredInp20: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    ...dynamicApmCurrentlyFields.reduce((schema, fieldName) => {
      if (isUndefined(virtualFormFields[fieldName])) return schema

      return {
        ...schema,
        [fieldName]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
          }),
      }
    }, {}),
    ...dynamicApmRequiredFields.reduce((schema, fieldName) => {
      if (isUndefined(virtualFormFields[fieldName])) return schema

      return {
        ...schema,
        [fieldName]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
          }),
      }
    }, {}),
    selAffiliatesTraffic: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    affiliatesList: yup.mixed().when('selAffiliatesTraffic', {
      is: 'Yes',
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    selCredentialBillingMethod: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    credentialDetailsExplanation: yup
      .string()
      .nullable()
      .when('selCredentialBillingMethod', {
        is: 'Yes',
        then: yup.string().nullable().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
      }),
    integrationTypeSelect: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    pciProvidersName: yup.mixed().when('pciCompliance', {
      is: 'from3rdParty',
      then: yup.string().nullable().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    processedHistory: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    currentProcessor: yup.mixed().when('processedHistory', {
      is: 'Yes',
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    periodWithProcessor: yup.mixed().when('processedHistory', {
      is: 'Yes',
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    historyStatement: yup.array().when('processedHistory', {
      is: 'Yes',
      then: yup
        .array()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .min(1, THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    ...dynamicMerchantWebsitesFields.reduce((schema, fieldName) => {
      return {
        ...schema,
        [fieldName]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup
              .string()
              .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val)),
          }),
      }
    }, {}),
  })
}
