import React, { FC } from 'react'
import { Grid, Box, makeStyles, Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ContainerWithImage, Logo } from '../Common'
import { useHistory } from 'react-router'
import INDIVIDUALS_IMAGE from '../../assets/images/img/individuals.jpg'
import { APP_PATHS } from '../../routes/paths'
import config from '../../config'

const useStyles = makeStyles(() => ({
  text: {
    margin: 0,
    marginBottom: 24,
  },
}))

export const IndividualsPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const handleSend = () => {
    history.push(APP_PATHS.signup)
  }

  return (
    <ContainerWithImage image={INDIVIDUALS_IMAGE} isRightMenu={false} classWrapper="content">
      <Grid item xs={12}>
        <Grid item md={12} className="logo hiddenMdUp">
          <a href={APP_PATHS.root}>
            <Logo />
          </a>
        </Grid>
        <Box display="flex" justifyContent="center" mb={7}>
          <Typography variant={'h1'}>{t('letsStayInTouch', 'Let’s stay in touch')}!</Typography>
        </Box>

        <Grid item xs={12}>
          <p className={classes.text}>
            {t(
              'thankYouForRegisteringOnKlarpay',
              'Thank you for registering on {{val1}}. Currently we don’t offer services for individuals but this is on our roadmap. We will send you an email as soon as we are ready.',
              {
                val1: config.name,
              },
            )}
          </p>
          <p className={classes.text}>
            {t(
              'accidentallySelectedPersonalAccount',
              'In case you accidentally selected “personal account” option on Sign Up, you can proceed to registering a company account clicking on the button below.',
            )}
          </p>
        </Grid>

        <Grid container alignItems="center">
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSend}
          >
            {t('registerMyCompanyAccount', 'Register my company account')}
          </Button>
        </Grid>
      </Grid>
    </ContainerWithImage>
  )
}

export default IndividualsPage
