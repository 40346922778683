import { isEmpty } from 'lodash'
import React, { FC, useCallback, useState, useEffect } from 'react'
import { Grid, Box, makeStyles, Typography } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { Logo, FormTextField, CommonTipItem, ContainerWithImage, ResendButton } from '../Common'
import { ReactComponent as IconConfirmed } from '../../assets/images/icons/confirmed.svg'
import { RestorePasswordPageInput, RestorePasswordPageInputSchema } from '../../schemes'
import { rxiosLogin } from '../../resources'
import RESTORE_IMAGE from '../../assets/images/img/restorepsw-img.svg'
import { APP_PATHS } from '../../routes/paths'
import { resendDelay } from '../../graphql/local'
import { millisecondsToSeconds } from 'date-fns'
import { focusKeyPressNext } from '../../utils'
import config from '../../config'

const useStyles = makeStyles(() => ({
  btn_margin: {
    marginTop: 48,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  info: {
    border: '1px solid #ADDEC9',
    '&>div': {
      padding: '5px 10px',
    },
  },
  notAsterisk: {
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
}))

export const RestorePasswordPage: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<RestorePasswordPageInput>({
    mode: 'onBlur',
    resolver: yupResolver(RestorePasswordPageInputSchema),
  })
  const { formState, errors, clearErrors } = methods
  const classes = useStyles()
  const [linkSent, setLinkSent] = useState<boolean>(false)
  const [btnDisable, setBtnDisable] = useState<boolean>(false)
  const [secondsLeft, setSecondsLeft] = useState(0)

  const dillyBeforeAllowResentMail = localStorage.getItem('dillyBeforeAllowResentMail')

  const handleSendSteps = useCallback(() => {
    setLinkSent(true)
    setBtnDisable(true)
    setSecondsLeft(Number(dillyBeforeAllowResentMail) || millisecondsToSeconds(resendDelay()))
  }, [setLinkSent, setBtnDisable, setSecondsLeft, dillyBeforeAllowResentMail])

  useEffect(() => {
    if (dillyBeforeAllowResentMail) {
      handleSendSteps()
    }
  }, [dillyBeforeAllowResentMail])

  const handleSend = useCallback(
    async (formData: RestorePasswordPageInput) => {
      rxiosLogin
        .post('password-reset/start', {
          email: formData.email,
        })
        .subscribe(
          () => {
            handleSendSteps()
          },
          () => {
            setLinkSent(false)
          },
        )
    },
    [setLinkSent, handleSendSteps],
  )

  const onChange = useCallback(() => {
    if (!dillyBeforeAllowResentMail) {
      setBtnDisable(false)
      clearErrors()
    }
  }, [dillyBeforeAllowResentMail, setBtnDisable, clearErrors])

  const handlerEnter = useCallback(
    (ev, fState) => {
      return focusKeyPressNext(ev, fState)
    },
    [formState.isDirty],
  )

  let timer: ReturnType<typeof setInterval>

  useEffect(() => {
    const onUnload = () => {
      if (secondsLeft) {
        localStorage.setItem('dillyBeforeAllowResentMail', String(secondsLeft))
      }
    }

    window.addEventListener('beforeunload', onUnload)

    return () => window.removeEventListener('beforeunload', onUnload)
  }, [secondsLeft])

  useEffect(() => {
    const millisecondToSecond =
      Number(dillyBeforeAllowResentMail) || millisecondsToSeconds(resendDelay())

    if (linkSent) {
      setSecondsLeft(millisecondToSecond)

      const timer = setInterval(() => {
        setSecondsLeft((prevSecondsLeft) => {
          if (prevSecondsLeft === 0) {
            setLinkSent(false)
            setBtnDisable(false)
            clearInterval(timer)
            localStorage.removeItem('dillyBeforeAllowResentMail')
            return 0
          }
          return prevSecondsLeft - 1
        })
      }, 1000)
    }

    return () => {
      clearInterval(timer)
    }
  }, [linkSent])

  return (
    <ContainerWithImage
      image={config.images.restorePassword || RESTORE_IMAGE}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid item xs={12} data-test="restorePasswordPage">
        <Grid item md={12} className="logo hiddenMdUp">
          <a href={APP_PATHS.root}>
            <Logo />
          </a>
        </Grid>
        <Box display="flex" justifyContent="center" mb={7}>
          <Typography variant={'h2'}>{t('restorePassword', 'Restore password')}</Typography>
        </Box>

        {linkSent && (
          <Grid item xs={12} className={classes.info} data-test="restorationTitle">
            <CommonTipItem value={'Restoration link sent'} iconComponent={<IconConfirmed />} />
          </Grid>
        )}

        <FormProvider {...methods}>
          <form
            id="forNextFocus"
            onSubmit={methods.handleSubmit(handleSend)}
            onKeyDown={(event) => {
              handlerEnter(event, formState)
            }}
          >
            <FormTextField
              label={t('enterEmail', 'Enter email')}
              name="email"
              type="email"
              fullWidth
              className={classes.notAsterisk}
              onChange={onChange}
              data-test="enterEmail"
            />
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              <ResendButton
                className={classes.btn_margin}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                disabled={btnDisable || !isEmpty(errors) || !!secondsLeft}
                secondsLeft={secondsLeft}
                linkSent={linkSent}
                data-test="sendLink"
              >
                {t('sendLink', 'Send link')}
              </ResendButton>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </ContainerWithImage>
  )
}

export default RestorePasswordPage
