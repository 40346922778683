import React, { Suspense, Fragment, useEffect } from 'react'
import { Switch, Route, RouteProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import NProgress from 'nprogress'

import { Loader } from '../components'
import { IAppRouteSetting } from '../types'

import { AuthenticatedRoutes, NonAuthenticatedRoutes } from './AppRoutes'

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}))

const RouteProgress: React.FC<RouteProps> = (props) => {
  nprogressStyle()

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  })

  useEffect(() => {
    NProgress.done()
    return () => {
      NProgress.start()
    }
  }, [])

  return <Route {...props} />
}

export const renderRoutes = (routeSettings: IAppRouteSetting[] = []): React.ReactNode => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routeSettings.map((route, i) => {
          const Component = route.component
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : Component ? (
                      <Component {...props} />
                    ) : undefined}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}

export const AppRoutesSettings: IAppRouteSetting[] = [
  ...NonAuthenticatedRoutes,
  AuthenticatedRoutes,
]
