import React, { FC, useCallback, useState } from 'react'
import { Grid, Box, makeStyles, Typography } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { ContainerWithImage, FormTextField, Logo } from '../Common'
import { ReactComponent as IconAttention } from '../../assets/images/icons/attention.svg'
import { CommonTipItem } from '../Common'
import { UnusualSignInPageInput, UnusualSignInPageInputSchema } from '../../schemes'
import UNUSUAL_IMAGE from '../../assets/images/img/unusual_signin.svg'
import config from '../../config'

const useStyles = makeStyles(() => ({
  info: {
    border: '1px solid #FED7D2',
    '&>div': {
      padding: '5px 10px',
    },
  },
  digits: {
    width: 56,
    margin: 0,
    padding: '43px 0 20px',
    '& .MuiInputBase-input': {
      padding: 0,
      paddingBottom: 20,
      fontSize: 36,
      lineHeight: 1.13,
      textAlign: 'center',
    },
  },
}))

//TODO check usage of this component
export const UnusualSignInPage: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<UnusualSignInPageInput>({
    mode: 'onChange',
    resolver: yupResolver(UnusualSignInPageInputSchema),
  })
  const classes = useStyles()
  const [setLoading] = useState<boolean>(false)

  const signInHandler = useCallback(
    async (formData: UnusualSignInPageInput) => {
      // eslint-disable-next-line no-console
      console.log(formData)

      // let cleanupFunction = false
      // setLoading(true)

      // const signUpData = {
      //   email: `${formData.email}`,
      //   privacyAcceptance: true,
      //   eulaAcceptance: true,
      //   password: `${formData.password}`,
      //   ageAcceptance: true,
      // }

      // rxiosAuth.post('signup', signUpData).subscribe(
      //   (next: any) => {
      //     toast.success('Account login')
      //     if (!cleanupFunction) setLoading(false)
      //   },
      //   (error) => {
      //     toast.error(error.message)
      //     setLoading(false)
      //   },
      //   () => {
      //     console.log('completed')
      //     setLoading(false)
      //     refreshPage()
      //   },
      // )
      // cleanupFunction = true
    },
    [setLoading],
  )

  return (
    <ContainerWithImage
      image={config.images.unusual || UNUSUAL_IMAGE}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid item xs={12}>
        <Grid item md={12} className="logo hiddenMdUp">
          <Logo />
        </Grid>
        <Box display="flex" justifyContent="center" mb={7}>
          <Typography variant={'h2'}>{t('areYouTravelling', 'Are you travelling')}?</Typography>
        </Box>

        <Grid item xs={12}>
          <p>
            {t(
              'looksLikeYouAreTryingToLogInFromAnUnusualLocation',
              'Looks like you are trying to log in from an unusual location. We’ve sent you a 6-digit verification code to your email. Please enter it below:',
            )}
          </p>
        </Grid>
        {/*icon: FC<{SVGProps<SVGSVGElement>}>*/}
        {
          <Grid item xs={12} className={classes.info}>
            <CommonTipItem
              value={t('codeIsIncorrectDoubleCheck', 'Code is incorrect. Double-check .....')}
              iconComponent={<IconAttention />}
            />
          </Grid>
        }

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(signInHandler)}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <FormTextField
                className={classes.digits}
                name="digit_1"
                type="text"
                inputProps={{ maxlength: 1 }}
              />
              <FormTextField
                className={classes.digits}
                name="digit_2"
                type="text"
                inputProps={{ maxlength: 1 }}
              />
              <FormTextField
                className={classes.digits}
                name="digit_3"
                type="text"
                inputProps={{ maxlength: 1 }}
              />
              <FormTextField
                className={classes.digits}
                name="digit_4"
                type="text"
                inputProps={{ maxlength: 1 }}
              />
              <FormTextField
                className={classes.digits}
                name="digit_5"
                type="text"
                inputProps={{ maxlength: 1 }}
              />
              <FormTextField
                className={classes.digits}
                name="digit_6"
                type="text"
                inputProps={{ maxlength: 1 }}
              />
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </ContainerWithImage>
  )
}

export default UnusualSignInPage
