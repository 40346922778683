import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import config from './config'
import Error500Page from './pages/Error500Page'

import { AppRoutesSettings, renderRoutes } from './routes'
import { LogOutByTime } from './components/LogOutByTime'

let ErrorBoundary: BugsnagErrorBoundary | undefined
const nodeEnv = process.env.REACT_APP_ENV || process.env.NODE_ENV
if (['staging', 'production'].includes(nodeEnv)) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_APIKEY || '',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['staging', 'production'],
    releaseStage: nodeEnv,
    appVersion: process.env.REACT_APP_VERSION || '',
  })
  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
}

export const App = (): React.ReactElement | null => {
  const favicon = document.getElementById('favicon') as HTMLAnchorElement
  const pageTitle = document.getElementById('page-title')

  if (pageTitle) pageTitle.innerText = config.name
  if (favicon && config.images.favicon) {
    favicon.href = `${process.env.PUBLIC_URL}/${config.images.favicon}`
  }

  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={Error500Page}>
      <LogOutByTime>{renderRoutes(AppRoutesSettings)}</LogOutByTime>
    </ErrorBoundary>
  ) : (
    <LogOutByTime>{renderRoutes(AppRoutesSettings)}</LogOutByTime>
  )
}
