import React, { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Grid, Typography, makeStyles, Modal, Button, Fab, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import config from '../../config'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}
const GOOGLE_SUPPORT_LINK = 'https://support.google.com/wallet/answer/12098871'
const GOOGLE_FIX_LINK = 'https://support.google.com/wallet/answer/12058983#fix_a_problem'

const useStyles = makeStyles((theme) => ({
  // modal
  modal: {
    maxWidth: 517,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
  },
  bodyModal: {
    padding: theme.spacing(0, 3),
    margin: theme.spacing(0),
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
  },
  infoTitle: {
    // fontFamily: 'Arial',
    lineHeight: '24px',
    // fontWeight: 400,
  },
  infoText: {
    paddingLeft: 20,
    lineHeight: '24px',
    '& ul': {
      marginTop: 0,
      paddingLeft: 2,
    },
  },
  footerModal: {
    padding: theme.spacing(3.5, 0, 3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: 'black',
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}))

export const AddWalletCardModal: FC<{
  isOpen: string | null
  closeModal: () => void
  phone: string | undefined | null
}> = ({ isOpen, closeModal, phone = 'N/A' }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)

  return (
    <Modal
      style={{ zIndex: 1401 }}
      open={!!isOpen}
      onClose={closeModal}
      aria-labelledby="account-modal-title"
      aria-describedby="account-modal-description"
    >
      {isOpen === 'android' ? (
        <Grid container item style={modalStyle} className={classes.modal}>
          <Grid item className={classes.headerModal}>
            <Typography variant={'h5'}>
              {t('addAndroidWallet', 'Add card to the Google Wallet')}
            </Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={closeModal}
            >
              <CloseIcon />
            </Fab>
          </Grid>

          <Grid item xs={12} className={classes.bodyModal}>
            <Box>
              <Typography className={classes.infoTitle}>
                {t(
                  'androidHeader',
                  'To add the Klarpay card to the Google Wallet, follow the steps below:',
                )}
              </Typography>
              <Typography className={classes.infoText}>
                <ul>
                  <li>{t('openGoogleWallet', 'Open the Google Wallet app')}</li>
                  <li>
                    <Trans i18nKey={'tapAddWallet'}>
                      At the bottom, tap <b>Add to Wallet</b>
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey={'tapPaymentCard'}>
                      Tap <b>Payment card</b>. Any cards you saved to your Google Account are shown
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey={'tapNewCard'}>
                      Tap <b>New credit or debit card</b>. To add a card, use your camera or tap{' '}
                      <b>Enter details manually</b>
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey={'tapSave'}>
                      At the bottom, tap <b>Save</b>
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey={'tapAccept'}>
                      Read the Issuer Terms and tap <b>Accept</b>
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey={'completeVerification'}>
                      If you`re asked to verify your payment method, choose an option from the list
                      and{' '}
                      <a href={GOOGLE_SUPPORT_LINK} className={classes.link}>
                        complete verification
                      </a>
                    </Trans>
                  </li>
                </ul>
              </Typography>
              <Typography className={classes.infoTitle}>
                <Trans i18nKey={'fixProblem'}>
                  After this step, you should get a message that your card was added and is ready to
                  make contactless payments in stores, online, or in apps where Google Pay is
                  accepted. If you get a different message,{' '}
                  <a href={GOOGLE_FIX_LINK} className={classes.link}>
                    learn to fix a problem
                  </a>
                </Trans>
              </Typography>
              <br />
              <Typography className={classes.infoTitle}>
                {t(
                  'afterAddCard',
                  `After you add a card, you might find a small transaction on your account from Google Wallet. This transaction checks that your card and account are valid. This transaction will soon disappear and doesn’t affect your balance`,
                )}
              </Typography>
            </Box>
            <Grid item xs={12} className={classes.footerModal}>
              <Button onClick={closeModal} color="primary" variant="contained">
                {t('gotIt', 'Got it')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container item style={modalStyle} className={classes.modal}>
          <Grid item className={classes.headerModal}>
            <Typography variant={'h5'}>
              {t('addCardToWallet', 'Add card to the Apple Wallet')}
            </Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={closeModal}
            >
              <CloseIcon />
            </Fab>
          </Grid>

          <Grid item xs={12} className={classes.bodyModal}>
            <Box>
              <Typography className={classes.infoTitle}>
                {t(
                  'infoBlockHeader',
                  'To add the {{val1}} card to the Apple Wallet, follow the steps below:',
                  {
                    val1: config.name,
                  },
                )}
              </Typography>
              <Typography className={classes.infoText}>
                <ul>
                  <li>{t('openWallet', 'Open the Wallet application on your iPhone')}</li>
                  <li>{t('tapPlus', 'Tap on the “+” icon on the top right')}</li>
                  <li>
                    {t(
                      'takePhoto',
                      'Take a photo of the {{val1}} virtual card from your computer screen - make sure that the details are revealed ',
                      {
                        val1: config.name,
                      },
                    )}
                  </li>
                  <li>
                    {t(
                      'addCardDetails',
                      'Alternatively, add your card details to the Wallet app manually',
                    )}
                  </li>
                  <li>{t('submitCvv', 'Submit your CVV code')}</li>
                  <li>{t('acceptConditions', 'Accept Terms and Conditions')}</li>
                  <li>
                    {t(
                      'walletSendConfirmation',
                      'Wallet app will send a confirmation code to your phone number:',
                    )}{' '}
                    <b>{phone}</b>. {t('submitWallet', 'Submit this code to the Wallet app.')}
                  </li>
                </ul>
              </Typography>
            </Box>
            <Grid item xs={12} className={classes.footerModal}>
              <Button onClick={closeModal} color="primary" variant="contained">
                {t('gotIt', 'Got it')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Modal>
  )
}
