import React, { FC, useEffect } from 'react'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { poiNeeded } from '../../../graphql/local'
import {
  ContractStatusType,
  ContractType,
  LegalEntity,
  Maybe,
  UboStakeType,
  useGetContractDetailsQuery,
} from '../../../graphql'

import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { AppActions, RegistrationSteps, statusActions, StepperTypes } from '../../../constants'
import { useStepper } from '../../../hooks'
import { GeneralPersonDataForm } from './GeneralPersonDataForm'
import { OwnerFormPropsType } from '../../../types'

export const OwnerForm: FC<{
  isDomiciliary: boolean
  companyName?: Maybe<string> | undefined
  type: UboStakeType
}> = ({ isDomiciliary, companyName = 'Company', type }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.uboId]: uboId,
    [PATH_PARAMS.ownedUboId]: ownedUboId,
  } = useParams() as Record<string, string>
  const location = useLocation()
  const isNewRecord = !!location.pathname.match('/new')
  const ownerFormProps: OwnerFormPropsType = {
    isDomiciliary,
    isNewRecord,
    companyName,
    applicationId,
    uboId,
    ownedUboId,
    type,
  }
  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const { setHeaderText, setCurrentStep } = useStepper(
    contractData?.contract?.type === ContractType.IntroducerReference
      ? StepperTypes.partnerRegistration
      : StepperTypes.registration,
  )

  useEffect(() => {
    setCurrentStep(RegistrationSteps.controllingPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t(
            contractData?.contract?.type === ContractType.IntroducerReference
              ? 'companyPartnerApplication'
              : 'companyBusinessApplication',
            {
              companyName: contractOwner.companyName,
            },
          ),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [contractData, contractLoading])

  useEffect(() => {
    poiNeeded(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return <GeneralPersonDataForm {...ownerFormProps} />
}
