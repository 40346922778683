import React from 'react'
import { Controller, FieldValues, useFormContext, UseFormMethods } from 'react-hook-form'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { find, get } from 'lodash'
import { Maybe } from '../../../graphql'
import { ControlledTextFieldProps } from '../../../types'
import { CountriesList, CountriesUnknownList } from '../../../utils/Data'
import { filterOptionsStart, handleLatinKeysDown } from '../../../utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { BaseEnumWithKey } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(3, 0, 2.5),
    maxWidth: '100%',
    '& + .MuiAutocomplete-popperDisablePortal': {
      left: 0,
      top: 85,
    },
  },
  input: {},
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
}))

type CountryAutocompleteSelectProps = {
  name: string
  defaultValue?: Maybe<string> | undefined
  clearFields?: () => void
  readOnly?: boolean | undefined
  setDisableParentScroll?: React.Dispatch<React.SetStateAction<boolean>>
  withUnknown?: boolean
  control: Pick<
    UseFormMethods<FieldValues>,
    'register' | 'unregister' | 'setValue' | 'getValues' | 'trigger'
  >
}

export const ControlledCountryAutoSelect: React.FC<
  CountryAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  defaultValue = '',
  readOnly = false,
  clearFields,
  rules,
  setDisableParentScroll,
  withUnknown = false,
  control,
  onChange: ignored,
  ...rest
}) => {
  const classes = useStyles()
  const { errors, watch } = useFormContext()
  const error = errors ? get(errors, name || 'FormAutocompleteSelect') : null
  const options = withUnknown ? CountriesUnknownList : CountriesList

  return (
    <Controller
      key={watch(name)}
      name={name}
      render={({ onChange, ...props }) => (
        <Autocomplete
          className={`${classes.formField} ${readOnly ? classes.readOnly : ''}`}
          options={options}
          onOpen={() => setDisableParentScroll && setDisableParentScroll(true)}
          onClose={() => setDisableParentScroll && setDisableParentScroll(false)}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              const label = find(options, ['key', option])?.label
              return label || option
            } else {
              return (option as BaseEnumWithKey).label
            }
          }}
          getOptionSelected={(option, value) => option.key === value}
          renderInput={(params) => {
            return (
              <TextField
                className={classes.input}
                {...params}
                error={!!error}
                helperText={error ? error.message : null}
                {...rest}
                disabled={readOnly}
                onKeyDown={handleLatinKeysDown}
              />
            )
          }}
          onChange={(_, data) => onChange(data.key)}
          filterOptions={filterOptionsStart}
          openText={''}
          closeText={''}
          popupIcon={<ExpandMoreIcon />}
          disabled={readOnly}
          disableClearable
          autoHighlight
          openOnFocus={!readOnly}
          {...props}
        />
      )}
      onChange={(data: BaseEnumWithKey) => {
        if (clearFields) {
          clearFields()
        }
        return data.key
      }}
      control={control}
    />
  )
}
