import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { YesNoOptions } from '../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../Common'
import { DocumentUpload } from '../../NewPerson/Forms/DocumentUpload'
import { CardProcessingHistory, DocumentType } from '../../../graphql'
import { FilesListUpload } from '../../NewPerson/Forms/FilesListUpload'
import { sizeInKB } from '../../../utils'
import { isEmpty } from 'lodash'
import FormHelperText from '@material-ui/core/FormHelperText'

import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  fileLabel: {
    marginBottom: theme.spacing(2),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
  },
  dirtyControl: {
    position: 'relative',
  },
}))
const ProcessingHistoryComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: CardProcessingHistory | null
}> = ({ isViewOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { watch, setValue, errors, trigger, formState, control } = useFormContext()

  const fileErrors = errors['historyStatement']

  const isHistory = watch('processedHistory') === YesNoOptions[0].key
  const historyStatementFile = watch('historyStatement')

  const removeFileHandler = () => {
    setValue('historyStatement', [])
  }

  useEffect(() => {
    if (!isHistory) {
      setValue('currentProcessor', '')
      setValue('reasonLeavingProcessor', '')
      setValue('periodWithProcessor', '')
    }
  }, [isHistory])

  useEffect(() => {
    if (Array.isArray(historyStatementFile) && historyStatementFile.length > 0) {
      trigger('historyStatement')
    }
  }, [historyStatementFile])

  const processPaymentCardsBeforeValue =
    changedContractProfileData?.processPaymentCardsBefore?.toString() || undefined

  const processingHistoryDataMap: Record<string, typeof YesNoOptions[number]['key']> = {
    true: YesNoOptions[0].key,
    false: YesNoOptions[1].key,
  }

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5">{t('processingHistory', 'Processing history')}</Typography>
      </Box>
      <GridRow>
        <Controller
          as={
            <FormAutocompleteSelect
              name="processedHistory"
              label={t('processedHistory', 'Have you processed payment Cards before?')}
              className={classes.boxInput}
              data={YesNoOptions}
              readOnly={isViewOnly}
            />
          }
          name="processedHistory"
          control={control}
        />
        {processPaymentCardsBeforeValue && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              formState.errors.processedHistory ||
              watch('processedHistory') === processingHistoryDataMap[processPaymentCardsBeforeValue]
            }
            title={processingHistoryDataMap[processPaymentCardsBeforeValue]}
          />
        )}
      </GridRow>

      {isHistory && (
        <>
          <GridRow>
            <FormControlledTextField
              label={t('nameOfCurrentFormerProcessor', 'Name of current / former processor(s)')}
              name="currentProcessor"
              type="text"
              fullWidth={true}
              multiline
              required={false}
              disabled={isViewOnly}
              data-test="nameOfCurrentFormerProcessor"
            />
            {changedContractProfileData?.nameOfCurrentFormerProcessor && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.currentProcessor}
                title={changedContractProfileData.nameOfCurrentFormerProcessor}
                arrowHorizontalPosition={'left'}
              />
            )}
          </GridRow>
          <GridRow>
            <FormControlledTextField
              label={t(
                'reasonForLeavingCurrentProcessor',
                'Reason for leaving current processor(s) (if any)',
              )}
              name="reasonLeavingProcessor"
              type="text"
              fullWidth={true}
              multiline
              required={false}
              disabled={isViewOnly}
              data-test="reasonForLeavingCurrentProcessor"
            />
            {changedContractProfileData?.reasonForLeavingCurrentProcessor && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.reasonLeavingProcessor}
                title={changedContractProfileData.reasonForLeavingCurrentProcessor}
                arrowHorizontalPosition={'left'}
              />
            )}
          </GridRow>
          <GridRow>
            <FormControlledTextField
              label={t('periodWithProcessor', 'Period of time with current/former processor(s)')}
              name="periodWithProcessor"
              type="text"
              fullWidth={true}
              multiline
              required={false}
              disabled={isViewOnly}
              data-test="periodWithProcessor"
            />
            {changedContractProfileData?.periodOfTimeWithCurrentFormerProcessor && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.periodWithProcessor}
                title={changedContractProfileData.periodOfTimeWithCurrentFormerProcessor}
                arrowHorizontalPosition={'left'}
              />
            )}
          </GridRow>
          <GridRow>
            <Box mt={4}>
              <Typography className={`${classes.fileLabel} ${!!fileErrors && classes.redError}`}>
                {t(
                  'officialProcessingHistory',
                  'Official processing history statement from your current/former processor(s):',
                )}
              </Typography>
            </Box>
            {!isEmpty(historyStatementFile) && !isViewOnly ? (
              <FilesListUpload
                key={`${historyStatementFile[0].id}`}
                name={historyStatementFile[0].fileName as string}
                size={sizeInKB(historyStatementFile[0].size as number)}
                percent={0}
                status={'done'}
                type={(historyStatementFile[0].metaType as string) || ''}
                removeFileHandler={removeFileHandler}
                docType={historyStatementFile[0].docType as string}
              />
            ) : (
              <DocumentUpload
                filesForUploadRegName={'historyStatement'}
                isTransaction={true}
                documentTypeRegName={
                  DocumentType.PreassessmentHistoryStatementOfCurrentFormerProcessor
                }
                disabled={isViewOnly}
              />
            )}
            {!!fileErrors && (
              <Grid container>
                <FormHelperText className={classes.redError}>{fileErrors.message}</FormHelperText>
              </Grid>
            )}
            {changedContractProfileData?.processingHistoryStatement?.fileName && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.historyStatement}
                title={changedContractProfileData.processingHistoryStatement.fileName ?? ''}
                arrowHorizontalPosition={'left'}
              />
            )}
          </GridRow>
        </>
      )}
    </>
  )
}
export const ProcessingHistory = React.memo(ProcessingHistoryComponent)
