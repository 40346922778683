import * as yup from 'yup'

import i18n from '../i18n'
import {
  LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD,
  MAX_LENGTH,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_ONLY_MESSAGE,
} from '../constants/validations'
import { isEmpty } from 'lodash'
// import {
//   MIN_AMOUNT_NUMBER,
//   MIN_AMOUNT_NUMBER_MESSAGE,
//   NUMBER_2DIGIT_AFTER_DOT_ONLY_REGEXP,
//   NUMBER_ONLY_MESSAGE,
// } from '../constants/validations'

export const CreateNewCardSchema = yup.object().shape({
  cardOwner: yup.string().required(i18n.t('thisFieldIsRequired', 'This field is required')),
  debitAccount: yup.string().required(i18n.t('thisFieldIsRequired', 'This field is required')),
  nameOptional: yup
    .string()
    .test(
      'length',
      NUMBER_AND_LATINICA_ONLY_MESSAGE,
      (val) => isEmpty(val) || (!!val && val?.length >= MIN_LENGTH && val?.length <= MAX_LENGTH),
    )
    .test(
      'nameMatch',
      NUMBER_AND_LATINICA_ONLY_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD),
    ),

  // spendinglimit: yup
  //   .string()
  //   .required(i18n.t('spendinglimitIsRenameOptionalquired'))
  //   .test(
  //     'minLength',
  //     MIN_AMOUNT_NUMBER_MESSAGE,
  //     (val) => !!val && val?.length >= MIN_AMOUNT_NUMBER,
  //   )
  //   .matches(NUMBER_2DIGIT_AFTER_DOT_ONLY_REGEXP, NUMBER_ONLY_MESSAGE),
})

export type CreateNewCardInput = yup.Asserts<typeof CreateNewCardSchema>
