import React, { useEffect, useState } from 'react'
import { matchPath, useRouteMatch } from 'react-router'
import { generatePath, Redirect, useHistory } from 'react-router-dom'

import { APP_PATHS, PATH_PARAMS, ROOT_PATHS } from '../paths'
import {
  ContractStatusType,
  Individual,
  useGetContractStatusQuery,
  useGetUserDataQuery,
} from '../../graphql'
import { useDetermineUserRights } from '../../hooks'

export const ApRouteGuard: React.FC = ({ children }) => {
  const history = useHistory()
  const routeActions = useRouteMatch(APP_PATHS.dashboard.actions)
  const dashboardPaths = Object.values(APP_PATHS.dashboard).filter((path) =>
    path.includes(':applicationId'),
  )
  const match = matchPath(history.location.pathname, {
    path: dashboardPaths,
  })
  const contractId = (match?.params as { applicationId: string })?.applicationId
  const [user, setUser] = useState<Individual | undefined>()
  const { data: userData, loading: userLoading, error: userError } = useGetUserDataQuery()
  useEffect(() => {
    !userLoading && !userError && setUser(userData?.viewer as Individual)
  }, [userData?.viewer, userError, userLoading])

  const { userRights, loading: rightsLoading } = useDetermineUserRights(contractId)
  const { data: contractData, loading: contractLoading } = useGetContractStatusQuery({
    variables: {
      id: +contractId,
    },
    skip: !contractId || Number.isNaN(Number(contractId)),
  })

  if (
    userLoading ||
    !user ||
    rightsLoading ||
    !userRights ||
    contractLoading ||
    !contractData?.contract
  )
    return <></>

  const contractStatusControl = ![
    ContractStatusType.Rejected,
    ContractStatusType.Draft,
    ContractStatusType.ChangeRequest,
    ContractStatusType.FirstLinePending,
  ].includes(contractData?.contract?.status as ContractStatusType)
  // # Verification checks for AP
  if (
    !user?.emailConfirmed ||
    !user?.isPhoneVerified ||
    (!!userRights.signatoryRight && !user?.videoVerified)
  ) {
    return (
      <Redirect
        to={generatePath(APP_PATHS.application.onboarding.personalDetails, {
          [PATH_PARAMS.applicationId]: contractId,
        })}
      />
    )
  }

  return contractStatusControl &&
    (userRights?.isAuthorizedPerson || userRights.isIntroducerContractOwner) ? (
    <>
      {/*AP verified but contract not signed (Pending Signatures)*/}
      {contractData?.contract?.status === ContractStatusType.SignaturesPending && !routeActions && (
        <Redirect
          to={generatePath(APP_PATHS.dashboard.actions, {
            [PATH_PARAMS.applicationId]: contractId,
          })}
        />
      )}
      {children}
    </>
  ) : (
    <Redirect to={ROOT_PATHS.appRoot} />
  )
}

export default ApRouteGuard
