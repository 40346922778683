import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Hidden } from '@material-ui/core'

import { useStyles } from './useStyles'
import { SignaturesTable, SignaturesBox } from './helpers'
import { AlertTipItem } from '../../../components'
import { ReactComponent as WarnIcon } from '../../../assets/images/icons/icon-info2.svg'
import { ActionSignatureProps } from '../contract'

export const TerminateContractTab: FC<{ action: ActionSignatureProps; signedCount: number }> = ({
  action,
  signedCount,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const alert = () => {
    return (
      <AlertTipItem
        value={`${t('terminateContractText')}`}
        iconComponent={<WarnIcon />}
        type={'warning'}
      />
    )
  }
  return (
    <>
      <Hidden xsDown>
        {alert()}
        <SignaturesTable signatures={action.signatures} signedCount={signedCount} />
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            {alert()}
            <SignaturesBox signatures={action.signatures} signedCount={signedCount} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
