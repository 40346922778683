import { isEmpty } from 'lodash'
import React, { FC, useCallback, useMemo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ButtonWithTooltip } from '../../components'
import config from '../../config'
import { AccountStatus, useGetContractAccountsQuery } from '../../graphql'
import { useIsLimitedContract } from '../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '24px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 14px',
    position: 'static',
    width: '130px',
    height: '48px',
    left: '115px',
    top: '256px',
    background: '#F5F5F5',
    color: 'black',
    '&:hover': {
      background: 'black',
      color: 'white',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const EmptyCardsListMessage: FC<{
  checkIsCardholder: boolean
  isNoCreateCardLimitations?: boolean
}> = ({ checkIsCardholder, isNoCreateCardLimitations = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
  })

  const isNoActiveAccounts = useMemo(() => isEmpty(data?.accounts), [data])
  const { isLimitedContract, contractStatusLabel } = useIsLimitedContract()

  const tooltipMessage = useMemo(() => {
    let msg = t('NoActiveAccounts', "You don't have active accounts")
    if (isLimitedContract) {
      msg = t(
        'isNotAvailable',
        `Adding cards is not available, because the contract is ${contractStatusLabel}`,
        {
          option: 'Adding cards',
          contractStatusLabel,
        },
      )
    }
    if (!isNoCreateCardLimitations) {
      msg = t(
        'cardUnAvailableMsg',
        'New card is not available at this time. Please contact support for more details.',
      )
    }
    return msg
  }, [isLimitedContract, isNoCreateCardLimitations])

  const handleOpen = useCallback(() => {
    history.push(
      generatePath(APP_PATHS.dashboard.createCard, {
        [PATH_PARAMS.applicationId]: applicationId,
        [PATH_PARAMS.cardsPageNum]: 1,
      }),
    )
  }, [applicationId, history])

  return checkIsCardholder ? (
    <Typography variant={'h2'} className={classes.text}>
      {t('noCardsCreatedYet', 'No cards created yet')}
    </Typography>
  ) : (
    <>
      <Typography variant={'h2'} className={classes.text}>
        {t(
          'cardsEmptyPageNotificationOne',
          'Create multiple {{val1}} virtual cards, connect to your Apple or Google Pay and unlock payments for your team.',
          {
            val1: config.name,
          },
        )}
      </Typography>

      <ButtonWithTooltip
        title={tooltipMessage}
        placement="bottom-end"
        type="button"
        className={classes.button}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOpen}
        disabled={isLimitedContract || isNoActiveAccounts || !isNoCreateCardLimitations}
      >
        {t('getStarted', 'Get Started')}
      </ButtonWithTooltip>
    </>
  )
}
