import * as yup from 'yup'

import i18n from '../../i18n'
import { totalShare } from '../../graphql/local'
import { MAX_LENGTH_PERCENTS_FIELD, PERCENT_FIELD_MAX_LENGTH } from '../../constants/validations'

export const OwnerShipPercent1InputSchema = yup.object().shape({
  ownershipPercent: yup
    .number()
    .typeError(i18n.t('percentageMustBeNumber', 'Percentage must be a number'))
    .required(i18n.t('percentageOfOwnershipIsRequired', 'Percentage of ownership is required'))
    .min(1, i18n.t('minimumPercentageIs', 'Minimum percentage is 1%'))
    .max(100, i18n.t('maxPercent', 'Maximum percentage is 100%'))
    .test({
      name: 'totalShare',
      message: 'Total percentage exceeds 100%, make sure all shares are submitted correctly',
      test: (value) => (value as number) <= 100 - totalShare(),
    })
    .test(
      'maxFieldLength',
      PERCENT_FIELD_MAX_LENGTH,
      (val) => !!val && val.toString().length <= MAX_LENGTH_PERCENTS_FIELD,
    ),
})

export type OwnerShipPercent1Input = yup.Asserts<typeof OwnerShipPercent1InputSchema>
