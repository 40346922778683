import * as yup from 'yup'

import i18n from '../../i18n'
import { DocumentTypePersonInputSchema } from './DocumentTypePerson'

export const PoiProfileInputSchema = yup
  .object()
  .shape({
    personRequired: yup.mixed().notRequired(),
    addressRequired: yup.mixed().notRequired(),
    documentTypeAddress: yup
      .string()
      .test(
        'mandatoryAddress',
        i18n.t('proofResidenceMandatory', 'Proof of address is mandatory'),
        function () {
          return !this.parent.addressRequired
        },
      ),
  })
  .concat(DocumentTypePersonInputSchema)

export type PoiProfileInput = yup.Asserts<typeof PoiProfileInputSchema>
