import React, { FC } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tableTitle: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontSize: '9px',
    color: 'grey',
  },
  tableData: {
    fontSize: '12px',
  },
}))

const HeaderRow = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={6} sm={3}>
        <Box className={classes.tableTitle} fontWeight="fontWeightBold">
          {/*<Typography  fontSize={8} variant="overline">iban</Typography>*/}
          {t('iban', 'IBAN')}
        </Box>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box className={classes.tableTitle} fontWeight="fontWeightBold">
          {t('bigSwift', 'BIC/SWIFT')}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box textAlign={'right'} className={classes.tableTitle} fontWeight="fontWeightBold">
          {t('balanceLower', 'Balance')}
        </Box>
      </Grid>
    </>
  )
}

const DataRow = () => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={6} sm={3}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Box className={classes.tableData}>CH12 34567 8901 2345 67890 1</Box>
      </Grid>
      <Grid item xs={6} sm={3}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Box className={classes.tableData}>KL123456</Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.tableData} textAlign={'right'}>
          $123.45
        </Box>
      </Grid>
    </>
  )
}

export const AccountCardDetails: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <HeaderRow />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <DataRow />
        </Grid>
      </Grid>
    </div>
  )
}
