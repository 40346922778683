import { Theme } from '@material-ui/core/styles'
import { FormProvider, useFormContext } from 'react-hook-form'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useParams } from 'react-router'
import {
  ContractProfileChangeAnswers,
  ProfileChange,
  ProfileChangeStatus,
  useGetContractProfileChangesQuery,
} from '../../../../graphql'
import { PATH_PARAMS } from '../../../../routes/paths'

import { GridRow } from '../../../Common'
import { CardVolumeFormattedValue } from '../../../Common/CardVolumeFormattedValue'
import { CardsTypesVolumes } from '../../../../types'
import { initialCardsVolumes } from '../../../../constants'
import { calculateTotatVolumes } from '../../../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '@media screen and (max-width: 460px)': {
      '& th, & td, & input': {
        padding: '2px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

export const cardsList = ['Visa', 'Mastercard']

export const CardsVolumes: FC<{ isViewOnly?: boolean }> = ({ isViewOnly }) => {
  const methods = useFormContext()
  const { setValue, watch, formState, register } = methods
  const { t } = useTranslation()
  const classes = useStyles()
  const visaValues = watch('volumesVisa')
  const masterValues = watch('volumesMaster')

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: contractDataChanges } = useGetContractProfileChangesQuery({
    variables: {
      contractId: +applicationId,
      status: [ProfileChangeStatus.PendingExecutionSignature, ProfileChangeStatus.Pending],
    },
    skip: !applicationId,
  })

  const cardChanges = useMemo(() => {
    const contractProfileChanges = contractDataChanges?.contractProfileChanges as ProfileChange[]
    const filterApproved =
      contractProfileChanges?.length > 0
        ? contractProfileChanges.reduce(
            (max, character: ProfileChange) =>
              (character?.id as number) > (max?.id as number) ? character : max,
            contractProfileChanges[0],
          )
        : ({} as ProfileChange)

    const changes = (filterApproved?.changeAnswers as ContractProfileChangeAnswers)?.preAssessment
      ?.cardPayment

    return {
      visaChanges: changes?.totalPredictedMonthlyVolumesVisa,
      masterCardChanges: changes?.totalPredictedMonthlyVolumesMasterCard,
    }
  }, [contractDataChanges])

  const calculateVolumes = useMemo((): number => {
    return calculateTotatVolumes(visaValues, masterValues)
  }, [visaValues, masterValues])

  useEffect(() => {
    register('volumesVisa')
    register('volumesMaster')
    register('totalPredictedMonthlyVolumes')

    if (!visaValues) setValue('volumesVisa', initialCardsVolumes)
    if (!masterValues) setValue('volumesMaster', initialCardsVolumes)
  }, [])

  useEffect(() => {
    setValue('totalPredictedMonthlyVolumes', calculateVolumes, {
      shouldValidate: formState.isSubmitted,
    })
  }, [calculateVolumes])

  return (
    <>
      <Hidden xsDown>
        <FormProvider {...methods}>
          <GridRow>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell width={'25%'}>{t('cardSchemas', 'Card schemas')}</TableCell>
                    <TableCell width={'25%'}>{t('domestic', 'Domestic')}</TableCell>
                    <TableCell width={'25%'}>{t('eea', 'EEA')}</TableCell>
                    <TableCell width={'25%'}>{t('nonEea', 'Non-EEA')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visaValues &&
                    masterValues &&
                    cardsList.map((card, i) => (
                      <TableRow key={`${card + i}`} data-test={`card-row-${i}`}>
                        <TableCell> {card} </TableCell>
                        <TableCell>
                          <CardVolumeFormattedValue
                            card={card}
                            type={CardsTypesVolumes.Domestic}
                            prefix={'\u20AC '}
                            isViewOnly={isViewOnly}
                            newValue={
                              card === 'Visa'
                                ? cardChanges.visaChanges?.domestic
                                : cardChanges.masterCardChanges?.domestic
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <CardVolumeFormattedValue
                            card={card}
                            type={CardsTypesVolumes.Eea}
                            prefix={'\u20AC '}
                            isViewOnly={isViewOnly}
                            newValue={
                              card === 'Visa'
                                ? cardChanges.visaChanges?.eea
                                : cardChanges.masterCardChanges?.eea
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <CardVolumeFormattedValue
                            card={card}
                            type={CardsTypesVolumes.NonEea}
                            prefix={'\u20AC '}
                            isViewOnly={isViewOnly}
                            newValue={
                              card === 'Visa'
                                ? cardChanges.visaChanges?.nonEea
                                : cardChanges.masterCardChanges?.nonEea
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridRow>
        </FormProvider>
      </Hidden>

      {/*Mobile table*/}

      <Hidden smUp>
        <FormProvider {...methods}>
          <GridRow>
            {visaValues &&
              masterValues &&
              cardsList.map((card, i) => (
                <TableContainer key={`${card + i}`} className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'50%'}>{t('cardSchemas', 'Card schemas')}</TableCell>
                        <TableCell width={'50%'}>{card}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{t('domestic', 'Domestic')}</TableCell>
                        <TableCell>
                          <CardVolumeFormattedValue
                            card={card}
                            type={CardsTypesVolumes.Domestic}
                            prefix={'\u20AC '}
                            isViewOnly={isViewOnly}
                            newValue={
                              card === 'Visa'
                                ? cardChanges.visaChanges?.domestic
                                : cardChanges.masterCardChanges?.domestic
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('eea', 'EEA')}</TableCell>
                        <TableCell>
                          <CardVolumeFormattedValue
                            card={card}
                            type={CardsTypesVolumes.Eea}
                            prefix={'\u20AC '}
                            isViewOnly={isViewOnly}
                            newValue={
                              card === 'Visa'
                                ? cardChanges.visaChanges?.eea
                                : cardChanges.masterCardChanges?.eea
                            }
                            // data-test="eeaCardsVolumes"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('nonEea', 'Non-EEA')}</TableCell>
                        <TableCell>
                          <CardVolumeFormattedValue
                            card={card}
                            type={CardsTypesVolumes.NonEea}
                            prefix={'\u20AC '}
                            isViewOnly={isViewOnly}
                            newValue={
                              card === 'Visa'
                                ? cardChanges.visaChanges?.nonEea
                                : cardChanges.masterCardChanges?.nonEea
                            }
                            // data-test="nonEeaCardsVolumes"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
          </GridRow>
        </FormProvider>
      </Hidden>
    </>
  )
}
