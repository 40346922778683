import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box, Button, Theme, makeStyles } from '@material-ui/core'
import { useParams, useHistory, generatePath } from 'react-router-dom'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { PersonsAccessTab } from './Tabs'
import { StepperTypes, RegistrationSteps, AppActions, statusActions } from '../../constants'
import { useStepper } from '../../hooks'
import { useGetContractDetailsQuery, LegalEntity, ContractStatusType } from '../../graphql'
import { accessRights as accessRightsVar, goBackPathVar } from '../../graphql/local'

const useStyles = makeStyles((theme: Theme) => ({
  stepButton: {
    width: '430px',
    height: 48,
    margin: theme.spacing(3, 0, 3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(4, 0, 3),
      height: 'auto',
    },
  },
  groupBtn: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  proceedBtn: {
    width: '302px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  backBtn: {
    width: '104px',
    marginRight: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 0 24px 0',
    },
  },
  wrapControlBtn: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 32px 0',
    },
  },
}))

export const ReviewPerson: FC<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setReviewPersonVisible?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setOpen, setReviewPersonVisible }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [canProceed, setCanProceed] = useState(false)
  const [clickedProceed, setClickedProceed] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const { setHeaderText, setCurrentStep } = useStepper(StepperTypes.registration)
  useEffect(() => {
    setCurrentStep(RegistrationSteps.authorizedPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t('companyBusinessApplication', 'Business application', {
            companyName: contractOwner.companyName,
          }),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [applicationId, contractData, contractLoading, history, setCurrentStep, setHeaderText, t])

  const onProceed = useCallback(() => {
    setClickedProceed(true)
    if (canProceed) {
      history.push(generatePath(APP_PATHS.application.directors.list, { applicationId }))
    }
  }, [applicationId, canProceed, history])

  const onAdd = useCallback(() => {
    accessRightsVar('full')
    history.push(
      generatePath(APP_PATHS.application.authorizedPersons.add, {
        [PATH_PARAMS.applicationId]: +applicationId,
      }),
    )
  }, [applicationId, history])

  const handleBack = useCallback(() => {
    goBackPathVar(
      generatePath(APP_PATHS.application.authorizedPersons.list, {
        [PATH_PARAMS.applicationId]: applicationId,
      }),
    )
    history.push(generatePath(APP_PATHS.application.edit, { applicationId }))
  }, [applicationId, history])

  return (
    <Box m={1}>
      <Grid container>
        <Grid item xs={12} lg={11}>
          {/* Persons */}
          <PersonsAccessTab
            setOpen={setOpen}
            setReviewPersonVisible={setReviewPersonVisible}
            setCanProceed={setCanProceed}
            clickedProceed={clickedProceed}
          />
          <Box className={classes.wrapControlBtn}>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={onAdd}
                className={classes.stepButton}
                disableElevation
              >
                {t('addAuthorisedPerson', 'Add Authorised person')}
              </Button>
            </Grid>
            <Grid className={classes.groupBtn}>
              <Button
                type="button"
                variant="contained"
                fullWidth
                disableElevation
                onClick={handleBack}
                className={classes.backBtn}
              >
                {t('goBack', 'Go back')}
              </Button>
              <Button
                disabled={!canProceed && clickedProceed}
                type="button"
                variant="contained"
                fullWidth
                disableElevation
                onClick={onProceed}
                className={classes.proceedBtn}
              >
                {t('proceedToDirectors', 'Proceed to Directors')}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReviewPerson
