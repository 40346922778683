/* eslint-disable i18next/no-literal-string */
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Box,
  Hidden,
  IconButton,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IMeta,
  IUploadParams,
  StatusValue,
} from 'react-dropzone-uploader'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { find, remove } from 'lodash'
import { MAX_FILE_SIZE_BYTES } from '../../constants'

import { DocIdsType, DocumentUploadPropsForContract } from '../../types'
import { ReactComponent as PdfIcon } from '../../assets/images/icons/pdf.svg'
import { ReactComponent as PngIcon } from '../../assets/images/icons/png.svg'
import { ReactComponent as JpgIcon } from '../../assets/images/icons/jpg.svg'
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg'
import CloseIcon from '@material-ui/icons/Close'
import { toast } from 'react-toastify'
import { useGetSignedUrlMutation } from '../../graphql'
import { reportAppError } from '../../utils'

interface IMetaWithFileUrl extends IMeta {
  fileUrl: string
}

interface IFileWithWidenedMeta extends IFileWithMeta {
  file: File
  meta: IMetaWithFileUrl
  cancel: () => void
  restart: () => void
  remove: () => void
  xhr?: XMLHttpRequest
}

const useStyles = (errorFileSize: boolean, rejectedFileType: boolean, docTypesError: boolean) =>
  makeStyles((theme: Theme) => ({
    dzuInputLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      top: 0,
      bottom: 0,
      left: 50,
      right: 0,
      fontFamily: 'Helvetica, sans-serif',
      fontSize: '20px',
      fontWeight: 600,
      color: '#ccc',
      cursor: 'pointer',
    },
    DzuInput: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& .dzu-dropzone': {
        height: 120,
        border:
          errorFileSize || rejectedFileType || docTypesError
            ? '1px solid #fed7d2'
            : '1px solid #f0f0f0',
        overflow: 'hidden',
      },
    },
    dropzoneInner: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',

      '& input': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: 0,
        '&:hover': {
          backgroundColor: 'red',
          boxShadow: 'inset -1px -3px 0px -1px #000',
          cursor: 'pointer',
        },
      },
    },
    dropzoneInnerText: {
      marginTop: theme.spacing(1.5),
      fontSize: '0.75rem',
    },
    filesList: {
      marginTop: theme.spacing(1.5),
      maxHeight: 500,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 250,
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        maxHeight: 120,
      },
      maxWidth: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
    },
    fileInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0.25, 0),
      fontSize: '0.75rem',
    },
    fileInfoName: {
      padding: theme.spacing(0, 1, 0, 1.5),
    },
    iconErrorMessage: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
    fileInfoSize: {
      color: '#999',
      padding: '0 8px 0 12px',
    },
    fileInfoProgress: {
      width: 128,
    },
    fileInfoPercent: {
      paddingLeft: theme.spacing(1),
      fontWeight: 700,
      color: '#276EF1',
    },
    removeFileButton: {
      minWidth: 44,
      fontSize: '1rem',
      color: '#999',
    },

    // Modal
    modal: {
      position: 'relative',
      width: '100%',
    },
    modalHeader: {},
    modalErrorMessage: {
      display: 'flex',
      padding: 8,
      alignItems: 'center',
      '& > svg': {
        marginRight: 15,
      },
      border: '1px solid #fed7d2',
      marginTop: 12,
    },
    addAnattachment: {
      margin: 0,
    },
    fileIcon: {
      padding: theme.spacing(0.5, 0, 0, 0),
    },
    fileInfoNameWrap: {
      display: 'flex',
      flexDirection: 'column',
    },
  }))()

interface MetaPreview {
  meta: IMeta
  fileWithMeta: IFileWithMeta
}

export const DropUploaderContactSupport: FC<DocumentUploadPropsForContract> = ({
  docIds,
  setDocIds,
}) => {
  const { t } = useTranslation()
  const [errorFileSize, setErrorFileSize] = useState(false)
  const [rejectedFileType, setRejectedFileType] = useState(false)
  const [docTypesError, setDocTypesError] = useState(false)
  const classes = useStyles(errorFileSize, rejectedFileType, docTypesError)
  const [getSignedUrlMutation, { error }] = useGetSignedUrlMutation({})

  useEffect(() => {
    if (error) {
      toast.error(
        t('errorDocumentUpload', 'Unable to upload document at the moment, please try again later'),
      )
      reportAppError(error)
    }
  }, [error, t])

  const getUploadParams: ({
    meta,
    file,
  }: IFileWithMeta) => IUploadParams | Promise<IUploadParams> = ({ file }: IFileWithMeta) => {
    return new Promise((resolve) => {
      getSignedUrlMutation({
        variables: {},
      })
        .then((r) => {
          if (r.errors) throw new Error(JSON.stringify(r.errors))
          const url = r.data?.getSignedUrl?.signedUrl as string
          const urlObj = new URL(url)
          const fields = {
            'X-Amz-Algorithm': urlObj.searchParams.get('X-Amz-Algorithm') as string,
            'X-Amz-Credential': urlObj.searchParams.get('X-Amz-Credential') as string,
            'X-Amz-Date': urlObj.searchParams.get('X-Amz-Date') as string,
            'X-Amz-Expires': urlObj.searchParams.get('X-Amz-Expires') as string,
            'X-Amz-Signature': urlObj.searchParams.get('X-Amz-Signature') as string,
            'X-Amz-SignedHeaders': urlObj.searchParams.get('X-Amz-SignedHeaders') as string,
            'x-amz-acl': urlObj.searchParams.get('x-amz-acl') as string,
          }
          resolve({
            fields,
            meta: { fileUrl: r.data?.getSignedUrl?.plainUrl },
            url,
            method: 'PUT',
            body: file,
          })
        })
        .catch((error) => {
          toast.error(
            t(
              'errorDocumentUpload',
              'Unable to upload document at the moment, please try again later',
            ),
          )
          reportAppError(error)
        })
    })
  }

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (
    file,
    status: StatusValue,
    allFiles: IFileWithWidenedMeta[],
  ) => {
    status === 'rejected_file_type' ? setRejectedFileType(true) : setRejectedFileType(false)
    if (status === 'done') {
      const alreadyUploadFailed = find(
        docIds,
        (o) => o.name === file.meta.name && o.size === file.meta.size && o.errorUpload,
      )
      if (alreadyUploadFailed) {
        file.remove()
      } else {
        const idValuesArr: DocIdsType[] = docIds
        idValuesArr.push({
          name: file.meta.name,
          size: file.meta.size,
          fileUrl: allFiles[0]?.meta?.fileUrl,
          docType: '',
          relatesTo: '',
          docTypeError: false,
          relatesToError: false,
          descriptionError: false,
          fileDescription: '',
          fileDescriptionRequired: false,
        } as DocIdsType)
        if (setDocIds) {
          setDocIds(idValuesArr)
        }
      }
    }
    if (status === 'error_file_size') {
      file.remove()
      setErrorFileSize(true)
    } else {
      setErrorFileSize(false)
    }
  }

  const Preview = ({ meta, fileWithMeta }: MetaPreview) => {
    const { name, percent, status, size, type } = meta
    function removeFileHandler() {
      remove(docIds, function (o) {
        return o.name === meta.name && o.size === meta.size
      })
      fileWithMeta.remove()
      setErrorFileSize(false)
      setRejectedFileType(false)
      setDocTypesError(false)
    }

    return (
      <Box className={classes.fileInfo}>
        <Box display="flex">
          <Box className={classes.fileIcon}>
            {type === 'application/pdf' && <PdfIcon />}
            {type === 'image/png' && <PngIcon />}
            {type === 'image/jpeg' && <JpgIcon />}
          </Box>
          <Box className={classes.fileInfoNameWrap}>
            <Box component="span" className={classes.fileInfoName} title={name}>
              {name}
            </Box>
            <Box component="span" className={classes.fileInfoSize}>
              {size}&nbsp;KB
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {status !== 'done' ? (
            <Box>
              <Hidden mdDown>
                <Box className={classes.fileInfoProgress}>
                  <LinearProgress variant="determinate" value={Math.round(percent)} />
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box component="span" className={classes.fileInfoPercent}>
                  {Math.round(percent)}%
                </Box>
              </Hidden>
            </Box>
          ) : (
            ''
          )}
          <IconButton
            aria-label="remove"
            className={classes.removeFileButton}
            onClick={() => removeFileHandler()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    )
  }

  const Layout = ({ input, previews, submitButton, dropzoneProps }: ILayoutProps) => {
    return (
      <Box className={classes.DzuInput} flexGrow={1}>
        <div {...dropzoneProps}>{input}</div>
        {errorFileSize && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              {/*<Attention />*/}
            </Box>
            <Typography>
              {t('fileExceedsMaximumSize', 'File exceeds maximum size of 15 MB')}
            </Typography>
          </Box>
        )}
        {rejectedFileType && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              {/*<Attention />*/}
            </Box>
            <Typography>
              {t('wrongDocumentFormat', 'Wrong document format. PDF, JPG and PNG are supported')}
            </Typography>
          </Box>
        )}
        {docTypesError && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              {/*<Attention />*/}
            </Box>
            <Typography>
              {t('pleaseSelectDocumentTypes', 'Please, select "Relates to" and "Document type"')}
            </Typography>
          </Box>
        )}
        <Box className={classes.filesList}>{previews}</Box>
        {submitButton}
      </Box>
    )
  }

  const getFilesFromEvent = (
    event: React.DragEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
  ): File[] | Promise<File[]> => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(event).then(
        (
          chosenFiles: {
            fileObject: File
            fullPath: string
            lastModified: string
            lastModifiedDate: string
            name: string
            size: number
            type: string
            webkitRelativePath: string
          }[],
        ) => {
          resolve(chosenFiles.slice(0, 1).map((f) => f.fileObject))
        },
      )
    })
  }

  const Input = ({ accept, onFiles, getFilesFromEvent }: IInputProps) => {
    return (
      <Box className={classes.dropzoneInner}>
        <input
          type="file"
          accept={accept}
          multiple={false}
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles)
            })
          }}
        />
        <UploadIcon />
        <Hidden smDown>
          <Box className={classes.dropzoneInnerText}>
            <strong>
              {t('drag', 'Drag')}&apos;{t('nDrop', 'n drop')}
            </strong>
            {t('orWithSpaces', ' or ')}
            <strong>{t('click', 'click')}</strong>
            {t('toUploadFiles', ' to upload files')}
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.dropzoneInnerText}>
            <strong>{t('tap', 'Tap')}</strong>
            {t('toUploadFiles', ' to upload files')}
          </Box>
        </Hidden>
      </Box>
    )
  }

  return (
    <Box className={classes.modal}>
      <Box className={classes.modalHeader}>
        <Box>
          <Typography className={classes.addAnattachment} paragraph={true}>
            {t('addAnattachment', 'Add an attachment:')}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Dropzone
          getUploadParams={getUploadParams}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={1}
          maxSizeBytes={MAX_FILE_SIZE_BYTES}
          classNames={{ inputLabel: classes.dzuInputLabel }}
          onChangeStatus={handleChangeStatus}
          PreviewComponent={Preview}
          LayoutComponent={Layout}
          disabled={(files) =>
            files.some((f) =>
              ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status),
            )
          }
          accept=".jpg,.png,.pdf"
          InputComponent={Input}
          autoUpload={true}
          multiple={false}
        />
      </Box>
    </Box>
  )
}

export default DropUploaderContactSupport
