import * as yup from 'yup'
import {
  BIRTHDAY_IS_REQUIRED_MESSAGE,
  INVALID_DATE_FORMAT_MESSAGE,
  LIMITED_AGE,
  LIMITED_AGE_MESSAGE,
  MAXIMUM_DATE_MESSAGE,
} from '../../constants/validations'

import { differenceInYears, isFuture } from 'date-fns'
import { isDateStringValid } from '../../utils/dateUtils'

export const BirthdayInputSchema = yup.object().shape({
  birthday: yup
    .mixed()
    .required(BIRTHDAY_IS_REQUIRED_MESSAGE)
    .test({
      name: 'invalidDateFormat',
      message: INVALID_DATE_FORMAT_MESSAGE,
      test: (value) => value && isDateStringValid(value),
    })
    .test({
      name: 'dateCannotBeInTheFuture',
      message: MAXIMUM_DATE_MESSAGE,
      test: (value) => !isFuture(new Date(value)),
    })
    .test({
      name: 'minAge',
      message: LIMITED_AGE_MESSAGE,
      test: (value) => {
        const distanceYear = differenceInYears(new Date(), new Date(value))
        return distanceYear >= LIMITED_AGE
      },
    }),
})

export type BirthdayInput = yup.Asserts<typeof BirthdayInputSchema>
