import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

import { Fab, Grid, makeStyles, Modal, Typography, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { AlertDataType, LimitsType } from '../../types'
import { SpendingLimits } from '../../pages/CardsPage'
import {
  ActionSignature,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  CardLimitInputType,
  CardStatuses,
  Currency,
  LimitedAccessRight,
  SignatoryRight,
  SpendingLimitRequestType,
  useChangeCardLimitsMutation,
  useGetActionSignatureLazyQuery,
  useGetCardLimitsQuery,
} from '../../graphql'
import Loader from './Loader'
import { unParseLimitsArr } from '../../utils'
import { PATH_PARAMS } from '../../routes/paths'
import { ConfirmSignatureModal } from './ActionsSign2FAModal'
import { RenameCard } from './RenameCard'
import { TerminateCard } from './TerminateCard'
import { useDetermineUserRights } from '../../hooks'
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/confirmed.svg'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  // modal
  modal: {
    maxWidth: 698,
    minWidth: '350px',
    overflow: 'hidden',
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 698,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
  },
  modalWrapper: {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
    maxHeight: '90vh',
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
    marginBottom: 24,
  },
  bodyModal: {
    padding: theme.spacing(0, 3),
    margin: theme.spacing(0),
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}))

type LimitsCardModalProps = {
  cardId: string | number
  isOpen: boolean
  closeModal: () => void
  alias: string
  setIsOpenAlert: (value: boolean) => void
  setAlertData: (value: AlertDataType) => void
  status: Maybe<CardStatuses> | undefined
}

export const SettingsCardModal: FC<LimitsCardModalProps> = ({
  alias,
  cardId,
  isOpen,
  closeModal,
  setIsOpenAlert,
  setAlertData,
  status,
}) => {
  const [limits, setLimits] = useState<LimitsType>()
  // const [limitsArr, setLimitsArr] = useState<CardLimitInputType[]>(limitsDefaultArr)
  const [modalStyle] = useState(getModalStyle)
  const { [PATH_PARAMS.applicationId]: contractId } = useParams() as Record<string, string>
  const [changeCardLimitsMutation] = useChangeCardLimitsMutation()
  const [GetActionSignatureQuery, { data: dataAction }] = useGetActionSignatureLazyQuery()

  const { t } = useTranslation()
  const classes = useStyles()

  const [signNow, setSignNow] = useState(false)
  const [actionModal, setActionModal] = useState<string | undefined>('')

  const { data: cardLimits } = useGetCardLimitsQuery({
    variables: {
      id: cardId,
      contractId,
    },
    fetchPolicy: 'network-only',
  })

  const { userRights } = useDetermineUserRights(contractId as string | number)
  const editableCheck = !(
    userRights?.limitedAccessRight === LimitedAccessRight.CardHolder ||
    userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly ||
    userRights?.limitedAccessRight === LimitedAccessRight.PaymentFacilitationView
  )

  const handleSave = useCallback(
    async (newLimits: CardLimitInputType[]) => {
      try {
        const { data } = await changeCardLimitsMutation({
          variables: {
            cardId: cardId,
            limits: newLimits,
          },
          refetchQueries: [
            {
              query: ActionSignatureRequestsCountDocument,
              variables: {
                contractId: contractId,
                statuses: [ActionSignatureStatus.Pending],
              },
            },
          ],
        })
        const signatureId = ((data?.spendingLimitRequest as SpendingLimitRequestType)
          ?.executionAction?.signatures as ActionSignature[]).find(
          (signaturesItem) => signaturesItem?.isMine,
        )?.id
        if (signNow && !!signatureId) {
          await GetActionSignatureQuery({
            variables: {
              id: signatureId as string,
            },
          })
          setActionModal('sign')
        }
        setSignNow(false)
        data?.spendingLimitRequest?.updatedAt && closeModal()

        if (!signNow) {
          setAlertData({
            title: t('uploadCardLimitsTitle'),
            text: t('afterCardLimitsSignatoriesNotCheck'),
            type: 'success',
            icon: <SuccessIcon />,
          })
        }
        !signNow && setIsOpenAlert(true)
      } catch (e) {
        toast.error((e as Error).message)
      }
    },
    [cardId, changeCardLimitsMutation, closeModal, GetActionSignatureQuery, signNow],
  )

  useEffect(() => {
    if (cardLimits?.contractCard?.limitsWithUsage) {
      const limitsArr = cardLimits.contractCard.limitsWithUsage as CardLimitInputType[]
      const limits = unParseLimitsArr(limitsArr)
      setLimits(limits)
    }
  }, [cardLimits])

  const handleCloseModal = useCallback(
    (success?: boolean) => {
      if (success && userRights?.signatoryRight === SignatoryRight.Sole) {
        setAlertData({
          text: t('cardLimitsSuccessfullySignatoriesSole'),
          type: 'success',
          icon: <SuccessIcon />,
        })
      }
      if (
        (success && userRights?.signatoryRight === SignatoryRight.Joint) ||
        (success && userRights?.signatoryRight === SignatoryRight.Group)
      ) {
        setAlertData({
          title: t('uploadCardLimitsTitle'),
          text: t('afterCardLimitsSignatoriesDualGroup'),
          type: 'success',
          icon: <SuccessIcon />,
        })
      }
      success && setIsOpenAlert(true)
      setActionModal(undefined)
    },
    [userRights],
  )

  return (
    <>
      <Modal
        style={{ zIndex: 1400 }}
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="account-modal-title"
        aria-describedby="account-modal-description"
      >
        <Grid container item style={modalStyle} className={classes.modal}>
          <Box className={classes.modalWrapper}>
            <Grid item className={classes.headerModal}>
              <Typography variant={'h5'}>{t('cardSettings', 'Card settings')}</Typography>
              <Fab
                color="default"
                aria-label="close"
                className={classes.btnCloseModal}
                onClick={closeModal}
              >
                <CloseIcon />
              </Fab>
            </Grid>

            <Grid item xs={12} className={classes.bodyModal}>
              <RenameCard
                cardId={cardId}
                alias={alias ?? ''}
                setIsOpenAlert={setIsOpenAlert}
                setAlertData={setAlertData}
              />
            </Grid>

            <Grid item xs={12} className={classes.bodyModal}>
              {isEmpty(limits) ? (
                <Loader />
              ) : (
                <SpendingLimits
                  limits={limits as LimitsType}
                  setLimits={setLimits}
                  currency={cardLimits?.contractCard?.account?.currency as Currency}
                  modalView={true}
                  saveLimits={handleSave}
                  editable={editableCheck}
                  setSignNow={setSignNow}
                  signNow={signNow}
                  status={status}
                />
              )}
            </Grid>

            <Grid item xs={12} className={classes.bodyModal}>
              <TerminateCard
                cardId={cardId}
                cardName={!!alias ? alias : ''}
                setAlertData={setAlertData}
                setIsOpenAlert={setIsOpenAlert}
                closeModal={closeModal}
              />
            </Grid>
          </Box>
        </Grid>
      </Modal>
      {!!actionModal && !!dataAction && (
        <ConfirmSignatureModal
          openAction={actionModal}
          handleClose={handleCloseModal}
          actionData={dataAction?.actionSignatureRequest as ActionSignature}
          multipleActionsData={[]}
        />
      )}
    </>
  )
}
