import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

import { AlertTipItem } from './Tips'
import { ReactComponent as AttentionIcon } from '../../assets/images/icons/icon-info2.svg'
import { AlertDataType, CardOperationTypes } from '../../types'
import { CardActions2FAModal } from './CardsActions2FAModal'

const useStyles = makeStyles((theme) => ({
  wrapTerminate: {
    width: '100%',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
  },
  footerModal: {
    padding: theme.spacing(2, 0, 3),
  },
  confirmTermination: {
    color: '#EF2828',
    textDecoration: 'underline',
    fontSize: 14,
    cursor: 'pointer',
    lineHeight: '24px',
    fontFamily: 'Arial',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
}))

type TerminateCardModalProps = {
  cardId: string | number
  cardName: string
  setIsOpenAlert: (value: boolean) => void
  setAlertData: (value: AlertDataType) => void
  closeModal: () => void
}

export const TerminateCard: FC<TerminateCardModalProps> = ({
  cardId,
  cardName,
  setIsOpenAlert,
  setAlertData,
  closeModal,
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()

  const onTerminate = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Grid container item className={classes.wrapTerminate}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>{t('terminateCard', 'Terminate card')}</Typography>

          <Box className={classes.alert}>
            <AlertTipItem
              value={t(
                'terminateCardAlert',
                'Card termination can’t be undone. If you want to termporary disable it, consider',
              )}
              valueWordUnderline={t('freezing', 'freezing')}
              iconComponent={<AttentionIcon />}
              type={'warning'}
            />
          </Box>

          <Grid item xs={12} className={classes.footerModal}>
            <Grid container item spacing={3}>
              <Grid item>
                <Box className={classes.confirmTermination} onClick={onTerminate}>
                  {t('confirmTermination', 'Confirm termination')}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CardActions2FAModal
        open={open}
        handleClose={setOpen}
        cardId={cardId}
        cardName={cardName}
        setIsOpenAlert={setIsOpenAlert}
        setAlertData={setAlertData}
        closeTerminateModal={closeModal}
        type={CardOperationTypes.terminate}
      />
    </>
  )
}
