import React, { FC } from 'react'

import { makeStyles, Theme, Hidden } from '@material-ui/core'

import { NonAuthenticatedMenu } from '../MainMenu'
import { FooterNonAuthenticated } from '../Footer'

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}))

export const PreAssessmentLayout: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrap}>
      <NonAuthenticatedMenu />
      <main className={classes.content}>
        <Hidden mdUp>
          <div className={classes.drawerHeader} />
        </Hidden>
        {children}
        <Hidden mdUp>{<FooterNonAuthenticated />}</Hidden>
      </main>
    </div>
  )
}

export default PreAssessmentLayout
