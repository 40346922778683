import React, { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Box,
  makeStyles,
  Modal,
  Typography,
  Fab,
  Button,
  FormControl,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { FormAutocompleteSelect, FormTextField } from '../index'

import { focusKeyPressNext } from '../../utils'
import { CardHolderSchema } from '../../schemes'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  SignatoryRight,
  useAddTeamMateMutation,
} from '../../graphql'
import { newSelectRoleInvite } from '../../stubs'
import { toast } from 'react-toastify'
import { PATH_PARAMS } from '../../routes/paths'
import { useParams } from 'react-router'
import { ListOption } from '../../types'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
    marginBottom: '15px',
    padding: '0 10px 0 10px',

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0, 3, 0),
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
    },
    '& .MuiBox-root ': {
      marginTop: 0,
    },
    '& .MuiGrid-root ': {
      padding: 0,
    },
  },
  modalStyle: {
    '& [tabindex]': {
      outline: 'none',
    },
  },
  modal: {
    maxWidth: 802,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      // maxWidth: 'calc(328px - 32px)',
      maxWidth: '95%',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  wrapBtn: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  btnControl: {
    padding: theme.spacing(1.25, 2.5),
  },
  container: {
    display: 'block',
    padding: '24px 15px 16px 15px',
  },
  wrapField: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    width: '100%',
    position: 'relative',
    '& .MuiInputAdornment-positionEnd': {
      color: '#000000',
      padding: '0 0 0 0',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#000000',
      padding: '18px 0 0 0',
    },
  },
}))

export const CardHolderFormModal: FC<{
  isOpen: boolean
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isOpen, setIsOpenModal }) => {
  const cardHolder: Array<ListOption> = [
    {
      key: 'cardHolder',
      label: 'Cardholder',
      value: 'cardholder',
    },
  ]

  const classes = useStyles()
  const { t } = useTranslation()
  const [modalStyle] = useState(getModalStyle)
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      role: cardHolder[0]?.key,
    },
    resolver: yupResolver(CardHolderSchema),
  })

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const [addTeamMateMutation] = useAddTeamMateMutation()

  const handleClose = useCallback(() => {
    setIsOpenModal(false)
  }, [setIsOpenModal])

  const sendInvitation = useCallback(
    async (formData) => {
      const {
        role,
        signatoryRight,
        groupSignature,
        firstName,
        lastName,
        email,
        birthday,
        nationality,
        phone,
        country,
        streetAddress,
        additionalDetails,
        postalCode,
        city,
      } = formData

      const isAuthorized = role === newSelectRoleInvite.signatory.key

      const input = {
        person: {
          firstName,
          lastName,
          email,
          birthday,
          nationality,
          phone,
          address: {
            line1: streetAddress,
            city,
            zip: postalCode,
            country,
            additionalDetails,
          },
        },
        authorizationSetting: {
          isAuthorizedSignatory: isAuthorized,
          isLimitedAccess: !isAuthorized,
          ...(!isAuthorized ? { limitedAccessRight: role } : {}),
          ...(isAuthorized ? { signatoryRight } : {}),
          ...(isAuthorized && signatoryRight === SignatoryRight.Group
            ? { groupSize: groupSignature }
            : {}),
        },
      }
      try {
        await addTeamMateMutation({
          variables: {
            contractId: applicationId,
            input,
          },
          refetchQueries: [
            {
              query: ActionSignatureRequestsCountDocument,
              variables: {
                contractId: applicationId,
                statuses: [ActionSignatureStatus.Pending],
              },
            },
          ],
        })
      } catch (e) {
        toast.error((e as Error).message)
      }
      handleClose()
    },
    [addTeamMateMutation, applicationId, handleClose],
  )

  return (
    <Grid item xs={12} className={classes.wrap}>
      <Grid item>
        <Modal
          open={isOpen}
          onClose={handleClose}
          className={classes.modalStyle}
          aria-labelledby="account-modal-title"
          aria-describedby="account-modal-description"
        >
          <Grid container item style={modalStyle} className={classes.modal}>
            <Grid item className={classes.headerModal}>
              <Typography variant={'h5'}>{t('newCardholder')}</Typography>
              <Fab
                color="default"
                aria-label="close"
                className={classes.btnCloseModal}
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </Grid>

            <Grid item xs={12} className={classes.container}>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(sendInvitation)}
                  id="forNextFocus"
                  onKeyDown={focusKeyPressNext}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.wrapField}>
                      <Box className={classes.wrap}>
                        <FormControl className={classes.formControl}>
                          <FormTextField
                            type="text"
                            label={t('firstNames', 'First Name(s)')}
                            name="firstName"
                            required={false}
                          />
                        </FormControl>
                      </Box>
                      <Box className={classes.wrap}>
                        <FormControl className={classes.formControl}>
                          <FormTextField
                            type="text"
                            label={t('lastName', 'Last Name')}
                            name="lastName"
                            required={false}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.wrapField}>
                      <Box className={classes.wrap}>
                        <FormControl className={classes.formControl}>
                          <FormTextField
                            type="text"
                            label={t('email', 'Email')}
                            name="email"
                            required={false}
                          />
                        </FormControl>
                      </Box>
                      <Box className={classes.wrap}>
                        <FormControl className={classes.formControl}>
                          <FormAutocompleteSelect
                            label={t('role', 'Role')}
                            name="role"
                            data={cardHolder}
                            readOnly={true}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.wrapBtn}>
                    <Grid container item spacing={3} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          disableElevation
                          className={classes.btnControl}
                        >
                          {t('cancel', 'Cancel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={!methods.formState?.isValid}
                          color="primary"
                          className={classes.btnControl}
                          disableElevation
                        >
                          {t('sendInvitation')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  )
}

export default CardHolderFormModal
