import * as yup from 'yup'
import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  LATINICA_ONLY_MESSAGE,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
} from '../../constants/validations'
import i18n from '../../i18n'

export const RegulatorInformationImputSchema = yup.object().shape({
  licenseRequired: yup.boolean().default(false),
  regulatorNameAndWebsite: yup
    .string()
    .nullable()
    .when('licenseRequired', {
      is: true,
      then: yup
        .string()
        .test(
          'regulatorNameAndWebsite',
          LATINICA_ONLY_MESSAGE,
          (val) => !!val?.match(ALL_EXCEPT_CYRILLIC_REGEXP),
        ),
    })
    .when('licenseRequired', {
      is: true,
      then: yup
        .string()
        .test(
          'regulatorNameAndWebsiteMaxLength',
          i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON),
          (val) => {
            return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
          },
        ),
    }),
  licenseNumber: yup
    .string()
    .nullable()
    .when('licenseRequired', {
      is: true,
      then: yup
        .string()
        .test(
          'licenseNumber',
          LATINICA_ONLY_MESSAGE,
          (val) => !val || !!val?.match(ALL_EXCEPT_CYRILLIC_REGEXP),
        ),
    })
    .when('licenseRequired', {
      is: true,
      then: yup
        .string()
        .test('licenseNumberMaxLength', i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON), (val) => {
          return !val || (!!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX)
        }),
    }),
})
