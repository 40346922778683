import * as yup from 'yup'
import { TIN_REGEX, THIS_FIELD_IS_REQUIRED_MESSAGE, TIN_IS_INVALID } from '../constants/validations'

export const PersonAdditionalDataSchema = yup.object().shape({
  USReportablePerson: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  tin: yup.mixed().when('USReportablePerson', {
    is: 'Yes',
    then: yup
      .mixed()
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
      .test('invalidTIN', TIN_IS_INVALID, (val) => TIN_REGEX.test(val)),
  }),
  politicallyExposedPerson: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
})
