import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'

import { ReactComponent as DatePickerIcon } from '../../../assets/images/icons/date_picker.svg'
import { formatDate } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme) => ({
  pickerWrap: {
    position: 'relative',
    margin: theme.spacing(3, 0, 2.5),
  },
  picker: {
    width: '100%',
    margin: theme.spacing(0),
    '& #executionDate-helper-text': {
      color: theme.palette.error.main,
    },
  },
  redError: {
    left: 0,
    color: '#EF2828',
  },
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
      color: '#999999',
    },
  },
}))

export const FormDatePickerExpirationField: FC<{
  name: string
  label: string
  defaultValue?: string
  readOnly?: boolean
  noPast?: boolean
}> = ({ name = 'date-picker-inline', label = 'Date', readOnly = false, noPast = false }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | string>(null)
  const { register, setValue, errors, watch, trigger } = useFormContext()
  const error = errors ? errors[name] : null
  const classes = useStyles()

  const dateFormValue = watch(name)
  const documentAlreadyUploaded = watch(`documentAlreadyUploaded`)

  const handleDateChange = useCallback(
    (date: Date | null, value?: string | null) => {
      const formattedDate = formatDate(date, true)
      if (!value?.includes('_') && formattedDate) {
        setValue(name, formattedDate, {
          shouldDirty: true,
        })

        if (formattedDate && date?.getTime()) {
          trigger(name)
        }

        setValue(name, formattedDate, {
          shouldValidate: false,
          shouldDirty: false,
        })
      }
    },
    [setValue, name, trigger],
  )

  const handleDateBlur = () => {
    if (!!dateFormValue) {
      trigger(name)
    }
  }

  useEffect(() => {
    register(name)
  }, [register])

  useEffect(() => {
    if (dateFormValue) {
      setSelectedDate(dateFormValue)
    } else {
      setSelectedDate(null)
    }
  }, [dateFormValue])

  useEffect(() => {
    if (documentAlreadyUploaded) {
      setValue(name, documentAlreadyUploaded.expiration)
    }
  }, [documentAlreadyUploaded, setValue, name])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around" className={classes.pickerWrap}>
        <KeyboardDatePicker
          className={`${classes.picker} ${readOnly ? classes.readOnly : ''}`}
          format="dd/MM/yyyy"
          margin="normal"
          name={name}
          id={name}
          label={label}
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
          onBlur={handleDateBlur}
          //invalidDateMessage={'Invalid date format (DD/MM/YYYY)'}
          invalidDateMessage={''}
          error={!!error}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDateMessage={''}
          maxDateMessage={''}
          okLabel="Select"
          readOnly={readOnly}
          disablePast={noPast}
          data-test={`autotest-${name}`}
          keyboardIcon={<DatePickerIcon />}
        />
        {error && <FormHelperText className={classes.redError}>{error.message}</FormHelperText>}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
