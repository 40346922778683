import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { isEmpty } from 'lodash'

import { useStyles } from './useStyles'
import { SignaturesTable, SignaturesBox } from './helpers'
import { cardSpendingLimitTypes, limitsChanged, limitsValueFormat } from '../../../utils'
import { SpendingLimitProps } from '../../../types'
import { Currency, SpendingLimitRequestAction } from '../../../graphql'

export const CardLimitTab: FC<{ action: SpendingLimitProps; signedCount: number }> = ({
  action,
  signedCount,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const currencyFormatOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  const card = (action?.rawData as SpendingLimitRequestAction)?.spendingLimitRequest?.card
  const spendingLimitRequest = action?.spendingLimitData?.spendingLimitRequest

  const renderSpendingLimitsTab = () => {
    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={'40%'}>{t('limit', 'Limit')}</TableCell>
              <TableCell>{t('current', 'Current')}</TableCell>
              <TableCell>{t('new', 'New')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {limitsChanged(
              !isEmpty(spendingLimitRequest?.oldLimits)
                ? spendingLimitRequest?.oldLimits
                : card?.limitsWithUsage,
              spendingLimitRequest?.limits,
            ).map((limitItem, i) => {
              return (
                <TableRow key={`${limitItem}+${i}`}>
                  <TableCell>{cardSpendingLimitTypes(limitItem.cardSpendingLimitTypes)}</TableCell>
                  <TableCell>
                    {limitsValueFormat(
                      card?.account?.currency as Currency,
                      limitItem.limitUsageAmount,
                      currencyFormatOptions,
                    )}
                  </TableCell>
                  <TableCell>
                    {limitsValueFormat(
                      card?.account?.currency as Currency,
                      limitItem.limitAmount,
                      currencyFormatOptions,
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('cardName', 'Card name')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{`${card?.alias ?? ''} *${card?.apiMaskedCardNumber?.slice(
                  -4,
                )}`}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('cardHolder', 'Cardholder')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{`${card?.cardHolder?.person?.firstName} ${card?.cardHolder?.person?.lastName}`}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200" colSpan={2} className={classes.cell}>
                <Typography variant="h4" className={classes.label}>
                  {t('spendingLimits', 'Spending limits')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {renderSpendingLimitsTab()}

        <SignaturesTable signatures={action.signatures} signedCount={signedCount} />
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('cardName', 'Card name')}</Box>
              <Box className={classes.mobileTableName}>{`${
                card?.alias
              } *${card?.apiMaskedCardNumber?.slice(-4)}`}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('cardHolder', 'Cardholder')}</Box>
              <Box
                className={classes.mobileTableName}
              >{`${card?.cardHolder?.person?.firstName} ${card?.cardHolder?.person?.lastName}`}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('spendingLimits', 'Spending limits')}
              </Box>
            </Box>

            {renderSpendingLimitsTab()}

            <SignaturesBox signatures={action.signatures} signedCount={signedCount} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
