import { Box, ButtonProps, ClickAwayListener, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsLimitedContract } from '../../../hooks'

type TooltipInfo =
  | { title: string; disabledOperationName?: never }
  | { title?: never; disabledOperationName: string }

export const ButtonWithTooltip: FC<
  TooltipInfo & {
    disabled: boolean
    hiddeTooltip?: boolean
    placement?: TooltipProps['placement']
    color?: ButtonProps['color']
    buttonClassName?: string
    fullWidth?: boolean
  } & Partial<ButtonProps>
> = ({
  title,
  disabledOperationName,
  disabled,
  hiddeTooltip,
  placement = 'bottom',
  color = 'default',
  fullWidth,
  children,
  ...rest
}) => {
  const { t } = useTranslation()

  const [showTooltip, setShowTooltip] = useState(false)

  const { contractStatusLabel } = useIsLimitedContract()

  const message = title
    ? title
    : t(
        'isNotAvailable',
        `${disabledOperationName} is not available, because the contract is ${contractStatusLabel}`,
        {
          option: disabledOperationName,
          contractStatusLabel,
        },
      )

  const handleShowTooltip = () => {
    disabled && setShowTooltip(true)
  }
  const handleHideTooltip = () => {
    disabled && setShowTooltip(false)
  }

  const handlePrevent = (e: React.MouseEvent) => {
    if (!disabled) return
    e.preventDefault()
    e.stopPropagation()
  }
  return (
    <ClickAwayListener onClickAway={handleHideTooltip}>
      <Tooltip
        title={message}
        disableHoverListener={!disabled}
        disableFocusListener={!disabled}
        disableTouchListener={!disabled}
        placement={placement}
        open={showTooltip && !hiddeTooltip}
        arrow
      >
        <Box
          onTouchStart={handleShowTooltip}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={handleHideTooltip}
          onClick={handlePrevent}
          width={fullWidth ? '100%' : 'auto'}
        >
          <Button
            disableElevation
            color={color}
            disabled={disabled}
            {...rest}
            fullWidth={fullWidth}
          >
            {children}
          </Button>
        </Box>
      </Tooltip>
    </ClickAwayListener>
  )
}
