import * as yup from 'yup'
import {
  COMPANY_NAME_REGEX,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
} from '../../constants/validations'
import { isEmpty } from 'lodash'
import i18n from '../../i18n'

export const AliasOptionalInputSchema = yup.object().shape({
  alias: yup
    .string()
    .nullable()
    .test({
      name: 'nameOptional',
      message: i18n.t('theFieldMustHaveLatin', 'Must have at least {{min}} characters', {
        min: MIN_LENGTH,
      }),
      test: (value) => (!!value && value?.length >= 2) || value === '',
    })
    .test({
      name: 'numberAndLatinicaField',
      message: NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
      test: (value) => isEmpty(value) || !!value?.match(COMPANY_NAME_REGEX),
    }),
})

export type AliasOptionalInput = yup.Asserts<typeof AliasOptionalInputSchema>
