import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as DateFns from 'date-fns'

import { LOGOUT_AFTER_TIME } from '../../constants'
import { idleSessionLoggedOff } from '../../graphql/local'

export const LogOutModal: FC<{
  isIdle: boolean
  onContinueSession: () => void
  onLogOut: () => void
}> = ({ isIdle, onContinueSession, onLogOut }) => {
  const [timeToAutoLogoff, setTimeToAutoLogoff] = useState<number>(LOGOUT_AFTER_TIME)
  const timeToAutoLogOffIntervalRef = React.useRef<NodeJS.Timeout>()

  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    onContinueSession()
  }, [onContinueSession])

  const logOut = useCallback(() => {
    onLogOut()
  }, [onLogOut])

  const handleLogoutByTime = useCallback(() => {
    idleSessionLoggedOff(true)
    logOut()
  }, [logOut])

  const reset = useCallback(() => {
    clearInterval(timeToAutoLogOffIntervalRef.current)
    setTimeToAutoLogoff(LOGOUT_AFTER_TIME)
  }, [])

  useEffect(() => {
    if (isIdle) {
      if (timeToAutoLogoff > 0) {
        timeToAutoLogOffIntervalRef.current = setInterval(() => {
          setTimeToAutoLogoff((prev) => prev - 1000)
        }, 1000)
      }
    } else if (timeToAutoLogOffIntervalRef.current) {
      reset()
    }
    return () => clearInterval(timeToAutoLogOffIntervalRef.current)
  }, [isIdle])

  useEffect(() => {
    if (timeToAutoLogoff < 0) {
      handleLogoutByTime()
      reset()
    }
  }, [timeToAutoLogoff, handleLogoutByTime, reset])

  const timeToLogOffDuration = DateFns.intervalToDuration({ start: 0, end: timeToAutoLogoff })
  const durationFormat = timeToAutoLogoff > 60000 ? ['minutes', 'seconds'] : ['seconds']
  const timeToLogOffDurationString = DateFns.formatDuration(timeToLogOffDuration, {
    format: durationFormat,
  })

  return (
    <Dialog open={isIdle} onClose={() => onContinueSession()} maxWidth="sm" fullWidth>
      <DialogContent>
        <Typography paragraph variant="h6">
          {t(
            'sessionWillExpireInTwoMinutes',
            'Your session will expire in {{timeFrame}}. Do you want to extend the session?',
            {
              timeFrame: timeToLogOffDurationString,
            },
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box mr={2} mb={1} display="flex" flexDirection="row" gridGap={16}>
          <Button variant="contained" disableElevation onClick={logOut}>
            {t('logOut', 'Log Out1')}
          </Button>
          <Button variant="contained" color="primary" disableElevation onClick={handleClose}>
            {t('continue', 'Continue')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
