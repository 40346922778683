import React, { FC, useCallback } from 'react'
import { Grid, makeStyles, Button, Typography, Box } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { FormTextField } from '../../Common'
import { TipsItem } from './Tips'
import { SettingsInput, SettingsInputSchema } from '../../../schemes'
import config from '../../../config'

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: '24px',
    '& .MuiTypography-gutterBottom': {
      marginTop: '1em',
      marginBottom: 'unset',
    },
  },
  topMrg: {
    paddingTop: 30,
  },
}))

const GridRow: FC = ({ children }) => {
  return (
    <Grid item container spacing={3} xs={12}>
      {children}
    </Grid>
  )
}

//TODO check usage of this component
export const Settings: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<SettingsInput>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(SettingsInputSchema),
  })
  const classes = useStyles()

  const onChangePassword = useCallback(async (formData: SettingsInput) => {
    // eslint-disable-next-line no-console
    console.log('change password')
    // eslint-disable-next-line no-console
    console.log(formData)
  }, [])

  const onChangePhoneNumber = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('change phone number')
  }, [])

  const onUploadSignature = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('upload signature')
  }, [])

  return (
    <FormProvider {...methods}>
      <Box className={classes.root}>
        <form onSubmit={methods.handleSubmit(onChangePassword)}>
          <Grid container spacing={3} className={classes.topMrg}>
            <Grid item xs={12}>
              <Typography variant={'h1'}>
                {t('changeAccountPassword', 'Change Account Password')}
              </Typography>
            </Grid>
            <GridRow>
              <Grid item xs={6}>
                <FormTextField
                  label={t('firstNames', 'First Name(s)')}
                  name="firstName"
                  type="text"
                  placeholder={t('authorisedMember1FirstName', 'Authorised Member 1 First Name')}
                  fullWidth={true}
                  required={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </GridRow>
            <GridRow>
              <Grid item xs={6}>
                <FormTextField
                  label={t('lastName', 'Last Name')}
                  name="lastName"
                  type="text"
                  placeholder={t('authorisedMember1LastName', 'Authorised Member 1 Last Name')}
                  fullWidth={true}
                  required={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </GridRow>
            <GridRow>
              <Grid item xs={6}>
                <FormTextField
                  label={t('dateOfBirth', 'Date of Birth')}
                  name="birthday"
                  type="text"
                  placeholder={t(
                    'authorisedMember1DateOfBirthDDMMYYYY',
                    'Authorised Member 1 Date of Birth (DD/MM/YYYY)',
                  )}
                  fullWidth={true}
                  required={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </GridRow>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" disableElevation color="primary">
                {t('changePassword', 'Change password')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid container spacing={3} className={classes.topMrg}>
          <Grid item xs={12}>
            <Typography variant={'h1'}>
              {t('2StepVerificationSettings', '2 Step Verification Settings')}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <p>
              {t(
                'yourTwoStepVerificationIsConnectedViaSmsOnPhoneNumber',
                'Your two step verification is connected via SMS on phone number ',
              )}
              <b>{t('phoneNumberExample', '+41 5464569874')}</b>
            </p>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              component="span"
              onClick={onChangePhoneNumber}
            >
              {t('changePhoneNumber', 'Change Phone Number')}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.topMrg}>
          <Grid item xs={12}>
            <Typography variant={'h1'}>{t('signatureSpecimen', 'Signature Specimen')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <p>
              {t(
                'klarpayMayAcceptDocumentsInTheFutureViaPost',
                '{{val1}} may accept documents in the future via post and require your signature for refference. Please sign a a piece of paper and upload a photo of your singature speciment.',
                {
                  val1: config.name,
                },
              )}
            </p>
            <p>{t('notes', 'Notes')}:</p>
            <p>
              - {t('mustBeSignedOnAWhitePieceOfPaper', 'Must be signed on a white piece of paper')}
            </p>
            <p>
              -{' '}
              {t(
                'thePhotoOfTheSignatureMustBeLegibleAndClear',
                'The photo of the signature must be legible and clear',
              )}
            </p>
            <p>- {t('uploadThePhoto', 'Upload the photo (JPG or PNG up to 5MB)')}</p>
          </Grid>
          <Grid item xs={5}>
            <TipsItem />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              component="span"
              onClick={onUploadSignature}
            >
              {t('uploadSignature', 'Upload Signature')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}
