import {
  Box,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { AppActions, displayStatusKeys, statusActions } from '../../../constants'
import clsx from 'clsx'
import {
  BankableEntityContractType,
  ContractStatusType,
  ContractType,
  Individual,
  LegalEntity,
  UboLegalEntityType,
  UboType,
} from '../../../graphql'
import { ActionsMenu } from '../ActionsMenu'
import { isEmpty } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  applicationsList: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('md')]: {
        padding: '6px 4px',
      },
    },
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkName: {
    cursor: 'pointer',
  },
}))
const BusinessAppDesktop: FC<{
  contracts: BankableEntityContractType[]
  actions: { [key in AppActions]: (contract: BankableEntityContractType) => void }
  updateFlag: (value: boolean) => void
  deleteApp: (value: string | number) => void
}> = ({ contracts, actions, deleteApp, updateFlag }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.applicationsList} data-test="applicationsList">
      <TableContainer>
        <Table>
          <TableBody>
            {contracts &&
              contracts.map(
                (contract) =>
                  contract && (
                    <TableRow
                      hover
                      key={contract.id}
                      data-test="businessApplications"
                      data-status={contract.status}
                    >
                      <TableCell data-test={`${displayStatusKeys[contract.status as string]}`}>
                        <Box className={clsx(classes.textEllipsis, 'nameApp')}>
                          <Link
                            component={'span'}
                            className={classes.linkName}
                            onClick={() =>
                              actions[statusActions[contract.status as ContractStatusType][0]](
                                contract as BankableEntityContractType,
                              )
                            }
                            underline="always"
                            title={t('name', 'Name')}
                          >
                            {contract?.ownerType === UboType.NaturalPerson
                              ? ((contract?.owner as Individual).fullName as string)
                              : ((contract?.owner as LegalEntity).companyName as string)}
                          </Link>
                        </Box>
                      </TableCell>
                      <TableCell width="160" align={'right'}>
                        <Box
                          component="span"
                          className={clsx(
                            `roundedPill ${displayStatusKeys[contract.status as string]}`,
                          )}
                        >
                          {t(displayStatusKeys[contract.status as string])}
                        </Box>
                      </TableCell>
                      <TableCell width="55">
                        <ActionsMenu
                          updateFlag={updateFlag}
                          deleteApp={deleteApp}
                          status={contract.status as ContractStatusType}
                          contractId={contract.id}
                          hasAPs={!isEmpty(contract.authorizedPersons)}
                          hasUbo={!isEmpty(contract.ubos)}
                          hasPendingBusinessDetails={
                            (contract.owner as LegalEntity).legalEntityType ===
                              UboLegalEntityType.Unknown &&
                            contract.type !== ContractType.IntroducerReference
                          }
                          ownerType={contract.ownerType as UboType}
                          owner={contract.owner}
                        />
                      </TableCell>
                    </TableRow>
                  ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BusinessAppDesktop
