import React, { FC, useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import clsx from 'clsx'

import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as IconDevice } from '../../assets/images/icons/2faIcon.svg'
import { ReactComponent as IconX } from '../../assets/images/icons/icon-x.svg'
import { ReactComponent as IconDeviceWarning } from '../../assets/images/icons/iconDeviceWarning.svg'
import {
  TwoFaCodeDispatchMode,
  TwoFaMethod,
  useGetActionSigningCodeMutation,
  useGetCardSensitiveInfoLazyQuery,
} from '../../graphql'
import { resendDelay } from '../../graphql/local'
import { AlertDataType, SettingsTabsEnum } from '../../types'
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/confirmed.svg'
import { InputCodeField } from './InputCode'
import { checkFullCode } from '../../utils'
import { useCurrentUser } from '../../hooks'
import { TOTP_STRING_PARAM } from '../../constants'
import { TwoFAModalRenderButton } from './TwoFAModalRenderButton'
import { generatePath, useHistory } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useParams } from 'react-router'
import { personalProfileSettingsTab } from '../../graphql/local/dashboard'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 3.5, 6),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 14, 3),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
      position: 'absolute',
      left: '0',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  btnChange2FA: {
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    margin: theme.spacing(2, 0, 0, 0),
  },
  btnChange: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const CardDetailsActions2FAModal: FC<{
  open: boolean
  handleClose: React.Dispatch<React.SetStateAction<boolean>>
  cardName?: string
  cardId: string | number
  setIsOpenAlert: (value: boolean) => void
  setAlertData: (value: AlertDataType) => void
  setNumber: (value: string | undefined | null) => void
  setCvv: (value: string | undefined | null) => void
}> = ({ open, handleClose, cardId, setIsOpenAlert, setAlertData, setNumber, setCvv }) => {
  const initialValue = useMemo(() => ({ d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }), [])
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassege, setValidMassege] = useState<string>('')
  const [successClose, setSuccessClose] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [phoneLastFourDigits, setPhoneLastFourDigits] = useState<string | null>()

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const startInput = useRef<HTMLInputElement>(null)
  const resultCheckFullCode = checkFullCode(code)
  const currentUser = useCurrentUser()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const [GetCardSensitiveInfoQuery, { data: cardInfo, error }] = useGetCardSensitiveInfoLazyQuery()

  const [
    getActionSigningCodeMutation,
    { data: actionServiceData },
  ] = useGetActionSigningCodeMutation()

  const enteredCode = Object.values(code).join('')

  const handleActionServiceData = useCallback(
    async (twoFAMode?: TwoFaCodeDispatchMode) => {
      try {
        const { data } = await getActionSigningCodeMutation({ variables: { mode: twoFAMode } })
        if (data?.getActionSigningCode) {
          const { resendTimeout, attemptsLeft, phoneLastFourDigits } = data.getActionSigningCode
          setPhoneLastFourDigits(phoneLastFourDigits?.slice(2))
          resendDelay((resendTimeout as number) * 1000)
          setAttemptsLeft(attemptsLeft as number)
        }
      } catch (e) {
        toast.error((e as Error).message)
        handleClose(false)
        // refreshPage()
      }
    },
    [getActionSigningCodeMutation, handleClose],
  )

  const onReSend = useCallback(() => {
    if (enteredCode.length === 6 && cardId) {
      setCode(initialValue)
      setIsError(false)
      setBtnDisable(true)
      setSecondsLeft(millisecondsToSeconds(resendDelay()))
      setValidMassege('')
      handleActionServiceData()
    }
  }, [cardId, enteredCode, handleActionServiceData])

  const sendCode = useCallback(() => {
    if (enteredCode.length === 6) {
      setLoading(true)
      setSubmit(true)
      try {
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        GetCardSensitiveInfoQuery({
          variables: {
            challengeId: `${challengeId}`,
            code: +enteredCode,
            cardId,
          },
        })
      } catch (e) {
        setIsError(true)
        if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
          setValidMassege(t('codeHasExpired', 'Code has expired'))
        } else {
          setValidMassege(t('codeIncorrect', 'Code is incorrect'))
        }
        setLoading(false)
        if (null !== startInput.current) {
          startInput.current.focus()
        }
      }
    }
  }, [
    enteredCode,
    actionServiceData,
    GetCardSensitiveInfoQuery,
    cardId,
    t,
    currentUser?.primaryTwoFAMethod,
  ])

  useEffect(() => {
    if (isSubmit) {
      if (cardInfo) {
        setValidMassege(t('codeCorrect', 'Code is correct'))
        setIsError(false)
        setLoading(false)
        setSuccessClose(true)
        setNumber(cardInfo?.getCardSensitiveInfo?.cardNumber)
        setCvv(cardInfo?.getCardSensitiveInfo?.cvv)
        setAlertData({
          text: t('cardDetailsRevealed'),
          type: 'success',
          icon: <SuccessIcon />,
        })
        setIsOpenAlert(true)
      }
      if (error) {
        setIsError(true)
        setValidMassege(t('codeIncorrect', 'Code is incorrect'))
        setLoading(false)
        if (null !== startInput.current) {
          startInput.current.focus()
        }
      }
    }
  }, [t, setNumber, setCvv, setAlertData, setIsOpenAlert, isSubmit, cardInfo, error])

  const onClose = useCallback(() => {
    setCode(initialValue)
    setIsError(false)
    setValidMassege('')
    handleClose(false)
  }, [handleClose, initialValue])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  useEffect(() => {
    enteredCode.length === 6 && sendCode()
  }, [code, sendCode])

  useEffect(() => {
    if (open && cardId && currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms) {
      handleActionServiceData()
    }
    setSecondsLeft(millisecondsToSeconds(resendDelay()))
  }, [cardId, open, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (successClose) {
      const timerId = setTimeout(() => {
        setCode(initialValue)
        setIsError(false)
        setValidMassege('')
        handleClose(false)
        setSuccessClose(false)
      }, 1500)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [handleClose, successClose, initialValue])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMassege('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  const confirmSignatureMessage = useMemo(() => {
    return currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>{phoneLastFourDigits ? `${phoneLastFourDigits}` : ''}</b>.{' '}
        {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [phoneLastFourDigits, currentUser?.primaryTwoFAMethod])

  return (
    <div>
      <Modal
        style={{ zIndex: 1410 }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={clsx(
            classes.paper,
            resultCheckFullCode && isSubmit && isError && 'error',
            validMassege && isSubmit && !isError && 'success',
          )}
        >
          <IconButton
            className={classes.btnClose}
            color="primary"
            aria-label="close modal"
            component="span"
            onClick={onClose}
          >
            <IconX />
          </IconButton>
          <Grid className="iconContainer">{!isError ? <IconDevice /> : <IconDeviceWarning />}</Grid>
          <Box textAlign="center" mb={1}>
            <Typography variant={'h2'}>
              {t('verifyYourIdentity', 'Please verify your identity')}
            </Typography>
          </Box>
          <Box textAlign="center">{confirmSignatureMessage}</Box>
          <InputCodeField
            startInput={startInput}
            currencyValue={code}
            setCode={handleSetCode}
            loading={loading}
          />
          <Grid container justifyContent="center" className="infoText">
            {!!validMassege && resultCheckFullCode && <span>{validMassege}</span>}
          </Grid>
          <TwoFAModalRenderButton
            isNotShow={
              (validMassege && !isError) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp
            }
            secondsLeft={secondsLeft}
            btnDisable={btnDisable}
            onReSend={onReSend}
            isShowContactSupportLink={attemptsLeft < 1}
          />
          <Grid item xs={12} className={classes.btnChange}>
            <Link
              component="button"
              onClick={() => {
                personalProfileSettingsTab(2)
                history.push(
                  generatePath(APP_PATHS.dashboard.settings, {
                    [PATH_PARAMS.applicationId]: applicationId,
                  }) + `?tabId=${SettingsTabsEnum.twoFactor}`,
                )
              }}
              className={classes.btnChange2FA}
              underline="always"
            >
              {t('changeVerificationMethod', 'Change verification method')}
            </Link>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
