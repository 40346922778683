import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { Logo } from './logo'

export const SplashScreen = withStyles(() => ({
  splash: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(({ classes }: WithStyles) => (
  <div className={classes.splash}>
    <Logo />
  </div>
))
