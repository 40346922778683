import React, { FC } from 'react'
import { Box, Grid, Hidden, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    overflow: 'auto',
    scrollbarGutter: 'stable',
    '&.MuiGrid-spacing-xs-6 > .MuiGrid-item': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    '& > .content': {
      display: 'flex',
      width: '100%',

      '& .logo': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(3),
        '&.noMarginTop': {
          marginTop: 0,
        },
        '&.hiddenMdUp': {
          [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            '& > a': {
              display: 'none',
            },
          },
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: '100%',
    },
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
  wrapperTitle: {
    width: '100%',
    textAlign: 'left',
  },
  signupPanel: {
    height: '100%',
    padding: '48px 112px',
    [theme.breakpoints.down('md')]: {
      padding: '48px 100px 40px 100px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 16px 32px 16px',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5.25),
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(9),
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  imgContainer: {
    position: 'relative',
  },
  imgBox: {
    // height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundColor: '#F0F3F7',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      bottom: -48,
      left: 0,
      right: -8,
    },
  },
  fullScreen: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  footer: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(7),
    fontSize: '0.75rem',
  },
  footer_company: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  footer_flag: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

type ContainerWithImageProps = {
  children?: React.ReactNode
  title?: string
  image?: string
  reverse?: boolean
  classWrapper?: string
  isRightMenu?: boolean
}

export const ContainerWithImage: FC<ContainerWithImageProps> = ({
  children,
  title,
  image = config.images.default,
  classWrapper,
  reverse = false,
  isRightMenu = true,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid
      container
      direction="row"
      spacing={isRightMenu ? 6 : 0}
      className={`${classes.mainContainer} ${reverse && classes.reverse}`}
    >
      <Grid item md={6} className={classWrapper}>
        {title ? (
          <Grid item xs={12} className={classes.wrapperTitle}>
            <Typography variant={'h2'} className={classes.title}>
              {title}
            </Typography>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          container
          className={classes.signupPanel}
          direction="row"
          justifyContent="center"
        >
          {children}

          <Grid item xs={12} container spacing={1} className={classes.footer}>
            <Grid item className={classes.footer_company}>
              <b>{t(config.isWhitelabel ? 'poweredByKlarpayAG' : 'klarpayAG')}</b>
            </Grid>
            <Grid item container spacing={1} className={classes.footer_flag}>
              <Grid item>
                <img
                  src={process.env.PUBLIC_URL + '/img/Flag_of_Switzerland 1.png'}
                  alt={'Flag_of_Switzerland'}
                />
              </Grid>
              <Grid item>{t('madeInSwitzerland', 'Made in Switzerland')}</Grid>
            </Grid>
            {process.env.REACT_APP_VERSION && (
              <Grid item container spacing={1} className={classes.footer_flag}>
                <Grid item>{`Version: ${process.env.REACT_APP_VERSION}`}</Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item md={6} className={classes.imgContainer}>
          <Box
            className={`${classes.imgBox} ${!isRightMenu ? classes.fullScreen : ''}`}
            style={{ backgroundImage: `url(${image ? image : ''})` }}
          />
        </Grid>
      </Hidden>
    </Grid>
  )
}
