import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { Box, Checkbox, makeStyles, TableCell, TableRow } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import {
  IndividualEmailCategoryPreference,
  useUpdateIndividualEmailCategoryPreferenceMutation,
} from '../../../graphql'

const useStyles = makeStyles((theme: Theme) => ({
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
  tableCell: {
    borderBottom: 'none',
    padding: theme.spacing(0.5, 0.5),
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableCellCheck: {
    width: 10,
  },
}))

export const EmailPreferencesListRow: FC<{
  data: IndividualEmailCategoryPreference
}> = ({ data }) => {
  const [checked, setChecked] = useState<'checked' | 'unchecked' | undefined>(undefined)
  const classes = useStyles()

  const disableClickPropagation = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }, [])

  const [
    updateIndividualEmailCategoryPreference,
  ] = useUpdateIndividualEmailCategoryPreferenceMutation()

  useEffect(() => {
    if (data && checked === undefined) {
      setChecked(data.active ? 'checked' : 'unchecked')
    }
  }, [data, setChecked, checked])

  const handleCheck = useCallback(async () => {
    if (data) {
      setChecked(checked === 'checked' ? 'unchecked' : 'checked')
      await updateIndividualEmailCategoryPreference({
        variables: {
          emailCategoryId: data.category.id,
          active: checked !== 'checked',
        },
      }).catch((e) => {
        toast.error((e as Error).message)
        setChecked(checked === 'checked' ? 'checked' : 'unchecked')
      })
    }
  }, [data, updateIndividualEmailCategoryPreference, checked])

  return (
    <TableRow>
      <TableCell className={clsx(classes.tableCell, classes.tableCellCheck)}>
        <Box>
          <Checkbox
            checked={checked === 'checked'}
            icon={<Unchecked />}
            checkedIcon={<Checked />}
            onChange={handleCheck}
            onClick={disableClickPropagation}
            classes={{ disabled: classes.disabledCheckbox }}
          />
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.tableCell)}>{data.category.description}</TableCell>
    </TableRow>
  )
}
