import * as yup from 'yup'

import {
  AdditionalDetailsInputSchema,
  AddressInputSchema,
  OwnerShipPercent1InputSchema,
  OwnerShipPercentInputSchema,
  PostalCodeInputSchema,
  StreetAddressInputSchema,
} from './common'

import {
  DATE_OF_REGISTRATION_REQUIRED_MESSAGE,
  INVALID_DATE_FORMAT_MESSAGE,
  MIN_REGISTARTION_NUMBER,
  MIN_REGISTARTION_NUMBER_MESSAGE,
  NUMBER_AND_LATINICA_AND_SYMBOLS,
  NUMBER_AND_LATINICA_AND_SYMBOLS_REGEXP,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../constants/validations'
import i18n from '../i18n'
import { isFuture } from 'date-fns'
import { isDateStringValid } from '../utils/dateUtils'

export const LegalEntityValidationSchema = yup
  .object()
  .shape({
    entityType: yup.string(),
    name: yup
      .string()
      .matches(NUMBER_AND_LATINICA_AND_SYMBOLS_REGEXP, NUMBER_AND_LATINICA_AND_SYMBOLS)
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    registartionNumber: yup
      .string()
      .test(
        'minLength',
        MIN_REGISTARTION_NUMBER_MESSAGE,
        (val) => !!val && val?.length >= MIN_REGISTARTION_NUMBER,
      )
      .required(i18n.t('commercialNumberRequired', 'Commercial Registration Number is required')),
    registrationDate: yup
      .mixed()
      .required(DATE_OF_REGISTRATION_REQUIRED_MESSAGE)
      .test({
        name: 'minDate',
        message: i18n.t('dateRegistrationMin', "Date of Registration can't be in the future"),
        test: (value) => !isFuture(new Date(value)),
      })
      .test({
        name: 'invalidDateFormat',
        message: INVALID_DATE_FORMAT_MESSAGE,
        test: (value) => value && isDateStringValid(value),
      }),
  })
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(AdditionalDetailsInputSchema)
  .concat(OwnerShipPercentInputSchema)

export type LegalEntityInput = yup.Asserts<typeof LegalEntityValidationSchema>

export const DomLegalEntityValidationSchema = yup
  .object()
  .shape({
    entityType: yup.string().required(i18n.t('thisFieldIsRequired', 'This field is required')),
    name: yup
      .string()
      .matches(NUMBER_AND_LATINICA_AND_SYMBOLS_REGEXP, NUMBER_AND_LATINICA_AND_SYMBOLS)
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    registartionNumber: yup
      .string()
      .test(
        'minLength',
        MIN_REGISTARTION_NUMBER_MESSAGE,
        (val) => !!val && val?.length >= MIN_REGISTARTION_NUMBER,
      )
      .required(i18n.t('commercialNumberRequired', 'Commercial Registration Number is required')),
    registrationDate: yup
      .mixed()
      .required(DATE_OF_REGISTRATION_REQUIRED_MESSAGE)
      .test({
        name: 'minDate',
        message: i18n.t('dateRegistrationMin', "Date of Registration can't be in the future"),
        test: (value) => !isFuture(new Date(value)),
      })
      .test({
        name: 'invalidDateFormat',
        message: INVALID_DATE_FORMAT_MESSAGE,
        test: (value) => value && isDateStringValid(value),
      }),
  })
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(AdditionalDetailsInputSchema)
  .concat(OwnerShipPercent1InputSchema)

export type DomLegalEntityInput = yup.Asserts<typeof DomLegalEntityValidationSchema>
