import React, { FC } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Create } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontSize: '14px',
  },
}))

export const AccountCardHeader: FC<{ text: string }> = ({ text }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Box fontWeight="fontWeightBold" className={classes.title}>
          {text}
        </Box>
      </Grid>
      <Grid item>
        <Box fontWeight="fontWeightBold" className={classes.title}>
          <Create fontSize={'inherit'} />
        </Box>
      </Grid>
    </Grid>
  )
}
