import React from 'react'
import { generatePath, Redirect } from 'react-router-dom'

import { useGetUserDataQuery, Individual } from '../../graphql'
import { CurrentUserContext } from '../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../paths'
import { SplashScreen } from '../../components'

export const AuthGuard: React.FC = ({ children }) => {
  const { data, loading, error } = useGetUserDataQuery()

  if (loading) return <SplashScreen />

  // eslint-disable-next-line no-underscore-dangle
  if (error || data?.viewer?.__typename !== 'Individual') {
    return <Redirect to={APP_PATHS.auth.login} />
  }

  return (
    <CurrentUserContext.Provider value={data.viewer as Individual}>
      {!data.viewer.emailConfirmed && data.viewer.email && (
        <Redirect
          to={generatePath(APP_PATHS.confirmEmail, {
            [PATH_PARAMS.token]: encodeURIComponent(data.viewer.email),
          })}
        />
      )}
      {children}
    </CurrentUserContext.Provider>
  )
}

export default AuthGuard
