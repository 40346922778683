import * as yup from 'yup'
import {
  PRE_ASSESSMENT_SUM_OF_PERCENTS0,
  PRE_ASSESSMENT_SUM_OF_PERCENTS100,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import { isEmpty } from 'lodash'
import { ExpectedVolumesErrorTypes } from '../../types'

export const ExpectedVolumesInputSchema = yup.object().shape({
  expectedVolumes: yup
    .mixed()
    .notRequired()
    .test(
      ExpectedVolumesErrorTypes.expectedVolumes100,
      PRE_ASSESSMENT_SUM_OF_PERCENTS100,
      function (value) {
        const sumVolume = (value as Record<string, string>[])?.reduce(
          (acc, field) => acc + Number(field.expectedVolume),
          0,
        )
        return sumVolume <= 100
      },
    )
    .test(
      ExpectedVolumesErrorTypes.expectedVolumes0,
      PRE_ASSESSMENT_SUM_OF_PERCENTS0,
      function (value) {
        const sumVolume = (value as Record<string, string>[])?.reduce(
          (acc, field) => acc + Number(field.expectedVolume),
          0,
        )
        return sumVolume > 0
      },
    )
    .test(
      ExpectedVolumesErrorTypes.volumeIsNotEmptyCurrency,
      THIS_FIELD_IS_REQUIRED_MESSAGE,
      function (value) {
        let valid = true
        ;(value as Record<string, string>[]).forEach((itemValue) => {
          if (isEmpty(itemValue.currency)) valid = false
        })
        return valid
      },
    )
    .test(
      ExpectedVolumesErrorTypes.volumeIsNotEmptyExpectedVolume,
      THIS_FIELD_IS_REQUIRED_MESSAGE,
      function (value) {
        let valid = true
        ;(value as Record<string, string>[]).forEach((itemValue) => {
          if (isEmpty(itemValue.expectedVolume)) {
            valid = false
          }
        })
        return valid
      },
    ),
})
