import React, { FC } from 'react'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ContainerWithImage } from '../Common'
import { useHistory } from 'react-router'
import { APP_PATHS } from '../../routes/paths'
import Box from '@material-ui/core/Box'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  wrapp: { height: '100%' },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '60px 200px 48px 200px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 67px 32px 67px',
    },
  },
  button: {
    borderColor: '#000',
    color: '#000',
    fontSize: '0.875rem',
    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.2',
    padding: '0.25rem 0.5rem',
    minWidth: 136,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 4),
    marginBottom: 40,
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    fontSize: 56,
    lineHeight: '56px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  errContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  errTitle: {
    marginBottom: 16,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
  },
  errText: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
  },
  footer: {
    fontSize: '12px',
    marginTop: 'calc(50% + 60px)',
  },
  footer_company: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export const ErrorPage: FC<{ code?: string; text?: string; description?: string | null }> = ({
  code = '',
  text = '',
  description = '',
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <ContainerWithImage
        image={config.images.error}
        reverse={true}
        classWrapper={classes.wrapp}
        isRightMenu={false}
      >
        <Grid item xs={10} lg={6} className={classes.errContainer}>
          <Typography variant={'h2'} className={classes.header}>
            {code} {t('error', 'Error')}
          </Typography>

          <Typography variant={'h2'} className={classes.errTitle}>
            {t(text)}
          </Typography>

          <Typography variant={'h2'} className={classes.errText}>
            {description ? t(description) : ''}
          </Typography>

          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="outlined"
              size="large"
              disabled={false}
              className={classes.button}
              onClick={() => history.push(APP_PATHS.root)}
            >
              {t('homepage', 'Homepage')}
            </Button>
          </Box>
        </Grid>
      </ContainerWithImage>
    </>
  )
}
