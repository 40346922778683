import React, { FC } from 'react'
import { getUserSignature, SignatureProps } from '../../AplicationActions/contract'
import { useCurrentUser } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { ActionSignatureStatus } from '../../../graphql'
import { Box } from '@material-ui/core'
import { AlertTipItem } from '../../../components'
// import { dateFormat } from '../../../utils'
import { ReactComponent as AttentionIcon } from '../../../assets/images/icons/icon-info2.svg'

//TODO change logic after Delayed execution appears on back

export const DelayedPaymentAlert: FC<{ signatures?: SignatureProps[] }> = ({ signatures }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const currentUser = useCurrentUser()
  if (!currentUser) {
    return null
  }
  const signature = getUserSignature(currentUser.id, signatures)
  if (!signature) {
    return null
  }
  switch (signature.status) {
    case ActionSignatureStatus.Reject:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'dateExecutionAlert',
              'The payment was scheduled but not signed, hence wasn’t executed. It will be executed after signing.',
            )}
            iconComponent={<AttentionIcon />}
            type={'warning'}
          />
        </Box>
      )
    case ActionSignatureStatus.Accept:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'signPaymentDateExecutionAlert',
              'The payment was scheduled but not signed, hence wasn’t executed. It will be executed after signing. The payment was scheduled but not signed, hence wasn’t executed. It will be executed after signing.',
            )}
            iconComponent={<AttentionIcon />}
            type={'warning'}
          />
        </Box>
      )
    default:
      return null
  }
}
