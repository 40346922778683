import React, { FC } from 'react'
import { Box, Container, Grid, Theme, makeStyles, SvgIcon } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ReactComponent as IconInfo } from '../../../assets/images/icons/icon-exclamation-mark.svg'

const useStyles = makeStyles((theme: Theme) => ({
  infoIcon: {
    color: theme.palette.warning.main,
  },
  container: {
    margin: '5px 0px 5px 0px',
  },
  tip: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontSize: '14px',
    marginTop: '2px',
  },
}))

export const TipItem: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Container component="div" maxWidth={false} disableGutters={true}>
      <Grid container className={classes.container}>
        <Grid item>
          <SvgIcon className={classes.infoIcon}>
            <IconInfo />
          </SvgIcon>
        </Grid>
        <Grid item>
          <Box className={classes.tip} ml={1}>
            {t('completeTheIntegrationBy', 'Complete the integration by')}{' '}
            <b>
              <u>{t('verifyingYourAccount', 'verifying your account')}</u>
            </b>{' '}
            {t(
              'andSelectingAPaymentFlowForYourBusiness',
              'and selecting a payment flow for your business',
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
