import * as yup from 'yup'
import { find, includes, isEmpty, some } from 'lodash'
import i18n from '../../i18n'

import { poiNeeded } from '../../graphql/local'
import { PoiDocTypesNotMandatory } from '../../utils/Data'
import { isPast, isToday } from 'date-fns'
import { INVALID_DATE_FORMAT_MESSAGE } from '../../constants/validations'
import { isDateStringValid } from '../../utils/dateUtils'
import { DocumentType } from '../../graphql'
import { makeDocTypeValidationMessage } from '../../utils'

export const DocumentTypeInputSchema = yup.object().shape({
  filesForUpload: yup.mixed().notRequired(),
  documentAlreadyUploaded: yup.mixed().notRequired(),
  documentType: yup
    .string()
    .test(
      'documentTypeCheck',
      i18n.t('pleaseSelectTheDocumentType', 'Please select the document type'),
      function () {
        return (
          !poiNeeded() ||
          find(this?.parent?.filesForUpload, (fileItem) =>
            includes(PoiDocTypesNotMandatory, fileItem?.docType),
          ) ||
          !isEmpty(this.parent.documentType)
        )
      },
    )
    .test(
      'documentTypeAlreadyNotRequiredDocsAddedCheck',
      i18n.t(
        'IDFrontDrivingLicenseFrontPassportMandatory',
        'One file of "ID Front", "Driving License Front" or "Passport" is mandatory',
      ),
      function () {
        return !poiNeeded() || !isEmpty(this.parent.documentType)
      },
    ),
  expiryDate: yup
    .mixed()
    .test('expiryDate', i18n.t('thisFieldIsRequired', 'This field is required'), function () {
      return !!this.parent.expiryDate
    })
    .test({
      name: 'minDate',
      message: i18n.t('dateExpiryPast', "Expiry date can't be in the past"),
      test: (value) => {
        if (!!value) {
          return isToday(new Date(value)) || !isPast(new Date(value))
        }
        return true
      },
    })
    .test({
      name: 'invalidDateFormat',
      message: INVALID_DATE_FORMAT_MESSAGE,
      test: (value) => value && isDateStringValid(value),
    }),
  //for slow internet
  undefinedExpiryDate: yup
    .date()
    .test(
      'undefinedExpiryDate',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      function () {
        return (
          find(this?.parent?.filesForUpload, (fileItem) => fileItem?.docType === undefined) ||
          !!this.parent.expiryDate
        )
      },
    ),
  idBack: yup.mixed().test({
    name: 'idBack',
    message: (params) => makeDocTypeValidationMessage(params.path),
    test: function () {
      if (this.parent?.documentType !== DocumentType.Id) return true
      return some(
        this.parent.filesForUpload,
        (fileInfo) => !fileInfo.isFirstSide && !fileInfo.isRemoved,
      )
    },
  }),
  drivingLicenseBack: yup.mixed().test({
    name: 'drivingLicenseBack',
    message: (params) => makeDocTypeValidationMessage(params.path),
    test: function () {
      if (this.parent?.documentType !== DocumentType.DrivingLicense) return true
      return some(
        this.parent.filesForUpload,
        (fileInfo) => !fileInfo.isFirstSide && !fileInfo.isRemoved,
      )
    },
  }),
  poiNeededCheck: yup.string().test({
    name: 'poiNeeded',
    test: () => !poiNeeded(),
  }),
})
