import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Grid'
import { Check } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { debounce, filter, find, isEmpty, isEqual, orderBy, sortBy, toNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as DownIcon } from '../../../assets/images/icons/download.svg'
import { displayAccountStatusKeys, initialTransactionPageState } from '../../../constants'
import {
  Account,
  FundsType,
  Maybe,
  TransactionOrderBy,
  useGetContractAccountsQuery,
  useGetContractTransactionsCountQuery,
} from '../../../graphql'
import { trxPageStateVar } from '../../../graphql/local'
import { usePageFiltersSorting } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { currencyFormat, getCurrencySign, negativeCheck, sortAccountStatuses } from '../../../utils'
import { DownloadStatementModal } from '../../../pages/TransactionsPage/DownloadStatementModal'
import { accountStatusesForTransactionsShown } from '../../../utils/Data'

const useStyles = (isViban: boolean, isLa: boolean) =>
  makeStyles((theme) => ({
    popper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: '0px 0px 0px',
      position: 'absolute',
      width: 258,
      left: 0,
      top: '160px',
      zIndex: 300,
      height: 300,
      [theme.breakpoints.down('md')]: {
        width: '45%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    paper: {
      marginRight: 0,
      maxWidth: '100%',
      background: '#FFFFFF',
      borderRadius: 0,
      overflowX: 'hidden',
      boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
      [theme.breakpoints.down('sm')]: {
        width: '44%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    accSelector: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      padding: '4px 0px 4px 0',
      position: 'relative',
      background: '#FFFFFF',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        justifyContent: isViban ? 'end' : 'space-between',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: isViban ? 'end' : 'space-between',
        flexDirection: 'unset',
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    cardLabel: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 26px 16px 14px',
      width: '100%',
    },
    alias: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0 5px 0 0',
    },
    balance: {
      display: 'flex',
      alignItems: 'center',
      '&>div': {
        fontWeight: '700',
        '&:first-child': {
          padding: '0 5px 0 0',
        },
      },
    },
    accButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      margin: '5px 0',
      minWidth: '150px',
      width: 258,
      height: 48,
      padding: 0,
      minHeight: 32,
      background: 'none',
      border: '1px solid #c4c4c4',
      boxSizing: 'border-box',
      position: 'relative',
      fontSize: '16px',
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 5,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.open': {
        border: '1px solid #000000',
        backgroundColor: 'transparent',
        '& .MuiSvgIcon-root': {
          color: '#000000',
          transform: 'rotate(180deg)',
        },
      },
      '&.MuiButton-root.Mui-disabled': {
        color: '#000000',
      },
      [theme.breakpoints.down('md')]: {
        width: '45%',
        margin: '5px 0 5px 0',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '5px 0 5px 0',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '10px 0 10px 0',
      },
    },
    item: {
      '& .MuiTypography-displayBlock': {
        marginBottom: '0px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '0.5em',
        marginRight: theme.spacing(2),
      },
      '& .flag-icon-lg': {
        fontSize: '0.8em',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'start',
      padding: '0px 12px 0px 12px',
      position: 'static',
      width: '100%',
      height: 'auto',
      minHeight: '58px',
      left: '0px',
      top: '48px',
      background: '#FFFFFF',
      justifyContent: 'space-between',
    },
    listItemTextLabel: {
      width: '60%',
    },
    statusRow: {
      width: '30%',
      flex: '0 0 auto',
    },
    checkRow: {
      flex: '0 0 auto',
      width: '10%',
      paddingLeft: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
    },
    listItemCheck: {
      color: '#000000',
    },
    list: {
      boxShadow: '5px 5px 4px 0px rgba(34, 60, 80, 0.2)',
      backgroundColor: 'white',
    },
    arrowDropDown: {
      color: '#999999',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    btn: {
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        width: isLa ? '100%' : 'auto',
        marginTop: 0,
      },
      '& .MuiButton-root': {
        padding: 8,
        caretColor: 'transparent',
      },
      '& button': {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    selectAccount: {
      margin: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    buttonGroup: {
      marginLeft: theme.spacing(2),
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1.5),
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        gap: 'unset',
        justifyContent: 'space-between',
        margin: 0,
      },
    },
  }))()

const TransactionsAccountSelectComponent: FC<{
  setAccountId: React.Dispatch<React.SetStateAction<string | number | undefined>>
  accountId: string | number | undefined
  fromCardId?: string | undefined
  vbanId?: string | undefined
  isLimitedAcces?: boolean
  showDownloadButton?: boolean
  children?: React.ReactNode
  resetTab?: () => void
}> = ({
  setAccountId,
  accountId,
  fromCardId,
  vbanId,
  isLimitedAcces = true,
  showDownloadButton = true,
  children,
  resetTab,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([])
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [acc, setAcc] = useState<Account>()
  const [accAll, setAccAll] = useState<string>()
  const history = useHistory()
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const classes = useStyles(!!vbanId, isLimitedAcces)

  const { data: accountsData, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: accountStatusesForTransactionsShown,
      isVirtual: !!vbanId,
    },
  })

  const { setPageFilters } = usePageFiltersSorting(trxPageStateVar)

  const { data: allUserTransactionsCount } = useGetContractTransactionsCountQuery({
    variables: {
      contractId: applicationId,
    },
  })

  const onDownloadStatement = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleChange = useCallback(
    (id) => {
      setAccountId(id)
      !!id && localStorage.setItem('selectedAccountId', String(id))
      setOpen(false)
      const account = accountsData?.accounts?.find((account) => account?.id === id)
      setPageFilters({
        ...initialTransactionPageState,
        ...(account?.fundsType === FundsType.DepositGuaranteed
          ? { sortBy: TransactionOrderBy.CreatedAt, notStatus: 'pending' }
          : {}),
      })
      resetTab && resetTab()
      // for resetting page pagination to 1 when account changed
      history.location.state = undefined
    },
    [setAccountId, accountsData, resetTab],
  )

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleCloseList = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const sortByAccounts = useMemo(() => {
    const routeCheck = history.location.pathname.includes('standing-orders')
    const filteredAccs = routeCheck
      ? filter(
          accountsData?.accounts,
          (acc) => acc?.fundsType !== FundsType.DepositGuaranteed && !acc?.qrrSupported,
        )
      : accountsData?.accounts

    const orderByAccounts = orderBy(filteredAccs, ['balance.available'], ['desc']) as Account[]
    return sortBy(orderByAccounts, sortAccountStatuses) as Account[]
  }, [accountsData, history.location.pathname])

  const accountSelectList = useMemo(
    () =>
      filteredAccounts.map((account) => {
        const { id, alias, currency, balance, status } = account as Account
        const normalizedBalance = `${getCurrencySign(currency as string)} ${currencyFormat(
          balance?.available as number,
        )}`

        return (
          <ListItem key={id} button className={classes.item} onClick={() => handleChange(id)}>
            <ListItemText className={classes.listItemTextLabel}>
              <Box>
                {alias} &nbsp;
                <Box component="span" className={clsx(`roundedPill ${status as string}`)}>
                  {t(displayAccountStatusKeys[status as string])}
                </Box>
              </Box>
              <Box>
                <b className={negativeCheck(balance?.available)}>{normalizedBalance}</b>
              </Box>
            </ListItemText>
            <Box className={classes.checkRow}>
              {accountId === id && <Check className={classes.listItemCheck} fontSize={'small'} />}
            </Box>
          </ListItem>
        )
      }),
    [filteredAccounts],
  )

  const handleAccountSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const insertedValue = e.target.value.trim().toLowerCase()

    const filteredAccounts = sortByAccounts.filter(({ alias }) =>
      alias?.toLowerCase()?.includes(insertedValue),
    )

    if (filteredAccounts.length === 1) {
      handleChange(filteredAccounts[0].id)
    }

    setFilteredAccounts(filteredAccounts)
  }, 100)

  useEffect(() => {
    if (!!accountId && !open) {
      const foundAcc = find(accountsData?.accounts, (accItem) => accItem?.id === accountId)
      const maxBalance = accountsData?.accounts?.reduce(
        (acc: Maybe<Account>, curr) =>
          toNumber(acc?.balance?.available) > toNumber(curr?.balance?.available) ? acc : curr,
        null,
      )
      if (!isEmpty(accountsData?.accounts) && (!!foundAcc || !!maxBalance)) {
        const selectedAccount = !!foundAcc ? foundAcc : (maxBalance as Account)
        setAcc(selectedAccount)
        setAccAll(accountId === '#allAccounts' ? 'allAccounts' : '')
        const accountIdForSet = String(
          accountId === '#allAccounts' ? '#allAccounts' : selectedAccount?.id,
        )
        localStorage.setItem('selectedAccountId', accountIdForSet)
        setAccountId(accountIdForSet)
      }
    }
  }, [accountsData, accountId])

  useEffect(() => {
    const localAccount = localStorage.getItem('selectedAccountId')
    !fromCardId &&
      !vbanId &&
      localAccount &&
      setAccountId(localAccount === 'undefined' ? '#allAccounts' : localAccount)
  }, [fromCardId, vbanId, setAccountId])

  useEffect(() => {
    if (sortByAccounts) {
      setFilteredAccounts(sortByAccounts)
    }
  }, [sortByAccounts])

  return (
    <>
      <Box item className={classes.container}>
        <Grid item className={classes.selectAccount}>
          <Box className={classes.accSelector}>
            {!vbanId && (
              <>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className={`${classes.accButton} ${open ? 'open' : ''}`}
                  disabled={accountsData?.accounts?.length === 1}
                >
                  <Typography component="div" className={classes.cardLabel}>
                    {accAll === 'allAccounts' ? (
                      <Typography
                        component="div"
                        className={classes.alias}
                        data-test="accNameInFilter"
                      >
                        {t(`${accAll}`)}
                      </Typography>
                    ) : acc ? (
                      <>
                        <Typography
                          component="div"
                          className={classes.alias}
                          data-test="accNameInFilter"
                        >
                          {acc?.alias}
                        </Typography>
                        <Typography
                          component="div"
                          className={`${classes.balance} ${negativeCheck(acc?.balance?.available)}`}
                        >
                          <Typography component="div">
                            {getCurrencySign(acc?.currency as string)}
                          </Typography>
                          <Typography component="div">
                            {currencyFormat(acc?.balance?.available as number)}
                          </Typography>
                        </Typography>
                      </>
                    ) : (
                      <Typography component="div">{t('allAccounts', 'All accounts')}</Typography>
                    )}
                  </Typography>
                  {!filteredAccounts.length ? null : open ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ExpandMoreIcon className={classes.arrowDropDown} />
                  )}
                </Button>

                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  // role={undefined}
                  transition
                  disablePortal
                  className={classes.popper}
                  placement="bottom-start"
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top',
                      }}
                    >
                      <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleCloseList}>
                          <List id="menu-list-grow" className={classes.list}>
                            <ListItem>
                              <TextField
                                onChange={handleAccountSearch}
                                label={t('selectAccount', 'Select Account')}
                                variant={'standard'}
                                fullWidth={true}
                              />
                            </ListItem>
                            <ListItem
                              key={'#all'}
                              button
                              className={classes.item}
                              onClick={() => handleChange('#allAccounts')}
                            >
                              <ListItemText>{t('allAccounts', 'All accounts')}</ListItemText>
                              <Box className={classes.checkRow}>
                                {accountId === '#allAccounts' && (
                                  <Check className={classes.listItemCheck} fontSize={'small'} />
                                )}
                              </Box>
                            </ListItem>
                            {!loading && accountSelectList}
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            <Box className={classes.buttonGroup}>
              {children}
              {showDownloadButton && (
                <Tooltip
                  title={`${t('statementIsNotAvailable')}`}
                  placement="bottom-end"
                  arrow
                  disableHoverListener={
                    (allUserTransactionsCount?.contractTransactionsCount ?? 0) > 0 &&
                    (accountsData?.accounts ?? []).length > 0
                  }
                  enterTouchDelay={50}
                >
                  <Grid item className={classes.btn}>
                    <Button
                      variant="contained"
                      disableElevation
                      color="default"
                      onClick={onDownloadStatement}
                      disabled={
                        !allUserTransactionsCount?.contractTransactionsCount ||
                        isEmpty(accountsData?.accounts)
                      }
                    >
                      <DownIcon />
                    </Button>
                  </Grid>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>

      <DownloadStatementModal isOpen={isOpen} handleClose={handleClose} accountId={accountId} />
    </>
  )
}

export const TransactionsAccountSelect = React.memo(TransactionsAccountSelectComponent, isEqual)
