import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'
import { find, isEmpty } from 'lodash'
import { Grid, Typography, Box, Button, FormControlLabel, RadioGroup } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useReactiveVar } from '@apollo/client'

import { Alerts, BeneficialOwnersTabs } from './Tabs'
import { activeForm, goBackPathVar, totalShare } from '../../graphql/local'
import { BlackRadio, Loader } from '../Common'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { LegalOwnerSelect } from '../Common/Selectors/LegalOwnersSelect'
import { personsNestedOwners, scrollToInputElement, transData } from '../../utils'
import {
  ContractStatusType,
  ContractType,
  ContractUbo,
  LegalEntity,
  UboStakeType,
  useGetContractDetailsQuery,
  useGetUbosListQuery,
} from '../../graphql'
import {
  AppActions,
  statusActions,
  RegistrationSteps,
  StepperTypes,
  BUSINESS_APP_STATUS_KEY,
} from '../../constants'
import { uboLegalEntityTypesList } from '../../utils/Data'
import { useStepper } from '../../hooks'
import { validateCP } from '../../utils'
import {
  ActionButtonType,
  CONTROLLING_PERSON_KEY,
  ControllingPersonsTypeEnum,
  CPAlerts,
  LegalList,
  ShowAlertType,
} from '../../types'
import { ControllingPersonsConfirmModal } from './ControllingPersonsConfirmModal'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  titleRadio: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
  description: {
    paddingLeft: theme.spacing(2),
  },
  radioGroup: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(4.5),
    '&:last-child': {
      marginBottom: theme.spacing(1.5),
    },
  },
  wrapControlBtn: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 32px 0',
    },
  },
  stepButton: {
    width: '430px',
    height: 48,
    margin: theme.spacing(3, 0, 3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(4, 0, 3),
    },
  },
  groupBtn: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  backBtn: {
    width: '104px',
    marginRight: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 0 24px 0',
    },
  },
  proceedBtn: {
    width: '302px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export function processLegalsList(
  owner: LegalEntity,
  ubos: ContractUbo[],
  setLegalsList: (
    value: ((prevState: Array<LegalList>) => Array<LegalList>) | Array<LegalList>,
  ) => void,
): void {
  if (!isEmpty(owner)) {
    const persons = ubos ?? []

    personsNestedOwners(persons as [ContractUbo]).then((result) => {
      const formatData = transData(result)
      setLegalsList([
        {
          key: `0`,
          label: (owner as LegalEntity).companyName,
          legalEntityType: (owner as LegalEntity).legalEntityType,
          ownersTotalShareSize:
            (ubos as ContractUbo[])?.reduce(
              (acc, val) =>
                acc +
                (val.ownershipType === UboStakeType.ManagingDirector ? 100 : val?.shareSize ?? 0),
              0,
            ) || 0,
        },
        ...formatData,
      ])
    })
  } else {
    setLegalsList([])
  }
}

export const ReviewOwners: FC = () => {
  const [radioValue, setRadioValue] = useState<ControllingPersonsTypeEnum>(
    ControllingPersonsTypeEnum.personOwner,
  )
  const [selectedUboId, setSelectedUboId] = useState('')
  const [directorAvailable, setDirectorAvailable] = useState(true)
  const [oneHundredPercentAdded, setOneHundredPercentAdded] = useState(false)
  const [isDomiciliary, setIsDomiciliary] = useState(false)
  const [canProceed, setCanProceed] = useState(true)
  const [alertData, setAlertData] = useState<ShowAlertType[]>([])
  const [alreadyWarned, setAlreadyWarned] = useState<string[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)

  // for LegalOwner Select component
  const [legalsList, setLegalsList] = useState<Array<LegalList>>([])

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.uboId]: uboId,
  } = useParams() as Record<string, string>
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const goBackPath = useReactiveVar(goBackPathVar)

  const { data, loading } = useGetUbosListQuery({
    variables: {
      id: +applicationId,
    },
  })

  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })
  const { setHeaderText, setCurrentStep } = useStepper(
    contractData?.contract?.type === ContractType.IntroducerReference
      ? StepperTypes.partnerRegistration
      : StepperTypes.registration,
  )

  const isContractStatusOnReview: boolean = useMemo(() => {
    return !!localStorage.getItem(BUSINESS_APP_STATUS_KEY(applicationId))
  }, [])

  useEffect(() => {
    if (history.action === 'PUSH' && isContractStatusOnReview && canProceed) {
      history.push(generatePath(APP_PATHS.application.review, { applicationId }))
    }
  }, [history, canProceed])

  useEffect(() => {
    setCurrentStep(RegistrationSteps.controllingPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t(
            contractData?.contract?.type === ContractType.IntroducerReference
              ? 'companyPartnerApplication'
              : 'companyBusinessApplication',
            {
              companyName: contractOwner.companyName,
            },
          ),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [applicationId, contractData, contractLoading, history, setCurrentStep, setHeaderText, t])

  const handleRadioChange = useCallback((event): void => {
    const name = (event?.target as HTMLInputElement).value as ControllingPersonsTypeEnum
    setRadioValue(name)
  }, [])

  const onAdd = useCallback(() => {
    const [alerts, canProcess, justWarning] = validateCP(
      legalsList,
      ActionButtonType.onAdd,
      selectedUboId,
    )
    if (!canProcess && !find(alreadyWarned, (o) => (o === selectedUboId ? selectedUboId : '0'))) {
      setAlertData(alerts)
      if (justWarning) {
        const tempArray: string[] = alreadyWarned
        tempArray.push(selectedUboId ? selectedUboId : '0')
        setAlreadyWarned(tempArray)
      }
      try {
        scrollToInputElement({ Alerts })
      } catch (e) {}
    } else {
      setAlertData([])
      activeForm(radioValue)
      selectedUboId && selectedUboId !== '0'
        ? history.push(
            generatePath(APP_PATHS.application.beneficialOwners.newUboOwner, {
              applicationId,
              uboId: selectedUboId,
            }),
          )
        : history.push(generatePath(APP_PATHS.application.beneficialOwners.new, { applicationId }))
    }
    localStorage.setItem(CONTROLLING_PERSON_KEY, radioValue)
  }, [legalsList, selectedUboId, alreadyWarned, radioValue, history, applicationId])

  const onProceed = useCallback(() => {
    const [alerts, canProcess] = validateCP(legalsList, ActionButtonType.onProceed)
    setCanProceed(canProcess)
    if (!canProcess) {
      if (alerts.find((alert) => alert.key === CPAlerts.OperatingCompanyPercents)) {
        setAlertData([])
        setCanProceed(true)
        setOpenModal(true)
      } else {
        setAlertData(alerts)
        try {
          scrollToInputElement({ Alerts })
        } catch (e) {}
      }
    } else {
      localStorage.setItem(BUSINESS_APP_STATUS_KEY(applicationId), 'review')
      setAlertData([])
      history.push(generatePath(APP_PATHS.application.review, { applicationId }))
    }
  }, [applicationId, history, legalsList, setCanProceed, setAlertData, setOpenModal])

  const handleBack = useCallback(() => {
    if (goBackPath) {
      history.push(goBackPath)
      goBackPathVar('')
    } else {
      const path =
        contractData?.contract?.type === ContractType.IntroducerReference
          ? APP_PATHS.application.edit
          : APP_PATHS.application.directors.list
      history.push(
        generatePath(path, {
          [PATH_PARAMS.applicationId]: +applicationId,
        }),
      )
    }
  }, [applicationId, goBackPath, history, contractData])

  useEffect(() => {
    if (isEmpty(selectedUboId) && !isEmpty(legalsList) && legalsList.length > 1) {
      setSelectedUboId('0')
    }
  }, [selectedUboId, legalsList, uboId])

  //TODO when query of all ubo & owners structure will be availiable refactor this component
  useEffect(() => {
    if (!isEmpty(data?.contract?.ubos) && uboId) {
      setSelectedUboId(uboId)
    }
    processLegalsList(
      data?.contract?.owner as LegalEntity,
      data?.contract?.ubos as ContractUbo[],
      setLegalsList,
    )
  }, [data?.contract?.ubos, data?.contract?.owner, uboId, setLegalsList, setSelectedUboId])

  useEffect(() => {
    const contractOwner = data?.contract?.owner as LegalEntity
    if (!loading && contractOwner) {
      setIsDomiciliary(contractOwner.legalEntityType === uboLegalEntityTypesList[2].key)
      setDirectorAvailable(
        !!find(legalsList, (item) => item.key === selectedUboId && item.ownersTotalShareSize === 0),
      )
      setOneHundredPercentAdded(
        !!find(
          legalsList,
          (item) => item.key === selectedUboId && item.ownersTotalShareSize === 100,
        ),
      )
      totalShare(
        find(legalsList, (item) => item.key === selectedUboId)?.ownersTotalShareSize as number,
      )
    }
  }, [data?.contract?.owner, legalsList, loading, selectedUboId])

  if (loading || contractLoading) return <Loader />

  return (
    <Box m={1}>
      <Grid container>
        <Grid item xs={12} lg={11}>
          <BeneficialOwnersTabs />
          <Alerts alertData={alertData} />
          <Box mt={6}>
            {!isEmpty(legalsList) && selectedUboId ? (
              <>
                <Typography variant={'h3'} className={classes.title}>
                  {t('addNewControllingPersonOf', 'Add a new controlling person of:')}
                </Typography>
                <LegalOwnerSelect
                  setUboId={setSelectedUboId}
                  uboId={selectedUboId}
                  dataPrepared={legalsList}
                />
              </>
            ) : (
              <Typography variant={'h3'} className={classes.title}>
                {t('addNewControllingPersonTo', 'Add a new controlling person to', {
                  companyName: (data?.contract?.owner as LegalEntity).companyName,
                })}
              </Typography>
            )}
          </Box>

          <Box mt={4}>
            <Typography variant={'h6'}>
              {t('selectOwnerType', 'Please add the controlling persons to {{company}}', {
                company: (data?.contract?.owner as LegalEntity).companyName,
              })}
              :
            </Typography>
          </Box>

          <Box mt={4}>
            <RadioGroup
              aria-label="gender"
              name="owner"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <Box className={classes.radioGroup} data-test="personMore25">
                <FormControlLabel
                  value={ControllingPersonsTypeEnum.personOwner}
                  control={<BlackRadio size="small" />}
                  label={
                    <Box>
                      <Typography variant={'h4'} className={classes.titleRadio}>
                        {isDomiciliary
                          ? t('domiciliaryPerson', 'Person who owns shares')
                          : t('personOwner', 'A person who owns 25% of the shares or more')}
                      </Typography>
                      <Typography variant={'body1'} className={classes.description}>
                        {t(
                          'requirePersonalDescription',
                          'We’ll require personal details and the percentage of ownership (capital shares or voting rights)',
                        )}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
              <Box className={classes.radioGroup} data-test="LegalEntity">
                <FormControlLabel
                  value={ControllingPersonsTypeEnum.legalOwner}
                  control={<BlackRadio size="small" />}
                  label={
                    <Box>
                      <Typography variant={'h4'} className={classes.titleRadio}>
                        {isDomiciliary
                          ? t('domiciliaryLegal', 'Legal entity which owns shares')
                          : t('legalOwner', 'A Legal entity that owns 25% of the shares or more')}
                      </Typography>
                      <Typography variant={'body1'} className={classes.description}>
                        {t(
                          'requireEntityDescription',
                          'We’ll require the entity details and the percentage of ownership',
                        )}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
              <Box className={classes.radioGroup} data-test="personMoreThroughTrust">
                <FormControlLabel
                  value={ControllingPersonsTypeEnum.attorneyPower}
                  control={<BlackRadio size="small" />}
                  label={
                    <Box>
                      <Typography variant={'h4'} className={classes.titleRadio}>
                        {isDomiciliary
                          ? t(
                              'personAttorneyText',
                              'Person who holds shares through trust or power of attorney',
                            )
                          : t(
                              'attorneyPower',
                              'A person who holds more than 25% of the shares through trust or power of attorney',
                            )}
                      </Typography>
                      <Typography variant={'body1'} className={classes.description}>
                        {t(
                          'requirePercentageDescription',
                          'We’ll require details and the percentage of ownership',
                        )}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
              <Box
                className={classes.radioGroup}
                hidden={isDomiciliary || !directorAvailable}
                data-test="managingDirector"
              >
                <FormControlLabel
                  value={ControllingPersonsTypeEnum.managingDirector}
                  control={<BlackRadio size="small" />}
                  label={
                    <Box>
                      <Typography variant={'h4'} className={classes.titleRadio}>
                        {t('managingDirector', 'Managing director')}
                      </Typography>
                      <Typography variant={'body1'} className={classes.description}>
                        {t(
                          'directorDescription',
                          'In case no persons or companies own or hold more than 25% of the shares.',
                        )}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </RadioGroup>
          </Box>
          <Box className={classes.wrapControlBtn}>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={onAdd}
                className={classes.stepButton}
                disableElevation
                disabled={oneHundredPercentAdded}
                data-test="addControllingPerson-btn"
              >
                {t('addControllingPerson', 'Add Controlling person')}
              </Button>
            </Grid>
            <Grid className={classes.groupBtn}>
              <Button
                type="button"
                variant="contained"
                fullWidth
                disableElevation
                onClick={handleBack}
                className={classes.backBtn}
              >
                {t('goBack', 'Go back')}
              </Button>
              <Button
                disabled={!canProceed}
                type="button"
                variant="contained"
                fullWidth
                disableElevation
                onClick={onProceed}
                className={classes.proceedBtn}
              >
                {t('completeApplication', 'Complete application')}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ControllingPersonsConfirmModal isOpen={openModal} setOpen={setOpenModal} />
    </Box>
  )
}

export default ReviewOwners
