import React, { FC } from 'react'
import { Box, Grid, Theme, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { TipItem } from './Tips'
import config from '../../config'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.warning.main,
    padding: '23px 22px 12px',
  },
}))

export const WelcomeAndTipsBar: FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6">
            {t('welcomeToKlarpay', 'Welcome to {{val1}}', {
              val1: config.name,
            })}
            , {name}!
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign={'right'}>
            <Close fontSize={'inherit'} />
          </Box>
        </Grid>
      </Grid>
      <TipItem />
    </div>
  )
}
