import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useFormContext, useWatch, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { get, isEmpty, take } from 'lodash'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { GridRow, CommonTipItem } from '../../Common'
import { ReactComponent as IconInfo } from '../../../assets/images/icons/icon-info2.svg'
import { KeyAndLabelSelectType, Partner, PartnersInformationEnum } from '../../../types'
import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { NewPartnerModal } from './NewPartnerModal'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import ControlledIconTooltip from '../../Common/Tooltips/ControlledIconTooltip'
import { ContractProfileChangeAnswers, Maybe, PreAssessmentPartner } from '../../../graphql'
import { makeTooltipTitleTextPartnersInformation } from './helpers/functions'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const EMPTY_PARTNER: Partner = {
  name: '',
  country: '',
  bankCountry: '',
  businessSector: '',
  paymentPurpose: [],
  website: '',
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiFormControl-root': {
      top: -3,
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textField: {
    flexBasis: '47%',
    '& .MuiInputBase-input': {
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  addButton: {
    width: 200,
    margin: theme.spacing(1, 0, 1.5, 0),
  },
  hidden: {
    display: 'none',
  },
  select: {
    marginBottom: '29px !important',
  },
  secondRow: {
    marginLeft: '25px !important',
    marginBottom: '20px !important',
  },
  table: {
    borderTop: '0',
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  errorWrap: {
    position: 'relative',
    height: theme.spacing(3),
  },
  error: {
    top: 0,
    color: '#EF2828',
    position: 'absolute',
    marginBottom: theme.spacing(1.5),
  },
  checkboxWrap: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1.5, 1.5),
    },
    marginLeft: 0,
    marginRight: 0,
  },
  helpIcon: {
    display: 'flex',
    marginLeft: 6,
    '& .MuiSvgIcon-root': {
      width: 19,
      height: 19,
    },
    cursor: 'pointer',
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 22,
  },
  lightDivider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255,.3)',
    height: 3,
  },
  tooltipKey: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

export const PartnersInformation: FC<{
  type: PartnersInformationEnum
  paymentPurposeData: KeyAndLabelSelectType[]
  readOnly: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ type, paymentPurposeData, readOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const [addingNewPartner, setAddingNewPartner] = useState(false)
  const [defaultPartner, setDefaultPartner] = useState<Partner | undefined>()
  const [editingPartner, setEditingPartner] = useState<
    { i: number; partner: Partner } | undefined
  >()

  const methods = useFormContext()
  const { control, errors, setValue, trigger, getValues, reset, formState } = methods

  const companyName = getValues('companyName')
  const country = getValues('country')
  const businessSector = getValues('businessSector')
  const website = getValues('urls') || getValues('businessWebsiteUrl')

  const isNoPartnersChecked = getValues(`noPartners${type}Required`)

  const { fields, append, remove } = useFieldArray({
    control,
    name: `partners${type}`,
  })
  const partners: Partner[] | undefined = useWatch({
    control,
    name: `partners${type}`,
  })
  const error = get(errors, `partners${type}`)

  const showTable =
    (!isEmpty(partners) && partners && partners?.length > 0 && !addingNewPartner) ||
    (addingNewPartner && partners && partners?.length > 1)

  const handleOnlyOnePartnerRequired = useCallback(
    (_, checked) => {
      setValue(`noPartners${type}Required`, checked)
      if (checked) {
        const counterParty: Partner = {
          name: companyName,
          country: country,
          bankCountry: '',
          businessSector: businessSector,
          paymentPurpose: [],
          website: website,
        }
        append(counterParty)
        setDefaultPartner(counterParty)
        setAddingNewPartner(true)
      }
    },
    [
      companyName,
      country,
      businessSector,
      append,
      website,
      setAddingNewPartner,
      getValues,
      setDefaultPartner,
    ],
  )

  const handleClose = () => {
    addingNewPartner && remove(fields.length - 1)
    if (!editingPartner) {
      setValue(`noPartners${type}Required`, false)
    }
    setAddingNewPartner(false)
    setEditingPartner(undefined)
    setDefaultPartner(undefined)
    if (!!editingPartner) {
      setValue(`partners${type}[${editingPartner.i}].name`, editingPartner.partner.name)
      setValue(`partners${type}[${editingPartner.i}].country`, editingPartner.partner.country)
      setValue(
        `partners${type}[${editingPartner.i}].bankCountry`,
        editingPartner.partner.bankCountry,
      )
      setValue(
        `partners${type}[${editingPartner.i}].businessSector`,
        editingPartner.partner.businessSector,
      )
      setValue(
        `partners${type}[${editingPartner.i}].paymentPurpose`,
        editingPartner.partner.paymentPurpose,
      )
      setValue(`partners${type}[${editingPartner.i}].website`, editingPartner.partner.website)
      trigger(`partners${type}`).then()
    }
  }

  const handleRemove = (i: number) => {
    remove(i)
    reset({ ...getValues() })
    setValue(`noPartners${type}Required`, false)
    setDefaultPartner(undefined)
  }

  const completedPartners = take(
    partners,
    addingNewPartner ? Math.max((partners || []).length - 1, 0) : (partners || []).length,
  ).filter((v) => v)

  const partnerData: Record<
    PartnersInformationEnum,
    Array<Maybe<PreAssessmentPartner>> | null | undefined
  > = {
    Incoming: changedContractProfileData?.preAssessment?.partnersIncoming,
    Outgoing: changedContractProfileData?.preAssessment?.partnersOutgoing,
  }

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].name`, companyName)
    }
  }, [companyName])

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].country`, country)
    }
  }, [country])
  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].businessSector`, businessSector)
    }
  }, [businessSector])

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].website`, website)
    }
  }, [website])

  return (
    <FormProvider {...methods}>
      {showTable && (
        <GridRow>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name', 'Name')}</TableCell>
                  <TableCell width={168}>{t('actions', 'Actions')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {completedPartners.map((value, i) => {
                  const rowError = (error || [])[i]
                  return (
                    <TableRow hover key={`${i}${Object.keys(value).toString()}`}>
                      <TableCell>
                        <Typography>{value.name} </Typography>
                        {rowError && (
                          <Typography variant="caption">
                            <CommonTipItem
                              value={t('hasError', 'Input has errors, click edit to fix')}
                              iconComponent={<IconInfo />}
                            />
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {!readOnly && (
                          <Box className={classes.buttonsBox}>
                            <Button
                              type="button"
                              onClick={() => setEditingPartner({ i, partner: value })}
                            >
                              {t('edit', 'Edit')}
                            </Button>
                            <Button
                              color="secondary"
                              aria-label="delete"
                              type="button"
                              onClick={() => handleRemove(i)}
                            >
                              {t('delete', 'Delete')}
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </GridRow>
      )}
      {!readOnly && (
        <GridRow>
          {partnerData[type] && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.partnersIncoming}
              title={makeTooltipTitleTextPartnersInformation(
                partnerData[type] as PreAssessmentPartner[],
                paymentPurposeData,
                isNoPartnersChecked,
                {
                  tooltipKey: classes.tooltipKey,
                  listItem: classes.listItem,
                  divider: classes.lightDivider,
                },
              )}
              arrowHorizontalPosition={'left'}
            />
          )}
          <Button
            name={
              type === PartnersInformationEnum.Incoming ? 'partnersIncoming' : 'partnersOutgoing'
            }
            type="button"
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              append(EMPTY_PARTNER)
              setAddingNewPartner(true)
            }}
            className={classes.addButton}
            disabled={isNoPartnersChecked}
          >
            {t('addPartner', 'Add Counterparty')}
          </Button>
          {completedPartners.length <= 1 && (
            <>
              <Box className={classes.checkboxWrap}>
                <Controller
                  name={`noPartners${type}Required`}
                  control={control}
                  render={() => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={isNoPartnersChecked}
                          name={`noPartners${type}Required`}
                          checked={isNoPartnersChecked}
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          disabled={completedPartners.length === 1}
                        />
                      }
                      onChange={handleOnlyOnePartnerRequired}
                      label={t('noCounterparties', 'No third-party account counterparties')}
                      className={classes.checkbox}
                    />
                  )}
                />

                <Box className={classes.helpIcon}>
                  <ControlledIconTooltip
                    Icon={HelpOutlineIcon}
                    placement={'top'}
                    title={
                      <Typography>
                        {type === PartnersInformationEnum.Incoming
                          ? t(
                              'noIncomingFundsFromThirdParties',
                              "Select this checkbox if you do not anticipate any incoming payments from third parties, i.e. fund transfers will only be from other bank accounts in the Company's name",
                            )
                          : t(
                              'noOutgoingFundsFromThirdParties',
                              "Select this checkbox if you do not anticipate making outgoing payments to third parties, i.e. fund transfers will only be to other bank accounts in the Company's name.",
                            )}
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </GridRow>
      )}
      {!!error && (
        <GridRow data-test="partnersErr">
          <Box className={classes.errorWrap}>
            <FormHelperText className={classes.error}>{error.message}</FormHelperText>
          </Box>
        </GridRow>
      )}
      {(addingNewPartner || !!editingPartner) && (
        <NewPartnerModal
          name={`partners${type}[${
            !!editingPartner ? editingPartner.i : Math.max(fields.length - 1, 0)
          }]`}
          onClose={handleClose}
          setEditingPartner={setEditingPartner}
          paymentPurposeData={paymentPurposeData}
          defaultPartner={!!editingPartner ? editingPartner.partner : defaultPartner}
          onlyOnePartnerRequired={isNoPartnersChecked}
          setAddingNewPartner={setAddingNewPartner}
          type={type}
        />
      )}
    </FormProvider>
  )
}

export default PartnersInformation
