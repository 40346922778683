import * as yup from 'yup'

import {
  EmailInputSchema,
  NameInputSchema,
  PhoneNumberInputSchema,
  CountryDomicileInputSchema,
  StreetAddressInputSchema,
  NationalityInputSchema,
  BirthdayInputSchema,
} from './common'

export const PersonalDetailsInputSchema = StreetAddressInputSchema.concat(NameInputSchema)
  .concat(CountryDomicileInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(PhoneNumberInputSchema)
  .concat(EmailInputSchema)

export type PersonalDetailsInput = yup.Asserts<typeof PersonalDetailsInputSchema>
