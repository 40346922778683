import React, { FC } from 'react'

import { ReactComponent as PdfIcon } from '../../assets/images/icons/pdf.svg'
import { ReactComponent as PngIcon } from '../../assets/images/icons/png.svg'
import { ReactComponent as JpgIcon } from '../../assets/images/icons/jpg.svg'
import { ReactComponent as CssIcon } from '../../assets/images/icons/css.svg'
import { ReactComponent as TxtIcon } from '../../assets/images/icons/txt.svg'
import { ReactComponent as DocIcon } from '../../assets/images/icons/doc.svg'
import { ReactComponent as XlsIcon } from '../../assets/images/icons/xls.svg'

export const FileIcon: FC<{ mimeType: string | undefined }> = ({ mimeType }) => {
  switch (mimeType) {
    case 'application/pdf':
    case 'pdf':
      return <PdfIcon width={'100%'} height={'100%'} />
    case 'image/png':
    case 'png':
      return <PngIcon width={'100%'} height={'100%'} />
    case 'image/jpeg':
    case 'jpeg':
    case 'jpg':
      return <JpgIcon width={'100%'} height={'100%'} />
    case 'css':
      return <CssIcon width={'100%'} height={'100%'} />
    case 'txt':
      return <TxtIcon width={'100%'} height={'100%'} />
    case 'doc':
    case 'docx':
      return <DocIcon width={'100%'} height={'100%'} />
    case 'xls':
    case 'xlsx':
      return <XlsIcon width={'100%'} height={'100%'} />
    default:
      return null
  }
}
