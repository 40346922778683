import { useState } from 'react'
import { TableSortPagesEnum, TableSortType } from '../types'

const STORAGE_KEY = 'tableSort'

export const useTableSort = (page?: TableSortPagesEnum) => {
  const getTableSort = (): TableSortType => {
    const savedValue = localStorage.getItem(STORAGE_KEY)
    const savedTableSort: TableSortType | null = savedValue !== null ? JSON.parse(savedValue) : null
    return {
      isActionsSorted:
        typeof savedTableSort?.isActionsSorted === 'boolean'
          ? savedTableSort.isActionsSorted
          : true,
      isTransactionsSorted:
        typeof savedTableSort?.isTransactionsSorted === 'boolean'
          ? savedTableSort?.isTransactionsSorted
          : true,
      isClientsSorted:
        typeof savedTableSort?.isClientsSorted === 'boolean'
          ? savedTableSort?.isClientsSorted
          : true,
    }
  }

  const getInitialTableSort = (): boolean => {
    switch (page) {
      case TableSortPagesEnum.actions:
        return getTableSort().isActionsSorted
      case TableSortPagesEnum.transactions:
        return getTableSort().isTransactionsSorted
      case TableSortPagesEnum.clients:
        return getTableSort().isClientsSorted
      default:
        return true
    }
  }

  const saveTableSort = (value: boolean): void => {
    const tableSort = getTableSort()
    switch (page) {
      case TableSortPagesEnum.actions:
        tableSort.isActionsSorted = value
        setIsTableSort(value)
        break
      case TableSortPagesEnum.transactions:
        tableSort.isTransactionsSorted = value
        setIsTableSort(value)
        break
      case TableSortPagesEnum.clients:
        tableSort.isClientsSorted = value
        setIsTableSort(value)
        break
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(tableSort))
  }
  const [isTableSort, setIsTableSort] = useState<boolean>(getInitialTableSort())

  const clearTablesSort = () => {
    localStorage.removeItem(STORAGE_KEY)
  }

  return { isTableSort, saveTableSort, clearTablesSort }
}
