import * as yup from 'yup'

import i18n from '../../i18n'
import { PASSWORD_CARD_REQUIREMENT_REGEXP } from '../../components/SignUp/Validations'

export const CardPasswordInputSchema = yup.object().shape({
  password: yup
    .string()
    .required(i18n.t('passwordIsMandatory', 'Password is mandatory'))
    .min(8, i18n.t('passwordIsTooShort', 'Password is too short, minimum 8 characters required'))
    .matches(
      PASSWORD_CARD_REQUIREMENT_REGEXP,
      i18n.t(
        'cardPasswordAllowedCharacters',
        `Password contains unsupported characters, only the special characters "! #  ? * ( ) + = / & , . [ ] { } ;"  are allowed`,
      ),
    ),
})

export type CardPasswordInput = yup.Asserts<typeof CardPasswordInputSchema>
