import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useMemo } from 'react'
import { Box, SvgIcon, Typography } from '@material-ui/core'
import { head, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AccountStatus, FundsType, useGetContractAccountsQuery } from '../../graphql'
import { useIsLimitedContract } from '../../hooks'
import { PATH_PARAMS } from '../../routes/paths'
import { ButtonWithTooltip } from '../Common'
import { useHandleNewPayments, useIsContractInactive } from './hooks'
import { ReactComponent as BatchIcon } from '../../assets/images/icons/upload_up.svg'

const useStyles = makeStyles(() => ({
  icon: {
    transform: 'translate(0, 15%)',
  },
}))

export const PaymentSection: FC<{ limitMode: boolean }> = ({ limitMode }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: accountsData, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
  })

  const isActiveAccounts = useMemo(() => !isEmpty(accountsData?.accounts), [accountsData])

  const isOnlyOneDepositGuaranteed =
    accountsData?.accounts?.length === 1 &&
    head(accountsData?.accounts)?.fundsType === FundsType.DepositGuaranteed

  const contractInactive = useIsContractInactive()

  const { isLimitedContract } = useIsLimitedContract()
  const [handleNewPayment, handleBatchPayment] = useHandleNewPayments(isActiveAccounts)

  const isDisabled = loading || limitMode || !isActiveAccounts || isLimitedContract

  return (
    <Box margin={2} display="flex" gridGap={16} flexDirection="column">
      <ButtonWithTooltip
        onClick={handleNewPayment}
        disabledOperationName="New payment"
        disabled={isDisabled || isOnlyOneDepositGuaranteed}
        variant="contained"
        color="primary"
        hiddeTooltip={isOnlyOneDepositGuaranteed}
        fullWidth
      >
        {t('newPayment', 'New payment')}
      </ButtonWithTooltip>

      <ButtonWithTooltip
        onClick={handleBatchPayment}
        disabledOperationName="Batch payment"
        disabled={contractInactive || isDisabled}
        variant="outlined"
        fullWidth
      >
        <Box display="flex" alignItems="center">
          <SvgIcon className={classes.icon}>
            <BatchIcon style={{ fill: isDisabled ? 'grey' : 'black' }} />
          </SvgIcon>
          <Typography variant="h4">{t('batchPaymentFile', 'Batch payment file')}</Typography>
        </Box>
      </ButtonWithTooltip>
    </Box>
  )
}
