import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IInputProps } from 'react-dropzone-uploader'

import { Box, Hidden, makeStyles, Theme } from '@material-ui/core'

import { ReactComponent as UploadIcon } from '../../../assets/images/icons/upload.svg'

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneInner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',

    '& input': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      opacity: 0,
      '&:hover': {
        backgroundColor: 'red',
        boxShadow: 'inset -1px -3px 0px -1px #000',
        cursor: 'pointer',
      },
    },
  },
  dropzoneInnerText: {
    marginTop: theme.spacing(1.5),
    fontSize: '0.75rem',
  },
}))

export const FileUploadInput = ({
  accept,
  onFiles,
  getFilesFromEvent,
}: IInputProps): ReactElement<IInputProps> => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.dropzoneInner}>
      <input
        type="file"
        accept={accept}
        multiple={false}
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles)
          })
        }}
      />
      <UploadIcon />
      <Hidden smDown>
        <Box className={classes.dropzoneInnerText}>
          <strong>{t('dragNDrop', `Drag'n drop`)}</strong> {t('or', 'or')}{' '}
          <strong>{t('click', 'click')}</strong> {t('toUpload', 'To Upload')} {t('files', 'files')}
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.dropzoneInnerText}>
          <strong>{t('tap', 'Tap')}</strong> {t('toUploadFiles', ' to upload files')}
        </Box>
      </Hidden>
    </Box>
  )
}
