import React, { FC } from 'react'
import Button, { ButtonTypeMap } from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { convertingTimerSecondsToMinutes } from '../../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  progress: {
    marginRight: theme.spacing(2.5),
    color: '#888',
  },
  spinner: {
    color: '#aaa',
    marginTop: theme.spacing(1),
  },
  time: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

type ResendButtonProps = {
  disabled: boolean
  disableElevation?: boolean
  fullWidth: boolean
  className?: string | undefined
  children?: React.ReactNode
  type?: 'button' | 'reset' | 'submit' | undefined
  variant?: 'text' | 'outlined' | 'contained' | undefined
  size?: 'small' | 'medium' | 'large' | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined
  progress?: number | undefined
  onClick?: () => void
  secondsLeft: number | undefined
  linkSent?: boolean
}

// IntrinsicAttributes

export const ResendButton: FC<Partial<ResendButtonProps & ButtonTypeMap>> = ({
  disabled,
  className,
  children,
  type = 'submit',
  variant = 'contained',
  size = 'large',
  color = 'primary',
  progress = 0,
  fullWidth = true,
  disableElevation = false,
  linkSent = true,
  onClick,
  secondsLeft,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Button
      type={type}
      className={`${classes.root} ${className}`}
      size={size}
      variant={variant}
      color={color}
      disabled={disabled}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      onClick={onClick}
      // endIcon={<ArrowForward />}
      {...rest}
    >
      {disabled && secondsLeft === undefined && (
        <Box
          className={classes.progress}
          sx={{
            position: 'relative',
          }}
        >
          <CircularProgress
            className={classes.spinner}
            key="1"
            value={progress}
            variant="determinate"
            size={32}
          />
          <Box
            key="2"
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '10px',
            }}
          >
            <Typography variant="caption" component="div">
              {`${100 - progress}s`}
            </Typography>
          </Box>
        </Box>
      )}
      {children}
      {disabled && !!secondsLeft && linkSent && (
        <Box className={classes.time}>{convertingTimerSecondsToMinutes(secondsLeft)}</Box>
      )}
    </Button>
  )
}
