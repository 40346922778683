import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { toNumber } from 'lodash'
import { Box, Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { currencyFormat, dateFormat, trxStatusNormalize } from '../../../utils'
import { TransferCardProps } from '../TrxCardPayDetails'

export const TrxCardTab: FC<{
  transfer: TransferCardProps
  handleBack?: () => void
}> = ({ transfer }) => {
  const { txData } = transfer

  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('transactionStatus', 'Transaction status')}
                </Box>
              </TableCell>
              <TableCell>
                {txData?.status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(txData?.status)}`)}
                  >
                    {t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('referenceNumber', 'Reference number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.referenceNumber}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('amount', 'Amount')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('account', 'Account')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.accountName}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('card', 'Card')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {!!txData?.cardNumber
                    ? `${'*' + txData?.cardNumber.slice(-4) + ' (' + txData?.cardName + ')'}`
                    : 'N/A'}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('cardholder', 'Cardholder')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.cardholder || 'N/A'}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('vendor')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.vendor}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('address', 'Address')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {txData?.address
                    ? `${
                        txData.address.line1 +
                        ',' +
                        txData.address.zip +
                        ' ' +
                        txData.address.city +
                        ' ' +
                        txData.address.countryDescription
                      }`
                    : 'N/A'}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('sent', 'Sent')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{dateFormat(txData?.sent)}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
              <Box className={classes.mobileTableName}>
                {txData?.status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(txData?.status)}`)}
                  >
                    {t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('referenceNumber', 'Reference number')}
              </Box>
              <Box className={classes.mobileTableName}>{txData?.referenceNumber}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
              <Box className={classes.mobileTableName}>
                {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('beneficiaryLabel', 'Beneficiary')}</Box>
              <Box>{txData?.beneficiary}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('account', 'Account')}</Box>
              <Box>{txData?.accountName}</Box>
            </Box>

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('card', 'Card')}</Box>
              <Box>
                {!!txData?.cardNumber
                  ? `${'*' + txData?.cardNumber.slice(-4) + ' (' + txData?.cardName + ')'}`
                  : 'N/A'}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('cardholder', 'Cardholder')}</Box>
              <Box>{txData?.cardholder || 'N/A'}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('vendor')}</Box>
              <Box>{txData?.vendor}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('address', 'Address')}</Box>
              <Box>
                {txData?.address
                  ? `${
                      txData.address.line1 +
                      ',' +
                      txData.address.zip +
                      ' ' +
                      txData.address.city +
                      ' ' +
                      txData.address.countryDescription
                    }`
                  : 'N/A'}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('sent', 'Sent')}</Box>
              <Box>{dateFormat(txData?.sent)}</Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
