import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { IconButton, InputAdornment } from '@material-ui/core'

import { FormInputFieldEntity } from '../../../types'
import { CommonTextField } from './CommonTextField'
import { ReactComponent as IconX } from '../../../assets/images/icons/icon-x.svg'

export const FormNewValueField: FC<FormInputFieldEntity> = ({
  name,
  label,
  placeholder,
  shouldValidateParam = true,
  ...rest
}) => {
  const { t } = useTranslation()
  const { errors, watch, setValue, clearErrors } = useFormContext()
  const error = errors ? errors[name] : null
  const currentValue = watch(name)

  const clearField = useCallback(
    () =>
      setValue(name, '', {
        shouldValidate: true,
        shouldDirty: true,
      }),
    [setValue],
  )

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target
      setValue(name, value, {
        shouldValidate: shouldValidateParam ?? true,
        shouldDirty: true,
      })
      !shouldValidateParam && clearErrors(name)
    },
    [setValue, clearErrors, shouldValidateParam, name],
  )

  return (
    <CommonTextField
      value={currentValue}
      type={'text'}
      label={label}
      placeholder={placeholder}
      error={!!error}
      helperText={error && t(error.message)}
      size="medium"
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle text" onClick={clearField}>
              {!isEmpty(currentValue) ? <IconX /> : ''}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}
