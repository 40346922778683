import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Table, TableBody, TableContainer, Typography } from '@material-ui/core'

import { Loader } from '../../../components'
import {
  Individual,
  IndividualEmailCategoryPreference,
  useGetEmailAndTwoFaCategoryPreferencesQuery,
} from '../../../graphql'
import { EmailPreferencesListRow } from './EmailPreferencesListRow'
import config from '../../../config'

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    borderTop: 'none',
  },
  caption: {
    marginTop: theme.spacing(4),
  },
}))

export const EmailPreferencesForm: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [sortedData, setSortedData] = useState<Array<IndividualEmailCategoryPreference>>([])
  const { data, loading } = useGetEmailAndTwoFaCategoryPreferencesQuery({})

  useEffect(() => {
    if (data?.viewer) {
      const emailCategoryPreferences = ((data?.viewer as Individual)?.emailCategoryPreferences ||
        []) as IndividualEmailCategoryPreference[]
      setSortedData(
        [...emailCategoryPreferences]?.sort((a, b) =>
          Number(a?.category?.id) > Number(b?.category?.id)
            ? 1
            : Number(a?.category?.id) < Number(b?.category?.id)
            ? -1
            : 0,
        ),
      )
    }
  }, [data?.viewer])

  if (loading || !data) return <Loader />

  return (
    <>
      <Typography className={classes.caption} paragraph={true}>
        {t(
          'pleaseSelectTheTypesOfEmails',
          'Please select the types of emails from {{val1}} that you prefer to receive.',
          {
            val1: config.name,
          },
        )}
      </Typography>
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {sortedData &&
              sortedData.map((value) => {
                return <EmailPreferencesListRow data={value} key={value.id} />
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
