import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { generatePath, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContactSupportLink, LogOutPrompt } from '../Common'
import { useMenuStyles } from './menuStyles'
import {
  Box,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { ReactComponent as PersonOutline } from '../../assets/images/icons/person_outline.svg'
import { ReactComponent as Settings } from '../../assets/images/icons/settings.svg'
import { ReactComponent as Logout } from '../../assets/images/icons/logout.svg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { ContractStatusType, Individual } from '../../graphql'
import { useIsLimitedContract, useLogOut } from '../../hooks'
import { useReactiveVar } from '@apollo/client'
import { isDirtyForm } from '../../graphql/local'
import { useTableSort } from '../../hooks/useCurrentTableSort'

const useStyles = makeStyles((theme: Theme) => ({
  profileMenuWrap: {
    zIndex: 1201,
  },
  profileMenu: {
    boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
    marginTop: '11px',
  },
  profileMenuItem: {
    padding: 0,
    color: '#000000',
    '& path': {
      fill: '#999999',
    },
    '&:hover': {
      '&.MuiListItem-button': {
        color: '#141414',
        '& path': {
          fill: '#595959',
        },
      },
    },
  },
  profileMenuLink: {
    display: 'inline-flex',
    alignItems: 'center',
    minHeight: '48px',
    color: 'inherit',
    textDecoration: 'none',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%',
    height: '100%',
    '& svg': {
      width: '20px',
    },
  },
  profileMenuText: {
    marginLeft: theme.spacing(2.5),
  },
  navNameMobile: {
    padding: 12,
    borderBottom: '1px solid black',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dropDownContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))

export const DashboardNavMenu: FC<{
  anchorEl: null | HTMLElement
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
  user: Individual
  contractId: number | string | undefined
  limitMode: boolean
}> = ({ anchorEl, setAnchorEl, user, contractId, limitMode }) => {
  const { t } = useTranslation()
  const handleLogOut = useLogOut()

  const classes = { ...useMenuStyles(), ...useStyles() }
  const { clearTablesSort } = useTableSort()
  const isDirtyField = useReactiveVar(isDirtyForm)
  const { contractStatus } = useIsLimitedContract()
  const [logoutPromptOpen, setLogoutPromptOpen] = useState(false)

  const checkableContractStatuses = [
    ContractStatusType.Active,
    ContractStatusType.Suspended,
    ContractStatusType.TerminationPending,
    ContractStatusType.Monitoring,
  ]

  const contractStatusCheck = contractStatus && checkableContractStatuses.includes(contractStatus)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onLogout = useCallback(() => {
    if (isDirtyField) {
      setLogoutPromptOpen(true)
    } else {
      handleLogOut()
    }
  }, [isDirtyField])

  return (
    <Box className={classes.dropDownContainer}>
      <Popper
        className={classes.profileMenuWrap}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        transition
      >
        <Paper className={classes.profileMenu}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList data-test="personalMenuList">
              <Box className={classes.navNameMobile}>
                <Typography variant="h5">
                  {user.firstName} {user.lastName}
                </Typography>
              </Box>
              <MenuItem
                className={classes.profileMenuItem}
                disabled={limitMode}
                onClick={handleClose}
              >
                <NavLink
                  to={generatePath(APP_PATHS.dashboard.personProfile, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  className={classes.profileMenuLink}
                  onClick={clearTablesSort}
                >
                  <PersonOutline />
                  <Typography variant="body1" className={classes.profileMenuText}>
                    {t('personalProfile')}
                  </Typography>
                </NavLink>
              </MenuItem>
              <MenuItem className={classes.profileMenuItem} onClick={handleClose}>
                <NavLink
                  to={generatePath(APP_PATHS.dashboard.settings, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  className={classes.profileMenuLink}
                  onClick={clearTablesSort}
                >
                  <Settings />
                  <Typography variant="body1" className={classes.profileMenuText}>
                    {t('settings', 'Settings')}
                  </Typography>
                </NavLink>
              </MenuItem>
              {contractStatusCheck && (
                <MenuItem className={classes.profileMenuItem} onClick={handleClose}>
                  <Box className={classes.profileMenuLink}>
                    <ContactSupportLink menuLink={true} footerDisplay={true} newTab />
                  </Box>
                </MenuItem>
              )}
              <MenuItem className={classes.profileMenuItem}>
                <Box className={classes.profileMenuLink} onClick={onLogout}>
                  <Logout />
                  <Typography variant="body1" className={classes.profileMenuText}>
                    {t('logOut', 'Log Out')}
                  </Typography>
                </Box>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>

      <LogOutPrompt logoutPromptOpen={logoutPromptOpen} setLogoutPromptOpen={setLogoutPromptOpen} />
    </Box>
  )
}
