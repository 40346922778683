import * as yup from 'yup'
import i18n from '../../i18n'
import { isEmpty } from 'lodash'
// import { isCountrySupportsIban } from '../../utils'
// import { getApolloClient } from '../../graphql'
// import { gql } from '@apollo/client'
import { isValid } from 'iban'
import { accTypeVar } from '../../graphql/local/dashboard'

export const IbanInputSchema = yup.object().shape({
  iban: yup
    .string()
    .test('ibanIsRequired', i18n.t('thisFieldIsRequired', 'This field is required'), function () {
      return accTypeVar() !== 1 || !isEmpty(this.parent.iban)
    })
    .test('incorrectIbanFormat', i18n.t('incorrectIbanFormat'), function () {
      return accTypeVar() !== 1 || isValid(this.parent.iban.trim())
    }),
  // .test(
  //   'validIban',
  //   i18n.t('validIban', 'Incorrect IBAN format for the selected country'),
  //   function () {
  //     return (
  //       !isCountrySupportsIban(this.parent.country) ||
  //       isValidIban(this.parent.iban?.trim(), this.parent.country)
  //     )
  //   },
  // ),
  // .test('validIban', i18n.t('bankNotFound', 'Bank not found'), async function () {
  //   const GET_BANK_INFO = gql`
  //     query getBankInfo($iban: String!) {
  //       bankInfo(iban: $iban) {
  //         bankName
  //         bic
  //         address
  //         city
  //         zip
  //         country
  //         countryISO
  //       }
  //     }
  //   `
  //   if (isValidIban(this.parent.iban?.trim(), this.parent.country)) {
  //     const response = await getApolloClient().query({
  //       query: GET_BANK_INFO,
  //       variables: { iban: this.parent.iban?.trim() },
  //     })
  //     return !isEmpty(response?.data?.bankInfo)
  //   }
  //   return true
  // }),
})

export type IbanInput = yup.Asserts<typeof IbanInputSchema>
