import { CardStatuses, Maybe } from '../../graphql'

export const CARDS_ITEMS_PER_PAGE = 10

export const gerCardStatus = (
  status: Maybe<CardStatuses> | undefined,
  isCard3dSecureActivated: Maybe<boolean> | undefined,
) => {
  const cardPending = !!status && status === CardStatuses.Pending
  const cardClosed = !!status && status === CardStatuses.Closed
  const cardCreated = !!status && status === CardStatuses.Created
  const cardInactive = !!status && status === CardStatuses.Active && !isCard3dSecureActivated

  return { cardPending, cardClosed, cardInactive, cardCreated }
}
