import React, { FC, useCallback } from 'react'
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/confirmed.svg'
import { ReactComponent as FreezeCard } from '../../assets/images/icons/freeze_card.svg'
import { ReactComponent as WarnIcon } from '../../assets/images/icons/icon-info2.svg'
import {
  CardsListDocument,
  CardStatuses,
  Maybe,
  useFreezeCardMutation,
  useUnFreezeCardMutation,
} from '../../graphql'
import { PATH_PARAMS } from '../../routes/paths'
import { AlertDataType } from '../../types'
import { CARDS_ITEMS_PER_PAGE, gerCardStatus } from './helpers'
import { usePageFiltersSorting } from '../../hooks'
import { cardsPageStateVar } from '../../components'

const useStyles = makeStyles((theme: Theme) => ({
  innerWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  textWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    width: '32px',
  },
  text: {
    marginLeft: theme.spacing(3),
    lineHeight: '1rem',
    textAlign: 'left',
    '&.terminated': {
      color: '#999999',
    },
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    lineHeight: '2rem',
    '&.terminated': {
      color: '#999999',
    },
  },
  itemButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    width: '100%',
    '& svg': {
      marginRight: theme.spacing(0.2),
    },
    '&.MuiButton-root': {
      minWidth: '5px',
      minHeight: '5px',
    },
    '&.MuiButton-disableElevation.Mui-disabled': {
      backgroundColor: '#F5F5F5',
    },
    '&.terminated': {
      '& svg path': {
        opacity: 0.5,
      },
    },
    '& .MuiButton-label': {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      background: '#ffffff',
      '&.MuiButton-disableElevation.Mui-disabled': {
        backgroundColor: '#ffffff',
      },
    },
  },
}))

type FreezeCardButtonPropsType = {
  cardId: string | number
  cardStatus: Maybe<CardStatuses> | undefined
  isCard3dSecureActivated: Maybe<boolean> | undefined
  setAlertData: React.Dispatch<React.SetStateAction<AlertDataType | undefined>>
  setIsOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
}

const FreezeCardButtonComponent: FC<FreezeCardButtonPropsType> = ({
  cardId,
  cardStatus,
  isCard3dSecureActivated,
  setAlertData,
  setIsOpenAlert,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { pageFilters } = usePageFiltersSorting(cardsPageStateVar)

  const { cardPending, cardInactive, cardClosed, cardCreated } = gerCardStatus(
    cardStatus,
    isCard3dSecureActivated,
  )

  const [freezeCardMutation, { loading: freezeCardLoading }] = useFreezeCardMutation({
    variables: {
      cardId,
    },
  })
  const [unFreezeCardMutation, { loading: unFreezeCardLoading }] = useUnFreezeCardMutation({
    variables: {
      cardId,
    },
  })

  const refetchQueriesArr = [
    {
      query: CardsListDocument,
      variables: {
        contractId: applicationId,
        search: pageFilters.searchValue,
        limit: CARDS_ITEMS_PER_PAGE,
        orderBy: pageFilters.sortBy,
        orderDirection: pageFilters.direction,
        offset: (Number(pageFilters.page) - 1) * CARDS_ITEMS_PER_PAGE,
      },
    },
  ]

  const handleFreeze = useCallback(async () => {
    try {
      if (cardStatus === CardStatuses.Blocked) {
        const { data } = await unFreezeCardMutation({
          refetchQueries: refetchQueriesArr,
          awaitRefetchQueries: true,
        })

        if (data?.unFreezeCard?.status === CardStatuses.Active) {
          setAlertData({
            text: t('unfrozenAlert', 'Card has been unfrozen'),
            type: 'success',
            icon: <SuccessIcon />,
          })
          setIsOpenAlert(true)
        }
      } else {
        const { data } = await freezeCardMutation({
          refetchQueries: refetchQueriesArr,
          awaitRefetchQueries: true,
        })

        if (data?.freezeCard?.status === CardStatuses.Blocked) {
          setAlertData({
            text: t('frozenAlert', 'Card has been frozen. All transactions will be blocked'),
            type: 'warning',
            icon: <WarnIcon />,
          })
          setIsOpenAlert(true)
        }
      }
    } catch (e) {
      toast.error((e as Error).message)
    }
  }, [freezeCardMutation, applicationId, cardStatus, unFreezeCardMutation])

  const isDisabled =
    cardClosed ||
    cardCreated ||
    cardInactive ||
    cardPending ||
    freezeCardLoading ||
    unFreezeCardLoading

  return (
    <Button
      disabled={isDisabled}
      className={clsx(classes.itemButton, isDisabled && 'terminated')}
      variant="contained"
      disableElevation
      color="default"
      onClick={handleFreeze}
    >
      <Grid className={classes.innerWrap}>
        <Grid item className={classes.icon}>
          <FreezeCard />
        </Grid>
        <Grid item className={classes.textWrap}>
          <Typography
            variant={'h4'}
            className={clsx(classes.subtitle, (cardClosed || cardPending) && 'terminated')}
          >
            {cardStatus === CardStatuses.Blocked
              ? t('unfreezeCard', 'Unfreeze card')
              : t('freezeCard', 'Freeze card')}
          </Typography>
          <Typography
            variant={'body1'}
            className={clsx(classes.text, (cardClosed || cardPending) && 'terminated')}
          >
            {t('temporarilyDisableCardUsage', 'Temporarily disable usage of the card')}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  )
}

export const FreezeCardButton = React.memo(FreezeCardButtonComponent, isEqual)
