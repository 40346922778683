import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import {
  AuthorizedPersonStatuses,
  ContractType,
  LimitedAccessRight,
  SignatoryRight,
  UboType,
} from '../graphql'
import { useCurrentUser } from './useCurrentUser'

export interface UserRightsResult {
  individualId: number
  isApplicant: boolean
  isAuthorizedPerson: boolean
  limitedAccessRight: LimitedAccessRight | null | undefined
  signatoryRight: SignatoryRight | null | undefined
  status: AuthorizedPersonStatuses | null | undefined
  isIntroducerContractOwner: boolean
  cannotSignOwnActions: boolean
  isIndividualContractOwner: boolean
  hasCardPayment: boolean
}
export interface GetUserRightsResult {
  loading: boolean
  error?: string | null
  userRights?: UserRightsResult
}

export function useDetermineUserRights(contractId: string | number): GetUserRightsResult {
  const user = useCurrentUser()
  const [userRights, setUserRights] = useState<UserRightsResult | undefined>()

  useEffect(() => {
    if (user) {
      const contract = user.contracts?.find((c) => c?.id === +contractId)
      const userAP = contract?.authorizedPersons?.find((ap) => ap?.person?.id === user.id)

      const rights = {
        individualId: user?.id,
        isApplicant: contract?.createdBy?.id === user.id,
        isAuthorizedPerson: !!userAP,
        limitedAccessRight: userAP?.limitedAccessRight,
        signatoryRight: userAP?.signatoryRight,
        status: userAP?.status,
        cannotSignOwnActions: !!userAP?.cannotSignOwnActions,
        isIntroducerContractOwner:
          (contract?.createdBy?.id === user.id || !contract?.createdBy) &&
          contract?.type === ContractType.IntroducerReference,
        isIndividualContractOwner: contract?.ownerType === UboType.NaturalPerson,
        hasCardPayment: !!contract?.preAssessment?.cardPayment?.isCardPayment,
      }
      !isEqual(userRights, rights) && setUserRights(rights)
    }
  }, [contractId, user, userRights])

  return !user || !userRights
    ? { loading: true }
    : {
        loading: false,
        userRights,
      }
}
