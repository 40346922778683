import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from '../assets/images/icons/info_icon.svg'
import { Account } from '../graphql'
import { AccountRequisites, AlertDataType } from '../types'
import { copyToClipboard, formatIban } from '../utils'

export const useHandleCopy = (
  account: Account,
  setAlertData: Dispatch<SetStateAction<AlertDataType | null>>,
) => {
  const { t } = useTranslation()

  return (value: AccountRequisites) => {
    value === AccountRequisites.iban
      ? copyToClipboard(`${formatIban(account.iban as string)}`)
      : copyToClipboard(`${account.swiftBIC}`)

    setAlertData({
      text: t('copiedToClipboard', { name: value.toUpperCase() }),
      type: 'primary',
      icon: <InfoIcon />,
    })

    setTimeout(() => {
      setAlertData(null)
    }, 3000)
  }
}
