import { signatureStatuses } from '@klarpay/enums'
import React, { FC, useCallback, MouseEvent, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { delaysForRefetch } from '../../../constants'
import { PATH_PARAMS } from '../../../routes/paths'
import { OperationNames } from '../../../types'
import { NeededToApprovedAlert } from '../../TransactionsPage/Tabs'
import { useStyles } from './useStyles'
import { getUserSignature, SignatureProps } from '../contract'
import {
  Action,
  ActionSignatureStatus,
  ActionStatus,
  LimitedAccessRight,
  SignatoryRight,
  TransactionStatus,
  useGetTransactionDetailsQuery,
} from '../../../graphql'
import { AlertTipItem } from '../../../components'
import { ReactComponent as RejectedIcon } from '../../../assets/images/icons/info_icon_red.svg'
import { ReactComponent as SignedIcon } from '../../../assets/images/icons/confirmed.svg'
import { dateFormat, normalizeActionStatus, transformActionStatus } from '../../../utils'
import { useCurrentUser } from '../../../hooks'
import clsx from 'clsx'
import { ReactComponent as IconDot } from '../../../assets/images/icons/Dot.svg'
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/info_icon.svg'
import config from '../../../config'

type signedAcc = {
  soleCount: number
  joinCount: number
  groupCount: number
}

const getSignedUsers = (signatures: SignatureProps[] | undefined) =>
  signatures
    ? signatures
        ?.filter((signature) => signature.status !== ActionSignatureStatus.Pending)
        .reduce(
          (acc: signedAcc, { signatoryRight: right }) => {
            if (acc.soleCount > 0) {
              return acc
            }
            switch (right) {
              case SignatoryRight.Sole:
                acc.soleCount = 1
                return acc
              case SignatoryRight.Joint:
                acc.joinCount++
                return acc
              case SignatoryRight.Group:
                acc.groupCount++
                return acc
              default:
                return acc
            }
          },
          { soleCount: 0, joinCount: 0, groupCount: 0 },
        )
    : { soleCount: 0, joinCount: 0, groupCount: 0 }

const getSignedAndNeedToSigned = (signatures: SignatureProps[] | undefined) => {
  let needToSign = signatures?.length
  let signed = 0

  const signedUsers = getSignedUsers(signatures)

  if (signedUsers?.soleCount) {
    needToSign = 1
    signed = 1
  } else if (signedUsers?.joinCount) {
    needToSign = 2
    signed = signedUsers.joinCount
  } else if (signedUsers?.groupCount) {
    needToSign = signatures?.filter(({ signatoryRight: right }) => right === SignatoryRight.Group)
      .length
    signed = signedUsers.groupCount
  }

  return { signed, needToSign }
}

export const SignaturesTitle: FC<{
  signatures: SignatureProps[] | undefined
  title?: string
}> = ({ title, signatures }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { signed, needToSign } = getSignedAndNeedToSigned(signatures)

  return (
    <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
      {`${title ?? t('signatures)', 'Signatures')} (${signed}/${needToSign || 1})`}
    </Typography>
  )
}

const useGetStatus = (signatureStatus: ActionSignatureStatus) => {
  const { t } = useTranslation()
  switch (signatureStatus) {
    case ActionSignatureStatus.Accept:
      return t('signed', 'Signed')
    case ActionSignatureStatus.Reject:
      return t('rejected', 'Rejected')
    case ActionSignatureStatus.Pending:
      return t('pending', 'Pending')
    case ActionSignatureStatus.Expired:
      return t('expired', 'Expired')
    default:
      break
  }
}

export const SignatureRow: FC<{
  signature: SignatureProps
}> = ({ signature }) => {
  const classes = useStyles()
  const statusLabel = useGetStatus(signature.status)
  return (
    <TableRow>
      <TableCell className={classes.signatory}>
        <Box component="span">{signature.signatory}</Box>
      </TableCell>
      <TableCell className={classes.status}>
        <Box component="span" className={clsx(`${classes.chipHeight}`)}>
          <Chip
            variant="outlined"
            size="small"
            label={statusLabel}
            className={clsx(`${classes.chipPadding} roundedPill ${signature.status}`)}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box component="span">{dateFormat(signature.actionTaken)}</Box>
      </TableCell>
    </TableRow>
  )
}

export const SignatureBox: FC<{ signature: SignatureProps; keyIndex: number }> = ({
  signature,
  keyIndex,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const statusLabel = useGetStatus(signature.status)
  return (
    <>
      <Box className={classes.mobileTableItem}>
        <Box className={classes.mobileTableLabel}>{t('signatory', 'Signatory')}</Box>
        <Box className={classes.mobileTableName}>{signature.signatory}</Box>
      </Box>
      <Box className={classes.mobileTableItem}>
        <Box className={classes.mobileTableLabel}>{t('status', 'Status')}</Box>
        <Box component="span" className={clsx(`${classes.chipHeight}`)}>
          <Chip
            variant="outlined"
            size="small"
            label={statusLabel}
            className={clsx(`${classes.chipPadding} roundedPill ${signature.status}`)}
          />
        </Box>
      </Box>
      <Box className={classes.mobileTableItem}>
        <Box className={classes.mobileTableLabel}>{t('actionTaken', 'Action taken')}</Box>
        <Box>{dateFormat(signature.actionTaken)}</Box>
      </Box>
      <Divider light className={classes.mobileDivider} key={keyIndex} />
    </>
  )
}

export const useIsAllSigned = (signatures: SignatureProps[] | undefined) => {
  if (!signatures) {
    return false
  }

  const signedUsers = getSignedUsers(signatures)

  const allGroupSignatoriesCount = signatures?.filter(
    ({ signatoryRight: right }) => right === SignatoryRight.Group,
  ).length

  return (
    signedUsers.soleCount >= 1 ||
    signedUsers.joinCount >= 2 ||
    allGroupSignatoriesCount - signedUsers.groupCount === 0
  )
}

const useOtherSignaturesStatus = (signatures: SignatureProps[] | undefined): boolean => {
  const currentUser = useCurrentUser()
  const signature = getUserSignature(currentUser?.id, signatures)
  const isAllSigned = useIsAllSigned(signatures)

  if (!signatures) {
    return false
  }

  return signature?.status === signatureStatuses.accept.value && !isAllSigned
}

export const SignaturesTable: FC<{
  signatures: SignatureProps[] | undefined
  signedCount?: number
  title?: string
}> = ({ title, signatures }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const showAlert = useOtherSignaturesStatus(signatures)

  const isSignedBySole = signatures?.some(
    ({ signatoryRight, status }) =>
      signatoryRight === SignatoryRight.Sole && status === signatureStatuses.accept.value,
  )

  const visibleSignatories = signatures?.filter((signature) =>
    isSignedBySole
      ? signature.signatoryRight === SignatoryRight.Sole &&
        signature.status !== ActionSignatureStatus.Pending
      : signature.status !== ActionSignatureStatus.Pending,
  )

  return (
    <>
      {!!visibleSignatories?.length && showAlert && <NeededToApprovedAlert />}

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} className={classes.cell}>
              <SignaturesTitle signatures={signatures} title={title} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.signatory}>{t('signatory', 'Signatory')}</TableCell>
            <TableCell className={classes.status}>{t('status', 'Status')}</TableCell>
            <TableCell>{t('actionTaken', 'Action taken')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleSignatories?.map((signature) => (
            <SignatureRow signature={signature} key={signature.id} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export const SignaturesBox: FC<{
  signatures: SignatureProps[] | undefined
  signedCount?: number
}> = ({ signatures }) => {
  const classes = useStyles()
  const showAlert = useOtherSignaturesStatus(signatures)

  return (
    <>
      {showAlert && <NeededToApprovedAlert />}

      <Box className={classes.mobileTableItem}>
        <Box className={classes.mobileTableLabel}>
          <SignaturesTitle signatures={signatures} />
        </Box>
      </Box>
      {signatures
        ?.filter((signature) => signature.status !== ActionSignatureStatus.Pending)
        .map((signature, index) => (
          <React.Fragment key={index}>
            <SignatureBox signature={signature} keyIndex={index} />
          </React.Fragment>
        ))}
    </>
  )
}

export const SignatureAlert: FC<{ signatures?: SignatureProps[] }> = ({ signatures }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const currentUser = useCurrentUser()

  let alert = null

  if (!currentUser) {
    return alert
  }
  const signature = getUserSignature(currentUser.id, signatures)

  switch (signature?.status) {
    case ActionSignatureStatus.Reject:
      alert = (
        <Box className={classes.alert}>
          <AlertTipItem
            value={`${t('youRejectedThisDocument', 'You rejected this document on')} ${dateFormat(
              signature.actionTaken,
            )}`}
            iconComponent={<RejectedIcon />}
            type={'warning'}
            isOpenAlert
          />
        </Box>
      )
      break
    case ActionSignatureStatus.Accept:
      alert = (
        <Box className={classes.alert}>
          <AlertTipItem
            value={`${t('youSignedThisDocument', 'You signed this document on')} ${dateFormat(
              signature.actionTaken,
            )}`}
            iconComponent={<SignedIcon />}
            type={'success'}
            isOpenAlert
          />
        </Box>
      )
      break
    default:
      break
  }
  const rejectedUser = signatures?.find(({ status }) => status === ActionSignatureStatus.Reject)

  if (!alert && rejectedUser) {
    alert = (
      <Box className={classes.alert}>
        <AlertTipItem
          value={t(
            'hasRejectedTheAction',
            `${rejectedUser.signatory} has rejected the action and you can’t sign it`,
            {
              val1: rejectedUser.signatory,
            },
          )}
          iconComponent={<RejectedIcon />}
          type={'warning'}
          isOpenAlert
        />
      </Box>
    )
  }

  return alert
}

export const NewAuthorizedPersonAlert: FC<{ role?: string }> = ({ role }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  switch (role) {
    case SignatoryRight.Sole:
    case SignatoryRight.Group:
    case SignatoryRight.Joint:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'approveInviteTextSignatories',
              'Only after the approval of the invitation by {{val1}}, this individual will have full access to initiate, view and sign transactions.',
              {
                val1: config.name,
              },
            )}
            iconComponent={<InfoIcon />}
            type={'primary'}
          />
        </Box>
      )
    case LimitedAccessRight.CardHolder:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'approveInviteTextCardholder',
              'After all the signatories approve the invitation, this individual will be able to use a corporate virtual bank card.',
            )}
            iconComponent={<InfoIcon />}
            type={'primary'}
          />
        </Box>
      )
    case LimitedAccessRight.ViewDataEntry:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'approveInviteTextLimitedDataEntry',
              'After all the signatories approve the invitation, this individual will have a limited access to the {{val1}} Dashboard being able to create transactions and initiate actions that must be then signed by Signatories.',
              {
                val1: config.name,
              },
            )}
            iconComponent={<InfoIcon />}
            type={'primary'}
          />
        </Box>
      )
    case LimitedAccessRight.ViewOnly:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'approveInviteTextLimited',
              'After all the signatories approve the invitation, this individual will have a limited read-only access to the {{val1}} Dashboard being able to view transactions.',
              {
                val1: config.name,
              },
            )}
            iconComponent={<InfoIcon />}
            type={'primary'}
          />
        </Box>
      )
    case LimitedAccessRight.PaymentFacilitationView:
      return (
        <Box className={classes.alert}>
          <AlertTipItem
            value={t(
              'approveInviteTextPaymentFacilitation',
              'After all the signatories approve the invitation, this individual will have a limited read-only access to the {{val1}} Dashboard being able to view the Payment facilitation page.',
              {
                val1: config.name,
              },
            )}
            iconComponent={<InfoIcon />}
            type={'primary'}
          />
        </Box>
      )
    default:
      return null
  }
}

export const SignatureStatus: FC<{
  data: Action
  handleSign: (data: Action, action: OperationNames | null) => void
}> = ({ data, handleSign }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const theme = useTheme()
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('lg'))

  const status = transformActionStatus(data)

  const onSign = useCallback(
    (action: OperationNames | null, e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      handleSign(data, action)
    },
    [data, handleSign],
  )

  const myActionSignature = data?.signatures?.find((s) => s?.isMine)

  const mySignatureIsSigned = !!myActionSignature?.signedAt

  const isNeedToAddtlSignatures =
    mySignatureIsSigned &&
    status !== ActionSignatureStatus.Reject &&
    data.status === ActionStatus.PendingSignatures

  return status !== ActionSignatureStatus.Pending ? (
    <>
      <Box component="span" className={clsx(`roundedPill ${status}`)} data-test={status}>
        {normalizeActionStatus(status)}
      </Box>
      {isNeedToAddtlSignatures && (
        <Box
          marginLeft={isMdOrLarger ? 1 : 0}
          mt={isMdOrLarger ? 0 : 1}
          component="span"
          className={clsx(`roundedPill pendingSignature`)}
          data-test={status}
        >
          {t('addlSignaturesRequired', 'Addtl. Signatures Required')}
        </Box>
      )}
    </>
  ) : (
    <Box className={classes.buttonsBox} data-test="unsigned">
      <Button
        aria-label="sign"
        type="button"
        onClick={onSign.bind(null, OperationNames.sign)}
        data-test="sign"
      >
        {t('sign', 'Sign')}
      </Button>
      <IconDot />
      <Button
        color="secondary"
        aria-label="reject"
        type="button"
        onClick={onSign.bind(null, OperationNames.reject)}
        data-test="reject"
      >
        {t('reject', 'Reject')}
      </Button>
    </Box>
  )
}

export const useUpdateTransactionStatusHandler = (signatures: SignatureProps[] | undefined) => {
  const { [PATH_PARAMS.transactionId]: trxId } = useParams() as Record<string, string>
  const { data, refetch } = useGetTransactionDetailsQuery({
    variables: {
      id: trxId,
    },
  })

  const { signed, needToSign } = getSignedAndNeedToSigned(signatures)
  const intervalEvolvingRef = useRef<NodeJS.Timer>()
  const delayBeforeChecking = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (signed === needToSign) {
      delayBeforeChecking.current = setTimeout(() => {
        intervalEvolvingRef.current = setInterval(refetch, delaysForRefetch.interval)
      }, delaysForRefetch.delay)
    }

    return () => {
      clearInterval(intervalEvolvingRef.current)
      clearTimeout(delayBeforeChecking.current as ReturnType<typeof setTimeout>)
    }
  }, [signed, needToSign])

  useEffect(() => {
    if (
      data?.transaction?.status !== TransactionStatus.PendingExecutionSignatures &&
      data?.transaction?.status !== TransactionStatus.PendingAml
    ) {
      clearInterval(intervalEvolvingRef.current)
      clearTimeout(delayBeforeChecking.current as ReturnType<typeof setTimeout>)
    }
  }, [data?.transaction?.status])
}
