import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { forEach, map } from 'lodash'

import './styles.css'
import { GridRow } from '../../Common'
import { FilesListUpload } from './FilesListUpload'
import { sizeInKB } from '../../../utils'
import { FilesForSavingAndShowType } from '../../../types'
import { useFormContext } from 'react-hook-form'

type FilesListStaticProps = {
  filesList: FilesForSavingAndShowType[]
}

export const FilesListStatic: FC<FilesListStaticProps> = ({ filesList }) => {
  const { setValue, watch } = useFormContext()
  const docsMarkedDelete = watch('docsMarkedDelete')

  function removeFileHandler(id: number) {
    const tempArray: number[] = []
    forEach(docsMarkedDelete, (itemId) => tempArray.push(itemId))
    tempArray.push(id)
    setValue(`docsMarkedDelete`, tempArray)
  }

  return (
    <Box>
      <GridRow>
        {map(filesList, (field) => (
          <FilesListUpload
            key={`${field.id + field.fileName}`}
            name={field.fileName as string}
            size={sizeInKB(field['size'] as number)}
            percent={0}
            status={'done'}
            type={field['mimeType'] as string}
            removeFileHandler={() => removeFileHandler(field?.id as number)}
            docType={field.docType as string}
          />
        ))}
      </GridRow>
    </Box>
  )
}
