import React, { Dispatch, FC, SetStateAction } from 'react'
import { Grid, makeStyles, Box } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { ControlledTextFieldProps, LegalList } from '../../../types'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 0 40px 0',
  },
  nameSelect: {
    width: '100%',
  },
  input: {
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.spacing(1.5, 0, 1.5, 1.5),
      lineHeight: '1.1876em',
      display: 'block',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(0.857)',
    },
  },
}))

export const LegalOwnerSelect: FC<
  {
    setUboId: Dispatch<SetStateAction<string>>
    uboId: string
    dataPrepared: LegalList[]
  } & Partial<ControlledTextFieldProps>
> = ({ setUboId, uboId, dataPrepared, ...rest }) => {
  const classes = useStyles()

  return (
    <Box>
      <Grid item xs={12} sm={5} className={classes.wrapper} data-test="legalEntity">
        <Autocomplete
          className={classes.nameSelect}
          options={dataPrepared}
          autoSelect
          getOptionLabel={(option) => option.label as string}
          getOptionSelected={(option, value) => {
            return option?.key === value?.key
          }}
          onChange={(event, newValue: LegalList | null) => {
            if (newValue) return setUboId(newValue.key)
          }}
          defaultValue={dataPrepared[0]}
          value={dataPrepared.find((value) => value.key === uboId)}
          renderInput={(params) => (
            <TextField className={classes.input} label="Legal entity" {...params} {...rest} />
          )}
        />
      </Grid>
    </Box>
  )
}
