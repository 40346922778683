import React, { FC, ReactElement } from 'react'
import clsx from 'clsx'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    padding: '23px 0',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: '0 0',
    alignSelf: 'normal',
    marginRight: 16,
  },
  container: {
    margin: '5px 0px 5px 0px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
  },
  wrapText: {
    width: '100%',
  },
  tip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tipBold: {
    margin: 0,
    '& .MuiTypography-body1': {
      fontWeight: '700',
    },
  },
}))

export const CommonTipItem: FC<{
  value: string
  iconComponent: ReactElement<HTMLElement, string> | null
  valueBold?: string
  valueWordUnderline?: string
  title?: string
}> = ({ value, title, iconComponent, valueBold, valueWordUnderline, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, 'tipItem')} {...rest}>
      <Container component="div" maxWidth={false} disableGutters={true}>
        <Grid container className={clsx(classes.container, 'alertHeader')}>
          <Grid item className={classes.iconContainer}>
            {iconComponent}
          </Grid>
          <Grid item className={classes.wrapText}>
            {!!title && (
              <Box className={classes.tipBold}>
                <Typography>{title}</Typography>
              </Box>
            )}
            {!!valueBold && (
              <Box className={classes.tipBold} data-test="tipBold">
                <Typography>{valueBold}</Typography>
              </Box>
            )}
            <Box className={classes.tip} data-test="tip">
              <Typography>
                {value}
                {!!valueWordUnderline && (
                  <b>
                    &nbsp;
                    <u>{valueWordUnderline}</u>
                  </b>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
