import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { Typography, makeStyles, Button } from '@material-ui/core'

import { ReactComponent as IconListRound } from '../../assets/images/icons/virtual-cards.svg'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  info: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 12,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '24px',
    width: '100%',
  },
  btn: {
    margin: '10px 0 0 0',
  },
}))

export const EmptyTransactionsPage: FC<{ getStarted: () => void }> = ({ getStarted }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.container}>
      <IconListRound />
      <Typography paragraph={true} className={classes.info}>
        {t('createMultipleKlarpay')}
        <br />
        {t('appleOrGooglePay')}
      </Typography>
      <Button
        onClick={getStarted}
        disableElevation
        variant="contained"
        color="default"
        className={classes.btn}
      >
        {t('getStarted', 'Get Started')}
      </Button>
    </Grid>
  )
}
export default EmptyTransactionsPage
