import React, { FC, useCallback, useEffect } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Box, Button, FormControl, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { InputAdornment } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'

import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../components'
import { ExpectedVolumes } from '../../types'
import { isEqual, toNumber } from 'lodash'
import { BusinessRegion, CardPaymentsTransactionalDataRegion } from '../../graphql'
import { findNumberedObjectKeys, findNumberedObjectValues } from '../../utils'

import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      '& label': {
        top: '-5px',
      },
    },
  },
  volume: {
    flexBasis: '47%',
    marginLeft: theme.spacing(2.5),
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      paddingRight: 15,
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      paddingRight: 5,
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputLabel-root': {
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
      },
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      marginLeft: '51%',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  removeButton: {
    margin: '10px 0px 0px 10px',
    padding: 0,
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '100%',
    },
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
}))

const DoubleInputComponent: FC<{
  name: string
  labels: [string, string]
  addBtnText: string
  selectData: { key: BusinessRegion; label: string; disabled?: boolean }[]
  isViewOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
}> = ({ name, labels, addBtnText, selectData, isViewOnly, tooltipTitle }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [label1, label2] = labels
  const methods = useFormContext()
  const { watch, register, setValue, getValues, errors, trigger, formState } = methods
  const inputsSelectNamesArr = selectData.map((item, i) => `${name + 'Sel' + i}`)
  const inputsFieldsNamesArr = selectData.map((item, i) => `${name + 'Inp' + i}`)
  const inputsValuesArr = watch(name)
  const inputsSelect = watch(inputsSelectNamesArr)
  const inputsField = watch(inputsFieldsNamesArr)

  const error = errors ? errors[name] : null
  const formData = watch()

  const foundInpKeys = findNumberedObjectKeys(formData, 'businessRegionsInp')
  const businessCategorySumPercent = foundInpKeys.reduce(
    (acc, key) => acc + toNumber(formData[key] ?? '0'),
    0,
  )

  const foundSelValues = findNumberedObjectValues(formData, 'businessRegionsSel')
  const dataExcludingPreviouslySelected = selectData.map((region) => {
    if (foundSelValues.includes(region.key)) {
      return {
        ...region,
        disabled: true,
      }
    } else {
      return region
    }
  })

  const onAdd = useCallback(() => {
    const valuesArr = getValues(name)
    register(inputsSelectNamesArr[valuesArr.length])
    register(inputsFieldsNamesArr[valuesArr.length])
    const newArrVal = [...valuesArr, { [label1]: '', [label2]: '' }]

    setValue(name, newArrVal, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [...Object.values(inputsSelect), ...Object.values(inputsField)])

  useEffect(() => {
    register(inputsSelectNamesArr[0])
    register(inputsFieldsNamesArr[0])
    register(name)
    if (!getValues(name)) {
      setValue(name, [{ [label1]: '', [label2]: '' }])
    }
  }, [])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      if (indexToRemove > 0) {
        const tempArray: ExpectedVolumes[] = []
        inputsValuesArr?.forEach((itemValue: ExpectedVolumes, i: number) => {
          if (i !== indexToRemove) {
            tempArray.push(itemValue)
          } else {
            setValue(`${name + 'Sel' + i}`, '', {
              shouldValidate: true,
              shouldDirty: true,
            })
            setValue(`${name + 'Inp' + i}`, '', {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        })
        setValue(name, tempArray, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    },
    [inputsValuesArr],
  )

  useEffect(() => {
    const selectedVal: { key: number; value: string }[] = []
    for (let j = 0; j < selectData.length; j++) {
      const selectValue = watch(`${name + 'Sel' + j}`)
      !!selectValue && selectedVal.push({ key: j, value: selectValue })
    }
  }, [...Object.values(inputsSelect)])

  useEffect(() => {
    if (formState.dirtyFields['businessRegionsInp0'] || formState.dirtyFields['businessRegions']) {
      trigger(`businessRegions`)
    }
  }, [...Object.values(inputsField)])

  const handleSelect = useCallback(
    (inputName: string, oldValue: string) => {
      const regionsArr = getValues(name)
      const newValue = getValues(inputName)

      const newRegionsArr = regionsArr.map((region: CardPaymentsTransactionalDataRegion) => {
        if (oldValue === region.businessCategoryRegion) {
          return {
            ...region,
            businessCategoryRegion: newValue,
          }
        }

        return region
      })
      setValue(name, newRegionsArr)
    },
    [name],
  )

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <GridRow>
            {inputsValuesArr &&
              inputsValuesArr.map((value: CardPaymentsTransactionalDataRegion, i: number) => {
                const inputName = name + 'Sel' + i
                const defaultValue = value?.businessCategoryRegion || ''

                return (
                  <Box className={classes.twoRows} key={i}>
                    <FormControl className={clsx(classes.formControl)}>
                      <FormAutocompleteSelect
                        label={label1}
                        name={inputName}
                        data={dataExcludingPreviouslySelected}
                        defaultValue={defaultValue}
                        readOnly={isViewOnly}
                        onInputChange={() => handleSelect(inputName, defaultValue)}
                      />
                    </FormControl>
                    <FormControlledTextField
                      className={clsx(classes.volume)}
                      label={label2}
                      name={`${name + 'Inp' + i}`}
                      fullWidth={true}
                      required={false}
                      type="number"
                      inputProps={{
                        step: '1',
                        style: { textAlign: 'right' },
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                      }}
                      defaultValue={value?.businessCategoryPercentage || ''}
                      disabled={isViewOnly}
                    />
                    {i !== 0 && i === inputsValuesArr.length - 1 && (
                      <Box className={classes.buttonsBox}>
                        <Button
                          color="secondary"
                          aria-label="delete"
                          type="button"
                          onClick={() => onRemove(i)}
                          className={clsx(
                            i !== 0 && i === inputsValuesArr.length - 1 ? '' : classes.hidden,
                          )}
                          disabled={isViewOnly}
                        >
                          {t('delete', 'Delete')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )
              })}
            {!!error && (
              <Grid container className={classes.errorMessage}>
                <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
              </Grid>
            )}
            {tooltipTitle && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.businessRegions}
                title={tooltipTitle}
              />
            )}
            <Grid item className={classes.addButton}>
              <Button
                type="button"
                variant="contained"
                fullWidth={true}
                disableElevation
                onClick={onAdd}
                disabled={
                  !!error ||
                  businessCategorySumPercent === 100 ||
                  inputsValuesArr?.length === selectData.length ||
                  isViewOnly
                }
              >
                {addBtnText}
              </Button>
            </Grid>
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}
export const DoubleInput = React.memo(DoubleInputComponent, isEqual)
