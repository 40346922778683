import { makeStyles } from '@material-ui/core'

export const useTabsStyle = makeStyles((theme) => ({
  tabBar: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F0F0F0',
    boxShadow: 'none',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-root': {
      minHeight: 32,
    },
  },
  tab: {
    ...theme.typography.h6,
    flex: '0 0 50%',
    color: '#000',
    maxWidth: '100%',
    textTransform: 'none',
    minHeight: 32,
    padding: theme.spacing(0.5),
    '&.Mui-selected': {
      backgroundColor: 'white',
    },
  },
}))
