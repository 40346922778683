import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'

export const StatusBar: FC<{ percentageValue: number }> = ({ percentageValue }) => {
  const bgColor = useMemo(() => {
    if (percentageValue < 50) {
      return '#19B661'
    } else if (percentageValue > 90) {
      return '#EF2828'
    }
    return '#EF8019'
  }, [percentageValue])
  return (
    <Box
      width="100%"
      height="8px"
      border={1}
      borderColor="#F0F0F0"
      borderRadius="4px"
      padding="1px"
    >
      <Box width={`${percentageValue}%`} height="4px" borderRadius="2px" bgcolor={bgColor} />
    </Box>
  )
}
