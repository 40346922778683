import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { StatusBar } from '../../components'
import { CardLimitWithUsage, CardSpendingLimitTypes } from '../../graphql'

export const CardSpent: FC<{ limitsWithUsage: CardLimitWithUsage[] }> = ({ limitsWithUsage }) => {
  if (!limitsWithUsage) return null

  const { totalSpent, totalLimit } = limitsWithUsage.reduce(
    (acc, limit) => {
      if (
        limit.limitType === CardSpendingLimitTypes.MonthlyInternetPurchase ||
        limit.limitType === CardSpendingLimitTypes.MonthlyContactlessPurchase
      ) {
        acc.totalSpent = acc.totalSpent + limit.used
        acc.totalLimit = acc.totalLimit + limit.amount
      }

      return acc
    },
    { totalSpent: 0, totalLimit: 0 },
  )

  const percentageValue = totalLimit ? (totalSpent / totalLimit) * 100 : 0

  return (
    <Box display="flex" flexDirection="column" gridGap={4}>
      <Typography>{`${Number(totalSpent.toFixed()).toLocaleString('en-US', {
        useGrouping: true,
      })} / ${Number(totalLimit.toFixed()).toLocaleString('en-US', {
        useGrouping: true,
      })}`}</Typography>
      <Box maxWidth="100px">
        <StatusBar
          percentageValue={percentageValue > 0 && percentageValue < 3 ? 3 : percentageValue}
        />
      </Box>
    </Box>
  )
}
