import React, { FC } from 'react'
import {
  Box,
  Chip,
  Divider,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'

type AdressTabProps = {
  streetAddress_old: string
  streetAddress_new: string
  postal_code_old: number
  postal_code_new: number
  city_old: string
  city_new: string
  signatures: {
    lindaMason: {
      status: string
      actionTaken: string
    }
    maxMustermann: {
      status: string
      actionTaken: string
    }
    ricardoDeLaCruz: {
      status: string
      actionTaken: string
    }
  }
}

export const AdressTab: FC<{ data: AdressTabProps }> = ({ data }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isStatusMaxMustermann = data?.signatures.maxMustermann.status.toLowerCase() === 'signed'
  const isStatusRicardoDeLaCruz = data?.signatures.ricardoDeLaCruz.status.toLowerCase() === 'signed'
  const isStatusLindaMasonGroup = data?.signatures.lindaMason.status.toLowerCase() === 'signed'

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width="200" colSpan={3}>
                <Typography variant="h4">
                  {t('changedBusinessDetails', 'What has changed: Business details')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('fieldLabel', 'Field')}</TableCell>
              <TableCell>{t('oldValue', 'Old value')}</TableCell>
              <TableCell>{t('newValue', 'New value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="200">
                <Box component="span">{t('streetAddress', 'Street Address')}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data?.streetAddress_old}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data?.streetAddress_new}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span">{t('postalCode', 'Postal code')}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data?.postal_code_old}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data?.postal_code_new}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span">{t('city', 'City')}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data?.city_old}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data?.city_new}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width="200" colSpan={3} className={classes.cell}>
                <Typography variant="h4" className={classes.label}>
                  {t('signatures23', 'Signatures (2/3)')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('signatory', 'Signatory')}</TableCell>
              <TableCell>{t('status', 'Status')}</TableCell>
              <TableCell>{t('actionTaken', 'Action taken')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="200">
                <Box component="span">{t('lindaMasonGroup', 'Linda Mason (group)')}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">
                  <Chip
                    variant="outlined"
                    size="small"
                    label={
                      isStatusLindaMasonGroup ? t('signed', 'Signed') : t('rejected', 'Rejected')
                    }
                    className={`${classes.chip} ${
                      isStatusLindaMasonGroup ? classes.success : classes.warning
                    }`}
                  />
                </Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data.signatures.lindaMason.actionTaken}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span">{t('maxMustermannGroup', 'Max Mustermann (group)')}</Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">
                  <Chip
                    variant="outlined"
                    size="small"
                    label={
                      isStatusMaxMustermann ? t('signed', 'Signed') : t('rejected', 'Rejected')
                    }
                    className={`${classes.chip} ${
                      isStatusMaxMustermann ? classes.success : classes.warning
                    }`}
                  />
                </Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data.signatures.maxMustermann.actionTaken}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span">
                  {t('ricardoDeLaCruzGroup', 'Ricardo de la Cruz (group)')}
                </Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">
                  <Chip
                    variant="outlined"
                    size="small"
                    label={
                      isStatusRicardoDeLaCruz ? t('signed', 'Signed') : t('rejected', 'Rejected')
                    }
                    className={`${classes.chip} ${
                      isStatusRicardoDeLaCruz ? classes.success : classes.warning
                    }`}
                  />
                </Box>
              </TableCell>
              <TableCell width="200">
                <Box component="span">{data.signatures.ricardoDeLaCruz.actionTaken}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={`${classes.mobileTableItem} ${classes.mobileEnd}`}>
              <Box className={classes.mobileTableLabel}>
                <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
                  {t('changedBusinessDetails', 'What has changed: Business details')}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('fieldLabel', 'Field')}</Box>
              <Box className={classes.mobileTableName}>{t('streetAddress', 'Street Address')}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('oldValue', 'Old value')}</Box>
              <Box>{data?.streetAddress_old}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('newValue', 'New value')}</Box>
              <Box>{data?.streetAddress_new}</Box>
            </Box>

            <Divider light className={classes.mobileDivider} />
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('fieldLabel', 'Field')}</Box>
              <Box className={classes.mobileTableName}>{t('postalCode', 'Postal code')}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('oldValue', 'Old value')}</Box>
              <Box>{data?.postal_code_old}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('newValue', 'New value')}</Box>
              <Box>{data?.postal_code_new}</Box>
            </Box>

            <Divider light className={classes.mobileDivider} />
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('fieldLabel', 'Field')}</Box>
              <Box className={classes.mobileTableName}>{t('postalCode', 'Postal code')}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('oldValue', 'Old value')}</Box>
              <Box>{data?.city_old}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('newValue', 'New value')}</Box>
              <Box>{data?.city_new}</Box>
            </Box>

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
                  {t('signatures23)', 'Signatures (2/3)')}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('signatory', 'Signatory')}</Box>
              <Box className={classes.mobileTableName}>
                {t('lindaMasonGroup', 'Linda Mason (group)')}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('status', 'Status')}</Box>
              <Box component="span">
                <Chip
                  variant="outlined"
                  size="small"
                  label={
                    isStatusLindaMasonGroup ? t('signed', 'Signed') : t('rejected', 'Rejected')
                  }
                  className={`${classes.chip} ${
                    isStatusLindaMasonGroup ? classes.success : classes.warning
                  }`}
                />
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('actionTaken', 'Action taken')}</Box>
              <Box>{data?.signatures.lindaMason.actionTaken}</Box>
            </Box>

            <Divider light className={classes.mobileDivider} />
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('signatory', 'Signatory')}</Box>
              <Box className={classes.mobileTableName}>
                {t('maxMustermannGroup', 'Max Mustermann (group)')}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('status', 'Status')}</Box>
              <Box component="span">
                <Chip
                  variant="outlined"
                  size="small"
                  label={isStatusMaxMustermann ? t('signed', 'Signed') : t('rejected', 'Rejected')}
                  className={`${classes.chip} ${
                    isStatusMaxMustermann ? classes.success : classes.warning
                  }`}
                />
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('actionTaken', 'Action taken')}</Box>
              <Box>{data?.signatures.maxMustermann.actionTaken}</Box>
            </Box>

            <Divider light className={classes.mobileDivider} />
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('signatory', 'Signatory')}</Box>
              <Box className={classes.mobileTableName}>
                {t('ricardoDeLaCruzGroup', 'Ricardo de la Cruz (group)')}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('status', 'Status')}</Box>
              <Box component="span">
                <Chip
                  variant="outlined"
                  size="small"
                  label={
                    isStatusRicardoDeLaCruz ? t('signed', 'Signed') : t('rejected', 'Rejected')
                  }
                  className={`${classes.chip} ${
                    isStatusRicardoDeLaCruz ? classes.success : classes.warning
                  }`}
                />
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('actionTaken', 'Action taken')}</Box>
              <Box>{data?.signatures.ricardoDeLaCruz.actionTaken}</Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
