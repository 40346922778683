import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import clsx from 'clsx'

import { Box, Button, Fab, Grid, makeStyles, Theme, Typography, Hidden } from '@material-ui/core'

import { ChangeCardPassword, trxPageStateVar } from '../../components'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'

import { CardType, LimitedAccessRight } from '../../graphql'
import { AlertDataType } from '../../types'

import { CardDetailsActions2FAModal } from '../../components/Common/CardDetailsActions2FAModal'
import { AddWalletCardModal } from '../../components/Common/AddCardWalletModal'
import { SettingsCardModal } from '../../components/Common/SettingsCardModal'
import { REGEXP_CARD_NUMBER } from '../../constants/regexp'
import { copyToClipboard } from '../../utils'
import { useCurrentUser, useDetermineUserRights, usePageFiltersSorting } from '../../hooks'

import { ReactComponent as SetSpendingLimit } from '../../assets/images/icons/set_spending_limit.svg'
import { ReactComponent as ViewTransactions } from '../../assets/images/icons/view_transactions.svg'
import { ReactComponent as ChangePassword } from '../../assets/images/icons/change_password.svg'
import { ReactComponent as ViewDetails } from '../../assets/images/icons/view_details.svg'
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/confirmed.svg'
import { ReactComponent as FlagIcon } from '../../assets/images/icons/logo 1.svg'
import { ReactComponent as CopySvg } from '../../assets/images/icons/copy.svg'
import { ReactComponent as Visa } from '../../assets/images/icons/visa.svg'
import CloseIcon from '@material-ui/icons/Close'
import { FreezeCardButton } from './FreezeCardButton'
import { gerCardStatus } from './helpers'
import { WalletButtons } from './WalletButtons'

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    padding: '8px 28px 32px 28px',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  details: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '400px',
      position: 'relative',
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      maxHeight: '750px',
      overflowX: 'hidden',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
      '&.signatoryScroll': {
        overflowY: 'auto',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      marginTop: 26,
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'static',
    maxHeight: 200,
    // padding: '12px 0 12px 0',
    left: '24px',
    top: '29.28px',
    background: '#FFFFFF',
    boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '16px',
    flex: 'none',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3.2),
    [theme.breakpoints.down('md')]: {
      maxHeight: '200px',
    },
  },
  cardNumber: {
    marginTop: theme.spacing(4.6),
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: '#000000',
    width: '100%',
  },
  expiryDateCvv: {
    marginTop: theme.spacing(1),
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'center',
    color: '#000000',
    width: '100%',
  },
  ownerVisa: {
    marginTop: theme.spacing(1),
    fontFamily: 'Arial',
    fontStyle: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000000',
    width: '100%',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    marginRight: 'auto',
  },
  visa: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  expiryDate: {},
  owner: {
    textTransform: 'capitalize',
  },
  cvv: {
    marginLeft: '20px',
  },
  controlButton: {
    minHeight: 32,
    padding: theme.spacing(0, 1),
    backgroundColor: '#FFFFFF',
    '&.MuiButton-root': {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      ...theme.typography.subtitle2,
      lineHeight: '16px',
      alignItems: 'center',
      textAlign: 'center',
    },
    border: '1px solid #D9D9D9',
    boxSizing: 'border-box',
    '&.Mui-disabled': {
      color: '#ccc',
      borderColor: '#ccc',
    },
    '&.Mui-disabled svg path': {
      fill: '#ccc',
    },
    '&.terminated': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  iconControlButton: {
    marginRight: theme.spacing(0.5),
  },
  buttonsBlock: {
    width: '100%',
    marginTop: theme.spacing(2),
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      '& .MuiGrid-item': {
        border: '1px solid #F5F5F5',
        background: '#F5F5F5',
        padding: 0,
      },
      '& .Mui-disabled': {
        background: '#F5F5F5',
      },
    },
  },
  blockItem: {
    width: '100%',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      width: '352px',
      marginTop: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  innerWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  textWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    width: '32px',
  },
  text: {
    marginLeft: theme.spacing(3),
    lineHeight: '1rem',
    textAlign: 'left',
    '&.terminated': {
      color: '#999999',
    },
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    lineHeight: '2rem',
    '&.terminated': {
      color: '#999999',
    },
  },
  itemButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    width: '100%',
    '& svg': {
      marginRight: theme.spacing(0.2),
    },
    '&.MuiButton-root': {
      minWidth: '5px',
      minHeight: '5px',
    },
    '&.MuiButton-disableElevation.Mui-disabled': {
      backgroundColor: '#F5F5F5',
    },
    '&.terminated': {
      '& svg path': {
        opacity: 0.5,
      },
    },
    '& .MuiButton-label': {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      background: '#ffffff',
      '&.MuiButton-disableElevation.Mui-disabled': {
        backgroundColor: '#ffffff',
      },
    },
  },
  headerModal: {
    padding: '24px 0 24px 0',
  },
  headerModalControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}))

export const CardDetailsPage: FC<{
  selectedCardData: CardType
  closeModal?: () => void
  setOpenLimits: (value: boolean) => void
  openLimits: boolean
  setAlertData: React.Dispatch<React.SetStateAction<AlertDataType | undefined>>
  setIsOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  closeModal,
  selectedCardData,
  setOpenLimits,
  openLimits,
  setIsOpenAlert,
  setAlertData,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const {
    id,
    alias,
    cardHolder,
    apiMaskedCardNumber,
    expiryDate,
    status,
    isCard3dSecureActivated,
    account,
  } = selectedCardData
  const [openWallet, setOpenWallet] = useState<string>('')
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false)

  const user = useCurrentUser()
  const [open2FADetails, setOpen2FADetails] = useState(false)
  const [cardNumber, setCardNumber] = useState<string | undefined | null>(undefined)
  const [cardCvv, setCardCvv] = useState<string | undefined | null>(undefined)
  const [isDetailsView, setDetailsView] = useState<boolean>(false)
  const { cardPending, cardInactive, cardClosed, cardCreated } = gerCardStatus(
    status,
    isCard3dSecureActivated,
  )
  const isOwner = cardHolder?.person?.id === user?.id

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { userRights } = useDetermineUserRights(applicationId as string | number)

  const disableViewTransactions = useMemo(() => {
    return cardPending || cardInactive || cardCreated || !selectedCardData.apiMaskedCardNumber
  }, [cardPending, cardInactive, selectedCardData.apiMaskedCardNumber])

  const { setPageFilters: trxSetPageFilters } = usePageFiltersSorting(trxPageStateVar)

  const onTransactions = useCallback(
    (id) => {
      account?.id && localStorage.setItem('selectedAccountId', account.id.toString())
      trxSetPageFilters({ cardId: id })
      history.push(
        generatePath(APP_PATHS.dashboard.transactions, {
          [PATH_PARAMS.applicationId]: applicationId,
        }),
        { page: 1, cardId: id },
      )
    },
    [applicationId, history, account],
  )

  const handleDetailsView = useCallback(() => {
    if (!isDetailsView) {
      return setOpen2FADetails(true)
    } else {
      setCardNumber(undefined)
      setCardCvv(undefined)
      setIsOpenAlert(false)
      setDetailsView(false)
    }
    setDetailsView(!isDetailsView)
  }, [isDetailsView, setCardNumber, setCardCvv])

  const copyCardNumber = useCallback(() => {
    if (isDetailsView && cardNumber) {
      copyToClipboard(cardNumber)
      setAlertData({
        text: t('cardNumberCopied'),
        type: 'success',
        icon: <SuccessIcon />,
      })
      setIsOpenAlert(true)
    }
  }, [isDetailsView, cardNumber, t])

  const copyCardCVV = useCallback(() => {
    if (isDetailsView && cardCvv) {
      copyToClipboard(cardCvv)
      setAlertData({
        text: t('copiedCVVclipboard'),
        type: 'success',
        icon: <SuccessIcon />,
      })
      setIsOpenAlert(true)
    }
  }, [cardCvv, isDetailsView, t])

  useEffect(() => {
    if (cardNumber) {
      setDetailsView(true)
    } else {
      setDetailsView(false)
    }
  }, [cardNumber])

  useEffect(() => {
    return () => {
      setCardCvv(undefined)
      setCardNumber(undefined)
      setIsOpenAlert(false)
      setDetailsView(false)
    }
  }, [selectedCardData.id])

  return (
    <>
      <Grid container item xs={12} className={classes.details}>
        <Box className={classes.wrap}>
          <Hidden lgUp>
            <Grid item xs={12} className={classes.headerModal}>
              <Grid className={classes.headerModalControl}>
                <Typography variant={'h5'}>{t('cardOverview', 'Card overview')}</Typography>
                <Fab
                  color="default"
                  aria-label="close"
                  className={classes.btnCloseModal}
                  onClick={closeModal}
                >
                  <CloseIcon />
                </Fab>
              </Grid>
            </Grid>
          </Hidden>
          <Grid container alignItems="center" wrap="nowrap" className={classes.card}>
            <Grid item className={classes.logo}>
              <FlagIcon />
            </Grid>
            <Grid item className={classes.cardNumber}>
              {isDetailsView &&
                cardNumber?.match(REGEXP_CARD_NUMBER)?.map((item, index) => {
                  return (
                    <Typography variant={'h2'} key={index}>
                      {item}
                    </Typography>
                  )
                })}

              {!isDetailsView && (
                <>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  <Typography variant={'h2'}>{'* * * *'}</Typography>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  <Typography variant={'h2'}>{'* * * *'}</Typography>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  <Typography variant={'h2'}>{'* * * *'}</Typography>
                  <Typography variant={'h2'}>
                    {apiMaskedCardNumber ? apiMaskedCardNumber.slice(-4) : '* * * *'}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item className={classes.expiryDateCvv}>
              <Typography className={classes.expiryDate} variant={'h4'}>
                {expiryDate && expiryDate ? format(new Date(expiryDate), 'MM/yy') : '* * / * *'}
              </Typography>
              <Typography className={classes.cvv} variant={'h4'}>
                {cardCvv ? String(cardCvv) : '* * *'}
              </Typography>
            </Grid>
            <Grid item className={classes.ownerVisa}>
              <Typography className={classes.owner} variant={'h4'}>
                {`${cardHolder?.person?.firstName} ${cardHolder?.person?.lastName}`}
              </Typography>
              <Box className={classes.visa}>
                <Visa />
              </Box>
              {/*//todo create for not just VISA*/}
            </Grid>
          </Grid>
          {isOwner && (
            <>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                className={classes.buttonsBlock}
              >
                <Grid item>
                  <Button
                    disabled={cardPending || !selectedCardData.apiMaskedCardNumber}
                    className={clsx(classes.controlButton, cardPending && 'terminated')}
                    variant="contained"
                    disableElevation
                    color="default"
                    onClick={handleDetailsView}
                  >
                    <ViewDetails className={classes.iconControlButton} />

                    {isDetailsView ? t('hideDetails') : t('viewDetails')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.controlButton}
                    variant="contained"
                    disableElevation
                    color="default"
                    onClick={copyCardNumber}
                    disabled={!isDetailsView || cardPending}
                  >
                    <CopySvg className={classes.iconControlButton} />
                    {t('copyNumber')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.controlButton}
                    variant="contained"
                    disableElevation
                    color="default"
                    onClick={copyCardCVV}
                    disabled={!isDetailsView || cardPending}
                  >
                    <CopySvg className={classes.iconControlButton} />
                    {t('copyCvv', 'Copy CVV')}
                  </Button>
                </Grid>
              </Grid>
              <WalletButtons
                cardPending={cardPending}
                cardClosed={cardClosed}
                setOpenWallet={setOpenWallet}
              />
            </>
          )}
          <Grid container alignItems="center" wrap="nowrap" className={classes.blockItem}>
            <FreezeCardButton
              cardId={id}
              cardStatus={status}
              isCard3dSecureActivated={isCard3dSecureActivated}
              setAlertData={setAlertData}
              setIsOpenAlert={setIsOpenAlert}
            />
          </Grid>
          {userRights?.limitedAccessRight === LimitedAccessRight?.CardHolder ? null : (
            <Grid container alignItems="center" wrap="nowrap" className={classes.blockItem}>
              <Button
                disabled={cardClosed || cardPending}
                className={clsx(classes.itemButton, (cardClosed || cardPending) && 'terminated')}
                variant="contained"
                disableElevation
                color="default"
                onClick={() => {
                  setOpenLimits(true)
                }}
              >
                <Grid className={classes.innerWrap}>
                  <Grid item className={classes.icon}>
                    <SetSpendingLimit />
                  </Grid>
                  <Grid item className={classes.textWrap}>
                    <Typography
                      variant={'h4'}
                      className={clsx(
                        classes.subtitle,
                        (cardClosed || cardPending) && 'terminated',
                      )}
                    >
                      {t('cardSettings', 'Card settings')}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      className={clsx(classes.text, (cardClosed || cardPending) && 'terminated')}
                    >
                      {t(
                        'changeSpendingLimitsRenameOrTerminate',
                        'Change spending limits, rename or terminate',
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          )}
          <Grid container alignItems="center" wrap="nowrap" className={classes.blockItem}>
            <Button
              disabled={!isOwner || cardPending || cardInactive || cardClosed || cardCreated}
              className={clsx(
                classes.itemButton,
                (!isOwner || cardPending || cardInactive || cardClosed || cardCreated) &&
                  'terminated',
              )}
              variant="contained"
              disableElevation
              color="default"
              onClick={() => {
                setOpenChangePassword(true)
              }}
            >
              <Grid className={classes.innerWrap}>
                <Grid item className={classes.icon}>
                  <ChangePassword />
                </Grid>
                <Grid item className={classes.textWrap}>
                  <Typography
                    variant={'h4'}
                    className={clsx(
                      classes.subtitle,
                      (cardPending || cardInactive || cardClosed) && 'terminated',
                    )}
                  >
                    {t('changePassword', 'Change password')}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    className={clsx(
                      classes.text,
                      (cardPending || cardInactive || cardClosed) && 'terminated',
                    )}
                  >
                    {t('verificationRequired', 'Verification required')}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid container alignItems="center" wrap="nowrap" className={classes.blockItem}>
            <Button
              disabled={disableViewTransactions}
              className={clsx(classes.itemButton, disableViewTransactions && 'terminated')}
              variant="contained"
              disableElevation
              color="default"
              onClick={() => onTransactions(id)}
            >
              <Grid className={classes.innerWrap}>
                <Grid item className={classes.icon}>
                  <ViewTransactions />
                </Grid>
                <Grid item className={classes.textWrap}>
                  <Typography
                    variant={'h4'}
                    className={clsx(classes.subtitle, disableViewTransactions && 'terminated')}
                  >
                    {t('viewTransactions', 'View transactions')}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    className={clsx(classes.text, disableViewTransactions && 'terminated')}
                  >
                    {t('allTransactionsMadeFromTheCard', 'All transactions made from the card')}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Box>
      </Grid>

      <AddWalletCardModal
        isOpen={openWallet}
        closeModal={() => setOpenWallet('')}
        phone={cardHolder?.person?.phone}
      />
      <SettingsCardModal
        isOpen={openLimits}
        closeModal={() => setOpenLimits(false)}
        setIsOpenAlert={setIsOpenAlert}
        setAlertData={setAlertData}
        cardId={id}
        alias={alias ?? ''}
        status={status}
      />
      <ChangeCardPassword
        isOpenModal={openChangePassword}
        closeModal={() => setOpenChangePassword(false)}
        cardId={id}
      />
      <CardDetailsActions2FAModal
        open={open2FADetails}
        handleClose={setOpen2FADetails}
        cardId={id}
        cardName={!!alias ? alias : ''}
        setIsOpenAlert={setIsOpenAlert}
        setAlertData={setAlertData}
        setNumber={setCardNumber}
        setCvv={setCardCvv}
      />
    </>
  )
}
