import React, { FC } from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import { FilesForSavingAndShowType } from '../../../types'
import { DocumentUpload } from './DocumentUpload'
import { GridRow } from '../../Common'
import { makeStyles } from '@material-ui/core/styles'

import { FilesListStatic } from './FilesListStatic'
import { Document } from '../../../graphql'

const useStyles = (infoLabel: string) =>
  makeStyles((theme) => ({
    title: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },
    text: {
      margin: infoLabel === ' ' ? theme.spacing(0, 0, 0, 0) : theme.spacing(1.5, 0, 2, 0),
    },
  }))()

export const ProofOfIdentity: FC<{
  poiDocTypesArr: Document[]
  managing?: boolean
  isProfile?: boolean
  nameLabel?: string
  infoLabel?: string
  filesForUploadRegName?: string
  documentTypeRegName?: string
  optionalIdentityList?: { key: string; label: string }[]
  classPut?: string
  isTransaction: boolean
  showTypeErr?: boolean
  setShowTypeErr?: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
}> = ({
  poiDocTypesArr,
  isProfile,
  nameLabel,
  infoLabel,
  managing,
  filesForUploadRegName,
  documentTypeRegName,
  optionalIdentityList,
  classPut,
  isTransaction,
  showTypeErr,
  setShowTypeErr,
  disabled,
}) => {
  const { t } = useTranslation()
  const classes = useStyles(infoLabel ? infoLabel : '')
  //todo rearrange this
  return (
    <Grid>
      <Box>
        <Box className={`${classPut ? classPut : ''}`}>
          {!!infoLabel && !!nameLabel && (
            <Box mt={4}>
              <Typography variant={'h3'} className={classes.title}>
                {nameLabel ? nameLabel : t('proofOfIdentity', 'Proof of identity')}
              </Typography>
              <Typography className={classes.text}>
                {managing
                  ? t(
                      'ownerIdentityLabelManaging',
                      'Please upload a copy of a document to prove the Managing director’s identity.',
                    )
                  : infoLabel
                  ? infoLabel
                  : t(
                      'ownerIdentityLabelControlling',
                      'Please upload a copy of a document to prove the Controlling owner’s identity.',
                    )}
              </Typography>
            </Box>
          )}
          <FilesListStatic
            filesList={map(
              poiDocTypesArr,
              (o) =>
                ({
                  id: o.id,
                  fileName: o.fileName,
                  size: o.size,
                  mimeType: o.mimeType,
                  docType: o.type,
                } as FilesForSavingAndShowType),
            )}
          />
          {isProfile && (
            <DocumentUpload
              filesForUploadRegName={filesForUploadRegName}
              documentTypeRegName={documentTypeRegName}
              optionalIdentityList={optionalIdentityList}
              shoudlValidateDocumentType={false}
              isTransaction={isTransaction}
              showTypeErr={showTypeErr}
              setShowTypeErr={setShowTypeErr}
              disabled={disabled}
            />
          )}
          {!isProfile && (
            <GridRow>
              <DocumentUpload disabled={disabled} isProfile={false} isTransaction={isTransaction} />
            </GridRow>
          )}
        </Box>
      </Box>
    </Grid>
  )
}
