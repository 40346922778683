import { find, isEmpty } from 'lodash'
import * as yup from 'yup'
import { validCountriesKeyList } from '../../components'

import i18n from '../../i18n'
import {
  ALL_ARABIC_REGEXP,
  CYRILIC_REGEXP,
  MIN_LENGTH,
  MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE,
  SPECIAL_SYMBOLS_REGEXP,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'

const countryValidation = () =>
  yup.mixed().when('correspondenceRequired', {
    is: true,
    then: yup
      .mixed()
      .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val))
      .test(
        'dontOfferServicesInThisCountry',
        i18n.t(
          'sorryWeDontOfferServicesInThisCountry',
          'Sorry, we don’t offer services in this country',
        ),
        (val) => !!find(validCountriesKeyList, (item) => item === val),
      ),
  })

export const AddressCheckForbiddenCountriesInputSchema = yup.object().shape({
  country: countryValidation(),
  city: yup
    .string()
    .nullable()
    .required(i18n.t('cityIsRequired', 'City is required'))
    .test(
      'minSymbols',
      MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE(MIN_LENGTH),
      (valueInput) => !!valueInput && valueInput?.length > 1,
    )
    .test('cityInvalid', i18n.t('invalidCity', 'Invalid city'), (inputValue) => {
      const cyrillicValidationRegex = RegExp(CYRILIC_REGEXP)
      const allArabicValidationRegexp = RegExp(ALL_ARABIC_REGEXP)
      const specialSymbols = RegExp(SPECIAL_SYMBOLS_REGEXP)

      let testResult = true
      if (typeof inputValue === 'string') {
        testResult =
          !cyrillicValidationRegex.test(inputValue) &&
          !allArabicValidationRegexp.test(inputValue) &&
          !specialSymbols.test(inputValue)
      }
      return testResult
    }),

  countryCorrespondence: countryValidation(),
})

export type AddressCheckForbiddenCountriesInput = yup.Asserts<
  typeof AddressCheckForbiddenCountriesInputSchema
>
