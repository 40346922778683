import React, { FC } from 'react'
import { Box, Hidden, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { toNumber } from 'lodash'

import { useStyles } from './useStyles'
import { TransferProps } from '../contract'
import { SignaturesTable, SignaturesBox } from './helpers'
import { UploadDocument } from '../UploadDocument'
import { currencyFormat, shortFormatDate, trxStatusNormalize } from '../../../utils'
import { notProcessedPayment } from '../../../utils/Data'
import { TransactionStatus } from '../../../graphql'

export const TransferTab: FC<{ transfer: TransferProps; signedCount: number }> = ({
  transfer,
  signedCount,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { txData } = transfer
  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('transactionStatus', 'Transaction status')}
                </Box>
              </TableCell>
              <TableCell>
                {txData?.status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(txData?.status)}`)}
                  >
                    {t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('paymentNumber', 'Payment number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.referenceNumber}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('account', 'Account')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.accountName}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('amount', 'Amount')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {currencyFormat(toNumber(txData?.amount ?? '0'))}&nbsp;{txData?.currency}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('beneficiaryLabel', 'Beneficiary')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.beneficiary}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('reference', 'Reference')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.comment}</Box>
              </TableCell>
            </TableRow>
            {txData?.additionalComment && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('additionalComment', 'Additional Comment')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">{txData?.additionalComment}</Box>
                </TableCell>
              </TableRow>
            )}

            {!notProcessedPayment.includes(txData?.status as TransactionStatus) && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={clsx(classes.label)}>
                    {t('execution', 'Execution')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span" className={clsx(classes.name)}>
                    {`${txData?.executionType} (${shortFormatDate(
                      new Date(txData?.createdAt as string),
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      },
                      'de-DE',
                    )})`}
                  </Box>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('processing', 'Processing')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span" className={clsx(classes.name)}>
                  {txData?.priority}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200" colSpan={2} className={classes.cell}>
                <Typography variant="h4" className={classes.label}>
                  {t('bankAccountDetails', 'Bank account details')}
                </Typography>
              </TableCell>
            </TableRow>
            {txData?.bank && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('bank', 'Bank')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">{txData?.bank}</Box>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('ibanAccountNumber', 'IBAN / Account number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.iban}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('swift', 'SWIFT')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.swift}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <UploadDocument
          accountId={txData?.accId}
          trxId={txData?.trxId}
          isTransaction={true}
          recipientType={txData?.recipientType}
        />

        <SignaturesTable signatures={transfer.signatures} signedCount={signedCount} />
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
              {txData?.status && (
                <Box component="span">
                  <Box className={clsx(`roundedPill ${trxStatusNormalize(txData.status)}`)}>
                    {t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('paymentNumber', 'Payment number')}</Box>
              <Box className={classes.mobileTableName}>{txData?.referenceNumber}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('account', 'Account')}</Box>
              <Box className={classes.mobileTableName}>{txData?.accountName}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
              <Box className={classes.mobileTableName}>{txData?.amount}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('beneficiaryLabel', 'Beneficiary')}</Box>
              <Box>{txData?.beneficiary}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('reference', 'Reference')}</Box>
              <Box>{txData?.comment}</Box>
            </Box>

            {!notProcessedPayment.includes(txData?.status as TransactionStatus) && (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('execution', 'Execution')}</Box>
                <Box className={clsx(classes.name)}>{`${txData?.executionType} (${shortFormatDate(
                  new Date(txData?.createdAt as string),
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  },
                  'de-DE',
                )})`}</Box>
              </Box>
            )}

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('processing', 'Processing')}</Box>
              <Box className={clsx(classes.name)}>{txData?.priority}</Box>
            </Box>

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
                  {t('bankAccountDetails', 'Bank account details')}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('bank', 'Bank')}</Box>
              <Box>{txData?.bank}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('iban', 'IBAN')}</Box>
              <Box>{txData?.iban}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('swift', 'SWIFT')}</Box>
              <Box>{txData?.swift}</Box>
            </Box>

            <UploadDocument
              accountId={txData?.accId}
              trxId={txData?.trxId}
              isTransaction={true}
              recipientType={txData?.recipientType}
            />

            <SignaturesBox signatures={transfer.signatures} signedCount={signedCount} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
