import React, { FC } from 'react'
import { NumericFormat } from 'react-number-format'
import { Controller, useFormContext } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  ClickAwayListener,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useControlledTooltip } from '../../hooks/useContolledTooltip'

import { CardsTypesVolumes } from '../../types'
import { cardsList } from '../NewBusinessApp/PreAssessmentQuestionnaire/Tabs/CardsVolumes'
import { isEmpty, isNumber } from 'lodash'

const useStyles = makeStyles((theme) => ({
  fieldWrap: { display: 'flex', alignItems: 'center' },
  prefix: { marginRight: theme.spacing(1) },
  volumeFormatted: {
    '& div': {
      overflow: 'hidden',
    },
    '& .MuiInput-underline::before, .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
      borderBottom: 'none',
    },
  },
  asterisk: {
    color: 'orange',
    marginRight: theme.spacing(1),
  },
}))

export const CardVolumeFormattedValue: FC<{
  card: string
  type: CardsTypesVolumes
  prefix: string
  newValue?: number | null
  isViewOnly?: boolean
}> = ({ card, type, prefix, newValue, isViewOnly = false }) => {
  const methods = useFormContext()
  const { setValue, watch } = methods
  const classes = useStyles()

  const { tooltipListeners, tooltipOpen, handleTooltipClose } = useControlledTooltip()

  const parseValue = (val: string | number) => {
    if ((typeof val === 'string' && val !== '') || typeof val === 'number') {
      return parseFloat(val?.toString()?.replaceAll(',', ''))
    }
    return isEmpty(val) ? 0 : val
  }
  const visaValues = watch('volumesVisa')
  const masterValues = watch('volumesMaster')

  const formValue = card === cardsList[0] ? visaValues[type] : masterValues[type]

  return (
    <FormControl
      onBlur={(event) => {
        const inpValue = parseValue((event.target as HTMLInputElement).value)
        card === cardsList[0]
          ? setValue('volumesVisa', { ...visaValues, [type]: inpValue })
          : setValue('volumesMaster', { ...masterValues, [type]: inpValue })
      }}
    >
      <Box className={classes.fieldWrap}>
        {isNumber(newValue) && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              title={`${prefix} ${newValue}`}
              open={tooltipOpen}
              disableFocusListener
              disableTouchListener
              disableHoverListener
              arrow
              {...tooltipListeners}
            >
              <Typography variant="h4" className={classes.asterisk}>
                *
              </Typography>
            </Tooltip>
          </ClickAwayListener>
        )}
        {prefix && <Box className={classes.prefix}>{prefix}</Box>}
        <Controller
          as={
            <NumericFormat
              customInput={TextField}
              inputProps={{
                step: '0.01',
                style: {
                  padding: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
              fullWidth={true}
              disabled={isViewOnly}
            />
          }
          id={`${card + type}`}
          control={methods.control}
          name={`${card + type}`}
          defaultValue={formValue ?? 0}
          className={classes.volumeFormatted}
          style={{ overflow: 'hidden' }}
          thousandSeparator=","
          fixedDecimalScale={true}
          fullWidth={true}
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros={false}
        />
      </Box>
    </FormControl>
  )
}
