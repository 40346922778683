import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { generatePath } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Grid, Box, makeStyles, Typography, Button } from '@material-ui/core'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import {
  ContactSupportLink,
  ContainerWithImage,
  Logo,
  ResendButton,
  CommonTipItem,
} from '../Common'
import { ReactComponent as IconAttention } from '../../assets/images/icons/attention.svg'
import { rxiosLogin } from '../../resources'
import { resendDelay } from '../../graphql/local'
import FACTOR_IMAGE from '../../assets/images/img/2fauth_img.jpg'
import { Response2FA_type } from '../../types'
import { InputCodeField } from '../Common/InputCode'
import { checkFullCode } from '../../utils'
import { TwoFaCodeDispatchMode, TwoFaMethod } from '../../graphql'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  info: {
    border: '1px solid #FED7D2',
    '&>div': {
      padding: '5px 10px',
    },
  },
  text: {
    letterSpacing: '-0.021rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      letterSpacing: '-0.01rem',
    },
  },
  mrgTop: {
    textAlign: 'center',
    marginTop: 28,
    display: 'flex',
    justifyContent: 'center',
  },
  btn_resend: {
    fontSize: theme.spacing(2.25),
    height: 47.5,
    maxWidth: 264,
    backgroundColor: 'black',
    color: 'white',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  wrapper: {
    position: 'relative',
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
      position: 'absolute',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
}))

const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }

export const AuthPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [error, setError] = useState(false)
  const [code, setCode] = useState(initialValue)
  const [attemptsLeft, setAttemptsLeft] = useState(6)
  const [btnDisable, setBtnDisable] = useState(true)
  const [resentViaCall, setResentViaCall] = useState(false)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassage, setValidMassage] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<string>('')
  const {
    [PATH_PARAMS.twoFaType]: twoFaType,
    [PATH_PARAMS.phoneLastDigits]: phoneLastDigits,
  } = useParams() as Record<string, string>
  const inviteContract = (history.location?.state as { inviteContract: string })?.inviteContract

  const startInput = useRef<HTMLInputElement>(null)
  const resultCheckFullCode = checkFullCode(code)

  const onReSend = useCallback(
    (mode: TwoFaCodeDispatchMode = TwoFaCodeDispatchMode.Sms) => {
      setBtnDisable(true)
      setSecondsLeft(millisecondsToSeconds(resendDelay()))
      setError(false)
      setValidMassage('')
      setAlertMessage('')

      if (error) setError(false)
      setResentViaCall(mode === TwoFaCodeDispatchMode.TextToSpeech)
      rxiosLogin
        .post('resend-2fa', {
          challengeId: sessionStorage.getItem('challengeId'),
          mode,
        })
        .subscribe(
          (next) => {
            const { resendTimeout, attemptsLeft } = next as Response2FA_type
            setSecondsLeft(millisecondsToSeconds(resendDelay(resendTimeout * 1000)))
            setAttemptsLeft(attemptsLeft)
          },
          (err) => {
            toast.error((err as Error).message)
          },
        )
    },
    [setAttemptsLeft, error],
  )

  const sendCode = useCallback(async () => {
    const smsCode = Object.values(code).join('')
    const payLoad = {
      code: smsCode,
      challengeId: sessionStorage.getItem('challengeId'),
      twoFaType: twoFaType,
    }
    rxiosLogin.post('verify-2fa', payLoad).subscribe(
      () => {
        // console.log('login success')
      },
      (error) => {
        setError(true)
        //setCode(initialValue)
        if (error.response?.data?.code === 'ACCOUNT_TEMPORARILY_LOCKED') {
          setAlertMessage(
            t(
              'accountTemporarilyLocked',
              'Account temporarily locked due to too many failed 2FA attempts, please retry in {{minutes}} minutes',
              { minutes: Math.ceil(error.response?.data.retryIn / 60000) },
            ),
          )
          setValidMassage('')
        } else if (
          error.response?.data?.tokenExpired ||
          error.response?.data?.code === 'EXPIRED_2FA'
        ) {
          setValidMassage(t('codeHasExpired', 'Code has expired'))
          setAlertMessage('')
        } else {
          setValidMassage(t('codeIncorrect', 'Code is incorrect'))
          setAlertMessage('')
        }
        if (null !== startInput.current) {
          startInput.current.focus()
        }
      },
      () => {
        sessionStorage.removeItem('challengeId')
        setError(false)
        setValidMassage(t('codeCorrect', 'Code is correct'))

        setTimeout(() => {
          history.push(APP_PATHS.root)
          if (!!inviteContract) {
            history.push(
              generatePath(APP_PATHS.application.onboarding.start, {
                [PATH_PARAMS.applicationId]: inviteContract as string,
              }),
            )
          } else {
            history.push(APP_PATHS.root)
          }
        }, 2000)
      },
    )
  }, [code, inviteContract, history])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  useEffect(() => {
    if (code.d6) {
      const input = document.getElementById('d6')
      input !== null && input.blur()
      sendCode()
    }
  }, [code])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMassage('')
      setError(false)
    }
  }, [resultCheckFullCode])

  const confirmSignatureMessage = useMemo(() => {
    return twoFaType === TwoFaMethod.Sms ? (
      <p className={classes.text}>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>{phoneLastDigits}</b>. {t('pleaseTypeItBelow.', 'Please type it below.')}
      </p>
    ) : (
      <p className={classes.text}>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </p>
    )
  }, [phoneLastDigits, twoFaType])

  const modalFooter = useMemo(() => {
    const showCallOption = !resentViaCall && secondsLeft !== 0 && secondsLeft <= 120
    if (twoFaType === TwoFaMethod.Totp) {
      return null
    } else if (attemptsLeft < 1) {
      return (
        <ContactSupportLink homeToLink={true}>
          <Button
            className={classes.btn_resend}
            variant="contained"
            disableElevation
            color="primary"
          >
            {t('contactSupport', 'Contact support')}
          </Button>
        </ContactSupportLink>
      )
    } else {
      return (
        <Grid container spacing={1} direction="column">
          <Grid item>
            <ResendButton
              className={classes.btn_resend}
              fullWidth
              size="large"
              variant="contained"
              disableElevation={true}
              disabled={btnDisable}
              onClick={() => onReSend()}
              secondsLeft={secondsLeft}
              data-test="resendBtn"
            >
              {t('resendCode', 'Resend code')}
            </ResendButton>
          </Grid>

          {showCallOption && (
            <Grid item>
              <Button
                variant="text"
                size="small"
                onClick={() => onReSend(TwoFaCodeDispatchMode.TextToSpeech)}
              >
                {t('resendCodeByCall', "SMS didn't arrive? Click to get a call instead.")}
              </Button>
            </Grid>
          )}
        </Grid>
      )
    }
  }, [twoFaType, attemptsLeft, btnDisable, onReSend, secondsLeft])

  return (
    <ContainerWithImage
      image={config.images.twoFA || FACTOR_IMAGE}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid
        item
        xs={12}
        className={clsx(
          classes.wrapper,
          resultCheckFullCode && error && !isEmpty(validMassage) && 'error',
          validMassage && !error && 'success',
        )}
      >
        <Grid item md={12} className="logo hiddenMdUp">
          <a href={APP_PATHS.root}>
            <Logo />
          </a>
        </Grid>
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography variant={'h2'} data-test="verifyIdentity">
            {t('verifyYourIdentity', 'Please verify your identity')}
          </Typography>
        </Box>
        <Grid item xs={12}>
          {confirmSignatureMessage}
        </Grid>
        {!isEmpty(alertMessage) && (
          <Grid item xs={12} className={classes.info}>
            <CommonTipItem value={alertMessage} iconComponent={<IconAttention />} />
          </Grid>
        )}
        <InputCodeField
          startInput={startInput}
          currencyValue={code}
          setCode={handleSetCode}
          loading={false}
          className="digits"
        />
        <Grid container justifyContent="center" className="infoText">
          {resultCheckFullCode && !!validMassage && <span>{validMassage}</span>}
        </Grid>
        <Grid item xs={12} className={classes.mrgTop}>
          {modalFooter}
        </Grid>
      </Grid>
    </ContainerWithImage>
  )
}

export default AuthPage
