import * as yup from 'yup'

import {
  AdditionalDetailsOptionalInputSchema,
  AddressInputSchema,
  BirthdayInputSchema,
  NameInputSchema,
  NationalityInputSchema,
  PostalCodeInputSchema,
  RegistryNumberInputSchema,
  StreetAddressInputSchema,
} from './common'
import { CompanyNameInputSchema } from './common/CompanyName'
import { RegistrationDateInputSchema } from './common/RegistrationDate'

export const AddVbanSchema = yup
  .object()
  .concat(NameInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(AdditionalDetailsOptionalInputSchema)

export const AddCompanyVbanSchema = yup
  .object()
  .concat(CompanyNameInputSchema)
  .concat(RegistryNumberInputSchema)
  .concat(RegistrationDateInputSchema)
  .concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(AdditionalDetailsOptionalInputSchema)

export type addVbanShemaInput = yup.Asserts<typeof AddVbanSchema>
