import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Checkbox,
  FormControlLabel,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { ReactComponent as Unchecked } from '../../../assets/images/icons/unchecked_icon.svg'
import { ReactComponent as Checked } from '../../../assets/images/icons/cheked_icon.svg'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { actionsPageStateValue } from '../../../graphql/local'
import { PATH_PARAMS } from '../../../routes/paths'
import { ActionsListRow } from './ActionsListRow'
import { ActionsMobile } from './ActionsMobile'
import { ActionsTablet } from './ActionsTablet'
import {
  Action,
  ActionsOrderBy,
  ActionStatus,
  Individual,
  OrderDirection,
  useGetUserDataQuery,
} from '../../../graphql'
import { Loader, ColumnNameWithSort } from '../../../components/Common'
import { useDetermineUserRights, usePageFiltersSorting } from '../../../hooks'
import { ActionsPageStateType, OperationNames } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    borderTop: '1px solid rgba(0,0,0,.1)',
  },
  mobileTable: {
    borderBottom: '1px solid rgba(0,0,0,.1)',
    '&.bordered': {
      border: '1px solid rgba(0,0,0,.1)',
      borderBottom: 0,
    },
  },
  mobileCheckAll: {
    paddingLeft: 11,
    position: 'relative',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50px',
      transform: 'translateY(-50%)',
    },
    '& .MuiCheckbox-indeterminate svg': {
      marginLeft: -2,
    },
    '& .MuiCheckbox-root:not(.Mui-checked) .MuiIconButton-label svg > path': {
      fill: '#000',
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
  sortTransfer: {
    width: '100%',
    justifyContent: 'space-between',
  },
}))

const ActionsListTabComponent: React.FC<{
  actionsList: Action[]
  handleActionsForSign: (actionData: Action, check: boolean) => void
  setActionData: React.Dispatch<React.SetStateAction<Action | undefined>>
  setActionModal: React.Dispatch<React.SetStateAction<OperationNames | null>>
  onChecked: () => void
  isPendingActions: boolean
  checkedActions: Action[]
  pendingActionsCount: number
}> = ({
  actionsList,
  handleActionsForSign,
  setActionData,
  setActionModal,
  onChecked,
  isPendingActions,
  checkedActions,
  pendingActionsCount,
}) => {
  const [user, setUser] = useState<Individual | undefined>()
  const { data: userData, loading: userLoading, error: userError } = useGetUserDataQuery()
  useEffect(() => {
    !userLoading && !userError && setUser(userData?.viewer as Individual)
  }, [userData?.viewer, userError, userLoading])

  const { pageFilters, setPageFilters } = usePageFiltersSorting(actionsPageStateValue)
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isLimitedAccessUser = !!userRights?.limitedAccessRight

  const sortedData = useMemo(() => {
    if (actionsList.some((action) => action.status === ActionStatus.PendingSignatures)) {
      return [...actionsList].sort((a, b) => {
        if (
          a?.status === ActionStatus.PendingSignatures &&
          b?.status !== ActionStatus.PendingSignatures
        ) {
          return -1
        }
        if (
          a?.status !== ActionStatus.PendingSignatures &&
          b?.status === ActionStatus.PendingSignatures
        ) {
          return 1
        }
        return 0
      })
    }

    return actionsList
  }, [actionsList])

  const handleSign = useCallback(
    (data: Action, operation: OperationNames | null) => {
      setActionData(data)
      setActionModal(operation)
    },
    [setActionData, setActionModal],
  )

  const handleRefetch = useCallback(
    (sortedBy: string) => {
      if (pageFilters.sortBy !== sortedBy) {
        setPageFilters({
          sortBy: sortedBy as ActionsOrderBy,
          direction: OrderDirection.Ascending,
          page: 1,
        })
      } else {
        const direction =
          pageFilters.direction === OrderDirection.Descending
            ? OrderDirection.Ascending
            : OrderDirection.Descending
        setPageFilters({ direction, page: 1 })
      }
    },
    [setPageFilters, pageFilters.direction, pageFilters.sortBy],
  )

  const isCheckedAll = useMemo(() => {
    if (pendingActionsCount === 0) return false

    return checkedActions.length === pendingActionsCount
  }, [pendingActionsCount, checkedActions])

  const checkboxIndeterminateResolver = useMemo(() => {
    if (checkedActions.length === 0) return false

    return checkedActions.length !== pendingActionsCount
  }, [pendingActionsCount, checkedActions])

  if (userLoading) return <Loader />

  return (
    <>
      <Hidden mdDown>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {!isLimitedAccessUser && (
                  <TableCell>
                    <Box data-test="checkbox">
                      <Checkbox
                        checked={isCheckedAll}
                        indeterminate={checkboxIndeterminateResolver}
                        icon={<Unchecked />}
                        checkedIcon={<Checked />}
                        indeterminateIcon={
                          <IndeterminateCheckBoxIcon color="primary" fontSize="small" />
                        }
                        onChange={onChecked}
                        disabled={!isPendingActions}
                        classes={{ disabled: classes.disabledCheckbox }}
                      />
                    </Box>
                  </TableCell>
                )}
                <TableCell data-test="name">{t('name', 'Name')}</TableCell>

                <ColumnNameWithSort
                  name={ActionsOrderBy.Type}
                  handleRefetch={handleRefetch}
                  data-test="type"
                >
                  {t('type', 'Type')}
                </ColumnNameWithSort>

                <TableCell data-test="amount">{t('amount', 'Amount')}</TableCell>

                <ColumnNameWithSort
                  name={ActionsOrderBy.CreatedAt}
                  handleRefetch={handleRefetch}
                  data-test="added"
                >
                  {t('added', 'Added')}
                </ColumnNameWithSort>

                <TableCell data-test="actionTaken">{t('actionTaken', 'Action taken')}</TableCell>

                <ColumnNameWithSort
                  name={ActionsOrderBy.Status}
                  handleRefetch={handleRefetch}
                  data-test="statusActions"
                >
                  {t('statusActions', 'Status/Actions')}
                </ColumnNameWithSort>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData &&
                sortedData.map((value) => {
                  return (
                    <ActionsListRow
                      userData={user}
                      data={value}
                      key={value.id}
                      handleSign={handleSign}
                      handleActionsForSign={handleActionsForSign}
                      filtersState={pageFilters as ActionsPageStateType}
                      checkedActions={checkedActions}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>

      {/* mobile tablet view */}
      <Hidden xsDown lgUp>
        {!isLimitedAccessUser ? (
          <Box className={classes.mobileTableItem}>
            <FormControlLabel
              className={classes.mobileCheckAll}
              control={
                <Checkbox
                  checked={isCheckedAll}
                  indeterminate={checkboxIndeterminateResolver}
                  icon={<Unchecked />}
                  checkedIcon={<Checked />}
                  indeterminateIcon={<IndeterminateCheckBoxIcon color="primary" fontSize="small" />}
                  onChange={onChecked}
                  disabled={!isPendingActions}
                  classes={{ disabled: classes.disabledCheckbox }}
                />
              }
              label={t('selectAll', 'Select all documents')}
            />
          </Box>
        ) : (
          <Box mt={2} />
        )}
        <Box>
          {sortedData &&
            sortedData.map((value) => {
              return (
                <ActionsTablet
                  data={value}
                  key={value.id}
                  handleSign={handleSign}
                  handleActionsForSign={handleActionsForSign}
                  filtersState={pageFilters as ActionsPageStateType}
                  checkedActions={checkedActions}
                />
              )
            })}
        </Box>
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          {!isLimitedAccessUser ? (
            <Box className={classes.mobileTableItem}>
              <FormControlLabel
                className={classes.mobileCheckAll}
                control={
                  <Checkbox
                    checked={isCheckedAll}
                    indeterminate={checkboxIndeterminateResolver}
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    indeterminateIcon={
                      <IndeterminateCheckBoxIcon color="primary" fontSize="small" />
                    }
                    onChange={onChecked}
                    disabled={!isPendingActions}
                    classes={{ disabled: classes.disabledCheckbox }}
                  />
                }
                label={t('selectAll', 'Select all documents')}
              />
            </Box>
          ) : (
            <Box mt={2} />
          )}
          {sortedData &&
            sortedData.map((value) => {
              return (
                <ActionsMobile
                  data={value}
                  key={value.id}
                  handleSign={handleSign}
                  handleActionsForSign={handleActionsForSign}
                  filtersState={pageFilters as ActionsPageStateType}
                  checkedActions={checkedActions}
                />
              )
            })}
        </Box>
      </Hidden>
    </>
  )
}

export const ActionsListTab = React.memo(ActionsListTabComponent, isEqual)
