import * as yup from 'yup'
import i18n from '../../i18n'

export const PrivacyAcceptanceInputSchema = yup.object().shape({
  privacyAcceptance: yup
    .boolean()
    .required(i18n.t('openReasonIsMandatory', 'openReason is mandatory'))
    .oneOf([true]),
})

export type PrivacyAcceptanceInput = yup.Asserts<typeof PrivacyAcceptanceInputSchema>
