import CheckIcon from '@material-ui/icons/Check'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { debounce, isEmpty } from 'lodash'

import {
  makeStyles,
  Grid,
  Fade,
  FormControl,
  Box,
  Fab,
  Typography,
  Collapse,
} from '@material-ui/core'

import {
  ChangePasswordModal,
  CommonTipItem,
  FormPasswordField,
  LoadingButton,
} from '../../../components'
import {
  TwoFaCodeResponse,
  TwoFaMethod,
  useGet2FaCodeChangePasswordMutation,
} from '../../../graphql'
import { ChangePasswordInput, changePasswordSchema } from '../../../schemes'
import { checkPassword, focusKeyPressNext } from '../../../utils'
import { toast } from 'react-toastify'
import { useReactiveVar } from '@apollo/client'
import { cardAlert } from '../../../graphql/local/dashboard'
import { ReactComponent as IconConfirm } from '../../../assets/images/icons/confirmed.svg'
import { ReactComponent as IconAttention } from '../../../assets/images/icons/attention.svg'
import CloseIcon from '@material-ui/icons/Close'
import { useCurrentUser } from '../../../hooks'
import { TOTP_STRING_PARAM } from '../../../constants'

const useStyles = (isErrorCurrentPassword: boolean) =>
  makeStyles((theme) => ({
    formControl: {
      width: '100%',
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        padding: theme.spacing(1.5, 0, 1.5, 1.5),
        lineHeight: '1.1876em',
        display: 'block',
        fontSize: '0.875rem',
      },
      '& .MuiInputLabel-formControl': {
        transform: 'translate(0, 10px) scale(0.857)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 10px) scale(0.857)',
      },
    },
    notAsterisk: {
      '& .MuiFormLabel-asterisk': {
        display: 'none',
      },
    },
    error: {
      color: 'red',
      padding: '0 0 0 12px',
      fontFamily: 'Helvetica Neue, Arial, sans-serif',
      fontSize: '12px',
    },
    form: {
      width: '100%',
    },
    button: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.125rem',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 160,
      },
      height: 48,
    },
    buttonWrap: {
      marginTop: theme.spacing(2),
    },
    alertContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'white',
      padding: '3px 8px',
      width: '381px',
      position: 'fixed',
      top: 8,
      zIndex: 9999,
      left: 0,
      right: 0,
      margin: 'auto',
      border: `1px solid ${isErrorCurrentPassword ? '#ef2828' : '#ADDEC9'}`,
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
      '&>div': {
        padding: 0,
      },
      '& p': {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    btnClose: {
      position: 'absolute',
      top: 6,
      right: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      height: 32,
      width: 32,
      minHeight: 32,
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
      '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
      },
    },
    formInput: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 400,
      },
    },
    successText: {
      color: 'green',
    },
    icon: {
      transform: 'scale(0.85)',
    },
  }))()

export const ChangePasswordForm: FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [phoneLastFourDigits, setPhoneLastFourDigits] = useState<string | null>()
  const [challengeId, setChallengeId] = useState<string | null>()
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const alert = useReactiveVar(cardAlert)
  const currentUser = useCurrentUser()

  const { t } = useTranslation()
  const methods = useForm<ChangePasswordInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(changePasswordSchema),
  })
  const { watch, errors, setError, clearErrors, trigger, reset } = methods

  const password = watch('password')

  const classes = useStyles(!!errors.currentPassword)

  const [get2FaCodeChangePasswordMutation] = useGet2FaCodeChangePasswordMutation()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const setCodeChangePassword = useCallback(
    async (formData) => {
      try {
        const { data } = await get2FaCodeChangePasswordMutation()
        if (data) {
          const { challengeId, phoneLastFourDigits } = data?.get2FACode as TwoFaCodeResponse
          setChallengeId(
            currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? challengeId : TOTP_STRING_PARAM,
          )
          setPhoneLastFourDigits(phoneLastFourDigits)
          setOpen(true)
        }

        setCurrentPassword(formData.currentPassword)
        setNewPassword(formData.password)
        cardAlert(undefined)
        reset()
      } catch (e) {
        toast.error((e as Error).message)
      }
    },
    [get2FaCodeChangePasswordMutation, reset],
  )

  const saveHandler = useCallback(
    (formData) => {
      setCodeChangePassword(formData)
    },
    [setCodeChangePassword],
  )

  const alertDebouncedRef = useRef(
    debounce(() => {
      cardAlert(undefined)
    }, 10000),
  )

  useEffect(
    () => () => {
      alertDebouncedRef.current.cancel()
      cardAlert(undefined)
    },
    [],
  )

  const setIncorrectPasswordErr = useCallback(
    (errMessage: string) => {
      setError('currentPassword', {
        type: 'manual',
        message: errMessage,
      })
    },
    [setError],
  )

  const handleChangeCurrentPass = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      trigger(`currentPassword`).then()
      cardAlert(undefined)
      checkPassword(e, setError, clearErrors)
    },
    [trigger, cardAlert, checkPassword, setError, clearErrors],
  )

  const handleCheckPass = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkPassword(e, setError, clearErrors)

    if (
      (!isEmpty(errors) || methods.formState.touched.repeatPassword) &&
      password &&
      !errors.password
    ) {
      trigger(['password', 'repeatPassword']).then()
    }
  }

  return (
    <>
      <ChangePasswordModal
        open={open}
        handleClose={handleClose}
        phoneLastFourDigits={phoneLastFourDigits}
        challengeId={challengeId}
        currentPassword={currentPassword}
        newPassword={newPassword}
        setIncorrectPasswordErr={setIncorrectPasswordErr}
        alertDebounce={alertDebouncedRef}
      />

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(saveHandler)}
          className={classes.form}
          id="forNextFocus"
          onKeyDown={focusKeyPressNext}
        >
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid item xs={12} className={classes.formInput}>
                <FormControl className={classes.formControl}>
                  <FormPasswordField
                    label={t('currentPassword', 'Current password')}
                    className={classes.notAsterisk}
                    name="currentPassword"
                    fullWidth
                    onChange={handleChangeCurrentPass}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.formInput}>
                <FormControl className={classes.formControl}>
                  <FormPasswordField
                    label={t('newPassword', 'New password')}
                    className={classes.notAsterisk}
                    name="password"
                    fullWidth
                    onChange={handleCheckPass}
                  />
                  <Collapse in={Boolean(password && !errors.password)}>
                    <Box display="flex">
                      <Typography className={classes.successText}>
                        {t('passwordAccepted', 'Password Accepted')}
                      </Typography>
                      <CheckIcon className={classes.icon} />
                    </Box>
                  </Collapse>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.formInput}>
                <FormControl className={classes.formControl}>
                  <FormPasswordField
                    label={t('repeatNewPassword', 'Repeat new password')}
                    className={classes.notAsterisk}
                    name="repeatPassword"
                    fullWidth
                    onChange={() => {
                      methods.trigger(`repeatPassword`).then()
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Fade in={methods.formState.isValid}>
              <Box className={classes.buttonWrap}>
                <LoadingButton
                  className={classes.button}
                  loading={false}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  {t('save', 'Save')}
                </LoadingButton>
              </Box>
            </Fade>
          </Grid>
        </form>
      </FormProvider>

      {alert && (
        <Box className={classes.alertContainer}>
          <CommonTipItem
            value={alert}
            iconComponent={errors.currentPassword ? <IconAttention /> : <IconConfirm />}
          />
          <Fab
            className={classes.btnClose}
            onClick={() => {
              cardAlert(undefined)
            }}
          >
            <CloseIcon />
          </Fab>
        </Box>
      )}
    </>
  )
}
