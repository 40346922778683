import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'

import { ReactComponent as IconAttention } from '../../assets/images/icons/attention.svg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import {
  CommonTipItem,
  ContactSupportLink,
  ContainerWithImage,
  Logo,
  ResendButton,
} from '../Common'
import { rxios } from '../../resources'
import { millisecondsToSeconds } from 'date-fns'
import { resendDelay } from '../../graphql/local'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { useWebApi } from '../../hooks'
import { EMAIL_REGEXP } from '../../constants/validations'
import config from '../../config'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '14px',
    fontSize: '24px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  mrgTop: {
    marginTop: '24px',
  },
  btn_resend: {
    height: 47.5,
    backgroundColor: 'black',
    color: 'white',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  info: {
    border: '1px solid #FED7D2',
    '&>div': {
      padding: '5px 10px',
    },
  },
  h2contactSupport: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textDecoration: 'revert',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      margin: '0 0 0 5px',
      '& svg': {
        display: 'none',
      },
    },
  },
}))

export const VerificationEmailPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { webApi } = useWebApi()
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [errVerification, setErrVerification] = useState(false)
  const [linkError, setLinkError] = useState(false)
  const [attemptsSend, setAttemptsSend] = useState(1)
  const [decodedEmail, setDecodedEmail] = useState('')

  const { [PATH_PARAMS.token]: tokenParam } = useParams() as { [key: string]: string }
  const token = decodeURIComponent(tokenParam)

  const onReSend = useCallback(() => {
    setBtnDisable(true)
    setSecondsLeft(millisecondsToSeconds(resendDelay()))
    setAttemptsSend((prev) => prev + 1)

    type Response = {
      resendTimeout: number
    }

    rxios.post('resend-email-confirmation', { email: token }).subscribe(
      (next: Response | unknown) => {
        resendDelay((next as Response).resendTimeout * 1000)
      },
      (err) => {
        toast.error((err as Error).message)
      },
    )
  }, [setAttemptsSend])

  const onChangeEmail = useCallback(() => {
    webApi?.logout()
    history.push(APP_PATHS.signup)
  }, [])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (token) {
      if (EMAIL_REGEXP.test(token)) {
        setDecodedEmail(token)
      } else {
        try {
          //getting the email coded in token in format - `randomstring::email`
          const email = atob(token).slice(atob(token).lastIndexOf(':') + 1)

          if (EMAIL_REGEXP.test(email)) {
            setDecodedEmail(email)
            rxios.post('confirm-email', { token }).subscribe(
              () => {
                sessionStorage.setItem('verifyToken', `${token}`)
              },
              () => {
                setErrVerification(true)
              },
              () => {
                history.push(APP_PATHS.auth.login)
              },
            )
          } else {
            setLinkError(true)
          }
        } catch (e) {
          setLinkError(true)
        }
      }
    }
  }, [token])

  useEffect(() => {
    errVerification && setBtnDisable(false)
  }, [errVerification])

  return (
    <ContainerWithImage
      image={config.images.emailVerification}
      reverse={true}
      isRightMenu={false}
      classWrapper="content"
    >
      <Grid container direction={'row'}>
        <Grid item xs>
          <Grid item xs={12} className="logo hiddenMdUp">
            <a href={APP_PATHS.root}>
              <Logo />
            </a>
          </Grid>

          <Grid item xs={12}>
            <Typography variant={'h2'} className={classes.header}>
              {!errVerification && !linkError ? (
                <b data-test="verifyYourEmail">{t('verifyYourEmail', 'Verify your email')}</b>
              ) : (
                <b>{t('verificationFailed', 'Verification failed')}</b>
              )}
            </Typography>

            {linkError ? (
              <Grid item xs={12} className={classes.info}>
                <CommonTipItem
                  value={t(
                    'theLinkYouUsedForVerificationIsIncorrectPleaseDoubleCheckIt',
                    'The link you used for verification is incorrect. Please double-check it.',
                  )}
                  iconComponent={<IconAttention />}
                />
              </Grid>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.text}>
                    <Typography>
                      {!errVerification
                        ? t(
                            'weHaveSentYouAnEmail',
                            'We have sent you a verification email message to:',
                          )
                        : t(
                            'weFailedToVerifyTheFollowingEmailAddress',
                            'We failed to verify the following email address:',
                          )}
                      <br />
                      <b>{decodedEmail}</b>
                    </Typography>
                    {!errVerification ? (
                      <Typography className={classes.mrgTop}>
                        {t(
                          'proceedToYourInboxAndClickOnTheVerificationLinkToLogIn',
                          'Please proceed to your inbox and click on the verification link to log in. Make sure to check your spam folder as well.',
                        )}
                      </Typography>
                    ) : (
                      <Typography className={classes.mrgTop}>
                        {t(
                          'proceedToOneOfThePossibleOptions',
                          'Please proceed to one of the possible options',
                        )}
                        :
                      </Typography>
                    )}

                    {!errVerification ? (
                      <Typography className={classes.mrgTop}>
                        {t('didntGetAnything', 'Didn`t receive an email')}?
                      </Typography>
                    ) : null}
                    {attemptsSend > 5 ? (
                      <Typography className={clsx(classes.mrgTop, classes.h2contactSupport)}>
                        {t('please', 'Please')}
                        <ContactSupportLink homeToLink={true} />
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.mrgTop}>
                  {attemptsSend > 5 ? null : (
                    <ResendButton
                      className={classes.btn_resend}
                      fullWidth
                      size="large"
                      variant="contained"
                      disableElevation={true}
                      disabled={btnDisable}
                      onClick={onReSend}
                      secondsLeft={secondsLeft}
                      data-test="resendEmailButton"
                    >
                      {t('rsesendEmail', 'Resend email')}
                    </ResendButton>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.mrgTop}>
                  <Button
                    className="signinButton"
                    fullWidth
                    size="large"
                    variant="contained"
                    disableElevation
                    onClick={onChangeEmail}
                    data-test="signupChangeEmailButton"
                  >
                    {t('changeEmail', 'Change email')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ContainerWithImage>
  )
}

export default VerificationEmailPage
