import React, { FC, MouseEvent, useCallback, useMemo } from 'react'
import { Box, Button } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { ActionSignature, ActionSignatureStatus, CardStatuses, CardType } from '../../graphql'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconDot } from '../../assets/images/icons/Dot.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  activationLink: {
    color: '#000',
  },
}))

export const CardStatusResolver: FC<{
  data: CardType
  userId: string | number
  setActionData: React.Dispatch<React.SetStateAction<ActionSignature | undefined>>
  setActionModal: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({ data, userId, setActionData, setActionModal }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { id, status, isCard3dSecureActivated, cardRequest } = data
  const {
    [PATH_PARAMS.applicationId]: contractId,
    [PATH_PARAMS.cardsPageNum]: cardsPage,
  } = useParams() as Record<string, string>

  const actionSignature = cardRequest?.executionAction?.signatures?.find((s) => s?.isMine)

  const onSign = useCallback(
    (action: 'sign' | 'reject', e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setActionModal(action)
      actionSignature && setActionData(actionSignature)
    },
    [actionSignature],
  )

  const specialCaseStatus = useMemo(() => {
    if (
      status === CardStatuses.Active &&
      !isCard3dSecureActivated &&
      userId !== data?.cardHolder?.person?.id
    ) {
      return CardStatuses.Blocked
    }
  }, [status, userId, data?.cardHolder])

  const isUserCardHolder: boolean = userId === data?.cardHolder?.person?.id

  const statusResult = useCallback(() => {
    const activationLink = (
      <NavLink
        className={classes.activationLink}
        to={generatePath(APP_PATHS.dashboard.activateCard, {
          [PATH_PARAMS.applicationId]: contractId,
          [PATH_PARAMS.cardId]: id,
          [PATH_PARAMS.cardsPageNum]: cardsPage,
        })}
      >
        {t('activate', 'Activate')}
      </NavLink>
    )
    switch (status) {
      case CardStatuses.Blocked:
        return t('frozen', 'Frozen')
      case CardStatuses.Closed:
        return t('terminated', 'Terminated')
      case CardStatuses.Active:
        return isCard3dSecureActivated
          ? t('active', 'Active')
          : isUserCardHolder
          ? activationLink
          : t('notActivated', 'Not activated')
      case CardStatuses.Created: {
        return isUserCardHolder ? activationLink : t('notActivated', 'Not activated')
      }
      case CardStatuses.Pending:
        if (actionSignature?.status !== ActionSignatureStatus.Pending) {
          return t('pending', 'Pending')
        }
        return (
          <Box className={classes.buttonsBox}>
            <Button aria-label="sign" type="button" onClick={onSign.bind(null, 'sign')}>
              {t('sign', 'Sign')}
            </Button>
            <IconDot />
            <Button
              color="secondary"
              aria-label="reject"
              type="button"
              onClick={onSign.bind(null, 'reject')}
            >
              {t('reject', 'Reject')}
            </Button>
          </Box>
        )
      default:
        return t(`${status?.toLocaleLowerCase()}`, `${status}`)
    }
  }, [status, actionSignature?.status])

  return (
    <>
      {actionSignature?.status !== ActionSignatureStatus.Pending && (
        <Box
          component={'span'}
          className={`iconCircle ${specialCaseStatus || status?.toLocaleLowerCase()}`}
        >
          <FiberManualRecordIcon />
        </Box>
      )}
      {statusResult()}
    </>
  )
}
