import React, { FC, ReactNode } from 'react'
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { ReactComponent as AlertIcon } from '../../assets/images/icons/icon_attention.svg'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    margin: theme.spacing(2, 0, 3),
    fontSize: '16px',
    border: '1px solid #FFD8B5',
    width: '100%',
    height: 'auto',
    padding: '10px 15px 10px 10px',
  },
  info: {
    paddingLeft: 18,
  },
}))

const WarningBlockComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Grid>
        <AlertIcon />
      </Grid>
      <Grid className={classes.info}>
        <Typography>{children}</Typography>
      </Grid>
    </Box>
  )
}

export const WarningBlock = React.memo(WarningBlockComponent)
