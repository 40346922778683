import React, { FC } from 'react'
import { Box, Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'
import { AccountProps } from '../contract'
import { SignaturesBox, SignaturesTable } from './helpers'
import { addressToString } from '../../../utils'
import { AccountAction, UboType } from '../../../graphql'
import { format } from 'date-fns'

export const VbanTab: FC<{ data: AccountProps; signedCount: number }> = ({ data, signedCount }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const account = (data?.rawData as AccountAction)?.account
  const entityType = account?.virtualDetails?.entityType
  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('vban', 'VBAN')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{data.iban}</Box>
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {entityType === UboType.NaturalPerson
                    ? t('name', 'Name')
                    : t('legalName', 'Legal name')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{data.name}</Box>
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('address', 'Address')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{addressToString(data?.registeredAddress)}</Box>
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {entityType === UboType.NaturalPerson
                    ? t('birthDay', 'Date of birth')
                    : t('dateOfRegistration', 'Date of registration')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span" className={classes.capitalize}>
                  {entityType === UboType.NaturalPerson
                    ? data.dateOfBirth
                    : format(
                        new Date(account?.virtualDetails?.companyRegistrationDate),
                        'MM.dd.yyyy',
                      )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {entityType === UboType.NaturalPerson
                    ? t('nationality', 'Nationality')
                    : t('commercialRegNum', 'Commercial registration number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span" className={classes.capitalize}>
                  {entityType === UboType.NaturalPerson
                    ? data.nationality
                    : account?.virtualDetails?.companyRegistrationNumber}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <SignaturesTable signatures={data.signatures} signedCount={signedCount} />
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={`${classes.mobileTableItem} ${classes.mobileTableBorderTop}`}>
              <Box className={classes.mobileTableLabel}>{t('vban', 'VBAN')}</Box>
              <Box className={classes.mobileTableName}>{data.iban}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {entityType === UboType.NaturalPerson
                  ? t('name', 'Name')
                  : t('legalName', 'Legal name')}
              </Box>
              <Box className={classes.mobileTableName}>{data.name}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('address', 'Address')}</Box>
              <Box className={classes.mobileTableName}>
                {addressToString(data?.registeredAddress)}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {entityType === UboType.NaturalPerson
                  ? t('birthDay', 'Date of birth')
                  : t('dateOfRegistration', 'Date of registration')}
              </Box>
              <Box className={classes.mobileTableName}>
                {entityType === UboType.NaturalPerson
                  ? data.dateOfBirth
                  : format(
                      new Date(account?.virtualDetails?.companyRegistrationDate),
                      'MM.dd.yyyy',
                    )}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {entityType === UboType.NaturalPerson
                  ? t('nationality', 'Nationality')
                  : t('commercialRegNum', 'Commercial registration number')}
              </Box>
              <Box className={`${classes.mobileTableName} ${classes.capitalize}`}>
                {entityType === UboType.NaturalPerson
                  ? data.nationality
                  : account?.virtualDetails?.companyRegistrationNumber}
              </Box>
            </Box>
            <SignaturesBox signatures={data.signatures} signedCount={signedCount} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
