import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'
import { isEmpty, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as InformationIcon } from '../../../assets/images/icons/info_icon.svg'
import { actionsPageStateValue, ActionsSignModal, AlertTipItem, Loader } from '../../../components'
import PagePagination from '../../../components/Common/PagePagination'
import { initialActionsPageState } from '../../../constants'
import {
  Action,
  ActionSignatureStatus,
  ActionsOrderBy,
  ContractStatusType,
  GetActionsQueryVariables,
  LimitedAccessRight,
  useGetActionsCountQuery,
  useGetActionsQuery,
} from '../../../graphql'
import { useLimitedAccessGuard, usePageFiltersSorting } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { OperationNames, StatusSelectorType } from '../../../types'
import { transformActionStatus } from '../../../utils'
import { normalizeTypesForBE } from '../contract'
import { ActionsFilters } from './ActionsFilters'
import { ActionsListTab } from './ActionsListTab'

import EmptyStatePage from './EmptyStatePage'
import { HandleAllActionsButtons } from './HandleAllActionsButtons'

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60,
    },
  },
  header: {
    padding: theme.spacing(10, 0, 3, 0),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 20px 0',
    },
  },
  title: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      minHeight: 48,
    },
  },
  wrapButton: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      width: '184px',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiButtonBase-root': {
        width: '50%',
        borderRadius: 0,
        right: 0,
        bottom: 0,
      },
    },
  },
  tipContainer: {
    margin: theme.spacing(0, 0, 3.5, 0),
  },
  filtersContainer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  marginContent: {
    marginTop: theme.spacing(3),
  },
  internalContainer: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(10),
    },
  },
}))

const ActionsPage: FC = () => {
  const [checkedActions, setCheckedActions] = useState<Action[]>([])
  const [actionData, setActionData] = useState<Action | undefined>()
  const [actionModal, setActionModal] = useState<OperationNames | null>(null)
  const [requestsCount, setRequestsCount] = useState<number>(0)

  const { pageFilters, setPageFilters } = usePageFiltersSorting(actionsPageStateValue)
  const { searchValue, status, type, sortBy, direction, page } = pageFilters

  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const itemsPerPage = 10

  useLimitedAccessGuard(
    LimitedAccessRight.CardHolder,
    LimitedAccessRight.ViewOnly,
    LimitedAccessRight.PaymentFacilitationView,
  )

  const queryParams: GetActionsQueryVariables = {
    contractId: applicationId,
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    orderBy: sortBy as ActionsOrderBy,
    orderDirection: direction,
    pendingFirst: true,
  }

  const updatedQueryParams = { ...queryParams }

  if (searchValue) {
    updatedQueryParams.search = searchValue
  } else {
    delete updatedQueryParams.search
  }

  if (status !== StatusSelectorType.AllStatuses) {
    updatedQueryParams.status = status
  } else {
    delete updatedQueryParams.status
  }

  if (type !== StatusSelectorType.AllTypes) {
    updatedQueryParams.types = normalizeTypesForBE(type)
  } else {
    delete updatedQueryParams.types
  }

  const variablesForActionsCount = useMemo(
    () => ({
      ...omit(updatedQueryParams, ['limit', 'offset', 'orderBy', 'orderDirection', 'pendingFirst']),
    }),
    [updatedQueryParams],
  )

  const { data: actionsData, loading, refetch } = useGetActionsQuery({
    variables: updatedQueryParams,
    fetchPolicy: 'network-only',
  })

  const { data: totalRequests, refetch: refetchActionsCount } = useGetActionsCountQuery({
    variables: variablesForActionsCount,
    skip: !applicationId,
    fetchPolicy: 'network-only',
  })

  const pendingActionsList: Action[] = useMemo(
    () =>
      (actionsData?.actions?.filter(
        (action) => transformActionStatus(action as Action) === ActionSignatureStatus.Pending,
      ) || []) as Action[],
    [actionsData],
  )

  const signDocuments = useMemo(() => {
    return (
      !!actionsData?.actions &&
      requestsCount > 0 &&
      (actionsData?.actions as Action[])[0]?.contract?.status ===
        ContractStatusType.SignaturesPending
    )
  }, [requestsCount, actionsData?.actions])

  const isFiltered = useMemo(
    () =>
      searchValue !== initialActionsPageState.searchValue ||
      type !== initialActionsPageState.type ||
      status !== initialActionsPageState.status,
    [pageFilters],
  )

  const refetchActions = useCallback(() => {
    refetch(updatedQueryParams).then()
    refetchActionsCount(variablesForActionsCount).then()
  }, [updatedQueryParams, refetch, refetchActionsCount, variablesForActionsCount, pageFilters])

  const handleChange = useCallback(
    (_, value) => {
      setPageFilters({ page: value })
      setCheckedActions([])
    },
    [setPageFilters, setCheckedActions],
  )

  const onChecked = useCallback(() => {
    setCheckedActions((prevState) =>
      prevState.length === pendingActionsList.length ? [] : pendingActionsList,
    )
  }, [setCheckedActions, pendingActionsList])

  const handleClose = useCallback(
    (success?: boolean) => {
      success && setCheckedActions([])
      setActionModal(null)
      setActionData(undefined)
    },
    [setCheckedActions, setActionData, setActionModal],
  )

  const handleActionsForSign = useCallback(
    (actionData: Action, check: boolean) => {
      setCheckedActions((prevState) =>
        check ? [...prevState, actionData] : prevState.filter(({ id }) => id !== actionData.id),
      )
    },
    [setCheckedActions],
  )

  const handleSignChecked = useCallback(
    (procedureName: OperationNames | null) => {
      if (isEmpty(checkedActions) && pendingActionsList?.length > 0) {
        setCheckedActions(pendingActionsList)
      }
      setActionModal(procedureName)
    },
    [checkedActions, pendingActionsList, setActionModal],
  )

  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [history])

  useEffect(() => {
    setCheckedActions([])
  }, [pageFilters])

  useEffect(() => {
    if (totalRequests?.actionsCount) {
      setRequestsCount(totalRequests?.actionsCount)
    } else {
      setRequestsCount(0)
    }
  }, [totalRequests, applicationId])

  return (
    <Box className={classes.mainContainer} data-test="actionsPage">
      <Box className={classes.header}>
        <div className={classes.title}>
          <Typography variant="h1">{t('actions', 'Actions')}</Typography>
        </div>
        <Grid className={classes.wrapButton}>
          <Hidden smDown>
            <HandleAllActionsButtons
              pendingActionsList={pendingActionsList}
              isNoCheckedSignatures={isEmpty(checkedActions)}
              handleSignChecked={handleSignChecked}
            />
          </Hidden>
        </Grid>
      </Box>

      <Box className={classes.filtersContainer}>
        <ActionsFilters />
      </Box>

      <Box className={classes.tipContainer} hidden={!signDocuments}>
        <AlertTipItem
          value={t('pleaseSignBelowDocuments')}
          iconComponent={<InformationIcon />}
          type={'primary'}
        />
      </Box>

      {loading || (!actionsData && !totalRequests) ? (
        <Loader height={'100%'} />
      ) : (
        <Box className={classes.internalContainer}>
          {isEmpty(actionsData?.actions) ? (
            <Box className={classes.marginContent}>
              <EmptyStatePage isFiltered={isFiltered} />
            </Box>
          ) : (
            <ActionsListTab
              actionsList={actionsData?.actions as Action[]}
              handleActionsForSign={handleActionsForSign}
              setActionData={setActionData}
              setActionModal={setActionModal}
              onChecked={onChecked}
              isPendingActions={!isEmpty(pendingActionsList)}
              checkedActions={checkedActions}
              pendingActionsCount={pendingActionsList.length}
            />
          )}

          {/* 2FA Modal */}
          {(!isEmpty(actionData) || !isEmpty(checkedActions)) && !!actionModal && (
            <ActionsSignModal
              openAction={actionModal}
              handleClose={handleClose}
              actionData={actionData}
              multipleActionsData={checkedActions}
              refetchActions={refetchActions}
            />
          )}

          <PagePagination
            page={page}
            totalItems={totalRequests?.actionsCount}
            handleChange={handleChange}
            itemsPerPage={itemsPerPage}
          />
        </Box>
      )}
      <Grid className={classes.wrapButton}>
        <Hidden mdUp>
          <HandleAllActionsButtons
            pendingActionsList={pendingActionsList}
            isNoCheckedSignatures={isEmpty(checkedActions)}
            handleSignChecked={handleSignChecked}
          />
        </Hidden>
      </Grid>
    </Box>
  )
}

export default ActionsPage
