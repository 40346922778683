import { Box, Fab, MenuItem, Modal, SvgIcon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Individual, useGetUserDataQuery } from '../../graphql'
import { TwoFactorAuthenticationForm } from '../../pages/PersonPage/forms'
import { ROOT_PATHS } from '../../routes/paths'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.5,
    paddingLeft: theme.spacing(4),
    '&:hover': {
      color: '#434343',
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        opacity: '.4',
        transition: '0.3s',
      },
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& a': {
      width: '100%',
    },
  },
  modalStyle: {
    '& [tabindex]': {
      outline: 'none',
    },
  },
  container: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    padding: theme.spacing(3, 3, 0, 3),

    width: 552,
    maxWidth: '90%',
    maxHeight: '90%',
    height: 'auto',
    overflow: 'auto',
    outline: 'none',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}))

export const Change2FAMenuItem: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: individualData } = useGetUserDataQuery()
  const isPhoneVerified = (individualData?.viewer as Individual)?.isPhoneVerified

  const isChange2FAPresented = location.pathname.includes(ROOT_PATHS.application)

  if (!isPhoneVerified || !isChange2FAPresented) return null

  const handleToggle = () => {
    setIsModalOpen((prevState) => !prevState)
  }
  return (
    <>
      <MenuItem onClick={handleToggle} className={classes.menuItem}>
        <SvgIcon style={{ marginLeft: -2 }}>
          <path
            fill="#000"
            d="M10.935 15.3525L17.49 8.7975L16.2825 7.61875L11.0212 12.88L8.1175 9.97625L6.8525 11.2413L10.935 15.3525ZM12.2 23C9.51667 22.3292 7.3125 20.7719 5.5875 18.3281C3.8625 15.8844 3 13.2058 3 10.2925V3.45L12.2 0L21.4 3.45V10.2925C21.4 13.2058 20.5375 15.8844 18.8125 18.3281C17.0875 20.7719 14.8833 22.3292 12.2 23ZM12.2 21.2175C14.4042 20.4892 16.201 19.114 17.5906 17.0919C18.9802 15.0698 19.675 12.8033 19.675 10.2925V4.6575L12.2 1.84L4.725 4.6575V10.2925C4.725 12.8033 5.41979 15.0698 6.80937 17.0919C8.19896 19.114 9.99583 20.4892 12.2 21.2175Z"
          />
        </SvgIcon>
        {t('change2FAMethod', 'Change 2FA method')}
      </MenuItem>

      <Modal
        open={isModalOpen}
        onClose={handleToggle}
        className={classes.modalStyle}
        aria-labelledby="change-2fa-menu-item-title"
        aria-describedby="change-2fa-menu-item-description"
      >
        <Box className={classes.container}>
          <Box className={classes.headerModal}>
            <Typography variant={'h5'}>
              {t('change2factorAuthenticationMethod', 'Change 2-factor authentication method')}
            </Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={handleToggle}
              data-test="closeModal"
            >
              <CloseIcon />
            </Fab>
          </Box>
          <Box my={5}>
            <TwoFactorAuthenticationForm fromModal />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
