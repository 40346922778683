import { ButtonProps } from '@material-ui/core'
import React, { FC } from 'react'
import Button, { ButtonTypeMap } from '@material-ui/core/Button'
import { Loader } from '../Loader'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  loadingButton: {
    '& .MuiButton-label': {
      width: 'auto',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(0, 1),
    '& svg circle': {
      stroke: 'rgba(0, 0, 0, 0.26)',
    },
  },
}))

type LoadingButtonProps = ButtonProps & {
  loading: boolean
  className?: string | undefined
  children?: React.ReactNode
  type?: 'button' | 'reset' | 'submit' | undefined
  variant?: 'text' | 'outlined' | 'contained' | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined
  disableElevation?: boolean | undefined
  disabled?: boolean
  onClick?: (() => void) | undefined
  fullWidth?: boolean
}

// IntrinsicAttributes

export const LoadingButton: FC<Partial<LoadingButtonProps & ButtonTypeMap>> = ({
  loading,
  className,
  children,
  type,
  variant,
  color,
  disableElevation,
  disabled,
  onClick,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Button
      disabled={loading || disabled}
      className={`${classes.loadingButton} ${className}`}
      type={type}
      variant={variant}
      color={color}
      disableElevation={disableElevation}
      onClick={onClick}
      {...rest}
    >
      {children}
      {loading ? (
        <span className={classes.loading}>
          <Loader height={24} size={24} />
        </span>
      ) : null}
    </Button>
  )
}
