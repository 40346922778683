import * as yup from 'yup'
import {
  INVALID_PHONE_FORMAT_MESSAGE,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import { parsePhoneNumber } from 'libphonenumber-js/max'

export const PhoneNumberInputSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .nullable()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test({
      name: 'invalidPhoneFormat',
      message: INVALID_PHONE_FORMAT_MESSAGE,
      test: (value) => {
        const phoneNumber = value && parsePhoneNumber(value)
        return !!value && !!phoneNumber && phoneNumber.isValid()
      },
    }),
})

export type PhoneNumberInput = yup.Asserts<typeof PhoneNumberInputSchema>
