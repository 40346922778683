import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'

import {
  clickBatchPayment,
  isBatchPaymentFile,
  isDirtyForm,
  isModalDialogOpen,
  isModalOpenAddAccount,
  isModalPromptOpen,
  isNavMenuRoute,
  isUnsaveFormData as isUnsaveFormDataVar,
} from '../../graphql/local'
import { useWebApi } from '../../hooks'
import { MODAL_WRAPPER_ZINDEX } from '../../constants'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  // Modal
  modal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
  },
  modalHeader: {
    display: 'flex',
    padding: theme.spacing(3, 3, 2),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalContent: {
    padding: theme.spacing(0, 3),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
}))

export const ConfirmationModal: FC<{
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  handleHelpReset?: (() => void) | undefined
  isGoBack?: boolean
}> = ({ setOpen, handleHelpReset, isGoBack = true }) => {
  const [modalStyle] = useState(getModalStyle)
  const isOpen = useReactiveVar(isModalDialogOpen)
  const isOpenBatchPayment = useReactiveVar(clickBatchPayment)
  const isDirtyField = useReactiveVar(isDirtyForm)
  const history = useHistory()
  const classes = useStyles()
  const { webApi } = useWebApi()
  const { t } = useTranslation()

  const onClose = useCallback(() => {
    isModalDialogOpen(false)
    isNavMenuRoute(false)
    isModalPromptOpen(true)
    clickBatchPayment(false)
  }, [])

  const handleBack = useCallback(() => {
    if (isGoBack) {
      history.goBack()
    }
    isModalDialogOpen(false)
    isUnsaveFormDataVar(false)
    isModalPromptOpen(false)
    isModalOpenAddAccount(false)

    handleHelpReset && handleHelpReset()

    if (isDirtyField) {
      if (isOpenBatchPayment) {
        isBatchPaymentFile(true)
        isDirtyForm(false)
        clickBatchPayment(false)
      } else {
        webApi?.logout().then(() => window.location.reload())
      }
    }

    if (setOpen) {
      setOpen(false)
    }
  }, [history, isGoBack, setOpen, isDirtyField, webApi, isOpenBatchPayment])

  useEffect(() => {
    return () => {
      isModalPromptOpen(true)
    }
  }, [])

  const confirmAction = (
    <Box style={modalStyle} className={classes.modal} data-test="modalGoBack">
      <Box className={classes.modalHeader}>
        <Typography variant={'h4'}>
          {isNavMenuRoute()
            ? t(
                'areYouSureYouWantToNavigateAwayFromThisPage',
                'Are you sure you want to navigate away from this page',
              )
            : t('areYouSureYouWantToGoBack', 'Are you sure you want to go back')}
          ?
        </Typography>
      </Box>

      <Box className={classes.modalContent}>
        <Typography>
          {t('allUnsubmittedDataWillBeLost', 'All unsubmitted data will be lost')}.
        </Typography>
      </Box>
      <Box className={classes.modalFooter}>
        <Button
          variant="contained"
          onClick={handleBack}
          disableElevation
          data-test="modal-goBack-button"
        >
          {isNavMenuRoute() ? t('navigateAway', 'Navigate away') : t('goBack', 'Go back')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          disableElevation
          data-test="continueEdit"
        >
          {t('continueEditing', 'Continue editing')}
        </Button>
      </Box>
    </Box>
  )

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ zIndex: MODAL_WRAPPER_ZINDEX }}
    >
      {confirmAction}
    </Modal>
  )
}
