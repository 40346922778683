import i18n from 'i18next'
import React from 'react'
import { ReactComponent as AlertIcon } from '../assets/images/icons/attention.svg'
import { AlertDataType } from '../types'

export const batchErrAlert: AlertDataType = {
  text: i18n.t(
    'batchfileNotRecognized',
    'Uploaded CSV file is not recognized. Please double-check all the fields and try uploading again.',
  ),
  type: 'danger',
  icon: <AlertIcon />,
}

export const batchOversizedAlert: AlertDataType = {
  text: i18n.t(
    'batchfileOversized',
    'The uploaded CSV file is too big. Maximum number of rows is 1000 and maximum size is 5 MB. Consider splitting file and uploading again',
  ),
  type: 'danger',
  icon: <AlertIcon />,
}

export const batchErrFileType: AlertDataType = {
  text: i18n.t('batchfileWrongFormat', 'Wrong file format. Please upload a valid CSV file.'),
  type: 'danger',
  icon: <AlertIcon />,
}

export const batchErrCurrencyMismatch: AlertDataType = {
  text: i18n.t(
    'batchCurrencyMismatchTxt',
    'Uploaded CSV contains payments in different currencies. Multi-currency batch payments are currently not supported. Please make sure all payments require the same currency and re-upload.',
  ),
  type: 'danger',
  icon: <AlertIcon />,
}
