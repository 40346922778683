/* eslint-disable i18next/no-literal-string */
import React, { FC, useCallback, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import PENDING from '../../assets/images/icons/grey_dot.svg'
import ACTIVE from '../../assets/images/icons/green_dot.svg'
import FROZEN from '../../assets/images/icons/orange_dot.svg'
import { ActionSignature, CardLimitWithUsage, CardStatuses, CardType } from '../../graphql'
import { CurrentUserContext } from '../../hooks'
import { dateFormat } from '../../utils'
import { CardSpent } from './CardSpent'
import { CardStatusResolver } from './CardStatusResolver'

const useStyles = (selected: boolean) =>
  makeStyles(() => ({
    backGround: {
      backgroundColor: selected ? '#F5F5F5' : '#FFFFFF',
      '&:hover': {
        borderRadius: 8,
        cursor: 'pointer',
      },
    },
    whichType: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      borderBottom: 'none',
      textTransform: 'capitalize',
      '& .Pending': {
        backgroundImage: `url(${PENDING})`,
        width: '4px',
        height: '4px',
        padding: '5px',
        margin: '0 6px 0 0',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      '& .Active': {
        backgroundImage: `url(${ACTIVE})`,
        width: '4px',
        height: '4px',
        padding: '5px',
        margin: '0 6px 0 0',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      '& .Blocked': {
        backgroundImage: `url(${FROZEN})`,
        width: '4px',
        height: '4px',
        padding: '5px',
        margin: '0 6px 0 0',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      '& .Closed': {
        backgroundImage: `url(${PENDING})`,
        width: '4px',
        height: '4px',
        padding: '5px',
        margin: '0 6px 0 0',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    spendLimit: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    view: {
      fontWeight: 700,
      textDecoration: 'underline',
      fontSize: 14,
      '&.MuiButton-root': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      paddingLeft: 0,
      paddingRight: 0,
    },
    btnCopy: {
      minWidth: 20,
      minHeight: 20,
      width: 20,
      height: 20,
      margin: 0,
      padding: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:hover .MuiSvgIcon-colorPrimary': {
        color: '#bfc1c4',
      },
      '& .MuiSvgIcon-colorPrimary': {
        color: '#999',
      },
    },
    owner: {
      textTransform: 'capitalize',
    },
    tooltipBody: {
      maxWidth: '220px',
      maxHeight: '55px',
      wordWrap: 'normal',
    },
  }))()

export const CardsListRow: FC<{
  data: CardType
  selectedCardId: number | string | undefined
  setSelectedCardData: React.Dispatch<React.SetStateAction<CardType | undefined>>
  setActionData: React.Dispatch<React.SetStateAction<ActionSignature | undefined>>
  setActionModal: React.Dispatch<React.SetStateAction<string | undefined>>
  setOpenLimits: (value: boolean) => void
}> = ({ data, setActionData, setActionModal, selectedCardId, setSelectedCardData }) => {
  const { t } = useTranslation()
  const { id, alias, cardHolder, apiMaskedCardNumber, account, status, freezeLog } = data
  const classes = useStyles(id === selectedCardId)
  const user = useContext(CurrentUserContext)
  const freezeDetails = freezeLog?.[0]

  const handleClickItem = useCallback(() => {
    setSelectedCardData(data)
  }, [data, setSelectedCardData])

  const areaRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (id === selectedCardId) {
      handleClickItem()
    }
  }, [status])

  return (
    <Tooltip
      title={
        status === CardStatuses.Blocked
          ? `${t('frozenAt', 'Frozen at')} ${dateFormat(freezeDetails?.createdAt, true)} ${t(
              'by',
              'by',
            )} ${freezeDetails?.actionBy}`
          : ''
      }
      classes={{ tooltip: classes.tooltipBody }}
      placement="top"
      arrow
      PopperProps={{
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => {
            return new DOMRect(
              areaRef.current ? areaRef.current.getBoundingClientRect().x : undefined,
              areaRef.current ? areaRef.current.getBoundingClientRect().y : undefined,
              0,
              0,
            )
          },
        },
      }}
    >
      <TableRow onClick={handleClickItem} hover className={clsx(classes.backGround)}>
        <TableCell>{alias || t('none', 'None')}</TableCell>
        <TableCell className={classes.owner}>{cardHolder?.person?.fullName}</TableCell>
        <TableCell>
          {'*'}
          {apiMaskedCardNumber && apiMaskedCardNumber.slice(-4)}
        </TableCell>
        <TableCell>{account?.currency}</TableCell>
        <TableCell>
          <Grid container className={classes.whichType}>
            <Grid ref={areaRef} item sm={12}>
              {status && user?.id && (
                <CardStatusResolver
                  data={data}
                  userId={user.id}
                  setActionData={setActionData}
                  setActionModal={setActionModal}
                />
              )}
            </Grid>
          </Grid>
          <Grid container className={`${status}`} />
        </TableCell>
        <TableCell>
          <CardSpent limitsWithUsage={data.limitsWithUsage as CardLimitWithUsage[]} />
        </TableCell>
      </TableRow>
    </Tooltip>
  )
}
