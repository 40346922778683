import React, { FC } from 'react'
import { Hidden } from '@material-ui/core'

import { ApplicationMenu } from '../MainMenu'
import { Footer } from '../Footer'
import { useLayoutStyles } from './layoutStyles'

export const ApplicationLayout: FC = ({ children }) => {
  const classes = useLayoutStyles()

  return (
    <div className={classes.wrap}>
      <ApplicationMenu />
      <main className={classes.content}>
        <Hidden mdUp>
          <div className={classes.drawerHeader} />
        </Hidden>
        {children}
        <Hidden mdUp>{<Footer isMenu={false} />}</Hidden>
      </main>
    </div>
  )
}

export default ApplicationLayout
