import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IMeta } from 'react-dropzone-uploader'

import { Box, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneInner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInfoProgress: {
    width: 128,
  },
  fileInfoPercent: {
    paddingLeft: theme.spacing(1),
    fontWeight: 700,
    color: '#276EF1',
  },
  titleProgress: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    marginBottom: 20,
  },
}))

export const BatchUploadProgress = (data: IMeta): ReactElement => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.dropzoneInner}>
      <Typography className={classes.titleProgress}>{t('recognizing')}</Typography>
      <Box className={classes.fileInfoProgress}>
        <LinearProgress
          className={classes.fileInfoPercent}
          variant="determinate"
          value={Math.round(data.percent)}
        />
      </Box>
    </Box>
  )
}
