import { makeStyles, Theme } from '@material-ui/core'

export const useLayoutStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(3, 4),
    overflow: 'auto',
    maxHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      //overflowX: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}))
