import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload } from 'react-use'
import { toast } from 'react-toastify'
import { Typography, Box, Checkbox, FormControlLabel, Button, RadioGroup } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useReactiveVar } from '@apollo/client'
import { isEmpty } from 'lodash'
import NavigationPrompt from 'react-router-navigation-prompt'

import {
  AddAuthorizedPersonInputType,
  Individual,
  useAddContractAuthorizedPersonMutation,
  useContractIndividualOwnersQuery,
  useGetUserDataQuery,
  useUpdateContractAuthPersonMutation,
  ContractUbo,
  useGetContractDetailsQuery,
  LegalEntity,
  ContractStatusType,
  useGetAuthorizedPersonsDataQuery,
  ContractAuthorizedPersonType,
  useAddLegalEntityDirectorMutation,
} from '../../graphql'
import {
  accessRights as accessRightsVar,
  isUnsaveFormData as isUnsaveFormDataVar,
  goBackPathVar,
  goNextPathVar,
  AccessRights,
} from '../../graphql/local'
import {
  BlackRadio,
  FormAutocompleteSelect,
  GridRow,
  LoadingButton,
  Loader,
  CustomPrompt,
} from '../Common'
import { Authorised, Limited } from './Tips'
import { NaturalPersonForm } from './Forms/NaturalPersonForm'
import { AddNewNaturalPersonInputSchema } from '../../schemes'
import { ReviewPerson } from '../NewBusinessApp'
import { ReactComponent as Checked } from '../../assets/images/icons/cheked_icon.svg'
import { ReactComponent as Unchecked } from '../../assets/images/icons/unchecked_icon.svg'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { StepperTypes, RegistrationSteps, AppActions, statusActions } from '../../constants'
import { useStepper } from '../../hooks'
import { extractDateValue, focusKeyPressNext, scrollToInputElement } from '../../utils'
import {
  EMAIL_EXSIST_AUTHORIZED_PERSON,
  EMAIL_EXSIST_AUTHORIZED_PERSON_RESPONSE,
} from '../../constants/validations'
import { PersonAccessRightsEnum } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(0.5, 1, 0.5, 1.5),
    },
  },
  group: {
    margin: theme.spacing(0, 0, 5, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 4, 0),
    },
    [theme.breakpoints.down('xs')]: {
      '& #email-helper-text': {
        height: 20,
      },
    },
  },
  titleWrap: {
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  radio: {
    '& .MuiRadio-root': {
      padding: theme.spacing(1, 1),
    },
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1.5, 1.5),
    },
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.125rem',
    maxWidth: 265,
    width: '100%',
    height: 48,
    position: 'relative',
    '& svg': {
      width: 24,
      height: 24,
    },
    '& span': {
      fontSize: '1.125rem',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  buttonsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 40,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  secondaryButton: {
    marginRight: 40,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: 20,
      maxWidth: '100%',
    },
  },
}))

export const AddNewPerson: FC<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setOpen }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [allowNavigation, setAllowNavigation] = useState(false)

  const [addAnother] = useState(false)
  const [ownersList, setOwnersList] = useState<{ key: number; label: string }[]>([])
  const [user, setUser] = useState<Individual | null>()
  const [formPerson, setFormPerson] = useState<Individual | null>()
  const [userIsAP, setUserIsAP] = useState(false)
  const [isReviewPersonVisible, setReviewPersonVisible] = useState(false)
  const [formMode, setFormMode] = useState('new')
  const [dataRoles, setDataRoles] = useState<ContractAuthorizedPersonType>()

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false)

  const history = useHistory()

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.authPersonId]: authPersonId,
  } = useParams() as Record<string, string>
  const goBackPath = useReactiveVar(goBackPathVar)
  const goNextPath = useReactiveVar(goNextPathVar)

  const access = useReactiveVar(accessRightsVar)

  const { data: userData } = useGetUserDataQuery()
  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })
  const { data: ownersData } = useContractIndividualOwnersQuery({
    variables: {
      id: +applicationId,
    },
    skip: !applicationId,
  })
  const { data: personsData } = useGetAuthorizedPersonsDataQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })
  const [addContractAuthorizedPersonMutation] = useAddContractAuthorizedPersonMutation()
  const [updateContractAuthPersonMutation] = useUpdateContractAuthPersonMutation()
  const [addLegalEntityDirectorMutation] = useAddLegalEntityDirectorMutation()

  const { setHeaderText, setCurrentStep } = useStepper(StepperTypes.registration)

  useEffect(() => {
    setCurrentStep(RegistrationSteps.authorizedPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t('companyBusinessApplication', 'Business application', {
            companyName: contractOwner.companyName,
          }),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [applicationId, contractData, contractLoading, history, setCurrentStep, setHeaderText, t])

  useEffect(() => {
    setUser(userData?.viewer as Individual)
  }, [userData])

  useEffect(() => {
    const apList = personsData?.contract?.authorizedPersons
    const ubosList = ownersData?.contract?.ubos as ContractUbo[]
    if (user && ubosList && apList) {
      const apIndividualsIds = apList.map((ap) => ap?.person?.id)
      setUserIsAP(apIndividualsIds.includes(user.id))
      const excludeIdsList = new Set([user.id, ...apIndividualsIds])
      setOwnersList(
        ubosList
          .filter((contractUbo) => {
            const uboEntity = contractUbo.ubo?.entity
            if (uboEntity?.__typename === 'Individual') {
              return !excludeIdsList.has(uboEntity.id)
            } else {
              return false
            }
          })
          .map((contractUbo) => {
            const { id, firstName, lastName } = contractUbo.ubo?.entity as Individual
            return {
              key: id,
              label: `${firstName} ${lastName}`,
            }
          }),
      )
    }
  }, [ownersData, personsData, user])

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(AddNewNaturalPersonInputSchema),
  })
  const { formState, setValue, setError, watch, reset } = methods
  const ownerPerson = watch('ownerPerson')

  useBeforeUnload(
    methods.formState.isDirty,
    t('youHaveUnsavedChangesAreYouSure', 'You have unsaved changes, are you sure?'),
  )

  const handleBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (goBackPath) {
        history.push(goBackPath)
        goBackPathVar('')
      } else {
        history.goBack()
      }
    },
    [formState.isDirty, goBackPath, history],
  )

  const onSubmit = useCallback(
    async (formData) => {
      setIsSubmitBtnDisabled(true)
      setAllowNavigation(true)
      const contractId = applicationId

      const personId = formMode === 'myself' ? user?.id : ownerPerson ? ownerPerson : undefined
      const person = {
        ...(personId ? { id: personId } : {}),
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        birthday: extractDateValue(formData.birthday),
        nationality: formData.nationality,
        phone: formData.phoneNumber,
        address: {
          line1: formData.streetAddress,
          city: formData.city,
          zip: formData.postalCode,
          country: formData.country,
          additionalDetails: formData.additionalDetailsOptional,
        },
      }

      const preparedInput: AddAuthorizedPersonInputType = {
        authorizationSetting: {
          isAuthorizedSignatory: access === PersonAccessRightsEnum.full,
          isLimitedAccess: access === PersonAccessRightsEnum.limited,
          ...(access === PersonAccessRightsEnum.limited
            ? { limitedAccessRight: formData.limitedAccessRight }
            : {}),
          ...(access === PersonAccessRightsEnum.full
            ? { signatoryRight: formData.signatoryRight }
            : {}),
          ...(formData.signatoryRight === 'sole' ? { groupSize: 1 } : {}),
          ...(formData.signatoryRight === 'joint' ? { groupSize: 2 } : {}),
          ...(formData.signatoryRight === 'group'
            ? { groupSize: Number(formData.groupSignature) }
            : {}),
        },
        person,
      }

      try {
        if (access === 'none') {
          await addLegalEntityDirectorMutation({
            variables: {
              contractId,
              person,
            },
          })
        } else {
          if (authPersonId) {
            await updateContractAuthPersonMutation({
              variables: {
                authPersonId,
                authorizationSetting: preparedInput.authorizationSetting,
                person: preparedInput.person,
              },
            })
          } else {
            await addContractAuthorizedPersonMutation({
              variables: {
                contractId: +contractId,
                input: preparedInput,
              },
            })
          }
        }

        accessRightsVar(PersonAccessRightsEnum.full)
        isUnsaveFormDataVar(false)

        if (addAnother) {
          reset()
          setFormMode('new')
          history.push(
            generatePath(APP_PATHS.application.authorizedPersons.add, { applicationId }),
            { successfully: 'open' },
          )
        } else if (goNextPath) {
          history.push(goNextPath)
        } else {
          goBackPathVar('')
          history.push(
            generatePath(APP_PATHS.application.authorizedPersons.list, {
              [PATH_PARAMS.applicationId]: +applicationId,
            }),
          )
        }
      } catch (e) {
        setIsSubmitBtnDisabled(false)
        setAllowNavigation(false)
        if (e?.message === EMAIL_EXSIST_AUTHORIZED_PERSON_RESPONSE) {
          setError('email', {
            type: 'focus',
            message: EMAIL_EXSIST_AUTHORIZED_PERSON,
            shouldFocus: true,
          })
          const emailInput: HTMLElement | null = document.querySelector(`input[name='email']`)
          emailInput?.focus()
          emailInput?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        } else {
          toast.error((e as Error).message)
        }
      }
    },
    [
      addAnother,
      addContractAuthorizedPersonMutation,
      applicationId,
      authPersonId,
      formMode,
      access,
      goNextPath,
      history,
      ownerPerson,
      reset,
      updateContractAuthPersonMutation,
      user?.id,
    ],
  )

  // const handleAddAnother = useCallback(() => {
  //   setAddAnother(!addAnother)
  // }, [addAnother])

  const handleMyselfChkBox = useCallback(() => {
    if (formMode === 'new') {
      setFormMode('myself')
    } else {
      reset()
      setFormMode('new')
    }
  }, [formMode, reset])

  const handleFormMode = useCallback(
    (event) => {
      reset()
      const value = event.target.value
      setFormMode(value)
    },
    [setFormMode, reset],
  )

  useEffect(() => {
    if (formState.isDirty) {
      isUnsaveFormDataVar(true)
    }
  }, [formState.isDirty])

  useEffect(() => {
    if (authPersonId) {
      const data = personsData?.contract?.authorizedPersons?.find(
        (person) => +authPersonId === person?.id,
      )
      if (data) {
        setDataRoles(data)
        setFormPerson(data.person as Individual)
      }
    }
  }, [personsData, authPersonId, setValue])

  useEffect(() => {
    if (ownersData?.contract?.ubos && ownerPerson) {
      const personData = ownersData?.contract?.ubos.find(
        (val) => val?.ubo?.entity?.__typename === 'Individual' && ownerPerson === val.ubo.entity.id,
      )

      setFormPerson(personData?.ubo?.entity as Individual)
    } else {
      reset()
    }
  }, [ownerPerson, ownersData?.contract?.ubos, reset])

  useEffect(() => {
    isReviewPersonVisible &&
      history.push(
        generatePath(APP_PATHS.application.authorizedPersons.list, {
          [PATH_PARAMS.applicationId]: +applicationId,
        }),
      )
  }, [isReviewPersonVisible, applicationId, history])

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    const accessRightsFromHistory = (history.location.state as {
      accessRights: AccessRights | undefined
    })?.accessRights
    if (accessRightsFromHistory && accessRightsFromHistory !== access) {
      accessRightsVar(accessRightsFromHistory)
    }
  }, [])

  if (!userData || !contractData || !ownersData || !personsData) return <Loader />

  return (
    <>
      <NavigationPrompt when={formState.isDirty && !allowNavigation}>
        {({ onCancel, onConfirm }) => (
          <CustomPrompt open={true} onCancel={onCancel} onConfirm={onConfirm} />
        )}
      </NavigationPrompt>
      {isReviewPersonVisible ? (
        <ReviewPerson setOpen={setOpen} setReviewPersonVisible={setReviewPersonVisible} />
      ) : (
        <FormProvider {...methods}>
          <Box className={classes.form}>
            <Box className={classes.group}>
              <Box mt={3} hidden={!!authPersonId}>
                <Box className={classes.titleWrap}>
                  <Typography variant={'h2'} className={classes.title} data-test="addNewAuthPerson">
                    {access === PersonAccessRightsEnum.full
                      ? t('newSignatory', 'New signatory')
                      : access === PersonAccessRightsEnum.limited
                      ? t(
                          'newAuthorisedPersonWithLimitedAccess',
                          'New authorised person with limited access',
                        )
                      : t('director', 'Director')}
                  </Typography>
                </Box>
                {!isEmpty(ownersList) ? (
                  <>
                    <RadioGroup name="formMode" value={formMode} onChange={handleFormMode}>
                      <Box mb={1.75}>
                        <FormControlLabel
                          value="new"
                          control={<BlackRadio size="small" />}
                          label={t('addNewPerson', 'Add new person')}
                          className={classes.radio}
                        />
                      </Box>
                      <Box mb={1.75} hidden={userIsAP}>
                        <FormControlLabel
                          value="myself"
                          control={<BlackRadio size="small" />}
                          label={t('iAmAddingMyself', 'I am adding myself')}
                          className={classes.radio}
                        />
                      </Box>
                      <Box mb={1.75} hidden={isEmpty(ownersList)}>
                        <FormControlLabel
                          value="existing"
                          control={<BlackRadio size="small" />}
                          label={t(
                            'addExistingBeneficial',
                            'Add existing beneficial owner or director',
                          )}
                          className={classes.radio}
                        />
                      </Box>
                    </RadioGroup>

                    <GridRow>
                      <Box hidden={formMode !== 'existing'}>
                        <FormAutocompleteSelect
                          name="ownerPerson"
                          label={t('ownerDirector', 'Owner/director')}
                          data={ownersList}
                        />
                      </Box>
                    </GridRow>
                  </>
                ) : (
                  <Box hidden={userIsAP}>
                    <FormControlLabel
                      control={
                        <Checkbox name="myself" icon={<Unchecked />} checkedIcon={<Checked />} />
                      }
                      onChange={handleMyselfChkBox}
                      label={t('iAmAddingMyself', 'I am adding myself')}
                      data-test="iAmAddingMyselfCheckBox"
                      className={classes.checkbox}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              id="forNextFocus"
              onKeyDown={focusKeyPressNext}
            >
              <Box>
                <Box className={classes.group}>
                  {access === PersonAccessRightsEnum.full && (
                    <Authorised
                      signatoryRight={dataRoles?.signatoryRight}
                      groupSignature={dataRoles?.groupSize}
                    />
                  )}
                  {access === PersonAccessRightsEnum.limited && (
                    <Limited limitedAccessRight={dataRoles?.limitedAccessRight} />
                  )}

                  <NaturalPersonForm
                    personData={formMode === 'myself' ? user : formPerson}
                    key={`${formMode}:${formMode === 'myself' ? user?.id : formPerson?.id}`}
                  />
                  <Box mt={5} hidden={!!authPersonId}>
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          name="addAnotherPerson"
                          checked={addAnother}
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          onClick={handleAddAnother}
                        />
                      }
                      value={false}
                      label={t(
                        'AddAnotherAuthorisedPerson',
                        'Add another authorised person on the next step',
                      )}
                    /> */}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.buttonsBox}>
                <Box className={classes.secondaryButton}>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    disableElevation
                    onClick={handleBack}
                    data-test="back-btn"
                  >
                    {t('back', 'Back')}
                  </Button>
                </Box>

                <Box hidden={formMode !== 'myself' && !formState.isDirty}>
                  <LoadingButton
                    className={classes.button}
                    // loading={mutationLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    data-test="saveChanges-btn"
                    disabled={isSubmitBtnDisabled}
                  >
                    {authPersonId
                      ? t('saveChanges', 'Save changes')
                      : t('submitAndProceed', 'Submit and proceed')}
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          </Box>
        </FormProvider>
      )}
    </>
  )
}

export default AddNewPerson
