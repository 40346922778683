import * as yup from 'yup'

import {
  AdditionalDetailsOptionalInputSchema,
  AddressInputSchema,
  BirthdayInputSchema,
  //CompanyTypeInputSchema,
  EmailInputSchema,
  NameInputSchema,
  NationalityInputSchema,
  PhoneNumberInputSchema,
  PostalCodeInputSchema,
  StreetAddressInputSchema,
} from './common'
import { GROUP_SIZE_REQUIRED, MAX_GROUP_SIZE, MIN_GROUP_SIZE } from '../constants/validations'
import i18n from '../i18n'

export const AddNewNaturalPersonInputSchema = yup
  .object()
  .shape({
    isLimitedAccess: yup.mixed().notRequired(),
    isControllingPerson: yup.mixed().notRequired(),
    isAuthorizedSignatory: yup.mixed().notRequired(),
    isAuthorizedPerson: yup.mixed().notRequired(),
    controllingEntityType: yup.mixed().notRequired(),
    signatoryRight: yup.mixed().notRequired(),
    groupSignature: yup
      .string()
      .test('groupSizeIsRequiredNotNaN', GROUP_SIZE_REQUIRED, function () {
        return this.parent.signatoryRight !== 'group' || !isNaN(+this.parent.groupSignature)
      })
      .test('groupSizeIsRequiredNotEmpty', i18n.t(GROUP_SIZE_REQUIRED), function () {
        return this.parent.signatoryRight !== 'group' || +this.parent.groupSignature !== 0
      })
      .test(
        'maximumGroupSize',
        i18n.t('maximumGroupSize', 'Maximum group size is 30'),
        function () {
          return (
            this.parent.signatoryRight !== 'group' || +this.parent.groupSignature <= MAX_GROUP_SIZE
          )
        },
      )
      .test('minimumGroupSize', i18n.t('minimumGroupSize', 'Minimum group size is 3'), function () {
        return (
          this.parent.signatoryRight !== 'group' || +this.parent.groupSignature >= MIN_GROUP_SIZE
        )
      }),
  })
  .concat(NameInputSchema)
  .concat(EmailInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(AddressInputSchema)
  .concat(AdditionalDetailsOptionalInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(PhoneNumberInputSchema)
