import { useState } from 'react'

export const useControlledTooltip = () => {
  const [tooltipOpen, setTooltipOpenOpen] = useState(false)
  let timeout: NodeJS.Timeout
  const handleTooltipClose = () => {
    timeout = setTimeout(() => setTooltipOpenOpen(false), 200)
  }

  const handleTooltipOpen = () => {
    clearTimeout(timeout)
    setTooltipOpenOpen(true)
  }

  const tooltipListeners = {
    onMouseEnter: handleTooltipOpen,
    onClick: handleTooltipOpen,
    onMouseLeave: handleTooltipClose,
  }

  return {
    handleTooltipClose,
    handleTooltipOpen,
    tooltipOpen,
    setTooltipOpenOpen,
    tooltipListeners,
  }
}
