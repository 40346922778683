import React, { FC, useCallback } from 'react'
import { Grid, makeStyles, Button, Box, Typography, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 35,
    justifyContent: 'space-between',
  },
  startBusinessText: {
    marginTop: 25,
  },
  startBusinessButton: {
    marginTop: 50,
    width: 208,
    height: 48,
    color: '#fff',
    backgroundColor: '#000',
  },
  imageWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(3),
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },

    '& img': {
      maxWidth: '100%',
    },
  },
}))

export const Approval: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const onContact = useCallback(() => {
    //TODO add logic to button
  }, [])

  return (
    <Grid container direction="row" spacing={6} className={classes.mainContainer}>
      <Grid item md={6}>
        <Box>
          <Typography variant={'h1'}>
            {t('applicationApprovalStatusPending', 'Application Approval Status: Pending')}
          </Typography>

          <Typography className={classes.startBusinessText}>
            {t(
              'thankYouForSubmittingYourApplication',
              'Thank you for submitting your application. It is currently being reviewed by our the {{val1}} team and you will be notified by email upon account opening.',
              {
                val1: config.name,
              },
            )}
          </Typography>
          <Typography className={classes.startBusinessText}>
            {t(
              'ifYouWantToGetInTouchWithUsInTheMeanTimePleaseVisitThe',
              'If you want to get in touch with us in the mean time please visit the',
            )}{' '}
            <Link href={'#'} underline="always">
              {t('supportPage.', 'support page.')}
            </Link>
          </Typography>
          <Button
            className={classes.startBusinessButton}
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            onClick={onContact}
          >
            {t('contactUs', 'Contact Us')}
          </Button>
        </Box>
      </Grid>

      <Grid item md={6}>
        <Box className={classes.imageWrap}>
          <img src={config.images.default} alt={t('tradingApplication', 'trading application')} />
        </Box>
      </Grid>
    </Grid>
  )
}
