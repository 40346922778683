import React, { FC } from 'react'
import { TableCell, TableRow } from '@material-ui/core'

import { BatchPaymentRecord, Maybe } from '../../graphql'
import { BeneficialCellType } from '../../types'
import { BeneficiariesListCell } from './BeneficiariesListCell'

export const BeneficiariesListRow: FC<{
  data: Maybe<BatchPaymentRecord>
}> = ({ data }) => {
  return (
    <TableRow>
      <TableCell width="150">
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.amount} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.name} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.ibanAccountNumber} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.swiftBic} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.processing} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.reference} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.payoutAmount} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.usingLocalAch} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.fixedPayoutAmount} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.bankClearingMemberId} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.bankBranchId} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.bankBranchName} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.purposeCode} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.beneficiaryPhone} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.beneficiaryEmail} />
      </TableCell>
      <TableCell>
        <BeneficiariesListCell data={data} cellType={BeneficialCellType.chargeBearer} />
      </TableCell>
    </TableRow>
  )
}
