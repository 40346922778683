import React, { FC } from 'react'
import { Box, Container, Grid, Theme, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import signature from '../../../../assets/images/img/missingSignature.png'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#000000',
  },
  container: {
    justifyContent: 'center',
  },
  tip: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontSize: '14px',
    marginTop: '2px',
  },
  img: {
    height: '67px',
  },
}))

export const Tips: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container component="div" maxWidth={false} disableGutters={true}>
        <Grid container className={classes.container}>
          <Grid item>
            <Box className={classes.tip} ml={1}>
              <img
                className={classes.img}
                src={signature}
                alt={t('missingSignature', 'Missing signature')}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
