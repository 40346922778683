import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, FormControl, Grid, List, ListItem, ListItemText, TextField } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { ControlledTextFieldProps, KeyAndLabelSelectType } from '../../../types'
import {
  NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
  NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS,
} from '../../../constants/validations'
import { FormNewValueField } from '../Fields'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  rootPaper: {
    zIndex: 9,
    backgroundColor: 'white',
    width: '100%',
  },
  formControl: {
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },

  fieldControl: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.spacing(1.5, 0, 1.5, 1.5),
      lineHeight: '1.1876em',
      display: 'block',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(0.857)',
    },
  },
  input: {
    width: '100%',
    position: 'relative',
    zIndex: 2,
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(4),
    },
  },
  iconInput: {
    width: 48,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 24px)',
    bottom: 12,
    right: 0,
    margin: 'auto',
    zIndex: 9,
    '& .MuiSvgIcon-root': {
      color: '#999',
    },
    '& svg': {
      width: 28,
      height: 28,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '50%',
        cursor: 'pointer',
      },
    },
  },
  item: {
    padding: theme.spacing(1, 1.5),
  },
  lastItem: {
    // borderTop: '1px solid #F5F5F5',
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    backgroundColor: '#000000',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    width: '100%',
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
    '&.disabled': {
      backgroundColor: '#F5F5F5',
      '& .MuiButton-contained.Mui-disabled': {
        backgroundColor: '#F5F5F5',
      },
    },
  },
  btn: {
    width: '100%',
    margin: '15px 0 0 0',
  },
  inputField: {
    border: '1px solid #D9D9D9',
    marginTop: 0,
    marginBottom: 10,
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.spacing(1.5, 0, 1.5, 1.5),
      lineHeight: '1.1876em',
      display: 'block',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(0.857)',
    },
    '& .MuiInputBase-input': {
      height: 48,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 10,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'black',
    },
  },
}))

const defaultRules = {} //{ required: true }

interface FormAutocompleteSelectCustomValueProps {
  name: string
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  data: Array<any> | KeyAndLabelSelectType[]
}

export const FormAutocompleteSelectCustomValue: FC<
  Partial<FormAutocompleteSelectCustomValueProps & ControlledTextFieldProps>
> = ({ name = '', data, rules, ...rest }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { errors, register, setValue, watch, clearErrors, setError } = useFormContext()
  const error = errors ? errors[name] : null
  const [open, setOpen] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState('')
  const customValVar = name + 'CustomVal'
  const customVal = watch(customValVar)
  const [validCustomVal, setValidCustomVal] = useState(false)

  const value = watch(name)

  useEffect(() => {
    data?.find((itemData) => itemData.key === value)
      ? setSelectedLabel(data?.find((itemData) => itemData.key === value)?.label)
      : setSelectedLabel(value)
  }, [value, data, setSelectedLabel])

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })
    register(customValVar, { ...defaultRules, ...(rules ?? {}) })
  }, [register, name, rules, customValVar])

  useEffect(() => {
    if (isEmpty(customVal)) {
      clearErrors(customValVar)
      setValidCustomVal(false)
    } else if (!customVal?.match(NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS)) {
      setError(customValVar, {
        type: 'manual',
        message: NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
      })
      setValidCustomVal(false)
    } else {
      clearErrors(customValVar)
      setValidCustomVal(true)
    }
  }, [customVal, clearErrors, setError, setValidCustomVal])

  const handleToggle = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setOpen((prevOpen) => !prevOpen)
    },
    [setOpen],
  )

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const setCustomValue = useCallback(() => {
    setValue(name, customVal, {
      shouldValidate: false,
      shouldDirty: true,
    })
    clearErrors(name)
    setSelectedLabel(customVal)
    setOpen(false)
  }, [customVal, setValue, setOpen, setSelectedLabel])

  const changeSelectedValue = useCallback(
    (label, chosenValue) => {
      setValue(name, chosenValue?.key, {
        shouldValidate: false,
        shouldDirty: true,
      })
      clearErrors(name)
      setSelectedLabel(label)
      setOpen(false)
    },
    [name, setValue, setSelectedLabel, setOpen],
  )

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <TextField
          value={selectedLabel}
          onClick={handleToggle}
          inputRef={anchorRef}
          className={classes.input}
          name={name}
          // {...params}
          error={!!error}
          helperText={error ? error.message : null}
          {...rest}
        />
        <Box component="span" className={classes.iconInput} onClick={handleToggle}>
          {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon viewBox="-2 0 28 24" />}
        </Box>
      </FormControl>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.rootPaper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List id={`menu-list-grow-${name}`} data-test={`autotest-${name}`}>
                  {!isEmpty(data) &&
                    data?.map((mapItem) => (
                      <ListItem
                        key={mapItem.key}
                        onClick={() => {
                          const label = `${mapItem.label ? mapItem.label : ''}`
                          changeSelectedValue(label, mapItem)
                        }}
                        button
                        className={classes.item}
                      >
                        <ListItemText>{`${mapItem.label ? mapItem.label : ''}`}</ListItemText>
                        {watch(name) === mapItem.key && <Check fontSize={'small'} />}
                      </ListItem>
                    ))}
                  <ListItem className={`${classes.item} ${classes.lastItem}`}>
                    <Grid item className={classes.btn}>
                      <FormControl className={classes.fieldControl}>
                        <FormNewValueField
                          className={classes.inputField}
                          name={customValVar}
                          placeholder={t('other', 'Other')}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.btn}>
                      <Button
                        className={clsx(classes.submitButton, isEmpty(customVal) && 'disabled')}
                        variant="contained"
                        disableElevation
                        color="default"
                        onClick={setCustomValue}
                        disabled={!validCustomVal}
                      >
                        {t('submit', 'Submit')}
                      </Button>
                    </Grid>
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
