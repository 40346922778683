/* eslint-disable i18next/no-literal-string */
import React, { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { DOCUMENTS_TYPE } from '../../../constants'
import { CommonTextField } from '../../Common'
import config from '../../../config'

export interface UploadDocumentsForm {
  picture: FileList
  documentType: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    lineHeight: '24px',
    '& .MuiTypography-gutterBottom': {
      marginTop: '1em',
      marginBottom: 'unset',
    },
  },
  topMrg: {
    paddingTop: 30,
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 40,
    },
  },
  select: {
    marginTop: theme.spacing(-2),
    borderRadius: 'unset',
  },
  input: {
    display: 'none',
  },
  mrg: {
    marginTop: theme.spacing(5),
  },
  fileName: {
    fontSize: '12px',
    lineHeight: '16px',
  },
}))

// const GridRow: FC = ({ children }) => {
//   return (
//     <Grid item container spacing={3} xs={12}>
//       {children}
//     </Grid>
//   )
// }

export const SecureDocumentUpload: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  // const { path } = useRouteMatch(PATH_DOCUMENTS) || {}
  const [docType, setDocType] = useState('')
  const [fileName, setFileName] = useState('No file chosen')
  const { register } = useForm<UploadDocumentsForm>()

  const handleChangeDocType = useCallback(
    (event) => {
      setDocType(event.target.value)
    },
    [setDocType],
  )

  const handleChangeFileName = useCallback(
    (event) => {
      setFileName(event.target.files[0].name)
    },
    [setFileName],
  )

  return (
    <Box className={classes.root}>
      <Grid container spacing={3} className={classes.topMrg}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {t('secureDocumentUpload', 'Secure Document Upload')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mb={4}>
            <Typography>
              {t(
                'youMayBeRequestedByKlarpayToUploadSupportingDocuments1',
                'You may be requested by {{val1}} to upload supporting documents.You may use this form to do so securely.',
                {
                  val1: config.name,
                },
              )}
            </Typography>
          </Box>
        </Grid>
        {/*<form onSubmit={handleSubmit(uploadDoc)} className={classes.form}>*/}
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12} sm={6} md={4}>
            <CommonTextField
              className={classes.select}
              SelectProps={{ native: true }}
              label={t('documentTypeSmall', 'Document type')}
              name="documentType"
              fullWidth
              select
              inputRef={register}
              onChange={handleChangeDocType}
            >
              {DOCUMENTS_TYPE.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </CommonTextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <input
              ref={register}
              accept="image/*,application/pdf"
              className={classes.input}
              id="contained-button-file"
              type="file"
              name="picture"
              onChange={handleChangeFileName}
            />
            {/*<Switch>*/}
            {/*  <Route exact path={`${path}/upload`} component={UploadDocumentsPage} />*/}
            {/*  <Route path={`${path}/upload/:category`} />*/}
            {/*</Switch>*/}
            <label htmlFor="contained-button-file" className={classes.button}>
              <Button variant="contained" disableElevation component="span">
                Choose file
              </Button>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.fileName}>{fileName}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.mrg}>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
            disabled={docType === '' || fileName === 'No file chosen'}
          >
            Upload
          </Button>
        </Grid>
        {/*</form>*/}
      </Grid>
    </Box>
  )
}
