import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Box, FormControl, List, ListItem, ListItemText, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useTranslation } from 'react-i18next'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useParams } from 'react-router'
import {
  Account,
  AccountStatus,
  Currency,
  FundsType,
  GetContractAccountsQuery,
  LegalEntity,
  useGetContractDetailsQuery,
} from '../../../graphql'
import { useCurrentUser, useDetermineUserRights } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'

import { ControlledTextFieldProps } from '../../../types'
import { useFormContext } from 'react-hook-form'
import Search from '@material-ui/icons/Search'
import { filter, isEmpty, orderBy, startsWith, toLower } from 'lodash'
import { Check } from '@material-ui/icons'
import Loader from '../Loader'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  rootPaper: {
    width: '100%',
    zIndex: 9,
    backgroundColor: 'white',
  },
  formControl: {
    width: '100%',
    position: 'relative',
  },
  input: {
    width: '100%',
    position: 'relative',
    zIndex: 2,
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(4),
    },
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(0, 10px) scale(1)',
    },
  },
  iconInput: {
    cursor: 'pointer',
    width: 48,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 24px)',
    bottom: 12,
    right: 0,
    margin: 'auto',
    zIndex: 2,
    '& .MuiSvgIcon-root': {
      '&:hover': {
        background: 'rgba(153, 153, 153, 0.1)',
        borderRadius: '20px',
      },
    },
  },
  searchField: {
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(0.5, 1, 1, 1),
      padding: theme.spacing(0),
      minHeight: 'auto',
    },
    '& .MuiInput-root': {
      paddingLeft: theme.spacing(1.5),
    },
    '& .MuiInputBase-input': {
      height: 48,
      boxSizing: 'border-box',
    },
    '& .MuiInput-underline:before': {
      height: '100%',
      border: '1px solid #D9D9D9',
    },
    '& .MuiSvgIcon-root': {
      fill: '#999',
    },
  },
  item: {
    padding: theme.spacing(1, 1.5),
  },
  disabled: {
    backgroundColor: '#f5f5f5',
  },
  listWrapper: {
    overflowY: 'auto',
    maxHeight: '300px',
  },
}))

const defaultRules = {} //{ required: true }

interface DebitAccountAutocompleteSelectProps {
  name: string
  classPut?: string
  updateCurrency?: (val: string) => void
  data: GetContractAccountsQuery | undefined | null
}

export const DebitAccountAutocompleteSelect: FC<
  Partial<DebitAccountAutocompleteSelectProps & ControlledTextFieldProps>
> = ({ name = '', updateCurrency, data, rules, classPut, ...rest }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const currentUser = useCurrentUser()

  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState('')
  const [accountList, setAccountList] = useState<Account[]>([])
  const [search, setSearch] = useState<string>('')

  const { data: contractData, loading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const currentContractCountry = userRights?.isIndividualContractOwner
    ? currentUser?.address?.country
    : (contractData?.contract?.owner as LegalEntity)?.address?.country

  const limitedCountryList = userRights?.isIndividualContractOwner
    ? process.env.REACT_APP_INDIVIDUAL_COUNTRY_EUR?.split(',') || []
    : process.env.REACT_APP_CORPORATE_COUNTRY_EUR_CARDS?.split(',') || []

  const { errors, register, setValue, watch } = useFormContext()
  const error = errors ? errors[name || 'debitAccount'] : null

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  // for cleaning search field
  useEffect(() => {
    setSearch('')
  }, [open])

  const changeAccount = useCallback(
    (label, id) => {
      setValue(name, id, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setSelectedLabel(label)
      setOpen(false)
    },
    [name],
  )
  const changeCurrency = useCallback(
    (val) => {
      if (updateCurrency) {
        updateCurrency(val)
      }
    },
    [updateCurrency],
  )

  const filteredAccounts = useMemo(
    () =>
      filter([...accountList], (account: Account) =>
        startsWith(toLower(account.alias as string), toLower(search)),
      ),
    [search, setSearch, accountList],
  )

  const sortedAccounts = useMemo(() => orderBy(filteredAccounts, ['balance.available'], ['desc']), [
    filteredAccounts,
  ])

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    return setSearch(event.target.value)
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      setAccountList((data?.accounts as Account[]) || [])
    }
  }, [data])

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })
  }, [register, name, rules])

  if (loading) return <Loader />

  return (
    <div className={`${classes.root} ${classPut ? classPut : ''}`}>
      <FormControl className={`${classes.formControl}`}>
        <TextField
          name={name}
          value={selectedLabel}
          onClick={handleToggle}
          disabled={open}
          className={classes.input}
          error={!!error}
          helperText={error ? error.message : null}
          inputRef={anchorRef}
          {...rest}
        />
        <Box component="span" className={classes.iconInput} onClick={handleToggle}>
          {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
        </Box>
      </FormControl>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.rootPaper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List id={`menu-list-grow-${name}`} className={classes.listWrapper}>
                  <FormControl className={classes.searchField}>
                    <TextField
                      InputProps={{ startAdornment: <Search fontSize={'small'} /> }}
                      onChange={handleSearch}
                      value={search}
                      variant={'standard'}
                      autoFocus
                      placeholder={t('search', 'Search')}
                    />
                  </FormControl>
                  {!isEmpty(accountList) &&
                    sortedAccounts.map((account: Account) => {
                      const { id, alias, currency, balance, fundsType, status } = account
                      const isDisabled =
                        !(fundsType !== FundsType.ClientFunds && status === AccountStatus.Active) ||
                        (limitedCountryList.includes(
                          currentContractCountry?.toUpperCase() as string,
                        ) &&
                          account.currency !== Currency.Eur)

                      return (
                        <ListItem
                          key={id}
                          disabled={isDisabled}
                          onClick={() => {
                            const label = `${alias} -  ${balance?.available?.toLocaleString(
                              'en-US',
                            )} ${currency}
                            `
                            changeAccount(label, id)
                            changeCurrency(currency)
                          }}
                          button
                          className={`${classes.item} ${isDisabled && classes.disabled}`}
                          data-test="debitAccountItem"
                        >
                          <ListItemText>
                            {`
                              ${alias} - 
                              ${balance?.available?.toLocaleString('en-US')}
                              ${currency}
                            `}
                          </ListItemText>
                          {watch(name) === id && <Check fontSize={'small'} />}
                        </ListItem>
                      )
                    })}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
