import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import {
  Box,
  Button,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'

import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { TransferProps } from '../TrxTransferPage'
import { currencyFormat, dateFormat, trxStatusNormalize } from '../../../utils'
import {
  SignaturesTable,
  useUpdateTransactionStatusHandler,
} from '../../AplicationActions/Tabs/helpers'
import { SignatureProps } from '../../AplicationActions/contract'
import {
  LimitedAccessRight,
  Transaction,
  TransactionStatus,
  TransactionType,
} from '../../../graphql'
import { UploadDocument } from '../../AplicationActions'
import { PATH_PARAMS } from '../../../routes/paths'
import { useParams } from 'react-router'
import { useDetermineUserRights } from '../../../hooks'
import { useDownloadTrxStatement } from './hooks/useDownloadTrxStatement'

export const TrxTransferTab: FC<{
  transfer: TransferProps
  signedCount: number
  handleBack?: () => void
}> = ({ transfer, signedCount, handleBack }) => {
  const { txData, rawData, signatures } = transfer
  const { t } = useTranslation()
  const classes = useStyles()

  const statementAvailable = txData?.status === TransactionStatus.Completed

  const amountFormatted = txData?.amount && currencyFormat(Math.abs(+txData.amount))

  useUpdateTransactionStatusHandler(signatures)

  const accId = (rawData as Transaction)?.account?.id as string
  const trxId = rawData?.id as string
  const trxType = rawData?.__typename
  const isInternal = (rawData as Transaction)?.type === TransactionType.TransferInternal

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly
  const confirmationAvailable = !!(rawData as Transaction)?.isConfirmationAvailable
  const handleDownloadStatement = useDownloadTrxStatement(accId, trxId, confirmationAvailable)
  const downLoadText = confirmationAvailable
    ? t('downloadConfirmation', 'Download Confirmation')
    : t('downloadStatement', 'Download Statement')
  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('transactionStatus', 'Transaction status')}
                </Box>
              </TableCell>
              <TableCell>
                {txData?.status && (
                  <Box
                    component="span"
                    className={clsx(`roundedPill ${trxStatusNormalize(txData.status)}`)}
                  >
                    {trxType === 'CreditTransaction' &&
                    txData.status === TransactionStatus.Completed
                      ? t('received', 'Received')
                      : t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                )}
              </TableCell>
            </TableRow>

            <TableRow className={classes.borderTop}>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('referenceNumber', 'Reference number')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.referenceNumber}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('fromAccount', 'From account')}
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" gridGap={8}>
                  <Box component="span">{txData?.from}</Box>
                  <Box component="span" hidden={isInternal && txData?.fromIban === 'N/A'}>
                    ({txData?.fromIban})
                  </Box>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('toAccount', 'To account')}
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" gridGap={8}>
                  <Box component="span">{txData?.to}</Box>
                  <Box component="span" hidden={isInternal && txData?.toIban === 'N/A'}>
                    ({txData?.toIban})
                  </Box>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('amount', 'Amount')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">
                  {amountFormatted}&nbsp;{txData?.currency}
                </Box>
              </TableCell>
            </TableRow>

            {txData?.xRate && txData?.xRate !== 1 && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('exchangeRate', 'Exchange Rate')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">{txData.xRate}</Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('sent', 'Sent')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{dateFormat(txData?.sent)}</Box>
              </TableCell>
            </TableRow>

            {trxType === 'DebitTransaction' && (
              <TableRow>
                <TableCell width="200">
                  <Box component="span" className={classes.label}>
                    {t('createdBy', 'Created by')}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">
                    {(rawData as Transaction)?.executionAction?.initiatorName}
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {!isViewOnly && <UploadDocument accountId={accId} trxId={trxId} isTransaction={true} />}

        <Table className={classes.comment}>
          <TableBody>
            <TableRow>
              <TableCell width="200">
                <Box component="span" className={classes.label}>
                  {t('comments')}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="span">{txData?.comment ? txData?.comment : '-'}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {!isViewOnly && trxType === 'DebitTransaction' && (
          <SignaturesTable signatures={signatures as SignatureProps[]} signedCount={signedCount} />
        )}

        {statementAvailable && (
          <Box className={classes.modalFooter}>
            <Button
              variant="contained"
              className={classes.selectBut}
              color="primary"
              onClick={handleDownloadStatement}
              disableElevation
            >
              {downLoadText}
            </Button>
            <Button
              className={classes.cancelBut}
              variant="contained"
              onClick={handleBack}
              disableElevation
            >
              {t('close', 'Close')}
            </Button>
          </Box>
        )}
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
              {txData?.status && (
                <Box component="span">
                  <Box className={clsx(`roundedPill ${trxStatusNormalize(txData.status)}`)}>
                    {trxType === 'CreditTransaction' &&
                    txData.status === TransactionStatus.Completed
                      ? t('received', 'Received')
                      : t(`${trxStatusNormalize(txData.status)}`)}
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                {t('referenceNumber', 'Reference number')}
              </Box>
              <Box className={classes.mobileTableName}>{txData?.referenceNumber}</Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
              <Box className={classes.mobileTableName}>
                {amountFormatted}&nbsp;{txData?.currency}
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('fromAccount', 'From account')}</Box>
              <Box mt={isInternal && txData?.fromIban === 'N/A' ? 0 : 0.5}>
                <Typography noWrap>{txData?.from}</Typography>
                <Typography noWrap hidden={isInternal && txData?.fromIban === 'N/A'}>
                  ({txData?.fromIban})
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('toAccount', 'To account')}</Box>
              <Box mt={isInternal && txData?.toIban === 'N/A' ? 0 : 0.5}>
                <Typography noWrap>{txData?.to}</Typography>
                <Typography noWrap hidden={isInternal && txData?.toIban === 'N/A'}>
                  ({txData?.toIban})
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('sent', 'Sent')}</Box>
              <Box>{dateFormat(txData?.sent)}</Box>
            </Box>
            {trxType === 'DebitTransaction' && (
              <Box className={classes.mobileTableItem}>
                <Box className={classes.mobileTableLabel}>{t('createdBy', 'Created by')}</Box>
                <Box>{(rawData as Transaction)?.executionAction?.initiatorName}</Box>
              </Box>
            )}

            {!isViewOnly && <UploadDocument accountId={accId} trxId={trxId} isTransaction={true} />}

            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>{t('comments')}</Box>
              <Box>{txData?.comment ? txData?.comment : '-'}</Box>
            </Box>

            {!isViewOnly && trxType === 'DebitTransaction' && (
              <SignaturesTable
                signatures={signatures as SignatureProps[]}
                signedCount={signedCount}
              />
            )}

            {statementAvailable && (
              <Box className={classes.modalFooter}>
                <Button
                  variant="contained"
                  className={classes.selectBut}
                  color="primary"
                  onClick={handleDownloadStatement}
                  disableElevation
                >
                  {downLoadText}
                </Button>
                <Button
                  className={classes.cancelBut}
                  variant="contained"
                  onClick={handleBack}
                  disableElevation
                >
                  {t('close', 'Close')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
