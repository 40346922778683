import React from 'react'
import {
  AmpInUse,
  CardPaymentsAndAmp,
  CardPaymentsTransactionalDataRegion,
  Maybe,
  PreAssessmentPartner,
} from '../../../../graphql'
import { Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import {
  KeyAndLabelSelectType,
  TooltipPartnersDataType,
  TooltipTitleTextClassesType,
} from '../../../../types'
import { getLabelByKey, getLabelsSeparatedByCommas } from '../../../../utils'
import { cardPaymentsRegions } from '../../../../constants'
import { BusinessCategoriesList, CountriesUnknownList } from '../../../../utils/Data'
import i18n from '../../../../i18n'

export const makeTooltipTitleTextDoubleTextInputApm = (
  fieldName: 'apmCurrently' | 'apmRequired',
  data: Record<string, Array<Maybe<AmpInUse>> | null | undefined>,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> | undefined => {
  if (!data?.[fieldName]) return undefined

  return (
    <List disablePadding>
      {data?.[fieldName]?.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''}>
            <ListItemText
              primary={`${dataItem?.ampInUseTypeCurrentlyInUse} ${dataItem?.monthlyVolumeInEuro}`}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextMerchantWebsites = (
  data?: CardPaymentsAndAmp | null,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> | undefined => {
  if (!data?.merchantWebsites) return undefined

  return (
    <List disablePadding>
      {data?.merchantWebsites?.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''} key={index}>
            <ListItemText primary={dataItem} />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextDoubleInputBusinessRegions = (
  data?: Maybe<Maybe<CardPaymentsTransactionalDataRegion>[]>,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> | undefined => {
  if (!data) return undefined

  return (
    <List disablePadding>
      {data?.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''} key={index}>
            <ListItemText
              primary={`${dataItem?.businessCategoryPercentage}% ${getLabelByKey(
                dataItem?.businessCategoryRegion,
                cardPaymentsRegions,
              )}`}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextPartnersInformation = (
  partners: PreAssessmentPartner[],
  paymentPurposeData: KeyAndLabelSelectType[],
  onlyOnePartnerRequired: boolean,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> => {
  const tooltipData: TooltipPartnersDataType[] = partners.map((partner) => ({
    name: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('name', 'Name')}:
        </Typography>
        {partner.name}
      </>
    ),
    country: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('country', 'Country')}:
        </Typography>
        {getLabelByKey(partner.country, CountriesUnknownList)}
      </>
    ),
    bankCountry: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t(
            onlyOnePartnerRequired
              ? 'countryOfYourCompanyCounterpartyBank'
              : 'countryOfPartnerBank',
          )}
          :
        </Typography>
        {getLabelByKey(partner.bankCountry, CountriesUnknownList)}
      </>
    ),
    businessSector: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('businessCategory', 'Business Category')}:
        </Typography>
        {getLabelByKey(partner.businessSector, BusinessCategoriesList)}
      </>
    ),
    paymentPurpose: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('paymentPurpose', 'Payment purpose')}:
        </Typography>
        {getLabelsSeparatedByCommas(partner.paymentPurpose ?? [], paymentPurposeData)}{' '}
        {partner.paymentPurposeOther !== 'N/A' ? partner.paymentPurposeOther : ''}
      </>
    ),
    website: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t(
            'siteLinkNewPartner',
            'Website, app store link or social media profile of the counterparty',
          )}
          :
        </Typography>
        {partner.website}
      </>
    ),
  }))
  return (
    <List disablePadding>
      {tooltipData.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          {Object.keys(dataItem).map((key, index) => (
            <ListItem className={classes?.listItem ?? ''} key={`${key + index}`}>
              <ListItemText primary={dataItem[key as keyof TooltipPartnersDataType]} />
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextExpectedVolumes = (
  data: string[],
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> => {
  return (
    <List disablePadding>
      {data.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''}>
            <ListItemText primary={dataItem} />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

// const makeTooltipTitleText = (
//   visaVolumes: TotalPredictedMonthlyVolumes,
//   mastercardVolumes: TotalPredictedMonthlyVolumes,
// ): NonNullable<React.ReactNode> => {
//   return (
//     <List disablePadding>
//       <ListItem className={classes.listItem}>
//         <ListItemText
//           primary={`Visa (${t('domestic', 'Domestic')}: ${visaVolumes.domestic ?? 0},
//           ${t('eea', 'EEA')}: ${visaVolumes.eea ?? 0},
//           ${t('nonEea', 'Non-EEA')}: ${visaVolumes.nonEea ?? 0})`}
//         />
//       </ListItem>
//       <Divider className={classes.lightDivider} />
//       <ListItem className={classes.listItem}>
//         <ListItemText
//           primary={`Mastercard (${t('domestic', 'Domestic')}: ${mastercardVolumes.domestic ?? 0},
//           ${t('eea', 'EEA')}: ${mastercardVolumes.eea ?? 0},
//           ${t('nonEea', 'Non-EEA')}: ${mastercardVolumes.nonEea ?? 0})`}
//         />
//       </ListItem>
//     </List>
//   )
// }
