import React, { FC } from 'react'
import { Button, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  endOfMonth,
  endOfYear,
  isEqual as dateIsEqual,
  startOfDay,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns'
import { head, isEqual, last } from 'lodash'
import { useTranslation } from 'react-i18next'
import { dateRangeType } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    display: 'flex',
  },
}))

const PeriodButtonsSectionComponent: FC<{
  dateRange: dateRangeType
  setDateRange: React.Dispatch<React.SetStateAction<dateRangeType>>
}> = ({ setDateRange, dateRange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const startDate = head(dateRange as Date[])
  const endDate = last(dateRange as Date[])

  const compareDate = (firstDateToCompare: Date | undefined, secondDateToCompare: Date) =>
    dateIsEqual(startOfDay(firstDateToCompare as Date), startOfDay(secondDateToCompare))

  const buttonOptions = [
    {
      label: t('thisMonth', 'This month'),
      onClick: () => {
        const currentDate = new Date()
        const start = startOfMonth(currentDate)
        setDateRange([start, currentDate])
      },
      bold: compareDate(startDate, startOfMonth(new Date())) && compareDate(endDate, new Date()),
    },
    {
      label: t('previousMonth', 'Previous month'),
      onClick: () => {
        const currentDate = new Date()
        currentDate.setMonth(currentDate.getMonth() - 1)
        const start = startOfMonth(currentDate)
        const end = endOfMonth(currentDate)
        setDateRange([start, end])
      },
      bold:
        compareDate(startDate, startOfMonth(sub(new Date(), { months: 1 }))) &&
        compareDate(endDate, endOfMonth(sub(new Date(), { months: 1 }))),
    },
    {
      label: t('thisYear', 'This year'),
      onClick: () => {
        const currentDate = new Date()
        const start = startOfYear(currentDate)
        setDateRange([start, currentDate])
      },
      bold: compareDate(startDate, startOfYear(new Date())) && compareDate(endDate, new Date()),
    },
    {
      label: t('previousYear', 'Previous year'),
      onClick: () => {
        const currentDate = new Date()
        currentDate.setFullYear(currentDate.getFullYear() - 1)
        const start = startOfYear(currentDate)
        const end = endOfYear(currentDate)
        setDateRange([start, end])
      },
      bold:
        compareDate(startDate, startOfYear(sub(new Date(), { years: 1 }))) &&
        compareDate(endDate, endOfYear(sub(new Date(), { years: 1 }))),
    },
  ]

  return (
    <Grid container className={classes.buttons} direction="row" justifyContent="space-evenly">
      {buttonOptions.map(({ onClick, label, bold }) => (
        <Grid key={label} item xs={3} className={classes.button} justifyContent="center">
          <Button
            color="default"
            variant="text"
            size="small"
            onClick={onClick}
            className={bold ? classes.bold : ''}
          >
            <u>{label}</u>
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export const PeriodButtonsSection = React.memo(PeriodButtonsSectionComponent, isEqual)
