import React, { FC } from 'react'
import { Box, Container, Grid, Theme, makeStyles, SvgIcon, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ReactComponent as IconInfo } from '../../../../assets/images/icons/icon-exclamation-mark.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.warning.main,
    padding: '23px 22px 12px',
  },
  infoIcon: {
    color: theme.palette.warning.main,
  },
  container: {
    margin: '5px 0px 5px 0px',
    justifyContent: 'center',
  },
  tip: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontSize: '14px',
    marginTop: '2px',
  },
}))

export const TipsItem: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container component="div" maxWidth={false} disableGutters={true}>
        <Grid container className={classes.container}>
          <Grid item>
            <SvgIcon className={classes.infoIcon}>
              <IconInfo />
            </SvgIcon>
          </Grid>
          <Grid item>
            <Box className={classes.tip} ml={1}>
              <Typography variant={'h2'}>{t('signatureMissing', 'Signature Missing')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
