import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { debounce, find, head, isEmpty, isEqual } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Grid'
import {
  Button,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Check } from '@material-ui/icons'

import { AccountFilterOptions, locationStateType, TransactionsMethodsList } from '../../../types'
import { selectOptions } from '../../../utils/Data'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  popper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0px 0px 0px',
    width: '100%',
    left: 0,
    zIndex: 300,
    height: 'max-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paper: {
    marginRight: 0,
    maxWidth: '100%',
    maxHeight: '65vh',
    background: '#FFFFFF',
    borderRadius: 0,
    overflowX: 'hidden',
    boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
  },
  cardSelector: {
    marginLeft: 'auto',
    position: 'relative',
    background: '#FFFFFF',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: 0,
    },
  },
  cardLabel: {
    padding: '16px 24px 16px 14px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cardButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    width: '100%',
    height: 48,
    padding: 0,
    minHeight: 32,
    background: 'none',
    border: '1px solid #c4c4c4',
    boxSizing: 'border-box',
    position: 'relative',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: 5,
    },
    '&.open': {
      border: '1px solid #000000',
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        color: '#000000',
        transform: 'rotate(180deg)',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '5px 0 5px 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  item: {
    '& .MuiTypography-displayBlock': {
      marginBottom: '0px',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0.5em',
      marginRight: theme.spacing(2),
    },
    '& .flag-icon-lg': {
      fontSize: '0.8em',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    alignContent: 'start',
    paddingRight: '12px',
    position: 'static',
    width: '100%',
    left: '0px',
    top: '48px',
    background: '#FFFFFF',
    marginTop: '5px',
    justifyContent: 'space-between',
  },
  listItemTextLabel: {
    flexGrow: 17,
    flex: '0 0 auto',
    width: '92%',
  },
  listItemCheck: {
    flexGrow: 0,
    color: '#000000',
  },
  list: {
    boxShadow: '5px 5px 4px 0px rgba(34, 60, 80, 0.2)',
    backgroundColor: 'white',
  },
  arrowDropDown: {
    color: '#999999',
  },
}))
const TransactionsCardSelectComponent: FC<{
  data: TransactionsMethodsList[]
  cardId: string | number | undefined
  setCardId: (cardId: string) => void
  isCardholder: boolean
  setSearchMethodValue: React.Dispatch<React.SetStateAction<string | null>>
}> = ({ data, setCardId, cardId, isCardholder, setSearchMethodValue }) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [methodList, setMethodList] = useState<TransactionsMethodsList[]>(data)
  const [selectedItem, setSelectedItem] = useState<TransactionsMethodsList>()

  const { t } = useTranslation()
  const history = useHistory()
  const accountId = localStorage.getItem('selectedAccountId')
  const allMethodsValue = selectOptions[0] as TransactionsMethodsList

  const fromCardId = (history.location.state as locationStateType)?.cardId

  const handleChange = useCallback(
    (transactMethod) => {
      const id = transactMethod.key as string
      const accountId = transactMethod.accountId as string
      setCardId(id)
      id !== AccountFilterOptions.allMethods && localStorage.setItem('CardId', String(id))
      accountId && localStorage.setItem('selectedAccountId', String(accountId))
      setOpen(false)
      if (id !== cardId) {
        history.location.state = { cardId: id }
      }
      setSearchMethodValue(null)
    },
    [cardId, setCardId, setSearchMethodValue],
  )

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
    setSearchMethodValue(null)
  }

  const handleMethodChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim().toLowerCase()
      const filteredMethods = data.filter((item) => item.label?.toLowerCase().includes(value))
      const newMethodList = filteredMethods.length
        ? [...new Set([allMethodsValue, ...filteredMethods])]
        : [allMethodsValue]

      if (
        filteredMethods.length === 1 &&
        filteredMethods[0].key !== AccountFilterOptions.allMethods
      ) {
        setSearchMethodValue(value)
        setMethodList(data)
      } else {
        setMethodList(newMethodList)
      }
    }, 250),
    [data],
  )

  useEffect(() => {
    if (selectedItem?.key === AccountFilterOptions.bankTransfer) {
      handleChange(allMethodsValue)
    }
  }, [accountId])

  useEffect(() => {
    if (data.length === 1) {
      setSelectedItem(data[0])
    } else if (data.length >= 2) {
      const foundCard = find(data, (cardItem) => cardItem?.key === cardId)
      !foundCard ? setSelectedItem(allMethodsValue) : setSelectedItem(foundCard)
    }
  }, [data, cardId])

  useEffect(() => {
    if (isCardholder && data.length && !fromCardId) {
      const cardId = localStorage.getItem('CardId')
      const firstCardInList = head(data)?.key || ''
      const defaultCardId = cardId ? cardId : firstCardInList

      setCardId(defaultCardId.toString())
    }

    if (data.length === 1) {
      handleChange(data[0])
    }

    setMethodList([...new Set([allMethodsValue, ...data])])
  }, [data])

  return (
    <>
      <Box className={classes.cardSelector}>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={`${classes.cardButton} ${open ? 'open' : ''}`}
          disabled={data.length < 2}
        >
          <Typography component="div" className={classes.cardLabel} data-test="methodsFilter">
            {selectedItem?.label || t('allMethods', 'All methods')}
          </Typography>
          {open ? <ExpandMoreIcon /> : <ExpandMoreIcon className={classes.arrowDropDown} />}
        </Button>
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          className={classes.popper}
          placement="bottom-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <List id="menu-list-grow" className={classes.list}>
                    <ListItem>
                      <TextField
                        onChange={handleMethodChange}
                        label={t('selectMethod', 'Select method')}
                        variant={'standard'}
                        autoFocus={open}
                        fullWidth
                      />
                    </ListItem>
                    {!isEmpty(methodList) &&
                      methodList.map((method) => {
                        if (!method) return null
                        const { key, label } = method as TransactionsMethodsList
                        return (
                          <ListItem
                            key={key}
                            button
                            className={classes.item}
                            onClick={() => handleChange(method as TransactionsMethodsList)}
                          >
                            <ListItemText className={classes.listItemTextLabel}>
                              <Trans i18nKey={label as string} defaults={label as string} />
                            </ListItemText>
                            {cardId === key && (
                              <Check className={classes.listItemCheck} fontSize={'small'} />
                            )}
                          </ListItem>
                        )
                      })}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  )
}

export const TransactionsCardSelect = React.memo(TransactionsCardSelectComponent, isEqual)
