import React, { FC } from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '../../../components'
import { Action } from '../../../graphql'
import { OperationNames } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  rejectButton: {
    background: '#F5F5F5',
    color: '#000000',
    margin: '0 16px 0 0',
    '&:hover': {
      background: '#000000',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '50rem',
      position: 'fixed',
      bottom: 0,
      left: 0,
      margin: 0,
      '&:hover': {
        background: '#F5F5F5',
        color: '#000000',
      },
    },
  },
  signButton: {
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '50rem',
      position: 'fixed',
      bottom: '40px',
      right: '16px',
      marginRight: '1px',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}))

type HandleAllActionsButtonsPropsType = {
  pendingActionsList: Action[]
  isNoCheckedSignatures: boolean
  handleSignChecked: (procedureName: OperationNames | null) => void
}

const HandleAllActionsButtonsComponent: FC<HandleAllActionsButtonsPropsType> = ({
  pendingActionsList,
  isNoCheckedSignatures,
  handleSignChecked,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box hidden={!pendingActionsList.length}>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => handleSignChecked(OperationNames.reject)}
        className={classes.rejectButton}
        data-test="rejectButtonController"
      >
        {isNoCheckedSignatures
          ? t('rejectUnsigned', 'Reject unsigned')
          : t('rejectSelected', 'Reject selected')}
      </LoadingButton>
      <LoadingButton
        className={classes.signButton}
        type="submit"
        variant="outlined"
        color="primary"
        disableElevation
        onClick={() => handleSignChecked(OperationNames.sign)}
        data-test="signButtonController"
      >
        {isNoCheckedSignatures
          ? t('signUnsigned', 'Sign unsigned')
          : t('signSelected', 'Sign selected')}
      </LoadingButton>
    </Box>
  )
}

export const HandleAllActionsButtons = React.memo(HandleAllActionsButtonsComponent, isEqual)
