import { head } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ReactComponent as BatchIcon } from '../../assets/images/icons/upload_up.svg'
import { AccountStatus, FundsType, useGetContractAccountsQuery } from '../../graphql'
import { isBatchFileUploaded } from '../../graphql/local/dashboard'
import { useIsLimitedContract } from '../../hooks'
import { PATH_PARAMS } from '../../routes/paths'
import { ButtonWithTooltip } from '../Common'
import { useHandleNewPayments, useIsContractInactive } from './hooks'
import { useMenuStyles } from './menuStyles'

const useStyles = makeStyles(() => ({
  uploadPayment: {
    display: 'flex',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 12,
      width: 2,
      height: 24,
      background: '#F0F0F0',
    },
  },
}))

type PaymentButtonsProps = {
  isActiveAccounts: boolean
  limitedMode: boolean
}

const PaymentButtonsComponent: FC<PaymentButtonsProps> = ({ isActiveAccounts, limitedMode }) => {
  const { t } = useTranslation()
  const classes = { ...useMenuStyles(), ...useStyles() }

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const isFileUploaded = useReactiveVar(isBatchFileUploaded)

  const { data: accountsData, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
  })

  const contractInactive = useIsContractInactive()
  const { isLimitedContract, contractStatusLabel } = useIsLimitedContract()

  const [handleNewPayment, handleBatchPayment] = useHandleNewPayments(isActiveAccounts)

  const tooltipBatchMessage = useMemo(() => {
    if (isLimitedContract) {
      return t(
        'isNotAvailable',
        `Batch payment is not available, because the contract is ${contractStatusLabel}`,
        { option: 'Batch payment', contractStatusLabel },
      )
    } else if (!isActiveAccounts) {
      return t(
        'uploadBatchIsNotAvailable',
        "Upload batch payment is not available because you don't have active accounts",
      )
    }
    return isFileUploaded
      ? `${t(
          'currentBatchPaymentShouldBeCompleted',
          'The current batch payment should be completed or cancelled',
        )}`
      : `${t('uploadBatchFile', 'Upload batch payment file')}`
  }, [isActiveAccounts, isFileUploaded, isLimitedContract])

  const tooltipNewPaymentMessage = isLimitedContract
    ? t(
        'isNotAvailable',
        `New payment is not available, because the contract is ${contractStatusLabel}`,
        { option: 'New payment', contractStatusLabel },
      )
    : t(
        'uploadBatchIsNotAvailable',
        "Upload batch payment is not available because you don't have active accounts",
      )

  const isOnlyOneDepositGuaranteed =
    accountsData?.accounts?.length === 1 &&
    head(accountsData?.accounts)?.fundsType === FundsType.DepositGuaranteed

  const isShouldDisabledPayment =
    limitedMode || !isActiveAccounts || isLimitedContract || loading || isOnlyOneDepositGuaranteed

  return (
    <Box className={classes.uploadPayment}>
      <Tooltip title={tooltipBatchMessage} placement="bottom-end" arrow>
        <span>
          <IconButton
            disabled={contractInactive}
            aria-label="open drawer"
            edge="start"
            onClick={handleBatchPayment}
            className={clsx(
              classes.batchButton,
              (isFileUploaded || !isActiveAccounts) && classes.disabled,
            )}
          >
            <BatchIcon style={{ fill: !contractInactive ? 'black' : 'grey' }} />
          </IconButton>
        </span>
      </Tooltip>

      <ButtonWithTooltip
        title={tooltipNewPaymentMessage}
        disabled={isShouldDisabledPayment}
        placement="bottom-end"
        variant="outlined"
        className={clsx(classes.paymentButton, (limitedMode || !isActiveAccounts) && 'disabled')}
        onClick={handleNewPayment}
        hiddeTooltip={isOnlyOneDepositGuaranteed}
        data-test="NewPayment"
      >
        {t('newPayment', 'New payment')}
      </ButtonWithTooltip>
    </Box>
  )
}

export const PaymentButtons = React.memo(PaymentButtonsComponent)
