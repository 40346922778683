import * as yup from 'yup'
import i18n from '../../i18n'
import { isEmpty, find, includes } from 'lodash'
import { isPast, isToday } from 'date-fns'

import { PoiDocTypesNotMandatory } from '../../utils/Data'

export const DocumentTypePersonInputSchema = yup.object().shape({
  filesForUpload: yup.mixed().notRequired(),
  filesForUploadAddress: yup.mixed().notRequired(),
  documentType: yup
    .string()
    .test(
      'documentTypeCheck',
      i18n.t('selectDocumentType', 'Please select the document type'),
      function () {
        return (
          !this.parent.personRequired ||
          !!find(this?.parent?.filesForUpload, (fileItem) =>
            includes(PoiDocTypesNotMandatory, fileItem?.docType),
          ) ||
          !isEmpty(this.parent.documentType)
        )
      },
    )
    .test(
      'documentTypeAlreadyNotRequiredDocsAddedCheck',
      i18n.t(
        'IDFrontDrivingLicenseFrontPassportMandatory',
        'One file of "ID Front", "Driving License Front" or "Passport" is mandatory',
      ),
      function () {
        return !this.parent.personRequired // || !isEmpty(this.parent.documentType)
      },
    ),
  documentTypeAddress: yup
    .string()
    .test(
      'documentTypeAddressCheck',
      i18n.t('pleaseSelectTheDocumentType', 'Please select the document type'),
      function () {
        return !this.parent.addressRequired || !isEmpty(this.parent.documentTypeAddress)
      },
    )
    .test(
      'documentTypeAddressAlreadyNotRequiredDocsAddedCheck',
      i18n.t('proofResidenceMandatory', 'Proof of address is mandatory'),
      function () {
        return !this.parent.addressRequired
      },
    ),
  expiryDate: yup
    .date()
    .nullable()
    .typeError(i18n.t('invalidDateFormat', 'Invalid date format (DD/MM/YYYY)'))
    .default(undefined)
    .test('expiryDate', i18n.t('thisFieldIsRequired', 'This field is required'), function () {
      return !!this.parent.expiryDate || !this?.parent?.documentType
    })
    .test({
      name: 'minDate',
      message: i18n.t('dateExpiryPast', "Expiry date can't be in the past"),
      test: (value) => {
        if (!!value) {
          return isToday(new Date(value)) || !isPast(new Date(value))
        }
        return true
      },
    }),
  expiryDateAddress: yup
    .date()
    .notRequired()
    .typeError(i18n.t('invalidDateFormat', 'Invalid date format (DD/MM/YYYY)'))
    .default(undefined)
    .test({
      name: 'minDateExpiryDateAddress',
      message: i18n.t('dateExpiryPast', "Expiry date can't be in the past"),
      test: (value) => {
        if (!!value) {
          return isToday(new Date(value)) || !isPast(new Date(value))
        }
        return true
      },
    }),
})

export type DocumentTypePersonInput = yup.Asserts<typeof DocumentTypePersonInputSchema>
