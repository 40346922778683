import React from 'react'
import { ALL_EXCEPT_CYRILLIC_REGEXP } from '../../../constants/validations'
import { CommonTextField } from './CommonTextField'
import { useFormContext } from 'react-hook-form'
import { FormInputFieldEntity } from '../../../types'
import { useTranslation } from 'react-i18next'

export const FormTextField: React.FC<FormInputFieldEntity> = ({
  type,
  name,
  label,
  placeholder,
  validations,
  ...rest
}) => {
  const { t } = useTranslation()
  const { register, errors } = useFormContext()
  const error = errors ? errors[name] : null

  return (
    <CommonTextField
      type={!!type ? type : 'text'}
      name={name}
      label={label}
      id={name}
      placeholder={placeholder}
      error={!!error}
      onKeyDown={(event) => {
        if (!ALL_EXCEPT_CYRILLIC_REGEXP.test(event.key)) {
          event.preventDefault()
        }
      }}
      helperText={error && t(error.message)}
      inputProps={{
        autoComplete: 'new-password',
      }}
      InputProps={{
        margin: 'none',
      }}
      inputRef={register(validations)}
      {...rest}
    />
  )
}
