import React, { useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'
import clsx from 'clsx'
import {
  Drawer,
  Toolbar,
  Box,
  useTheme,
  CssBaseline,
  AppBar,
  IconButton,
  Hidden,
  List,
  MenuItem,
  Typography,
  MenuList,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import { Footer } from '../Footer'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useMenuStyles } from './menuStyles'
import {
  applicationType,
  isModalDialogOpen as isModalDialogOpenVar,
  isUnsaveFormData as isUnsaveFormDataVar,
} from '../../graphql/local'
import {
  ActionSignatureStatus,
  AuthorizedPersonStatuses,
  ContractStatusType,
  useActionSignatureRequestsCountQuery,
  useGetContractStatusQuery,
} from '../../graphql'
import { MainMenuItem } from './MainMenuItem'
import { ReactComponent as IconHome } from '../../assets/images/icons/home_2.svg'
import { ReactComponent as IconPencil } from '../../assets/images/icons/icon_pencil.svg'
import { ReactComponent as IconUser } from '../../assets/images/icons/user.svg'
import { makeStyles } from '@material-ui/core/styles'
import { CompanySelect } from '../Common'
import { useCurrentUser, useDetermineUserRights } from '../../hooks'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  list: {
    position: 'relative',
    '& > span': {
      width: '100%',
    },
  },
  backWrapp: {
    marginTop: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 1.5, 3, 4),
    '& .MuiFormControl-root': {
      width: '100%',
      minHeight: 'auto',
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      bottom: 5,
      margin: 0,
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    zIndex: 1305,
  },
  drawerInner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    overflow: 'auto',
  },
}))

export const ApplicationMenu: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = { ...useMenuStyles(), ...useStyles() }
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const user = useCurrentUser()

  const { [PATH_PARAMS.applicationId]: appId } = useParams() as Record<string, string>
  const applicationId = isNaN(parseInt(appId, 10)) ? '' : appId

  const { userRights } = useDetermineUserRights(applicationId)
  const { data: statusData } = useGetContractStatusQuery({
    variables: { id: applicationId },
    skip: !applicationId || Number.isNaN(Number(applicationId)),
  })
  const { data: signaturesCount } = useActionSignatureRequestsCountQuery({
    variables: {
      contractId: applicationId,
      statuses: [ActionSignatureStatus.Pending],
    },
    skip: !applicationId || Number.isNaN(Number(applicationId)),
  })

  const contractStatusCheck = useMemo(() => {
    return statusData?.contract?.status !== ContractStatusType.Draft
  }, [statusData?.contract, userRights])

  const verificationNeed = useMemo(() => {
    const result = {
      //!user?.emailConfirmed || !user?.isPhoneVerified ||
      status: userRights?.status === AuthorizedPersonStatuses.Invited, //(!!userRights?.signatoryRight && !user?.videoVerified),
      step: 0,
    }
    if (!!userRights?.signatoryRight && !user?.videoVerified) result.step = 3
    if (!user?.isPhoneVerified) result.step = 2
    if (!user?.emailConfirmed) result.step = 1

    return result
  }, [user, userRights])
  const handleDrawerToggle = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setMobileOpen(!mobileOpen)
  }

  const handleBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      history.push(APP_PATHS.root, { from: 'dashboard' })
    },
    [history],
  )

  useEffect(() => {
    return () => {
      isUnsaveFormDataVar(false)
      isModalDialogOpenVar(false)
    }
  }, [])

  const drawer = (
    <div
      className={classes.drawerInner}
      onClick={() => {
        setMobileOpen(false)
      }}
    >
      <Box>
        <Hidden smDown>
          <Toolbar className={classes.logoMenu}>
            <img src={process.env.PUBLIC_URL + config.logo} alt={'company_logo'} />
          </Toolbar>
        </Hidden>

        {!!applicationId && contractStatusCheck ? null : (
          <Box className={`${classes.backWrapp} ${mobileOpen ? classes.backWrappApp : ''}`}>
            <MenuList>
              <MenuItem
                button
                onClick={handleBack}
                className={classes.backItem}
                data-test="btn-back"
              >
                <Box component={'span'} className={`${classes.backIcon} black`}>
                  <ArrowBackIcon fontSize="small" />
                </Box>
                <Typography variant="body1">
                  <b>{t('quitApplication', 'Quit application')}</b>
                </Typography>
              </MenuItem>
              {!statusData?.contract?.ownershipTransferInitiated &&
                applicationType() === 'business' && (
                  <MenuItem
                    button
                    disabled={!applicationId}
                    onClick={() =>
                      history.push(
                        generatePath(APP_PATHS.application.transferContractOwnership, {
                          [PATH_PARAMS.applicationId]: applicationId,
                        }),
                      )
                    }
                    className={classes.backItem}
                    data-test="btn-back"
                  >
                    <Box component={'span'} className={`${classes.backIcon} black`}>
                      <SyncAltIcon fontSize="small" />
                    </Box>
                    <Typography variant="body1">
                      <b>{t('transferContract', 'Transfer Contract')}</b>
                    </Typography>
                  </MenuItem>
                )}
            </MenuList>
          </Box>
        )}

        {!!applicationId && contractStatusCheck && (
          <>
            <Box className={classes.backWrapp}>
              <CompanySelect contractId={applicationId} name={`${user?.fullName}`} />
            </Box>
            <List className={classes.list} data-test="sideMenuList">
              <MainMenuItem
                icon={<IconHome />}
                primary={t('application', 'Application')}
                path={generatePath(APP_PATHS.application.status, {
                  [PATH_PARAMS.applicationId]: applicationId,
                })}
                // disabled={limitMode}
              />
              {verificationNeed.status && (
                <MainMenuItem
                  icon={<IconUser />}
                  countNum={1}
                  primary={t('personalOnboarding', 'Personal onboarding')}
                  path={generatePath(APP_PATHS.application.onboarding.personalDetails, {
                    [PATH_PARAMS.applicationId]: applicationId,
                  })}
                  detailsPath={[
                    APP_PATHS.application.onboarding.videoVerification,
                    APP_PATHS.application.onboarding.phoneVerification,
                    APP_PATHS.application.onboarding.complete,
                  ]}
                />
              )}

              {!!userRights?.signatoryRight && (
                <MainMenuItem
                  icon={<IconPencil />}
                  countNum={signaturesCount?.actionSignatureRequestsCount as number}
                  primary={t('actions', 'Actions')}
                  path={generatePath(APP_PATHS.application.actions, {
                    [PATH_PARAMS.applicationId]: applicationId,
                  })}
                  disabled={
                    !signaturesCount?.actionSignatureRequestsCount ||
                    signaturesCount?.actionSignatureRequestsCount === 0
                  }
                />
              )}
            </List>
          </>
        )}
      </Box>
      <Box className={classes.footerWrap}>
        <Footer isMenu={true} isMenuOpen={true} />
      </Box>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={`${clsx(classes.appBarShift, classes.appBar)}`}>
        <Hidden mdUp>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              {mobileOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Box display="flex" flexGrow={1} justifyContent="center">
              <Box className={classes.logo}>
                <img src={process.env.PUBLIC_URL + config.logo} alt={'company_logo'} />
              </Box>
            </Box>
          </Toolbar>
        </Hidden>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}
