import { Box, FormControl, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, { FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { CountriesUnknownList, uboLegalEntityTypesListFiltered } from '../../../utils/Data'
import {
  ContactSupportLink,
  FormAutocompleteSelect,
  FormControlledTextField,
  FormDatePickerField,
  GridRow,
} from '../../Common'
import ControlledIconTooltip from '../../Common/Tooltips/ControlledIconTooltip'
import { CountryAutocompleteSelect } from '../../Common/Selectors/CountryAutocompleteSelect'
import { useCommonStyles } from './PreAssessmentQuestionnaire'
import { makeStyles } from '@material-ui/styles'
import { useFormContext } from 'react-hook-form'
import { ContractProfileChangeAnswers } from '../../../graphql'
import { getLabelByKey } from '../../../utils'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'
import { ControlledCountryAutoSelect } from '../../Common/Selectors/ControlledCountryAutoSelect'

const useStyles = makeStyles((theme: Theme) => ({
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: 'rgb(245 245 245)',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
  dirtyControl: {
    position: 'relative',
  },
  rightSpace: {
    '& .MuiInputBase-input': {
      [theme.breakpoints.down(386)]: {
        marginRight: theme.spacing(2),
      },
    },
  },
}))

const CompanyInformationComponent: FC<{
  isFromBusinessDetails?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ isFromBusinessDetails = false, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useStyles(), ...useCommonStyles() }
  const methods = useFormContext()
  const { formState, control } = methods

  return (
    <>
      <Box mt={4}>
        <Typography variant="h3" className={classes.title}>
          {t('companyInformation', 'Company Information')}
        </Typography>
      </Box>
      <GridRow>
        <FormControl
          className={clsx(
            classes.formControl,
            classes.formControlMultiSelect,
            !isFromBusinessDetails ? classes.rightSpace : '',
          )}
        >
          <FormAutocompleteSelect
            name="entityType"
            label={t('entityType', 'Entity Type')}
            readOnly={isFromBusinessDetails}
            data={uboLegalEntityTypesListFiltered}
            data-test="entityType"
          />
          {!isFromBusinessDetails && (
            <Box className={classes.iconTooltip}>
              <ControlledIconTooltip
                Icon={HelpOutlineIcon}
                placement="top"
                title={
                  <Box>
                    <Typography className={classes.titleTooltip} color="inherit">
                      <b>{t('informationAboutEntityTypes')}</b>
                    </Typography>
                    <Typography className={classes.titleTooltip} color="inherit">
                      <b> {t('domiciliaryHolding')}</b>
                    </Typography>
                    <Box component="div" className={classes.textTooltip}>
                      {t('domiciliaryHoldingDescription')}
                    </Box>
                    <Box component="div" className={classes.textTooltip}>
                      {t('domiciliaryHoldingDescription2')}
                    </Box>
                    <Typography className={classes.titleTooltip} color="inherit">
                      <b>{t('operatingNotQuoted')}</b>
                    </Typography>
                    <Box component="div" className={classes.textTooltip}>
                      {t('operatingNotQuotedDescription')}
                    </Box>
                    <Typography className={classes.titleTooltip} color="inherit">
                      <b>{t('operatingQuoted')}</b>
                    </Typography>
                    <Box component="span" className={classes.textTooltip}>
                      {t('operatingQuotedDescription')}
                    </Box>
                  </Box>
                }
              />
            </Box>
          )}
        </FormControl>
      </GridRow>
      <GridRow>
        <FormControlledTextField
          label={t('legalName', 'Legal Name')}
          name="companyName"
          type="text"
          multiline
          fullWidth={true}
          required={false}
          data-test="companyName"
        />
        {changedContractProfileData?.companyName && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.companyName}
            title={changedContractProfileData.companyName}
          />
        )}
      </GridRow>
      <GridRow>
        <FormControlledTextField
          label={t('tradingNameDetails', 'Trading Name')}
          name="tradingName"
          type="text"
          fullWidth={true}
          multiline
          required={false}
          data-test="tradingName"
        />
        {changedContractProfileData?.tradingName && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.tradingName}
            title={changedContractProfileData.tradingName}
          />
        )}
      </GridRow>
      <GridRow>
        <FormDatePickerField
          name="registrationDate"
          label={t('dateOfRegistration', 'Date of Registration')}
          isShiftTimezone={false}
          noFuture
          readOnly={isFromBusinessDetails}
        />
      </GridRow>
      <GridRow>
        <FormControl className={`${classes.formControl}`}>
          <CountryAutocompleteSelect
            name="country"
            label={t('countryOfRegistration', 'Country of Registration')}
            data-test="countryOfRegistration"
            readOnly={isFromBusinessDetails}
          />
          {!isFromBusinessDetails && (
            <Typography className={classes.inputHelperText}>
              {t('ifYouDontSeeYourCountryHere, ', 'If you don’t see your country here, ')}
              <ContactSupportLink newTab />
            </Typography>
          )}
        </FormControl>
      </GridRow>
      <GridRow>
        <FormControlledTextField
          className={isFromBusinessDetails ? classes.readOnly : ''}
          label={t('commercialRegistrationNumber', 'Commercial Registration Number')}
          name="registryNumber"
          type="text"
          fullWidth={true}
          required={false}
          readOnly={isFromBusinessDetails}
          inputProps={{
            readOnly: isFromBusinessDetails,
          }}
          data-test="commercialRegistrationNumber"
        />
      </GridRow>
      <GridRow>
        <ControlledCountryAutoSelect
          name="countryOfTaxResidence"
          label={t('countryOfTaxResidence', 'Country of Tax Residence')}
          data-test="countryOfTaxResidence"
          control={control}
        />
        {changedContractProfileData?.preAssessment?.countryOfTaxResidence && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            title={getLabelByKey(
              changedContractProfileData?.preAssessment?.countryOfTaxResidence,
              CountriesUnknownList,
            )}
          />
        )}
      </GridRow>
      <GridRow>
        <FormControlledTextField
          label={t('taxId', 'Tax ID')}
          name="taxId"
          type="text"
          fullWidth={true}
          required={false}
          data-test="taxId"
        />
        {changedContractProfileData?.taxId && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.taxId}
            title={changedContractProfileData.taxId}
          />
        )}
      </GridRow>
    </>
  )
}

export const CompanyInformation = React.memo(CompanyInformationComponent)
