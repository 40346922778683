import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, makeStyles, Grid, Collapse } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { ContactSupportLink } from './ContactSupportLink'
import { ResendButton } from './Buttons'
import { TwoFaCodeDispatchMode } from '../../graphql'

const useStyles = makeStyles((theme: Theme) => ({
  btn_resend: {
    fontSize: theme.spacing(2.25),
    height: 47.5,
    maxWidth: 264,
    backgroundColor: 'black',
    color: 'white',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  bottomModal: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mrgTop: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    left: 0,
    right: 0,
  },
}))

export const TwoFAModalRenderButton: FC<{
  isNotShow: boolean
  secondsLeft: number
  btnDisable: boolean
  onReSend: (dispatchMode?: TwoFaCodeDispatchMode) => void
  isShowContactSupportLink: boolean
}> = ({ isNotShow, secondsLeft, btnDisable, onReSend, isShowContactSupportLink }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [resentViaCall, setRentViaCall] = useState(false)

  const showCallOption = !resentViaCall && secondsLeft !== 0 && secondsLeft <= 120

  return isNotShow ? null : (
    <Grid item xs={12} className={classes.mrgTop}>
      <Box className={classes.bottomModal}>
        {isShowContactSupportLink ? (
          <ContactSupportLink homeToLink={false}>
            <Button
              className={classes.btn_resend}
              size="large"
              variant="contained"
              disableElevation
              color="primary"
              fullWidth
            >
              {t('contactSupport', 'Contact support')}
            </Button>
          </ContactSupportLink>
        ) : (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <ResendButton
                className={classes.btn_resend}
                fullWidth
                size="large"
                variant="contained"
                disableElevation={true}
                disabled={btnDisable}
                onClick={() => onReSend()}
                secondsLeft={secondsLeft}
              >
                {t('resendCode', 'Resend code')}
              </ResendButton>
            </Grid>
            <Collapse in={showCallOption}>
              <Grid item>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    onReSend(TwoFaCodeDispatchMode.TextToSpeech)
                    setRentViaCall(true)
                  }}
                >
                  {t('resendCodeByCall', "SMS didn't arrive? Click to get a call instead.")}
                </Button>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </Box>
    </Grid>
  )
}
