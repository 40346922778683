import React, { FC, useCallback, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { APP_PATHS } from '../../routes/paths'
import config from '../../config'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px)',
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto 0px',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'right',
    padding: theme.spacing(3, 3),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalBody: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
  bodyText: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
  },
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export const InviteOnlyModal: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const handleClose = useCallback(() => {
    //not needed
  }, [])

  const handleConfirm = useCallback(async () => {
    history.push(generatePath(APP_PATHS.auth.login))
  }, [])

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={`cp-modal-title`}
      aria-describedby={`cp-modal-description`}
    >
      <Box style={modalStyle} className={classes.modal}>
        <Box className={classes.modalHeader}>&nbsp;</Box>
        <Box className={classes.modalBody}>
          <Typography className={classes.bodyText}>
            {t(
              'inviteOnly',
              'Due to high demand, {{val1}} is accepting applications by invite only. Thank you.',
              {
                val1: config.name,
              },
            )}
          </Typography>
        </Box>
        <Box className={classes.modalFooter}>
          <Button variant="contained" color="primary" onClick={handleConfirm} disableElevation>
            {t('ok')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
