import React, { FC, useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { debounce, DebouncedFunc } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import { millisecondsToSeconds } from 'date-fns'
import Modal from '@material-ui/core/Modal'
import clsx from 'clsx'

import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as IconDevice } from '../../assets/images/icons/2faIcon.svg'
import { ReactComponent as IconX } from '../../assets/images/icons/icon-x.svg'
import { ReactComponent as IconDeviceWarning } from '../../assets/images/icons/iconDeviceWarning.svg'
import { Theme } from '@material-ui/core/styles'

import { TwoFaMethod, useUpdateOwnPasswordMutation } from '../../graphql'
import { resendDelay } from '../../graphql/local'
import { checkFullCode } from '../../utils'
import { InputCodeField } from './InputCode'
import { cardAlert, personalProfileSettingsTab } from '../../graphql/local/dashboard'
import { useCurrentUser } from '../../hooks'
import { TwoFAModalRenderButton } from './TwoFAModalRenderButton'
import { generatePath, useHistory } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useParams } from 'react-router'
import { SettingsTabsEnum } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 3.5),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 4.5),
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  btnChange2FA: {
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    margin: theme.spacing(2, 0, 0, 0),
  },
  btnChange: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const ChangePasswordModal: FC<{
  open: boolean
  handleClose: React.Dispatch<React.SetStateAction<boolean>>
  challengeId?: string | null
  currentPassword?: string
  phoneLastFourDigits?: string | null
  newPassword?: string
  setIncorrectPasswordErr: (errorMessage: string) => void
  alertDebounce: React.MutableRefObject<DebouncedFunc<() => void>>
}> = ({
  open,
  handleClose,
  challengeId,
  currentPassword,
  newPassword,
  phoneLastFourDigits,
  setIncorrectPasswordErr,
  alertDebounce,
}) => {
  const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [attemptsSend, setAttemptsSend] = useState(1)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMessage, setValidMessage] = useState<string>('')
  const currentUser = useCurrentUser()

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [updateOwnPasswordMutation] = useUpdateOwnPasswordMutation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const startInput = useRef<HTMLInputElement>(null)
  const enteredCode = Object.values(code).join('')
  const resultCheckFullCode = checkFullCode(code)

  const onReSend = useCallback(() => {
    setBtnDisable(true)
    setSecondsLeft(millisecondsToSeconds(resendDelay()))
    setAttemptsSend((prev) => prev + 1)
  }, [setAttemptsSend])

  const debouncedRef = useRef(
    debounce(() => {
      handleClose(true)
      setCode(initialValue)
      setIsError(false)
      setValidMessage('')
    }, 2000),
  )

  const clearDebounce = () => {
    debouncedRef.current.cancel()
    handleClose(false)
  }

  useEffect(() => clearDebounce(), [])

  const setSuccessCodeVerification = (isCorrectCurrentPassword: boolean) => {
    const alertMessage = isCorrectCurrentPassword
      ? t('thePasswordChanged', 'The password has been changed successfully!')
      : t('currentPasswordIsIncorrect', 'Current password is incorrect')
    setValidMessage(t('codeCorrect', 'Code is correct'))
    setLoading(false)
    debouncedRef.current()
    cardAlert(alertMessage)
    alertDebounce.current()
    !isCorrectCurrentPassword && setIncorrectPasswordErr(alertMessage)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const setFailedCodeVerification = (e) => {
    setIsError(true)
    if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
      setValidMessage(t('codeHasExpired', 'Code has expired'))
    } else {
      setValidMessage(t('codeIncorrect', 'Code is incorrect'))
    }
    setLoading(false)
    if (null !== startInput.current) {
      startInput.current.focus()
    }
  }

  const sendCode = useCallback(async () => {
    if (enteredCode.length === 6) {
      setLoading(true)
      try {
        const { data } = await updateOwnPasswordMutation({
          variables: {
            challengeId: `${challengeId}`,
            code: +enteredCode,
            currentPassword: `${currentPassword}`,
            newPassword: `${newPassword}`,
          },
        })
        if (data?.updateOwnPassword) {
          setSuccessCodeVerification(true)
        }
      } catch (e) {
        e.message === 'Incorrect password'
          ? setSuccessCodeVerification(false)
          : setFailedCodeVerification(e)
      }
    }
  }, [code, setSuccessCodeVerification, setFailedCodeVerification])

  const onClose = useCallback(() => {
    setCode(initialValue)
    setIsError(false)
    setValidMessage('')
    handleClose(false)
  }, [handleClose, initialValue])
  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  useEffect(() => {
    code.d6 && sendCode()
  }, [code])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [open, btnDisable])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMessage('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  const confirmPassportMessage = useMemo(() => {
    return currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>{phoneLastFourDigits ? `${phoneLastFourDigits.slice(2)}` : ''}</b>.{' '}
        {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [phoneLastFourDigits, currentUser])

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={clsx(
            classes.paper,
            resultCheckFullCode && isError && 'error',
            validMessage && !isError && 'success',
          )}
        >
          <IconButton
            className={classes.btnClose}
            color="primary"
            aria-label="close modal"
            component="span"
            onClick={onClose}
          >
            <IconX />
          </IconButton>

          <Grid className="iconContainer">{!isError ? <IconDevice /> : <IconDeviceWarning />}</Grid>

          <Box textAlign="center" mb={1}>
            <Typography variant={'h2'}>
              {t('confirmСhangePassword.', 'Confirm change password')}
            </Typography>
          </Box>

          <Box textAlign="center">{confirmPassportMessage}</Box>

          <InputCodeField
            startInput={startInput}
            currencyValue={code}
            setCode={handleSetCode}
            loading={loading}
          />

          <Grid container justifyContent="center" className="infoText">
            {!!validMessage && resultCheckFullCode && <span>{validMessage}</span>}
          </Grid>

          <TwoFAModalRenderButton
            isNotShow={
              (validMessage && !isError) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp
            }
            secondsLeft={secondsLeft}
            btnDisable={btnDisable}
            onReSend={onReSend}
            isShowContactSupportLink={attemptsSend > 5}
          />
          <Grid item xs={12} className={classes.btnChange}>
            <Link
              component="button"
              onClick={() => {
                personalProfileSettingsTab(2)
                history.push(
                  generatePath(APP_PATHS.dashboard.settings, {
                    [PATH_PARAMS.applicationId]: applicationId,
                  }) + `?tabId=${SettingsTabsEnum.twoFactor}`,
                )
              }}
              className={classes.btnChange2FA}
              underline="always"
            >
              {t('changeVerificationMethod', 'Change verification method')}
            </Link>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
