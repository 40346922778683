import React, { useCallback, useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core'

import { ReactComponent as IconDots } from '../../assets/images/icons/icon_dots_vertical.svg'
import {
  Scalars,
  ContractStatusType,
  UboType,
  LegalEntityOrIndividual,
  Individual,
  useActionSignatureRequestsCountQuery,
  ActionSignatureStatus,
} from '../../graphql'
import { applicationType as applicationTypeVar } from '../../graphql/local'
import { useDetermineUserRights } from '../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { AppActions, ExtendedAccessContractsTypesList, statusActions } from '../../constants'

type ActionsMenuProps = {
  contractId: Scalars['ID']['input']
  status: ContractStatusType
  updateFlag: (value: boolean) => void
  deleteApp: (value: string | number) => void
  hasAPs: boolean
  hasUbo: boolean
  hasPendingBusinessDetails: boolean
  ownerType: UboType
  owner: LegalEntityOrIndividual
}

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  contractId,
  status,
  updateFlag,
  deleteApp,
  hasAPs,
  hasUbo,
  hasPendingBusinessDetails,
  ownerType,
  owner,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()

  const { data: signaturesCount } = useActionSignatureRequestsCountQuery({
    variables: { contractId, statuses: [ActionSignatureStatus.Pending] },
  })

  const { userRights } = useDetermineUserRights(contractId as string | number)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onView = useCallback(() => {
    let redirectPath = APP_PATHS.application.status
    if (ExtendedAccessContractsTypesList.includes(status)) {
      const regularPath =
        signaturesCount?.actionSignatureRequestsCount === 0
          ? APP_PATHS.dashboard.home
          : APP_PATHS.dashboard.actions

      redirectPath = userRights?.isIntroducerContractOwner
        ? APP_PATHS.dashboard.partnerManagementOverview
        : regularPath
    }
    history.push(generatePath(redirectPath, { [PATH_PARAMS.applicationId]: contractId }))
  }, [contractId])

  const onEdit = useCallback(() => {
    let redirectPath = APP_PATHS.application.review
    if (ownerType === UboType.NaturalPerson) {
      applicationTypeVar('personal')
      if (!(owner as Individual).isVerified) {
        redirectPath = APP_PATHS.application.individual.identityVerification
      }
    } else if (status === ContractStatusType.Draft) {
      if (hasPendingBusinessDetails) {
        redirectPath = APP_PATHS.application.edit
      } else if (!hasAPs) {
        redirectPath = APP_PATHS.application.authorizedPersons.add
      } else if (!hasUbo) {
        redirectPath = APP_PATHS.application.authorizedPersons.list
      } else {
        redirectPath = APP_PATHS.application.beneficialOwners.list
      }
    }
    history.push(generatePath(redirectPath, { [PATH_PARAMS.applicationId]: contractId }))
  }, [contractId, ownerType, owner, applicationTypeVar])

  const onDelete = useCallback(() => {
    updateFlag(true)
    deleteApp(contractId)
  }, [contractId])

  const actions: { [key in AppActions]: [string, () => void] } = {
    [AppActions.View]: [t('view', 'View'), onView],
    [AppActions.Edit]: [t('edit', 'Edit'), onEdit],
    [AppActions.Delete]: [t('delete', 'Delete'), onDelete],
  }

  return (
    <Box>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${open ? 'open' : ''}`}
      >
        <IconDots />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: -48, horizontal: 'right' }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 112,
          },
          square: true,
        }}
        data-test="menu-list"
      >
        {statusActions[status].map((actionKey) => {
          const action = actions[actionKey]
          return (
            <MenuItem
              key={`${contractId}-${action[0]}`}
              onClick={() => {
                handleClose()
                action[1]()
              }}
            >
              {action[0]}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
