import React, { FC } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { cardsPageStateVar, SearchField } from '../../components'
import { usePageFiltersSorting } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  filterWrap: {
    marginBottom: theme.spacing(4),
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
  },
}))

export const CardFilters: FC<{
  setPage: React.Dispatch<React.SetStateAction<number>>
  disabled: boolean
}> = ({ setPage, disabled = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { pageFilters, setPageFilters } = usePageFiltersSorting(cardsPageStateVar)

  return (
    <Box className={classes.filterWrap}>
      <SearchField
        key={pageFilters.searchValue}
        placeHolder={t(
          'enterCardNameOwnerNameOrLastFourDigits',
          'Enter card’s name, owner’s name or last four digits to search',
        )}
        currentValue={pageFilters.searchValue}
        setPage={setPage}
        setValue={(value) => setPageFilters({ searchValue: value })}
        disabled={disabled}
      />
    </Box>
  )
}
