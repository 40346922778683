import { useParams } from 'react-router-dom'
import { ContractStatusType, useGetContractStatusQuery } from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'

export const useIsContractInactive = () => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: statusData } = useGetContractStatusQuery({
    variables: { id: applicationId },
    skip: !applicationId || Number.isNaN(Number(applicationId)),
    fetchPolicy: 'cache-first',
  })

  return (
    statusData?.contract?.status !== ContractStatusType.Active &&
    statusData?.contract?.status !== ContractStatusType.Monitoring
  )
}
