import React from 'react'
import { Individual } from '../graphql'

type UseCurrentUser = () => Individual | null

export const CurrentUserContext = React.createContext<Individual | null>(null)

export const useCurrentUser: UseCurrentUser = () => {
  return React.useContext(CurrentUserContext)
}
