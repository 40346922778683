import { isEmpty, toNumber } from 'lodash'
import * as yup from 'yup'
import {
  LATINICA_ONLY_MESSAGE,
  LATINICA_ONLY_REGEXP,
  NOT_NEGATIVE,
  NUMBERS_DASHES_COMMAS_FULLSTOPS,
  NUMBERS_ONLY_MESSAGE,
  NUMBERS_ONLY_REGEXP,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import i18n from '../../i18n'

export const TransactionalQuestionsInputSchema = yup.object().shape({
  companyAnnualIncome: yup
    .string()
    .required(i18n.t('thisFieldIsRequired', 'This field is required'))
    .matches(NUMBERS_ONLY_REGEXP, NUMBERS_ONLY_MESSAGE)
    .test(
      'companyAnnualIncomeMaxLength',
      i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON),
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    )
    .test(
      'companyAnnualIncomeNotZero',
      i18n.t('Company annual income cannot be 0'),
      (val) => Number(val) !== 0,
    ),
  companyAnnualIncomeCurrency: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  estimatedIncomingTransactions: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  estimatedIncomingTransactionsAmount: yup
    .string()
    .nullable()
    .required(i18n.t('thisFieldIsRequired', 'This field is required'))
    .test(
      'estimatedIncomingTransactionsAmount',
      NUMBERS_DASHES_COMMAS_FULLSTOPS,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test('notNegative', NOT_NEGATIVE, (value) => toNumber(value) >= 0)
    .test(
      'companyEstimatedIncomingTransactionsAmount',
      i18n.t('Company estimated incoming transactions amount cannot be 0'),
      (val) => Number(val) !== 0,
    )
    .max(PRE_ASSESSMENT_COMMON_FIELD_MAX, i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON)),
  estimatedOutgoingTransactions: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  estimatedOutgoingTransactionsAmount: yup
    .string()
    .nullable()
    .required(i18n.t('thisFieldIsRequired', 'This field is required'))
    .test(
      'estimatedOutgoingTransactionsAmount',
      NUMBERS_DASHES_COMMAS_FULLSTOPS,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test('notNegative', NOT_NEGATIVE, (value) => toNumber(value) >= 0)
    .test(
      'companyEstimatedOutgoingTransactionsAmount',
      i18n.t('Company estimated outgoing transactions amount cannot be 0'),
      (val) => Number(val) !== 0,
    )
    .max(PRE_ASSESSMENT_COMMON_FIELD_MAX, i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON)),
})
