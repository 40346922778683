import React, { FC, useCallback, useEffect, useState } from 'react'
import { generatePath, Redirect, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'
import clsx from 'clsx'

import {
  Button,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import { ReactComponent as LogoutIcon } from '../../../assets/images/icons/logout_black.svg'
import { AppActions, ExtendedAccessContractsTypesList } from '../../../constants'
import { ContractsAuthorizedPerson } from '../ContractsAuthorizedPerson'
import {
  applicationType as applicationTypeVar,
  ApplicationType,
  goBackPathVar,
  goNextPathVar,
  isModalContractTransferOpen,
} from '../../../graphql/local'
import { getDetailsContractsByIndividual, showError } from '../../../utils'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import ConfirmModalDel from '../../Common/ConfirmModalDel'
import { useCurrentUser, useDetermineUserRights, useWebApi } from '../../../hooks'
import { StartApp } from '../StartApp'
import { Loader, LoadingButton } from '../../Common'
import {
  ActionSignatureStatus,
  BankableEntityContractType,
  ContractStatusType,
  ContractType,
  Individual,
  LegalEntity,
  UboLegalEntityType,
  UboType,
  useActionSignatureRequestsCountLazyQuery,
  useGetUserContractsQuery,
  useGetUserDataQuery,
} from '../../../graphql'
import config from '../../../config'
import { TransferContractModal } from '../../../pages/HomePage/Tips/TransferContractModal'
import { ApplicationTypeTabsSection } from './ApplicationTypeTabsSection'
import BusinessAppDesktop from './BusinessAppDesktop'
import BusinessAppMobile from './BusinessAppMobile'
import { dashboardPassStatuses } from '../../../utils/Data'

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  wrapBtn: {
    width: '100%',
    margin: 0,
    '&.MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  wrapBtnApp: {
    [theme.breakpoints.only('sm')]: {
      marginBottom: '20%',
    },
  },
  btn: {
    width: '100%',
    margin: 0,
  },
  startBusinessButton: {
    width: '100%',
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
  },
  createAppBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #D9D9D9',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  createAppBlack: {
    backgroundColor: '#000000',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
  },
  textApp: {
    margin: 0,
    color: '#595959',
  },
  textAppNew: {
    marginBottom: theme.spacing(5),
  },
  changeMailLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
}))

export const StartBusinessAppPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const history = useHistory()
  const { webApi } = useWebApi()
  const user = useCurrentUser()
  const [flagConfirm, setFlagConfirm] = useState(false)
  const [numDeleteApp, setNumDeleteApp] = useState('')

  const { data: userData, loading: userDataLoading } = useGetUserDataQuery({
    fetchPolicy: 'network-only',
  })

  const from = (history.location.state as { from: string })?.from

  const {
    data: userContractsData,
    loading: userContractsLoading,
    error: getUserContractsError,
  } = useGetUserContractsQuery({
    fetchPolicy: 'network-only',
  })

  const [getActionSignatureRequestsCount] = useActionSignatureRequestsCountLazyQuery()

  const [contracts, setContracts] = useState<BankableEntityContractType[]>()
  const [currentContractId, setCurrentContractId] = useState<string | number>()
  const [contractsAsAP, setContractsAsAP] = useState<BankableEntityContractType[]>()
  const [individualContract, setIndividualContract] = useState<BankableEntityContractType | null>(
    null,
  )

  const { userRights } = useDetermineUserRights(currentContractId as string | number)

  const addNewApp = useCallback(
    (type: ApplicationType) => {
      applicationTypeVar(type)
      const path =
        type === 'personal' ? APP_PATHS.application.individual.new : APP_PATHS.application.new
      history.push(path)
    },
    [history, applicationTypeVar],
  )

  const onLogout = useCallback(
    () => webApi?.logout().then(() => history.push(APP_PATHS.auth.login)),
    [webApi],
  )

  const onView = useCallback(
    async (contract: BankableEntityContractType) => {
      setCurrentContractId(contract.id)
      let redirectPath = APP_PATHS.application.status

      if (ExtendedAccessContractsTypesList.includes(contract.status as ContractStatusType)) {
        const { data: signaturesCount } = await getActionSignatureRequestsCount({
          variables: { contractId: contract.id, statuses: [ActionSignatureStatus.Pending] },
        })

        const regularPath =
          signaturesCount?.actionSignatureRequestsCount === 0
            ? APP_PATHS.dashboard.home
            : APP_PATHS.dashboard.actions

        redirectPath = userRights?.isIntroducerContractOwner
          ? APP_PATHS.dashboard.partnerManagementOverview
          : regularPath
      }

      if (contract.ownerType === UboType.NaturalPerson) {
        const owner = contract.owner as Individual
        if (!owner.isPhoneVerified) {
          redirectPath = APP_PATHS.application.individual.phoneVerification
        }
      }

      history.push(
        generatePath(redirectPath, {
          [PATH_PARAMS.applicationId]: contract.id,
        }),
      )
    },
    [history],
  )

  const onEdit = useCallback(
    (contract: BankableEntityContractType) => {
      let redirectPath = APP_PATHS.application.review
      if (contract.ownerType === UboType.NaturalPerson) {
        applicationTypeVar('personal')
        const owner = contract.owner as Individual
        if (!owner.isVerified) {
          redirectPath = APP_PATHS.application.individual.identityVerification
        }
      } else if (contract.status === ContractStatusType.Draft) {
        if (
          (contract.owner as LegalEntity).legalEntityType === UboLegalEntityType.Unknown &&
          contract.type !== ContractType.IntroducerReference
        ) {
          redirectPath = APP_PATHS.application.edit
        } else if (
          isEmpty(contract.authorizedPersons) &&
          contract.type !== ContractType.IntroducerReference
        ) {
          redirectPath = APP_PATHS.application.authorizedPersons.add
        } else if (isEmpty((contract.owner as LegalEntity)?.legalEntityDirectors)) {
          redirectPath = APP_PATHS.application.directors.list
        } else if (isEmpty(contract.ubos)) {
          redirectPath = APP_PATHS.application.beneficialOwners.list
        }
      }
      history.push(generatePath(redirectPath, { [PATH_PARAMS.applicationId]: contract.id }))
    },
    [history, applicationTypeVar],
  )

  const actions: { [key in AppActions]: (contract: BankableEntityContractType) => void } = {
    [AppActions.View]: onView,
    [AppActions.Edit]: onEdit,
    [AppActions.Delete]: () => true,
  }

  const isIntroducer = !!(userData?.viewer as Individual)?.isIntroducer

  useEffect(() => {
    goBackPathVar('')
    goNextPathVar('')
  }, [])

  useEffect(() => {
    isModalContractTransferOpen(!isEmpty(user?.pendingContractOwnershipTransfers ?? []))
  }, [user, isModalContractTransferOpen])

  const updateFlag = (value: boolean) => {
    setFlagConfirm(value)
  }
  const deleteApp = (value: string | number) => {
    setNumDeleteApp(String(value))
  }

  useEffect(() => {
    if (!userContractsLoading && !userDataLoading) {
      const userContracts = get(userContractsData, 'contracts') as BankableEntityContractType[]
      const individual = userData?.viewer as Individual
      if (individual && !isEmpty(userContracts)) {
        const {
          contractsAp,
          contractsApplicant,
          individualContract,
        } = getDetailsContractsByIndividual(userContracts, individual)
        setContractsAsAP(contractsAp)
        setContracts(contractsApplicant)
        setIndividualContract(individualContract)
      } else {
        setContractsAsAP([])
        setContracts([])
        setIndividualContract(null)
      }
    }
  }, [
    userContractsLoading,
    userDataLoading,
    userData,
    userContractsData,
    setContractsAsAP,
    setContracts,
  ])

  useEffect(() => {
    if (getUserContractsError) {
      showError(getUserContractsError)
    }
  }, [getUserContractsError])

  useEffect(() => {
    const individual = userData?.viewer as Individual
    if (individual?.isIntroducer && !individual?.isPhoneVerified) {
      history.push(generatePath(APP_PATHS.phoneVerification))
    }
  }, [userData, history, isIntroducer])

  const firstContractAsAP = contractsAsAP && contractsAsAP[0]
  const hasIndContract = !isEmpty(individualContract)
  const isAllowToCreateIndContract = !hasIndContract && config.isIndividualSignupEnabled
  const contractsAsAPSize = contractsAsAP?.length
  const contractsSize = contracts?.length
  const indContractActivated = !!individualContract?.activatedOn
  const firstContractAsAPIsFirsLine =
    firstContractAsAP?.status !== ContractStatusType.FirstLinePending
  const notFromDashboard = from !== 'dashboard'
  const hasNoIndContractAndSingleContractAsAP = !hasIndContract && contractsAsAPSize === 1
  const hasIndContractAndNoContractsAsAP = hasIndContract && contractsAsAPSize === 0

  if (
    contractsAsAP &&
    contractsSize === 0 &&
    (hasNoIndContractAndSingleContractAsAP ||
      (hasIndContractAndNoContractsAsAP && indContractActivated)) &&
    firstContractAsAPIsFirsLine &&
    notFromDashboard
  ) {
    const appId = firstContractAsAP?.id || individualContract?.id
    const isIntroducerContractOwner =
      (firstContractAsAP?.createdBy?.id === user?.id || !firstContractAsAP?.createdBy) &&
      firstContractAsAP?.type === ContractType.IntroducerReference
    return (
      <Redirect
        to={generatePath(
          isIntroducerContractOwner
            ? APP_PATHS.dashboard.partnerManagementOverview
            : APP_PATHS.dashboard.home,
          { [PATH_PARAMS.applicationId]: appId },
        )}
      />
    )
  }

  const readyContracts = [
    ...(individualContract &&
    dashboardPassStatuses.includes(individualContract.status as ContractStatusType)
      ? [individualContract]
      : []),
    ...(contractsAsAP || []),
  ]

  if (!contractsAsAP || !contracts || userContractsLoading || userDataLoading)
    return <Loader height={'100%'} />

  return (
    <StartApp>
      <ConfirmModalDel
        value={flagConfirm}
        updateFlag={updateFlag}
        deleteApp={deleteApp}
        numDeleteApp={numDeleteApp}
      />
      <Typography variant={'h1'} className={classes.pageTitle}>
        {!isEmpty(contractsAsAP) || !isEmpty(contracts)
          ? t('welcomeBack', 'Welcome back')
          : t('letsGetStarted', 'Let’s get started')}
        !
      </Typography>
      {/* AP is applicant and AP access (only contracts where user AP)  */}
      {!isEmpty(readyContracts) && (
        <>
          <Typography variant={'h4'} className={classes.textApp}>
            {t('selectContractToLogIn', 'Select a contract to log in')}
          </Typography>
          {readyContracts && <ContractsAuthorizedPerson contracts={readyContracts} />}
        </>
      )}

      {individualContract &&
        [
          ContractStatusType.Draft,
          ContractStatusType.PreAssessment,
          ContractStatusType.Suspended,
          ContractStatusType.TerminationPending,
        ].includes(individualContract.status as ContractStatusType) && (
          <>
            <Typography variant={'h4'} className={classes.textApp}>
              {t('yourPersonalApplication', 'Your personal application')}
            </Typography>

            <Hidden xsDown>
              <BusinessAppDesktop
                contracts={[individualContract]}
                actions={actions}
                deleteApp={deleteApp}
                updateFlag={updateFlag}
              />
            </Hidden>
            <Hidden smUp>
              <BusinessAppMobile
                contracts={[individualContract]}
                actions={actions}
                deleteApp={deleteApp}
                updateFlag={updateFlag}
              />
            </Hidden>
          </>
        )}

      {!isEmpty(contracts) && (
        <>
          <Typography variant={'h4'} className={classes.textApp}>
            {t('yourApplications', 'Your applications')}
          </Typography>

          <Hidden xsDown>
            <BusinessAppDesktop
              contracts={contracts}
              actions={actions}
              deleteApp={deleteApp}
              updateFlag={updateFlag}
            />
          </Hidden>
          <Hidden smUp>
            <BusinessAppMobile
              contracts={contracts}
              actions={actions}
              deleteApp={deleteApp}
              updateFlag={updateFlag}
            />
          </Hidden>
        </>
      )}

      {isEmpty(contractsAsAP) && isEmpty(contracts) && !individualContract && (
        <Typography className={classes.textAppNew}>
          {t('getAccessToCompany', {
            val1: config.name,
          })}
        </Typography>
      )}

      {isBigScreen && !hasIndContract ? (
        <ApplicationTypeTabsSection addNewApp={addNewApp} />
      ) : (
        <Grid
          item
          container
          spacing={2}
          className={clsx(
            classes.wrapBtn,
            `${!isEmpty(contractsAsAP) || !isEmpty(contracts) ? '' : classes.wrapBtnApp}`,
          )}
        >
          <Grid item className={classes.btn}>
            <Button
              className={clsx(
                classes.startBusinessButton,
                classes.createAppBtn,
                `${!isEmpty(contractsAsAP) || !isEmpty(contracts) ? '' : classes.createAppBlack}`,
              )}
              variant="contained"
              disableElevation
              color="default"
              onClick={() => addNewApp('business')}
              data-test="createApplicationButton"
            >
              {!isEmpty(contractsAsAP) || !isEmpty(contracts)
                ? t('createNewBusinessApplication', 'Create new business application')
                : isIntroducer
                ? t('createApplication', 'Create application')
                : t('createBusinessApplication', 'Create business application')}
            </Button>
          </Grid>
          {isAllowToCreateIndContract && (
            <Grid item className={classes.btn}>
              <LoadingButton
                className={clsx(classes.startBusinessButton, classes.createAppBtn)}
                variant="contained"
                disableElevation
                color="default"
                onClick={() => addNewApp('personal')}
                data-test="createPersonalApplicationButton"
                loading={!user}
              >
                {t('createPersonalApplication', 'Create personal application')}
              </LoadingButton>
            </Grid>
          )}
          <Hidden lgUp>
            <Grid item className={classes.btn}>
              <Button
                className={classes.startBusinessButton}
                variant="contained"
                disableElevation
                color="default"
                onClick={onLogout}
                data-test="logOutButton"
              >
                <LogoutIcon />
                {t('logOut', 'Log Out')}
              </Button>
            </Grid>
            <Grid className={classes.changeMailLink}>
              <Link href={APP_PATHS.auth.changeEmail} underline="always">
                <span>
                  <b>{t('changeEmail', 'Change email')}</b>
                </span>
              </Link>
            </Grid>
          </Hidden>
        </Grid>
      )}
      <TransferContractModal />
    </StartApp>
  )
}

export default StartBusinessAppPage
