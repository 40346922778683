import { Box, Grid, makeStyles } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import HOME_CREDIT from '../../assets/images/icons/home-credit.svg'
import HOME_DEBIT from '../../assets/images/icons/home-debit.svg'
import HOME_FEE from '../../assets/images/icons/home-fee.svg'
import HOME_TRANSFER from '../../assets/images/icons/home-transfer.svg'
import {
  CreditTransaction,
  DebitTransaction,
  TransactionEntryType,
  TransactionType,
} from '../../graphql'
import { dateFormat, transactionType } from '../../utils'

const useStyles = makeStyles(() => ({
  dateAccount: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#999999',
  },
  whichType: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    '& .outbound': {
      backgroundImage: `url(${HOME_CREDIT})`,
      width: '14px',
      height: '14px',
      background: '#F1FBF7',
      padding: '16px',
      borderRadius: '56px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .inbound': {
      backgroundImage: `url(${HOME_DEBIT})`,
      width: '14px',
      height: '14px',
      background: '#F1FBF7',
      padding: '16px',
      borderRadius: '56px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .credit': {
      backgroundImage: `url(${HOME_DEBIT})`,
      width: '14px',
      height: '14px',
      background: '#FFEFED',
      padding: '16px',
      borderRadius: '56px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .transfer': {
      backgroundImage: `url(${HOME_TRANSFER})`,
      width: '14px',
      height: '14px',
      background: '#F1FBF7',
      border: '1px solid #ADDEC9',
      padding: '16px',
      borderRadius: '56px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .fee': {
      backgroundImage: `url(${HOME_FEE})`,
      width: '14px',
      height: '14px',
      background: '#FFEFED',
      padding: '16px',
      borderRadius: '56px',
      margin: '0 6px 0 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}))

export const TransactionTypeSell: FC<{
  data: CreditTransaction | DebitTransaction
}> = ({ data }) => {
  const classes = useStyles()

  const { isInterContract, entryType, createdAt, type } = data

  const transactionTypeName = useMemo(() => {
    if (type === TransactionType.Fee && !isInterContract) {
      return transactionType(type)
    } else {
      return type === TransactionType.TransferInternal && !isInterContract
        ? transactionType(type)
        : transactionType(entryType as TransactionEntryType)
    }
  }, [entryType, isInterContract, type])

  return (
    <Box className={classes.whichType}>
      <Grid container>
        <Grid item xs={12}>
          {transactionTypeName}
        </Grid>
        <Grid item xs={12} className={classes.dateAccount}>
          {dateFormat(createdAt)}
        </Grid>
      </Grid>
      <Grid container className={`${transactionTypeName.toLocaleLowerCase()}`} />
    </Box>
  )
}
