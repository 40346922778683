import { ReactiveVar, useReactiveVar } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import {
  initCardsPageState,
  initClientsOverviewPageState,
  initClientsPageState,
  initialAccountsPageState,
  initialActionsPageState,
  initialDocsPageState,
  initialPaymentsPageState,
  initialTeamPageState,
  initialTransactionPageState,
  initVbansPageState,
} from '../constants'
import {
  actionsPageStateValue,
  cardsPageStateVar,
  clientsPageStateVar,
  uploadDocsPageStateVar,
  contractsTeamPageStateVar,
  trxPageStateVar,
  receivedPageStateVar,
  vbansPageStateVar,
  clientsOverviewPageStateVar,
  accountsPageStateValue,
  paymentsPageStateVar,
} from '../graphql/local'

export const usePageFiltersSorting = <T>(pageStateVar?: ReactiveVar<T>) => {
  const currentVar = useMemo(
    () => pageStateVar || ((trxPageStateVar as unknown) as ReactiveVar<T>),
    [pageStateVar],
  )

  const accountsCurrentVar = useReactiveVar(accountsPageStateValue)
  const trxCurrentVar = useReactiveVar(trxPageStateVar)
  const actionCurrentVar = useReactiveVar(actionsPageStateValue)
  const clientsCurrentVar = useReactiveVar(clientsPageStateVar)
  const clientsOverviewCurrentVar = useReactiveVar(clientsOverviewPageStateVar)
  const contractsTeamCurrentVar = useReactiveVar(contractsTeamPageStateVar)
  const uploadDocsCurrentVar = useReactiveVar(uploadDocsPageStateVar)
  const receivedDocCurrentVar = useReactiveVar(receivedPageStateVar)
  const cardsCurrentVar = useReactiveVar(cardsPageStateVar)
  const vbansCurrentVar = useReactiveVar(vbansPageStateVar)
  const paymentsCurrentVar = useReactiveVar(paymentsPageStateVar)

  const resetSearchAndPageValues = useCallback(() => {
    accountsPageStateValue({ ...accountsCurrentVar, searchValue: '', page: 1 })
    trxPageStateVar({ ...trxCurrentVar, searchValue: '', page: 1 })
    actionsPageStateValue({ ...actionCurrentVar, searchValue: '', page: 1 })
    clientsPageStateVar({ ...clientsCurrentVar, searchValue: '', page: 1 })
    clientsOverviewPageStateVar({ ...clientsOverviewCurrentVar, page: 1 })
    contractsTeamPageStateVar({ ...contractsTeamCurrentVar, searchValue: '', page: 1 })
    uploadDocsPageStateVar({ ...uploadDocsCurrentVar, searchValue: '', page: 1 })
    receivedPageStateVar({ ...receivedDocCurrentVar, searchValue: '', page: 1 })
    cardsPageStateVar({ ...cardsCurrentVar, searchValue: '' })
    vbansPageStateVar({ ...vbansCurrentVar, searchValue: '', page: 1 })
    paymentsPageStateVar({ ...paymentsCurrentVar, searchValue: '', page: 1 })
  }, [
    accountsCurrentVar,
    trxCurrentVar,
    actionCurrentVar,
    clientsCurrentVar,
    clientsOverviewCurrentVar,
    contractsTeamCurrentVar,
    uploadDocsCurrentVar,
    receivedDocCurrentVar,
    cardsCurrentVar,
    paymentsCurrentVar,
  ])

  const resetAll = () => {
    accountsPageStateValue(initialAccountsPageState)
    trxPageStateVar(initialTransactionPageState)
    actionsPageStateValue(initialActionsPageState)
    clientsPageStateVar(initClientsPageState)
    clientsOverviewPageStateVar(initClientsOverviewPageState)
    contractsTeamPageStateVar(initialTeamPageState)
    uploadDocsPageStateVar(initialDocsPageState)
    receivedPageStateVar(initialDocsPageState)
    cardsPageStateVar(initCardsPageState)
    vbansPageStateVar(initVbansPageState)
    paymentsPageStateVar(initialPaymentsPageState)
  }

  const pageFilters = useReactiveVar(currentVar)

  const setPageFilters = useCallback(
    (valuesObj: Partial<typeof pageFilters>) => {
      const pageFiltersState = currentVar()
      const newFiltersState = {
        ...pageFiltersState,
        ...valuesObj,
      }

      currentVar(newFiltersState)
    },
    [currentVar, pageFilters],
  )

  return { pageFilters, setPageFilters, resetSearchAndPageValues, resetAll }
}
