import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FormControlLabel, makeStyles, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { BlackRadio, FormRadioGroup } from '../../Common'
import { useFormContext } from 'react-hook-form'
import { LimitedAccessRight } from '../../../graphql'

const useStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0, 2.5, 0),
    },
  },
  title: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  radio: {
    '& .MuiRadio-root': {
      padding: theme.spacing(0.25, 0.5, 0.5, 1),
    },
  },
}))

export const Limited: FC<{
  limitedAccessRight?: LimitedAccessRight | undefined | null
}> = ({ limitedAccessRight }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { watch, setValue } = useFormContext()

  const radioValue = watch('limitedAccessRight')

  useEffect(() => {
    if (limitedAccessRight) {
      setValue('limitedAccessRight', limitedAccessRight)
    }
  }, [limitedAccessRight, setValue])

  return (
    <Box mb={4}>
      <Box className={classes.titleWrap}>
        <Typography variant={'h1'} className={classes.title}>
          {t('accessRights', 'Access Rights')}
        </Typography>
      </Box>
      <Box>
        <FormRadioGroup name="limitedAccessRight" value={radioValue || 'viewOnly'}>
          <Box mb={1.75} data-test="viewOnly">
            <FormControlLabel
              value="viewOnly"
              control={<BlackRadio size="small" />}
              label={t('viewOnly', 'View Only')}
              className={classes.radio}
            />
          </Box>
          <FormControlLabel
            value="viewDataEntry"
            control={<BlackRadio size="small" />}
            label={t('viewAndDataEntry', 'View and Data Entry')}
            className={classes.radio}
            data-test="viewAndDataEntry"
          />
        </FormRadioGroup>
      </Box>
    </Box>
  )
}
